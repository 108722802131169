import { useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "../Docs.module.css";
import { useTOC } from "../TOCContext";
import DocImages from "../DocImages";

const entries = [
  {
    displayText: "Various Volume Indicators",
    urlFragment: "various-volume-indicators",
    headingType: "h2",
    subEntries: [],
  },
];
export { entries };

export default function DocsTechnicalAnalysisTechnicalIndicatorsVolume() {
  const { addToTOC, removeFromTOC } = useTOC();

  useEffect(() => {
    entries.forEach(addToTOC);
    return () => {
      entries.forEach((entry) => removeFromTOC(entry));
    };
  }, [addToTOC, removeFromTOC]);

  return (
    <>
      <h1 className={styles.h1}>Volume Indicators</h1>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[63]}
          width={1856}
          height={846}
          className="mx-auto"
          alt="Volume Indicators"
        ></img>
      </p>

      <p className={styles.p}>
        Volume serves as a quantitative measure of the traded shares within a
        specific timeframe, offering insights into the activity and liquidity of
        a security.
      </p>
      <p className={styles.p}>
        Volume indicators encompass tools that incorporate historical volume
        data into their calculations. However, it's important to note that not
        all indicators that use volume in their calculations fall under this
        category. Examples like the volume weighted moving average (VWMA) or
        money flow index (MFI) don't qualify as volume indicators. These
        indicators can be categorized as either lagging or leading, depending on
        their design.
      </p>
      <h2
        id="various-volume-indicators"
        className={`scroll-mt-2 ${styles["h2"]}`}
      >
        Various Volume Indicators
        <Link
          to="#various-volume-indicators"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        While the selection of volume indicators is smaller compared to trend or
        momentum indicators, some have gained significant popularity. For
        instance, the price volume trend indicator (PVT) calculates the
        cumulative sum of price changes weighted by current volume. This
        approach offers an alternative view of price movement by filtering out
        variations with low volume.
      </p>
      <figure className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[64]}
          width={1972}
          height={827}
          className="mx-auto"
          alt="PVT - Price Volume Trend Indicator"
        ></img>
        <figcaption
          style={{
            textAlign: "center",
            fontSize: 12,
          }}
        >
          PVT in blue
        </figcaption>
      </figure>
      <p className={styles.p}>
        A similar indicator is the on balance volume (OBV), which shares a
        calculation method with PVT but factors in the direction of price
        changes.
      </p>
      <figure className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[65]}
          width={2068}
          height={840}
          className="mx-auto"
          alt="OBV - On Balance Volume"
        ></img>
        <figcaption
          style={{
            textAlign: "center",
            fontSize: 12,
          }}
        >
          OBV in orange
        </figcaption>
      </figure>
      <p className={styles.p}>
        Additional volume indicators encompass the accumulation/distribution
        index, ease of movement, and Elder force index, among others.
      </p>

      <h2 id="volume-profile" className={`scroll-mt-2 ${styles["h2"]}`}>
        Volume Profile
        <Link
          to="#volume-profile"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        The volume profile serves as a valuable tool displaying the distribution
        of volume across different price levels.
      </p>
      <figure className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[66]}
          width={1521}
          height={760}
          className="mx-auto"
          alt="MADALGO Pulse FRVP"
        ></img>
        <figcaption
          style={{
            textAlign: "center",
            fontSize: 12,
          }}
        >
          MADALGO Pulse FRVP
        </figcaption>
      </figure>
      <p className={styles.p}>
        The point of control is denoted by the red line, representing the price
        level that has accumulated the highest volume. This line is often
        observed in regions where the price remains relatively stable
      </p>
    </>
  );
}
