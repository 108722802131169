import { motion } from "framer-motion";
import Blur from "./Blur";
import { useEffect, useState } from "react";
import styles from "./Main.module.css";

export default function CommunityEarn() {
  const [text, setText] = useState("Cash");
  const [isDeleting, setIsDeleting] = useState(false);
  const [loopNum, setLoopNum] = useState(0);
  const [typingSpeed, setTypingSpeed] = useState(200);
  const [isTypingStarted, setIsTypingStarted] = useState(false);
  const texts = ["Cash", "Crypto", "Points"];

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsTypingStarted(true);
      setIsDeleting(true);
    }, 2400);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (!isTypingStarted) return;

    let timer;
    const current = loopNum % texts.length;
    const fullText = texts[current];

    const handleTyping = () => {
      setText(
        isDeleting
          ? fullText.substring(0, text.length - 1)
          : fullText.substring(0, text.length + 1),
      );

      setTypingSpeed(isDeleting ? 100 : 200);

      if (!isDeleting && text === fullText) {
        setTimeout(() => setIsDeleting(true), 2000);
      } else if (isDeleting && text === "") {
        setIsDeleting(false);
        setLoopNum(loopNum + 1);
      }
    };

    timer = setTimeout(handleTyping, typingSpeed);

    return () => clearTimeout(timer);
  }, [text, isDeleting, loopNum, texts, typingSpeed, isTypingStarted]);

  const list = {
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.2,
        ease: [0.04, 0.62, 0.23, 0.98],
      },
    },
    hidden: {
      opacity: 0,
      transition: {
        when: "afterChildren",
        ease: [0.04, 0.62, 0.23, 0.98],
      },
    },
  };

  return (
    <motion.div
      id="creators-home"
      className="relative box-content flex min-h-screen w-full flex-col items-center justify-center pb-24"
    >
      <div
        id="blur"
        className=" absolute bottom-[0%] left-[0%] right-[0%] top-[0%] z-[-1] block"
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 2, delay: 0.4 }}
          id="gradient-blur-group-1"
          className="lg:w[85vw] absolute bottom-[55.8rem] left-auto right-[-6.6rem] top-auto h-[100vw] w-[95vw] opacity-60 filter-none lg:-right-80 lg:bottom-[40rem] lg:h-[85vw] xl:bottom-[45rem] xl:left-[5.7rem] xl:max-h-[100rem] xl:max-w-[100rem]"
        >
          <Blur />
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 2, delay: 0.8 }}
          id="gradient-blur-group-2"
          className="lg:w[85vw] absolute bottom-[10.8rem] left-auto right-[10.6rem] top-auto h-[100vw] w-[95vw] opacity-60 filter-none sm:bottom-14  md:-bottom-80 md:w-screen lg:-left-80 lg:h-[85vw] xl:left-[-5.7rem] xl:top-60 xl:max-h-[100rem] xl:max-w-[100rem]"
        >
          <Blur />
        </motion.div>
      </div>
      <section
        id="creators-content"
        className=" relative z-[1] block h-screen w-full"
      >
        <motion.div
          id="wrapper"
          className="flex h-full flex-col px-7 pb-20 pt-40 [-moz-box-direction:normal] [-moz-box-orient:vertical] [-moz-box-pack:center] lg:px-16"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 2 }}
        >
          <motion.div
            id="inner"
            className="mb-40 w-full max-w-xl"
            initial="hidden"
            animate="visible"
            variants={list}
          >
            <motion.h1
              className="mb-6 text-left text-6xl font-bold tracking-tight lg:text-9xl"
              initial={{ opacity: 0, y: 40 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1.2, delay: 0.4 }}
            >
              Earn
            </motion.h1>

            <motion.h1
              className="mb-6 inline-block transform text-left text-6xl font-bold tracking-tight lg:text-9xl"
              initial={{ opacity: 0, y: 40 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1.2, delay: 0.8 }}
            >
              {text}
              <span className={`${styles.cursor}`}>|</span>
            </motion.h1>
          </motion.div>
          <motion.div
            className="mt-6 flex items-center justify-center text-4xl uppercase"
            initial="hidden"
            animate="visible"
            variants={list}
            transition={{ delay: 0.3 }}
          >
            Coming Soon
          </motion.div>
        </motion.div>
      </section>
    </motion.div>
  );
}
