import { Link } from "react-router-dom";
import { Auth, API, graphqlOperation } from "aws-amplify";
import { getSubscriptionPlanByUserId } from "../utils/queries";
import { useState, useEffect } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
  Elements,
  AddressElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ClipLoader } from "react-spinners";
import Confetti from "react-dom-confetti";
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";

import("./checkoutindex.css");

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const getPriceId = (planName, billingCycle) => {
  const stripePriceIds = {
    premium: {
      monthly: "price_1OZGGtFM3Ng1Lx5iTpoaGEtY",
      annual: "price_1OZFx6FM3Ng1Lx5iT7o4gmaj",
    },
    lifetime: "price_1O6yZnFM3Ng1Lx5icl6JcUWQ",
  };

  if (planName.toLowerCase() === "lifetime") {
    return stripePriceIds.lifetime;
  }

  return (
    stripePriceIds[planName.toLowerCase()] &&
    stripePriceIds[planName.toLowerCase()][billingCycle]
  );
};

const getPayPalPlanId = (planName, billingCycle, promoCode) => {
  const payPalPlanIds = {
    premium: {
      monthly: "P-7CC2812474629204UMU25YPI",
      annual: "P-1LV68815KP8839049MU25YVQ",
    },
  };

  const discountedPlanIds = {
    MADALGO100: {
      premium: {
        monthly: "P-1WM604123V3312818MWTL2IY",
        annual: "",
      },
    },
  };

  if (promoCode && discountedPlanIds[promoCode]) {
    return (
      discountedPlanIds[promoCode][planName.toLowerCase()] &&
      discountedPlanIds[promoCode][planName.toLowerCase()][billingCycle]
    );
  }

  return (
    payPalPlanIds[planName.toLowerCase()] &&
    payPalPlanIds[planName.toLowerCase()][billingCycle]
  );
};

const planDetailsMap = {
  premium: [
    "Access to MADALGO",
    "Real-Time Updates",
    "Access to Premium Discord",
    "Buy/Sell Signals for All Assets",
    "New Coin Listing Alerts",
    "Insider Trading Alerts",
    "Exclusive Guides & Content",
    "Access to Workshops",
    "Prioritized Customer Support",
  ],
  lifetime: [
    "Everything from Premium",
    "Lifetime Updates",
    "Access to Future Indicators",
    "First Access to New Features",
    "Beta Tester Access",
    "VIP Discord Access",
  ],
};

function getPlanDetails(planName) {
  return planDetailsMap[planName.toLowerCase()] || [];
}

function StripeForm({ planName, billingCycle, stripeCustomerId }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("card"); // "card" or "paypal"
  const [agreeChecked, setAgreeChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  const [promoCode, setPromoCode] = useState("");
  const [discountAmount, setDiscountAmount] = useState(0);
  const [showPromoInput, setShowPromoInput] = useState(false);

  const stripe = useStripe();
  const elements = useElements();
  const [isAddressComplete, setIsAddressComplete] = useState(false);
  const [addressDetails, setAddressDetails] = useState(null);

  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const planDetails = getPlanDetails(planName);

  const paymentElementOptions = {
    layout: "tabs",
  };

  const config = {
    angle: 90,
    spread: 360,
    startVelocity: 20,
    elementCount: 200,
    dragFriction: 0.1,
    duration: 3000,
    stagger: 0,
    width: "10px",
    height: "10px",
    colors: ["#a864fd", "#29cdff", "#20e39e", "#e0d62e", "#ef62a6"],
  };

  const handlePromoCodeChange = (e) => {
    setPromoCode(e.target.value);
  };
  const handleApplyPromoCode = async () => {
    try {
      let priceId = getPriceId(planName, billingCycle);

      const response = await fetch(process.env.REACT_APP_STRIPE_VERIFY_PROMO, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ promoCode, priceId }),
      });
      const result = await response.json();

      if (result.valid) {
        setDiscountAmount(result.discountAmount);
      } else {
        // Handle invalid promo code case
      }
    } catch (error) {
      setErrorMessage(error);
      setShowError(true);
    }
  };

  const handleAddressChange = (event) => {
    if (event.complete) {
      setIsAddressComplete(true);
      setAddressDetails(event.value);
    } else {
      setIsAddressComplete(false);
    }
  };

  useEffect(() => {
    const paymentElement = elements
      ? elements.getElement(PaymentElement)
      : null;

    setIsFormValid(paymentElement && isAddressComplete && agreeChecked);
  }, [elements, isAddressComplete, agreeChecked]);

  const handlePayment = async (e) => {
    try {
      e.preventDefault();

      if (!stripe || !elements) {
        return;
      }

      setIsLoading(true);

      // Obtain the PaymentElement instance
      const paymentElement = elements.getElement(PaymentElement);

      // Check if both the paymentElement and addressDetails are available
      if (!paymentElement || !addressDetails) {
        setErrorMessage("Missing payment or address details.");
        setShowError(true);
        setIsLoading(false);
        return;
      }

      // Confirm the setup
      const confirmResult = await stripe.confirmSetup({
        elements,
        redirect: "if_required",
      });

      if (confirmResult.error) {
        setErrorMessage(confirmResult.error.message);
        setShowError(true);
        setIsLoading(false);
        return;
      }

      // The setup has been confirmed and a PaymentMethod object has been created
      const setupIntent = confirmResult.setupIntent;
      const paymentMethodId = setupIntent.payment_method;

      // Call your createSubscription function with the PaymentMethod ID
      createSubscription(paymentMethodId);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setErrorMessage(error.message || "An error occurred");
      setShowError(true);
      setIsLoading(false);
    }
  };

  // function generateUniqueCode() {
  //   const timestamp = Date.now(); // Current timestamp in milliseconds
  //   const randomNum = Math.floor(Math.random() * 100000); // Random number between 0 and 99999
  //   return `${timestamp}-${randomNum}`;
  // }

  // async function sendConfirmationEmail(email, confirmationCode) {
  //   const payload = JSON.stringify({
  //     email: email,
  //     confirmationCode: confirmationCode,
  //   });

  //   const response = await fetch(process.env.REACT_APP_SEND_EMAIL_CONFIRM, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: payload,
  //   });

  //   if (!response.ok) {
  //     const errorData = await response.json();
  //     throw new Error(errorData.error || "Failed to send confirmation email");
  //   }

  //   return response.json();
  // }

  // async function getSubscriberEmail() {
  //   try {
  //     const user = await Auth.currentAuthenticatedUser();
  //     return user.attributes.email;
  //   } catch (error) {
  //     console.error("Error getting authenticated user:", error);
  //     throw new Error("Failed to get authenticated user");
  //   }
  // }

  function PayPalSubscriptionButton({ promoCode }) {
    const [{ isPending }] = usePayPalScriptReducer();
    const payPalPlanId = getPayPalPlanId(planName, billingCycle, promoCode);

    if (isPending) return null;

    const handleApprove = async () => {
      setShowSuccessModal(true);
      document
        .getElementById("confetti-container")
        .classList.add("show-confetti");

      // const subscriberEmail = await getSubscriberEmail();
      // const confirmationCode = generateUniqueCode();
      // await sendConfirmationEmail(subscriberEmail, confirmationCode);
    };

    if (planName.toLowerCase() === "lifetime") {
      return (
        <PayPalButtons
          style={{
            shape: "pill",
            color: "gold",
            layout: "vertical",
            label: "paypal",
          }}
          createOrder={(data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    value: "499.99",
                  },
                },
              ],
            });
          }}
          onApprove={async (data, actions) => {
            console.log("Order approved:", data);
            try {
              const details = await actions.order.capture();
              console.log("Order captured:", details);
              handleApprove();
            } catch (error) {
              console.error("Error capturing order:", error);
            }
          }}
          onError={(err) => {
            console.error("Payment Error:", err);
          }}
          onCancel={(data) => {
            console.log("Payment Cancelled:", data);
          }}
        />
      );
    }

    return (
      <PayPalButtons
        style={{
          shape: "pill",
          color: "gold",
          layout: "vertical",
          label: "paypal",
        }}
        createSubscription={(data, actions) => {
          console.log(payPalPlanId);
          return actions.subscription.create({ plan_id: payPalPlanId });
        }}
        onApprove={handleApprove}
      />
    );
  }

  const createSubscription = async (paymentMethodId) => {
    setIsLoading(true);
    try {
      let priceId = getPriceId(planName, billingCycle);

      const response = await fetch(
        process.env.REACT_APP_CREATE_STRIPE_SUBSCRIPTION,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            paymentMethodId,
            stripeCustomerId,
            priceId,
            promoCode,
          }),
        },
      );

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error || "Network response was not ok");
      } else if (data.error) {
        setErrorMessage(data.error);
        setShowError(true);
      } else {
        setShowSuccessModal(true);
        document
          .getElementById("confetti-container")
          .classList.add("show-confetti");

        // const subscriberEmail = await getSubscriberEmail(); // Get subscriber's email
        // const confirmationCode = generateUniqueCode(); // Generate unique code
        // await sendConfirmationEmail(subscriberEmail, confirmationCode); // Send confirmation email
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getPrice = (planName, billingCycle) => {
    const prices = {
      premium: {
        monthly: "29.99",
        annual: "299.99",
      },
      lifetime: "499.99",
    };

    if (planName.toLowerCase() === "lifetime") {
      return prices.lifetime;
    }

    const priceValue =
      prices[planName.toLowerCase()] &&
      prices[planName.toLowerCase()][billingCycle];

    return priceValue;
  };

  // Modify the getPrice function to consider the discount
  const getFinalPrice = () => {
    const originalPrice = parseFloat(getPrice(planName, billingCycle));
    return (originalPrice * 100 - discountAmount) / 100; // Convert to cents, subtract, then convert back to dollars
  };

  const handleLogOut = async () => {
    try {
      await Auth.signOut();

      // Delete the cookies
      document.cookie = `token=; domain=.madalgo.io; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC; secure`;
      document.cookie = `accessToken=; domain=.madalgo.io; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC; secure`;

      window.location.href = "https://www.madalgo.io";
    } catch (error) {
      setShowError(true);
      setErrorMessage(`Error signing out: Contact Customer Support`);
      console.error("Error signing out:", error);
    }
  };
  const handleRedirect = async () => {
    try {
      await Auth.signOut();

      // Delete the cookies
      document.cookie = `token=; domain=.madalgo.io; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC; secure`;
      document.cookie = `accessToken=; domain=.madalgo.io; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC; secure`;

      window.location.href =
        "https://www.tradingview.com/u/MADALGO/#published-scripts";
    } catch (error) {
      setShowError(true);
      setErrorMessage(`Error Navigating: Contact Customer Support`);
      console.error("Error Navigating:", error);
    }
  };

  const Spinner = () => (
    <div className="spinner">
      <style jsx>{`
        .spinner {
          border: 4px solid rgba(0, 0, 0, 0.1);
          width: 36px;
          height: 36px;
          border-radius: 50%;
          border-left-color: #08f;
          animation: spin 1s linear infinite;
        }

        @keyframes spin {
          to {
            transform: rotate(360deg);
          }
        }
      `}</style>
    </div>
  );

  const handleShowDetails = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="flex min-h-screen flex-col text-gray-800">
        <div className=" absolute left-5 top-5">
          <Link
            className="text-la-gray-500 hover:text-la-gray-700 flex items-center gap-1"
            to="https://www.madalgo.io/#premium-packages"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
              className="h-5 w-5"
            >
              <path
                fillRule="evenodd"
                d="M17 10a.75.75 0 01-.75.75H5.612l4.158 3.96a.75.75 0 11-1.04 1.08l-5.5-5.25a.75.75 0 010-1.08l5.5-5.25a.75.75 0 111.04 1.08L5.612 9.25H16.25A.75.75 0 0117 10z"
                clipRule="evenodd"
              ></path>
            </svg>
            Back
          </Link>
        </div>
        <div className="flex flex-1 flex-col lg:flex-row">
          <div className="flex flex-1 flex-col gap-10 p-5 pt-16 sm:px-20 md:px-40 lg:p-20 xl:pl-40 2xl:pl-60">
            <div className="flex-1">
              <div className="block lg:hidden">
                <div className="">
                  <div className="text-gray-500">MADALGO Premium</div>
                  <div className="flex items-baseline gap-2">
                    <div className="text-2xl font-bold">{getFinalPrice()}</div>
                    <div className="text-gray-500">
                      {planName !== "lifetime" && billingCycle}
                    </div>
                  </div>
                  <div className="flex items-center justify-between">
                    <button
                      className="focus:outline-3 false inline flex items-center justify-center overflow-hidden truncate rounded-lg p-0 leading-5 text-blue-600 outline outline-transparent transition-all transition-colors ease-in-out hover:text-blue-400 focus:outline-blue-200"
                      onClick={handleShowDetails}
                    >
                      See details
                    </button>
                    {showPromoInput ? (
                      <div>
                        <input
                          type="text"
                          value={promoCode}
                          onChange={handlePromoCodeChange}
                          placeholder="Enter Promo Code"
                        />
                        <button onClick={handleApplyPromoCode}>Apply</button>
                      </div>
                    ) : (
                      <button onClick={() => setShowPromoInput(true)}>
                        <div className="flex cursor-pointer items-center gap-1 text-blue-600 transition-colors hover:text-blue-400">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                            className="h-5 w-5"
                          >
                            <path
                              fillRule="evenodd"
                              d="M5.5 3A2.5 2.5 0 003 5.5v2.879a2.5 2.5 0 00.732 1.767l6.5 6.5a2.5 2.5 0 003.536 0l2.878-2.878a2.5 2.5 0 000-3.536l-6.5-6.5A2.5 2.5 0 008.38 3H5.5zM6 7a1 1 0 100-2 1 1 0 000 2z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                          <span>Add promotion code</span>
                        </div>
                      </button>
                    )}
                    {isModalOpen && (
                      <div className="details-modal fixed inset-0 left-0 top-40 z-[999] m-0 grid h-fit max-h-none w-fit max-w-none justify-center overscroll-contain p-0 sm:left-16 md:left-40">
                        <div className="modal-content rounded-2xl bg-slate-100 p-4 text-xs font-semibold text-black shadow-lg ring-1">
                          <span
                            className="close absolute right-3 top-3 cursor-pointer text-base"
                            onClick={handleCloseModal}
                          >
                            &times;
                          </span>
                          <h2>Plan Details</h2>
                          <ul>
                            {planDetails.map((detail, index) => (
                              <li key={index}>{detail}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="hidden lg:block">
                <div className="flex flex-col gap-4">
                  <div className="space-y-1">
                    <div>
                      <div>Subscribe to MADALGO Premium</div>
                      <div className="text-sm text-gray-500">
                        All MADLAGO Premium indicators + VIP Discord
                      </div>
                    </div>
                    <div className="flex items-baseline gap-2">
                      <div className="text-2xl font-bold">
                        {getFinalPrice()}
                      </div>
                      <div className="text-gray-500">
                        {planName !== "lifetime" && billingCycle}
                      </div>
                    </div>
                  </div>
                  <div className="flex items-baseline justify-between gap-2">
                    <div className="">Subtotal</div>
                    {getFinalPrice()}
                  </div>
                  <div>
                    {showPromoInput ? (
                      <div>
                        <input
                          type="text"
                          value={promoCode}
                          onChange={handlePromoCodeChange}
                          placeholder="Enter Promo Code"
                        />
                        <button onClick={handleApplyPromoCode}>Apply</button>
                      </div>
                    ) : (
                      <button onClick={() => setShowPromoInput(true)}>
                        <div className="flex cursor-pointer items-center gap-1 text-blue-600 transition-colors hover:text-blue-400">
                          <svg
                            className="h-5 w-5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox=" 0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M5.5 3A2.5 2.5 0 003 5.5v2.879a2.5 2.5 0 00.732 1.767l6.5 6.5a2.5 2.5 0 003.536 0l2.878-2.878a2.5 2.5 0 000-3.536l-6.5-6.5A2.5 2.5 0 008.38 3H5.5zM6 7a1 1 0 100-2 1 1 0 000 2z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                          <span>Add promotion code</span>
                        </div>
                      </button>
                    )}
                  </div>
                  <hr className="border-gray-800" />
                  <div className="flex items-baseline justify-between gap-2">
                    <div className="">Total due today</div>
                    <span className="font-bold">{getFinalPrice()}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="hidden lg:block">
              <div className="flex-none">
                <div className="flex items-center justify-center gap-5 text-sm text-gray-600">
                  <div className="flex items-center gap-1">
                    <svg
                      className=" -mt-1 h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M9.661 2.237a.531.531 0 01.678 0 11.947 11.947 0 007.078 2.749.5.5 0 01.479.425c.069.52.104 1.05.104 1.59 0 5.162-3.26 9.563-7.834 11.256a.48.48 0 01-.332 0C5.26 16.564 2 12.163 2 7c0-.538.035-1.069.104-1.589a.5.5 0 01.48-.425 11.947 11.947 0 007.077-2.75zm4.196 5.954a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span>Secured by</span>
                    <span className="-mt-1">
                      <svg
                        className="svg-header-logo w-24 [transition:all_.15s_ease-in-out]"
                        version="1.1"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="61"
                        height="15"
                        fill="currentColor"
                        viewBox="0 0 180 24"
                        xmlSpace="preserve"
                      >
                        <g>
                          <g>
                            <path d="M39.2,12.1H28.9V3.4h-3.4v16.9h3.4v-4.8h10.3V24h3.4V3.4h-3.4V12.1z" />
                            <path d="M39.2,3.4V0H28.9v3.4H39.2z" />
                          </g>
                          <g>
                            <path d="M123.8,19.9h4V4.1h-4V19.9z" />
                            <path d="M138.9,19.9h4V4.1h-4V19.9z" />
                            <path d="M139,4.1V0h-11.3v4.1H139z" />
                            <path d="M139,24v-4.1h-11.3V24H139z" />
                          </g>
                          <path d="M0.1,24h3.4V3.4H0.1V24z" />
                          <path d="M9,10.8h3.4V2.7H9V10.8z" />
                          <path d="M18,20.5h3.4V3.4H18V20.5z" />
                          <path d="M19.1,3.5V0H2.3v3.4L19.1,3.5L19.1,3.5z" />
                          <path d="M92.5,20.5V3.2h-3.4V24h12.1v-3.5H92.5z" />
                          <path d="M79,0h-3.8l-8.7,24h3.7l2-5.6h9.9l2,5.6h3.7L79.1,0.2L79,0z M73.3,15.3l3.9-11l3.8,11H73.3z" />
                          <path
                            d="M54.8,0h-5.2v6.9H53V3.4h1.7c4.7,0,8.6,3.8,8.6,8.6c0,4.7-3.8,8.6-8.6,8.6h-5.2V6.9h-3.3V24h8.6c6.6,0,12-5.4,12-12
	S61.4,0,54.8,0z"
                          />
                          <g>
                            <path d="M112.3,1.4c4.3,0,6.9,2,8.8,4.5l-2.8,1.6c-1.3-1.8-3.4-3.1-6-3.1" />
                            <path
                              d="M121.6,18.6c0,2-5.4,5.4-9.3,5.4c-6.6,0-11.9-4.8-11.9-12s5.3-12,11.9-12v3.1c-4.8,0-8.4,3.7-8.4,8.9s3.6,9,8.4,9
		c2.6,0,4.7-1.2,5.8-2.3"
                            />
                            <path d="M117.1,18.7v-2.8h-4.2v-3h7.1c0.3,0,0.5,0.2,0.5,0.5v5.3" />
                          </g>
                        </g>
                      </svg>
                    </span>
                  </div>
                  <div className="h-6 border-l"></div>
                  <div className="flex items-center justify-center gap-4">
                    <Link
                      className="text-blue-600 hover:text-blue-400"
                      to="https://www.madalgo.io"
                      onClick={handleLogOut}
                    >
                      Log Out
                    </Link>
                  </div>
                  <Link
                    className="text-blue-600 hover:text-blue-400"
                    to="mailto:support@madalgo.io"
                  >
                    Support
                  </Link>
                </div>
                {showError && (
                  <div className="error text-right text-red-600">
                    {errorMessage}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex-1 p-5 pt-0 sm:px-20 md:px-40 lg:p-20 lg:shadow-[0_0_15px_3px_rgba(0,0,0,0.05)] xl:pr-40 2xl:pr-60">
            <div className="space-y-5">
              <div className="space-y-5">
                <div className="flex gap-3">
                  <button
                    className="focus:outline-3 focus:outline-la-blue-200 w-full cursor-default space-y-1 rounded-3xl border border-blue-500 p-3 text-left font-medium text-blue-500 shadow outline outline-transparent transition-all ease-in-out"
                    onClick={() => setPaymentMethod("card")}
                  >
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                        className="h-5 w-5"
                      >
                        <path
                          fillRule="evenodd"
                          d="M2.5 4A1.5 1.5 0 001 5.5V6h18v-.5A1.5 1.5 0 0017.5 4h-15zM19 8.5H1v6A1.5 1.5 0 002.5 16h15a1.5 1.5 0 001.5-1.5v-6zM3 13.25a.75.75 0 01.75-.75h1.5a.75.75 0 010 1.5h-1.5a.75.75 0 01-.75-.75zm4.75-.75a.75.75 0 000 1.5h3.5a.75.75 0 000-1.5h-3.5z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </div>
                    <div>Card or Bank</div>
                  </button>
                  <button
                    className="focus:outline-3 focus:outline-la-blue-200 w-full cursor-default space-y-1 rounded-3xl border border-blue-500 p-3 text-left font-medium text-blue-500 shadow outline outline-transparent transition-all ease-in-out"
                    onClick={() => setPaymentMethod("paypal")}
                  >
                    <div>
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        role="img"
                        viewBox="0 0 24 24"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <title></title>
                        <path d="M7.076 21.337H2.47a.641.641 0 0 1-.633-.74L4.944.901C5.026.382 5.474 0 5.998 0h7.46c2.57 0 4.578.543 5.69 1.81 1.01 1.15 1.304 2.42 1.012 4.287-.023.143-.047.288-.077.437-.983 5.05-4.349 6.797-8.647 6.797h-2.19c-.524 0-.968.382-1.05.9l-1.12 7.106zm14.146-14.42a3.35 3.35 0 0 0-.607-.541c-.013.076-.026.175-.041.254-.93 4.778-4.005 7.201-9.138 7.201h-2.19a.563.563 0 0 0-.556.479l-1.187 7.527h-.506l-.24 1.516a.56.56 0 0 0 .554.647h3.882c.46 0 .85-.334.922-.788.06-.26.76-4.852.816-5.09a.932.932 0 0 1 .923-.788h.58c3.76 0 6.705-1.528 7.565-5.946.36-1.847.174-3.388-.777-4.471z"></path>
                      </svg>
                    </div>
                    <div>PayPal</div>
                  </button>
                </div>
                {paymentMethod === "card" && (
                  <>
                    <form id="payment-form">
                      <PaymentElement options={paymentElementOptions} />
                    </form>
                    <form id="address-input-form">
                      <AddressElement
                        options={{ mode: "billing" }}
                        onChange={handleAddressChange}
                      />
                    </form>
                  </>
                )}
                {paymentMethod === "paypal" && (
                  <PayPalSubscriptionButton promoCode={promoCode} />
                )}
                <div className="flex items-start gap-2 text-sm">
                  {paymentMethod === "paypal" ? (
                    <span>
                      By clicking one of the PayPal buttons above, you agree to
                      MADALO's{" "}
                      <Link
                        className="text-blue-600 hover:text-blue-400"
                        to="https://www.madalgo.io/legal/terms-of-service"
                      >
                        Terms of Service
                      </Link>
                      ,{" "}
                      <Link
                        className="text-blue-600 hover:text-blue-400"
                        to="https://www.madalgo.io/legal/privacy-policy"
                      >
                        Privacy Policy
                      </Link>
                      , and{" "}
                      <Link
                        className="text-blue-600 hover:text-blue-400"
                        to="https://www.madalgo.io/legal/disclaimer"
                      >
                        Disclaimer
                      </Link>
                      .
                    </span>
                  ) : (
                    <>
                      <input
                        id="agree"
                        className="my-[3px]"
                        type="checkbox"
                        checked={agreeChecked}
                        onChange={(e) => setAgreeChecked(e.target.checked)}
                      />
                      <label htmlFor="agree">
                        I agree to MADALO's{" "}
                        <Link
                          className="text-blue-600 hover:text-blue-400"
                          to="https://www.madalgo.io/legal/terms-of-service"
                        >
                          Terms of Service
                        </Link>
                        ,{" "}
                        <Link
                          className="text-blue-600 hover:text-blue-400"
                          to="https://www.madalgo.io/legal/privacy-policy"
                        >
                          Privacy Policy
                        </Link>
                        , and{" "}
                        <Link
                          className="text-blue-600 hover:text-blue-400"
                          to="https://www.madalgo.io/legal/disclaimer"
                        >
                          Disclaimer
                        </Link>
                        .
                      </label>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="sticky bottom-0 -mx-1 mt-0 space-y-2 bg-gradient-to-b from-transparent from-0% to-white to-[8px] px-1 py-5 lg:static lg:mx-0 lg:mt-5 lg:space-y-4 lg:bg-transparent lg:px-0 lg:py-0">
              <div className="block lg:hidden">
                <div className="flex items-baseline justify-between gap-2">
                  <div className="">Total due today</div>
                  <span className="font-bold">
                    {getPrice(planName, billingCycle)}
                  </span>
                </div>
              </div>
              {paymentMethod === "card" && (
                <button
                  className="focus:outline-3 focus:outline-la-blue-200 inline flex w-full items-center justify-center overflow-hidden truncate rounded-lg border border-blue-500 bg-blue-500 p-4 leading-5 text-white outline outline-transparent transition-all transition-colors ease-in-out hover:border-blue-600 hover:bg-blue-600 hover:text-neutral-100 disabled:border-blue-800 disabled:bg-blue-800 disabled:text-neutral-400"
                  disabled={!isFormValid || isLoading}
                  onClick={handlePayment}
                >
                  {isLoading ? <Spinner /> : "Subscribe"}
                </button>
              )}
            </div>
          </div>
          <div className="mb-10 block sm:mb-20 lg:hidden">
            <div className="flex-none">
              <div className="flex items-center justify-center gap-5 text-sm text-gray-600">
                <div className="flex items-center gap-1">
                  <svg
                    className=" -mt-1 h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M9.661 2.237a.531.531 0 01.678 0 11.947 11.947 0 007.078 2.749.5.5 0 01.479.425c.069.52.104 1.05.104 1.59 0 5.162-3.26 9.563-7.834 11.256a.48.48 0 01-.332 0C5.26 16.564 2 12.163 2 7c0-.538.035-1.069.104-1.589a.5.5 0 01.48-.425 11.947 11.947 0 007.077-2.75zm4.196 5.954a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span>Secured by</span>
                  <span className="-mt-1">
                    <svg
                      className="svg-header-logo w-24 [transition:all_.15s_ease-in-out]"
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="61"
                      height="15"
                      fill="currentColor"
                      viewBox="0 0 180 24"
                      xmlSpace="preserve"
                    >
                      <g>
                        <g>
                          <path d="M39.2,12.1H28.9V3.4h-3.4v16.9h3.4v-4.8h10.3V24h3.4V3.4h-3.4V12.1z" />
                          <path d="M39.2,3.4V0H28.9v3.4H39.2z" />
                        </g>
                        <g>
                          <path d="M123.8,19.9h4V4.1h-4V19.9z" />
                          <path d="M138.9,19.9h4V4.1h-4V19.9z" />
                          <path d="M139,4.1V0h-11.3v4.1H139z" />
                          <path d="M139,24v-4.1h-11.3V24H139z" />
                        </g>
                        <path d="M0.1,24h3.4V3.4H0.1V24z" />
                        <path d="M9,10.8h3.4V2.7H9V10.8z" />
                        <path d="M18,20.5h3.4V3.4H18V20.5z" />
                        <path d="M19.1,3.5V0H2.3v3.4L19.1,3.5L19.1,3.5z" />
                        <path d="M92.5,20.5V3.2h-3.4V24h12.1v-3.5H92.5z" />
                        <path d="M79,0h-3.8l-8.7,24h3.7l2-5.6h9.9l2,5.6h3.7L79.1,0.2L79,0z M73.3,15.3l3.9-11l3.8,11H73.3z" />
                        <path
                          d="M54.8,0h-5.2v6.9H53V3.4h1.7c4.7,0,8.6,3.8,8.6,8.6c0,4.7-3.8,8.6-8.6,8.6h-5.2V6.9h-3.3V24h8.6c6.6,0,12-5.4,12-12
	S61.4,0,54.8,0z"
                        />
                        <g>
                          <path d="M112.3,1.4c4.3,0,6.9,2,8.8,4.5l-2.8,1.6c-1.3-1.8-3.4-3.1-6-3.1" />
                          <path
                            d="M121.6,18.6c0,2-5.4,5.4-9.3,5.4c-6.6,0-11.9-4.8-11.9-12s5.3-12,11.9-12v3.1c-4.8,0-8.4,3.7-8.4,8.9s3.6,9,8.4,9
		c2.6,0,4.7-1.2,5.8-2.3"
                          />
                          <path d="M117.1,18.7v-2.8h-4.2v-3h7.1c0.3,0,0.5,0.2,0.5,0.5v5.3" />
                        </g>
                      </g>
                    </svg>
                  </span>
                </div>
                <div className="h-6 border-l"></div>
                <div className="flex items-center justify-center gap-4">
                  <Link
                    className="text-blue-600 hover:text-blue-400"
                    to="https://www.madalgo.io"
                    onClick={handleLogOut}
                  >
                    Log Out
                  </Link>
                </div>
                <Link
                  to="mailto:support@madalgo.io"
                  className="text-blue-600 hover:text-blue-400"
                >
                  Support
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showSuccessModal && (
        <div className="fixed inset-0 z-[999] flex items-center justify-center bg-black bg-opacity-50">
          <div className="modal-content relative rounded-lg bg-white p-6 shadow-lg">
            <span
              className="close-button absolute -top-0.5 right-1 cursor-pointer"
              onClick={handleRedirect}
            >
              ×
            </span>
            <p className="mb-4 text-center text-xl font-bold">
              Thank you for Subscribing!
            </p>
            <p className="mb-2">
              Join the{" "}
              <a
                href="https://www.discord.gg/urfhuyhTXd"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 underline"
              >
                Discord
              </a>{" "}
              and message Cody - MADALGO to gain access.
            </p>
            <p>
              Click{" "}
              <a
                href="https://www.tradingview.com/u/MADALGO/#published-scripts"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 underline"
              >
                here
              </a>{" "}
              to view our premium scripts on TradingView.
            </p>
          </div>
        </div>
      )}
      <div
        id="confetti-container"
        className="fixed bottom-0 left-0 right-0 top-0 z-[998] flex items-center justify-center"
        style={{ display: showSuccessModal ? "block" : "none" }}
      >
        <Confetti active={showSuccessModal} config={config} />
      </div>
    </>
  );
}

export default function Checkout() {
  const urlParams = new URLSearchParams(window.location.search);
  const [stripeCustomerId, setStripeCustomerId] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const [loading, setLoading] = useState(true);
  const planName = urlParams.get("planName");
  const billingCycle = urlParams.get("billingCycle");

  useEffect(() => {
    async function fetchStripeCustomerId() {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        const userID = currentUser.username;
        const response = await API.graphql(
          graphqlOperation(getSubscriptionPlanByUserId, { userID }),
        );

        const fetchedStripeCustomerId =
          response.data.listSubscriptionPlans.items[0]?.stripeCustomerId;

        if (!fetchedStripeCustomerId) {
          console.error("Stripe Customer ID not found for user:", userID);
          return;
        }

        setStripeCustomerId(fetchedStripeCustomerId);
      } catch (error) {
        console.error("Error fetching Stripe Customer ID:", error);
      }
    }
    fetchStripeCustomerId();
  }, []);

  useEffect(() => {
    async function createPaymentIntent() {
      if (!stripeCustomerId || !getPriceId(planName, billingCycle)) {
        return;
      }

      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        const jwtToken = currentUser.signInUserSession.idToken.jwtToken;
        const response = await fetch(
          process.env.REACT_APP_CREATE_PAYMENT_INTENT,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwtToken}`,
            },
            body: JSON.stringify({
              customerId: stripeCustomerId,
              amount: getPriceId(planName, billingCycle),
              currency: "usd",
            }),
          },
        );

        const data = await response.json();

        if (data.error) {
          console.error("Failed to create payment intent:", data.error);
          return;
        }

        if (!data.clientSecret) {
          console.error("Unexpected response from createPaymentIntent:", data);
          return;
        }

        setClientSecret(data.clientSecret);
      } catch (error) {
        console.error("Error creating payment intent:", error);
      }

      setLoading(false);
    }

    createPaymentIntent();
  }, [planName, billingCycle, stripeCustomerId]);

  function getInitialOptions() {
    const commonOptions = {
      "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
      "enable-funding": "paylater,card",
      "data-sdk-integration-source": "integrationbuilder_sc",
      vault: "true",
      intent: "subscription",
    };

    if (planName && planName.toLowerCase() === "lifetime") {
      return {
        "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
        "enable-funding": "paylater,card",
        "data-sdk-integration-source": "integrationbuilder_sc",
      };
    }

    return commonOptions;
  }

  const initialOptions = getInitialOptions();

  return (
    <>
      {loading ? (
        <div className="flex h-screen w-screen items-center justify-center">
          <ClipLoader color="#123abc" size={150} />
        </div>
      ) : (
        clientSecret && (
          <PayPalScriptProvider options={initialOptions}>
            <Elements
              stripe={stripePromise}
              options={{ clientSecret: clientSecret }}
            >
              <StripeForm
                planName={planName}
                billingCycle={billingCycle}
                stripeCustomerId={stripeCustomerId}
                clientSecret={clientSecret}
              />
            </Elements>
          </PayPalScriptProvider>
        )
      )}
    </>
  );
}
