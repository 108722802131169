import React from "react";
import { ContactUs } from "./ContactUs";
import { Link, useLocation } from "react-router-dom";
import { SvgIcon } from "./BrandSVG";

function Footer() {
  const location = useLocation();
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const isBlogPage = location.pathname.startsWith("/blog");
  return (
    <footer
      className="bg-opacity-100 text-start"
      aria-labelledby="footer-heading"
    >
      {!isBlogPage && <ContactUs />}
      <div
        id="footer"
        className={`mx-auto flex flex-col items-center px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32
                  ${isBlogPage ? "bg-white" : "bg-black"}
                  `}
      >
        <div className=" max-w-7xl xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8">
            <SvgIcon isBlogPage={isBlogPage} />
            <p
              className={`text-sm leading-6 ${
                isBlogPage ? " text-slate-800" : "text-gray-300"
              }`}
            >
              Revolutionizing the trading landscape with data-driven AI insight
              for superior decision-making.
            </p>
            <div className="flex gap-6">
              <a
                href="https://facebook.com/madalgo"
                target="_blank"
                rel="noreferrer"
                className={` ${
                  isBlogPage
                    ? " text-slate-800 hover:text-slate-400"
                    : "text-gray-500 hover:text-gray-400"
                }`}
              >
                <span className="absolute m-[-1px] h-[1px] w-[1px] overflow-hidden whitespace-nowrap p-0 [border-width:0] [clip:rect(0,0,0,0)]">
                  Facebook
                </span>
                <svg
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  className="h-6 w-6"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </a>
              <a
                href="https://instagram.com/_madalgo"
                target="_blank"
                rel="noreferrer"
                className={` ${
                  isBlogPage
                    ? " text-slate-800 hover:text-slate-400"
                    : "text-gray-500 hover:text-gray-400"
                }`}
              >
                <span className="absolute m-[-1px] h-[1px] w-[1px] overflow-hidden whitespace-nowrap p-0 [border-width:0] [clip:rect(0,0,0,0)]">
                  Instagram
                </span>
                <svg
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  className="h-6 w-6"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </a>
              <a
                href="https://twitter.com/_madalgo"
                target="_blank"
                rel="noreferrer"
                className={` ${
                  isBlogPage
                    ? " text-slate-800 hover:text-slate-400"
                    : "text-gray-500 hover:text-gray-400"
                }`}
              >
                <span className="absolute m-[-1px] h-[1px] w-[1px] overflow-hidden whitespace-nowrap p-0 [border-width:0] [clip:rect(0,0,0,0)]">
                  Twitter
                </span>
                <svg
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  className="h-6 w-6"
                  aria-hidden="true"
                >
                  <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"></path>
                </svg>
              </a>
              <a
                href="https://discord.gg/urfhuyhTXd"
                target="_blank"
                rel="noreferrer"
                className={` ${
                  isBlogPage
                    ? " text-slate-800 hover:text-slate-400"
                    : "text-gray-500 hover:text-gray-400"
                }`}
              >
                <span className="absolute m-[-1px] h-[1px] w-[1px] overflow-hidden whitespace-nowrap p-0 [border-width:0] [clip:rect(0,0,0,0)]">
                  Discord
                </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 127.14 96.36"
                  fill="currentColor"
                  className="h-6 w-6"
                  aria-hidden="true"
                >
                  <path d="M107.7,8.07A105.15,105.15,0,0,0,81.47,0a72.06,72.06,0,0,0-3.36,6.83A97.68,97.68,0,0,0,49,6.83,72.37,72.37,0,0,0,45.64,0,105.89,105.89,0,0,0,19.39,8.09C2.79,32.65-1.71,56.6.54,80.21h0A105.73,105.73,0,0,0,32.71,96.36,77.7,77.7,0,0,0,39.6,85.25a68.42,68.42,0,0,1-10.85-5.18c.91-.66,1.8-1.34,2.66-2a75.57,75.57,0,0,0,64.32,0c.87.71,1.76,1.39,2.66,2a68.68,68.68,0,0,1-10.87,5.19,77,77,0,0,0,6.89,11.1A105.25,105.25,0,0,0,126.6,80.22h0C129.24,52.84,122.09,29.11,107.7,8.07ZM42.45,65.69C36.18,65.69,31,60,31,53s5-12.74,11.43-12.74S54,46,53.89,53,48.84,65.69,42.45,65.69Zm42.24,0C78.41,65.69,73.25,60,73.25,53s5-12.74,11.44-12.74S96.23,46,96.12,53,91.08,65.69,84.69,65.69Z" />
                </svg>
              </a>
              <a
                href="https://youtube.com/_madalgo"
                target="_blank"
                rel="noreferrer"
                className={` ${
                  isBlogPage
                    ? " text-slate-800 hover:text-slate-400"
                    : "text-gray-500 hover:text-gray-400"
                }`}
              >
                <span className="absolute m-[-1px] h-[1px] w-[1px] overflow-hidden whitespace-nowrap p-0 [border-width:0] [clip:rect(0,0,0,0)]">
                  YouTube
                </span>
                <svg
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  className="h-6 w-6"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </a>
            </div>
          </div>
          <div className="mt-16 grid grid-cols-2 gap-8 xl:mt-0 xl:[grid-column:span_2_/_span_2]">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3
                  className={`text-sm font-semibold leading-6 ${
                    isBlogPage ? " text-black" : "text-white"
                  }`}
                >
                  Community
                </h3>
                <ul className="mt-2 lg:mt-6">
                  <li>
                    <Link
                      to="/community"
                      onClick={scrollToTop}
                      className={`text-sm leading-6 ${
                        isBlogPage
                          ? " text-slate-600 hover:text-black"
                          : "text-gray-300 hover:text-white"
                      }`}
                    >
                      Community
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/community/charts"
                      onClick={scrollToTop}
                      className={`text-sm leading-6 ${
                        isBlogPage
                          ? " text-slate-600 hover:text-black"
                          : "text-gray-300 hover:text-white"
                      }`}
                    >
                      Charts
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/community/earn-program"
                      onClick={scrollToTop}
                      className={`text-sm leading-6 ${
                        isBlogPage
                          ? " text-slate-600 hover:text-black"
                          : "text-gray-300 hover:text-white"
                      }`}
                    >
                      Earn
                    </Link>
                  </li>
                  {/* <li>
                    <Link
                      to="/community/advocate-program"
                      onClick={scrollToTop}
                      className={`text-sm leading-6 ${
                        isBlogPage
                          ? " text-slate-600 hover:text-black"
                          : "text-gray-300 hover:text-white"
                      }`}
                    >
                      Advocates
                    </Link>
                  </li> */}
                </ul>
              </div>
              <div className="mt-10 md:mt-0">
                <h3
                  className={`text-sm font-semibold leading-6 ${
                    isBlogPage ? " text-black" : "text-white"
                  }`}
                >
                  Resources
                </h3>
                <ul className="mt-2 lg:mt-6">
                  <li>
                    <Link
                      to="docs/tutorials"
                      onClick={scrollToTop}
                      className={`text-sm leading-6 ${
                        isBlogPage
                          ? " text-slate-600 hover:text-black"
                          : "text-gray-300 hover:text-white"
                      }`}
                    >
                      Tutorials
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/blog"
                      onClick={scrollToTop}
                      className={`text-sm leading-6 ${
                        isBlogPage
                          ? " text-slate-600 hover:text-black"
                          : "text-gray-300 hover:text-white"
                      }`}
                    >
                      Blog
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/docs"
                      onClick={scrollToTop}
                      className={`text-sm leading-6 ${
                        isBlogPage
                          ? " text-slate-600 hover:text-black"
                          : "text-gray-300 hover:text-white"
                      }`}
                    >
                      Docs
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/docs/changelog"
                      onClick={scrollToTop}
                      className={`text-sm leading-6 ${
                        isBlogPage
                          ? " text-slate-600 hover:text-black"
                          : "text-gray-300 hover:text-white"
                      }`}
                    >
                      Changelog
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3
                  className={`text-sm font-semibold leading-6 ${
                    isBlogPage ? " text-black" : "text-white"
                  }`}
                >
                  Company
                </h3>
                <ul className="mt-2 lg:mt-6">
                  <li>
                    <Link
                      to="/about-us"
                      onClick={scrollToTop}
                      className={`text-sm leading-6 ${
                        isBlogPage
                          ? " text-slate-600 hover:text-black"
                          : "text-gray-300 hover:text-white"
                      }`}
                    >
                      About
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/brand"
                      onClick={scrollToTop}
                      className={`text-sm leading-6 ${
                        isBlogPage
                          ? " text-slate-600 hover:text-black"
                          : "text-gray-300 hover:text-white"
                      }`}
                    >
                      Brand
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/careers"
                      onClick={scrollToTop}
                      className={`text-sm leading-6 ${
                        isBlogPage
                          ? " text-slate-600 hover:text-black"
                          : "text-gray-300 hover:text-white"
                      }`}
                    >
                      Careers
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/contact-us"
                      onClick={scrollToTop}
                      className={`text-sm leading-6 ${
                        isBlogPage
                          ? " text-slate-600 hover:text-black"
                          : "text-gray-300 hover:text-white"
                      }`}
                    >
                      Contact
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/partners"
                      onClick={scrollToTop}
                      className={`text-sm leading-6 ${
                        isBlogPage
                          ? " text-slate-600 hover:text-black"
                          : "text-gray-300 hover:text-white"
                      }`}
                    >
                      Partners
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/legal/disclaimer"
                      onClick={scrollToTop}
                      className={`text-sm leading-6 ${
                        isBlogPage
                          ? " text-slate-600 hover:text-black"
                          : "text-gray-300 hover:text-white"
                      }`}
                    >
                      Disclaimer
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/legal/privacy-policy"
                      onClick={scrollToTop}
                      className={`text-sm leading-6 ${
                        isBlogPage
                          ? " text-slate-600 hover:text-black"
                          : "text-gray-300 hover:text-white"
                      }`}
                    >
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/legal"
                      onClick={scrollToTop}
                      className={`text-sm leading-6 ${
                        isBlogPage
                          ? " text-slate-600 hover:text-black"
                          : "text-gray-300 hover:text-white"
                      }`}
                    >
                      Legal
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="mt-10 md:mt-0">
                <h3
                  className={`text-sm font-semibold leading-6 ${
                    isBlogPage ? " text-black" : "text-white"
                  }`}
                >
                  My Account
                </h3>
                <ul className="mt-2 lg:mt-6">
                  <li>
                    <Link
                      to="/manage-account"
                      onClick={scrollToTop}
                      className={`text-sm leading-6 ${
                        isBlogPage
                          ? " text-slate-600 hover:text-black"
                          : "text-gray-300 hover:text-white"
                      }`}
                    >
                      Manage
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`mt-8 text-sm leading-tight sm:max-w-xl lg:mt-20 xl:max-w-7xl ${
            isBlogPage ? " text-black" : "text-white"
          }`}
        >
          <p>
            Trading inherently involves risk and the majority of day traders may
            incur losses. MADALGO's products and services, as well as all
            content on this site, are for educational and informational purposes
            only.
            <br />
            <br />
            All presented content is hypothetical, curated post-factum to
            showcase our product, and should not be understood as financial
            advice. Investment decisions, including decisions to buy, sell,
            hold, or trade in securities, commodities, and other investments,
            carry risk and should be made based on advice from qualified
            financial professionals. Prior performance does not assure future
            outcomes.
            <br />
            <br />
            Hypothetical or Simulated performance results carry certain
            limitations. Unlike an actual performance record, simulated outcomes
            do not represent actual trading. Additionally, unexecuted trades
            mean the results may not accurately account for certain market
            factors, such as liquidity. Simulated trading programs are generally
            designed with hindsight. Therefore, no claims are made that any
            account is likely to achieve profits or losses similar to those
            displayed.
            <br />
            <br />
            Testimonials appearing on this website may not represent experiences
            of all clients or customers and does not assure future performance
            or success.
            <br />
            <br />
            MADALGO is a provider of technical analysis tools for charting
            platforms. We do not have access to our customers' personal trading
            accounts or brokerage statements. Consequently, we do not have any
            basis to claim that our customers perform better or worse than the
            average trader due to any content or tool we provide.
            <br />
            <br />
            Charts used on this site are provided by TradingView, on which our
            tools are built. TradingView® is a registered trademark of
            TradingView, Inc. Visit{" "}
            <a
              className="font-bold text-red-400 [text-decoration:underline]"
              href="https://tradingview.com"
            >
              www.TradingView.com
            </a>{" "}
            for more information.
            <br />
            <br />
            This does not represent our full Disclaimer. Please read our{" "}
            <a
              className="font-bold text-red-400 [text-decoration:underline]"
              href="/legal/disclaimer"
              target="_blank"
              onClick={scrollToTop}
            >
              full disclaimer.
            </a>
          </p>
        </div>
        <div className="mt-16 border-t pt-8 [border-color:rgb(255_255_255_/_0.1)] sm:mt-20 lg:mt-24">
          <p
            className={`text-xs leading-5 ${
              isBlogPage ? " text-slate-800" : "text-gray-400"
            }`}
          >
            © 2023 Digi Globe - MADALGO - All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
