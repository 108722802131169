import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useContext } from "react";
import ThemeContext from "./ThemeContext";
import menuItems from "./DocsMenuItems";

export default function DocsSideNav() {
  const { theme } = useContext(ThemeContext);
  const [openDropdown, setOpenDropdown] = useState(null);
  const location = useLocation();
  const currentPath = location.pathname;
  const isFaqPage = currentPath === "/docs/faq";
  const faqClassName = isFaqPage
    ? "bg-[rgba(164,159,180,.15)] [color:rgb(168_85_247)]"
    : "";

  const [selectedItem, setSelectedItem] = useState({
    main: null,
    sub: null,
    dropdown: null,
  });
  const getLinkClass = (isDropdownOpen, subIndex, theme) => {
    const baseClasses =
      "flex justify-between items-center rounded-[.25rem] py-[.375rem] pl-0 pr-2 text-sm font-semibold leading-tight transition-[background,_color] duration-200 [text-decoration:none] [transition-timing-function:cubic-bezier(.08,.52,.52,1)] [word-break:break-word]";

    let themeClasses;
    if (theme === "light") {
      themeClasses =
        isDropdownOpen === subIndex
          ? "text-slate-900 hover:bg-[rgba(0,0,0,.05)]"
          : "text-slate-900 hover:bg-[rgba(0,0,0,.05)]";
    } else {
      themeClasses =
        isDropdownOpen === subIndex
          ? "text-zinc-300 hover:bg-[rgba(255,255,255,0.05)]"
          : "text-zinc-300 hover:bg-[rgba(255,255,255,0.05)]";
    }

    return `${baseClasses} ${themeClasses}`;
  };

  useEffect(() => {
    document.body.className = theme; // 'light' or 'dark'
  }, [theme]);

  useEffect(() => {
    // Update selectedItem when currentPath changes
    let newSelectedItem = { main: null, sub: null, dropdown: null };

    menuItems.forEach((item, mainIndex) => {
      if (currentPath === item.link) {
        newSelectedItem = { main: mainIndex, sub: null, dropdown: null };
      } else if (item.subItems) {
        item.subItems.forEach((subItem, subIndex) => {
          if (currentPath === subItem.link) {
            newSelectedItem = {
              main: mainIndex,
              sub: subIndex,
              dropdown: null,
            };
          }
        });
      }
    });

    setSelectedItem(newSelectedItem);
  }, [currentPath]);

  return (
    <ul id="menu-list" className=" m-0 list-none pl-0">
      <li id="FAQ-link" className="group ml-[calc(.375rem*2)]">
        <Link
          id="faq"
          to="/docs/faq"
          className={`flex justify-between rounded-[.25rem] pl-0 pr-0 text-sm font-medium leading-normal transition-[background,_color] duration-200 [transition-timing-function:cubic-bezier(.08,.52,.52,1)] [word-break:break-word] group-hover:text-gray-500 ${
            theme === "light"
              ? "text-slate-900 group-hover:bg-[rgba(0,0,0,.05)]"
              : "text-zinc-300 group-hover:bg-[rgba(255,255,255,0.05)]"
          } ${faqClassName}`}
        >
          <span className="m-[3px_8px] ml-2">❓ FAQ</span>
        </Link>
      </li>
      {menuItems.map((item, index) => {
        const isCurrentMainItem = currentPath === item.link;
        const mainItemClassName = isCurrentMainItem
          ? "bg-[rgba(164,159,180,.15)] [color:rgb(168_85_247)]"
          : "";
        return (
          <li key={index} id={`item-category-${index}`} className="mt-1">
            <div
              id="item-collapsible"
              className="relative flex flex-wrap rounded-[.25rem] transition-[background,_color] duration-200 [transition-timing-function:cubic-bezier(.08,.52,.52,1)]"
            >
              {index === 0 || index === 1 || index === menuItems.length - 1 ? (
                <div
                  className={`mt-4 flex flex-1 cursor-default justify-between rounded-[.25rem] py-[.375rem] pl-6 pr-4 text-xs font-bold uppercase leading-tight tracking-[1.2px]  transition-[background,_color] duration-200 [transition-timing-function:cubic-bezier(.08,.52,.52,1)] [text-decoration:none] ${
                    theme === "light" ? "text-slate-500" : " text-zinc-100"
                  }`}
                >
                  <span>
                    {item.icon} {item.label}
                  </span>
                </div>
              ) : (
                <Link
                  to={item.link}
                  id="menu-link"
                  onClick={() => {
                    setSelectedItem({
                      main: index,
                      sub: null,
                      dropdown: null,
                    });
                  }}
                  className={`mt-4 flex flex-1 cursor-pointer justify-between rounded-[.25rem] py-[.375rem] pl-6 pr-4 text-xs font-bold uppercase leading-tight tracking-[1.2px] transition-[background,_color] duration-200 [transition-timing-function:cubic-bezier(.08,.52,.52,1)] [text-decoration:none] hover:text-gray-500 ${
                    theme === "light"
                      ? "text-slate-900 hover:bg-[rgba(0,0,0,.05)]"
                      : " text-zinc-300 hover:bg-[rgba(255,255,255,0.05)]"
                  } ${mainItemClassName}`}
                >
                  <span>
                    {item.icon} {item.label}
                  </span>
                </Link>
              )}
            </div>
            <ul
              id="inner-list"
              className="m-0 mt-1 list-none pl-4"
              style={{
                display: "block",
                overflow: "visible",
                height: "auto",
              }}
            >
              {item.subItems.map((subItem, subIndex) => {
                const isSubItemSelected =
                  (selectedItem.main === index &&
                    selectedItem.sub === subIndex) ||
                  (selectedItem.main === index &&
                    selectedItem.sub === subIndex &&
                    selectedItem.dropdown !== null);

                const isDropdownItemSelected =
                  selectedItem.main === index &&
                  selectedItem.sub === subIndex &&
                  selectedItem.dropdown !== null;

                const isCurrentSubItem = currentPath === subItem.link;

                const subItemClassName = `${getLinkClass(
                  openDropdown,
                  subIndex,
                  theme,
                )} 
                  ${
                    (!isCurrentMainItem && !isFaqPage && isCurrentSubItem) ||
                    (!isCurrentMainItem &&
                      !isFaqPage &&
                      selectedItem.main === index &&
                      selectedItem.sub === subIndex &&
                      selectedItem.dropdown === null &&
                      selectedItem.sub !== null)
                      ? "bg-[rgba(164,159,180,.15)] [color:rgb(168_85_247)]"
                      : ""
                  } 
                  ${
                    !isCurrentMainItem &&
                    !isFaqPage &&
                    isSubItemSelected &&
                    selectedItem.sub !== null
                      ? "[color:rgb(168_85_247)]"
                      : ""
                  }`;

                return (
                  <li key={subIndex} id={`item-link-${index}`} className="mt-1">
                    <Link
                      to={subItem.link}
                      onClick={(e) => {
                        if (e.target.closest("svg")) {
                          e.preventDefault();
                        } else {
                          setSelectedItem({
                            main: index,
                            sub: subIndex,
                            dropdown: null,
                          });
                        }
                      }}
                      className={subItemClassName}
                    >
                      <span className="ml-2">{subItem.text}</span>
                      {subItem.dropdown && (
                        <span
                          onClick={(e) => {
                            if (!isDropdownItemSelected) {
                              e.stopPropagation();
                            }
                            e.preventDefault();
                            if (openDropdown === subIndex) {
                              setOpenDropdown(null);
                            } else {
                              setOpenDropdown(subIndex);
                            }
                          }}
                        >
                          <svg
                            className={`transform rounded-lg bg-transparent transition-transform duration-300 ${
                              openDropdown === subIndex
                                ? "rotate-180"
                                : "rotate-90"
                            } ${
                              theme === "light"
                                ? "hover:bg-[rgba(0,0,0,.05)]"
                                : "hover:bg-[rgba(255,255,255,0.05)]"
                            }`}
                            xmlns="http://www.w3.org/2000/svg"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill={`${
                                theme === "light"
                                  ? "rgba(0,0,0,0.5)"
                                  : "rgba(255,255,255,0.5)"
                              }`}
                              d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z"
                            ></path>
                          </svg>
                        </span>
                      )}
                    </Link>
                    {subItem.dropdown && (
                      <ul
                        id="dropdown-list"
                        className=" m-0 mt-1 list-none pl-4"
                        style={{
                          display: "block",
                          overflow: "hidden",
                          maxHeight: openDropdown === subIndex ? "300px" : "0",
                          transition: "max-height 292ms ease-in-out",
                        }}
                      >
                        {subItem.dropdown.map((dropdownItem, dropdownIndex) => {
                          const isCurrentDropdownItem =
                            currentPath === dropdownItem.link;

                          const dropdownItemClassName = `${getLinkClass(
                            openDropdown,
                            subIndex,
                            theme,
                          )} 
                              ${
                                (!isCurrentMainItem &&
                                  !isFaqPage &&
                                  isCurrentDropdownItem) ||
                                (!isCurrentMainItem &&
                                  !isFaqPage &&
                                  selectedItem.main === index &&
                                  selectedItem.sub === subIndex &&
                                  selectedItem.dropdown === dropdownIndex)
                                  ? "bg-[rgba(164,159,180,.15)] [color:rgb(168_85_247)]"
                                  : ""
                              }`;

                          return (
                            <li key={dropdownIndex}>
                              <Link
                                to={dropdownItem.link}
                                onClick={() =>
                                  setSelectedItem({
                                    main: index,
                                    sub: subIndex,
                                    dropdown: dropdownIndex,
                                  })
                                }
                                className={dropdownItemClassName}
                              >
                                <span className="ml-2">
                                  {dropdownItem.text}
                                </span>
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </li>
                );
              })}
            </ul>
          </li>
        );
      })}
    </ul>
  );
}
