import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

const DropDownMenu = ({ dropdown, index, handleMenuClick }) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [isHoveredOrOpen, setIsHoveredOrOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const dropdownRef = useRef(null);
  const dropdownMenuRef = useRef(null);

  const toggleDropdown = () => {
    setOpenDropdown(!openDropdown);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        dropdownMenuRef.current &&
        !dropdownMenuRef.current.contains(event.target)
      ) {
        setIsHoveredOrOpen(false);
        setOpenDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setIsHoveredOrOpen(openDropdown);
  }, [openDropdown]);

  const { title, links, to } = dropdown;
  if (to) {
    return (
      <li
        key={index}
        className={`mb-2 list-none ${
          windowWidth <= 767
            ? "border-b border-gray-500 p-[.5em_0_1em] font-normal"
            : "font-medium"
        }`}
      >
        <Link to={to} className="">
          {title}
        </Link>
      </li>
    );
  }

  return (
    <li
      key={index}
      className={`mb-2 list-none ${
        windowWidth <= 767
          ? "border-b border-gray-500 p-[.5em_0_1em] font-normal"
          : "font-medium"
      }`}
    >
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        ref={dropdownRef}
        href="#"
        id={`dropdown-${index}-toggle`}
        className={`relative overflow-hidden bg-transparent text-white [text-decoration:none] [transition:all_.1s_ease-in-out] after:absolute after:right-0 after:top-[110%] after:h-0.5 after:w-[80%] after:bg-[#fff] after:opacity-0 after:[content:''] after:[transform:translateX(-50%)] after:[transition:all_.25s_ease-in-out] ${
          windowWidth <= 767
            ? "after:hidden"
            : windowWidth > 767 && windowWidth <= 1024 && isHoveredOrOpen
            ? "after:opacity-100 after:[transform:translateX(0)]"
            : ""
        }`}
        onClick={() => {
          toggleDropdown();
          setIsHoveredOrOpen(!openDropdown);
        }}
        onMouseEnter={() => setIsHoveredOrOpen(true)}
        onMouseLeave={() => setIsHoveredOrOpen(openDropdown)}
      >
        {title}
      </a>
      <ul
        ref={dropdownMenuRef}
        id={`dropdown-${index}`}
        className={`relative left-auto top-auto w-full p-0 py-1 text-left text-xs [transition:.25s_ease-in-out] md:absolute md:left-[40%] md:top-0 md:w-[160%] md:text-sm ${
          openDropdown && windowWidth <= 767
            ? "z-5 block"
            : openDropdown && windowWidth > 767 && windowWidth <= 1024
            ? "z-[5] opacity-100 [transform:translateX(0)]"
            : windowWidth > 767 && windowWidth <= 1024
            ? "z-[-1] opacity-0 [transform:translateX(-2%)]"
            : "hidden"
        }`}
      >
        {links.map((link, index) => (
          <li
            key={index}
            id={`menu-item-${index}`}
            className=" mt-4 list-none pb-1 font-normal"
          >
            <Link
              to={link.to}
              onClick={handleMenuClick}
              className={`relative overflow-hidden bg-transparent text-gray-400 [text-decoration:none] [transition:all_.1s_ease-in-out] after:absolute after:left-0 after:top-[110%] after:h-0.5 after:w-[80%] after:bg-white after:opacity-0 after:[content:''] after:[transform:translateX(-10%)] after:[transition:.25s_ease-in-out] md:text-white md:hover:after:opacity-100 md:hover:after:[transform:translateX(0)]
              ${
                !openDropdown || (windowWidth > 1024 && windowWidth <= 767)
                  ? "pointer-events-none"
                  : ""
              }`}
            >
              {link.name}
            </Link>
          </li>
        ))}
      </ul>
    </li>
  );
};

export default DropDownMenu;
