import { motion } from "framer-motion";
import Blur from "./Blur";
import images from "./images";
import { Link } from "react-router-dom";

export default function CommunityMain() {
  const list = {
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.2, // Increase the stagger to make it slower
        ease: [0.04, 0.62, 0.23, 0.98],
      },
    },
    hidden: {
      opacity: 0,
      transition: {
        when: "afterChildren",
        ease: [0.04, 0.62, 0.23, 0.98],
      },
    },
  };
  const item = {
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 2,
        ease: [0.04, 0.62, 0.23, 0.98],
      },
    },
    hidden: {
      opacity: 0,
      y: 50,
      transition: {
        duration: 2,
        ease: [0.04, 0.62, 0.23, 0.98],
      },
    },
  };

  return (
    <motion.div
      id="community-home"
      className="relative box-content flex min-h-screen w-full flex-col items-center pb-24"
    >
      <div
        id="blur"
        className=" absolute bottom-[0%] left-[0%] right-[0%] top-[0%] z-[-1] block"
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 2, delay: 0.4 }}
          id="gradient-blur-group-1"
          className="lg:w[85vw] absolute bottom-[55.8rem] left-auto right-[-6.6rem] top-auto h-[100vw] w-[95vw] opacity-60 filter-none lg:-right-80 lg:bottom-[40rem] lg:h-[85vw] xl:bottom-[45rem] xl:left-[5.7rem] xl:max-h-[100rem] xl:max-w-[100rem]"
        >
          <Blur />
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 2, delay: 0.8 }}
          id="gradient-blur-group-2"
          className="lg:w[85vw] absolute bottom-[10.8rem] left-auto right-[10.6rem] top-auto h-[100vw] w-[95vw] opacity-60 filter-none sm:bottom-14  md:-bottom-80 md:w-screen lg:-left-80 lg:h-[85vw] xl:left-[-5.7rem] xl:top-60 xl:max-h-[100rem] xl:max-w-[100rem]"
        >
          <Blur />
        </motion.div>
      </div>
      <section
        id="community-content"
        className=" relative z-[1] block min-h-[300px] txsm:min-h-[356px]"
      >
        <motion.div
          id="wrapper"
          className="flex h-full flex-col items-center justify-center px-7 pb-20 pt-40 [-moz-box-direction:normal] [-moz-box-orient:vertical] [-moz-box-pack:center] lg:px-16"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 2 }}
        >
          <motion.div
            id="inner"
            className="mb-40 max-w-xl "
            initial="hidden"
            animate="visible"
            variants={list}
          >
            <motion.h1
              className="mb-6 text-center text-xl font-bold leading-8 tracking-tight sm:text-2xl md:text-4xl lg:text-5xl"
              variants={item}
            >
              Community
            </motion.h1>
            <motion.p
              className="mb-2 text-center text-sm leading-6 md:mb-4 md:text-base md:leading-7"
              variants={item}
            >
              Join us on this exciting journey!
            </motion.p>
          </motion.div>
          <motion.div
            initial="hidden"
            animate="visible"
            variants={list}
            transition={{ delay: 0.3 }}
          >
            <Link className="group" to="charts">
              <motion.div id="charts-tab" variants={item}>
                <div
                  id="community-container"
                  className="w-full px-7 py-4 md:px-14 lg:py-8 xl:px-28"
                >
                  <div
                    id="community-body"
                    className="relative mx-auto flex w-full max-w-[50rem] flex-row items-center justify-between overflow-hidden rounded-3xl bg-[rgb(20,18,23,0.8)] p-5 transition-all duration-200 ease-in-out [box-shadow:1px_1px_2.125rem_1.25rem_rgba(0,_0,_0,_.05)] group-hover:scale-110 group-hover:bg-[rgba(148,122,218,0.47)] group-hover:shadow-lg group-hover:shadow-[rgba(81,65,122,0.47)] md:p-7 lg:rounded-[2.5rem] lg:p-12"
                  >
                    <div className="absolute left-0 top-0 p-4 opacity-0 transition-opacity duration-200 ease-in-out group-hover:opacity-100">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 transform cursor-pointer transition hover:scale-110 lg:h-10 lg:w-10"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M16 7L12 3M12 3L8 7M12 3V15M21 11V17.7992C21 18.9193 21 19.4794 20.782 19.9072C20.5903 20.2835 20.2843 20.5895 19.908 20.7812C19.4802 20.9992 18.9201 20.9992 17.8 20.9992H6.2C5.0799 20.9992 4.51984 20.9992 4.09202 20.7812C3.71569 20.5895 3.40973 20.2835 3.21799 19.9072C3 19.4794 3 18.9193 3 17.7992V11"
                        />
                      </svg>
                    </div>
                    <h2 className="mr-4 text-left text-lg uppercase sm:text-2xl md:text-4xl">
                      Charts
                    </h2>
                    <img
                      className={` relative -right-12 w-[45vw] rounded-3xl sm:-right-24 ${
                        window.innerWidth > 712 ? "max-w-xs" : ""
                      }`}
                      src={images[27]}
                      alt="Charts"
                    />
                  </div>
                </div>
              </motion.div>
            </Link>
            <Link className="group" to="earn-program">
              <motion.div id="earn-tab" variants={item}>
                <div
                  id="community-container"
                  className="w-full px-7 py-4 md:px-14 lg:py-8 xl:px-28"
                >
                  <div
                    id="community-body"
                    className="relative mx-auto flex max-w-[50rem] flex-row items-center justify-between overflow-hidden rounded-3xl bg-[rgb(20,18,23,0.8)] p-5 transition-all duration-200 ease-in-out [box-shadow:1px_1px_2.125rem_1.25rem_rgba(0,_0,_0,_.05)] group-hover:scale-110 group-hover:bg-[rgba(148,122,218,0.47)] group-hover:shadow-lg group-hover:shadow-[rgba(81,65,122,0.47)] md:p-7 lg:rounded-[2.5rem] lg:p-12"
                  >
                    <img
                      className="relative ml-[-6rem] w-[45vw] max-w-xs rounded-3xl"
                      src={images[28]}
                      alt="Earn"
                    />
                    <h2 className="text-right text-lg uppercase sm:text-2xl md:text-4xl">
                      Earn
                    </h2>
                    <div className="absolute right-0 top-0 p-4 opacity-0 transition-opacity duration-200 ease-in-out group-hover:opacity-100">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 transform cursor-pointer transition hover:scale-110 lg:h-10 lg:w-10"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M16 7L12 3M12 3L8 7M12 3V15M21 11V17.7992C21 18.9193 21 19.4794 20.782 19.9072C20.5903 20.2835 20.2843 20.5895 19.908 20.7812C19.4802 20.9992 18.9201 20.9992 17.8 20.9992H6.2C5.0799 20.9992 4.51984 20.9992 4.09202 20.7812C3.71569 20.5895 3.40973 20.2835 3.21799 19.9072C3 19.4794 3 18.9193 3 17.7992V11"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </motion.div>
            </Link>
            {/* <Link className="group" to="advocate-program">
              <motion.div id="advocates-tab" variants={item}>
                <div
                  id="community-container"
                  className="w-full px-7 py-4 md:px-14 lg:py-8 xl:px-28"
                >
                  <div
                    id="community-body"
                    className="relative mx-auto flex w-full max-w-[50rem] flex-row items-center justify-between overflow-hidden rounded-3xl bg-[rgb(20,18,23,0.8)] p-5 transition-all duration-200 ease-in-out [box-shadow:1px_1px_2.125rem_1.25rem_rgba(0,_0,_0,_.05)] group-hover:scale-110 group-hover:bg-[rgba(148,122,218,0.47)] group-hover:shadow-lg group-hover:shadow-[rgba(81,65,122,0.47)] md:p-7 lg:rounded-[2.5rem] lg:p-12"
                  >
                    <div className="absolute left-0 top-0 p-4 opacity-0 transition-opacity duration-200 ease-in-out group-hover:opacity-100">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 transform cursor-pointer transition hover:scale-110 lg:h-10 lg:w-10"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M16 7L12 3M12 3L8 7M12 3V15M21 11V17.7992C21 18.9193 21 19.4794 20.782 19.9072C20.5903 20.2835 20.2843 20.5895 19.908 20.7812C19.4802 20.9992 18.9201 20.9992 17.8 20.9992H6.2C5.0799 20.9992 4.51984 20.9992 4.09202 20.7812C3.71569 20.5895 3.40973 20.2835 3.21799 19.9072C3 19.4794 3 18.9193 3 17.7992V11"
                        />
                      </svg>
                    </div>
                    <h2 className="mr-4 text-left text-lg uppercase sm:text-2xl md:text-4xl">
                      Advocates
                    </h2>
                    <img
                      className={`relative -right-12 w-[45vw] rounded-3xl sm:-right-24 ${
                        window.innerWidth > 712 ? "max-w-xs" : ""
                      }`}
                      src={images[29]}
                      alt="Advocates"
                    />
                  </div>
                </div>
              </motion.div>
            </Link> */}
          </motion.div>
        </motion.div>
      </section>
    </motion.div>
  );
}
