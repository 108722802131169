import { useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "../Docs.module.css";
import { useTOC } from "../TOCContext";
import DocImages from "../DocImages";
import { InlineMath } from "react-katex";
import "katex/dist/katex.min.css";

const entries = [
  {
    displayText: "Popular Momentum Indicators",
    urlFragment: "popular-momentum-indicators",
    headingType: "h2",
    subEntries: [
      {
        displayText: "Scale-Neutral Momentum Indicators",
        urlFragment: "scale-neutral-momentum-indicators",
        headingType: "h3",
      },
    ],
  },
  {
    displayText: "Detecting Divergences",
    urlFragment: "detecting-divergences",
    headingType: "h2",
    subEntries: [],
  },
];
export { entries };

export default function DocsTechnicalAnalysisTechnicalIndicatorsMomentum() {
  const momentumEquation = "C_t - C_{t-p}";
  const scaleNeutralEquation = "\\text{Osc}_{t}(kC_t) = \\text{Osc}_{t}(C_t)";
  const { addToTOC, removeFromTOC } = useTOC();

  useEffect(() => {
    entries.forEach(addToTOC);
    return () => {
      entries.forEach((entry) => removeFromTOC(entry));
    };
  }, [addToTOC, removeFromTOC]);

  return (
    <>
      <h1 className={styles.h1}>Momentum Indicators</h1>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[46]}
          width={1757}
          height={791}
          className="mx-auto"
          alt="Momentum Indicators"
        ></img>
      </p>

      <p className={styles.p}>
        Momentum indicators, commonly referred to as oscillators, strip away the
        trend component from the price, spotlighting the cyclical aspect. They
        are typically leading indicators and are displayed in a distinct panel.
      </p>
      <h2
        id="popular-momentum-indicators"
        className={`scroll-mt-2 ${styles["h2"]}`}
      >
        Popular Momentum Indicators
        <Link
          to="#popular-momentum-indicators"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        A plethora of oscillators exist, each boasting unique features. The
        momentum oscillator stands out due to its simplicity; it's calculated
        by:
      </p>
      <p className={styles.p}>
        <div className="flex justify-center">
          <InlineMath math={momentumEquation} />
        </div>
      </p>
      <p className={styles.p}>
        Some momentum indicators, like the renowned Moving Average Convergence
        Divergence (MACD), TRIX, and Awesome Oscillator, employ smoothing
        techniques. Though they offer clearer insights, they can sometimes lag.
      </p>
      <figure className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[47]}
          width={1785}
          height={892}
          className="mx-auto"
          alt="MADALGO Matrix With Momentum Oscillator"
        ></img>
        <figcaption
          style={{
            textAlign: "center",
            fontSize: 14,
          }}
        >
          MADALGO Matrix (top) and momentum oscillator (bottom)
        </figcaption>
      </figure>

      <h3 id="scale-neutral-momentum-indicators" className={`${styles["h3"]}`}>
        Scale-Neutral Momentum Indicators
        <Link
          to="#scale-neutral-momentum-indicators"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>

      <p className={styles.p}>
        Certain momentum indicators are scale-neutral, meaning they maintain a
        consistent scale, irrespective of the price's original scale. For
        instance, an oscillator is scale-neutral when:
      </p>
      <p className={styles.p}>
        <div className="flex justify-center">
          <InlineMath math={scaleNeutralEquation} />
        </div>
      </p>
      <p className={styles.p}>
        where k is a positive integer. These oscillators, sometimes termed
        normalized, have fixed ranges. The Relative Strength Index (RSI) is a
        prime example of such an indicator.
      </p>
      <figure className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[48]}
          width={1629}
          height={877}
          className="mx-auto"
          alt="RSI 14"
        ></img>
        <figcaption
          style={{
            textAlign: "center",
            fontSize: 14,
          }}
        >
          RSI (bottom) with length equal to 14
        </figcaption>
      </figure>
      <p className={styles.p}>
        The Stochastic Oscillator, another scale-neutral tool, comprises a %K
        line and its smoother counterpart, %D. These scale-neutral indicators'
        merit is their ability to maintain fixed "overbought" and "oversold"
        levels, assisting traders in gauging market extremities.
      </p>
      <figure className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[49]}
          width={1512}
          height={765}
          className="mx-auto"
          alt="Stochastic Oscillator"
        ></img>
        <figcaption
          style={{
            textAlign: "center",
            fontSize: 14,
          }}
        >
          Stochastic oscillator %K in blue and %D in orange
        </figcaption>
      </figure>
      <p className={styles.p}>
        Other notable momentum indicators include the Commodity Channel Index
        (CCI), True Strength Index (TSI), and William %R.
      </p>
      <h2 id="detecting-divergences" className={`scroll-mt-2 ${styles["h2"]}`}>
        Detecting Divergences
        <Link
          to="#detecting-divergences"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[50]}
          width={782}
          height={825}
          className="mx-auto"
          alt="Types of Divergences"
        ></img>
      </p>
      <p className={styles.p}>
        Traders employ momentum indicators to pinpoint divergences between price
        and the oscillator. A divergence arises when price and the indicator
        move in contrasting directions. This discrepancy can be assessed by
        examining the peaks and troughs created by both the price and the
        indicator.
      </p>
    </>
  );
}
