import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import styles from "./Main.module.css";
import earth from "./videos/earth.webm";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import images from "./images";

const wrapperVariants = {
  hover: {
    width: "40rem",
    height: "28.4rem",
    transition: {
      // Your transition configuration
    },
  },
  initial: {
    transform:
      "translate3d(50px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)",
    width: "0rem",
    height: "0rem",
  },
};

const dropdownVariants = {
  hover: {
    opacity: 1,
    transform:
      "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)",
    display: "grid",
    transition: {
      // Your transition configuration
    },
  },
  initial: {
    opacity: 0,
    transform:
      "translate3d(-150px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)",
    display: "none",
  },
};

const arrowVariants = {
  initial: {
    opacity: 0,
    transform:
      "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)",
  },
  navResourcesHovered: {
    opacity: 1,
    transform:
      "translate3D(6px, -4px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)",
  },
  learnMoreHovered: {
    transform:
      "translate3D(9px, -2px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)",
  },
};

export default function DropDownCompany({ isAboutUsPage, isBlogPage }) {
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isNavResourcesHovered, setIsNavResourcesHovered] = useState(false);
  const [isLearnMoreHovered, setIsLearnMoreHovered] = useState(false);
  const location = useLocation();
  useEffect(() => {
    setDropdownVisible(false);
  }, [location]);

  return (
    <motion.div
      id="nav-menu-item-wrapper"
      className="relative flex flex-[0_auto]"
      onMouseEnter={() => setDropdownVisible(true)}
      onMouseLeave={() => setDropdownVisible(false)}
    >
      <div
        id="nav-link-company"
        className="group flex w-28 cursor-default items-center justify-center py-6"
      >
        <Link
          to="#"
          id="company-nav-link"
          className={` relative mr-0 flex max-w-full items-center justify-center rounded-xl p-[8px_12px] text-sm font-medium capitalize opacity-100 [text-decoration:none] ${
            isAboutUsPage || isBlogPage
              ? " text-black [transition:all_.1s_ease-in-out] after:absolute after:left-0 after:top-[80%] after:h-0.5 after:w-[80%] after:bg-black after:opacity-0 after:[content:''] after:[transform:translateX(-10%)] after:[transition:.25s_ease-in-out] hover:after:opacity-100 hover:after:[transform:translateX(0)]"
              : " text-white [transition:opacity_.2s,color_.2s] hover:bg-[rgb(20,_18,_23)] hover:text-cyan-500"
          }`}
        >
          Company
        </Link>
      </div>
      <motion.div
        id="nav-dropdown-wrapper"
        className=" absolute -left-[100px] top-full h-0 max-h-[calc(100vh_-_120px)_!important] w-0 overflow-hidden rounded-lg pt-2 will-change-transform"
        style={{
          transformStyle: "preserve-3d",
        }}
        variants={wrapperVariants}
        animate={isDropdownVisible ? "hover" : "initial"}
      >
        <div
          id="nav-drop-wrap"
          className="z-100 relative h-full w-full overflow-hidden rounded-lg bg-[#0a090d] [border:1px_solid_#141217]"
        >
          <motion.div
            id="company-drop-down"
            className=" absolute left-[0] h-[28.4rem] w-[40rem] rounded-xl bg-[#0a090d]  will-change-transform [grid-auto-columns:1fr] [grid-column-gap:0px] [grid-template-columns:1fr_1fr] [grid-template-rows:auto]"
            style={{
              transformStyle: "preserve-3d",
            }}
            variants={dropdownVariants}
            animate={isDropdownVisible ? "hover" : "initial"}
          >
            <Link
              to="/about-us"
              id="nav-company-left"
              className=" content group grid max-w-full auto-cols-fr grid-cols-1 grid-rows-[auto_auto] items-start justify-start justify-items-start gap-x-4 gap-y-4 bg-black p-8"
              onClick={() => setDropdownVisible(false)}
            >
              <motion.div
                onMouseEnter={() => setIsNavResourcesHovered(true)}
                onMouseLeave={() => setIsNavResourcesHovered(false)}
              >
                <div
                  id="left-content-wrapper"
                  className="mb-4 flex flex-col items-start [span:span_1/span_1/span_1/span_1]"
                >
                  <h3
                    id="content-heading"
                    className="my-0 text-sm font-medium leading-tight"
                  >
                    About Us
                  </h3>
                  <div id="padding" className=" p-2 pl-0 pr-0 pt-0"></div>
                  <p
                    id="about-us-p-text"
                    className=" my-0 text-xs font-medium text-gray-400"
                  >
                    Discover our story, mission, and the team behind our
                    cutting-edge trading tools.
                  </p>
                  <motion.div
                    id="learn-more-button-container"
                    className="group relative cursor-pointer rounded-[3rem] bg-transparent p-[.5rem_.75rem_.5rem_.125rem] pl-0 text-center text-sm font-medium capitalize text-[rgba(255,_255,_255,_0.7)] shadow-none transition-colors duration-200 ease-in-out will-change-transform [text-decoration:none] [background-image:none] hover:[filter:brightness(470%)grayscale()] group-hover:text-cyan-500"
                    onMouseEnter={() => setIsLearnMoreHovered(true)}
                    onMouseLeave={() => setIsLearnMoreHovered(false)}
                  >
                    <div id="learn-more-about-us" className="mr-0.5">
                      <div id="text" className="text-xs">
                        Learn More
                      </div>
                    </div>
                    <motion.div
                      id="arrow-container"
                      className="absolute bottom-auto left-auto right-0 top-2 block"
                      style={{
                        transformStyle: "preserve-3d",
                      }}
                      variants={arrowVariants}
                      animate={
                        isLearnMoreHovered
                          ? "learnMoreHovered"
                          : isNavResourcesHovered
                          ? "navResourcesHovered"
                          : "initial"
                      }
                    >
                      <div id="icon" className={`h-6 w-6` + styles["w-embed"]}>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="currentcolor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7 17L17 7M17 7V17M17 7H7"
                            stroke="rgb(6 182 212)"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                      </div>
                    </motion.div>
                  </motion.div>
                </div>
                <div
                  id="nav-animation-wrapper"
                  className=" relative w-full pt-[100%] mix-blend-lighten"
                >
                  <div
                    id="animation-embed"
                    className={
                      `absolute bottom-[0%] left-[0%] right-[0%] top-[0%] z-[1] [transform:translate(0)]` +
                      styles["w-embed"]
                    }
                  >
                    <div
                      id="w-background-video"
                      className="relative overflow-hidden"
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <video
                        id="data-globe"
                        className=" absolute z-[-100] m-auto h-full w-full object-cover [background-position:50%] [background-size:cover]"
                        autoPlay
                        loop
                        muted
                        poster={images[30]}
                      >
                        <source src={earth} data-wf-ignore="true" />
                      </video>
                    </div>
                  </div>
                </div>
              </motion.div>
            </Link>
            <div
              id="nav-company-right-container"
              className=" grid auto-cols-fr auto-rows-min grid-cols-1 grid-rows-[min-content] gap-y-2 bg-[#141217] p-4 [grid-area:span_1/span_1/span_1/span_1]"
            >
              <Link
                to="/careers"
                rel="noreferrer"
                id="nav-careers-element"
                className=" group grid w-full max-w-full cursor-pointer auto-cols-fr grid-cols-[1.5rem_1fr] grid-rows-[auto] gap-x-4 gap-y-1 rounded-xl bg-[#141217] p-[1rem_1.5rem_1rem_1rem] [align-content:start] [align-items:start] [transition:background-color_.2s_ease-in-out,color_.1s_linear] hover:bg-[#212026]"
                onClick={() => setDropdownVisible(false)}
              >
                <div
                  id="careers-icon"
                  className=" [grid-area:span_1/span_1/span_1/span 1] items-start"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlSpace="preserve"
                    id="Layer_1"
                    x="0"
                    y="0"
                    version="1.1"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#fff"
                    strokeWidth="1.5px"
                    className="[transition:stroke_.2s_ease-in-out,color_.1s_linear] group-hover:stroke-cyan-500"
                  >
                    <path d="M7.3 7.3H6.2c-.2 0-.4.2-.4.5v1.9c0 .2.2.4.4.4h1.1c.2 0 .4-.2.4-.4V7.8c0-.3-.1-.5-.4-.5z" />
                    <path d="M11.3 17.4H3.2c-.8 0-1.4-.6-1.4-1.4V5c0-.8.6-1.4 1.4-1.4h16.1c.8 0 1.4.6 1.4 1.4v5.5" />
                    <path d="M8.9 3.5V2.2c0-.5.4-1 1-1h2.7c.5 0 1 .4 1 1v1.5H8.9v-.2z" />
                    <path d="M7.8 8.7h6.9l.1-1.3h1.7v1.3h4.2" />
                    <path d="M5.9 8.7h-4" />
                    <path d="M16.6 10c-2.5 0-4.6 2.1-4.6 4.6 0 2.5 2.1 4.6 4.6 4.6 2.5 0 4.6-2.1 4.6-4.6 0-2.5-2-4.6-4.6-4.6zM23 21.2l-1.1-1.5c-.4-.6-1.2-.7-1.8-.3l-.1.1c-.6.4-.7 1.2-.3 1.8l1.1 1.5c.4.6 1.2.7 1.8.3l.1-.1c.6-.5.7-1.3.3-1.8zM20.1 19.4l-.8-1.1" />
                  </svg>
                </div>
                <div
                  id="careers-content"
                  className="grid auto-cols-fr grid-cols-1 grid-rows-[auto_auto] gap-x-4 gap-y-1 [grid-area:span_1/span_1/span_1/span_1]"
                >
                  <div id="careers-heading-wrap" className="flex items-start">
                    <h3
                      id="careers-heading"
                      className=" my-0 text-sm font-medium leading-tight [border-bottom:1px_solid_transparent]"
                    >
                      Careers
                    </h3>
                  </div>
                  <p id="careers-p" className=" my-0 text-xs text-gray-400">
                    Join us in revolutionizing the trading industry. Explore
                    opportunities to become part of our dynamic team.
                  </p>
                </div>
              </Link>
              <Link
                to="/contact-us"
                rel="noreferrer"
                id="nav-contact-element"
                className=" group grid w-full max-w-full cursor-pointer auto-cols-fr grid-cols-[1.5rem_1fr] grid-rows-[auto] gap-x-4 gap-y-1 rounded-xl bg-[#141217] p-[1rem_1.5rem_1rem_1rem] [align-content:start] [align-items:start] [transition:background-color_.2s_ease-in-out,color_.1s_linear] hover:bg-[#212026]"
                onClick={() => setDropdownVisible(false)}
              >
                <div
                  id="contact-icon"
                  className=" [grid-area:span_1/span_1/span_1/span 1] items-start [transition:text-color_.2s_ease-in-out,color_.1s_linear] group-hover:text-cyan-500"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlSpace="preserve"
                    id="Layer_1"
                    x="0"
                    y="0"
                    viewBox="0 0 24 24"
                    stroke="#fff"
                    strokeWidth="1.5"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    fill="none"
                    className="[transition:stroke_.2s_ease-in-out,color_.1s_linear] group-hover:stroke-cyan-500"
                  >
                    <path d="M17.9 10.2h4.7V1.6H10.1v8.3h3.1l-.2 3.4 3.1-2.8" />
                    <path d="M13.7 5.9h.3v.3h-.3zM16.3 5.9h.3v.3h-.3zM18.9 5.9h.3v.3h-.3z" />
                    <path d="M1.6 9.6C1.1 13.4 11.8 25 15.8 22c.8-.6 2.2-1.4 1.6-2.4-.8-1.4-2.7-2.9-3.5-2.9-.6 0-1.3 1.4-2.3 1.1-1-.4-4.7-4.4-5.1-5.4-.4-.9 1.3-1.2 1.3-1.9 0-.6-2.2-3.7-3.3-3.6-1.4.2-2.8 1.6-2.9 2.7z" />
                  </svg>
                </div>
                <div
                  id="contact-content"
                  className="grid auto-cols-fr grid-cols-1 grid-rows-[auto_auto] gap-x-4 gap-y-1 [grid-area:span_1/span_1/span_1/span_1]"
                >
                  <div id="contact-heading-wrap" className="flex items-start">
                    <h3
                      id="contact-heading"
                      className=" my-0 text-sm font-medium leading-tight [border-bottom:1px_solid_transparent]"
                    >
                      Contact
                    </h3>
                  </div>
                  <p id="contact-p" className=" my-0 text-xs text-gray-400">
                    Got questions or feedback? Get in touch with our team for
                    any inquiries or support you need.
                  </p>
                </div>
              </Link>
              <Link
                to="/partners"
                rel="noreferrer"
                id="nav-partners-element"
                className=" group grid w-full max-w-full cursor-pointer auto-cols-fr grid-cols-[1.5rem_1fr] grid-rows-[auto] gap-x-4 gap-y-1 rounded-xl bg-[#141217] p-[1rem_1.5rem_1rem_1rem] [align-content:start] [align-items:start] [transition:background-color_.2s_ease-in-out,color_.1s_linear] hover:bg-[#212026]"
                onClick={() => setDropdownVisible(false)}
              >
                <div
                  id="partners-icon"
                  className=" [grid-area:span_1/span_1/span_1/span 1] items-start"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlSpace="preserve"
                    id="Layer_1"
                    x="0"
                    y="0"
                    viewBox="0 0 24 24"
                    fill="none"
                    strokeWidth="1.5"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    stroke="#fff"
                    className="[transition:stroke_.2s_ease-in-out,color_.1s_linear] group-hover:stroke-cyan-500"
                  >
                    <path d="m.7 4.6 5 2L3 14.4l-2.2.2-.1-10zM23.3 5.7v9.8h-2.4l-2.8-7.9z" />
                    <path d="M3.3 13.3c2 1.3 3.4 2.6 4.3 3.5.9.9 2.7 2.1 3.7 2.5.5.2.9.2 1.2.2M18.5 8.7c-2-1-3.1-1.4-5.7-2-1.1-.3-5 2.8-4.6 4.1.1.4.6.8 1 .9.6.1 1.1 0 1.5-.6.7-1.1 1-.3 2-1l5.7 5.1M20.5 14.7l-1.9.6M10.5 7.6l-5.2.1M10.7 16.5c.4.7 1.3 1.8 2.5 2.5.4.2 1 .3 1.2.2.3-.1.7-.7.7-1M12.5 15.5c.1.1.9.9 1.4 1.3.5.5.9.9 1.2 1.1.7.5 1.9-.2 2-.8M14 14c.1.1 1.4 1.3 1.8 1.7.5.5.9.9 1.2 1.1.7.5 1.8.1 1.7-1.2" />
                  </svg>
                </div>
                <div
                  id="partners-content"
                  className="grid auto-cols-fr grid-cols-1 grid-rows-[auto_auto] gap-x-4 gap-y-1 [grid-area:span_1/span_1/span_1/span_1]"
                >
                  <div id="partners-heading-wrap" className="flex items-start">
                    <h3
                      id="partners-heading"
                      className=" my-0 text-sm font-medium leading-tight [border-bottom:1px_solid_transparent]"
                    >
                      Partners
                    </h3>
                  </div>
                  <p id="partners-p" className=" my-0 text-xs text-gray-400">
                    Interested in partnering with us? Learn how we can drive
                    mutual growth and revolutionize the trading industry
                    together.
                  </p>
                </div>
              </Link>
              <div
                id="social-footer"
                className=" flex flex-row content-center items-center justify-center border-t border-gray-300 pt-2"
              >
                <a
                  href="https://twitter.com/_madalgo"
                  target="_blank"
                  rel="noreferrer"
                  id="x-link"
                  className=" group grid max-w-full cursor-pointer auto-cols-fr grid-cols-[1.5rem_1fr] grid-rows-[auto] rounded-xl bg-[#141217] p-4 [align-content:start] [align-items:start] [transition:background-color_.2s_ease-in-out,color_.1s_linear] hover:bg-[#212026]"
                >
                  <svg
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    stroke="none"
                    strokeWidth="1.5"
                    fill="#fff"
                    className="[transition:fill_.2s_ease-in-out,color_.1s_linear] group-hover:fill-cyan-500"
                  >
                    <g>
                      <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path>
                    </g>
                  </svg>
                </a>
                <a
                  href="https://facebook.com/madalgo"
                  target="_blank"
                  rel="noreferrer"
                  className="group grid max-w-full cursor-pointer auto-cols-fr grid-cols-[1.5rem_1fr] grid-rows-[auto] rounded-xl bg-[#141217] p-4 [align-content:start] [align-items:start] [transition:background-color_.2s_ease-in-out,color_.1s_linear] hover:bg-[#212026]"
                >
                  <svg
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    className="h-6 w-6 [transition:fill_.2s_ease-in-out,color_.1s_linear] group-hover:fill-cyan-500"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </a>
                <a
                  href="https://instagram.com/_madalgo"
                  target="_blank"
                  rel="noreferrer"
                  className="group grid max-w-full cursor-pointer auto-cols-fr grid-cols-[1.5rem_1fr] grid-rows-[auto] rounded-xl bg-[#141217] p-4 [align-content:start] [align-items:start] [transition:background-color_.2s_ease-in-out,color_.1s_linear] hover:bg-[#212026]"
                >
                  <svg
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    className="h-6 w-6 [transition:fill_.2s_ease-in-out,color_.1s_linear] group-hover:fill-cyan-500"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </a>
                <a
                  href="https://youtube.com/_madalgo"
                  target="_blank"
                  rel="noreferrer"
                  className="group grid max-w-full cursor-pointer auto-cols-fr grid-cols-[1.5rem_1fr] grid-rows-[auto] rounded-xl bg-[#141217] p-4 [align-content:start] [align-items:start] [transition:background-color_.2s_ease-in-out,color_.1s_linear] hover:bg-[#212026]"
                >
                  <svg
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    className="h-6 w-6 [transition:fill_.2s_ease-in-out,color_.1s_linear] group-hover:fill-cyan-500"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </a>
              </div>
            </div>
          </motion.div>
        </div>
      </motion.div>
    </motion.div>
  );
}
