import React, { useState, useRef } from "react";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import { ShowPasswordIcon } from "./utils/ShowPasswordIcon";
import { HidePasswordIcon } from "./utils/HidePasswordIcon";

const ForgotPassword = () => {
  console.log("Received URL:", window.location.href);
  const [stage, setStage] = useState(0);

  const [verificationCode, setVerificationCode] = useState("");
  const [handleVerificationCode, setHandleVerificationCode] = useState("");

  const [newPassword, setNewPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [hasStartedTyping, setHasStartedTyping] = useState(false);
  const [showTooltip, setShowTooltip] = useState(true);
  const [isActive, setIsActive] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const containsAtLeast = useRef(null);

  const [isEmailActive, setIsEmailActive] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isEmailFilled, setIsEmailFilled] = useState(false);
  const [email, setEmail] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [showSuccess, setShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const urlParams = new URLSearchParams(window.location.search);
  const planName = urlParams.get("planName");
  const billingCycle = urlParams.get("billingCycle");
  console.log(
    `Retrieved URL parameters: planName = ${planName}, billingCycle = ${billingCycle}`,
  );

  const togglePassword = () => {
    setShowPassword(!showPassword);
    setShowTooltip(!showTooltip);
  };

  const handleFocus = () => setIsActive(true);
  const handleBlur = () => setIsActive(false);

  const validatePassword = (inputPassword) => {
    let conditionsMet = 0;

    // Lower case letters
    if (/[a-z]/.test(inputPassword)) conditionsMet++;

    // Upper case letters
    if (/[A-Z]/.test(inputPassword)) conditionsMet++;

    // Numbers
    if (/\d/.test(inputPassword)) conditionsMet++;

    // Special characters
    if (/[!@#$%^&*]/.test(inputPassword)) conditionsMet++;

    // At least 8 characters and at least 3 of the above conditions
    if (inputPassword.length >= 8 && conditionsMet >= 3) {
      return true;
    }

    return false;
  };
  const handleChange = (e) => {
    const inputPassword = e.target.value;
    let conditionsMet = 0;

    // Lower case letters
    if (/[a-z]/.test(inputPassword)) conditionsMet++;

    // Upper case letters
    if (/[A-Z]/.test(inputPassword)) conditionsMet++;

    // Numbers
    if (/\d/.test(inputPassword)) conditionsMet++;

    // Special characters
    if (/[!@#$%^&*]/.test(inputPassword)) conditionsMet++;
    // At least 8 characters
    toggleCheckClass(
      document.querySelector(
        "[data-error-code='password-policy-length-at-least']",
      ),
      inputPassword.length >= 8,
    );

    // Update individual conditions
    toggleCheckClass(
      document.querySelector("[data-error-code='password-policy-lower-case']"),
      /[a-z]/.test(inputPassword),
    );

    toggleCheckClass(
      document.querySelector("[data-error-code='password-policy-upper-case']"),
      /[A-Z]/.test(inputPassword),
    );

    toggleCheckClass(
      document.querySelector("[data-error-code='password-policy-numbers']"),
      /\d/.test(inputPassword),
    );

    toggleCheckClass(
      document.querySelector(
        "[data-error-code='password-policy-special-characters']",
      ),
      /[!@#$%^&*]/.test(inputPassword),
    );
    toggleCheckClass(containsAtLeast, conditionsMet >= 3);

    setHasStartedTyping(inputPassword !== "");
    setNewPassword(e.target.value);
    setIsFilled(e.target.value !== "");
    setErrorMessage("");
    setShowSuccess(false);
  };
  const toggleCheckClass = (element, condition) => {
    // If it's a React ref, get the current DOM node
    if (element && element.current) {
      element = element.current;
    }
    // Toggle the class based on the condition
    if (element) {
      if (condition) {
        element.classList.add("valid-check");
      } else {
        element.classList.remove("valid-check");
      }
    }
  };

  const handleEmailFocus = () => {
    setIsEmailActive(true);
  };
  const handleEmailBlur = () => {
    setIsEmailActive(false);
  };

  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setIsEmailFilled(e.target.value !== "");
    setIsEmailValid(validateEmail(e.target.value));

    // Optionally clear other errors
    setErrorMessage([]);
    setShowSuccess(false);
  };

  const handleRequestResetCode = async () => {
    if (!isEmailValid && isEmailFilled) {
      setShowError(true);
      setErrorMessage("Invalid email format");
      return;
    }
    setIsLoading(true);
    try {
      await Auth.forgotPassword(email);
      setStage(1);
    } catch (error) {
      setShowError(true);
      setErrorMessage(`Error requesting password reset`);
      console.error("Error requesting password reset", error);
    } finally {
      setIsLoading(false);
    }
  };

  const validateVerificationCode = (code) => {
    // Basic check: code should be 6 digits
    return /^\d{6}$/.test(code);
  };

  const handleResetPassword = async () => {
    if (!validateVerificationCode(verificationCode)) {
      setShowError(true);
      setErrorMessage("Invalid verification code format.");
      return;
    }
    if (!validatePassword(newPassword)) {
      setShowError(true);
      setErrorMessage("Password must meet all requirements");
      return;
    }
    setIsLoading(true);
    try {
      await Auth.forgotPasswordSubmit(email, verificationCode, newPassword);
      setShowSuccess(true);
      setSuccessMessage(
        "Password reset was successful... Redirecting back to Log In.",
      );
      const loginHref = `/u/signin?planName=${planName}&billingCycle=${billingCycle}`;

      window.location.href = loginHref;
    } catch (error) {
      setShowError(true);
      setErrorMessage(`Error resetting password: ${error.message}`);
      console.error("Error resetting password", error);
      // Handle error
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerificationCodeFocus = () => setHandleVerificationCode(true);
  const handleVerificationCodeBlur = () => setHandleVerificationCode(false);
  const handleVerificationCodeChange = (e) => {
    setVerificationCode(e.target.value);
    setHandleVerificationCode(e.target.value !== "");
    setErrorMessage("");
    setShowSuccess(false);
  };

  const signUpLink = `/u/signin?planName=${planName}&billingCycle=${billingCycle}`;

  return (
    <section className="hdf04n04 _prompt-box-outer hdf04n04">
      <div className="hd73bh7d hdn8236v">
        <div className="hd8hn83b">
          <header className="hd83b78n he9n38nn">
            <div
              title="MADALGO"
              id="c-p-logo"
              style={{
                width: "auto",
                height: "60",
                position: "static",
                margin: "auto",
                padding: "0",
                backgroundColor: "transparent",
                backgroundPosition: "center",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              }}
            ></div>
            <img
              className="he8937bd8 he8nds8n"
              id="p-l-c"
              src="https://madalgo.io/logo192.png"
              alt="MADALGO"
            ></img>
            <h1 className="h73bh90sd h38ubjd9">
              {stage === 0 ? "Forgot Your Password?" : "Reset Your Password"}
            </h1>
            <div className="h8dnb7bd h7bs6vls">
              <p className="h98sdbfdd h3md9ndd">
                {stage === 0
                  ? "If your email address is registered, you'll receive a password reset link shortly."
                  : "Please enter the verification code sent to your email."}
              </p>
            </div>
          </header>
          {stage === 0 ? (
            <div className="hs873bdf8 hd83b78n">
              <form className="c12db2c34 cf114ab0b">
                <input type="hidden" name="" value="" />
                <div className="c62f2b894 c3bcf3ed8">
                  <input type="hidden" name="strengthPolicy" value="good" />
                  <input
                    type="hidden"
                    name="complexityOptions.minLength"
                    value=""
                  />
                  <div className="c93ndsnfj cf832ns09">
                    <div className="input-wrapper">
                      <div
                        className="ca19662c8 c0749183e text c41b84477 cb6f7ea00"
                        data-action-text=""
                        data-alternate-action-text=""
                      >
                        <label
                          className="c9d37bb1f no-js ce8354906 c7aebad14"
                          htmlFor="email"
                        >
                          Email
                        </label>
                        <input
                          id="email"
                          className={`cbbf11f5c c1ad8da8e input ${
                            isEmailActive || isEmailFilled ? "hbdf8hsdn" : ""
                          }`}
                          inputMode="email"
                          name="email"
                          type="text"
                          value={email}
                          required={true}
                          autoComplete="username"
                          autoCapitalize="none"
                          onChange={handleEmailChange}
                          onFocus={handleEmailFocus}
                          onBlur={handleEmailBlur}
                        />
                        <div
                          className={`c9d37bb1f js-required ce8354906 c7aebad14 ${
                            isEmailActive || isEmailFilled ? "hbdf8hsdn" : ""
                          }`}
                          data-dynamic-label-for="email"
                          aria-hidden="true"
                        >
                          Email Address
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="c6d5cc3be">
                  <button
                    className="df893n9fn dh83b09c3 c5bf19ed3 c66fbaaf0 c732daa90"
                    disabled={!isEmailValid || isLoading}
                    onClick={handleRequestResetCode}
                  >
                    {isLoading ? <div className="spinner"></div> : "Continue"}
                  </button>
                </div>
                {showError && (
                  <div className="hd73666 error d9893h7">{errorMessage}</div>
                )}
                {showSuccess && (
                  <div className="hd73666 success d9893h7">
                    {successMessage}
                  </div>
                )}
              </form>
              <div className="ul-alternate-action  _alternate-action">
                <p className="hdsm94nd njd9n4398">
                  <Link
                    className="h2nd9ns8 h39dns89"
                    to={signUpLink}
                    aria-label=""
                  >
                    Back to MADALGO Account
                  </Link>
                </p>
              </div>
            </div>
          ) : (
            <div className="hs873bdf8">
              <div className="input-wrapper">
                <div
                  className={`ca19662c8 c0749183e password c41b84477 cb6f7ea00 ${
                    handleVerificationCode ? "hbdf8hsdn" : ""
                  }`}
                  data-action-text=""
                  data-alternate-action-text=""
                >
                  <label className="c9d37bb1f no-js ce8354906 c189ccd39">
                    Verification Code
                  </label>
                  <input
                    type="text"
                    id="verification-code-input"
                    className="cbbf11f5c c24e80294 input"
                    required={true}
                    value={verificationCode}
                    onChange={handleVerificationCodeChange}
                    onFocus={handleVerificationCodeFocus}
                    onBlur={handleVerificationCodeBlur}
                  />
                  <div
                    className={`c9d37bb1f js-required ce8354906 c189ccd39 ${
                      handleVerificationCode ? "hbdf8hsdn" : ""
                    }`}
                    data-dynamic-label-for="password"
                    aria-hidden="true"
                  >
                    Verification Code
                  </div>
                </div>
              </div>
              <div className="input-wrapper">
                <div
                  className={`ca19662c8 c0749183e password c41b84477 cb6f7ea00 ${
                    isActive || isFilled ? "hbdf8hsdn" : ""
                  }`}
                  data-action-text=""
                  data-alternate-action-text=""
                >
                  <label className="c9d37bb1f no-js ce8354906 c189ccd39">
                    New Password
                  </label>
                  <input
                    id="password"
                    className="cbbf11f5c c24e80294 input"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    required={true}
                    autoComplete="new-password"
                    autoCapitalize="none"
                    spellCheck="false"
                    value={newPassword}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <div
                    className={`c9d37bb1f js-required ce8354906 c189ccd39 ${
                      isActive || isFilled ? "hbdf8hsdn" : ""
                    }`}
                    data-dynamic-label-for="password"
                    aria-hidden="true"
                  >
                    New Password
                  </div>
                  <button
                    className="df893n9fn ulp-button-icon ddf87b3n9 _button-icon"
                    type="button"
                    onClick={togglePassword}
                  >
                    <span
                      className={`password-icon-tooltip show-password-tooltip ${
                        showTooltip ? "" : "hide"
                      }`}
                      aria-hidden="true"
                    >
                      Show password
                    </span>
                    <span
                      className={`password-icon-tooltip hide-password-tooltip ${
                        showTooltip ? "hide" : ""
                      }`}
                      aria-hidden="true"
                    >
                      Hide password
                    </span>
                    <span
                      className={`screen-reader-only password-toggle-label ${
                        showTooltip ? "" : "hide"
                      }`}
                      data-label="show-password"
                    >
                      Show password
                    </span>
                    <span
                      className={`screen-reader-only password-toggle-label ${
                        showTooltip ? "hide" : "unset"
                      }`}
                      data-label="hide-password"
                    >
                      Hide password
                    </span>
                    <span
                      className="h8G2R6oW password js-required"
                      aria-hidden="true"
                    >
                      {showTooltip ? (
                        <ShowPasswordIcon />
                      ) : (
                        <HidePasswordIcon />
                      )}
                    </span>
                  </button>
                </div>
              </div>
              {showError && (
                <div className="hd73666 error d9893h7">{errorMessage}</div>
              )}
              {showSuccess && (
                <div className="hd8hb3b success d9893h7">{successMessage}</div>
              )}
              <div
                className={`md0m39nfl no-arrow ndf98nm3 dkjd93nmn iksd93n _hide ${
                  hasStartedTyping ? "" : "hide"
                }`}
                aria-live="assertive"
                aria-atomic="true"
              >
                <div className="mhdf8n3j">
                  <ul className="mjr9sdn9">
                    <li className="hd8h39mk">
                      <div className="nd93hmas">
                        <span className="93nd8n3k">
                          Your password must contain:
                        </span>
                        <ul className="mdoi93n0">
                          <li
                            className="j93nsd8n"
                            data-error-code="password-policy-length-at-least"
                          >
                            <span className="caf0d2c4a">
                              At least 8 characters
                            </span>
                          </li>
                          <li
                            className="j93nsd8n"
                            data-error-code="password-policy-contains-at-least"
                            ref={containsAtLeast}
                          >
                            <span className="caf0d2c4a">
                              At least 3 of the following:
                            </span>
                            <div>
                              <ul>
                                <li
                                  className="j93nsd8n"
                                  data-error-code="password-policy-lower-case"
                                >
                                  <span className="caf0d2c4a">
                                    Lower case letters (a-z)
                                  </span>
                                </li>
                                <li
                                  className="j93nsd8n"
                                  data-error-code="password-policy-upper-case"
                                >
                                  <span className="caf0d2c4a">
                                    Upper case letters (A-Z)
                                  </span>
                                </li>
                                <li
                                  className="j93nsd8n"
                                  data-error-code="password-policy-numbers"
                                >
                                  <span className="caf0d2c4a">
                                    Numbers (0-9)
                                  </span>
                                </li>
                                <li
                                  className="j93nsd8n"
                                  data-error-code="password-policy-special-characters"
                                >
                                  <span className="caf0d2c4a">
                                    Special characters (e.g. !@#$%^&*)
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="c6d5cc3be">
                <button
                  className="df893n9fn dh83b09c3 c5bf19ed3 c66fbaaf0 c732daa90"
                  onClick={handleResetPassword}
                >
                  {isLoading ? (
                    <div className="spinner"></div>
                  ) : (
                    "Reset Password"
                  )}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default ForgotPassword;
