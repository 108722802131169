import React, { useState } from "react";
import { motion } from "framer-motion";
import styles from "./Main.module.css";
import DiscordLogoWithBlinkingCircle from "./DiscordLogoWithBlinkingCircle";
import { Link } from "react-router-dom";
import Blur from "./Blur";

import { Amplify } from "aws-amplify";
import { API } from "aws-amplify";

import awsExports from "../aws-exports";
Amplify.configure(awsExports);

export default function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [honeypot, setHoneypot] = useState("");
  const hasLink = /https?:\/\/|www\./i;
  const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [submissionStatus, setSubmissionStatus] = useState("idle");

  const resetForm = () => {
    setHoneypot("");
    setSubmissionStatus("idle");
  };

  const ArrowIcon = () => (
    <svg
      className="pt-1"
      width="100%"
      height="100%"
      viewBox="0 0 20 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 5H19M19 5L14.6667 1M19 5L14.6667 9"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </svg>
  );

  const Spinner = () => {
    return (
      <div className={`${styles.spinnerContainer}`}>
        <div className={`${styles.spinner}`}></div>
      </div>
    );
  };

  const GradientDefs = () => (
    <defs>
      <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="10%">
        <stop offset="0%" style={{ stopColor: "#be6ffc", stopOpacity: "1" }} />
        <stop
          offset="100%"
          style={{ stopColor: "#eb0cbd", stopOpacity: "1" }}
        />
      </linearGradient>
    </defs>
  );

  async function handleSubmit(e) {
    e.preventDefault();
    let hasError = false;
    let timer;

    if (!name) {
      setNameError("Name is required.");
      hasError = true;
    }

    if (!email) {
      setEmailError("Email is required.");
      hasError = true;
    } else if (!isValidEmail.test(email)) {
      setEmailError("Invalid email address.");
      hasError = true;
    }

    if (!message) {
      setMessageError("Message is required.");
      hasError = true;
    }

    if (hasLink.test(name)) {
      setNameError("Links are not allowed in name.");
      hasError = true;
    }

    if (hasLink.test(message)) {
      setMessageError("Links are not allowed in message.");
      hasError = true;
    }

    if (honeypot) {
      return;
    }

    if (hasError) {
      return;
    }

    setSubmissionStatus("loading");
    setNameError("");
    setEmailError("");
    setMessageError("");

    const data = {
      body: {
        name: name,
        email: email,
        message: message,
      },
    };

    try {
      const apiData = await API.post("madalgocontactapi", "/contact", data);
      console.log({ apiData });
      setSubmissionStatus("success");
      setEmail("");
      setName("");
      setMessage("");
      timer = setTimeout(() => {
        resetForm();
      }, 3000);
    } catch (error) {
      console.error(error);
      setSubmissionStatus("error");
    }
    return () => clearTimeout(timer);
  }

  return (
    <div
      id="main-contact-wrapper"
      className="flex min-h-screen flex-col items-center justify-center"
    >
      <div
        id="contact-us-wrapper"
        className="relative flex w-full flex-col pt-40 lg:flex-row-reverse lg:items-center"
      >
        <div
          id="blur"
          className=" absolute bottom-[0%] left-[0%] right-[0%] top-[0%] z-[-1] block"
        >
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 2, delay: 0.4 }}
            id="gradient-blur-group-1"
            className="lg:w[85vw] absolute bottom-[55.8rem] left-auto right-[-6.6rem] top-auto h-[100vw] w-[95vw] opacity-60 filter-none lg:-right-80 lg:bottom-[40rem] lg:h-[85vw] xl:bottom-[45rem] xl:left-[5.7rem] xl:max-h-[100rem] xl:max-w-[100rem]"
          >
            <Blur />
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 2, delay: 0.8 }}
            id="gradient-blur-group-2"
            className="lg:w[85vw] absolute bottom-[10.8rem] left-auto right-[10.6rem] top-auto h-[100vw] w-[95vw] opacity-60 filter-none sm:bottom-14  md:-bottom-80 md:w-screen lg:-left-80 lg:h-[85vw] xl:left-[-5.7rem] xl:top-60 xl:max-h-[100rem] xl:max-w-[100rem]"
          >
            <Blur />
          </motion.div>
        </div>
        <div className="flex w-full justify-center lg:ml-[-40rem] lg:justify-end lg:pr-40">
          <motion.div
            id="contact-us-form-container"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 2 }}
            className="w-11/12 p-6 md:w-6/12"
          >
            <h1
              id="contact-us-heading"
              className="mb-4 text-right text-2xl font-bold uppercase"
            >
              Contact Us!
            </h1>

            <form
              id="contact-us-form"
              className=""
              method="post"
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <div
                id="name-input-field"
                className="group relative z-[1] mb-8 w-full"
              >
                <input
                  type="text"
                  name="honeypot"
                  value={honeypot}
                  onChange={(e) => setHoneypot(e.target.value)}
                  style={{ display: "none" }} // Hide the field
                />

                <input
                  type="text"
                  required
                  maxLength="50"
                  minLength="2"
                  className={`w-full bg-transparent p-3 text-purple-500 caret-purple-500 transition-[all_.3s_easeInOut] [border:_1px_solid_hsla(0,0%,100%,.2)] placeholder:hidden placeholder:opacity-0 focus:border-purple-500 focus:outline-none group-hover:border-purple-500 ${
                    styles.inputField
                  } ${name ? styles.purpleBorder : ""}`}
                  placeholder="placeholder"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <label
                  className={`pointer-events-none absolute left-[var(--spacing-16)] top-[var(--spacing-1)] select-none p-[.2rem_.5rem] text-2xl font-light [background:var(--color-black)] [color:hsla(0,0%,100%,.5)] [transition:transform_.3s_ease,top_.3s_ease] ${
                    name ? styles.activeLabel : styles.inputLabel
                  }`}
                >
                  name
                </label>
                <p className="error-message text-red-500">{nameError}</p>
              </div>

              <div
                id="email-input-field"
                className="group relative z-[1] mb-8 w-full"
              >
                <input
                  type="email"
                  required
                  className={`w-full bg-transparent p-3 text-purple-500 caret-purple-500 transition-[all_.3s_easeInOut] [border:_1px_solid_hsla(0,0%,100%,.2)] placeholder:hidden placeholder:opacity-0 focus:border-purple-500 focus:outline-none group-hover:border-purple-500 ${
                    styles.inputField
                  } ${email ? styles.purpleBorder : ""}`}
                  placeholder="placeholder"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <label
                  className={`pointer-events-none absolute left-[var(--spacing-16)] top-[var(--spacing-1)] select-none p-[.2rem_.5rem] text-2xl font-light [background:var(--color-black)] [color:hsla(0,0%,100%,.5)] [transition:transform_.3s_ease,top_.3s_ease] ${
                    email ? styles.activeLabel : styles.inputLabel
                  }`}
                >
                  email
                </label>
                <p className="error-message text-red-500">{emailError}</p>
              </div>

              <div
                id="message-input-field"
                className="group relative z-[1] mb-8 w-full"
              >
                <textarea
                  rows="4"
                  required
                  maxLength="500"
                  minLength="2"
                  className={`w-full resize-none bg-transparent p-3 text-purple-500 caret-purple-500 transition-[all_.3s_easeInOut] [border:_1px_solid_hsla(0,0%,100%,.2)] placeholder:hidden placeholder:opacity-0 focus:border-purple-500 focus:outline-none group-hover:border-purple-500 ${
                    styles.inputField
                  } ${message ? styles.purpleBorder : ""}`}
                  placeholder="placeholder"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
                <label
                  className={`pointer-events-none absolute left-[var(--spacing-16)] top-[var(--spacing-1)] select-none p-[.2rem_.5rem] text-2xl font-light [background:var(--color-black)] [color:hsla(0,0%,100%,.5)] [transition:transform_.3s_ease,top_.3s_ease] ${
                    message ? styles.activeLabel : styles.inputLabel
                  }`}
                >
                  message
                </label>
                <p className="error-message text-red-500">{messageError}</p>
              </div>

              <button
                id="submit-button"
                className="group flex w-full items-center justify-center bg-[var(--color-purple)] p-[var(--spacing-24)] text-xl font-normal text-[var(--color-black)] transition-[all_.3s_easeInOut] [box-shadow:0_0_49px_rgba(214,162,255,.3)] hover:bg-[#c57efc]"
                disabled={submissionStatus === "loading"}
              >
                {submissionStatus === "idle" && (
                  <>
                    <span className="transition-[all_.3s_easeInOut] group-hover:scale-110">
                      Send
                    </span>
                    <span className="ml-2 h-[19.2px] w-4 transition-[all_.3s_easeInOut] group-hover:scale-110">
                      <ArrowIcon />
                    </span>
                  </>
                )}
                {submissionStatus === "loading" && <Spinner />}{" "}
                {submissionStatus === "success" && "Message Sent Successfully"}{" "}
                {submissionStatus === "error" && "Error Sending Message"}{" "}
              </button>
            </form>
          </motion.div>
        </div>
        <div className="flex w-full justify-center lg:pr-80">
          <motion.div
            id="social-container"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 2, delay: 0.3 }}
            className="mt-6 flex flex-col gap-1 p-4 pb-12 text-left md:pb-6"
          >
            <h1
              id="contact-us-heading"
              className="mb-4 text-left text-2xl font-bold uppercase"
            >
              Write Us!
            </h1>

            <p className="flex items-center">
              <span className="icon-placeholder">
                <svg
                  id="a"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 239.02 169.28"
                  width={20}
                  height={20}
                >
                  <path
                    d="m78.32,83.83L1.59,159.72l-1.5-7.49.04-68.57-.12-69.27,1.91-7.47m158.79,76.91l76.73,75.89,1.5-7.49-.04-68.57.12-69.27-1.91-7.47M82.55,91.21c-25.46,25.38-50.93,50.76-76.39,76.13l4.01,1.26c.77.21,3,.56,3.8.56l105.28.09,101.61.03c1.28,0,4.58-.02,5.83-.28l6.08-1.83c-12.94-12.61-76.42-75.95-76.42-75.95-2.43,2.69-12.68,12.55-17.71,16.06-4.6,3.21-10.42,5.38-19.14,5.38-9.15.04-15.26-2.39-18.19-4.51-10.68-7.73-14.76-12.93-18.75-16.93ZM232.76,1.94l-4.01-1.26c-.77-.21-3-.56-3.8-.56L119.67.03,18.06,0c-1.28,0-4.58.02-5.83.28l-6.08,1.83,98.62,98.1c5.28,4.33,10.38,5.1,14.79,5.19,4.33,0,9.6-.78,13.93-4.84L232.76,1.94Z"
                    fill="#fff"
                  />
                </svg>
              </span>
              <Link
                to="mailto:info@madalgo.io"
                className="group relative ml-[1.15rem] [transition:all_.1s_ease-in-out]"
              >
                <svg
                  width="216.45"
                  height="30"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <GradientDefs />
                  <text
                    className="fill-[rgba(10,9,13,0)] stroke-[url(#gradient)] text-xl font-semibold uppercase [transition:all_.3s_ease-in-out] group-hover:scale-110"
                    x="0"
                    y="22"
                  >
                    info@madalgo.io
                  </text>
                </svg>
                <svg
                  width="216.45"
                  height="30"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  className=" absolute left-0 top-0 w-0 [transition:all_.3s_ease-in-out] group-hover:w-full"
                >
                  <GradientDefs />
                  <text
                    className="fill-[url(#gradient)] stroke-[url(#gradient)] text-xl font-semibold uppercase [transition:all_.3s_ease-in-out] group-hover:scale-110"
                    x="0"
                    y="22"
                  >
                    info@madalgo.io
                  </text>
                </svg>
              </Link>
            </p>
            <p className="flex items-center">
              <DiscordLogoWithBlinkingCircle className="h-6 w-[1.66rem]" />
              <Link
                to="https://discord.gg/urfhuyhTXd"
                className="group relative ml-[.73rem] [transition:all_.1s_ease-in-out]"
              >
                <svg
                  width="100"
                  height="30"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <GradientDefs />
                  <text
                    className="fill-[rgba(10,9,13,0)] stroke-[url(#gradient)] text-xl font-semibold uppercase [transition:all_.3s_ease-in-out] group-hover:scale-110"
                    x="0"
                    y="22"
                  >
                    Discord
                  </text>
                </svg>
                <svg
                  width="100"
                  height="30"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  className=" absolute left-0 top-0 w-0 [transition:all_.3s_ease-in-out] group-hover:w-full"
                >
                  <GradientDefs />
                  <text
                    className="fill-[url(#gradient)] stroke-[url(#gradient)] text-xl font-semibold uppercase [transition:all_.3s_ease-in-out] group-hover:scale-110"
                    x="0"
                    y="22"
                  >
                    Discord
                  </text>
                </svg>
              </Link>
            </p>
            <p className="flex items-center">
              <span className="icon-placeholder">
                <svg
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  stroke="none"
                  strokeWidth="1.5"
                  fill="#fff"
                  width={20}
                  height={20}
                >
                  <g>
                    <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path>
                  </g>
                </svg>
              </span>
              <Link
                to="https://twitter.com/_madalgo"
                target="_blank"
                rel="noopener noreferrer"
                className="group relative ml-[1.15rem] border-b-2 border-transparent uppercase [transition:all_.1s_ease-in-out]"
              >
                <svg
                  width="144.367"
                  height="30"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <GradientDefs />
                  <text
                    className="fill-[rgba(10,9,13,0)] stroke-[url(#gradient)] text-xl font-semibold uppercase [transition:all_.3s_ease-in-out] group-hover:scale-110"
                    x="0"
                    y="22"
                  >
                    @_madalgo
                  </text>
                </svg>
                <svg
                  width="144.367"
                  height="30"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  className=" absolute left-0 top-0 w-0 [transition:all_.3s_ease-in-out] group-hover:w-full"
                >
                  <GradientDefs />
                  <text
                    className="fill-[url(#gradient)] stroke-[url(#gradient)] text-xl font-semibold uppercase [transition:all_.3s_ease-in-out] group-hover:scale-110"
                    x="0"
                    y="22"
                  >
                    @_madalgo
                  </text>
                </svg>
              </Link>
            </p>
            <p className="flex items-center">
              <span className="icon-placeholder">
                <svg
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  width={20}
                  height={20}
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </span>
              <Link
                to="https://instagram.com/_madalgo"
                target="_blank"
                rel="noopener noreferrer"
                className="group relative ml-[1.15rem] border-b-2 border-transparent uppercase [transition:all_.1s_ease-in-out]"
              >
                <svg
                  width="144.367"
                  height="30"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <GradientDefs />
                  <text
                    className="fill-[rgba(10,9,13,0)] stroke-[url(#gradient)] text-xl font-semibold uppercase [transition:all_.3s_ease-in-out] group-hover:scale-110"
                    x="0"
                    y="22"
                  >
                    @_madalgo
                  </text>
                </svg>
                <svg
                  width="144.367"
                  height="30"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  className=" absolute left-0 top-0 w-0 [transition:all_.3s_ease-in-out] group-hover:w-full"
                >
                  <GradientDefs />
                  <text
                    className="fill-[url(#gradient)] stroke-[url(#gradient)] text-xl font-semibold uppercase [transition:all_.3s_ease-in-out] group-hover:scale-110"
                    x="0"
                    y="22"
                  >
                    @_madalgo
                  </text>
                </svg>
              </Link>
            </p>
            <p className="flex items-center">
              <span className="icon-placeholder">
                <svg
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  width={20}
                  height={20}
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </span>
              <Link
                to="https://facebook.com/madalgo"
                target="_blank"
                rel="noopener noreferrer"
                className="group relative ml-[1.15rem] border-b-2 border-transparent uppercase [transition:all_.1s_ease-in-out]"
              >
                <svg
                  width="144.367"
                  height="30"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <GradientDefs />
                  <text
                    className="fill-[rgba(10,9,13,0)] stroke-[url(#gradient)] text-xl font-semibold uppercase [transition:all_.3s_ease-in-out] group-hover:scale-110"
                    x="0"
                    y="22"
                  >
                    @_madalgo
                  </text>
                </svg>
                <svg
                  width="144.367"
                  height="30"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  className=" absolute left-0 top-0 w-0 [transition:all_.3s_ease-in-out] group-hover:w-full"
                >
                  <GradientDefs />
                  <text
                    className="fill-[url(#gradient)] stroke-[url(#gradient)] text-xl font-semibold uppercase [transition:all_.3s_ease-in-out] group-hover:scale-110"
                    x="0"
                    y="22"
                  >
                    @_madalgo
                  </text>
                </svg>
              </Link>
            </p>
            <p className="flex items-center">
              <span className="icon-placeholder">
                <svg
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  width={20}
                  height={20}
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </span>
              <Link
                to="https://youtube.com/_madalgo"
                target="_blank"
                rel="noopener noreferrer"
                className="group relative ml-[1.15rem] border-b-2 border-transparent uppercase [transition:all_.1s_ease-in-out]"
              >
                <svg
                  width="144.367"
                  height="30"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <GradientDefs />
                  <text
                    className="fill-[rgba(10,9,13,0)] stroke-[url(#gradient)] text-xl font-semibold uppercase [transition:all_.3s_ease-in-out] group-hover:scale-110"
                    x="0"
                    y="22"
                  >
                    @_madalgo
                  </text>
                </svg>
                <svg
                  width="144.367"
                  height="30"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  className=" absolute left-0 top-0 w-0 [transition:all_.3s_ease-in-out] group-hover:w-full"
                >
                  <GradientDefs />
                  <text
                    className="fill-[url(#gradient)] stroke-[url(#gradient)] text-xl font-semibold uppercase [transition:all_.3s_ease-in-out] group-hover:scale-110"
                    x="0"
                    y="22"
                  >
                    @_madalgo
                  </text>
                </svg>
              </Link>
            </p>
          </motion.div>
        </div>
      </div>
    </div>
  );
}
