import { useState, useRef } from "react";

const CodeInput = ({ onCodeComplete }) => {
  const [code, setCode] = useState(new Array(6).fill(""));
  const inputsRef = useRef([]);

  const processInput = (e, slot) => {
    const newCode = [...code];
    newCode[slot] = e.target.value.toUpperCase();

    setCode(newCode);

    // Move focus forward on input
    if (e.target.value && slot < 5) {
      inputsRef.current[slot + 1].focus();
    }
  };

  const handleKeyUp = (e, slot) => {
    if (e.key === "Backspace" && slot > 0) {
      if (code[slot] === "") {
        const newCode = [...code];
        newCode[slot - 1] = "";
        setCode(newCode);
        inputsRef.current[slot - 1].focus();
      }
    }
  };

  const isCodeComplete = code.every((c) => c.trim() !== "");

  const handleSubmit = () => {
    if (isCodeComplete) {
      onCodeComplete(code.join(""));
    }
  };

  return (
    <div className="flex flex-col items-center space-y-4">
      <div className="flex justify-center space-x-2 pb-2">
        {code.map((num, idx) => (
          <input
            key={idx}
            type="text"
            inputMode="text"
            maxLength="1"
            className="form-input h-14 w-10 rounded border-2 border-gray-300 bg-transparent text-center focus:border-blue-500 focus:outline-none"
            value={num}
            onChange={(e) => processInput(e, idx)}
            onKeyUp={(e) => handleKeyUp(e, idx)}
            ref={(el) => (inputsRef.current[idx] = el)}
            autoComplete="off"
            autoCorrect="off"
            tabIndex={idx + 1}
          />
        ))}
      </div>
      <button
        className="w-full rounded bg-blue-500 px-4 py-2 text-white shadow disabled:opacity-50"
        disabled={!isCodeComplete}
        onClick={handleSubmit}
      >
        Enter Code to Continue
      </button>
    </div>
  );
};

export default CodeInput;
