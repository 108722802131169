import styles from "../Docs.module.css";
import DocImages from "../DocImages";

export default function DocsTechnicalAnalysis() {
  return (
    <>
      <h1 className={styles.h1}>Introduction</h1>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[32]}
          width={1226}
          height={539}
          alt="Technical Analysis Introduction"
        ></img>
      </p>
      <p className={styles.p}>
        Technical analysis (TA) encompasses a variety of tools and methods aimed
        at achieving profitable trading outcomes in the market. Contrasting with
        fundamental analysis (FA), which delves into a company's economic and
        financial circumstances, technical analysis evaluates the historical
        progression of a security's price. This evaluation uses patterns and
        instruments grounded in previous market data.
      </p>
      <p className={styles.p}>
        For technical analysis to be effective, certain presumptions must be
        made:
      </p>
      <ol className={styles.olBullet}>
        <li>
          Historical prices can be utilized to predict a security's future price
          movements (thereby challenging the random walk hypothesis).
        </li>
      </ol>
      <ol className={styles.olBullet}>
        <li>
          Prices operate in discernible trends, with present trends being shaped
          by their predecessors.
        </li>
      </ol>
      <p className={styles.p}>
        Subsequent sections will introduce diverse tools and strategies
        frequently employed by experts in technical analysis.
      </p>
    </>
  );
}
