import { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import styles from "../Docs.module.css";
import { useTOC } from "../TOCContext";
import DocImages from "../DocImages";
import ThemeContext from "../ThemeContext";

const entries = [
  {
    displayText: "Activation and Customization",
    urlFragment: "activation-and-customization",
    headingType: "h2",
    subEntries: [
      {
        displayText: "Activation",
        urlFragment: "activation",
        headingType: "ol",
      },
      {
        displayText: "Median Line",
        urlFragment: "median-line",
        headingType: "ol",
      },
      {
        displayText: "Cloud Colors",
        urlFragment: "cloud-colors",
        headingType: "ol",
      },
    ],
  },
  {
    displayText: "Calculation and Interpretation",
    urlFragment: "calculation-and-interpretation",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "Relationship with Profit Bands",
    urlFragment: "relationship-with-profit-bands",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "Significance and Application",
    urlFragment: "significance-and-application",
    headingType: "h2",
    subEntries: [
      {
        displayText: "Early Trend Detection",
        urlFragment: "early-trend-detection",
        headingType: "h3",
      },
      {
        displayText: "Validation Tool",
        urlFragment: "validation-tool",
        headingType: "h3",
      },
    ],
  },
];
export { entries };

export default function DocsMADALGONavigatorTrendShiftClouds() {
  const { theme } = useContext(ThemeContext);
  const { addToTOC, removeFromTOC } = useTOC();

  useEffect(() => {
    entries.forEach(addToTOC);
    return () => {
      entries.forEach((entry) => removeFromTOC(entry));
    };
  }, [addToTOC, removeFromTOC]);

  return (
    <>
      <h1 className={styles.h1}>TrendShift Clouds</h1>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[143]}
          width={712}
          height={591}
          className="mx-auto"
          alt="TrendShift Clouds"
        ></img>
      </p>
      <p className={styles.p}>
        The "TrendShift Clouds" feature in MADALGO Navigator introduces a
        dynamic tool designed to assist traders in identifying potential shifts
        in market trends. By plotting visually distinct cloud-like regions on
        your chart, this feature offers a unique perspective on potential trend
        changes and empowers informed trading decisions.
      </p>

      <h2
        id="activation-and-customization"
        className={`scroll-mt-2 ${styles["h2"]}`}
      >
        Activation and Customization
        <Link
          to="#activation-and-customization"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <ol id="activation" className={styles.olBullet}>
        <li>Activation</li>
      </ol>
      <p className={styles.p}>To utilize the TrendShift Clouds feature:</p>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[144]}
          width={610}
          height={674}
          className="mx-auto"
          alt="TrendShift Clouds Settings"
        ></img>
      </p>
      <ol className={styles.olTrend}>
        <li>Access the "Main Settings" of MADALGO Navigator.</li>
        <li>Locate the "Clouds" switch and toggle it to the ON position.</li>
      </ol>

      <ol id="median-line" className={styles.olBullet}>
        <li>Median Line</li>
      </ol>
      <p className={styles.p}>
        For additional context, consider activating the "Median" line within the
        cloud regions:
      </p>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[145]}
          width={334}
          height={216}
          className="mx-auto"
          alt="TrendShift Clouds Median Line"
        ></img>
      </p>
      <ol className={styles.olTrend}>
        <li>Enable the "Median" switch in the "Clouds" settings.</li>
      </ol>

      <ol id="styling" className={styles.olBullet}>
        <li>Cloud Colors</li>
      </ol>
      <p className={styles.p}>
        Personalize the appearance of the cloud regions:
      </p>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[146]}
          width={222}
          height={57}
          className="mx-auto"
          alt="TrendShift Clouds Colors"
        ></img>
      </p>
      <ol className={styles.olTrend}>
        <li>
          Adjust the "Cloud Colors" inputs to select your desired colors for the
          upper and lower cloud areas.
        </li>
      </ol>
      <div
        className={`
        info info-tip selection:bg-[#c4f6ff] selection:text-[#2c2c2c49]
        ${theme === "light" ? styles.infoTipInfo : styles.infoTipInfoDark} 
        `}
      >
        <div className="info-heading">
          <h5 className={styles.h5}>
            <span
              className={`
                ${theme === "light" ? styles.infoTip : styles.infoTipDark}
              `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 12 16"
              >
                <path
                  fillRule="evenodd"
                  d="M6.5 0C3.48 0 1 2.19 1 5c0 .92.55 2.25 1 3 1.34 2.25 1.78 2.78 2 4v1h5v-1c.22-1.22.66-1.75 2-4 .45-.75 1-2.08 1-3 0-2.81-2.48-5-5.5-5zm3.64 7.48c-.25.44-.47.8-.67 1.11-.86 1.41-1.25 2.06-1.45 3.23-.02.05-.02.11-.02.17H5c0-.06 0-.13-.02-.17-.2-1.17-.59-1.83-1.45-3.23-.2-.31-.42-.67-.67-1.11C2.44 6.78 2 5.65 2 5c0-2.2 2.02-4 4.5-4 1.22 0 2.36.42 3.22 1.19C10.55 2.94 11 3.94 11 5c0 .66-.44 1.78-.86 2.48zM4 14h5c-.23 1.14-1.3 2-2.5 2s-2.27-.86-2.5-2z"
                ></path>
              </svg>
            </span>
            Tip
          </h5>
        </div>
        <div className="warning-content">
          <p>
            Combine TrendShift Clouds with our other technical indicators that
            align with your trading strategy for a comprehensive view of market
            conditions.
          </p>
        </div>
      </div>

      <h2
        id="calculation-and-interpretation"
        className={`scroll-mt-2 ${styles["h2"]}`}
      >
        Calculation and Interpretation
        <Link
          to="#calculation-and-interpretation"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <figure className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[147]}
          width={749}
          height={489}
          className="mx-auto"
          alt="TrendShift Clouds Interpretation"
        ></img>
        <figcaption
          style={{
            textAlign: "center",
            fontSize: 12,
          }}
        >
          Trend Shift occurs as price reaches cloud
        </figcaption>
      </figure>
      <p className={styles.p}>
        The TrendShift Clouds are generated through a sophisticated calculation
        that involves multiple factors, including Keltner channels, Donchian
        channels, and Bollinger Bands. These components collectively contribute
        to the creation of cloud regions, which effectively highlight areas of
        potential trend shifts.
      </p>
      <h2
        id="relationship-with-profit-bands"
        className={`scroll-mt-2 ${styles["h2"]}`}
      >
        Relationship with Profit Bands
        <Link
          to="#relationship-with-profit-bands"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        It's worth noting that the TrendShift Clouds feature shares the same
        algorithm as the Profit Bands from MADALGO Pulse. This common algorithm
        underscores the effectiveness of this approach in identifying shifts in
        trends and potential profit opportunities.
      </p>

      <div
        className={`
        info info-tip selection:bg-[#c4f6ff] selection:text-[#2c2c2c49]
        ${theme === "light" ? styles.infoWarning : styles.infoWarningDark} 
        `}
      >
        <div className="info-heading">
          <h5 className={styles.h5}>
            <span
              className={`
                ${theme === "light" ? styles.infoAlert : styles.infoAlertDark}
              `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 12 16"
              >
                <path
                  fillRule="evenodd"
                  d="M7 2.3c3.14 0 5.7 2.56 5.7 5.7s-2.56 5.7-5.7 5.7A5.71 5.71 0 0 1 1.3 8c0-3.14 2.56-5.7 5.7-5.7zM7 1C3.14 1 0 4.14 0 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm1 3H6v5h2V4zm0 6H6v2h2v-2z"
                ></path>
              </svg>
            </span>
            Info
          </h5>
        </div>
        <div className="warning-content">
          <p>
            Adjust the input parameters, such as the "Length," to tailor the
            feature to various timeframes and adapt to changing market dynamics.
          </p>
        </div>
      </div>

      <h2
        id="significance-and-application"
        className={`scroll-mt-2 ${styles["h2"]}`}
      >
        Significance and Application
        <Link
          to="#significance-and-application"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <h3 id="early-trend-detection" className={`${styles["h3"]}`}>
        Early Trend Detection
        <Link
          to="#early-trend-detection"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[148]}
          width={653}
          height={476}
          className="mx-auto"
          alt="TrendShift Clouds Trend Detection"
        ></img>
      </p>
      <p className={styles.p}>
        One of the primary advantages of the TrendShift Clouds feature is its
        ability to aid in early trend detection. By visually marking potential
        shifts in trends, this tool provides traders with timely insights to
        inform their trading decisions.
      </p>

      <h3 id="validation-tool" className={`${styles["h3"]}`}>
        Validation Tool
        <Link
          to="#validation-tool"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[149]}
          width={291}
          height={572}
          className="mx-auto"
          alt="TrendShift Clouds Validation Tool"
        ></img>
      </p>
      <p className={styles.p}>
        TrendShift Clouds can be used as a validation tool to complement other
        technical indicators and analysis methods. The cloud regions offer an
        additional layer of confirmation to support your trading signals.
      </p>

      <div
        className={`warning warning-caution selection:bg-[#ffeec4] selection:text-[#4b4b4b] ${styles["alertWarning"]}`}
      >
        <div className="warning-heading">
          <h5 className={styles.h5}>
            <span className={styles.alertWarningIcon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M8.893 1.5c-.183-.31-.52-.5-.887-.5s-.703.19-.886.5L.138 13.499a.98.98 0 0 0 0 1.001c.193.31.53.501.886.501h13.964c.367 0 .704-.19.877-.5a1.03 1.03 0 0 0 .01-1.002L8.893 1.5zm.133 11.497H6.987v-2.003h2.039v2.003zm0-3.004H6.987V5.987h2.039v4.006z"
                ></path>
              </svg>
            </span>
            caution
          </h5>
        </div>
        <div className="warning-content">
          <p>
            While TrendShift Clouds provide valuable insights, remember that no
            single indicator guarantees accuracy. Always incorporate other
            analytical tools and methodologies to make well-informed trading
            choices.
          </p>
          <p>
            <em>
              Disclaimer: The information provided here is for educational
              purposes only and does not constitute financial advice. Trading
              involves risk, and it's essential to conduct thorough research and
              seek advice from financial professionals before making any trading
              decisions.
            </em>
          </p>
        </div>
      </div>
    </>
  );
}
