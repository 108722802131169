import { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import styles from "../Docs.module.css";
import { useTOC } from "../TOCContext";
import DocImages from "../DocImages";
import ThemeContext from "../ThemeContext";

const entries = [
  {
    displayText: "Activation and Customization",
    urlFragment: "activation-and-customization",
    headingType: "h2",
    subEntries: [
      {
        displayText: "Activation",
        urlFragment: "activation",
        headingType: "ol",
      },
      {
        displayText: "Customization",
        urlFragment: "customization",
        headingType: "ol",
      },
    ],
  },
  {
    displayText: "Calculation",
    urlFragment: "calculation",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "Integration with Divergence Finder and Gradient Filter",
    urlFragment: "integration-with-divergence-finder-and-gradient-filter",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "Interpreting the Oscillator",
    urlFragment: "interpreting-the-oscillator",
    headingType: "h2",
    subEntries: [],
  },
];
export { entries };

export default function DocsMADALGODynamicsInstitutionalMoneyFlow() {
  const { theme } = useContext(ThemeContext);
  const { addToTOC, removeFromTOC } = useTOC();

  useEffect(() => {
    entries.forEach(addToTOC);
    return () => {
      entries.forEach((entry) => removeFromTOC(entry));
    };
  }, [addToTOC, removeFromTOC]);

  return (
    <>
      <h1 className={styles.h1}>Institutional Money Flow (IMF)</h1>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[157]}
          width={665}
          height={568}
          className="mx-auto"
          alt="Institutional Money Flow"
        ></img>
      </p>
      <p className={styles.p}>
        The Institutional Money Flow (IMF) feature is designed to analyze the
        buying and selling activities of institutional investors, such as hedge
        funds and mutual funds. It calculates the net volume of these
        high-volume traders and uses it to generate a trend-following
        oscillator. This oscillator indicates overbought and oversold levels,
        giving traders an indication of potential reversal points.
      </p>

      <h2
        id="activation-and-customization"
        className={`scroll-mt-2 ${styles["h2"]}`}
      >
        Activation and Customization
        <Link
          to="#activation-and-customization"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <ol id="activation" className={styles.olBullet}>
        <li>Activation</li>
      </ol>
      <p className={styles.p}>
        To activate the True Range Fair Value Gaps (TR FVG) feature:
      </p>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[158]}
          width={446}
          height={215}
          className="mx-auto"
          alt="Institutional Money Flow Settings"
        ></img>
      </p>
      <ol className={styles.ol}>
        <li>Navigate to the "Main Settings" section of MADALGO Dynamics.</li>
        <li>Locate the "IMF" toggle switch and set it to the ON position.</li>
      </ol>

      <ol id="customization" className={styles.olBullet}>
        <li>Customization</li>
      </ol>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[159]}
          width={218}
          height={167}
          className="mx-auto"
          alt="Institutional Money Flow Customization"
        ></img>
      </p>
      <p className={styles.p}>Customize IMF using the following settings:</p>
      <ol className={styles.olBullet}>
        <li>
          <strong>Up Volume Color:</strong> Choose the color that represents
          upward volume movements.
        </li>
        <li>
          <strong>Down Volume Color:</strong> Select the color for downward
          volume movements.
        </li>
        <li>
          <strong>Gradient:</strong> Enable or disable the gradient display of
          the oscillator.
        </li>
        <li>
          <strong>Div Mode:</strong> Toggle to enable or disable the Gradient
          Intensity Filter and Divergence Finder.
        </li>
        <li>
          <strong>OB/OS Zones:</strong> Enable or disable Overbought (OB) and
          Oversold (OS) zones on the chart.
        </li>
      </ol>

      <div
        className={`
        info info-tip selection:bg-[#c4f6ff] selection:text-[#2c2c2c49]
        ${theme === "light" ? styles.infoTipInfo : styles.infoTipInfoDark} 
        `}
      >
        <div className="info-heading">
          <h5 className={styles.h5}>
            <span
              className={`
                ${theme === "light" ? styles.infoTip : styles.infoTipDark}
              `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 12 16"
              >
                <path
                  fillRule="evenodd"
                  d="M6.5 0C3.48 0 1 2.19 1 5c0 .92.55 2.25 1 3 1.34 2.25 1.78 2.78 2 4v1h5v-1c.22-1.22.66-1.75 2-4 .45-.75 1-2.08 1-3 0-2.81-2.48-5-5.5-5zm3.64 7.48c-.25.44-.47.8-.67 1.11-.86 1.41-1.25 2.06-1.45 3.23-.02.05-.02.11-.02.17H5c0-.06 0-.13-.02-.17-.2-1.17-.59-1.83-1.45-3.23-.2-.31-.42-.67-.67-1.11C2.44 6.78 2 5.65 2 5c0-2.2 2.02-4 4.5-4 1.22 0 2.36.42 3.22 1.19C10.55 2.94 11 3.94 11 5c0 .66-.44 1.78-.86 2.48zM4 14h5c-.23 1.14-1.3 2-2.5 2s-2.27-.86-2.5-2z"
                ></path>
              </svg>
            </span>
            Tip
          </h5>
        </div>
        <div className="warning-content">
          <p>
            Utilize the Divergence Finder and Gradient Filter to refine your
            trading strategy for more precise signals.
          </p>
        </div>
      </div>

      <h2 id="calculation" className={`scroll-mt-2 ${styles["h2"]}`}>
        Calculation
        <Link
          to="#calculation"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <figure className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[160]}
          width={1280}
          height={397}
          className="mx-auto"
          alt="Institutional Money Flow Calculation"
        ></img>
        <figcaption
          style={{
            textAlign: "center",
            fontSize: 12,
          }}
        >
          Oscillating between OB and OS
        </figcaption>
      </figure>
      <p className={styles.p}>
        IMF calculates a trend-following oscillator based on the net volume of
        institutional traders. This oscillator oscillates between overbought and
        oversold levels, indicating potential reversal points.
      </p>

      <h2
        id="integration-with-divergence-finder-and-gradient-filter"
        className={`scroll-mt-2 ${styles["h2"]}`}
      >
        Integration with Divergence Finder and Gradient Filter
        <Link
          to="#integration-with-divergence-finder-and-gradient-filter"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        IMF offers enhanced functionality with the "Div Mode" setting:
      </p>
      <ol className={styles.olBullet}>
        <li>
          The Gradient Intensity Filter reduces noise and enhances signal
          accuracy.
        </li>
        <li>
          The Divergence Finder identifies potential divergences between price
          and the oscillator, helping you spot trend reversals.
        </li>
      </ol>
      <div
        className={`
        info info-tip selection:bg-[#c4f6ff] selection:text-[#2c2c2c49]
        ${theme === "light" ? styles.infoWarning : styles.infoWarningDark} 
        `}
      >
        <div className="info-heading">
          <h5 className={styles.h5}>
            <span
              className={`
                ${theme === "light" ? styles.infoAlert : styles.infoAlertDark}
              `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 12 16"
              >
                <path
                  fillRule="evenodd"
                  d="M7 2.3c3.14 0 5.7 2.56 5.7 5.7s-2.56 5.7-5.7 5.7A5.71 5.71 0 0 1 1.3 8c0-3.14 2.56-5.7 5.7-5.7zM7 1C3.14 1 0 4.14 0 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm1 3H6v5h2V4zm0 6H6v2h2v-2z"
                ></path>
              </svg>
            </span>
            Info
          </h5>
        </div>
        <div className="warning-content">
          <p>
            Consider combining IMF with other technical indicators, such as
            trendlines or moving averages, to validate potential reversal
            signals.
          </p>
        </div>
      </div>

      <h2 id="practical-application" className={`scroll-mt-2 ${styles["h2"]}`}>
        Practical Application
        <Link
          to="#practical-application"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[161]}
          width={705}
          height={570}
          className="mx-auto"
          alt="Institutional Money Flow Practical Application"
        ></img>
      </p>
      <h3 id="interpreting-the-oscillator" className={`${styles["h3"]}`}>
        Interpreting the Oscillator
        <Link
          to="#interpreting-the-oscillator"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>

      <ol className={styles.olBullet}>
        <li>
          The oscillator values are scaled between -100 and +100 for ease of
          interpretation.
        </li>
        <li>
          Positive values suggest bullish pressure, while negative values
          indicate bearish pressure.
        </li>
        <p className={styles.p}>
          <img
            loading="lazy"
            src={DocImages[162]}
            width={182}
            height={577}
            className="mx-auto"
            alt="Institutional Money Flow Overbought Conditions"
          ></img>
        </p>
        <li>
          Overbought conditions are generally indicated when the oscillator is
          near or above +70, suggesting a potential downtrend reversal.
        </li>
        <p className={styles.p}>
          <img
            loading="lazy"
            src={DocImages[163]}
            width={274}
            height={551}
            className="mx-auto"
            alt="Institutional Money Flow Oversold Conditions"
          ></img>
        </p>
        <li>
          Oversold conditions are typically indicated when the oscillator is
          near or below -70, hinting at a potential uptrend reversal.
        </li>
      </ol>

      <div
        className={`warning warning-caution selection:bg-[#ffeec4] selection:text-[#4b4b4b] ${styles["alertWarning"]}`}
      >
        <div className="warning-heading">
          <h5 className={styles.h5}>
            <span className={styles.alertWarningIcon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M8.893 1.5c-.183-.31-.52-.5-.887-.5s-.703.19-.886.5L.138 13.499a.98.98 0 0 0 0 1.001c.193.31.53.501.886.501h13.964c.367 0 .704-.19.877-.5a1.03 1.03 0 0 0 .01-1.002L8.893 1.5zm.133 11.497H6.987v-2.003h2.039v2.003zm0-3.004H6.987V5.987h2.039v4.006z"
                ></path>
              </svg>
            </span>
            caution
          </h5>
        </div>
        <div className="warning-content">
          <p className={styles.p}>
            While IMF is a powerful tool, no indicator can guarantee accurate
            predictions all the time.
          </p>
          <p className={styles.p}>
            <em>
              Disclaimer: Please note that the information provided is intended
              for educational purposes only and should not be construed as
              financial advice. Trading involves risks, and it's essential to
              conduct thorough research and seek guidance from financial
              professionals before making trading decisions.
            </em>
          </p>
        </div>
      </div>
    </>
  );
}
