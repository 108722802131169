import { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import styles from "../Docs.module.css";
import { useTOC } from "../TOCContext";
import DocImages from "../DocImages";
import ThemeContext from "../ThemeContext";

const entries = [
  {
    displayText: "Features",
    urlFragment: "features",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "Main Settings",
    urlFragment: "main-settings",
    headingType: "h2",
    subEntries: [
      {
        displayText: "Shading and Signal Strength",
        urlFragment: "shading-and-signal-strength",
        headingType: "h3",
      },
      {
        displayText: "Signal Strength and Band Interaction",
        urlFragment: "signal-strength-and-band-interaction",
        headingType: "h3",
      },
    ],
  },
];
export { entries };

export default function DocsMADALGOPulseProfitBands() {
  const { theme } = useContext(ThemeContext);
  const { addToTOC, removeFromTOC } = useTOC();

  useEffect(() => {
    entries.forEach(addToTOC);
    return () => {
      entries.forEach((entry) => removeFromTOC(entry));
    };
  }, [addToTOC, removeFromTOC]);

  return (
    <>
      <h1 className={styles.h1}>Profit Bands</h1>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[81]}
          width={1601}
          height={1001}
          className="mx-auto"
          alt="Profit Bands"
        ></img>
      </p>

      <h2 id="features" className={`scroll-mt-2 ${styles["h2"]}`}>
        Features
        <Link
          to="#features"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        Profit Bands is a powerful indicator designed to provide traders with
        valuable insights into market trends and potential trade opportunities.
        It offers a range of customizable features and signals to enhance your
        trading decisions. Let's explore its key components and functionalities:
      </p>
      <ol className={styles.ol}>
        <li>
          Profit Bands Toggle - Activate or deactivate the Profit Bands
          visualization with the toggle provided. This allows you to quickly
          enable or disable the indicator's display based on your preferences.
        </li>
        <li>
          Trend Colors - Customize the colors for up and down trends. Toggle
          between these colors to visualize trends with ease.
        </li>
        <li>
          Buy / Sell Algorithms - Unlock the potential of four distinct buy/sell
          algorithms. Each algorithm generates signals based on specific market
          conditions, contributing to a well-rounded trading strategy.
        </li>
        <li>
          Customizable Signal Colors - Tailor the colors of buy and sell signals
          to match your preferred color scheme, ensuring clarity in signal
          identification.
        </li>
      </ol>

      <h2 id="main-settings" className={`scroll-mt-2 ${styles["h2"]}`}>
        Main Settings
        <Link
          to="#main-settings"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        The "Profit Bands v1" indicator incorporates various settings that
        impact its behavior and appearance. Fine-tune these settings to align
        with your trading style and requirements. Each feature has dedicated
        settings for optimal customization.
      </p>
      <div
        className={`
        info info-tip selection:bg-[#c4f6ff] selection:text-[#2c2c2c49]
        ${theme === "light" ? styles.infoWarning : styles.infoWarningDark} 
        `}
      >
        <div className="info-heading">
          <h5 className={styles.h5}>
            <span
              className={`
                ${theme === "light" ? styles.infoAlert : styles.infoAlertDark}
              `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 12 16"
              >
                <path
                  fillRule="evenodd"
                  d="M7 2.3c3.14 0 5.7 2.56 5.7 5.7s-2.56 5.7-5.7 5.7A5.71 5.71 0 0 1 1.3 8c0-3.14 2.56-5.7 5.7-5.7zM7 1C3.14 1 0 4.14 0 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm1 3H6v5h2V4zm0 6H6v2h2v-2z"
                ></path>
              </svg>
            </span>
            Info
          </h5>
        </div>
        <div className="warning-content">
          <p>
            Always remember that indicators are tools to assist your trading
            decisions, not guarantees of profits. Market conditions can change
            rapidly, so use signals in conjunction with your analysis.
          </p>
        </div>
      </div>

      <h3
        id="shading-and-signal-strength"
        className={`scroll-mt-2 ${styles["h3"]}`}
      >
        Shading and Signal Strength
        <Link
          to="#shading-and-signal-strength"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[82]}
          width={1221}
          height={956}
          className="mx-auto"
          alt="Profit Bands Shaded Areas"
        ></img>
      </p>
      <p className={styles.p}>
        One of the standout features of the Profit Bands v1 indicator is its
        ability to shade the area between the algorithmic bands and the price
        candles. This shading helps visualize overbought and oversold
        conditions, enabling traders to make informed decisions when the price
        action ventures outside the bands.
      </p>
      <div
        className={`
        info info-tip selection:bg-[#c4f6ff] selection:text-[#2c2c2c49]
        ${theme === "light" ? styles.infoTipInfo : styles.infoTipInfoDark} 
        `}
      >
        <div className="info-heading">
          <h5 className={styles.h5}>
            <span
              className={`
                ${theme === "light" ? styles.infoTip : styles.infoTipDark}
              `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 12 16"
              >
                <path
                  fillRule="evenodd"
                  d="M6.5 0C3.48 0 1 2.19 1 5c0 .92.55 2.25 1 3 1.34 2.25 1.78 2.78 2 4v1h5v-1c.22-1.22.66-1.75 2-4 .45-.75 1-2.08 1-3 0-2.81-2.48-5-5.5-5zm3.64 7.48c-.25.44-.47.8-.67 1.11-.86 1.41-1.25 2.06-1.45 3.23-.02.05-.02.11-.02.17H5c0-.06 0-.13-.02-.17-.2-1.17-.59-1.83-1.45-3.23-.2-.31-.42-.67-.67-1.11C2.44 6.78 2 5.65 2 5c0-2.2 2.02-4 4.5-4 1.22 0 2.36.42 3.22 1.19C10.55 2.94 11 3.94 11 5c0 .66-.44 1.78-.86 2.48zM4 14h5c-.23 1.14-1.3 2-2.5 2s-2.27-.86-2.5-2z"
                ></path>
              </svg>
            </span>
            Tip
          </h5>
        </div>
        <div className="warning-content">
          <p>
            Experiment with different combinations of algorithms and trend
            colors to find the setup that best suits your trading strategy.
          </p>
        </div>
      </div>

      <h3
        id="signal-strength-and-band-interaction"
        className={`scroll-mt-2 ${styles["h3"]}`}
      >
        Signal Strength and Band Interaction
        <Link
          to="#signal-strength-and-band-interaction"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[83]}
          width={1202}
          height={1011}
          className="mx-auto"
          alt="Profit Band Signals"
        ></img>
      </p>
      <p className={styles.p}>
        Profit Bands v1 generates buy and sell signals only when the price
        action extends beyond the custom algorithmic bands. This enhances the
        accuracy of signals, as they are backed by the additional confirmation
        of price action outside the bands.
      </p>
      <div
        className={`warning warning-caution selection:bg-[#ffeec4] selection:text-[#4b4b4b] ${styles["alertWarning"]}`}
      >
        <div className="warning-heading">
          <h5 className={styles.h5}>
            <span className={styles.alertWarningIcon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M8.893 1.5c-.183-.31-.52-.5-.887-.5s-.703.19-.886.5L.138 13.499a.98.98 0 0 0 0 1.001c.193.31.53.501.886.501h13.964c.367 0 .704-.19.877-.5a1.03 1.03 0 0 0 .01-1.002L8.893 1.5zm.133 11.497H6.987v-2.003h2.039v2.003zm0-3.004H6.987V5.987h2.039v4.006z"
                ></path>
              </svg>
            </span>
            caution
          </h5>
        </div>
        <div className="warning-content">
          <p>
            Avoid solely relying on indicator signals. Use them as part of a
            comprehensive trading approach, incorporating risk management and
            fundamental analysis.
          </p>
          <br />
          <p>
            <em>
              CFTC Rule 4.41 - Hypothetical or Simulated performance results
              have certain limitations, unlike an actual performance record,
              simulated results do not represent actual trading. Also, since the
              trades have not been executed, the results may have under-or-over
              compensated for the impact, if any, of certain market factors,
              such as lack of liquidity. Simulated trading programs in general
              are also subject to the fact that they are designed with the
              benefit of hindsight. No representation is being made that any
              account will or is likely to achieve profit or losses similar to
              those shown.
            </em>
          </p>
        </div>
      </div>
    </>
  );
}
