import { useState } from "react";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";

const CheckMark = (
  <svg
    viewBox="0 0 24 24"
    aria-label="Verified account"
    role="img"
    fill="#6366f1"
    className="h-5 w-5"
    data-testid="icon-verified"
  >
    <g>
      <path d="M20.396 11c-.018-.646-.215-1.275-.57-1.816-.354-.54-.852-.972-1.438-1.246.223-.607.27-1.264.14-1.897-.131-.634-.437-1.218-.882-1.687-.47-.445-1.053-.75-1.687-.882-.633-.13-1.29-.083-1.897.14-.273-.587-.704-1.086-1.245-1.44S11.647 1.62 11 1.604c-.646.017-1.273.213-1.813.568s-.969.854-1.24 1.44c-.608-.223-1.267-.272-1.902-.14-.635.13-1.22.436-1.69.882-.445.47-.749 1.055-.878 1.688-.13.633-.08 1.29.144 1.896-.587.274-1.087.705-1.443 1.245-.356.54-.555 1.17-.574 1.817.02.647.218 1.276.574 1.817.356.54.856.972 1.443 1.245-.224.606-.274 1.263-.144 1.896.13.634.433 1.218.877 1.688.47.443 1.054.747 1.687.878.633.132 1.29.084 1.897-.136.274.586.705 1.084 1.246 1.439.54.354 1.17.551 1.816.569.647-.016 1.276-.213 1.817-.567s.972-.854 1.245-1.44c.604.239 1.266.296 1.903.164.636-.132 1.22-.447 1.68-.907.46-.46.776-1.044.908-1.681s.075-1.299-.165-1.903c.586-.274 1.084-.705 1.439-1.246.354-.54.551-1.17.569-1.816zM9.662 14.85l-3.429-3.428 1.293-1.302 2.072 2.072 4.4-4.794 1.347 1.246z"></path>
    </g>
  </svg>
);
const AngleLeft = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="1em"
    fill="#0066ff"
    viewBox="0 0 448 512"
  >
    <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
  </svg>
);
const Profile = ({ user, onUpdateUser }) => {
  const [name, setName] = useState(user.name);
  const [imageUrl, setImageUrl] = useState(user.imageUrl);
  const [occupation, setOccupation] = useState(user.occupation);
  const [uploadingImage, setUploadingImage] = useState(false);
  const [age, setAge] = useState(user.age);
  const [location, setLocation] = useState(user.location);
  const url = process.env.REACT_APP_UPLOAD_URL;
  const MAX_FILE_SIZE = 1048576;

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        alert(`File size should not exceed ${MAX_FILE_SIZE / 1024 / 1024}MB`);
        return;
      }
      setUploadingImage(true);
      try {
        const uniqueFileName = `${Date.now()}-${file.name}`;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async () => {
          const base64 = reader.result.split(",")[1];
          const response = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              fileContent: base64,
              fileName: uniqueFileName,
              mimeType: file.type,
            }),
          });
          const data = await response.json();
          const updatedImageUrl = data.data.fileUrl;

          // Update Cognito and state as before
          const cognitoUser = await Auth.currentAuthenticatedUser();
          await Auth.updateUserAttributes(cognitoUser, {
            "custom:profile_image": updatedImageUrl,
          });
          onUpdateUser({ ...user, imageUrl: updatedImageUrl });
          setImageUrl(updatedImageUrl);
        };
      } catch (error) {
        console.error("Error uploading file:", error);
      } finally {
        setUploadingImage(false);
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setUploadingImage(true);

    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(cognitoUser, {
        "custom:name": name,
        "custom:occupation": occupation,
        "custom:age": age,
        "custom:location": location,
      });

      onUpdateUser({ ...user, name, imageUrl, occupation, age, location });
    } catch (error) {
      console.error("Error updating profile:", error);
    } finally {
      setUploadingImage(false);
    }
  };

  const ProfileCard = ({ user }) => {
    return (
      <div className="profile-card flex w-fit flex-row rounded bg-[#272727] p-4 shadow-lg ">
        <div className="profile-image mr-3">
          {user.imageUrl ? (
            <img
              src={user.imageUrl}
              alt="Profile"
              className="h-24 w-24 rounded-full"
            />
          ) : (
            <div className="h-24 w-24 rounded-full bg-gray-200" />
          )}
        </div>
        <div className="space-y-1">
          <h2 className="flex w-full flex-row text-xl font-bold">
            {user.name}
            {user.email_verified && <span className="ml-0.5">{CheckMark}</span>}
          </h2>
          <div className="text-sm text-gray-400">
            <p className="">
              {user.occupation}
              <br />
              {user.age}
              <br />
              {user.location}
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="mx-2">
      <div className="flex w-full items-center justify-end pb-2">
        <Link className="cursor-pointer text-sm text-[#0066ff]" to="/dashboard">
          <div className="flex flex-row items-center space-x-1">
            <span>{AngleLeft}</span> <p>Dashboard</p>
          </div>
        </Link>
      </div>
      <div className="mb-6 max-w-md space-y-4 rounded bg-[#171717] p-4 md:p-8">
        <ProfileCard user={user} onUpdateUser={onUpdateUser} />
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label
              htmlFor="image"
              className="block text-sm font-medium text-gray-300"
            >
              Profile Image
            </label>
            <input
              type="file"
              id="image"
              name="image"
              onChange={handleImageChange}
              className="block w-full text-sm text-gray-300 file:mr-4 file:rounded file:border-0 file:bg-gray-800 file:px-4 file:py-2 file:text-sm file:font-semibold file:text-gray-300 hover:file:bg-gray-700"
            />
          </div>
          <div>
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-300"
            >
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={name}
              onChange={handleNameChange}
              className="block w-full rounded-md border-gray-600 bg-gray-800 p-2 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
          <div>
            <label
              htmlFor="age"
              className="block text-sm font-medium text-gray-300"
            >
              Age
            </label>
            <input
              type="number"
              id="age"
              name="age"
              value={age}
              onChange={(e) => setAge(e.target.value)}
              className="block w-full rounded-md border-gray-600 bg-gray-800 p-2 text-white"
            />
          </div>
          <div>
            <label
              htmlFor="location"
              className="block text-sm font-medium text-gray-300"
            >
              Location
            </label>
            <input
              type="text"
              id="location"
              name="location"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              className="block w-full rounded-md border-gray-600 bg-gray-800 p-2 text-white"
            />
          </div>
          <div>
            <label
              htmlFor="occupation"
              className="block text-sm font-medium text-gray-300"
            >
              Occupation
            </label>
            <input
              type="text"
              id="occupation"
              name="occupation"
              value={occupation}
              onChange={(e) => setOccupation(e.target.value)}
              className="block w-full rounded-md border-gray-600 bg-gray-800 p-2 text-white"
            />
          </div>
          <button
            type="submit"
            className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            disabled={uploadingImage}
          >
            {uploadingImage ? "Updating..." : "Update Profile"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Profile;
