import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import styles from "./Main.module.css";
import stock from "./videos/stock.webm";
import { useLocation } from "react-router-dom";
import images from "./images";

const wrapperVariants = {
  hover: {
    width: "40rem",
    height: "28.4rem",
    transition: {
      // Your transition configuration
    },
  },
  initial: {
    transform:
      "translate3d(50px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)",
    width: "0rem",
    height: "0rem",
  },
};

const dropdownVariants = {
  hover: {
    opacity: 1,
    transform:
      "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)",
    display: "grid",
    transition: {
      // Your transition configuration
    },
  },
  initial: {
    opacity: 0,
    transform:
      "translate3d(-150px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)",
    display: "none",
  },
};

const arrowVariants = {
  initial: {
    opacity: 0,
    transform:
      "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)",
  },
  navResourcesHovered: {
    opacity: 1,
    transform:
      "translate3D(6px, -4px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)",
  },
  learnMoreHovered: {
    transform:
      "translate3D(9px, -2px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)",
  },
};

export default function DropDownResources({ isAboutUsPage, isBlogPage }) {
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isNavResourcesHovered, setIsNavResourcesHovered] = useState(false);
  const [isLearnMoreHovered, setIsLearnMoreHovered] = useState(false);
  const location = useLocation();
  useEffect(() => {
    setDropdownVisible(false);
  }, [location]);

  return (
    <motion.div
      id="nav-menu-item-wrapper"
      className="relative flex flex-[0_auto]"
      onMouseEnter={() => setDropdownVisible(true)}
      onMouseLeave={() => setDropdownVisible(false)}
    >
      <div
        id="nav-link-resources"
        className="group flex w-28 cursor-default items-center justify-center py-6"
      >
        <Link
          to="#"
          id="resources-nav-link"
          className={` relative mr-0 flex max-w-full items-center justify-center rounded-xl p-[8px_12px] text-sm font-medium capitalize opacity-100 [text-decoration:none] ${
            isAboutUsPage || isBlogPage
              ? " text-black [transition:all_.1s_ease-in-out] after:absolute after:left-0 after:top-[80%] after:h-0.5 after:w-[80%] after:bg-black after:opacity-0 after:[content:''] after:[transform:translateX(-10%)] after:[transition:.25s_ease-in-out] hover:after:opacity-100 hover:after:[transform:translateX(0)]"
              : " text-white [transition:opacity_.2s,color_.2s] hover:bg-[rgb(20,_18,_23)] hover:text-cyan-500"
          }`}
        >
          Resources
        </Link>
      </div>
      <motion.div
        id="nav-dropdown-wrapper"
        className=" absolute -left-[100px] top-full h-0 max-h-[calc(100vh_-_120px)_!important] w-0 overflow-hidden rounded-lg pt-2 will-change-transform"
        style={{
          transformStyle: "preserve-3d",
        }}
        variants={wrapperVariants}
        animate={isDropdownVisible ? "hover" : "initial"}
      >
        <div
          id="nav-drop-wrap"
          className="z-100 relative h-full w-full overflow-hidden rounded-lg bg-[#0a090d] [border:1px_solid_#141217]"
        >
          <motion.div
            id="resources-drop-down"
            className=" absolute left-[0] h-[28.4rem] w-[40rem] rounded-xl bg-[#0a090d]  will-change-transform [grid-auto-columns:1fr] [grid-column-gap:0px] [grid-template-columns:1fr_1fr] [grid-template-rows:auto]"
            style={{
              transformStyle: "preserve-3d",
            }}
            variants={dropdownVariants}
            animate={isDropdownVisible ? "hover" : "initial"}
          >
            <Link
              to="/docs/tutorials"
              id="nav-resources-left"
              className="content group grid max-w-full auto-cols-fr grid-cols-1 grid-rows-[auto_auto] items-start justify-start justify-items-start gap-x-4 gap-y-4 bg-black p-8"
              onClick={() => setDropdownVisible(false)}
            >
              <motion.div
                onMouseEnter={() => setIsNavResourcesHovered(true)}
                onMouseLeave={() => setIsNavResourcesHovered(false)}
              >
                <div
                  id="left-content-wrapper"
                  className="mb-4 flex flex-col items-start [span:span_1/span_1/span_1/span_1]"
                >
                  <h3
                    id="content-heading"
                    className="my-0 text-sm font-medium leading-tight"
                  >
                    Tutorials
                  </h3>
                  <div id="padding" className=" p-2 pl-0 pr-0 pt-0"></div>
                  <p
                    id="tutorials-p-text"
                    className=" my-0 text-xs font-medium text-gray-400"
                  >
                    Dive into tutorials guiding you through our AI-based trading
                    indicators. Perfect for novices and experienced traders
                    seeking to maximize their portfolio's performance.
                  </p>
                  <motion.div
                    id="learn-more-button-container"
                    className="group relative cursor-pointer rounded-[3rem] bg-transparent p-[.5rem_.75rem_.5rem_.125rem] pl-0 text-center text-sm font-medium capitalize text-[rgba(255,_255,_255,_0.7)] shadow-none transition-colors duration-200 ease-in-out will-change-transform [text-decoration:none] [background-image:none] hover:[filter:brightness(470%)grayscale()] group-hover:text-cyan-500"
                    onMouseEnter={() => setIsLearnMoreHovered(true)}
                    onMouseLeave={() => setIsLearnMoreHovered(false)}
                  >
                    <div id="learn-more-tutorials" className="mr-0.5">
                      <div id="text" className="text-xs">
                        Learn More
                      </div>
                    </div>
                    <motion.div
                      id="arrow-container"
                      className="absolute bottom-auto left-auto right-0 top-2 block"
                      style={{
                        transformStyle: "preserve-3d",
                      }}
                      variants={arrowVariants}
                      animate={
                        isLearnMoreHovered
                          ? "learnMoreHovered"
                          : isNavResourcesHovered
                          ? "navResourcesHovered"
                          : "initial"
                      }
                    >
                      <div id="icon" className={`h-6 w-6` + styles["w-embed"]}>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="currentcolor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7 17L17 7M17 7V17M17 7H7"
                            stroke="rgb(6 182 212)"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                      </div>
                    </motion.div>
                  </motion.div>
                </div>
                <div
                  id="nav-animation-wrapper"
                  className=" relative w-full pt-[100%] mix-blend-lighten"
                >
                  <div
                    id="animation-embed"
                    className={
                      `absolute bottom-[0%] left-[0%] right-[0%] top-[0%] z-[1] [transform:translate(0)]` +
                      styles["w-embed"]
                    }
                  >
                    <div
                      id="w-background-video"
                      className="relative overflow-hidden"
                      style={{
                        width: "100%",
                        height: "90%",
                      }}
                    >
                      <video
                        id="data-globe"
                        className=" absolute z-[-100] m-auto h-full w-full object-cover [background-position:50%] [background-size:cover]"
                        autoPlay
                        loop
                        muted
                        poster={images[32]}
                      >
                        <source src={stock} data-wf-ignore="true" />
                      </video>
                    </div>
                  </div>
                </div>
              </motion.div>
            </Link>
            <div
              id="nav-resource-right-container"
              className=" grid auto-cols-fr auto-rows-min grid-cols-1 grid-rows-[min-content] gap-y-2 bg-[#141217] p-4 [grid-area:span_1/span_1/span_1/span_1]"
            >
              <Link
                to="/blog"
                id="nav-blog-element"
                className=" group grid w-full max-w-full cursor-pointer auto-cols-fr grid-cols-[1.5rem_1fr] grid-rows-[auto] gap-x-4 gap-y-1 rounded-xl bg-[#141217] p-[1rem_1.5rem_1rem_1rem] [align-content:start] [align-items:start] [transition:background-color_.2s_ease-in-out,color_.1s_linear] hover:bg-[#212026]"
                onClick={() => setDropdownVisible(false)}
              >
                <div
                  id="nav-blog-icon"
                  className=" [grid-area:span_1/span_1/span_1/span 1] flex items-start [transition:text-color_.2s_ease-in-out,color_.1s_linear] group-hover:text-cyan-500"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-6 w-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 01-2.25 2.25M16.5 7.5V18a2.25 2.25 0 002.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 002.25 2.25h13.5M6 7.5h3v3H6v-3z"
                    />
                  </svg>
                </div>
                <div
                  id="nav-blog-content"
                  className="grid auto-cols-fr grid-cols-1 grid-rows-[auto_auto] gap-x-4 gap-y-1 [grid-area:span_1/span_1/span_1/span_1]"
                >
                  <div id="nav-blog-wrap" className="flex items-start">
                    <h3
                      id="nav-blog-heading"
                      className=" my-0 text-sm font-medium leading-tight [border-bottom:1px_solid_transparent]"
                    >
                      Blog
                    </h3>
                  </div>
                  <p id="nav-blog-p" className=" my-0 text-xs text-gray-400">
                    Follow our blog for insights on market trends, algorithmic
                    trading strategies, and exclusive tips on using our AI-based
                    indicators.
                  </p>
                </div>
              </Link>
              <Link
                to="/docs"
                id="nav-docs-element"
                className=" group grid w-full max-w-full cursor-pointer auto-cols-fr grid-cols-[1.5rem_1fr] grid-rows-[auto] gap-x-4 gap-y-1 rounded-xl bg-[#141217] p-[1rem_1.5rem_1rem_1rem] [align-content:start] [align-items:start] [transition:background-color_.2s_ease-in-out,color_.1s_linear] hover:bg-[#212026]"
                onClick={() => setDropdownVisible(false)}
              >
                <div
                  id="nav-docs-icon"
                  className=" [grid-area:span_1/span_1/span_1/span 1] flex items-start [transition:text-color_.2s_ease-in-out,color_.1s_linear] group-hover:text-cyan-500"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-6 w-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25"
                    />
                  </svg>
                </div>
                <div
                  id="nav-docs-content"
                  className="grid auto-cols-fr grid-cols-1 grid-rows-[auto_auto] gap-x-4 gap-y-1 [grid-area:span_1/span_1/span_1/span_1]"
                >
                  <div id="nav-docs-wrap" className="flex items-start">
                    <h3
                      id="nav-docs-heading"
                      className=" my-0 text-sm font-medium leading-tight [border-bottom:1px_solid_transparent]"
                    >
                      Docs
                    </h3>
                  </div>
                  <p id="nav-docs-p" className=" my-0 text-xs text-gray-400">
                    Dive into our comprehensive docs. Find all the information
                    you need to use MADALGO
                  </p>
                </div>
              </Link>
              <Link
                to="/docs/changelog"
                id="nav-advocate-element"
                className=" group grid w-full max-w-full cursor-pointer auto-cols-fr grid-cols-[1.5rem_1fr] grid-rows-[auto] gap-x-4 gap-y-1 rounded-xl bg-[#141217] p-[1rem_1.5rem_1rem_1rem] [align-content:start] [align-items:start] [transition:background-color_.2s_ease-in-out,color_.1s_linear] hover:bg-[#212026]"
                onClick={() => setDropdownVisible(false)}
              >
                <div
                  id="changelog-icon"
                  className=" [grid-area:span_1/span_1/span_1/span 1] flex items-start"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32.248"
                    height="32.248"
                    viewBox="0 0 32.248 32.248"
                    id="pencil"
                    fill="#fff"
                    className="[transition:fill_.2s_ease-in-out,color_.1s_linear] group-hover:fill-cyan-500"
                  >
                    <path d="M21.172 21.172l-1.782-5.38L9.11 5.512 5.512 9.11l10.28 10.28zM.746.746a2.543 2.543 0 0 0 0 3.598l2.648 2.648 3.598-3.598L4.344.746a2.543 2.543 0 0 0-3.598 0zM30 6H15.822l2 2H30v22H8V17.822l-2-2V30a2 2 0 0 0 2 2h22a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2z"></path>
                  </svg>
                </div>
                <div
                  id="changelog-content"
                  className="grid auto-cols-fr grid-cols-1 grid-rows-[auto_auto] gap-x-4 gap-y-1 [grid-area:span_1/span_1/span_1/span_1]"
                >
                  <div id="changelog-heading-wrap" className="flex items-start">
                    <h3
                      id="changelog-heading"
                      className=" my-0 text-sm font-medium leading-tight [border-bottom:1px_solid_transparent]"
                    >
                      Changelog
                    </h3>
                  </div>
                  <p
                    id="changelog-p-text"
                    className=" my-0 text-xs text-gray-400"
                  >
                    Stay up-to-date with the latest enhancements to our
                    AI-powered trading bundle. Continuously improving our
                    algorithms for the most accurate trade indicators.
                  </p>
                </div>
              </Link>
            </div>
          </motion.div>
        </div>
      </motion.div>
    </motion.div>
  );
}
