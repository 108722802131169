import React, { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import Blur from "./Blur";
import TradingViewWidget from "./TradingViewWidgets/TradingViewWidget";
import TechnicalAnalysisWidget from "./TradingViewWidgets/TechnicalAnalysisWidget";
import FundamentalDataWidget from "./TradingViewWidgets/FundamentalDataWidget";
import CompanyProfile from "./TradingViewWidgets/CompanyProfile";
import SymbolInfoWidget from "./TradingViewWidgets/SymbolInfoWidget";

export default function CommunityCharts() {
  const [tickerSymbol, setTickerSymbol] = useState("NASDAQ:AAPL");
  const [inputValue, setInputValue] = useState("");
  const container = useRef(null);
  const containerTA = useRef(null);
  const containerFA = useRef(null);
  const containerCP = useRef(null);
  const containerSI = useRef(null);

  const list = {
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.2, // Increase the stagger to make it slower
        ease: [0.04, 0.62, 0.23, 0.98],
      },
    },
    hidden: {
      opacity: 0,
      transition: {
        when: "afterChildren",
        ease: [0.04, 0.62, 0.23, 0.98],
      },
    },
  };
  const item = {
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 2,
        ease: [0.04, 0.62, 0.23, 0.98],
      },
    },
    hidden: {
      opacity: 0,
      y: 50,
      transition: {
        duration: 2,
        ease: [0.04, 0.62, 0.23, 0.98],
      },
    },
  };

  function handleSearch() {
    updateTradingViewScript(container, inputValue);
    updateTechnicalAnalysisScript(containerTA, inputValue);
    updateFundamentalAnalysisScript(containerFA, inputValue);
    updateCompanyProfileScript(containerCP, inputValue);
    updateSymbolInfoScript(containerSI, inputValue);
    setTickerSymbol(inputValue);
  }

  function createScript(container, tickerSymbol) {
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = `
      {
        "autosize": true,
        "symbol": "${tickerSymbol}",
        "interval": "D",
        "timezone": "Etc/UTC",
        "theme": "dark",
        "style": "1",
        "locale": "en",
        "enable_publishing": false,
        "allow_symbol_change": true,
        "support_host": "https://www.tradingview.com"
      }`;
    container.appendChild(script);
  }

  function createTAScript(containerTA, tickerSymbol) {
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-technical-analysis.js";
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = `
        {
          "interval": "1m",
          "width": "100%",
          "isTransparent": false,
          "height": "60%",
          "symbol": "${tickerSymbol}",
          "showIntervalTabs": true,
          "displayMode": "single",
          "locale": "en",
          "colorTheme": "dark"
        }`;
    containerTA.appendChild(script);
  }

  function createFAScript(containerFA, tickerSymbol) {
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-financials.js";
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = `
        {
          "isTransparent": false,
          "largeChartUrl": "",
          "displayMode": "regular",
          "width": "100%",
          "height": "100%",
          "colorTheme": "dark",
          "symbol": "${tickerSymbol}",
          "locale": "en"
        }`;
    containerFA.appendChild(script);
  }

  function createCPScript(containerCP, tickerSymbol) {
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-symbol-profile.js";
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = `
        {
          "height": "420",
          "width": "100%",
          "isTransparent": false,
          "colorTheme": "dark",
          "symbol": "${tickerSymbol}",
          "locale": "en"
        }`;
    containerCP.appendChild(script);
  }

  function createSIScript(containerSI, tickerSymbol) {
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-symbol-info.js";
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = `
        {
          "width": "100%",
          "isTransparent": false,
          "colorTheme": "dark",
          "symbol": "${tickerSymbol}",
          "locale": "en"
        }`;
    containerSI.appendChild(script);
  }

  function updateTradingViewScript(container, tickerSymbol) {
    if (container.current) {
      container.current.innerHTML = "";
      createScript(container.current, tickerSymbol);
    } else {
      console.error("TradingView container ref is not defined");
    }
  }

  function updateTechnicalAnalysisScript(containerTA, tickerSymbol) {
    if (containerTA.current) {
      containerTA.current.innerHTML = "";
      createTAScript(containerTA.current, tickerSymbol);
    } else {
      console.error("TechnicalAnalysis container ref is not defined");
    }
  }

  function updateFundamentalAnalysisScript(containerFA, tickerSymbol) {
    if (containerFA.current) {
      containerFA.current.innerHTML = "";
      createFAScript(containerFA.current, tickerSymbol);
    } else {
      console.error("FundamentalAnalysis container ref is not defined");
    }
  }

  function updateCompanyProfileScript(containerCP, tickerSymbol) {
    if (containerCP.current) {
      containerCP.current.innerHTML = "";
      createCPScript(containerCP.current, tickerSymbol);
    } else {
      console.error("FundamentalAnalysis container ref is not defined");
    }
  }

  function updateSymbolInfoScript(containerSI, tickerSymbol) {
    if (containerSI.current) {
      containerSI.current.innerHTML = "";
      createSIScript(containerSI.current, tickerSymbol);
    } else {
      console.error("FundamentalAnalysis container ref is not defined");
    }
  }

  useEffect(() => {
    if (
      !container.current.querySelector("script") &&
      !containerTA.current.querySelector("script") &&
      !containerFA.current.querySelector("script") &&
      !containerCP.current.querySelector("script") &&
      !containerSI.current.querySelector("script")
    ) {
      createScript(container.current, tickerSymbol);
      createTAScript(containerTA.current, tickerSymbol);
      createFAScript(containerFA.current, tickerSymbol);
      createCPScript(containerCP.current, tickerSymbol);
      createSIScript(containerSI.current, tickerSymbol);
    }
  }, []);

  return (
    <motion.div
      id="charts-home"
      className="relative box-content flex min-h-screen w-full flex-col items-center justify-center pb-24"
    >
      <div
        id="blur"
        className=" absolute bottom-[0%] left-[0%] right-[0%] top-[0%] z-[-1] block"
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 2, delay: 0.4 }}
          id="gradient-blur-group-1"
          className="lg:w[85vw] absolute bottom-[55.8rem] left-auto right-[-6.6rem] top-auto h-[100vw] w-[95vw] opacity-60 filter-none lg:-right-80 lg:bottom-[40rem] lg:h-[85vw] xl:bottom-[45rem] xl:left-[5.7rem] xl:max-h-[100rem] xl:max-w-[100rem]"
        >
          <Blur />
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 2, delay: 0.8 }}
          id="gradient-blur-group-2"
          className="lg:w[85vw] absolute bottom-[10.8rem] left-auto right-[10.6rem] top-auto h-[100vw] w-[95vw] opacity-60 filter-none sm:bottom-14  md:-bottom-80 md:w-screen lg:-left-80 lg:h-[85vw] xl:left-[-5.7rem] xl:top-60 xl:max-h-[100rem] xl:max-w-[100rem]"
        >
          <Blur />
        </motion.div>
      </div>
      <section
        id="charts-content"
        className=" relative z-[1] block h-full min-h-[300px] w-full txsm:min-h-[356px]"
      >
        <motion.div
          id="wrapper"
          className="flex h-fit flex-col items-center justify-center [-moz-box-direction:normal] [-moz-box-orient:vertical] [-moz-box-pack:center]"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 2 }}
        >
          <input
            className="mt-40 border-none text-black caret-black"
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder={tickerSymbol}
          />
          <button className="mb-20" onClick={handleSearch}>
            Search
          </button>
          <div className="flex w-full flex-col">
            <SymbolInfoWidget containerSI={containerSI} />
            <div className="flex w-full flex-col lg:flex-row">
              {" "}
              <TradingViewWidget container={container} />
              <div className="flex flex-col">
                <FundamentalDataWidget containerFA={containerFA} />
                <TechnicalAnalysisWidget containerTA={containerTA} />
              </div>
            </div>
            <CompanyProfile containerCP={containerCP} />
          </div>
          <motion.div
            initial="hidden"
            animate="visible"
            variants={list}
            transition={{ delay: 0.3 }}
          ></motion.div>
        </motion.div>
      </section>
    </motion.div>
  );
}
