import { useState, useEffect } from "react";
import { BlogBrandSVG, SvgIcon } from "./BrandSVG";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DiscordLogoWithBlinkingCircle from "./DiscordLogoWithBlinkingCircle";
import DropDownCommunity from "./DropDownCommunity";
import DropDownResources from "./DropDownResources";
import DropDownCompany from "./DropDownCompany";
import styles from "./Main.module.css";
import { motion } from "framer-motion";

const Header = ({
  onMenuClick,
  isLogoHidden,
  isClicked,
  setIsClicked,
  menuOpen,
  isAboutUsPage,
  showLoader,
  setShowLoader,
  isBlogPage,
}) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const navigate = useNavigate();
  const location = useLocation();

  const handleButtonClick = () => {
    onMenuClick();
    setIsClicked(!isClicked);
  };

  const handlePricingClick = (e) => {
    e.preventDefault();
    console.log("Setting showLoader to: ", !showLoader);
    setShowLoader(true);

    setTimeout(() => {
      console.log("Setting showLoader to: ", !showLoader);
      setShowLoader(false);
    }, 500);

    // Check if the user is currently on the home page
    if (location.pathname !== "/") {
      navigate(""); // Navigate to the home page
      setTimeout(() => {
        document
          .querySelector("#premium-packages")
          .scrollIntoView({ behavior: "smooth" });
      }, 0);
    }
    // If the user is already on the home page
    else {
      document
        .querySelector("#premium-packages")
        .scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 50;
      if (show) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleLogoClick = () => {
    // If menu is open, close it. Otherwise, do nothing.
    if (menuOpen) {
      onMenuClick();
    }

    setShowLoader(true); // Set to true to show the loader

    // Timer to hide the loader after some time
    setTimeout(() => {
      setShowLoader(false);
    }, 150); // Adjust this duration as per your need

    if (location.pathname !== "/") {
      navigate(""); // Navigate to the home page
    }
  };

  return (
    <header
      id="header"
      role="banner"
      className={
        ` pointer-events-none top-0 z-[99999999999] w-full flex-none border-slate-50/[0.06] backdrop-blur transition-colors duration-75 ease-out md:pointer-events-auto lg:border-b
        ${
          (isAboutUsPage && !isClicked) || (isBlogPage && !isClicked)
            ? "[background:_transparent]"
            : ""
        }
        ${
          menuOpen
            ? " "
            : isScrolled
            ? " [background-color:rgba(0,_0,_0,_0.3)] "
            : " supports-backdrop-blur:bg-white/60 [background-color:rgba(0,_0,_0,_0.3)] "
        }
      ${
        isLogoHidden
          ? " backdrop-blur-none [background-color:rgba(0,_0,_0,_0)] "
          : ""
      }
      ${isBlogPage ? "relative" : "fixed"}
      ` + styles["w-nav"]
      }
    >
      <div
        id="header-container"
        className="relative mx-auto flex h-[4.6875em] max-w-8xl items-center justify-between px-4 lg:px-8"
      >
        <div
          id="header-logo-container"
          className={`mr-8 flex items-center pb-1 1xl:mr-72 ${
            isLogoHidden && windowWidth <= 767 ? "hidden" : ""
          }`}
        >
          <div
            id="wrapper"
            className="pointer-events-auto m-0 flex text-lg font-bold leading-none"
          >
            <Link
              to="/"
              onClick={handleLogoClick}
              aria-label="Home Logo Navigation to Home Page"
            >
              {isBlogPage ? (
                <BlogBrandSVG menuOpen={menuOpen} />
              ) : (
                <SvgIcon isAboutUsPage={isAboutUsPage} menuOpen={menuOpen} />
              )}
            </Link>
            <Link
              to="/"
              className="ml-2 text-white"
              onClick={handleLogoClick}
              aria-label="MADALGO Text Navigation to Home Page"
            >
              <svg
                className="svg-header-logo w-24 [transition:all_.15s_ease-in-out]"
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="143"
                height="50"
                fill={
                  (isAboutUsPage && menuOpen) || (isBlogPage && menuOpen)
                    ? "#fff"
                    : isAboutUsPage || isBlogPage
                    ? "#000"
                    : "#fff"
                }
                viewBox="0 0 143 24"
                xmlSpace="preserve"
              >
                <g>
                  <g>
                    <path d="M39.2,12.1H28.9V3.4h-3.4v16.9h3.4v-4.8h10.3V24h3.4V3.4h-3.4V12.1z" />
                    <path d="M39.2,3.4V0H28.9v3.4H39.2z" />
                  </g>
                  <g>
                    <path d="M123.8,19.9h4V4.1h-4V19.9z" />
                    <path d="M138.9,19.9h4V4.1h-4V19.9z" />
                    <path d="M139,4.1V0h-11.3v4.1H139z" />
                    <path d="M139,24v-4.1h-11.3V24H139z" />
                  </g>
                  <path d="M0.1,24h3.4V3.4H0.1V24z" />
                  <path d="M9,10.8h3.4V2.7H9V10.8z" />
                  <path d="M18,20.5h3.4V3.4H18V20.5z" />
                  <path d="M19.1,3.5V0H2.3v3.4L19.1,3.5L19.1,3.5z" />
                  <path d="M92.5,20.5V3.2h-3.4V24h12.1v-3.5H92.5z" />
                  <path d="M79,0h-3.8l-8.7,24h3.7l2-5.6h9.9l2,5.6h3.7L79.1,0.2L79,0z M73.3,15.3l3.9-11l3.8,11H73.3z" />
                  <path
                    d="M54.8,0h-5.2v6.9H53V3.4h1.7c4.7,0,8.6,3.8,8.6,8.6c0,4.7-3.8,8.6-8.6,8.6h-5.2V6.9h-3.3V24h8.6c6.6,0,12-5.4,12-12
	S61.4,0,54.8,0z"
                  />
                  <g>
                    <path d="M112.3,1.4c4.3,0,6.9,2,8.8,4.5l-2.8,1.6c-1.3-1.8-3.4-3.1-6-3.1" />
                    <path
                      d="M121.6,18.6c0,2-5.4,5.4-9.3,5.4c-6.6,0-11.9-4.8-11.9-12s5.3-12,11.9-12v3.1c-4.8,0-8.4,3.7-8.4,8.9s3.6,9,8.4,9
		c2.6,0,4.7-1.2,5.8-2.3"
                    />
                    <path d="M117.1,18.7v-2.8h-4.2v-3h7.1c0.3,0,0.5,0.2,0.5,0.5v5.3" />
                  </g>
                </g>
              </svg>
            </Link>
          </div>
        </div>

        <nav
          id="nav-bar"
          className=" relative float-right hidden w-full items-center justify-between lg:flex"
          role="navigation"
        >
          <div
            id="nav-menu-item-wrap"
            className="flex flex-[0_auto] items-center gap-x-4"
          >
            <DropDownCommunity
              isAboutUsPage={isAboutUsPage}
              isBlogPage={isBlogPage}
            />
            <DropDownResources
              isAboutUsPage={isAboutUsPage}
              isBlogPage={isBlogPage}
            />
            <DropDownCompany
              isAboutUsPage={isAboutUsPage}
              isBlogPage={isBlogPage}
            />
            <div id="pricing-wrap" className="relative flex flex-[0_auto]">
              <div
                id="pricing-inner-wrap"
                className="flex w-28 cursor-pointer items-center justify-center py-6 "
              >
                <Link
                  to={"/"}
                  id="pricing-a"
                  className={` relative mr-0 flex max-w-full items-center justify-center rounded-xl p-[8px_12px] text-sm font-medium capitalize opacity-100 [text-decoration:none] ${
                    isAboutUsPage || isBlogPage
                      ? " text-black [transition:all_.1s_ease-in-out] after:absolute after:left-0 after:top-[80%] after:h-0.5 after:w-[80%] after:bg-black after:opacity-0 after:[content:''] after:[transform:translateX(-15%)] after:[transition:.25s_ease-in-out] hover:after:opacity-100 hover:after:[transform:translateX(-5%)]"
                      : " text-white [transition:opacity_.2s,color_.2s] hover:bg-[rgb(20,_18,_23)] hover:text-cyan-500"
                  }`}
                  onClick={handlePricingClick}
                >
                  Pricing
                </Link>
              </div>
            </div>
          </div>
          <div
            id="button-group"
            className="flex flex-nowrap items-center justify-start gap-x-4 gap-y-4 [flex-flow:wrap]"
          >
            <Link
              to="https://discord.gg/urfhuyhTXd"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center"
            >
              <DiscordLogoWithBlinkingCircle
                isAboutUsPage={isAboutUsPage}
                isBlogPage={isBlogPage}
                className="h-5 w-5"
              />
            </Link>
            <Link
              to="/manage-account"
              className={` relative mr-0 flex max-w-full items-center justify-center rounded-xl p-[8px_12px] text-sm font-medium capitalize opacity-100 [text-decoration:none] 
                ${
                  isAboutUsPage || isBlogPage
                    ? "text-black [transition:all_.1s_ease-in-out] after:absolute after:left-0 after:top-[80%] after:h-0.5 after:w-[85%] after:bg-black after:opacity-0 after:[content:''] after:[transform:translateX(-15%)] after:[transition:.25s_ease-in-out] hover:after:opacity-100 hover:after:[transform:translateX(-5%)]"
                    : " font-semibold text-white [transition:opacity_.2s,color_.2s] hover:bg-[rgb(20,_18,_23)] hover:text-cyan-500"
                }`}
            >
              My Account
            </Link>
          </div>
        </nav>

        <motion.button
          id="menu-not-desktop"
          aria-label="Mobile Menu Button"
          onClick={handleButtonClick}
          type="button"
          className={`"[border-0] group pointer-events-auto relative z-[500] ml-auto mr-3 inline-block h-6 w-[45px] cursor-pointer overflow-hidden bg-transparent align-middle [-webkit-appearance:button] [-webkit-transition:opacity_.5s] [outline:0] [text-transform:none] [transition:opacity_.5s] selection:text-black selection:[background:#ccc] selection:[text-shadow:none] lg:hidden ${
            styles.button
          } ${isClicked ? styles.buttonActive : ""}`}
        >
          <motion.span
            id="first-span"
            className={` duration-50 absolute left-[3px] top-0 block h-[3px] w-[90%] translate-x-14 transform transition-transform [transition:_opacity_.5s,transform_.5s] before:absolute before:right-full before:top-0 before:mr-[15px] before:h-full before:w-full before:[content:''] group-hover:translate-x-0 ${
              isAboutUsPage || isBlogPage
                ? isClicked
                  ? "bg-white before:bg-white"
                  : "bg-black before:bg-black"
                : "bg-white before:bg-white"
            }`}
            style={{
              opacity: isClicked ? 0 : 1,
            }}
          ></motion.span>
          <motion.span
            id="second-span"
            className={` duration-50 absolute left-[3px] top-[43.5%] block h-[3px] w-[90%] translate-x-14 transform transition-transform [transition:_transform_.5s_.1s] before:absolute before:right-full before:top-0 before:mr-[15px] before:h-full before:w-full before:[content:''] group-hover:translate-x-0 ${
              isAboutUsPage || isBlogPage
                ? isClicked
                  ? "bg-white before:bg-white"
                  : "bg-black before:bg-black"
                : "bg-white before:bg-white"
            }`}
            style={{
              transform: isClicked ? "rotate(45deg)" : "",
            }}
          ></motion.span>
          <motion.span
            id="third-span"
            className={` duration-50 absolute left-[3px] top-[43.5%] block h-[3px] w-[90%] translate-x-14 transform transition-transform [transition:_transform_.5s_.1s] before:absolute before:right-full before:top-0 before:mr-[15px] before:h-full before:w-full before:[content:''] group-hover:translate-x-0 ${
              isAboutUsPage || isBlogPage
                ? isClicked
                  ? "bg-white before:bg-white"
                  : "bg-black before:bg-black"
                : "bg-white before:bg-white"
            }`}
            style={{
              transform: isClicked ? "rotate(135deg)" : "",
            }}
          ></motion.span>
          <motion.span
            id="fourth-span"
            className={` duration-50 absolute bottom-0 left-[3px] block h-[3px] w-[90%] translate-x-14 transform transition-transform [transition:_opacity_.5s,transform_.5s_.2s] before:absolute before:right-full before:top-0 before:mr-[15px] before:h-full before:w-full before:[content:''] group-hover:translate-x-0 ${
              isAboutUsPage || isBlogPage
                ? isClicked
                  ? "bg-white before:bg-white"
                  : "bg-black before:bg-black"
                : "bg-white before:bg-white"
            }`}
            style={{
              opacity: isClicked ? 0 : 1,
            }}
          ></motion.span>
        </motion.button>
      </div>
    </header>
  );
};

export default Header;
