import { useState, useContext } from "react";
import { InlineMath } from "react-katex";
import "katex/dist/katex.min.css";
import styles from "../Docs.module.css";
import ThemeContext from "../ThemeContext";

export default function DocsTabContainer() {
  const { theme } = useContext(ThemeContext);
  const [selectedTab, setSelectedTab] = useState(0); // default to the first tab

  const equations = {
    "HA Open": "O^t = \\frac{2O^{t-1} + C^{t-1}}{2}",
    "HA Close": "C^t = \\frac{O^t + H^t + L^t + C^t}{4}",
    "HA High": "H^t = \\max(C^t, O^t, H^t)",
    "HA Low": "L^t = \\min(C^t, O^t, L^t)",
  };
  return (
    <div id="tabs">
      <ul
        id="tabs-list"
        className="m-[0_0_1rem] mb-0 flex overflow-x-auto pl-0 font-bold"
        role="tablist"
        aria-orientation="horizontal"
      >
        {["HA Open", "HA Close", "HA High", "HA Low"].map((label, index) => (
          <li
            key={label}
            role="tab"
            tabIndex="0"
            aria-selected={selectedTab === index}
            className={`mt-0 inline-flex cursor-pointer rounded-md p-[1rem_1rem] transition-colors duration-200 [border-bottom:3px_solid_transparent] [transition-timing-function:cubic-bezier(0.08,_.52,_0.52,_1)] [word-wrap:break-word] hover:bg-[hsla(0,0%,100%,.05)] ${
              theme === "light"
                ? selectedTab === index
                  ? styles.activeTab
                  : ""
                : selectedTab === index
                ? styles.activeTabDark
                : ""
            }`}
            onClick={() => setSelectedTab(index)}
          >
            {label}
          </li>
        ))}
      </ul>
      <div
        id="math-container"
        className="mx-auto my-4 flex h-[26.1px] justify-center"
      >
        {["HA Open", "HA Close", "HA High", "HA Low"].map((content, index) => (
          <div key={content} role="tabpanel" hidden={selectedTab !== index}>
            <div className={styles.mathDisplay}>
              <InlineMath math={equations[content]} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
