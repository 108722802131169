import React, { useRef, useState } from "react";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import HidePasswordIcon from "./dashboard/HidePasswordIcon";
import ShowPasswordIcon from "./dashboard/ShowPasswordIcon";

const Login = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [isVerifyingCode, setIsVerifyingCode] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [isAwaitingVerification, setIsAwaitingVerification] = useState(false);
  const emailInputRef = useRef(null);
  const navigate = useNavigate();

  const focusEmailInput = () => {
    if (emailInputRef.current) {
      emailInputRef.current.focus();
    }
  };

  const handleForgotPassword = async () => {
    setIsLoading(true);
    try {
      await Auth.forgotPassword(email);
      setIsResettingPassword(true);
      setIsVerifyingCode(true);
      setSuccessMessage("Password reset email sent.");
    } catch (error) {
      setErrorMessage("Error sending forgot password email: " + error.message);
    }
    setIsLoading(false);
  };

  const handleResetPassword = async () => {
    setIsLoading(true);
    try {
      await Auth.forgotPasswordSubmit(email, verificationCode, password);
      setIsResettingPassword(false);
      setIsVerifyingCode(false);
      setEmail("");
      setPassword("");
      setVerificationCode("");
      setSuccessMessage("Password reset successfully.");
    } catch (error) {
      setErrorMessage("Error resetting password: " + error.message);
    }
    setIsLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage("");
    setSuccessMessage("");

    try {
      if (isVerifyingCode) {
        await handleResetPassword();
        setSuccessMessage("Password reset successful.");
        setIsVerifyingCode(false);
        setIsLogin(true);
        focusEmailInput();
      } else if (isAwaitingVerification) {
        await Auth.confirmSignUp(email, verificationCode);
        setSuccessMessage("Account verified successfully.");
        setIsAwaitingVerification(false);
        setIsResettingPassword(false);
        setIsLogin(true);
        setEmail("");
        setVerificationCode("");
        setPassword("");
        focusEmailInput();
      } else if (isResettingPassword) {
        await handleForgotPassword();
      } else if (isLogin) {
        await Auth.signIn(email, password);
        setSuccessMessage("Sign in successful.");
        navigate("/dashboard");
      } else {
        // Logic for sign-up
        await Auth.signUp({ username: email, password });
        setSuccessMessage(
          "Sign up successful. Please check your email for the verification code.",
        );
        setIsAwaitingVerification(true); // User needs to verify email next
      }
    } catch (error) {
      setErrorMessage("Error: " + error.message);
      console.error("Error during sign in/up/reset/verification", error);
    }

    setIsLoading(false);
  };

  const toggleForm = () => {
    setIsLogin(!isLogin);
    setIsResettingPassword(false);
    setIsVerifyingCode(false);
    setErrorMessage("");
    setSuccessMessage("");
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const Spinner = () => (
    <svg
      className="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 1.26.313 2.446.874 3.417l1.142-1.126z"
      ></path>
    </svg>
  );

  return (
    <div className="flex min-h-screen items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
      <div className="w-full max-w-md space-y-8 rounded-xl bg-white p-10 shadow-lg">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            {isResettingPassword
              ? "Reset your password"
              : isAwaitingVerification
              ? "Verify your email"
              : isLogin
              ? "Sign in to your account"
              : "Create a new account"}
          </h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          <input type="hidden" name="remember" value="true" />
          <div className="-space-y-px rounded-md shadow-sm">
            {!isAwaitingVerification && (
              <div className="mb-4">
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  ref={emailInputRef}
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            )}
            {!isResettingPassword &&
              !isVerifyingCode &&
              !isAwaitingVerification && (
                <div className="relative mb-4">
                  <label htmlFor="password" className="sr-only">
                    Password
                  </label>
                  <input
                    id="password"
                    name="password"
                    type={passwordVisible ? "text" : "password"}
                    autoComplete="current-password"
                    required
                    className="relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <button
                    type="button"
                    className="absolute inset-y-0 right-0 z-10 flex items-center pr-3 text-sm leading-5"
                    onMouseEnter={() => setShowTooltip(true)}
                    onMouseLeave={() => setShowTooltip(false)}
                    onClick={togglePasswordVisibility}
                  >
                    {passwordVisible ? (
                      <ShowPasswordIcon />
                    ) : (
                      <HidePasswordIcon />
                    )}
                  </button>
                  {showTooltip && (
                    <div
                      className="absolute -right-10 z-10 mb-1 w-auto -translate-y-full whitespace-nowrap rounded bg-black p-2 text-xs text-white shadow-md"
                      style={{ bottom: "100%", transform: "translateX(-50%)" }}
                    >
                      {passwordVisible ? "Hide password" : "Show password"}
                    </div>
                  )}
                </div>
              )}
            {isAwaitingVerification && (
              <>
                <p className="mb-4 text-center text-xs font-bold text-gray-600">
                  We have sent a verification code to your email. Please check
                  your inbox and enter the code below to verify your account.
                </p>
                <div className="mb-4">
                  <label htmlFor="verification-code" className="sr-only">
                    Verification Code
                  </label>
                  <input
                    id="verification-code"
                    name="verificationCode"
                    type="text"
                    autoComplete="off"
                    required
                    className="relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    placeholder="Verification code"
                    value={verificationCode}
                    onChange={(e) => setVerificationCode(e.target.value)}
                  />
                </div>
              </>
            )}
            {isVerifyingCode && (
              <>
                <div className="mb-4">
                  <label htmlFor="verification-code" className="sr-only">
                    Verification Code
                  </label>
                  <input
                    id="verification-code"
                    name="verificationCode"
                    type="text"
                    autoComplete="off"
                    required
                    className="relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    placeholder="Verification code"
                    value={verificationCode}
                    onChange={(e) => setVerificationCode(e.target.value)}
                  />
                </div>
                <div className="relative mb-4">
                  <label htmlFor="new-password" className="sr-only">
                    New Password
                  </label>
                  <input
                    id="new-password"
                    name="password"
                    type={passwordVisible ? "text" : "password"}
                    autoComplete="new-password"
                    required
                    className="relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    placeholder="New password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <button
                    type="button"
                    className="absolute inset-y-0 right-0 z-10 flex items-center pr-3 text-sm leading-5"
                    onMouseEnter={() => setShowTooltip(true)}
                    onMouseLeave={() => setShowTooltip(false)}
                    onClick={togglePasswordVisibility}
                  >
                    {passwordVisible ? (
                      <ShowPasswordIcon />
                    ) : (
                      <HidePasswordIcon />
                    )}
                  </button>
                  {showTooltip && (
                    <div
                      className="absolute -right-10 z-10 mb-1 w-auto -translate-y-full whitespace-nowrap rounded bg-black p-2 text-xs text-white shadow-md"
                      style={{ bottom: "100%", transform: "translateX(-50%)" }}
                    >
                      {passwordVisible ? "Hide password" : "Show password"}
                    </div>
                  )}
                </div>
              </>
            )}
            {!isLogin && !isResettingPassword && !isAwaitingVerification && (
              <div className="relative mb-4">
                <label htmlFor="confirm-password" className="sr-only">
                  Confirm Password
                </label>
                <input
                  id="confirm-password"
                  name="confirmPassword"
                  type={passwordVisible ? "text" : "password"}
                  autoComplete="confirm-password"
                  required={!isLogin}
                  className="relative mt-1 block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
            )}
          </div>
          {!isResettingPassword && !isVerifyingCode && (
            <div className="flex items-center justify-between">
              <div className="text-sm">
                <button
                  type="button"
                  className="font-medium text-indigo-600 hover:text-indigo-500"
                  onClick={() => setIsResettingPassword(true)}
                >
                  Forgot your password?
                </button>
              </div>
            </div>
          )}

          <div>
            {errorMessage && (
              <p className="text-xs text-red-500">{errorMessage}</p>
            )}
            {successMessage && (
              <p className="text-xs text-green-500">{successMessage}</p>
            )}
          </div>
          <div>
            <button
              type="submit"
              disabled={isLoading}
              className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              {isLoading ? (
                <Spinner />
              ) : isResettingPassword || isAwaitingVerification ? (
                "Submit"
              ) : isLogin ? (
                "Sign In"
              ) : (
                "Sign Up"
              )}
            </button>
          </div>
          {!isAwaitingVerification && (
            <div className="mt-4 flex items-center justify-between">
              <button
                type="button"
                className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                onClick={toggleForm}
              >
                {isLogin
                  ? "Don't have an account? Sign Up"
                  : "Already have an account? Sign In"}
              </button>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default Login;
