import image0 from "./doc-imgs/tvlogo.png";
import image1 from "./doc-imgs/tvsignin.png";
import image2 from "./doc-imgs/tvsignin1.png";
import image3 from "./doc-imgs/supercharts.png";
import image4 from "./doc-imgs/changesymbol.png";
import image5 from "./doc-imgs/symbolsearch.png";
import image6 from "./doc-imgs/changetimeframe.png";
import image7 from "./doc-imgs/addindicators.png";
import image8 from "./doc-imgs/findindicators.png";
import image9 from "./doc-imgs/savechartlayout.png";
import image10 from "./doc-imgs/selectmadalgo.png";
import image11 from "./doc-imgs/favoriteindicators.png";
import image12 from "./doc-imgs/discord-logo-blue.png";
import image13 from "./doc-imgs/joindiscord.png";
import image14 from "./doc-imgs/script-notifications.png";
import image15 from "./doc-imgs/alerts.png";
import image16 from "./doc-imgs/alertmanager.png";
import image17 from "./doc-imgs/condition.png";
import image18 from "./doc-imgs/crossing.png";
import image19 from "./doc-imgs/crossingup.png";
import image20 from "./doc-imgs/crossingdown.png";
import image21 from "./doc-imgs/enteringchannel.png";
import image22 from "./doc-imgs/exitingchannel.png";
import image23 from "./doc-imgs/outsidechannel.png";
import image24 from "./doc-imgs/insidechannel.png";
import image25 from "./doc-imgs/greaterthan.png";
import image26 from "./doc-imgs/lessthan.png";
import image27 from "./doc-imgs/placeholder.png";
import image28 from "./doc-imgs/caution.png";
import image29 from "./doc-imgs/removealerts.png";
import image30 from "./doc-imgs/editalert.png";
import image31 from "./doc-imgs/updatealert.png";
import image32 from "./doc-imgs/tainto.png";
import image33 from "./doc-imgs/linechart.png";
import image34 from "./doc-imgs/candles.png";
import image35 from "./doc-imgs/candlebars.png";
import image36 from "./doc-imgs/heikinashi.png";
import image37 from "./doc-imgs/showrealprice.png";
import image38 from "./doc-imgs/volume.png";
import image39 from "./doc-imgs/candlesdarkbg.png";
import image40 from "./doc-imgs/trends.png";
import image41 from "./doc-imgs/uptrend.png";
import image42 from "./doc-imgs/downtrend.png";
import image43 from "./doc-imgs/sideways.png";
import image44 from "./doc-imgs/supportresistance.png";
import image45 from "./doc-imgs/changeinpolarity.png";
import image46 from "./doc-imgs/momentum.png";
import image47 from "./doc-imgs/momentumindicators.png";
import image48 from "./doc-imgs/momentumrsi.png";
import image49 from "./doc-imgs/momentumstochastic.png";
import image50 from "./doc-imgs/momentumdivergences.png";
import image51 from "./doc-imgs/trend.png";
import image52 from "./doc-imgs/trendmovingaverages.png";
import image53 from "./doc-imgs/trendema.png";
import image54 from "./doc-imgs/trend200ema.png";
import image55 from "./doc-imgs/trendbands.png";
import image56 from "./doc-imgs/trendbbbands.png";
import image57 from "./doc-imgs/trenddonchian.png";
import image58 from "./doc-imgs/trendbbbandsbreakout.png";
import image59 from "./doc-imgs/trendpsar.png";
import image60 from "./doc-imgs/trendtrailingstops.png";
import image61 from "./doc-imgs/volatility.png";
import image62 from "./doc-imgs/volatilitystdevatr.png";
import image63 from "./doc-imgs/volumeindicators.png";
import image64 from "./doc-imgs/volumepvt.png";
import image65 from "./doc-imgs/volumeobv.png";
import image66 from "./doc-imgs/madalgopulsefrvp.png";
import image67 from "./doc-imgs/rr.png";
import image68 from "./doc-imgs/assetallocation.png";
import image69 from "./doc-imgs/mpt.png";
import image70 from "./doc-imgs/stoploss.png";
import image71 from "./doc-imgs/gap.png";
import image72 from "./doc-imgs/takeprofit.png";
import image73 from "./doc-imgs/tradestation.png";
import image74 from "./doc-imgs/oanda.png";
import image75 from "./doc-imgs/forexcom.png";
import image76 from "./doc-imgs/tradovate.png";
import image77 from "./doc-imgs/alpaca.png";
import image78 from "./doc-imgs/binance.png";
import image79 from "./doc-imgs/coinbase.png";
import image80 from "./doc-imgs/adjustingsettings.png";
import image81 from "./doc-imgs/profitbands.png";
import image82 from "./doc-imgs/profitbandsshaded.png";
import image83 from "./doc-imgs/profitbandsshadedsignals.png";
import image84 from "./doc-imgs/vwsi.png";
import image85 from "./doc-imgs/vwsiinterpretation.png";
import image86 from "./doc-imgs/vwapanchors.png";
import image87 from "./doc-imgs/intradayvwap.png";
import image88 from "./doc-imgs/anchoredvwap.png";
import image89 from "./doc-imgs/overboughtvwap.png";
import image90 from "./doc-imgs/oversoldvwap.png";
import image91 from "./doc-imgs/selectanchortimedate.png";
import image92 from "./doc-imgs/versama.png";
import image93 from "./doc-imgs/versama1.png";
import image94 from "./doc-imgs/versamaforecast.png";
import image95 from "./doc-imgs/versamausage.png";
import image96 from "./doc-imgs/hypertrend.png";
import image97 from "./doc-imgs/hypertrendtrendline.png";
import image98 from "./doc-imgs/hypertrendbuy.png";
import image99 from "./doc-imgs/hypertrendsell.png";
import image100 from "./doc-imgs/hypertrendsettings.png";
import image101 from "./doc-imgs/trendalertcandles.png";
import image102 from "./doc-imgs/tacfirstcandlepattern.png";
import image103 from "./doc-imgs/tacsecondcandlepattern.png";
import image104 from "./doc-imgs/tacthirdcandlepattern.png";
import image105 from "./doc-imgs/tacfourthcandlepattern.png";
import image106 from "./doc-imgs/tacsettings.png";
import image107 from "./doc-imgs/volumeprofilepro.png";
import image108 from "./doc-imgs/volumeprofileproup.png";
import image109 from "./doc-imgs/volumeprofileprodown.png";
import image110 from "./doc-imgs/volumeprofileprosettings.png";
import image111 from "./doc-imgs/navigatorsettings.png";
import image112 from "./doc-imgs/ilz.png";
import image113 from "./doc-imgs/ilzsettings.png";
import image114 from "./doc-imgs/ilzmethods.png";
import image115 from "./doc-imgs/ilzlookback.png";
import image116 from "./doc-imgs/ilzstyling.png";
import image117 from "./doc-imgs/ilzvisdis.png";
import image118 from "./doc-imgs/ilzkeypricelevels.png";
import image119 from "./doc-imgs/ilzoes.png";
import image120 from "./doc-imgs/msb.png";
import image121 from "./doc-imgs/msbsettings.png";
import image122 from "./doc-imgs/msbmethods.png";
import image123 from "./doc-imgs/msbstyling.png";
import image124 from "./doc-imgs/msbdis.png";
import image125 from "./doc-imgs/msbsupport.png";
import image126 from "./doc-imgs/msbtrend.png";
import image127 from "./doc-imgs/dd.png";
import image128 from "./doc-imgs/ddsettings.png";
import image129 from "./doc-imgs/ddmethods.png";
import image130 from "./doc-imgs/ddstyling.png";
import image131 from "./doc-imgs/dddis.png";
import image132 from "./doc-imgs/ddtrend.png";
import image133 from "./doc-imgs/ddentry.png";
import image134 from "./doc-imgs/ts.png";
import image135 from "./doc-imgs/tssettings.png";
import image136 from "./doc-imgs/tsreverse.png";
import image137 from "./doc-imgs/tsfixed.png";
import image138 from "./doc-imgs/tslookback.png";
import image139 from "./doc-imgs/tsstyling.png";
import image140 from "./doc-imgs/tstarget.png";
import image141 from "./doc-imgs/tsprecise.png";
import image142 from "./doc-imgs/tsrisk.png";
import image143 from "./doc-imgs/tsc.png";
import image144 from "./doc-imgs/tscsettings.png";
import image145 from "./doc-imgs/tscmedian.png";
import image146 from "./doc-imgs/tsccolors.png";
import image147 from "./doc-imgs/tsccalc.png";
import image148 from "./doc-imgs/tsctrend.png";
import image149 from "./doc-imgs/tscvalidation.png";
import image150 from "./doc-imgs/trfvg.png";
import image151 from "./doc-imgs/trfvgsettings.png";
import image152 from "./doc-imgs/trfvgcustom.png";
import image153 from "./doc-imgs/trfvgcalc.png";
import image154 from "./doc-imgs/trfvggaps.png";
import image155 from "./doc-imgs/trfvgfailed.png";
import image156 from "./doc-imgs/dynamicsactivate.png";
import image157 from "./doc-imgs/imf.png";
import image158 from "./doc-imgs/imfsettings.png";
import image159 from "./doc-imgs/imfcustomize.png";
import image160 from "./doc-imgs/imfcalc.png";
import image161 from "./doc-imgs/imfinterp.png";
import image162 from "./doc-imgs/imfob.png";
import image163 from "./doc-imgs/imfos.png";
import image164 from "./doc-imgs/mrs.png";
import image165 from "./doc-imgs/mrssettings.png";
import image166 from "./doc-imgs/mrscustomize.png";
import image167 from "./doc-imgs/mrscalc.png";
import image168 from "./doc-imgs/mrsmomentumshift.png";
import image169 from "./doc-imgs/mrsother.png";
import image170 from "./doc-imgs/pti.png";
import image171 from "./doc-imgs/ptisettings.png";
import image172 from "./doc-imgs/pticustomize.png";
import image173 from "./doc-imgs/pticalc.png";
import image174 from "./doc-imgs/ptitrend.png";
import image175 from "./doc-imgs/ptiother.png";
import image176 from "./doc-imgs/stochastix.png";
import image177 from "./doc-imgs/stochastixsettings.png";
import image178 from "./doc-imgs/stochastixcustomize.png";
import image179 from "./doc-imgs/stochastixcalc.png";
import image180 from "./doc-imgs/stochastixcondition.png";
import image181 from "./doc-imgs/stochastixother.png";
import image182 from "./doc-imgs/wp.png";
import image183 from "./doc-imgs/wpsettings.png";
import image184 from "./doc-imgs/wpcustomize.png";
import image185 from "./doc-imgs/wpcalc.png";
import image186 from "./doc-imgs/wptrend.png";
import image187 from "./doc-imgs/wpother.png";

const DocImages = [
  image0,
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11,
  image12,
  image13,
  image14,
  image15,
  image16,
  image17,
  image18,
  image19,
  image20,
  image21,
  image22,
  image23,
  image24,
  image25,
  image26,
  image27,
  image28,
  image29,
  image30,
  image31,
  image32,
  image33,
  image34,
  image35,
  image36,
  image37,
  image38,
  image39,
  image40,
  image41,
  image42,
  image43,
  image44,
  image45,
  image46,
  image47,
  image48,
  image49,
  image50,
  image51,
  image52,
  image53,
  image54,
  image55,
  image56,
  image57,
  image58,
  image59,
  image60,
  image61,
  image62,
  image63,
  image64,
  image65,
  image66,
  image67,
  image68,
  image69,
  image70,
  image71,
  image72,
  image73,
  image74,
  image75,
  image76,
  image77,
  image78,
  image79,
  image80,
  image81,
  image82,
  image83,
  image84,
  image85,
  image86,
  image87,
  image88,
  image89,
  image90,
  image91,
  image92,
  image93,
  image94,
  image95,
  image96,
  image97,
  image98,
  image99,
  image100,
  image101,
  image102,
  image103,
  image104,
  image105,
  image106,
  image107,
  image108,
  image109,
  image110,
  image111,
  image112,
  image113,
  image114,
  image115,
  image116,
  image117,
  image118,
  image119,
  image120,
  image121,
  image122,
  image123,
  image124,
  image125,
  image126,
  image127,
  image128,
  image129,
  image130,
  image131,
  image132,
  image133,
  image134,
  image135,
  image136,
  image137,
  image138,
  image139,
  image140,
  image141,
  image142,
  image143,
  image144,
  image145,
  image146,
  image147,
  image148,
  image149,
  image150,
  image151,
  image152,
  image153,
  image154,
  image155,
  image156,
  image157,
  image158,
  image159,
  image160,
  image161,
  image162,
  image163,
  image164,
  image165,
  image166,
  image167,
  image168,
  image169,
  image170,
  image171,
  image172,
  image173,
  image174,
  image175,
  image176,
  image177,
  image178,
  image179,
  image180,
  image181,
  image182,
  image183,
  image184,
  image185,
  image186,
  image187,
];

export default DocImages;
