import { Link } from "react-router-dom";
import styles from "./Main.module.css";
import { Auth } from "aws-amplify";
import { useState } from "react";

const ManageAccountArrow = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 4L12 12M20 4V8.5M20 4H15.5M19 12.5V16.8C19 17.9201 19 18.4802 18.782 18.908C18.5903 19.2843 18.2843 19.5903 17.908 19.782C17.4802 20 16.9201 20 15.8 20H7.2C6.0799 20 5.51984 20 5.09202 19.782C4.71569 19.5903 4.40973 19.2843 4.21799 18.908C4 18.4802 4 17.9201 4 16.8V8.2C4 7.0799 4 6.51984 4.21799 6.09202C4.40973 5.71569 4.71569 5.40973 5.09202 5.21799C5.51984 5 6.07989 5 7.2 5H11.5"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
  </svg>
);
// Modal Component
const Modal = ({
  showModal,
  closeModal,
  email,
  setEmail,
  cancelSubscription,
}) => {
  return (
    showModal && (
      <div className="cancel-modal pointer-events-auto fixed inset-0 top-1/2 z-[999] m-0 grid h-fit max-h-none w-full max-w-none justify-center overscroll-contain p-0">
        <div className="modal-content relative rounded-2xl bg-slate-50 p-12 text-stone-950 shadow-lg ring-1">
          <span
            className="close absolute right-3 top-3 cursor-pointer text-base"
            onClick={closeModal}
          >
            &times;
          </span>
          <form onSubmit={(e) => cancelSubscription(e, email)}>
            <input
              type="email"
              className="mr-1"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              required
            />
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
    )
  );
};

export default function ManageAccount() {
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState("");

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  async function cancelSubscription(e, userEmail) {
    e.preventDefault();
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      const jwtToken = currentUser.signInUserSession.idToken.jwtToken;

      const response = await fetch(process.env.REACT_APP_CANCEL_SUBSCRIPTION, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`, // Include the JWT token here
        },
        body: JSON.stringify({ email: userEmail }),
      });

      const responseData = await response.json();
      if (!response.ok) {
        throw new Error(responseData.error || "Network response was not ok");
      }

      // Handle successful cancellation
      console.log("Subscription cancelled successfully:", responseData);
      setEmail("");
      setShowModal(false);
    } catch (error) {
      console.error("Error cancelling subscription:", error.message);
    }
  }

  return (
    <>
      <section className="relative z-0 block w-full">
        <div
          id="manage-account-heading-container"
          className=" w-full px-7 pb-4 pt-40 md:px-[3.785rem] md:pb-[6.25rem] lg:px-24 lg:pt-32"
        >
          <div
            id="manage-account-heading"
            className="relative z-[1] text-center"
          >
            <h1 className=" text-5xl md:text-7xl lg:text-9xl">
              Manage Account
            </h1>
            <p className="mb-0 mt-10 text-lg font-normal leading-[116.667%] tracking-[.1px] md:text-xl lg:text-2xl">
              Everything to manage your account is below.
            </p>
          </div>
        </div>
      </section>
      <section className="relative z-0 block w-full">
        <div
          id="manage-account-card"
          className=" w-full px-7 pt-16 md:px-16 lg:grid lg:justify-center lg:px-28"
        >
          <div
            id="manage-account-card-container"
            className="flex max-w-5xl flex-col"
          >
            <div id="card" className={styles.card}>
              <div className="">
                <h2 className=" mb-6 text-center text-2xl leading-[100%] md:text-4xl lg:text-5xl">
                  Accounts and support
                </h2>
              </div>
              <div
                id="manage-account-card-content"
                className={styles.cardContent}
              >
                <div
                  id="card-content-container"
                  className={`w-full max-w-[55rem] [grid-area:span_1_/_span_1_/_span_1_/_span_1] ${styles.cardContentContain}`}
                >
                  <Link
                    to="https://billing.stripe.com/p/login/4gwdU19JOaqgcA8cMM"
                    target="_blank"
                    className={`group w-full max-w-[55rem] [grid-area:span_1_/_span_1_/_span_1_/_span_1] ${styles.selected} ${styles.manageAccountLink}`}
                  >
                    <p className="text-base font-bold leading-[116.667%] md:text-xl lg:text-2xl">
                      Update Billing
                    </p>
                    <div
                      className={`group-hover:scale-110 ${styles.manageAccountArrow} ${styles["w-embed"]}`}
                    >
                      <ManageAccountArrow />
                    </div>
                  </Link>
                  <div
                    className={`group p-[1rem_1.25rem] md:p-[1.25rem_1.875rem] lg:p-[1.875rem_2.5rem] ${styles.manageAccountLink}`}
                    onClick={openModal}
                  >
                    <p className="text-base font-bold leading-[116.667%] md:text-xl lg:text-2xl">
                      Cancel
                    </p>
                    <div
                      className={`group-hover:scale-110 ${styles.manageAccountArrow} ${styles["w-embed"]}`}
                    >
                      <ManageAccountArrow />
                    </div>
                  </div>
                  <Link
                    to="https://discord.gg/urfhuyhTXd"
                    target="_blank"
                    className={`group p-[1rem_1.25rem] md:p-[1.25rem_1.875rem] lg:p-[1.875rem_2.5rem] ${styles.manageAccountLink}`}
                  >
                    <p className="text-base font-bold leading-[116.667%] md:text-xl lg:text-2xl">
                      Community Support
                    </p>
                    <div
                      className={`group-hover:scale-110 ${styles.manageAccountArrow} ${styles["w-embed"]}`}
                    >
                      <ManageAccountArrow />
                    </div>
                  </Link>
                  <Link
                    to="/docs/get-started/setup-madalgo"
                    className={`group p-[1rem_1.25rem] md:p-[1.25rem_1.875rem] lg:p-[1.875rem_2.5rem] ${styles.manageAccountLink}`}
                  >
                    <p className="text-base font-bold leading-[116.667%] md:text-xl lg:text-2xl">
                      Help in docs
                    </p>
                    <div
                      className={`group-hover:scale-110 ${styles.manageAccountArrow} ${styles["w-embed"]}`}
                    >
                      <ManageAccountArrow />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div>
              <Modal
                showModal={showModal}
                closeModal={closeModal}
                email={email}
                setEmail={setEmail}
                cancelSubscription={cancelSubscription}
              />
            </div>
          </div>
        </div>
        <div
          id="manage-account-card"
          className="w-full px-7 pt-16 md:px-16 lg:grid lg:justify-center lg:px-28"
        >
          <div
            id="manage-account-card-container"
            className="flex max-w-5xl flex-col"
          >
            <div id="card" className={`${styles.card} ${styles.emailSupport}`}>
              <div
                id="heading-email-support"
                className="[grid-area:span_1_/_span_1_/_span_1_/_span_1]"
              >
                <h2 className="my-0 text-base font-bold leading-[116.667%] md:text-xl lg:text-2xl">
                  Have any other questions or in need of direct support?
                </h2>
              </div>
              <div
                id="email-support-content"
                className=" flex w-full flex-col [grid-row-gap:1.5rem]"
              >
                <p className="mb-0 mt-0 text-lg font-normal leading-[130%] tracking-[.1px]">
                  Send an email to{" "}
                  <Link
                    className="bg-[rgba(0,0,0,0)] text-purple-500 underline outline-none"
                    to="mailto:support@madalgo.io"
                  >
                    support@madalgo.io
                  </Link>{" "}
                  or contact us through our Community Support and someone will
                  get right with you!
                </p>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </section>
    </>
  );
}
