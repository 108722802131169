import React from "react";
import { useState, useEffect } from "react";
import { motion, LayoutGroup } from "framer-motion";

export default function RadioSwitch({ options, value, onChange }) {
  const [isOn, setIsOn] = useState(value === options[1].value);

  useEffect(() => {
    setIsOn(value === options[1].value);
  }, [value, options]);

  const toggleSwitch = () => {
    const newValue = isOn ? options[0].value : options[1].value;
    setIsOn(!isOn);
    onChange(newValue);
  };

  return (
    <div
      className="relative flex cursor-pointer select-none content-start rounded-full bg-white bg-opacity-5 p-1"
      data-ison={isOn}
      onClick={toggleSwitch}
    >
      <LayoutGroup id="radio-switch">
        {options.map((option, index) => (
          <div
            key={option.value}
            className={`relative flex items-center rounded-[2.5rem] px-[1.3rem] py-3 
              ${isOn === (index === 1) ? "text-white" : ""}`}
          >
            {isOn === (index === 1) && (
              <motion.div
                layoutId="outline"
                className="absolute inset-0 rounded-[2.5rem] bg-cyan-500"
                initial={false}
                transition={spring}
              />
            )}
            <p className="relative font-medium">{option.name}</p>
          </div>
        ))}
      </LayoutGroup>
    </div>
  );
}

const spring = {
  type: "spring",
  stiffness: 700,
  damping: 30,
};
