import { Routes, Route } from "react-router-dom";
import CommunityMain from "./CommunityMain";
import CommunityCharts from "./CommunityCharts";
import CommunityEarn from "./CommunityEarn";
// import CommunityAdvocates from "./CommunityAdvocates";

function CommunityMADALGO() {
  return (
    <Routes>
      <Route path="/" element={<CommunityMain />} />
      <Route path="charts" element={<CommunityCharts />} />
      <Route path="earn-program" element={<CommunityEarn />} />
      {/* <Route path="advocate-program" element={<CommunityAdvocates />} /> */}
    </Routes>
  );
}

export default CommunityMADALGO;
