import { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import styles from "../Docs.module.css";
import { useTOC } from "../TOCContext";
import DocImages from "../DocImages";
import ThemeContext from "../ThemeContext";

const entries = [
  {
    displayText: "Features",
    urlFragment: "features",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "Main Settings",
    urlFragment: "main-settings",
    headingType: "h2",
    subEntries: [
      {
        displayText: "Source",
        urlFragment: "source",
        headingType: "h3",
      },
      {
        displayText: "Lookback",
        urlFragment: "lookback",
        headingType: "h3",
      },
      {
        displayText: "Trend Intensity",
        urlFragment: "trend-intensity",
        headingType: "h3",
      },
    ],
  },
  {
    displayText: "Adjusting Settings",
    urlFragment: "adjusting-settings",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "Style Settings",
    urlFragment: "style-settings",
    headingType: "h2",
    subEntries: [],
  },
];
export { entries };

export default function DocsMADALGONavigator() {
  const { addToTOC, removeFromTOC } = useTOC();
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    entries.forEach(addToTOC);
    return () => {
      entries.forEach((entry) => removeFromTOC(entry));
    };
  }, [addToTOC, removeFromTOC]);

  return (
    <>
      <h1 className={styles.h1}>MADALGO Navigator</h1>
      <p className={styles.p}>
        MADALGO Navigator is the second key component of the toolkit offered by
        MADALGO, offering a wide spectrum of price action related tools, zone &
        divergence finders, and much more . It equips traders with precisive
        tools to make enhanced trading decisions based on the current market
        structure. Provided below are the pages describing each of the features
        in the main indicator in detail:
      </p>

      <h2 id="features" className={`scroll-mt-2 ${styles["h2"]}`}>
        Features
        <Link
          to="#features"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <Link
        to="institutional-liquidity-zones"
        className={`
          ${theme === "light" ? styles.changelogLink : styles.changelogLinkDark}
        `}
      >
        <span
          style={{
            userSelect: "none",
            marginRight: 10,
            marginBottom: 3,
          }}
        >
          »
        </span>
        Institutional Liquidity Zones
      </Link>
      <Link
        to="divergence-detector"
        className={`
          ${theme === "light" ? styles.changelogLink : styles.changelogLinkDark}
        `}
      >
        <span
          style={{
            userSelect: "none",
            marginRight: 10,
            marginBottom: 3,
          }}
        >
          »
        </span>
        Divergence Detector
      </Link>
      <Link
        to="target-scope"
        className={`
          ${theme === "light" ? styles.changelogLink : styles.changelogLinkDark}
        `}
      >
        <span
          style={{
            userSelect: "none",
            marginRight: 10,
            marginBottom: 3,
          }}
        >
          »
        </span>
        TargetScope
      </Link>
      <Link
        to="trendshift-clouds"
        className={`
          ${theme === "light" ? styles.changelogLink : styles.changelogLinkDark}
        `}
      >
        <span
          style={{
            userSelect: "none",
            marginRight: 10,
            marginBottom: 3,
          }}
        >
          »
        </span>
        TrendShift Clouds
      </Link>
      <Link
        to="true-range-fvg"
        className={`
          ${theme === "light" ? styles.changelogLink : styles.changelogLinkDark}
        `}
      >
        <span
          style={{
            userSelect: "none",
            marginRight: 10,
            marginBottom: 3,
          }}
        >
          »
        </span>
        True Range FVG
      </Link>

      <h2 id="main-settings" className={`scroll-mt-2 ${styles["h2"]}`}>
        Main Settings
        <Link
          to="#main-settings"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        The MADALGO Navigator like the Pulse presents an array of settings
        influencing its behavior and visual aspects. These settings are specific
        to their corresponding features and are elaborated on their respective
        pages. The indicator comprises multiple sections for settings. The
        foundational settings are detailed in the subsequent sections:
      </p>

      <h3 id="source" className={`scroll-mt-2 ${styles["h3"]}`}>
        Source
        <Link
          to="#source"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        The "Source" setting in MADALGO Navigator is a critical choice that
        determines the specific price data used for calculations in various
        features of the indicator. It offers options such as "open," "high,"
        "low," "close," and "OHLC4" (the average of open, high, low, and close
        prices). Selecting the appropriate source is essential, as it directly
        impacts the accuracy and relevance of the insights provided by the
        indicator. Different features may require different sources to generate
        meaningful signals and trend analysis.
      </p>

      <h3 id="lookback" className={`scroll-mt-2 ${styles["h3"]}`}>
        Lookback
        <Link
          to="#lookback"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        The "Lookback" setting allows users to define the number of past data
        points considered when calculating various indicators and analysis tools
        within the MADALGO Navigator. By adjusting the lookback period, traders
        can control the responsiveness of indicators to recent price movements.
        Shorter lookback periods result in indicators that react quickly to
        market changes, potentially generating more frequent signals.
        Conversely, longer lookback periods provide a smoother view of trends
        but may lag behind rapid price shifts. Selecting an optimal lookback
        period involves finding the right balance between timely signals and
        reliable trend identification.
      </p>

      <h3 id="trend-intensity" className={`scroll-mt-2 ${styles["h3"]}`}>
        Trend Intensity
        <Link
          to="#trend-intensity"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        "Trend Intensity" in the MADALGO Navigator refers to the strength and
        momentum of a prevailing market trend. This concept is assessed using
        various indicators that analyze price movements over a specified period.
        A strong trend is characterized by consistent price movements in one
        direction, while a weaker trend may display more volatility and
        fluctuations. The trend intensity feature help users identify whether a
        trend is gaining or losing momentum. Understanding trend intensity
        assists traders in making informed decisions about position entries,
        exits, and risk management. It's a valuable tool for gauging the
        potential duration and magnitude of a trend.
      </p>

      <h2 id="adjusting-settings" className={`scroll-mt-2 ${styles["h2"]}`}>
        Adjusting Settings
        <Link
          to="#adjusting-settings"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[111]}
          width={421}
          height={434}
          className="mx-auto"
          alt="Navigator Adjusting Settings"
        ></img>
      </p>
      <p className={styles.p}>
        To fine-tune settings, hover your cursor over the indicator title,
        prompting a list of icons. Click on the gear ⚙️ icon to open the
        settings panel.
      </p>
      <p className={styles.p}>
        Alternatively, double-click any element displayed by the indicator, be
        it signals, overlaid indicators, or the dashboard, to access the
        settings panel.
      </p>

      <h2 id="style-settings" className={`scroll-mt-2 ${styles["h2"]}`}>
        Style Settings
        <Link
          to="#style-settings"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        Every feature is customizable in terms of color, accommodating
        individual user preferences for a seamless UI/UX experience.
      </p>
    </>
  );
}
