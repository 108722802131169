import { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import styles from "../Docs.module.css";
import { useTOC } from "../TOCContext";
import DocImages from "../DocImages";
import ThemeContext from "../ThemeContext";

const entries = [
  {
    displayText: "Categories Of Trends",
    urlFragment: "categories-of-trends",
    headingType: "h2",
    subEntries: [
      {
        displayText: "Ascending Trends",
        urlFragment: "ascending-trends",
        headingType: "h3",
      },
      {
        displayText: "Descending Trends",
        urlFragment: "descending-trends",
        headingType: "h3",
      },
      {
        displayText: "Horizontal Trends",
        urlFragment: "horizontal-trends",
        headingType: "h3",
      },
    ],
  },
  {
    displayText: "Pullbacks",
    urlFragment: "pullbacks",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "Shifts in Trend",
    urlFragment: "shifts-in-trend",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "Leveraging AI for Trend Identification with MADALGO",
    urlFragment: "leveraging-ai-for-trend-identification-with-madalgo",
    headingType: "h2",
    subEntries: [],
  },
];
export { entries };

export default function DocsTechnicalAnalysisTrends() {
  const { addToTOC, removeFromTOC } = useTOC();
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    entries.forEach(addToTOC);
    return () => {
      entries.forEach((entry) => removeFromTOC(entry));
    };
  }, [addToTOC, removeFromTOC]);

  return (
    <>
      <h1 className={styles.h1}>Trends</h1>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[40]}
          width={919}
          height={514}
          className="mx-auto"
          alt="Types of Trends"
        ></img>
      </p>

      <p className={styles.p}>
        Trends depict the predominant direction of price movement over a notable
        duration. Over time, historical prices manifest different trend
        patterns—some ascend, some descend, and some remain stable.
      </p>
      <p className={styles.p}>
        The core trends in prices often mingle with other fluctuations, such as
        cyclical and random variations. These extraneous fluctuations pose
        challenges for traders aiming to capitalize on the primary trend.
      </p>
      <h2 id="categories-of-trends" className={`scroll-mt-2 ${styles["h2"]}`}>
        Categories Of Trends
        <Link
          to="#categories-of-trends"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        Trends are categorized based on their directional movement.
      </p>

      <h3 id="ascending-trends" className={`scroll-mt-2 ${styles["h3"]}`}>
        Ascending Trends
        <Link
          to="#ascending-trends"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[41]}
          width={718}
          height={401}
          className="mx-auto"
          alt="Uptrend"
        ></img>
      </p>
      <p className={styles.p}>
        An ascending trend manifests when prices consistently climb,
        establishing frequent new peaks and troughs at higher levels.
      </p>

      <h3 id="descending-trends" className={`scroll-mt-2 ${styles["h3"]}`}>
        Descending Trends
        <Link
          to="#descending-trends"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[42]}
          width={718}
          height={401}
          className="mx-auto"
          alt="Downtrend"
        ></img>
      </p>
      <p className={styles.p}>
        In a descending trend, prices consistently fall, often setting new
        troughs and peaks at lower levels.
      </p>

      <h3 id="changing-the-timeframe" className={`scroll-mt-2 ${styles["h3"]}`}>
        Horizontal Trends
        <Link
          to="#changing-the-timeframe"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[43]}
          width={1200}
          height={324}
          alt="Sideways Trend"
        ></img>
      </p>
      <p className={styles.p}>
        Horizontal trends indicate a market that remains static. Prices neither
        set new peaks nor new troughs but oscillate within a determined
        boundary.
      </p>
      <p className={styles.p}>
        In such trends, it's common to say the market is in a holding pattern,
        accumulating, or, occasionally, in a trend equilibrium.
      </p>
      <h2 id="pullbacks" className={`scroll-mt-2 ${styles["h2"]}`}>
        Pullbacks
        <Link
          to="#pullbacks"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        Though trends might follow a set direction, they can exhibit different
        forms—straight or curved—and may experience pullbacks. These are
        momentary reversals in the direction, contrary to the primary trend.
        Such pullbacks are often the result of seasonal price fluctuations and
        can influence the efficacy of trend-based trading strategies.
      </p>
      <h2 id="shifts-in-trend" className={`scroll-mt-2 ${styles["h2"]}`}>
        Shifts in Trend
        <Link
          to="#shifts-in-trend"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        Trend shifts represent changes in the established direction. While many
        traders rely on identifying and following the trend direction for their
        trades, some seek to pinpoint these shifts. The potential rewards are
        higher, but identifying trend shifts is intricate.
      </p>
      <p className={styles.p}>
        Technical analysts believe that the longer a shift in trend direction is
        in the making, the more significant the subsequent price movement will
        be.
      </p>
      <h2
        id="leveraging-ai-for-trend-identification-with-madalgo"
        className={`scroll-mt-2 ${styles["h2"]}`}
      >
        Leveraging AI for Trend Identification with MADALGO
        <Link
          to="#leveraging-ai-for-trend-identification-with-madalgo"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        MADALGO Elite employs advanced AI-driven indicators and algorithms to
        offer nuanced insights into market trends. These indicators, powered by
        cutting-edge algorithms, provide signals that allow traders to gauge not
        only the direction of the market trend but also its strength and
        potential longevity. Whether the market is holding steady or trending,
        MADALGO's AI-powered tools equip traders with the necessary information
        to make informed decisions. Dive deeper into how it works through the
        link provided.
      </p>
      <Link
        to="/docs/madalgo-premium-solutions/madalgo-pulse"
        className={`
          ${theme === "light" ? styles.changelogLink : styles.changelogLinkDark}
        `}
      >
        <span
          style={{
            userSelect: "none",
            marginRight: 10,
            marginBottom: 3,
          }}
        >
          »
        </span>
        MADALGO Premium Pulse
      </Link>
    </>
  );
}
