import React from "react";
import images from "./images";
import { Link } from "react-router-dom";

const BrandGuidelines = () => {
  const colorPalette = (
    name,
    colorInfo,
    bgColor,
    nameColor = "text-black",
    infoColor = "text-gray-400",
  ) => (
    <div
      className={`flex h-32 w-full max-w-[21.9rem] items-start justify-between rounded-lg bg-white p-4 shadow-md`}
      style={{
        backgroundColor: bgColor,
        backgroundImage: name === "Gradient" ? bgColor : "",
      }}
    >
      <div className="flex h-full flex-col justify-between">
        <p className={`text-lg font-semibold ${nameColor}`}>{name}</p>
        <div>
          <p className={`text-sm font-semibold ${infoColor}`}>{colorInfo[0]}</p>{" "}
          <p className={`text-sm font-semibold ${infoColor}`}>{colorInfo[1]}</p>{" "}
        </div>
      </div>
    </div>
  );

  return (
    <div className="mx-auto max-w-[1180px]">
      <div className="py-10"></div>
      <div className="grid grid-cols-1 items-center gap-10 p-6 pb-16 md:grid-cols-2 md:pl-14 lg:pt-28">
        <div>
          <h2 className="pb-4 text-center text-3xl font-extrabold uppercase md:text-left md:text-4xl lg:text-6xl">
            Brand
          </h2>
          <p className="text-center text-sm md:text-left md:text-base lg:text-lg">
            Our brand is more than just a logo. It's a representation of our
            values, our mission, and our commitment to excellence. From our
            distinctive colors to our visual style, every element works together
            to create a cohesive and memorable identity.
          </p>
        </div>
        <img
          className="mx-auto h-[156px] w-[200px] max-w-xs"
          src={images[34]}
          alt="Brand"
        />
      </div>
      <div className="mx-auto p-6 pb-40 md:pl-14">
        <section className="lg:mb10 mb-8">
          <h2 className="mb-4 text-3xl font-semibold md:text-left md:text-4xl lg:text-6xl">
            Our Logo
          </h2>
          <p className="mb-5 text-sm md:text-base lg:text-lg">
            Please do not edit, change, distort, recolor, or reconfigure the
            MADALGO logo.
          </p>
          <div className="grid auto-cols-[1fr] grid-cols-[1fr] grid-rows-[auto] gap-x-6 gap-y-6 md:grid-cols-[1fr_1fr_1fr]">
            <div
              className="relative flex h-32 min-h-[160px] w-full max-w-[27rem] items-center justify-center rounded-lg border-2 border-solid border-[#373a3d] p-4 shadow-md [grid-area:span_1/span_1/span_1/span_1]"
              style={{
                backgroundImage: `url(${images[33]})`,
                backgroundSize: "30px",
                backgroundPosition: "center",
              }}
            >
              <img
                src={images[35]}
                alt="Centered"
                className="w-full max-w-[292px]"
              />
              <div className="absolute bottom-0 left-auto right-0 top-auto flex">
                <a
                  data-download-file="M-MADALGO-WHITE.svg"
                  data-track="branding-page-white-logo-svg"
                  href="/downloads/M-MADALGO-WHITE.svg"
                  target="_blank"
                  className="inline-block max-w-full [text-decoration:none]"
                >
                  <div className="mr-2 text-sm font-bold leading-6 text-blue-600 [text-decoration:none]">
                    .svg
                  </div>
                </a>
                <a
                  data-download-file="M-MADALGO-WHITE.png"
                  data-track="branding-page-white-logo-png"
                  href="/downloads/M-MADALGO-WHITE.png"
                  target="_blank"
                  className="inline-block max-w-full [text-decoration:none]"
                >
                  <div className="mr-1 text-sm font-bold leading-6 text-blue-600 [text-decoration:none]">
                    .png
                  </div>
                </a>
              </div>
            </div>
            <div
              className="relative flex h-32 min-h-[160px] w-full max-w-[27rem] items-center justify-center rounded-lg border-2 border-solid border-[#373a3d] p-4 shadow-md [grid-area:span_1/span_1/span_1/span_1]"
              style={{
                backgroundImage: `url(${images[33]})`,
                backgroundSize: "30px",
                backgroundPosition: "center",
              }}
            >
              <img
                src={images[36]}
                alt="Centered"
                className="w-full max-w-[292px]"
              />
              <div className="absolute bottom-0 left-auto right-0 top-auto flex">
                <a
                  data-download-file="M-MADALGO-BLACK.svg"
                  data-track="branding-page-black-logo-svg"
                  href="/downloads/M-MADALGO-BLACK.svg"
                  target="_blank"
                  className="inline-block max-w-full [text-decoration:none]"
                >
                  <div className="mr-2 text-sm font-bold leading-6 text-blue-600 [text-decoration:none]">
                    .svg
                  </div>
                </a>
                <a
                  data-download-file="M-MADALGO-BLACK.png"
                  data-track="branding-page-black-logo-png"
                  href="/downloads/M-MADALGO-BLACK.png"
                  target="_blank"
                  className="inline-block max-w-full [text-decoration:none]"
                >
                  <div className="mr-1 text-sm font-bold leading-6 text-blue-600 [text-decoration:none]">
                    .png
                  </div>
                </a>
              </div>
            </div>
            <div
              className="relative flex h-32 min-h-[160px] w-full max-w-[27rem] items-center justify-center rounded-lg border-2 border-solid border-[#373a3d] p-4 shadow-md [grid-area:span_1/span_1/span_1/span_1]"
              style={{
                backgroundImage: `url(${images[33]})`,
                backgroundSize: "30px",
                backgroundPosition: "center",
              }}
            >
              <img
                src={images[37]}
                alt="Centered"
                className="w-full max-w-[292px]"
              />
              <div className="absolute bottom-0 left-auto right-0 top-auto flex">
                <a
                  data-download-file="M-MADALGO-G.svg"
                  data-track="branding-page-g-logo-svg"
                  href="/downloads/M-MADALGO-G.svg"
                  target="_blank"
                  className="inline-block max-w-full [text-decoration:none]"
                >
                  <div className="mr-2 text-sm font-bold leading-6 text-blue-600 [text-decoration:none]">
                    .svg
                  </div>
                </a>
                <a
                  data-download-file="M-MADALGO-G.png"
                  data-track="branding-page-g-logo-png"
                  href="/downloads/M-MADALGO-G.png"
                  target="_blank"
                  className="inline-block max-w-full [text-decoration:none]"
                >
                  <div className="mr-1 text-sm font-bold leading-6 text-blue-600 [text-decoration:none]">
                    .png
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="mb-16 lg:mb-20">
          <h2 className="mb-4 text-3xl font-semibold md:text-left md:text-4xl lg:text-6xl">
            Alternate Logos
          </h2>
          <p className="mb-5 text-sm md:text-base lg:text-lg">
            Use these only when the MADALGO brand is clearly visible or has been
            well established elsewhere on the page or in the design.{" "}
            <span className="text-yellow-500">(</span>
            When in doubt, use the other one.
            <span className="text-yellow-500">)</span>
          </p>
          <div className="grid auto-cols-[1fr] grid-cols-[1fr] grid-rows-[auto] gap-x-6 gap-y-6 md:grid-cols-[1fr_1fr_1fr]">
            <div
              className="relative flex h-32 min-h-[160px] w-full max-w-[27rem] items-center justify-center rounded-lg border-2 border-solid border-[#373a3d] p-4 shadow-md [grid-area:span_1/span_1/span_1/span_1]"
              style={{
                backgroundImage: `url(${images[33]})`,
                backgroundSize: "30px",
                backgroundPosition: "center",
              }}
            >
              <img
                src={images[38]}
                alt="Centered"
                className="w-full max-w-[71px]"
              />
              <div className="absolute bottom-0 left-auto right-0 top-auto flex">
                <a
                  data-download-file="MWHITE.svg"
                  data-track="branding-logo-svg"
                  href="/downloads/MWHITE.svg"
                  target="_blank"
                  className="inline-block max-w-full [text-decoration:none]"
                >
                  <div className="mr-2 text-sm font-bold leading-6 text-blue-600 [text-decoration:none]">
                    .svg
                  </div>
                </a>
                <a
                  data-download-file="MWHITE.png"
                  data-track="branding-logo-png"
                  href="/downloads/MWHITE.png"
                  target="_blank"
                  className="inline-block max-w-full [text-decoration:none]"
                >
                  <div className="mr-1 text-sm font-bold leading-6 text-blue-600 [text-decoration:none]">
                    .png
                  </div>
                </a>
              </div>
            </div>
            <div
              className="relative flex h-32 min-h-[160px] w-full max-w-[27rem] items-center justify-center rounded-lg border-2 border-solid border-[#373a3d] p-4 shadow-md [grid-area:span_1/span_1/span_1/span_1]"
              style={{
                backgroundImage: `url(${images[33]})`,
                backgroundSize: "30px",
                backgroundPosition: "center",
              }}
            >
              <img
                src={images[39]}
                alt="Centered"
                className="w-full max-w-[71px]"
              />
              <div className="absolute bottom-0 left-auto right-0 top-auto flex">
                <a
                  data-download-file="MBLACK.svg"
                  data-track="branding-logo-svg"
                  href="/downloads/MBLACK.svg"
                  target="_blank"
                  className="inline-block max-w-full [text-decoration:none]"
                >
                  <div className="mr-2 text-sm font-bold leading-6 text-blue-600 [text-decoration:none]">
                    .svg
                  </div>
                </a>
                <a
                  data-download-file="MBLACK.png"
                  data-track="branding-logo-png"
                  href="/downloads/MBLACK.png"
                  target="_blank"
                  className="inline-block max-w-full [text-decoration:none]"
                >
                  <div className="mr-1 text-sm font-bold leading-6 text-blue-600 [text-decoration:none]">
                    .png
                  </div>
                </a>
              </div>
            </div>
            <div
              className="relative flex h-32 min-h-[160px] w-full max-w-[27rem] items-center justify-center rounded-lg border-2 border-solid border-[#373a3d] p-4 shadow-md [grid-area:span_1/span_1/span_1/span_1]"
              style={{
                backgroundImage: `url(${images[33]})`,
                backgroundSize: "30px",
                backgroundPosition: "center",
              }}
            >
              <img
                src={images[40]}
                alt="Centered"
                className="w-full max-w-[71px]"
              />
              <div className="absolute bottom-0 left-auto right-0 top-auto flex">
                <a
                  data-download-file="MG.svg"
                  data-track="branding-logo-svg"
                  href="/downloads/MG.svg"
                  target="_blank"
                  className="inline-block max-w-full [text-decoration:none]"
                >
                  <div className="mr-2 text-sm font-bold leading-6 text-blue-600 [text-decoration:none]">
                    .svg
                  </div>
                </a>
                <a
                  data-download-file="MG.png"
                  data-track="branding-logo-png"
                  href="/downloads/MG.png"
                  target="_blank"
                  className="inline-block max-w-full [text-decoration:none]"
                >
                  <div className="mr-1 text-sm font-bold leading-6 text-blue-600 [text-decoration:none]">
                    .png
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="mb-16 lg:mb-20">
          <h2 className="mb-8 text-3xl font-semibold md:text-4xl lg:text-6xl">
            Colors
          </h2>
          <div className="flex flex-wrap justify-center gap-6 lg:justify-start">
            {colorPalette(
              "Gradient",
              ["45°", "#00ffff - #ff00ff"],
              "linear-gradient(45deg, #00ffff, #ff00ff)",
              "text-white",
              "text-zinc-800",
            )}
            {colorPalette(
              "Fuchsia",
              ["#F4429E", "CMYK 0, 75, 0, 0"],
              "#F4429E",
              "text-white",
              "text-zinc-100",
            )}
            {colorPalette(
              "Yellow",
              ["#F4E542", "CMYK 0, 0, 89, 0"],
              "#F4E542",
              "text-black",
              "text-zinc-800",
            )}
            {colorPalette(
              "Green",
              ["#42F44e", "CMYK 59, 0, 100, 0"],
              "#42F44e",
              "text-black",
              "text-zinc-800",
            )}
            {colorPalette(
              "Blue",
              ["#4275F4", "CMYK 75, 57, 0, 0"],
              "#4275F4",
              "text-white",
              "text-zinc-100",
            )}
            {colorPalette(
              "Red",
              ["#F44242", "CMYK 0, 89, 76, 0"],
              "#F44242",
              "text-white",
              "text-zinc-100",
            )}
            {colorPalette(
              "White",
              ["#FFFFFF", "CMYK 0, 0, 0, 0"],
              "#FFFFFF",
              "text-black",
              "text-zinc-800",
            )}
            {colorPalette(
              "Black",
              ["#000000", "CMYK 0, 0, 0, 100"],
              "#000000",
              "text-white",
              "text-zinc-200",
            )}
          </div>
        </section>
        <section className="mb-0">
          <h2 className="mb-6 text-left text-2xl leading-8 lg:text-4xl">
            Looking for more?
          </h2>
          <p className="mx-0 mb-5 max-w-none text-left text-base leading-6 lg:text-lg lg:leading-8">
            Check out our growing Community!
          </p>
          <div className="btn-wrapper">
            <Link
              to="https://discord.gg/fVpF6zVT5Y"
              className="group mb-0 ml-0 mr-0 mt-0 inline-block w-full max-w-full rounded-[99px] bg-[#4275F4] p-[12px_32px_15px] px-5 text-center font-medium text-white transition-all duration-300 sm:mr-auto sm:rounded-3xl md:mb-5 md:mr-5 md:w-28 lg:hover:-translate-y-0.5 lg:hover:bg-[#425df4] lg:hover:shadow-xl lg:hover:[box-shadow:_0_2px_9px_rgba(136,145,242,.44);] lg:hover:[outline:0]"
              target="_blank"
            >
              <div className="ml-0 text-center text-sm font-bold capitalize transition-opacity duration-200">
                Join
              </div>
            </Link>
          </div>
        </section>
      </div>
    </div>
  );
};

export default BrandGuidelines;
