import { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import styles from "../Docs.module.css";
import { useTOC } from "../TOCContext";
import DocImages from "../DocImages";
import ThemeContext from "../ThemeContext";

const entries = [
  {
    displayText: "Overview",
    urlFragment: "overview",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "Settings",
    urlFragment: "settings",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "Histogram Patterns and Interpretation",
    urlFragment: "histogram-patterns-and-interpretation",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "How to Use",
    urlFragment: "how-to-use",
    headingType: "h2",
    subEntries: [],
  },
];
export { entries };

export default function DocsMADALGOPulseVolumeProfilePro() {
  const { theme } = useContext(ThemeContext);
  const { addToTOC, removeFromTOC } = useTOC();

  useEffect(() => {
    entries.forEach(addToTOC);
    return () => {
      entries.forEach((entry) => removeFromTOC(entry));
    };
  }, [addToTOC, removeFromTOC]);

  return (
    <>
      <h1 className={styles.h1}>Volume Profile Pro</h1>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[107]}
          width={2409}
          height={1239}
          className="mx-auto"
          alt="Volume Profile Pro"
        ></img>
      </p>
      <p className={styles.p}>
        The Volume Profile Pro indicator is a powerful tool within MADALGO Pulse
        that allows traders to analyze volume distribution across different
        price levels. By visualizing the volume profile, traders can identify
        areas of high trading activity and make informed trading decisions. This
        guide will provide you with comprehensive insights into effectively
        using the Volume Profile Pro indicator.
      </p>

      <h2 id="overview" className={`scroll-mt-2 ${styles["h2"]}`}>
        Overview
        <Link
          to="#overview"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        The Volume Profile Pro indicator provides valuable information about
        volume distribution within a specified range. This information is
        displayed through histograms on your price chart, allowing you to
        identify price levels with the highest trading activity. By observing
        these histograms, you can better understand where buying and selling
        pressures are concentrated.
      </p>

      <h2 id="settings" className={`scroll-mt-2 ${styles["h2"]}`}>
        Settings
        <Link
          to="#settings"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        Before using the Volume Profile Pro indicator, familiarize yourself with
        its customizable settings. Here are the key settings you can adjust:
      </p>
      <ol className={styles.olBullet}>
        <li>
          FRVP (Fixed Ranged Volume Profile): Enable or disable the Fixed Ranged
          Volume Profile feature, which uses a lookback algorithm to determine
          proper lookback variables for the fixed range.
        </li>
        <li>
          VRVP (Visible Range Volume Profile): Enable or disable the Visible
          Range Volume Profile feature, which uses a real-time visible
          algorithm.
        </li>
        <li>
          Flip: Enable or disable flipping the Volume Profile Pro from one side
          of the chart to the other.
        </li>
        <li>
          Up Volume Color: Set the color for histograms representing up volume.
        </li>
        <li>
          Down Volume Color: Set the color for histograms representing down
          volume.
        </li>
        <li>
          Offset: Set the offset value for histograms when flipping the Volume
          Profile Pro.
        </li>
        <li>Width: Set the overall width of histograms.</li>
      </ol>

      <h2
        id="histogram-patterns-and-interpretation"
        className={`scroll-mt-2 ${styles["h2"]}`}
      >
        Histogram Patterns and Interpretation
        <Link
          to="#histogram-patterns-and-interpretation"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        The Volume Profile Pro indicator displays histograms that represent
        volume distribution across price levels. Here's how to interpret the
        histograms:
      </p>
      <ol className={styles.olBullet}>
        <li>Up Volume Histogram</li>
      </ol>
      <figure className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[108]}
          width={2119}
          height={1068}
          className="mx-auto"
          alt="Up Histogram Only"
        ></img>
        <figcaption
          style={{
            textAlign: "center",
            fontSize: 12,
          }}
        >
          Up Volume Histogram Only
        </figcaption>
      </figure>
      <p className={styles.p}>
        Displays the volume distribution associated with price levels during
        bullish price movements.
      </p>

      <ol className={styles.olBullet}>
        <li>Down Volume Histogram</li>
      </ol>
      <figure className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[109]}
          width={1237}
          height={863}
          className="mx-auto"
          alt="Down Histogram Only"
        ></img>
        <figcaption
          style={{
            textAlign: "center",
            fontSize: 12,
          }}
        >
          Down Volume Histogram Only
        </figcaption>
      </figure>
      <p className={styles.p}>
        Displays the volume distribution associated with price levels during
        bearish price movements.
      </p>

      <div
        className={`
        info info-tip selection:bg-[#c4f6ff] selection:text-[#2c2c2c49]
        ${theme === "light" ? styles.infoTipInfo : styles.infoTipInfoDark} 
        `}
      >
        <div className="info-heading">
          <h5 className={styles.h5}>
            <span
              className={`
                ${theme === "light" ? styles.infoTip : styles.infoTipDark}
              `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 12 16"
              >
                <path
                  fillRule="evenodd"
                  d="M6.5 0C3.48 0 1 2.19 1 5c0 .92.55 2.25 1 3 1.34 2.25 1.78 2.78 2 4v1h5v-1c.22-1.22.66-1.75 2-4 .45-.75 1-2.08 1-3 0-2.81-2.48-5-5.5-5zm3.64 7.48c-.25.44-.47.8-.67 1.11-.86 1.41-1.25 2.06-1.45 3.23-.02.05-.02.11-.02.17H5c0-.06 0-.13-.02-.17-.2-1.17-.59-1.83-1.45-3.23-.2-.31-.42-.67-.67-1.11C2.44 6.78 2 5.65 2 5c0-2.2 2.02-4 4.5-4 1.22 0 2.36.42 3.22 1.19C10.55 2.94 11 3.94 11 5c0 .66-.44 1.78-.86 2.48zM4 14h5c-.23 1.14-1.3 2-2.5 2s-2.27-.86-2.5-2z"
                ></path>
              </svg>
            </span>
            Tip
          </h5>
        </div>
        <div className="warning-content">
          <p>
            The Volume Profile Pro indicator is particularly useful for
            identifying support and resistance levels based on volume
            distribution. It can help you spot price areas where significant
            market activity occurs
          </p>
        </div>
      </div>

      <h2 id="how-to-use" className={`scroll-mt-2 ${styles["h2"]}`}>
        How to Use
        <Link
          to="#how-to-use"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[110]}
          width={545}
          height={370}
          className="mx-auto"
          alt="Volume Profile Pro - How to Use"
        ></img>
      </p>
      <ol className={styles.ol}>
        <li>
          Choose Profile Type: Decide whether to use the Fixed Ranged Volume
          Profile (FRVP) or the Visible Range Volume Profile (VRVP). You can
          also enable the "Flip" option to adjust the placement of histograms.
        </li>
        <li>
          Set Histogram Colors: Customize the colors of up and down volume
          histograms to match your preferences.
        </li>
        <li>
          Adjust Offset and Width: Fine-tune the offset and overall width of the
          histograms based on your chart display preferences.
        </li>
        <li>
          Observe Histograms: Pay attention to the histograms displayed on your
          price chart. Each histogram represents volume distribution at specific
          price levels.
        </li>
        <li>
          Interpret Histograms: Identify areas of high trading activity by
          observing taller histograms. These levels often indicate significant
          support or resistance zones.
        </li>
        <li>
          Combine with Technical Analysis: Use the Volume Profile Pro indicator
          alongside our other technical analysis tools, such as Hyper Trend or
          VersaMA, to validate your trading decisions.
        </li>
        <li>
          Confirm Reversal Points: Volume accumulation at specific price levels
          can signal potential trend reversal or continuation points.
        </li>
      </ol>

      <p className={styles.p}>
        By understanding how to choose between FRVP and VRVP, interpreting
        histogram patterns, and customizing the indicator's settings, you can
        enhance your trading strategy and make more informed trading decisions.
      </p>

      <div
        className={`warning warning-caution selection:bg-[#ffeec4] selection:text-[#4b4b4b] ${styles["alertWarning"]}`}
      >
        <div className="warning-heading">
          <h5 className={styles.h5}>
            <span className={styles.alertWarningIcon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M8.893 1.5c-.183-.31-.52-.5-.887-.5s-.703.19-.886.5L.138 13.499a.98.98 0 0 0 0 1.001c.193.31.53.501.886.501h13.964c.367 0 .704-.19.877-.5a1.03 1.03 0 0 0 .01-1.002L8.893 1.5zm.133 11.497H6.987v-2.003h2.039v2.003zm0-3.004H6.987V5.987h2.039v4.006z"
                ></path>
              </svg>
            </span>
            caution
          </h5>
        </div>
        <div className="warning-content">
          <p>
            While the indicator provides insights into volume distribution,
            always consider using it in conjunction with other technical
            indicators and risk management strategies.
          </p>
        </div>
      </div>
    </>
  );
}
