import { useState } from "react";
import styles from "./Main.module.css";
import { Link } from "react-router-dom";
import images from "./blogfeatureimages";
import { motion } from "framer-motion";
import BlogPosts from "./BlogPostEntries";

function BlogMain() {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [activeCategory, setActiveCategory] = useState(null);

  const handleCategoryClick = (category) => {
    setActiveCategory((prevCategory) =>
      prevCategory === category ? null : category,
    );
  };

  const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  // Define handleSearch first
  const handleSearch = (query) => {
    const filteredPosts = BlogPosts.filter((post) =>
      post.title.toLowerCase().includes(query.toLowerCase()),
    );
    setSearchResults(filteredPosts);
  };

  // Then create the debounced function using handleSearch
  const debouncedHandleSearch = debounce(handleSearch, 300);
  let currentSearchId = 0;

  const onSearchChange = (e) => {
    const searchId = ++currentSearchId;
    const inputValue = e.target.value;

    setSearchQuery(inputValue);

    clearTimeout(debouncedHandleSearch.currentTimeout);
    debouncedHandleSearch.currentTimeout = setTimeout(() => {
      if (searchId === currentSearchId) {
        debouncedHandleSearch(inputValue);
      }
    }, 300);
  };

  const renderBlogPosts = (blogPosts) => {
    const filteredPosts = blogPosts.filter((post) => {
      const matchesCategory =
        !activeCategory || post.category === activeCategory;
      const matchesSearch =
        searchResults.length === 0 || searchResults.includes(post);

      return matchesCategory && matchesSearch;
    });

    return filteredPosts.map((post, i) => (
      <motion.div
        initial={{ opacity: 0, translateY: 50 }}
        animate={{ opacity: 1, translateY: 0 }}
        transition={{ duration: 1.5, delay: 1 + i * 0.2 }}
        key={post.slug}
        className="mr-10 w-full flex-none"
      >
        <Link
          className={`${styles.blogLinkItemContainer} ${styles.v4}`}
          to={post.slug}
        >
          <div className="relative h-[45vw] w-full overflow-hidden rounded-[.625rem] sm:h-[40vw] md:h-[25vw] lg:h-[13.3333vw] 2xl:h-[8.3vw]">
            <img
              className="transition-all duration-500 ease-in-out hover:[transform:scale(1.03,1.03)]"
              src={post.image}
              loading="lazy"
              alt={post.title}
              sizes="(max-width: 479px) 88vw, (max-width: 767px) 89vw, (max-width: 1439px) 42vw, 600px"
              srcSet={post.image}
            />
          </div>
          <div className="flex w-full flex-col items-start justify-start p-5 md:p-[1.875rem] lg:p-10">
            <div className={styles.blogThumnailContainer}>
              <h5 className="my-0 text-center text-xs font-medium uppercase leading-[153.846%] text-purple-500 [border:1px_solid_rgba(19,23,34,0)] sm:border-none ">
                {post.category}
              </h5>
            </div>
            <h3 className="my-[.875remrem] text-base font-bold leading-[116.667%] md:text-[1.375rem] lg:text-2xl">
              {post.title}
            </h3>
            <div>
              <h5 className="my-0 text-xs font-medium leading-[153.846%]">
                {post.date}
              </h5>
            </div>
          </div>
        </Link>
      </motion.div>
    ));
  };

  return (
    <motion.div
      id="blog-wrapper"
      className=" relative  w-full overflow-hidden pb-24 text-black"
    >
      <section
        id="blog-content"
        className=" relative z-[1] block min-h-[300px] txsm:min-h-[356px]"
      >
        <div
          id="container"
          className=" flex h-full flex-col justify-center px-7 pb-20 pt-24 [-moz-box-direction:normal] [-moz-box-orient:vertical] [-moz-box-pack:center] lg:px-16"
        >
          <div
            id="heading-wrapper"
            className="relative z-[1] mx-auto mb-16 max-w-[35rem] text-center lg:mb-20"
          >
            <motion.h1
              className="m-[.67em_0] my-0 text-5xl font-bold leading-[110%] lg:text-7xl"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
            >
              MADALGO Blog
            </motion.h1>
            <motion.p
              className="mb-0 mt-10 text-lg font-normal leading-[116.667%] tracking-[.1px] lg:text-xl"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.2 }}
            >
              New Features, Updates, Indicators & Education. Keep in the loop.
            </motion.p>
          </div>
          <div className="blog-list relative z-[1] mx-auto mt-4 flex max-w-[76.5rem] flex-col pl-0 pt-[2.125rem] [grid-row-gap:0rem] ">
            <div className="flex w-full flex-col items-center justify-between md:flex-row">
              <motion.p
                className="mb-0 mt-0 text-sm font-normal leading-[125%] tracking-[.1px] lg:text-base"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, delay: 0.4 }}
              >
                Sort by category
              </motion.p>
            </div>
            <div
              id="blog-filler"
              className="m-[0_0_15px] mb-5 mt-0 md:mb-12 md:mt-6 lg:mt-3"
            >
              <form
                id="email-form-2"
                className="flex flex-col items-center justify-between md:flex-row"
                name="email-form-2"
                data-name="Email Form 2"
                method="get"
              >
                <motion.div
                  id="blog-filter"
                  className="mb-4 mt-[1.625rem] flex flex-wrap items-center justify-center [grid-column-gap:0rem] md:[grid-column-gap:1rem]"
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 1, delay: 0.6 }}
                >
                  <div
                    id="blog-filter-option"
                    className={`${styles.blogFilterOption} ${
                      activeCategory === "New Indicator" && styles.active
                    }`}
                    onClick={() => handleCategoryClick("New Indicator")}
                  >
                    <p className="mb-0 mt-0 select-none whitespace-nowrap text-xs font-medium leading-[100%] tracking-[.1px] transition-opacity duration-200">
                      New indicator
                    </p>
                  </div>
                  <div
                    id="blog-filter-option"
                    className={`${styles.blogFilterOption} ${
                      activeCategory === "Education" && styles.active
                    }`}
                    onClick={() => handleCategoryClick("Education")}
                  >
                    <p className="mb-0 mt-0 select-none whitespace-nowrap text-xs font-medium leading-[100%] tracking-[.1px] transition-opacity duration-200">
                      Education
                    </p>
                  </div>
                  <div
                    id="blog-filter-option"
                    className={`${styles.blogFilterOption} ${
                      activeCategory === "New Feature" && styles.active
                    }`}
                    onClick={() => handleCategoryClick("New Feature")}
                  >
                    <p className="mb-0 mt-0 select-none whitespace-nowrap text-xs font-medium leading-[100%] tracking-[.1px] transition-opacity duration-200">
                      New feature
                    </p>
                  </div>
                </motion.div>

                <motion.div
                  className={styles.blogSearchContainer}
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 1, delay: 0.8 }}
                >
                  <input
                    className={`${styles.blogSearchInput} ${styles.wInput}`}
                    placeholder="Search"
                    type="text"
                    maxLength={256}
                    name="Search"
                    data-name="Search"
                    value={searchQuery}
                    onChange={onSearchChange}
                    required=""
                  />
                  <div className="flex flex-none items-center">
                    <div
                      className={`${styles["w-embed"]} ${styles.linkArrowRight}`}
                    >
                      <svg
                        width="100%"
                        height="100%"
                        viewBox="0 0 20 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 5H19M19 5L14.6667 1M19 5L14.6667 9"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </motion.div>
              </form>
            </div>
            <motion.div
              id="blog-featured-post"
              className="relative z-[1] mx-auto mb-10 flex max-w-[76.5rem] flex-col"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 1 }}
            >
              <div className="w-full">
                <div className="flex w-full" role="list">
                  <div className="mr-10 w-full flex-none md:mr-6 ">
                    <Link
                      className={`${styles.blogLinkItemContainer} ${styles.v3}`}
                      blog-link=""
                      to="introducing-ai-with-madalgo"
                    >
                      <div className="relative h-[45vw] w-full overflow-hidden rounded-[.625rem] sm:h-[40vw] md:h-fit md:w-[41.6667vw] lg:max-w-[600px]">
                        <img
                          className="transition-all duration-500 ease-in-out hover:[transform:scale(1.03,1.03)]"
                          src={images[0]}
                          loading="lazy"
                          alt="Introducing AI with MADALGO"
                          sizes="(max-width: 479px) 88vw, (max-width: 767px) 89vw, (max-width: 1439px) 42vw, 600px"
                          srcSet={images[0]}
                        ></img>
                      </div>
                      <div className=" flex w-full flex-col items-start justify-center p-5 py-4">
                        <div className={styles.blogThumnailContainer}>
                          <h5 className=" my-0 text-center text-xs font-medium uppercase leading-[153.846%] text-purple-500 [border:1px_solid_rgba(19,23,34,0)]">
                            Education
                          </h5>
                        </div>
                        <h3 className=" my-[.625rem] text-lg font-bold leading-[116.667%] md:text-[1.75rem] lg:my-5 lg:text-[2.25rem]">
                          Introducing AI with MADALGO
                        </h3>
                        <div>
                          <h5 className=" my-0 text-xs font-medium leading-[153.846%]">
                            August 27th, 2023
                          </h5>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </motion.div>
            <div className="w-full">
              <motion.div className={styles.blogRecentPostList}>
                {searchResults.length > 0
                  ? renderBlogPosts(searchResults)
                  : renderBlogPosts(BlogPosts)}
              </motion.div>
            </div>
          </div>
        </div>
      </section>
    </motion.div>
  );
}

export default BlogMain;
