const HidePasswordIcon = ({ fill = "#000" }) => {
  return (
    <svg
      width="18"
      height="13"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          d="M9 12c3.13 0 5.732-1.788 7.856-5.5C14.732 2.788 12.13 1 9 1S3.268 2.788 1.144 6.5C3.268 10.212 5.87 12 9 12zM9 0c3.667 0 6.667 2.167 9 6.5-2.333 4.333-5.333 6.5-9 6.5s-6.667-2.167-9-6.5C2.333 2.167 5.333 0 9 0zm0 9a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5zm0 1a3.5 3.5 0 1 1 0-7 3.5 3.5 0 0 1 0 7z"
          id="a"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b" fill={fill}>
          <use xlinkHref="#a" />
        </mask>
        <use fill={fill} fillRule="nonzero" xlinkHref="#a" />
        <g mask="url(#b)" fill={fill}>
          <path d="M-1-4h20v20H-1z" />
        </g>
      </g>
    </svg>
  );
};

export default HidePasswordIcon;
