import { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import styles from "../Docs.module.css";
import { useTOC } from "../TOCContext";
import DocImages from "../DocImages";
import ThemeContext from "../ThemeContext";

const entries = [
  {
    displayText: "Overview",
    urlFragment: "overview",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "Settings",
    urlFragment: "settings",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "Candle Patterns and Interpretation",
    urlFragment: "candle-patterns-and-interpretation",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "How to Use",
    urlFragment: "how-to-use",
    headingType: "h2",
    subEntries: [],
  },
];
export { entries };

export default function DocsMADALGOPulseTrendAlertCandles() {
  const { theme } = useContext(ThemeContext);
  const { addToTOC, removeFromTOC } = useTOC();

  useEffect(() => {
    entries.forEach(addToTOC);
    return () => {
      entries.forEach((entry) => removeFromTOC(entry));
    };
  }, [addToTOC, removeFromTOC]);

  return (
    <>
      <h1 className={styles.h1}>Trend Alert Candles</h1>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[101]}
          width={1321}
          height={770}
          className="mx-auto"
          alt="Trend Alert Candles"
        ></img>
      </p>
      <p className={styles.p}>
        The Trend Alert Candles indicator is a unique feature within MADALGO
        Pulse that helps traders identify potential trend exhaustion points in
        the market. By observing specific candle patterns, traders can
        anticipate trend reversals and make informed trading decisions. This
        guide will provide you with a comprehensive understanding of how to
        effectively use the Trend Alert Candles indicator.
      </p>

      <h2 id="overview" className={`scroll-mt-2 ${styles["h2"]}`}>
        Overview
        <Link
          to="#overview"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        The Trend Alert Candles indicator is designed to catch exhaustion in the
        current trend and highlight it through specific candle color patterns.
        This feature enables traders to spot potential trend reversals and
        adjust their trading strategies accordingly. By identifying trend
        exhaustion early, you can be better prepared to capitalize on potential
        market shifts.
      </p>

      <h2 id="settings" className={`scroll-mt-2 ${styles["h2"]}`}>
        Settings
        <Link
          to="#settings"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        Before using the Trend Alert Candles indicator, it's essential to
        familiarize yourself with its customizable settings. Here are the key
        settings you can adjust:
      </p>
      <ol className={styles.olBullet}>
        <li>
          Trend Alert Candles: Enable or disable the Trend Alert Candles
          feature.
        </li>
        <li>
          Color 1: Set the color for the first candle pattern, indicating
          potential trend exhaustion.
        </li>
        <li>
          Color 2: Set the color for the second pattern, indicating a potential
          trend continuation.
        </li>
        <li>
          Color 3: Set the color for the third candle pattern, indicating
          potential trend exhaustion in the opposite direction.
        </li>
        <li>
          Color 4: Set the color for the fourth candle pattern, indicating a
          potential trend continuation in the opposite direction.
        </li>
      </ol>

      <h2
        id="candle-patterns-and-interpretation"
        className={`scroll-mt-2 ${styles["h2"]}`}
      >
        Candle Patterns and Interpretation
        <Link
          to="#candle-patterns-and-interpretation"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        The Trend Alert Candles indicator identifies four specific candle
        patterns, each representing different potential scenarios:
      </p>
      <ol className={styles.olBullet}>
        <li>First Candle Pattern</li>
      </ol>
      <figure className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[102]}
          width={459}
          height={427}
          className="mx-auto"
          alt="Trend Alert Candles - First Candle Pattern"
        ></img>
        <figcaption
          style={{
            textAlign: "center",
            fontSize: 12,
          }}
        >
          First Candle Pattern
        </figcaption>
      </figure>
      <p className={styles.p}>
        This pattern appears when the histogram crosses above zero and is
        rising. It suggests that the current bullish trend may be experiencing
        exhaustion.
      </p>

      <ol className={styles.olBullet}>
        <li>Second Candle Pattern</li>
      </ol>
      <figure className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[103]}
          width={557}
          height={838}
          className="mx-auto"
          alt="Trend Alert Candles - Second Candle Pattern"
        ></img>
        <figcaption
          style={{
            textAlign: "center",
            fontSize: 12,
          }}
        >
          Second Candle Pattern
        </figcaption>
      </figure>
      <p className={styles.p}>
        This pattern occurs when the histogram is above zero but decreasing. It
        indicates that the bullish trend is potentially continuing, but with
        less momentum.
      </p>
      <ol className={styles.olBullet}>
        <li>Third Candle Pattern</li>
      </ol>
      <figure className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[104]}
          width={501}
          height={633}
          className="mx-auto"
          alt="Trend Alert Candles - Third Candle Pattern"
        ></img>
        <figcaption
          style={{
            textAlign: "center",
            fontSize: 12,
          }}
        >
          Third Candle Pattern
        </figcaption>
      </figure>
      <p className={styles.p}>
        This pattern emerges when the histogram crosses below zero and is
        falling. It implies that the current bearish trend may be experiencing
        exhaustion.
      </p>
      <ol className={styles.olBullet}>
        <li>Fourth Candle Pattern</li>
      </ol>
      <figure className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[105]}
          width={1768}
          height={1011}
          className="mx-auto"
          alt="Trend Alert Candles - Fourth Candle Pattern"
        ></img>
        <figcaption
          style={{
            textAlign: "center",
            fontSize: 12,
          }}
        >
          Fourth Candle Pattern
        </figcaption>
      </figure>
      <p className={styles.p}>
        This pattern is seen when the histogram is below zero but increasing. It
        suggests that the bearish trend is potentially continuing, albeit with
        reduced momentum.
      </p>

      <div
        className={`
        info info-tip selection:bg-[#c4f6ff] selection:text-[#2c2c2c49]
        ${theme === "light" ? styles.infoTipInfo : styles.infoTipInfoDark} 
        `}
      >
        <div className="info-heading">
          <h5 className={styles.h5}>
            <span
              className={`
                ${theme === "light" ? styles.infoTip : styles.infoTipDark}
              `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 12 16"
              >
                <path
                  fillRule="evenodd"
                  d="M6.5 0C3.48 0 1 2.19 1 5c0 .92.55 2.25 1 3 1.34 2.25 1.78 2.78 2 4v1h5v-1c.22-1.22.66-1.75 2-4 .45-.75 1-2.08 1-3 0-2.81-2.48-5-5.5-5zm3.64 7.48c-.25.44-.47.8-.67 1.11-.86 1.41-1.25 2.06-1.45 3.23-.02.05-.02.11-.02.17H5c0-.06 0-.13-.02-.17-.2-1.17-.59-1.83-1.45-3.23-.2-.31-.42-.67-.67-1.11C2.44 6.78 2 5.65 2 5c0-2.2 2.02-4 4.5-4 1.22 0 2.36.42 3.22 1.19C10.55 2.94 11 3.94 11 5c0 .66-.44 1.78-.86 2.48zM4 14h5c-.23 1.14-1.3 2-2.5 2s-2.27-.86-2.5-2z"
                ></path>
              </svg>
            </span>
            Tip
          </h5>
        </div>
        <div className="warning-content">
          <p>
            The Trend Alert Candles indicator is particularly useful for
            identifying potential trend reversals and exhaustion points, making
            it a valuable tool in swing and position trading strategies.
          </p>
        </div>
      </div>

      <h2 id="how-to-use" className={`scroll-mt-2 ${styles["h2"]}`}>
        How to Use
        <Link
          to="#how-to-use"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[106]}
          width={598}
          height={370}
          className="mx-auto"
          alt="Trend Alert Candles - How to Use"
        ></img>
      </p>
      <ol className={styles.ol}>
        <li>
          Enable Trend Alert Candles: Ensure that the Trend Alert Candles
          feature is enabled by checking the "Trend Alert Candles" checkbox in
          the indicator settings.
        </li>
        <li>
          Adjust Color Preferences: Customize the candle colors to match your
          preferences. You can set colors for each of the four potential
          patterns.
        </li>
        <li>
          Observe Candle Patterns: Watch for the appearance of specific candle
          patterns on your price chart. Each pattern's color indicates a
          potential trend scenario.
        </li>
        <li>
          Interpret Pattern Colors: Interpret the color of each candle pattern
          to determine potential trend exhaustion or continuation. Refer to the
          patterns' descriptions above.
        </li>
        <li>
          Combine with Other Indicators: Consider using the Trend Alert Candles
          indicator alongside some of our other technical analysis tools to
          confirm your trading decisions.
        </li>
        <li>
          Practice Risk Management: As with any trading tool, practice proper
          risk management techniques and only execute trades that align with
          your overall trading strategy.
        </li>
      </ol>

      <p className={styles.p}>
        By understanding the candle patterns, color interpretations, and
        customizable settings of the Trend Alert Candles indicator, you can
        enhance your trading strategy and potentially identify key trend
        reversal points.
      </p>

      <div
        className={`warning warning-caution selection:bg-[#ffeec4] selection:text-[#4b4b4b] ${styles["alertWarning"]}`}
      >
        <div className="warning-heading">
          <h5 className={styles.h5}>
            <span className={styles.alertWarningIcon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M8.893 1.5c-.183-.31-.52-.5-.887-.5s-.703.19-.886.5L.138 13.499a.98.98 0 0 0 0 1.001c.193.31.53.501.886.501h13.964c.367 0 .704-.19.877-.5a1.03 1.03 0 0 0 .01-1.002L8.893 1.5zm.133 11.497H6.987v-2.003h2.039v2.003zm0-3.004H6.987V5.987h2.039v4.006z"
                ></path>
              </svg>
            </span>
            caution
          </h5>
        </div>
        <div className="warning-content">
          <p>
            While the indicator provides valuable insights, remember that no
            tool can predict market movements with absolute certainty. Always
            use additional analysis and risk management strategies in your
            trading decisions.
          </p>
        </div>
      </div>
    </>
  );
}
