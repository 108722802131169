import { Link } from "react-router-dom";
import ThemeContext from "./ThemeContext";
import { useContext } from "react";

function Pagination({ currentIndex, docRoutes }) {
  const { theme } = useContext(ThemeContext);
  const prevIndex = currentIndex > 0 ? currentIndex - 1 : null;
  const nextIndex =
    currentIndex < docRoutes.length - 1 ? currentIndex + 1 : null;

  return (
    <div className="relative mx-auto mb-0 mt-6 flex w-full min-w-0 max-w-3xl flex-row">
      <div className=" relative z-0 m-0 box-border flex min-h-0 min-w-0 flex-shrink flex-grow list-none flex-row items-stretch justify-center border-solid border-black bg-transparent p-0 [flex-basis:0%] [text-decoration:none]">
        <div className="relative z-0 m-0 box-border flex min-h-0 min-w-0 flex-shrink flex-grow list-none flex-col items-stretch border-solid border-black bg-transparent p-0 [flex-basis:0%] [text-decoration:none]">
          <div className="mb-6 flex flex-auto flex-shrink-0 flex-col">
            <div className="pagination mb-8 mt-8 flex flex-col justify-center md:flex-row">
              {prevIndex !== null && (
                <Link
                  className={`previous group relative z-0 m-0 mb-2 flex min-h-0 min-w-0 flex-shrink flex-grow cursor-pointer touch-manipulation select-none flex-row items-center overflow-hidden rounded p-4 transition-all duration-[.15s] [flex-basis:0%] [border:1px_solid_rgb(227,_232,_237)] hover:-translate-y-0.5 md:mb-0 md:mr-2 ${
                    theme === "light"
                      ? "bg-white hover:shadow-lg"
                      : "bg-[rgb(10,9,13)] hover:[box-shadow:rgba(255,_255,_255,_0.12)_0px_12px_13px]"
                  }`}
                  to={`/docs/${docRoutes[prevIndex].path}`}
                >
                  <div className=" relative m-0 mr-4 box-border flex min-h-0 min-w-0 flex-shrink-0 list-none flex-col items-stretch p-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 16 16"
                      className="[color:rgb(136,_153,_168)] group-hover:[color:rgb(52,_109,_219)]"
                      style={{
                        width: 24,
                        height: 24,
                        verticalAlign: "middle",
                      }}
                    >
                      <path
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M6.924 3.576a.6.6 0 0 1 0 .848L3.95 7.4H13.5a.6.6 0 1 1 0 1.2H3.949l2.975 2.976a.6.6 0 0 1-.848.848l-4-4a.6.6 0 0 1 0-.848l4-4a.6.6 0 0 1 .848 0Z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </div>
                  <div className=" relative m-0 flex min-h-0 min-w-0 flex-shrink flex-grow basis-[0%] list-none flex-col items-end p-0">
                    <div className=" relative m-0 flex min-h-0 min-w-0 flex-auto flex-shrink-0 list-none flex-col items-stretch p-0">
                      <div className=" text-xs font-normal leading-5 text-[rgb(136,_153,_168)]">
                        Previous
                      </div>
                    </div>
                    <div className="inline max-w-full overflow-hidden text-ellipsis whitespace-nowrap font-medium leading-6 [overflow-wrap:normal] group-hover:[color:rgb(52,_109,_219)]">
                      {docRoutes[prevIndex].title}
                    </div>
                  </div>
                </Link>
              )}
              {nextIndex !== null && (
                <Link
                  className={`next group relative ml-0 mt-2 flex min-h-0 min-w-0 flex-shrink flex-grow cursor-pointer touch-manipulation select-none flex-row items-center overflow-hidden rounded p-4 transition-all duration-[.15s] [flex-basis:0%] [border:1px_solid_rgb(227,_232,_237)] hover:-translate-y-0.5 md:ml-2 md:mt-0 ${
                    theme === "light"
                      ? "bg-white hover:shadow-lg "
                      : "bg-[rgb(10,9,13)] hover:[box-shadow:rgba(255,_255,_255,_0.12)_0px_12px_13px]"
                  }`}
                  to={`/docs/${docRoutes[nextIndex].path}`}
                >
                  <div className=" relative m-0 flex min-h-0 min-w-0 flex-shrink flex-grow basis-[0%] list-none flex-col items-stretch p-0">
                    <div className=" relative m-0 flex min-h-0 min-w-0 flex-shrink-0 list-none flex-col items-stretch p-0">
                      <div className=" flex items-stretch text-xs font-normal leading-5 text-[rgb(136,_153,_168)]">
                        Next
                      </div>
                    </div>
                    <div className="inline max-w-full overflow-hidden text-ellipsis whitespace-nowrap font-medium leading-6 [overflow-wrap:normal] group-hover:[color:rgb(52,_109,_219)]">
                      {docRoutes[nextIndex].title}
                    </div>
                  </div>
                  <div className=" relative m-0 mr-4 box-border flex min-h-0 min-w-0 flex-shrink-0 list-none flex-col items-stretch p-0 [flex-basis:auto]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 16 16"
                      className="[color:rgb(136,_153,_168)] group-hover:[color:rgb(52,_109,_219)]"
                      style={{
                        width: 24,
                        height: 24,
                        verticalAlign: "middle",
                      }}
                    >
                      <path
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M9.076 3.576a.6.6 0 0 1 .848 0l4 4a.6.6 0 0 1 0 .848l-4 4a.6.6 0 0 1-.848-.848L12.052 8.6H2.5a.6.6 0 0 1 0-1.2h9.552L9.076 4.424a.6.6 0 0 1 0-.848Z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </div>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pagination;
