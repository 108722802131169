export const getSubscriptionPlanByUserId = `
  query GetSubscriptionPlanByUserId($userID: String!) {
    listSubscriptionPlans(filter: {userID: {eq: $userID}}) {
      items {
        id
        planName
        billingCycle
        stripeCustomerId
      }
    }
  }
`;
