import { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import styles from "../Docs.module.css";
import { useTOC } from "../TOCContext";
import DocImages from "../DocImages";
import ThemeContext from "../ThemeContext";
import "katex/dist/katex.min.css"; // Import KaTeX CSS
import { BlockMath, InlineMath } from "react-katex";
const entries = [
  {
    displayText: "Position Sizing",
    urlFragment: "position-sizing",
    headingType: "h2",
    subEntries: [
      {
        displayText: "Position Sizing with the Kelly Formula",
        urlFragment: "position-sizing-with-the-kelly-formula",
        headingType: "h3",
      },
    ],
  },
  {
    displayText: "Stop Loss",
    urlFragment: "stop-loss",
    headingType: "h2",
    subEntries: [
      {
        displayText: "Where to Place a Stop Loss?",
        urlFragment: "where-to-place-a-stop-loss",
        headingType: "h3",
      },
      {
        displayText: "Trailing Stop Loss",
        urlFragment: "trailing-stop-loss",
        headingType: "h3",
      },
      {
        displayText: "Gaps",
        urlFragment: "gaps",
        headingType: "h3",
      },
    ],
  },
  {
    displayText: "Take Profits",
    urlFragment: "take-profits",
    headingType: "h2",
    subEntries: [],
  },
];
export { entries };

export default function DocsTradingBestPracticesMoneyManagement() {
  const { addToTOC, removeFromTOC } = useTOC();
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    entries.forEach(addToTOC);
    return () => {
      entries.forEach((entry) => removeFromTOC(entry));
    };
  }, [addToTOC, removeFromTOC]);

  return (
    <>
      <h1 className={styles.h1}>Money Management</h1>
      <p className={styles.p}>
        Once you've established a well-defined trader profile, an initial
        capital, and a thought-out portfolio, you're ready to begin trading. If
        you're yet to select a broker, you can find our list of recommended
        brokers below:
      </p>
      <Link
        to="/docs/best-practices/recommended-brokers"
        className={`
          ${theme === "light" ? styles.changelogLink : styles.changelogLinkDark}
        `}
      >
        <span
          style={{
            userSelect: "none",
            marginRight: 10,
            marginBottom: 3,
          }}
        >
          »
        </span>
        Recommended Brokers
      </Link>
      <p className={styles.p}>
        The subsequent step involves crafting an effective money management
        strategy, a pivotal aspect for achieving more profitable trading.
        Starting out, it's not about randomly entering and exiting trades with
        arbitrary position sizes. Money management ensures control over the
        level of risk you're willing to take and enhances the longevity of your
        capital.
      </p>
      <h2 id="position-sizing" className={`scroll-mt-2 ${styles["h2"]}`}>
        Position Sizing
        <Link
          to="#position-sizing"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        Determining the size of your position is crucial, as it dictates how
        many contracts of a security you'll acquire. There are diverse methods
        for sizing your positions, but the most prudent approach is one aligned
        with your risk tolerance. Your position size should be calibrated to
        avoid losing a specific percentage of your capital.
      </p>
      <p className={styles.p}>
        You can size an investment based on the percentage of capital at risk or
        consider market volatility. For volatile markets, employing smaller
        positions helps mitigate risk.
      </p>
      <div
        className={`
        info info-caution selection:bg-[#c4f6ff] selection:text-[#2c2c2c49]
        ${theme === "light" ? styles.danger : styles.dangerDark} 
        `}
      >
        <div className="info-heading">
          <h5 className={styles.h5}>
            <span
              className={`
                ${
                  theme === "light"
                    ? styles.dangerAlert
                    : styles.dangerAlertDark
                }
              `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 14 16"
              >
                <path
                  fillRule="evenodd"
                  d="M5.05.31c.81 2.17.41 3.38-.52 4.31C3.55 5.67 1.98 6.45.9 7.98c-1.45 2.05-1.7 6.53 3.53 7.7-2.2-1.16-2.67-4.52-.3-6.61-.61 2.03.53 3.33 1.94 2.86 1.39-.47 2.3.53 2.27 1.67-.02.78-.31 1.44-1.13 1.81 3.42-.59 4.78-3.42 4.78-5.56 0-2.84-2.53-3.22-1.25-5.61-1.52.13-2.03 1.13-1.89 2.75.09 1.08-1.02 1.8-1.86 1.33-.67-.41-.66-1.19-.06-1.78C8.18 5.31 8.68 2.45 5.05.32L5.03.3l.02.01z"
                ></path>
              </svg>
            </span>
            Warning
          </h5>
        </div>
        <div className="warning-content">
          <p>
            Avoid adopting aggressive position sizing strategies like
            martingales, which could lead to rapid depletion of your initial
            invested capital.
          </p>
        </div>
      </div>
      <h3
        id="position-sizing-with-the-kelly-formula"
        className={`scroll-mt-2 ${styles["h3"]}`}
      >
        Position Sizing with the Kelly Formula
        <Link
          to="#position-sizing-with-the-kelly-formula"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        The Kelly formula aids in determining the percentage of capital suitable
        for your position size, based on historical performance. It's calculated
        as follows:
      </p>
      <BlockMath math="K = \frac{bpp - q}{100}" />
      <p className={styles.p}>
        Where <InlineMath math="K \times 100" /> represents the percentage of
        capital for your position, <InlineMath math="p" /> is the probability of
        a win, and <InlineMath math="q" /> is the ratio of win-to-loss. Assess
        your past trades for <InlineMath math="p" />, derived by dividing the
        number of winning trades by the total trades. <InlineMath math="q" />{" "}
        can be calculated by dividing average gains by average losses. A
        negative value implies lower average gains than losses.
      </p>
      <h2 id="stop-loss" className={`scroll-mt-2 ${styles["h2"]}`}>
        Stop Loss
        <Link
          to="#stop-loss"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[70]}
          width={443}
          height={745}
          className="mx-auto"
          alt="Stop Loss"
        ></img>
      </p>
      <p className={styles.p}>
        Implementing stop losses safeguards against unexpected price movements.
        Trading without a stop loss exposes you to volatile shifts that can
        significantly impact your capital.
      </p>
      <p className={styles.p}>
        Typically set at a specific price level, a stop loss closes your
        position when that price is reached. Some platforms permit adjusting the
        distance between the stop loss and the price in pips, dollars, or
        percentage.
      </p>
      <div
        className={`warning warning-caution selection:bg-[#ffeec4] selection:text-[#4b4b4b] ${styles["alertWarning"]}`}
      >
        <div className="warning-heading">
          <h5 className={styles.h5}>
            <span className={styles.alertWarningIcon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M8.893 1.5c-.183-.31-.52-.5-.887-.5s-.703.19-.886.5L.138 13.499a.98.98 0 0 0 0 1.001c.193.31.53.501.886.501h13.964c.367 0 .704-.19.877-.5a1.03 1.03 0 0 0 .01-1.002L8.893 1.5zm.133 11.497H6.987v-2.003h2.039v2.003zm0-3.004H6.987V5.987h2.039v4.006z"
                ></path>
              </svg>
            </span>
            caution
          </h5>
        </div>
        <div className="warning-content">
          <p>
            A triggered stop loss may not be filled at the intended price,
            potentially leading to unforeseen losses.
          </p>
        </div>
      </div>
      <h3
        id="where-to-place-a-stop-loss"
        className={`scroll-mt-2 ${styles["h3"]}`}
      >
        Where to Place a Stop Loss?
        <Link
          to="#where-to-place-a-stop-loss"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        Your initial capital and risk tolerance heavily influence stop loss
        placement. If a $10 loss is unacceptable, adjust the stop loss
        accordingly.
      </p>
      <p className={styles.p}>
        Also, consider volatility; setting a stop loss too close might trigger
        it prematurely. Allow for variations in price due to volatility.
      </p>
      <p className={styles.p}>
        Tailor your stop loss to your trade horizon and profit target.
        Longer-term trades necessitate a more distant stop loss.
      </p>
      <h3 id="trailing-stop-loss" className={`scroll-mt-2 ${styles["h3"]}`}>
        Trailing Stop Loss
        <Link
          to="#trailing-stop-loss"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        Unlike a standard stop loss, a trailing stop loss adjusts over time and
        can protect profits. This stop loss is set a certain percentage away
        from the current price.
      </p>
      <h3 id="gaps" className={`scroll-mt-2 ${styles["h3"]}`}>
        Gaps
        <Link
          to="#gaps"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <figure className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[71]}
          width={707}
          height={664}
          className="mx-auto"
          alt="Daily Gap in AMD"
        />
        <figcaption
          style={{
            textAlign: "center",
            fontSize: 12,
          }}
        >
          Significant Gap in AMD(D)
        </figcaption>
      </figure>
      <p className={styles.p}>
        Gaps emerge when the opening price differs from the prior close. They're
        common in the stock market and forex market but nearly absent in the
        crypto market due to continuous trading.
      </p>
      <p className={styles.p}>
        Gaps can trigger a stop loss at a significantly altered price, adding
        extra risk. Solutions include closing positions before market close.
      </p>

      <h2 id="take-profits" className={`scroll-mt-2 ${styles["h2"]}`}>
        Take Profits
        <Link
          to="#take-profits"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[72]}
          width={438}
          height={728}
          className="mx-auto"
          alt="Take Profit"
        ></img>
      </p>
      <p className={styles.p}>
        Similar to a stop loss, take profits close positions at a specific price
        level. However, as the name suggests, they're executed to secure
        profits, not prevent losses.
      </p>
    </>
  );
}
