import { useEffect } from "react";
import { motion, useAnimate } from "framer-motion";
import images from "./images";

function Blur() {
  const [scope, animate] = useAnimate();
  const spin = {
    animate: {
      rotate: 360,
      transition: {
        duration: 20,
        repeat: Infinity,
        ease: "linear",
      },
    },
    initial: {
      rotate: 0,
    },
  };

  useEffect(() => {
    let timeout1, timeout2, intervalId;

    animate(
      "#gradient-blur-1",
      { opacity: [0, 1, 0] },
      { duration: 8, repeat: Infinity, repeatDelay: 4 },
    );
    timeout1 = setTimeout(
      () =>
        animate(
          "#gradient-blur-2",
          { opacity: [0, 1, 0] },
          { duration: 8, repeat: Infinity, repeatDelay: 4 },
        ),
      4000,
    );
    timeout2 = setTimeout(
      () =>
        animate(
          "#gradient-blur-3",
          { opacity: [0, 1, 0] },
          { duration: 8, repeat: Infinity, repeatDelay: 4 },
        ),
      8000,
    );

    intervalId = setInterval(() => {
      animate(
        "#gradient-blur-1",
        { opacity: [0, 1, 0] },
        { duration: 8, repeat: Infinity, repeatDelay: 4 },
      );
      timeout1 = setTimeout(
        () =>
          animate(
            "#gradient-blur-2",
            { opacity: [0, 1, 0] },
            { duration: 8, repeat: Infinity, repeatDelay: 4 },
          ),
        4000,
      );
      timeout2 = setTimeout(
        () =>
          animate(
            "#gradient-blur-3",
            { opacity: [0, 1, 0] },
            { duration: 8, repeat: Infinity, repeatDelay: 4 },
          ),
        8000,
      );
    }, 12000);

    return () => {
      clearTimeout(timeout1);
      clearTimeout(timeout2);
      clearInterval(intervalId);
    };
  }, [animate]);

  return (
    <motion.div
      id="3d-spin"
      className="relative top-60 z-[1] h-full w-full will-change-transform"
      variants={spin}
      initial="initial"
      animate="animate"
      ref={scope}
    >
      <div ref={scope}>
        <img
          id="gradient-blur-1"
          alt="Gradient Blur"
          className="absolute z-[1] inline-block h-full w-full max-w-full select-none border-none will-change-[opacity]"
          sizes="(max-width: 479px) 100vw, (max-width: 991px) 95vw, 85vw"
          loading="eager"
          src={images[16]}
        />
        <img
          id="gradient-blur-2"
          alt="Blur Gradient"
          className="absolute z-[1] inline-block h-full w-full max-w-full select-none border-none will-change-[opacity]"
          sizes="(max-width: 479px) 100vw, (max-width: 991px) 95vw, 85vw"
          loading="eager"
          style={{ opacity: 0 }}
          src={images[17]}
        />
        <img
          id="gradient-blur-3"
          alt="Rainbow Blur"
          className="absolute z-[1] inline-block h-full w-full max-w-full select-none border-none will-change-[opacity]"
          sizes="(max-width: 479px) 100vw, (max-width: 991px) 95vw, 85vw"
          loading="eager"
          style={{ opacity: 0 }}
          src={images[18]}
        />
      </div>
    </motion.div>
  );
}
export default Blur;
