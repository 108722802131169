import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { TextCarousel } from "./TextCarousel";
import styles from "./Main.module.css";
import Sparkles from "./Sparkle";
import Star1 from "./imgs/bluestar.png";
import Star2 from "./imgs/purplestar.png";
import Star3 from "./imgs/whitestar.png";

import { Amplify } from "aws-amplify";
import { API } from "aws-amplify";

import awsExports from "../aws-exports";
Amplify.configure(awsExports);

const placeholders = [
  "A New TA Analysis Chart...",
  "AI-driven market trend predictions...",
  "Insights personalized to your trading style...",
  "Dynamic market sentiment analysis...",
  "Data-backed trading strategies...",
  "First access to new feature rollouts...",
  "Deep learning models for high-probability trades...",
  "Quantitative market models at your fingertips...",
  "Cutting-edge algorithmic trading insights...",
  "Bespoke algorithmic trading strategies...",
  "Maximize your profits, minimize your risks...",
];

const ArrowIcon = () => (
  <svg
    className="pt-1"
    width="100%"
    height="100%"
    viewBox="0 0 20 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 5H19M19 5L14.6667 1M19 5L14.6667 9"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
  </svg>
);

const SpinnerMobile = () => {
  return (
    <div className={`${styles.spinnerContainer}`}>
      <div className={`${styles.spinnerMobile}`}></div>
    </div>
  );
};

function EmailModal({ isOpen, onClose }) {
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const [email, setEmail] = useState("");
  const [honeypot, setHoneypot] = useState("");
  const [submissionStatus, setSubmissionStatus] = useState("idle");
  const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [emailError, setEmailError] = useState("");

  const resetFormAndCloseModal = () => {
    setHoneypot("");
    setSubmissionStatus("idle");
    onClose();
    document.body.classList.remove("overflow-hidden");
  };

  async function handleSubmit(e) {
    e.preventDefault();
    let hasError = false;
    let timer;

    if (!email) {
      setEmailError("Email is required.");
      hasError = true;
    } else if (!isValidEmail.test(email)) {
      setEmailError("Invalid email address.");
      hasError = true;
    }

    if (honeypot) {
      return;
    }

    if (hasError) {
      return;
    }

    setSubmissionStatus("loading");
    setEmailError("");

    const data = {
      body: {
        email: email,
      },
    };

    try {
      const apiData = await API.post("madalgocontactapi", "/contact", data);
      console.log({ apiData });
      setSubmissionStatus("success");
      setEmail("");
      timer = setTimeout(() => {
        resetFormAndCloseModal();
      }, 3000);
    } catch (error) {
      console.error(error);
      setSubmissionStatus("error");
    }
    return () => clearTimeout(timer);
  }

  return (
    <>
      <div
        className={`fixed inset-0 z-40 bg-black transition-opacity duration-300 ${
          isOpen ? "opacity-50" : "pointer-events-none opacity-0"
        }`}
      ></div>

      <div
        className={`fixed inset-0 z-50 flex items-center justify-center transition-opacity duration-300 ${
          isOpen ? "opacity-100" : "pointer-events-none opacity-0"
        }`}
      >
        <div
          id="contact-wrapper"
          className={`relative rounded p-8 shadow-2xl transition-all duration-300 ${
            isOpen ? "bg-black bg-opacity-90" : "backdrop-blur-0"
          }`}
        >
          <button onClick={onClose} className="absolute right-2 top-2 text-xl">
            ×
          </button>
          <h2 className="mb-4 text-xl">Enter your email</h2>
          <input
            type="text"
            name="honeypot"
            value={honeypot}
            onChange={(e) => setHoneypot(e.target.value)}
            style={{ display: "none" }}
          />
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={handleEmailChange}
            className="mb-4 w-full rounded border p-2 text-purple-500 placeholder:text-purple-500 hover:border-purple-500 focus:border-purple-500 focus:outline-none"
          />
          <button
            onClick={handleSubmit}
            className="flex w-full flex-row justify-center rounded bg-blue-500 p-2 text-white"
            disabled={submissionStatus === "loading"}
          >
            {submissionStatus === "loading" ? (
              <SpinnerMobile />
            ) : submissionStatus === "success" ? (
              "Email Sent Successfully"
            ) : submissionStatus === "error" ? (
              "Error Sending Email"
            ) : (
              <>
                Send
                <div className="ml-2 flex h-[19.2px] w-4 flex-none transform items-center justify-center text-white transition-transform duration-300">
                  <ArrowIcon />
                </div>
              </>
            )}
          </button>
          {submissionStatus !== "loading" && (
            <p className="error-message m-0 mt-4 text-sm text-red-500">
              {emailError}
            </p>
          )}
        </div>
      </div>
    </>
  );
}

export function ContactUs() {
  const [index, setIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [honeypot, setHoneypot] = useState("");
  const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [submissionStatus, setSubmissionStatus] = useState("idle");
  const [emailError, setEmailError] = useState("");

  const resetFormLargeCTA = () => {
    setHoneypot("");
    setSubmissionStatus("idle");
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleOpenModal = () => {
    document.body.classList.add("overflow-hidden");
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    document.body.classList.remove("overflow-hidden");
    setModalOpen(false);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setIndex((current) => (current + 1) % placeholders.length);
    }, 4000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();
    let hasError = false;
    let timer;

    if (!email) {
      setEmailError("Email is required.");
      hasError = true;
    } else if (!isValidEmail.test(email)) {
      setEmailError("Invalid email address.");
      hasError = true;
    }

    if (honeypot) {
      return;
    }

    if (hasError) {
      return;
    }

    setSubmissionStatus("loading");
    setEmailError("");

    const data = {
      body: {
        email: email,
      },
    };

    try {
      const apiData = await API.post("madalgocontactapi", "/contact", data);
      console.log({ apiData });
      setSubmissionStatus("success");
      setEmail("");
      timer = setTimeout(() => {
        resetFormLargeCTA();
      }, 3000);
    } catch (error) {
      console.error(error);
      setSubmissionStatus("error");
    }
    return () => clearTimeout(timer);
  }

  return (
    <>
      <div
        id="contact-section"
        className={
          `contact-section relative mx-auto flex h-min w-full flex-none flex-col flex-nowrap content-center items-center justify-center gap-10 overflow-hidden border-b-0 border-l-0 border-r-0 border-t border-solid p-[60px_20px_30px] [background:_linear-gradient(90deg,_rgb(0,_153,_255)_0%,_rgb(191,_0,_255)_100%);] [border-color:_rgba(0,_0,_0,_0.1)] md:p-[80px_50px_150px] md:[gap:none] ` +
          styles["contact-section"]
        }
      >
        <div
          id="contact-section-frame"
          className="absolute -bottom-px left-[0] right-[0] z-[2] h-[70%] overflow-visible opacity-100 [background:linear-gradient(rgba(32,_41,_44,_0)_0%,_rgb(0,_0,_0)_100%);]"
        ></div>
        <div
          id="contact-us-container"
          className="relative z-[3] flex max-w-full flex-none flex-col flex-nowrap content-center items-center justify-center gap-8 overflow-visible p-[0] opacity-100 md:gap-10"
        >
          <div
            id="contact-heading"
            className="relative flex h-auto w-fit max-w-full flex-none flex-shrink-0  flex-col justify-start overflow-visible whitespace-pre-wrap tracking-normal [word-break:break-word] selection:text-fuchsia-600 xl:w-[780px]"
          >
            <h1
              id="heading"
              className=" m-0 p-0 text-center text-5xl font-bold leading-[133%_!important] tracking-tighter md:text-7xl md:tracking-tight md:[text-decoration:none] md:[text-transform:none] lg:text-8xl lg:tracking-tighter"
            >
              <span
                id="text"
                data-text-fill="true"
                style={{
                  backgroundImage:
                    "radial-gradient(31.9% 131.819% at 87.1% 75.9%, rgb(250, 184, 255) 0%, rgb(255, 255, 255) 100%)",
                }}
                className="m-[min(0em,_calc(calc(1.3em_-_var(--framer-line-height,_1.3em))_/_-2))] inline-block bg-clip-text p-[max(0em,_calc(calc(1.3em_-_var(--framer-line-height,_1.3em))_/_2))] [-webkit-text-fill-color:transparent]"
              >
                Start your journey with trading.
              </span>
            </h1>
          </div>
          <div
            id="cta-container-mobile"
            className="relative h-auto w-auto flex-none opacity-100 md:[display:none]"
          >
            <motion.div
              id="cta-inner"
              style={{
                display: "contents",
              }}
            >
              <motion.a
                onClick={handleOpenModal}
                id="button-link"
                data-highlight="true"
                style={{
                  opacity: 1,
                  borderRadius: "14px",
                  boxShadow:
                    "rgba(0, 0, 0, 0.18) 0px 0.602187px 1.80656px -1.25px, rgba(0, 0, 0, 0.16) 0px 2.28853px 6.8656px -2.5px, rgba(0, 0, 0, 0.063) 0px 10px 30px -3.75px",
                  background: "rgb(255, 255, 255)",
                }}
                className="relative flex h-min w-min cursor-pointer flex-row flex-nowrap content-center items-center justify-center gap-[10px] overflow-hidden border-0 p-[15px_20px_15.6px] will-change-transform [text-decoration:none]"
                whileHover={{ opacity: 0.8 }}
              >
                <div
                  id="a-inner-container"
                  className="relative z-[1] flex h-auto w-auto flex-none flex-shrink-0 transform-none select-none flex-col justify-start whitespace-pre opacity-100"
                >
                  <h2
                    id="cta-text"
                    className="m-0 p-0 text-center text-xl font-semibold leading-4 text-black"
                  >
                    Get Started
                  </h2>
                </div>
                <motion.div
                  id="framer"
                  style={{
                    background:
                      "linear-gradient(290deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.6) 100%)",
                    WebkitBackground:
                      "linear-gradient(290deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.6) 100%)",
                    opacity: 1,
                  }}
                  className="absolute inset-0 z-[1] overflow-hidden mix-blend-overlay"
                  whileHover={{ opacity: 0 }}
                ></motion.div>
              </motion.a>
            </motion.div>
          </div>
        </div>
        <div
          id="cta-large-screen"
          className="relative z-[8] h-min max-w-full flex-none flex-col flex-nowrap content-center items-center justify-center gap-2 overflow-visible p-[0] [display:none] md:flex md:w-full lg:w-[850px]"
          style={{
            opacity: 1,
            borderRadius: 37,
            boxShadow: "rgba(0, 0, 0, 0.25) 0px 60px 220px 0px",
          }}
        >
          <div
            id="cta-container"
            className="relative z-0 h-auto w-full flex-none"
            style={{
              opacity: 1,
            }}
          >
            <div
              style={{
                position: "relative",
              }}
            >
              <div
                style={{
                  display: "contents",
                }}
              >
                <div
                  id="content"
                  data-border="true"
                  className={`content relative flex h-min flex-row flex-nowrap content-center items-center justify-center gap-2 overflow-visible rounded-[26px] p-[6px] [backdrop-filter:blur(6px)] xl:rounded-[36px] xl:p-[10px] xl:[backdrop-filter:blur(10px)] ${styles.content}`}
                  style={{
                    borderBottomWidth: 1,
                    borderColor: "rgba(0, 0, 0, 0.05)",
                    borderTopWidth: 1,
                    borderLeftWidth: 1,
                    borderRightWidth: 1,
                    borderStyle: "solid",
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                    borderRadius: 26,
                    width: "100%",
                    opacity: 1,
                  }}
                >
                  <div
                    id="content-inner"
                    data-border="true"
                    className={
                      `content-inner pointer-events-none absolute inset-0 z-[1] flex overflow-visible rounded-[26px] ` +
                      styles["content-inner"]
                    }
                    style={{
                      borderBottomWidth: 1,
                      borderColor: "rgb(255, 255, 255)",
                      borderLeftWidth: 1,
                      borderRightWidth: 1,
                      borderStyle: "solid",
                      borderTopWidth: 1,
                      opacity: 0.1,
                    }}
                  ></div>
                  <div
                    id="main-content-holder"
                    className=" relative -order-none flex w-[1px] flex-[1_0_0px] flex-row flex-nowrap content-center items-center justify-between overflow-visible rounded-[20px] p-4 xl:p-5"
                    style={{
                      background:
                        "linear-gradient(rgba(255, 255, 255, 0.8) 0%, rgb(255, 255, 255) 100%)",
                      opacity: 1,
                      boxShadow: "rgba(0, 0, 0, 0.05) 0px 1px 4px 0px",
                    }}
                  >
                    <div
                      id="ai-badge"
                      className="relative h-[30px] w-[30px] flex-none overflow-visible rounded-lg xl:-order-none xl:h-12 xl:w-12 xl:rounded-[14px]"
                      style={{
                        opacity: 1,
                        background:
                          "linear-gradient(270deg, rgba(167, 129, 255, 0.1) 0%, rgba(27, 187, 255, 0.1) 100%)",
                      }}
                    >
                      <div
                        id="ai-image"
                        className=" xl:h-[30px]xl:w-[30px] absolute left-[calc(50.00000000000002%_-_20px_/_2)] top-[calc(50.00000000000002%_-_20px_/_2)] h-5 w-5"
                        style={{
                          opacity: 1,
                          imageRendering: "pixelated",
                          backgroundSize: "100% 100%",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlSpace="preserve"
                          viewBox="0 0 24 24"
                        >
                          <defs>
                            <linearGradient
                              id="grad1"
                              x1="2"
                              x2="22"
                              y1="11.708"
                              y2="11.708"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop
                                offset="0"
                                style={{
                                  stopColor: "#41b3ff",
                                }}
                              />
                              <stop
                                offset="1"
                                style={{
                                  stopColor: "#d700ff",
                                }}
                              />
                            </linearGradient>
                          </defs>
                          <path
                            d="M10.6 4.1c.2-.3.6-.3.7 0l4.3 7.4 4.3 7.4c.2.3 0 .6-.4.6H2.4c-.3 0-.5-.4-.4-.6l4.3-7.4 4.3-7.4zm-3.1 7.8-3.2 5.6c-.1.2 0 .5.3.5h12.8c.2 0 .4-.3.3-.5l-3.2-5.6-3.2-5.6c-.1-.2-.4-.2-.6 0l-3.2 5.6zM22 19.1V4.3c0-.2-.2-.4-.4-.4h-.4c-.2 0-.4.2-.4.4v14.8c0 .2.2.4.4.4h.4c.2 0 .4-.2.4-.4z"
                            fill="url(#grad1)"
                            strokeWidth="2"
                          />
                        </svg>
                      </div>
                    </div>
                    <div
                      id="large-button-container"
                      className="relative z-[4] order-1 h-10 w-[160.6px] flex-none xl:h-auto"
                      style={{
                        opacity: 1,
                      }}
                    >
                      <motion.div
                        id="focus-container"
                        style={{
                          display: "contents",
                        }}
                        onHoverStart={() => setIsHovered(true)}
                        onHoverEnd={() => setIsHovered(false)}
                        onClick={handleSubmit}
                        disabled={submissionStatus === "loading"}
                      >
                        <motion.div
                          id="button-inner-container"
                          className="relative flex cursor-pointer flex-row flex-nowrap content-center items-center justify-center gap-1 overflow-visible rounded-xl p-[8px_12px_8px_8px] shadow-none xl:rounded-[14px] xl:p-[10px_16px_10px_10px] xl:shadow-[rgba(0,_0,_0,_0.18)_0px_0.722625px_0.722625px_-1.25px,_rgba(0,_0,_0,_0.16)_0px_2.74624px_2.74624px_-2.5px,_rgba(0,_0,_0,_0.063)_0px_12px_12px_-3.75px]"
                          style={{
                            height: "100%",
                            opacity: 1,
                            background:
                              "linear-gradient(282deg, rgb(204, 0, 255) 0%, rgb(0, 153, 255) 100%)",
                          }}
                        >
                          <div
                            id="button-overlay"
                            className=" absolute inset-0 z-0 overflow-hidden will-change-transform"
                            style={{
                              opacity: 0,
                              filter: "blur(4px)",
                              borderRadius: 8,
                              background:
                                "linear-gradient(270deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 100%)",
                            }}
                          ></div>
                          <div
                            id="button-overlay-2"
                            className=" absolute inset-0 z-0 overflow-hidden will-change-transform"
                            style={{
                              background:
                                "linear-gradient(270deg, rgb(153, 102, 255) 0%, rgb(0, 204, 255) 100%)",
                              filter: "blur(4px)",
                              opacity: 0.4,
                              borderRadius: 8,
                            }}
                          ></div>
                          <motion.div
                            id="button-icon"
                            className="relative z-[1] h-[18px] w-[18px] flex-none xl:h-[24px] xl:w-[24px]"
                            style={{
                              opacity: isHovered ? 0.5 : 1,
                              imageRendering: "pixelated",
                              flexShrink: 0,
                              backgroundSize: "100% 100%",
                              transition: "opacity 0.5s",
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlSpace="preserve"
                              viewBox="0 0 24 24"
                            >
                              <path
                                d="m11.4 22.8-1.6-7.3c-.1-.3-.3-.5-.6-.6l-8-2c-.8-.2-.8-1.4 0-1.6L9 9.8c.3-.1.6-.3.6-.6l1.7-7.9c.2-.9 1.4-.9 1.6 0l1.7 8.3c.1.3.3.6.7.6l7.4 1.2c.9.1.9 1.4.1 1.6l-7.5 1.7c-.3.1-.5.3-.6.6L13 22.8c-.2.9-1.4.9-1.6 0zm-6.1.4.6-2.6c0-.1.1-.2.2-.2l2.6-.6c.3-.1.3-.5 0-.5l-2.5-.4c-.1 0-.2-.1-.2-.2l-.6-2.8c-.1-.3-.5-.3-.5 0l-.6 2.7c0 .1-.1.2-.2.2l-2.7.5c-.3.1-.3.5 0 .5l2.6.7c.1 0 .2.1.2.2l.5 2.5c.1.3.5.3.6 0zM6.6 8 7 5.9c0-.1.1-.2.2-.2l2.1-.5c.2-.1.2-.4 0-.4l-2.1-.4c-.1 0-.1-.1-.2-.2l-.4-2.3c0-.2-.4-.2-.4 0l-.5 2.2c0 .1-.1.2-.2.2l-2.2.4c-.2 0-.2.4 0 .4l2.2.6c.1 0 .1.1.2.2l.4 2c.1.3.4.3.5.1z"
                                style={{
                                  fill: "#fff",
                                }}
                              />
                            </svg>
                          </motion.div>

                          <motion.div
                            id="button-text"
                            className="relative z-[1] h-auto w-auto flex-none overflow-visible whitespace-pre"
                            style={{
                              outline: "none",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              flexShrink: 0,
                              color: "#fff",
                              wordSpacing: 0,
                              opacity: isHovered ? 0.5 : 1,
                              transform: "none",
                              transition: "opacity 0.5s",
                            }}
                          >
                            {submissionStatus === "loading" ? (
                              <SpinnerMobile />
                            ) : submissionStatus === "success" ? (
                              "Email Sent"
                            ) : (
                              <p
                                id="text-p"
                                className=" m-0 p-0 text-start text-[15px] font-bold [text-decoration:none] [text-transform:none] xl:text-xl"
                                style={{
                                  letterSpacing: -0.1,
                                  lineHeight: 1.4,
                                }}
                              >
                                Start with AI
                              </p>
                            )}
                          </motion.div>
                        </motion.div>
                      </motion.div>
                    </div>
                    <Sparkles
                      imgSrc={Star1}
                      id="particles-bottom"
                      className="pointer-events-none absolute bottom-[-53px] right-0 top-[-47px] z-[1] w-40 overflow-hidden xl:right-5 xl:w-[177px]"
                      style={{
                        opacity: 1,
                      }}
                    />
                    <Sparkles
                      imgSrc={Star2}
                      id="particles-middle"
                      className="pointer-events-none absolute bottom-[-53px] right-[0] top-[-47px] z-[5] w-40 overflow-hidden xl:right-5 xl:w-[177px] "
                      style={{
                        opacity: 1,
                      }}
                    />
                    <Sparkles
                      imgSrc={Star3}
                      id="particles-top"
                      className="pointer-events-none absolute bottom-[-53px] right-[0] top-[-47px] z-[5] w-40 overflow-hidden xl:right-5 xl:w-[177px] "
                      style={{
                        opacity: 1,
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                id="input-field-content"
                className=" pr-[156px] xl:pr-[220px]"
                style={{
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  top: 0,
                  left: 0,
                  pointerEvents: "none",
                }}
              >
                <input
                  type="text"
                  name="honeypot"
                  value={honeypot}
                  onChange={(e) => setHoneypot(e.target.value)}
                  style={{ display: "none" }} // Hide the field
                />
                <input
                  type="email"
                  value={email}
                  id="input"
                  onChange={handleEmailChange}
                  className="h-full w-full pl-[66px] pr-5 text-base font-semibold shadow-none outline-0 [background:none] [border:medium_none] [color:rgb(17,_17,_17)_!important] [pointer-events:all] placeholder:font-semibold placeholder:[color:rgba(0,_0,_0,_0.2)_!important] focus:outline-none xl:pl-24 xl:text-xl"
                  placeholder={placeholders[index]}
                ></input>
              </div>
            </div>
          </div>
        </div>
        {submissionStatus !== "loading" && (
          <p className="error-message m-0 mt-2 text-lg font-bold text-[#fd9d9d]">
            {emailError}
          </p>
        )}
        <div
          id="floating-text-container"
          className="relative z-[2] flex h-min w-full flex-none flex-row flex-nowrap content-center items-center justify-center gap-5 overflow-visible p-0 [max-width:unset] lg:max-w-[1400px] "
          style={{
            opacity: 1,
          }}
        >
          <div
            id="floating-inner"
            className=" relative flex h-min w-[1px] flex-[1_0_0px] flex-col flex-nowrap content-center items-center justify-start gap-5 overflow-hidden p-0 will-change-transform"
            style={{
              opacity: 1,
              borderTopLeftRadius: "20px",
              borderTopRightRadius: "20px",
            }}
          >
            <div
              id="framer-container"
              className="relative h-9 w-full max-w-full flex-none md:w-[850px]"
              style={{
                opacity: 0.7,
              }}
            >
              <TextCarousel />
            </div>
          </div>
        </div>
      </div>
      <EmailModal isOpen={isModalOpen} onClose={handleCloseModal} />
    </>
  );
}
