import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const Error = ({ is404Ref }) => {
  useEffect(() => {
    is404Ref.current = true;
    return () => {
      is404Ref.current = false;
    }; // reset when unmounting
  }, [is404Ref]);

  return (
    <div className="h-0 min-h-screen">
      <main className="relative isolate min-h-full bg-[#0a090d]">
        <div className=" mx-auto flex max-w-7xl flex-col items-center justify-center px-6 py-32 text-center sm:py-40 lg:px-8">
          <div className="mb-20">
            <img
              src="/logo192.png"
              alt=""
              className="inset-0 -z-10 h-40 w-40"
            />
          </div>
          <div>
            <p className=" m-[0] mb-10 text-6xl font-semibold leading-8 text-red-600">
              404
            </p>
            <h1 className="m-[0] mt-4 text-3xl font-bold leading-none tracking-tight text-white sm:text-5xl">
              Page not found
            </h1>
            <p className="m-[0] mt-6 text-base text-slate-50">
              Sorry, we couldn’t find the page you’re looking for.
            </p>
            <div className="mt-10 flex justify-center">
              <Link
                to="https://www.madalgo.io"
                className="text-base font-semibold leading-7 text-white decoration-inherit"
              >
                <span aria-hidden="true">← </span>
                Back to home
              </Link>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Error;
