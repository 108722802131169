import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styles from "./Main.module.css";
import DiscordLogoWithBlinkingCircle from "./DiscordLogoWithBlinkingCircle";
import DropDownMenu from "./DropDownMenu";

const SideNav = ({ open, handleMenuClick }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const location = useLocation();
  const navigate = useNavigate();

  const dropdowns = [
    {
      title: "Community",
      links: [
        { name: "Charts & Financials", to: "/community/charts" },
        { name: "Earn Program", to: "/community/earn-program" },
        // { name: "Advocates", to: "/community/advocate-program" },
      ],
    },
    {
      title: "Resources",
      links: [
        { name: "Tutorials", to: "/docs/tutorials" },
        { name: "Blog", to: "/blog" },
        { name: "Docs", to: "/docs" },
        { name: "Changelog", to: "/docs/changelog" },
      ],
    },
    {
      title: "Company",
      links: [
        { name: "About Us", to: "/about-us" },
        { name: "Careers", to: "/careers" },
        { name: "Partners", to: "/partners" },
      ],
    },
    {
      title: "Pricing",
      to: "#premium-packages",
    },
  ];

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleLinkClick = (e, to) => {
    // When you're navigating to the premium packages section
    if (to === "#premium-packages") {
      // If you're not already on the home page, navigate there first
      if (location.pathname !== "/") {
        e.preventDefault();
        navigate("/");
        handleMenuClick();
        setTimeout(() => {
          document.querySelector(to).scrollIntoView({ behavior: "smooth" });
        }, 0);
      }
      // If you're already on the home page, simply scroll to the section
      else {
        e.preventDefault(); // Prevent the default action
        handleMenuClick(); // Close the side nav
        document.querySelector(to).scrollIntoView({ behavior: "smooth" });
      }
    }
    // For other links
    else {
      handleMenuClick();
    }
  };

  return (
    <div id="side-nav" className={`side-nav ${open ? "open" : ""}`}>
      <div
        id="nav-mobile-sidebar-menu"
        className=" flex h-full w-[30%] flex-col justify-between bg-black p-[2em_1em_1em] [-webkit-box-direction:normal] [-webkit-box-origin:vertical] [-webkit-box-pack:justify] md:hidden"
      >
        <div id="sidebar-mobile-menu" className="text-center">
          <Link
            to="/"
            id="home-side-nav"
            className="mb-6 block bg-transparent text-white [text-decoration:none] [transition:all_.1s_ease-in-out]"
            onClick={handleMenuClick}
          >
            <svg
              id="home-icon"
              className="inline-block overflow-hidden fill-current stroke-current stroke-0 align-bottom text-2xl"
              viewBox="0 0 38 32"
              width="24px"
              height="24px"
              fill="#fff"
            >
              <title>home-icon</title>
              <path
                fill="#fff"
                d="M37.596 12.751l-18.013-12.57c-0.347-0.242-0.819-0.242-1.166 0l-18.013 12.57c-0.44 0.307-0.536 0.896-0.213 1.315s0.94 0.51 1.38 0.203l17.429-12.163 17.429 12.163c0.176 0.123 0.38 0.182 0.582 0.182 0.304 0 0.604-0.133 0.797-0.385 0.322-0.419 0.227-1.008-0.213-1.315z"
              ></path>
              <path
                fill="#fff"
                d="M32.793 14.452c-0.544 0-0.985 0.415-0.985 0.928v14.765h-7.881v-8.060c0-2.558-2.21-4.638-4.926-4.638s-4.926 2.081-4.926 4.638v8.060h-7.881v-14.765c0-0.512-0.441-0.928-0.985-0.928s-0.985 0.415-0.985 0.928v15.693c0 0.512 0.441 0.928 0.985 0.928h9.852c0.518 0 0.942-0.377 0.981-0.855 0.002-0.022 0.004-0.045 0.004-0.072v-8.988c0-1.535 1.326-2.783 2.956-2.783s2.956 1.249 2.956 2.783v8.988c0 0.027 0.001 0.050 0.004 0.071 0.039 0.479 0.463 0.856 0.981 0.856h9.852c0.544 0 0.985-0.415 0.985-0.928v-15.693c-0-0.512-0.441-0.928-0.985-0.928z"
              ></path>
            </svg>
            <p id="p-home" className="m-[.25em_0_0] text-sm text-gray-400">
              Home
            </p>
          </Link>
          <Link
            to="/contact-us"
            id="contact"
            className="mb-6 flex flex-col items-center bg-transparent text-white [text-decoration:none] [transition:all_.1s_ease-in-out]"
            onClick={handleMenuClick}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlSpace="preserve"
              id="Layer_1"
              x="0"
              y="0"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              stroke="#fff"
              strokeWidth="1.5"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              fill="none"
            >
              <path d="M17.9 10.2h4.7V1.6H10.1v8.3h3.1l-.2 3.4 3.1-2.8" />
              <path d="M13.7 5.9h.3v.3h-.3zM16.3 5.9h.3v.3h-.3zM18.9 5.9h.3v.3h-.3z" />
              <path d="M1.6 9.6C1.1 13.4 11.8 25 15.8 22c.8-.6 2.2-1.4 1.6-2.4-.8-1.4-2.7-2.9-3.5-2.9-.6 0-1.3 1.4-2.3 1.1-1-.4-4.7-4.4-5.1-5.4-.4-.9 1.3-1.2 1.3-1.9 0-.6-2.2-3.7-3.3-3.6-1.4.2-2.8 1.6-2.9 2.7z" />
            </svg>

            <p id="p-contact" className="m-[.25em_0_0] text-sm text-gray-400">
              Contact
            </p>
          </Link>
          <Link
            to="https://discord.gg/urfhuyhTXd"
            id="home"
            className=" mb-6 flex flex-col items-center bg-transparent text-white [text-decoration:none] [transition:all_.1s_ease-in-out]"
          >
            <DiscordLogoWithBlinkingCircle className="ml-2.5 h-8 w-8" />
            <p
              id="p-discord"
              className="m-[.25em_0_0] mt-[-3.2px] text-sm text-gray-400"
            >
              Discord
            </p>
          </Link>
        </div>
        <div
          id="nav-mobile-sidebar-logo"
          className=" text-[.9em] font-bold leading-tight"
        >
          <Link
            to="/"
            id="home-risk"
            className="bg-transparent text-white [text-decoration:none] [transition:all_.1s_ease-in-out]"
            onClick={handleMenuClick}
          >
            <p>
              the
              <br />
              biggest
              <br />
              ris
              <span
                id="letter-glitch"
                className={`${styles.letterGlitch} relative z-0 overflow-auto opacity-100`}
              >
                k
              </span>
              <br />
              is not
              <br />
              taking
              <br />
              one
              <br />
            </p>
          </Link>
        </div>
      </div>

      <div
        id="side-nav-container"
        className={`flex-[1_1_100%] [-webkit-box-flex:1] ${
          windowWidth <= 767
            ? "h-full overflow-auto [-webkit-overflow-scrolling:touch]"
            : ""
        }`}
      >
        <div
          id="inner-container"
          className="relative mx-auto max-w-full px-[15px] md:w-[738px]"
        >
          <div
            id="flex"
            className={`[-webkit-box-align:stretch]" flex w-full items-stretch justify-between [-webkit-box-pack:justify] ${
              windowWidth <= 767
                ? "w-full flex-wrap overflow-auto p-[2em_1em]"
                : ""
            }`}
          >
            <nav
              id="primary-mobile-nav"
              className={` relative block translate-x-0 transform opacity-100 [transition:transform_.75s_.25s_ease-in-out,opacity_.75s_.25s_ease-in-out] ${
                windowWidth <= 767
                  ? "w-full text-left text-base before:mb-3 before:block before:text-2xl before:font-bold before:leading-tight before:[content:'Nav']"
                  : " w-[80%] text-right text-2xl"
              }`}
            >
              <ul
                id="menu-primary-nav"
                className={`p-0" m-0 ${
                  windowWidth <= 767 ? "w-full" : "w-[35%]"
                }`}
              >
                {dropdowns.map((dropdown, i) => {
                  if (dropdown.to) {
                    return (
                      <li
                        key={i}
                        className={`mb-2 list-none ${
                          windowWidth <= 767
                            ? "border-b border-gray-500 p-[.5em_0_1em] font-normal"
                            : "font-medium"
                        }`}
                      >
                        <Link
                          to={location.pathname === "/" ? dropdown.to : "/"}
                          className={`relative overflow-hidden bg-transparent text-white [text-decoration:none] [transition:all_.1s_ease-in-out] after:absolute after:right-0 after:top-[110%] after:h-0.5 after:w-[80%] after:bg-[#fff] after:opacity-0 after:[content:''] after:[transform:translateX(-50%)] after:[transition:all_.25s_ease-in-out] hover:after:opacity-100 hover:after:[transform:translateX(0)] ${
                            windowWidth <= 767 ? "after:hidden" : ""
                          }`}
                          onClick={(e) => handleLinkClick(e, dropdown.to)}
                        >
                          {dropdown.title}
                        </Link>
                      </li>
                    );
                  } else {
                    return (
                      <DropDownMenu
                        key={i}
                        dropdown={dropdown}
                        index={i}
                        handleMenuClick={handleMenuClick}
                      />
                    );
                  }
                })}
              </ul>
            </nav>
            <div
              id="nav_sidebar"
              className=" relative z-50 mt-6 flex w-full flex-[0_0_auto] flex-col justify-between [-webkit-box-direction:normal] [-webkit-box-pack:justify] [-webkit-box-flexL:0] [-webkit-box-orient:vertical] md:mt-0 md:w-[20%]"
            >
              <div
                id="nav_sidebar_info"
                className={`[-webkit-box-flex:0] md:flex-[0_0_auto] ${
                  windowWidth <= 767 ? "hidden" : ""
                }`}
              >
                <div id="nav_sidebar_social" className="mb-8">
                  <p
                    id="follow-us"
                    className=" m-[.5em_auto] mb-0 text-xl font-bold"
                  >
                    Follow Us
                  </p>
                  <ul
                    id="social-icons-ul"
                    className=" m-0 inline-block list-none p-0 text-center text-base"
                  >
                    <li
                      id="x-social"
                      className="m-[0_.5em_0_0] inline-block align-middle text-base"
                    >
                      <a
                        href="https://twitter.com/_madalgo"
                        target="_blank"
                        rel="noreferrer"
                        id="x-link"
                        className="group relative inline-block h-[1.257rem] w-[1.257rem] rounded-[50%] border-none bg-transparent text-center text-xs text-white [text-decoration:none] [transition:all_.1s_ease-in-out]"
                      >
                        <svg
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          stroke="none"
                          strokeWidth="1.5"
                          fill="#fff"
                          className="relative top-1/2 inline-block overflow-hidden [transition:all_.1s_ease-in-out] [-webkit-transform:translateY(-50%)] [transform:translateY(-50%)] group-hover:fill-green-500"
                        >
                          <g>
                            <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path>
                          </g>
                        </svg>
                      </a>
                    </li>
                    <li
                      id="facebook-social"
                      className="m-[0_.5em_0_0] inline-block align-middle text-base"
                    >
                      <a
                        href="https://facebook.com/madalgo"
                        target="_blank"
                        rel="noreferrer"
                        className="group relative inline-block h-[1.357rem] w-[1.357rem] rounded-[50%] border-none bg-transparent text-center text-xs text-white [text-decoration:none] [transition:all_.1s_ease-in-out]"
                      >
                        <svg
                          fill="currentColor"
                          viewBox="0 0 24 24"
                          className="relative top-1/2 inline-block overflow-hidden [transition:all_.1s_ease-in-out] [-webkit-transform:translateY(-50%)] [transform:translateY(-50%)] group-hover:fill-blue-500"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </a>
                    </li>
                    <li
                      id="instagram-social"
                      className="m-[0_.5em_0_0] inline-block align-middle text-base"
                    >
                      <a
                        href="https://instagram.com/_madalgo"
                        target="_blank"
                        rel="noreferrer"
                        className="group relative inline-block h-[1.297rem] w-[1.297rem] rounded-[50%] border-none bg-transparent text-center text-xs text-white [text-decoration:none] [transition:all_.1s_ease-in-out]"
                      >
                        <svg
                          fill="currentColor"
                          viewBox="0 0 24 24"
                          className="relative top-1/2 inline-block overflow-hidden [transition:all_.1s_ease-in-out] [-webkit-transform:translateY(-50%)] [transform:translateY(-50%)] group-hover:fill-fuchsia-500"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </a>
                    </li>
                    <li
                      id="youtube-social"
                      className="m-[0_.5em_0_0] inline-block align-middle text-base"
                    >
                      <a
                        href="https://youtube.com/_madalgo"
                        target="_blank"
                        rel="noreferrer"
                        className="group relative mt-1 inline-block h-[1.557rem] w-[1.557rem] rounded-[50%] border-none bg-transparent text-center text-xs text-white [text-decoration:none] [transition:all_.1s_ease-in-out]"
                      >
                        <svg
                          fill="currentColor"
                          viewBox="0 0 24 24"
                          className="relative top-1/2 inline-block overflow-hidden [transition:all_.1s_ease-in-out] [-webkit-transform:translateY(-50%)] [transform:translateY(-50%)] group-hover:fill-red-500"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                id="nav_sidebar_menu"
                className="flex-[0_0_auto] [-webkit-box-flex:0]"
              >
                <ul id="menu-nav-sidebar-navigation" className="p-0">
                  <li
                    id="list-item"
                    className="m-[.5em_auto] mt-1 list-none text-[1.5em] font-normal leading-tight xsm:text-[2em] md:text-[.9375em]"
                  >
                    <Link
                      to="/careers"
                      id="careers-link"
                      className="bg-transparent text-white [text-decoration:none] [transition:all_.1s_ease-in-out]"
                      onClick={handleMenuClick}
                    >
                      Careers
                    </Link>
                  </li>
                  <li
                    id="list-item"
                    className="m-[.5em_auto] mt-1 list-none text-[1.5em] font-normal leading-tight xsm:text-[2em] md:text-[.9375em]"
                  >
                    <Link
                      to="/blog"
                      id="blog-link"
                      className="bg-transparent text-white [text-decoration:none] [transition:all_.1s_ease-in-out]"
                      onClick={handleMenuClick}
                    >
                      Blog
                    </Link>
                  </li>
                  <li
                    id="list-item"
                    className="m-[.5em_auto] mt-1 list-none text-[1.5em] font-normal leading-tight xsm:text-[2em] md:text-[.9375em]"
                  >
                    <Link
                      to="/contact-us"
                      id="contact-link"
                      className="bg-transparent text-white [text-decoration:none] [transition:all_.1s_ease-in-out]"
                      onClick={handleMenuClick}
                    >
                      Contact
                    </Link>
                  </li>
                  <li
                    id="list-item"
                    className="m-[.5em_auto] mt-1 list-none text-[1.5em] font-normal leading-tight xsm:text-[2em] md:text-[.9375em]"
                  >
                    <Link
                      to="/manage-account"
                      id="my-account-link"
                      className="bg-transparent text-white [text-decoration:none] [transition:all_.1s_ease-in-out]"
                      onClick={handleMenuClick}
                    >
                      My Account
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideNav;
