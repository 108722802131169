import React, { memo } from "react";

function FundamentalDataWidget({ containerFA }) {
  return (
    <div
      className="tradingview-widget-container"
      ref={containerFA}
      style={{ height: "100%", width: "100%" }}
    >
      <div
        className="tradingview-widget-container__widget"
        style={{ height: "100%", width: "100%" }}
      ></div>
    </div>
  );
}

export default memo(FundamentalDataWidget);
