import { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import styles from "../Docs.module.css";
import { useTOC } from "../TOCContext";
import DocImages from "../DocImages";
import ThemeContext from "../ThemeContext";

const entries = [
  {
    displayText: "Create A Free Account",
    urlFragment: "create-a-free-account",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "Join The Discord",
    urlFragment: "join-the-discord",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "VIP Role",
    urlFragment: "vip-role",
    headingType: "h2",
    subEntries: [],
  },
];
export { entries };

export default function DocsJoinDiscord() {
  const { addToTOC, removeFromTOC } = useTOC();
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    entries.forEach(addToTOC);
    return () => {
      entries.forEach((entry) => removeFromTOC(entry));
    };
  }, [addToTOC, removeFromTOC]);

  return (
    <>
      <h1 className={styles.h1}>Join MADALGO In Discord</h1>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[12]}
          width={2743}
          height={469}
          alt="Discord"
        ></img>
      </p>
      <p className={styles.p}>
        Discord offers a complimentary instant-messaging service tailored for
        community engagement. Below is a beginner's guide to setting up a free
        Discord account and acquiring VIP access as a MADALGO Premium member.
      </p>
      <div
        className={`
        info info-caution selection:bg-[#c4f6ff] selection:text-[#2c2c2c49]
        ${theme === "light" ? styles.infoWarning : styles.infoWarningDark} 
        `}
      >
        <div className="info-heading">
          <h5 className={styles.h5}>
            <span
              className={`
                ${theme === "light" ? styles.infoAlert : styles.infoAlertDark}
              `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 14 16"
              >
                <path
                  fillRule="evenodd"
                  d="M7 2.3c3.14 0 5.7 2.56 5.7 5.7s-2.56 5.7-5.7 5.7A5.71 5.71 0 0 1 1.3 8c0-3.14 2.56-5.7 5.7-5.7zM7 1C3.14 1 0 4.14 0 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm1 3H6v5h2V4zm0 6H6v2h2v-2z"
                ></path>
              </svg>
            </span>
            Info
          </h5>
        </div>
        <div className="warning-content">
          <p>
            Discord is our chosen platform for housing our community, automated
            notifications, and trading optimization bots.
          </p>
        </div>
      </div>
      <h2 id="create-a-free-account" className={`scroll-mt-2 ${styles["h2"]}`}>
        Create A Free Account
        <Link
          to="#create-a-free-account"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        To establish a Discord account, visit the{" "}
        <Link
          to="https://discord.com/"
          target="_blank"
          rel="noopener noreferrer"
          className={`select-none transition-opacity duration-200 [text-decoration:none] hover:underline
              ${theme === "light" ? "text-blue-500" : "text-purple-500"}
              `}
        >
          Discord website
        </Link>
        , download the app (or access it via your browser), and select your
        preferred username.
      </p>
      <h2 id="join-the-discord" className={`scroll-mt-2 ${styles["h2"]}`}>
        Join The Discord
        <Link
          to="#join-the-discord"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[13]}
          width={1229}
          height={775}
          alt="Join Discord"
        ></img>
      </p>
      <p className={styles.p}>
        Upon joining our server, initially, you'll only have access to our
        public channels. Engage in the public trading forum, seek assistance in
        the #support channel, browse our announcements, view our tool demos,
        gather MADALGO-related info, and discover how to register. If you've yet
        to join the server,{" "}
        <Link
          to="https://discord.gg/urfhuyhTXd"
          className={`select-none transition-opacity duration-200 [text-decoration:none] hover:underline
              ${theme === "light" ? "text-blue-500" : "text-purple-500"}
              `}
        >
          click here.
        </Link>
      </p>
      <h2 id="vip-role" className={`scroll-mt-2 ${styles["h2"]}`}>
        VIP Role
        <Link
          to="#vip-role"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        The esteemed VIP Discord role is exclusively for MADALGO Premium
        subscribers from{" "}
        <Link
          to="/#premium-packages"
          className={`select-none transition-opacity duration-200 [text-decoration:none] hover:underline
              ${theme === "light" ? "text-blue-500" : "text-purple-500"}
              `}
        >
          our website
        </Link>
        . To obtain this role, users must link their Discord by pressing the
        "Connect Accounts" option in the Subscription Confirmation email
        received post-purchase.
      </p>
      <p className={styles.p}>
        Being a VIP unveils hidden chats, allowing interactions with our vibrant
        member community. Here, members share trade insights, assist with
        cross-market analyses, and chat in the #vip-hangout.
      </p>
      <div
        className={`
        info info-caution selection:bg-[#c4f6ff] selection:text-[#2c2c2c49]
        ${theme === "light" ? styles.infoWarning : styles.infoWarningDark} 
        `}
      >
        <div className="info-heading">
          <h5 className={styles.h5}>
            <span
              className={`
                ${theme === "light" ? styles.infoAlert : styles.infoAlertDark}
              `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 14 16"
              >
                <path
                  fillRule="evenodd"
                  d="M7 2.3c3.14 0 5.7 2.56 5.7 5.7s-2.56 5.7-5.7 5.7A5.71 5.71 0 0 1 1.3 8c0-3.14 2.56-5.7 5.7-5.7zM7 1C3.14 1 0 4.14 0 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm1 3H6v5h2V4zm0 6H6v2h2v-2z"
                ></path>
              </svg>
            </span>
            Info
          </h5>
        </div>
        <div className="warning-content">
          <p className="mb-4">
            For instant VIP Discord privileges, select "Connect Accounts" in
            your Subscription Confirmation email, then hit the 'Authorize
            Discord' option in the portal for automatic VIP role assignment.
          </p>
          <p>
            Encountering issues? Respond to the Subscription Confirmation email
            with your Discord username, and our dedicated support team will
            ensure you're fully set up within 6 hours.
          </p>
        </div>
      </div>
    </>
  );
}
