import { useState } from "react";
import styles from "./Main.module.css";
import { motion } from "framer-motion";
import ThreeComponent from "./ThreeComponent";

import { Amplify } from "aws-amplify";
import { API } from "aws-amplify";

import awsExports from "../aws-exports";
Amplify.configure(awsExports);

export default function Partners() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [honeypot, setHoneypot] = useState("");
  const hasLink = /https?:\/\/|www\./i;
  const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [emailError, setEmailError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [submissionStatus, setSubmissionStatus] = useState("idle");
  const ArrowIcon = () => (
    <svg
      className="pt-1"
      width="100%"
      height="100%"
      viewBox="0 0 20 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 5H19M19 5L14.6667 1M19 5L14.6667 9"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </svg>
  );

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const resetForm = () => {
    setHoneypot("");
    setSubmissionStatus("idle");
  };

  const list = {
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.2, // Increase the stagger to make it slower
        ease: [0.04, 0.62, 0.23, 0.98],
      },
    },
    hidden: {
      opacity: 0,
      transition: {
        when: "afterChildren",
        ease: [0.04, 0.62, 0.23, 0.98],
      },
    },
  };
  const item = {
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 2,
        ease: [0.04, 0.62, 0.23, 0.98],
      },
    },
    hidden: {
      opacity: 0,
      y: 50,
      transition: {
        duration: 2,
        ease: [0.04, 0.62, 0.23, 0.98],
      },
    },
  };

  const SpinnerMobile = () => {
    return (
      <div className={`${styles.spinnerContainer}`}>
        <div className={`${styles.spinnerMobile}`}></div>
      </div>
    );
  };

  async function handleSubmit(e) {
    e.preventDefault();
    let hasError = false;
    let timer;

    if (!email) {
      setEmailError("Email is required.");
      hasError = true;
    } else if (!isValidEmail.test(email)) {
      setEmailError("Invalid email address.");
      hasError = true;
    }

    if (!message) {
      setMessageError("Message is required.");
      hasError = true;
    }

    if (hasLink.test(message)) {
      setMessageError("Links are not allowed in message.");
      hasError = true;
    }

    if (honeypot) {
      return;
    }

    if (hasError) {
      return;
    }

    setSubmissionStatus("loading");
    setEmailError("");
    setMessageError("");

    const data = {
      body: {
        email: email,
        message: message,
      },
    };

    try {
      await API.post("madalgocontactapi", "/contact", data);
      setSubmissionStatus("success");
      setEmail("");
      setMessage("");
      timer = setTimeout(() => {
        resetForm();
      }, 3000);
    } catch (error) {
      console.error(error);
      setSubmissionStatus("error");
    }
    return () => clearTimeout(timer);
  }

  return (
    <div
      id="partners"
      className="flex min-h-screen flex-col justify-center py-40 sm:items-center"
    >
      <ThreeComponent />
      <div id="global-padding" className="p-2">
        <motion.div
          id="wrapper"
          className="flex w-full max-w-6xl flex-col items-center justify-around rounded-[2rem] border-[1px_solid_rgba(255,_255,_255,_0.125)] bg-white bg-opacity-10 p-6 shadow-2xl backdrop-blur-[2px] md:box-border md:p-16 lg:flex-row lg:p-[8rem_4rem] xl:p-[8rem_6rem]"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 2 }}
        >
          <motion.div
            id="inner"
            className="mb-10 max-w-xl lg:mb-0 lg:mr-8"
            initial="hidden"
            animate="visible"
            variants={list}
          >
            <motion.h1
              className="mb-6 text-xl font-bold leading-8 tracking-tight"
              variants={item}
            >
              Interested in Partnering with MADALGO?
            </motion.h1>
            <motion.p
              className="mb-2 text-sm leading-6 md:mb-4 md:text-base md:leading-7"
              variants={item}
            >
              At MADALGO, we're always eager to collaborate with
              forward-thinking entities and individuals. As we continue to grow
              and make strides in our industry, we recognize the immense value
              partnerships can bring.
            </motion.p>
            <motion.p
              className="mb-2 text-sm leading-6 md:mb-4 md:text-base md:leading-7"
              variants={item}
            >
              We're committed to innovation, dedication, and the endless
              potential of collaborative ventures. If our goals align with yours
              and you're considering a partnership, we're excited to discuss the
              possibilities.
            </motion.p>
            <motion.p
              className="text-sm leading-6 md:mb-12 md:text-base md:leading-7"
              variants={item}
            >
              If you're keen on exploring partnership opportunities with
              MADALGO, please fill out the form. We're eager to understand your
              perspectives and look forward to connecting with you.
            </motion.p>
          </motion.div>
          <motion.div
            className="flex w-full max-w-xl flex-col items-start lg:w-auto"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 2, delay: 0.8 }}
          >
            <label htmlFor="email" className="mb-2 hidden text-sm">
              Stay updated:
            </label>
            <div className="flex w-full flex-col lg:w-64">
              <input
                type="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="Your email"
                className="mb-2 flex-grow rounded border border-purple-300 bg-transparent px-3 py-2 text-purple-400 caret-purple-400 placeholder:text-purple-400 hover:border-purple-500 focus:border-purple-500 focus:outline-none"
              />
              <p className="error-message mb-2 text-sm text-red-500">
                {emailError}
              </p>
              <textarea
                type="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
                maxLength="500"
                minLength="2"
                placeholder="Your message or proposal..."
                className="mb-2 flex-grow resize-none rounded border border-purple-300 bg-transparent px-3 py-2 text-purple-400 caret-purple-400 placeholder:text-purple-400 hover:border-purple-500 focus:border-purple-500 focus:outline-none"
                rows="5"
              ></textarea>
              <p className="error-message mb-2 text-sm text-red-500">
                {messageError}
              </p>
              <button
                onClick={handleSubmit}
                className="group flex w-full items-center justify-center rounded bg-purple-600 px-4 py-2 text-white shadow-2xl shadow-purple-950 transition-[all_.3s_easeInOut]  hover:bg-purple-700 focus:outline-none lg:w-auto"
                disabled={submissionStatus === "loading"}
              >
                {submissionStatus === "loading" ? (
                  <SpinnerMobile />
                ) : submissionStatus === "success" ? (
                  "Email Sent Successfully"
                ) : submissionStatus === "error" ? (
                  "Error Sending Email"
                ) : (
                  <>
                    <span className="transition-[all_.3s_easeInOut] group-hover:scale-110">
                      Submit
                    </span>
                    <span className="ml-2 h-[19.2px] w-4 transition-[all_.3s_easeInOut] group-hover:translate-x-2 group-hover:scale-110">
                      <ArrowIcon />
                    </span>
                  </>
                )}
              </button>
            </div>
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
}
