import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import styles from "./Main.module.css";
import ai from "./videos/ai.webm";
import DiscordLogoWithBlinkingCircle from "./DiscordLogoWithBlinkingCircle";
import { useLocation } from "react-router-dom";
import images from "./images";

const wrapperVariants = {
  hover: {
    width: "40rem",
    height: "28.4rem",
    transition: {
      // Your transition configuration
    },
  },
  initial: {
    transform:
      "translate3d(50px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)",
    width: "0rem",
    height: "0rem",
  },
};

const dropdownVariants = {
  hover: {
    opacity: 1,
    transform:
      "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)",
    display: "grid",
    transition: {
      // Your transition configuration
    },
  },
  initial: {
    opacity: 0,
    transform:
      "translate3d(-150px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)",
    display: "none",
  },
};

const arrowVariants = {
  initial: {
    opacity: 0,
    transform:
      "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)",
  },
  navResourcesHovered: {
    opacity: 1,
    transform:
      "translate3D(6px, -4px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)",
  },
  learnMoreHovered: {
    transform:
      "translate3D(9px, -2px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)",
  },
};

export default function DropDownCommunity({ isAboutUsPage, isBlogPage }) {
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isNavResourcesHovered, setIsNavResourcesHovered] = useState(false);
  const [isLearnMoreHovered, setIsLearnMoreHovered] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const location = useLocation();
  useEffect(() => {
    setDropdownVisible(false);
  }, [location]);

  return (
    <motion.div
      id="nav-menu-item-wrapper"
      className="relative flex flex-[0_auto]"
      onMouseEnter={() => setDropdownVisible(true)}
      onMouseLeave={() => setDropdownVisible(false)}
    >
      <div
        id="nav-link-community"
        className="group flex w-28 cursor-default items-center justify-center py-6"
      >
        <Link
          to="#"
          id="community-nav-link"
          className={` relative mr-0 flex max-w-full items-center justify-center rounded-xl p-[8px_12px] text-sm font-medium capitalize opacity-100 [text-decoration:none] ${
            isAboutUsPage || isBlogPage
              ? " text-black [transition:all_.1s_ease-in-out] after:absolute after:left-0 after:top-[80%] after:h-0.5 after:w-[80%] after:bg-black after:opacity-0 after:[content:''] after:[transform:translateX(-10%)] after:[transition:.25s_ease-in-out] hover:after:opacity-100 hover:after:[transform:translateX(0)]"
              : " text-white [transition:opacity_.2s,color_.2s] hover:bg-[rgb(20,_18,_23)] hover:text-cyan-500"
          }`}
        >
          Community
        </Link>
      </div>
      <motion.div
        id="nav-dropdown-wrapper"
        className=" absolute -left-[100px] top-full h-0 max-h-[calc(100vh_-_120px)_!important] w-0 overflow-hidden rounded-lg pt-2 will-change-transform"
        style={{
          transformStyle: "preserve-3d",
        }}
        variants={wrapperVariants}
        animate={isDropdownVisible ? "hover" : "initial"}
      >
        <div
          id="nav-drop-wrap"
          className="z-100 relative h-full w-full overflow-hidden rounded-lg bg-[#0a090d] [border:1px_solid_#141217]"
        >
          <motion.div
            id="community-drop-down"
            className=" bg-[#0a090d][grid-column-gap:0px] absolute left-[0] h-[28.4rem] w-[40rem]  rounded-xl will-change-transform [grid-auto-columns:1fr] [grid-template-columns:1fr_1fr] [grid-template-rows:auto]"
            style={{
              transformStyle: "preserve-3d",
            }}
            variants={dropdownVariants}
            animate={isDropdownVisible ? "hover" : "initial"}
          >
            <Link
              to="/community"
              id="nav-community-left"
              className=" content group grid max-w-full auto-cols-fr grid-cols-1 grid-rows-[auto_auto] items-start justify-start justify-items-start gap-x-4 gap-y-4 bg-black p-8"
              onClick={() => setDropdownVisible(false)}
            >
              <motion.div
                onMouseEnter={() => setIsNavResourcesHovered(true)}
                onMouseLeave={() => setIsNavResourcesHovered(false)}
              >
                <div
                  id="left-content-wrapper"
                  className="mb-4 flex flex-col items-start [span:span_1/span_1/span_1/span_1]"
                >
                  <h3
                    id="content-heading"
                    className="my-0 text-sm font-medium leading-tight"
                  >
                    Community Home
                  </h3>
                  <div id="padding" className=" p-2 pl-0 pr-0 pt-0"></div>
                  <p
                    id="community-p-text"
                    className=" my-0 text-xs font-medium text-gray-400"
                  >
                    Join our vibrant community of traders to scale and develop
                    your skills while being supported by your peers.
                  </p>
                  <motion.div
                    id="learn-more-button-container"
                    className="group relative cursor-pointer rounded-[3rem] bg-transparent p-[.5rem_.75rem_.5rem_.125rem] pl-0 text-center text-sm font-medium capitalize text-[rgba(255,_255,_255,_0.7)] shadow-none transition-colors duration-200 ease-in-out will-change-transform [text-decoration:none] [background-image:none] hover:[filter:brightness(470%)grayscale()] group-hover:text-cyan-500"
                    onMouseEnter={() => setIsLearnMoreHovered(true)}
                    onMouseLeave={() => setIsLearnMoreHovered(false)}
                  >
                    <div id="learn-more-community-home" className="mr-0.5">
                      <div id="text" className="text-xs">
                        Learn More
                      </div>
                    </div>
                    <motion.div
                      id="arrow-container"
                      className="absolute bottom-auto left-auto right-0 top-2 block"
                      style={{
                        transformStyle: "preserve-3d",
                      }}
                      variants={arrowVariants}
                      animate={
                        isLearnMoreHovered
                          ? "learnMoreHovered"
                          : isNavResourcesHovered
                          ? "navResourcesHovered"
                          : "initial"
                      }
                    >
                      <div id="icon" className={`h-6 w-6` + styles["w-embed"]}>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="currentcolor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7 17L17 7M17 7V17M17 7H7"
                            stroke="rgb(6 182 212)"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                      </div>
                    </motion.div>
                  </motion.div>
                </div>
                <div
                  id="nav-animation-wrapper"
                  className=" relative w-full pt-[100%] mix-blend-lighten"
                >
                  <div
                    id="animation-embed"
                    className={
                      `absolute bottom-[0%] left-[0%] right-[0%] top-[0%] z-[1] [transform:translate(0)]` +
                      styles["w-embed"]
                    }
                  >
                    <div
                      id="w-background-video"
                      className="relative overflow-hidden"
                      style={{
                        width: "100%",
                        height: "95%",
                      }}
                    >
                      <video
                        id="data-globe"
                        className=" absolute z-[-100] m-auto h-full w-full object-cover [background-position:50%] [background-size:cover]"
                        autoPlay
                        loop
                        muted
                        poster={images[31]}
                      >
                        <source src={ai} data-wf-ignore="true" />
                      </video>
                    </div>
                  </div>
                </div>
              </motion.div>
            </Link>
            <div
              id="nav-resource-right-container"
              className=" grid auto-cols-fr auto-rows-min grid-cols-1 grid-rows-[min-content] gap-y-2 bg-[#141217] p-4 [grid-area:span_1/span_1/span_1/span_1]"
            >
              <Link
                to="/community/charts"
                id="nav-charts-element"
                className=" group grid w-full max-w-full cursor-pointer auto-cols-fr grid-cols-[1.5rem_1fr] grid-rows-[auto] gap-x-4 gap-y-1 rounded-xl bg-[#141217] p-[1rem_1.5rem_1rem_1rem] [align-content:start] [align-items:start] [transition:background-color_.2s_ease-in-out,color_.1s_linear] hover:bg-[#212026]"
                onClick={() => setDropdownVisible(false)}
              >
                <div
                  id="nav-link-icon"
                  className=" [grid-area:span_1/span_1/span_1/span 1] items-start [transition:text-color_.2s_ease-in-out,color_.1s_linear] group-hover:text-cyan-500"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                    <circle cx="9" cy="7" r="4"></circle>
                    <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                    <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                  </svg>
                </div>
                <div
                  id="nav-link-content"
                  className="grid auto-cols-fr grid-cols-1 grid-rows-[auto_auto] gap-x-4 gap-y-1 [grid-area:span_1/span_1/span_1/span_1]"
                >
                  <div id="nav-link-heading-wrap" className="flex items-start">
                    <h3
                      id="nav-link-heading"
                      className=" my-0 text-sm font-medium leading-tight [border-bottom:1px_solid_transparent]"
                    >
                      Charts & Financials
                    </h3>
                  </div>
                  <p id="nav-link-p" className=" my-0 text-xs text-gray-400">
                    Dive into the charts of your favorite asset, learn more
                    about the companies financials, all to help you make a more
                    informed decision while trading
                  </p>
                </div>
              </Link>
              <Link
                to="/community/earn-program"
                id="nav-earn-element"
                className=" group grid w-full max-w-full cursor-pointer auto-cols-fr grid-cols-[1.5rem_1fr] grid-rows-[auto] gap-x-4 gap-y-1 rounded-xl bg-[#141217] p-[1rem_1.5rem_1rem_1rem] [align-content:start] [align-items:start] [transition:background-color_.2s_ease-in-out,color_.1s_linear] hover:bg-[#212026]"
                onClick={() => setDropdownVisible(false)}
              >
                <div
                  id="nav-link-icon"
                  className=" [grid-area:span_1/span_1/span_1/span 1] items-start [transition:text-color_.2s_ease-in-out,color_.1s_linear] group-hover:text-cyan-500"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 256 256"
                    id="link"
                  >
                    <rect width="256" height="256" fill="none"></rect>
                    <path
                      className="[transition:stroke_.2s_ease-in-out,color_.1s_linear] group-hover:stroke-cyan-500"
                      fill="none"
                      stroke="#fff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="24"
                      d="M130.49413,63.28047l11.648-11.648a44,44,0,1,1,62.22539,62.22539l-28.28427,28.28428a44,44,0,0,1-62.2254,0"
                    ></path>
                    <path
                      className="[transition:stroke_.2s_ease-in-out,color_.1s_linear] group-hover:stroke-cyan-500"
                      fill="none"
                      stroke="#fff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="24"
                      d="M125.50407,192.72133l-11.64621,11.6462a44,44,0,1,1-62.22539-62.22539l28.28427-28.28428a44,44,0,0,1,62.2254,0"
                    ></path>
                  </svg>
                </div>
                <div
                  id="nav-link-content"
                  className="grid auto-cols-fr grid-cols-1 grid-rows-[auto_auto] gap-x-4 gap-y-1 [grid-area:span_1/span_1/span_1/span_1]"
                >
                  <div id="nav-link-heading-wrap" className="flex items-start">
                    <h3
                      id="nav-link-heading"
                      className=" my-0 text-sm font-medium leading-tight [border-bottom:1px_solid_transparent]"
                    >
                      Earn Program
                    </h3>
                  </div>
                  <p id="nav-link-p" className=" my-0 text-xs text-gray-400">
                    Start earning side cash today through our lucatrive program,
                    invite your friends and family to sign up to start their own
                    trading career today
                  </p>
                </div>
              </Link>
              {/* <Link
                to="/community/advocate-program"
                id="nav-advocate-element"
                className=" group grid w-full max-w-full cursor-pointer auto-cols-fr grid-cols-[1.5rem_1fr] grid-rows-[auto] gap-x-4 gap-y-1 rounded-xl bg-[#141217] p-[1rem_1.5rem_1rem_1rem] [align-content:start] [align-items:start] [transition:background-color_.2s_ease-in-out,color_.1s_linear] hover:bg-[#212026]"
                onClick={() => setDropdownVisible(false)}
              >
                <div
                  id="nav-link-icon"
                  className=" [grid-area:span_1/span_1/span_1/span 1] items-start [transition:text-color_.2s_ease-in-out,color_.1s_linear] group-hover:text-cyan-500"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className=""
                  >
                    <path
                      d="m8.9 15.2-4.7 2.7c-.9.5-2 .2-2.6-.7l-.8-1.4c-.5-.9-.2-2 .7-2.6l4.7-2.7 2.7 4.7z"
                      className="st0"
                      style={{
                        fill: "none",
                        stroke: "currentColor",
                        strokeWidth: "1.5",
                        strokeLinecap: "round",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M18.5 13.3 9 15.1l-2.8-4.5 6.2-7.9c.4-.6 1.3-.5 1.7.1l5.2 9c.2.6-.1 1.4-.8 1.5zM11.9 3.2l5.9 10.2"
                      className="st0"
                      style={{
                        fill: "none",
                        stroke: "currentColor",
                        strokeWidth: "1.5",
                        strokeLinecap: "round",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M15.8 5.8c.1 0 1.3 0 1.7-.1.1 0 .1 0 .2.1l.8 1.4v.2L17.6 9"
                      className="st0"
                      style={{
                        fill: "none",
                        stroke: "currentColor",
                        strokeWidth: "1.5",
                        strokeLinecap: "round",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="m18.9 1.2-.4 1.9M23.4 3.2l-2.6 1.5M22.9 8l-2-.5M4.9 15.4c-.4.4-.9.7-1.4.6-.5 0-.8-.4-.8-.5"
                      className="st1"
                      style={{
                        fill: "none",
                        stroke: "currentColor",
                        strokeWidth: "1.5",
                        strokeLinecap: "round",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <circle cx="6.4" cy="14.5" r=".1" className="st1" />
                    <path
                      d="m9.8 22.2-.8.4c-.5.3-1.1.1-1.4-.4l-2.7-4.7L7.5 16l2.7 4.7c.2.6.1 1.2-.4 1.5z"
                      style={{
                        fill: "none",
                        stroke: "currentColor",
                        strokeWidth: "1.5",
                        strokeLinecap: "round",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="m9.7 16.8-1.3.7c-.1 0-.2 0-.2-.1l-.7-1.3c0-.1 0-.2.1-.2l1.3-.7c.1 0 .2 0 .2.1l.7 1.3c0 .1 0 .2-.1.2z"
                      style={{
                        fill: "none",
                        stroke: "currentColor",
                        strokeWidth: "1.5",
                        strokeLinecap: "round",
                        strokeMiterlimit: "10",
                      }}
                    />
                  </svg>
                </div>
                <div
                  id="nav-link-content"
                  className="grid auto-cols-fr grid-cols-1 grid-rows-[auto_auto] gap-x-4 gap-y-1 [grid-area:span_1/span_1/span_1/span_1]"
                >
                  <div id="nav-link-heading-wrap" className="flex items-start">
                    <h3
                      id="nav-link-heading"
                      className=" my-0 text-sm font-medium leading-tight [border-bottom:1px_solid_transparent]"
                    >
                      Advocates
                    </h3>
                  </div>
                  <p id="nav-link-p" className=" my-0 text-xs text-gray-400">
                    Join our passionate advocate team, make a difference
                  </p>
                </div>
              </Link> */}
              <Link
                to="https://discord.gg/urfhuyhTXd"
                target="_blank"
                id="nav-discord-element"
                className=" group grid w-full max-w-full cursor-pointer auto-cols-fr grid-cols-[1.5rem_1fr] grid-rows-[auto] gap-x-4 gap-y-1 rounded-xl bg-[#141217] p-[1rem_1.5rem_1rem_1rem] [align-content:start] [align-items:start] [transition:background-color_.2s_ease-in-out,color_.1s_linear] hover:bg-[#212026]"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={() => setDropdownVisible(false)}
              >
                <div
                  id="nav-link-icon"
                  className=" [grid-area:span_1/span_1/span_1/span 1] flex items-start [transition:text-color_.2s_ease-in-out,color_.1s_linear] group-hover:text-cyan-500"
                >
                  <DiscordLogoWithBlinkingCircle
                    className="h-6 w-6"
                    isHovered={isHovered}
                  />
                </div>
                <div
                  id="nav-link-content"
                  className="grid auto-cols-fr grid-cols-1 grid-rows-[auto_auto] gap-x-4 gap-y-1 [grid-area:span_1/span_1/span_1/span_1]"
                >
                  <div id="nav-link-heading-wrap" className="flex items-start">
                    <h3
                      id="nav-link-heading"
                      className=" my-0 text-sm font-medium leading-tight [border-bottom:1px_solid_transparent]"
                    >
                      Discord Community
                    </h3>
                  </div>
                  <p id="nav-link-p" className=" my-0 text-xs text-gray-400">
                    Join our lively Discord Community! Engage, collaborate, and
                    be apart of a vibrant growing community where we all grow
                    together
                  </p>
                </div>
              </Link>
            </div>
          </motion.div>
        </div>
      </motion.div>
    </motion.div>
  );
}
