import { motion, useAnimation } from "framer-motion";
import { useState } from "react";

function Slide({ slideIndex, image, date, category, description, to }) {
  const [isHovered, setIsHovered] = useState(false);
  const controls = useAnimation();
  const contentControls = useAnimation();
  const arrowControls = useAnimation();

  const hoverEnter = () => {
    setIsHovered(true);
    controls.start({
      boxShadow: "0 25px 50px -12px rgba(0, 0, 0, 1)",
      transition: { duration: 0.2 },
    });
    contentControls.start({
      y: "-10px",
      transition: { duration: 0.2 },
    });

    // start arrow animation
    arrowControls.start({
      opacity: 1,
      translateX: 6,
      translateY: 6,
      transition: { duration: 0.2 },
    });
  };

  const hoverLeave = () => {
    setIsHovered(false);
    controls.start({
      boxShadow: "none",
      transition: { duration: 0.3 },
    });
    contentControls.start({
      y: "0px",
      transition: { duration: 0.3 },
    });

    // reset arrow animation
    arrowControls.start({
      opacity: 0,
      translateX: 0,
      translateY: 0,
      transition: { duration: 0.3 },
    });
  };

  return (
    <div
      className="splide__slide flex w-1/3 max-w-full flex-none"
      id={`card-slider-list-slide-${slideIndex + 1}`}
      key={slideIndex}
      onMouseEnter={hoverEnter}
      onMouseLeave={hoverLeave}
    >
      <div id="feature-card" className="group relative">
        <motion.a
          href={to}
          className="visible relative flex h-full w-full max-w-full flex-col rounded-xl border-[#0a090d] border-[1px_solid_transparent] bg-[#151318] bg-none bg-clip-padding text-white [-webkit-text-fill-color:_inherit] [box-shadow:_none] [text-decoration:none] [transition:_box-shadow_.3s_ease-out,color_.1s] after:absolute after:bottom-[-1px] after:left-[-1px] after:right-[-1px] after:top-[-1px] after:z-[-1] after:rounded-xl after:content-[''] after:[background:_linear-gradient(rgba(0,_159,_255,_0.19),_rgba(255,_255,_255,_0.05))]"
          animate={controls}
        >
          <motion.div
            id="image-wrapper"
            className="relative w-full [aspect-ratio:_454/256_!important;]"
          >
            <motion.img
              loading="lazy"
              id="featureListImage"
              src={image}
              srcSet={image}
              alt=""
              className="inline-block aspect-[1.77] h-auto w-full max-w-full rounded-t-xl object-cover [border:_0]"
              initial={{ opacity: 1 }}
              animate={{ opacity: isHovered ? 0.6 : 1 }}
              transition={{ duration: 0.3 }}
            />
            <motion.div
              id="splide__hover--arrow"
              className="hidden lg:absolute lg:bottom-auto lg:left-auto lg:right-[30px] lg:top-[30px] lg:block lg:h-8 lg:w-8"
              animate={arrowControls}
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)",
                opacity: 0,
                transformStyle: "preserve-3d",
              }}
            >
              <svg
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 17L17 7M17 7V17M17 7H7"
                  stroke="rgba(63, 169, 245, 1)"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
            </motion.div>
          </motion.div>
          <div
            id="content-wrapper"
            className=" relative w-full flex-[1] bg-none p-8"
          >
            <motion.div
              id="content-wrapper-bg"
              className="right[0%] absolute bottom-[0%] left-[0%] top-[0%] w-full rounded-b-xl bg-[#1f5780] bg-[linear-gradient(rgba(10,10,13,0),rgba(10,10,13,.88)_65%),linear-gradient(#16141a,#0f0d13_78%)] sm:bg-none"
              initial={{ opacity: 0 }}
              animate={{ opacity: isHovered ? 1 : 0 }}
              transition={{ duration: 0.3 }}
            ></motion.div>
            <motion.div
              id="content-wrapper-content"
              className="relative grid grid-cols-[1fr] grid-rows-[auto_auto] [grid-auto-columns:1fr] [grid-column-gap:1rem] [grid-row-gap:1rem]"
              animate={contentControls}
            >
              <div
                id="date-wrapper"
                className="flex items-start justify-between"
              >
                <motion.div
                  id="date"
                  className=" inline-block leading-none text-[color:_rgb(103,_102,_110)] [text-transform:_uppercase]"
                  animate={{
                    color: isHovered ? "#ffffff" : "rgb(103, 102, 110)",
                  }}
                  transition={{ duration: 0.3 }}
                >
                  {date}
                </motion.div>
                <div className="inline-block text-xs uppercase leading-none text-[#3fa9f5]">
                  {category}
                </div>
              </div>
              <h2
                id="content"
                className="my-0 overflow-hidden text-xl font-normal leading-[1.6] [-webkit-box-orient:_vertical] [-webkit-line-clamp:_3] [display:-webkit-box] [grid-area:_span_1/span_1/span_1/span_1]"
              >
                {description}
              </h2>
            </motion.div>
          </div>
        </motion.a>
      </div>
    </div>
  );
}

export default Slide;
