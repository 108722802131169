import { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import styles from "../Docs.module.css";
import { useTOC } from "../TOCContext";
import DocImages from "../DocImages";
import ThemeContext from "../ThemeContext";

const entries = [
  {
    displayText: "Settings",
    urlFragment: "settings",
    headingType: "h2",
    subEntries: [
      {
        displayText: "Activation",
        urlFragment: "activation",
        headingType: "ol",
      },
      {
        displayText: "Method Selection",
        urlFragment: "method-selection",
        headingType: "ol",
      },
      {
        displayText: "Algo Lookback",
        urlFragment: "algo-lookback",
        headingType: "ol",
      },
      {
        displayText: "Styling",
        urlFragment: "styling",
        headingType: "ol",
      },
    ],
  },
  {
    displayText: "Visual Display",
    urlFragment: "visual-display",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "Importance and Use",
    urlFragment: "importance-and-use",
    headingType: "h2",
    subEntries: [
      {
        displayText: "Key Price Levels",
        urlFragment: "key-price-levels",
        headingType: "h3",
      },
      {
        displayText: "Order Execution Strategy",
        urlFragment: "order-execution-strategy",
        headingType: "h3",
      },
    ],
  },
];
export { entries };

export default function DocsMADALGONavigatorInstitutionalLiquidutyZones() {
  const { theme } = useContext(ThemeContext);
  const { addToTOC, removeFromTOC } = useTOC();

  useEffect(() => {
    entries.forEach(addToTOC);
    return () => {
      entries.forEach((entry) => removeFromTOC(entry));
    };
  }, [addToTOC, removeFromTOC]);

  return (
    <>
      <h1 className={styles.h1}>Institutional Liquiduty Zones</h1>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[112]}
          width={606}
          height={495}
          className="mx-auto"
          alt="Institutional Liquiduty Zones"
        ></img>
      </p>
      <p className={styles.p}>
        The "Institutional Liquidity Zones" (ILZ) feature in MADALGO Navigator
        is a powerful tool that helps traders identify potential areas of
        significant price levels where institutional trading activity is likely
        to occur. These zones can offer insights into the execution strategies
        of large market participants.
      </p>

      <h2 id="settings" className={`scroll-mt-2 ${styles["h2"]}`}>
        Settings
        <Link
          to="#settings"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        Before using the Institutional Liquidity Zones indicator, it's essential
        to familiarize yourself with its customizable settings. Here are the key
        settings you can adjust:
      </p>
      <ol id="activation" className={styles.olBullet}>
        <li>Activation</li>
      </ol>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[113]}
          width={620}
          height={587}
          className="mx-auto"
          alt="Institutional Liquiduty Zones Settings"
        ></img>
      </p>
      <p className={styles.p}>
        To utilize the ILZ feature, navigate to the main settings of MADALGO
        Navigator and toggle the "ILZ" switch to the ON position. This activates
        the calculation and visualization of Institutional Liquidity Zones on
        your chart.
      </p>
      <ol id="method-selection" className={styles.olBullet}>
        <li>Method Selection</li>
      </ol>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[114]}
          width={103}
          height={246}
          className="mx-auto"
          alt="Institutional Liquiduty Zones Method Selection"
        ></img>
      </p>
      <p className={styles.p}>
        Choose from different calculation methods (A, B, C, D, E) to generate
        the Institutional Liquidity Zones. Each method utilizes a unique
        algorithm to calculate these zones based on historical price data. These
        methods provide varying perspectives on potential areas of liquidity
        accumulation.
      </p>
      <ol id="algo-lookback" className={styles.olBullet}>
        <li>Algo Lookback</li>
      </ol>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[115]}
          width={352}
          height={68}
          className="mx-auto"
          alt="Institutional Liquiduty Zones Algo Lookback"
        ></img>
      </p>
      <p className={styles.p}>
        Adjust the "Algo Lookback" parameter to determine the lookback period
        for the calculation of Institutional Liquidity Zones. This setting
        defines the number of past bars the algorithm considers when identifying
        potential zones. A longer lookback period may capture zones with a
        broader historical context.
      </p>
      <ol id="styling" className={styles.olBullet}>
        <li>Styling</li>
      </ol>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[116]}
          width={289}
          height={118}
          className="mx-auto"
          alt="Institutional Liquiduty Zones Styling"
        ></img>
      </p>
      <p className={styles.p}>
        The ILZ feature allows for customization of the visual appearance of the
        Institutional Liquidity Zones:
      </p>
      <ol className={styles.ol}>
        <li>
          Bear and Bull Box Colors: Choose colors for bearish and bullish
          liquidity zones. These colors help distinguish between areas where
          institutions are likely to sell or buy.
        </li>
        <li>
          Box Border Width and Style: Adjust the width and style of the border
          lines of the liquidity zone boxes. This customization helps you
          visualize the zones in a way that aligns with your preferences.
        </li>
      </ol>
      <div
        className={`
        info info-tip selection:bg-[#c4f6ff] selection:text-[#2c2c2c49]
        ${theme === "light" ? styles.infoTipInfo : styles.infoTipInfoDark} 
        `}
      >
        <div className="info-heading">
          <h5 className={styles.h5}>
            <span
              className={`
                ${theme === "light" ? styles.infoTip : styles.infoTipDark}
              `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 12 16"
              >
                <path
                  fillRule="evenodd"
                  d="M6.5 0C3.48 0 1 2.19 1 5c0 .92.55 2.25 1 3 1.34 2.25 1.78 2.78 2 4v1h5v-1c.22-1.22.66-1.75 2-4 .45-.75 1-2.08 1-3 0-2.81-2.48-5-5.5-5zm3.64 7.48c-.25.44-.47.8-.67 1.11-.86 1.41-1.25 2.06-1.45 3.23-.02.05-.02.11-.02.17H5c0-.06 0-.13-.02-.17-.2-1.17-.59-1.83-1.45-3.23-.2-.31-.42-.67-.67-1.11C2.44 6.78 2 5.65 2 5c0-2.2 2.02-4 4.5-4 1.22 0 2.36.42 3.22 1.19C10.55 2.94 11 3.94 11 5c0 .66-.44 1.78-.86 2.48zM4 14h5c-.23 1.14-1.3 2-2.5 2s-2.27-.86-2.5-2z"
                ></path>
              </svg>
            </span>
            Tip
          </h5>
        </div>
        <div className="warning-content">
          <p>
            Try different calculation methods to understand how each method
            generates liquidity zones. This experimentation can help you find
            the method that aligns best with your trading strategy.
          </p>
        </div>
      </div>

      <h2 id="visual-display" className={`scroll-mt-2 ${styles["h2"]}`}>
        Visual Display
        <Link
          to="#visual-display"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <figure className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[117]}
          width={1055}
          height={455}
          className="mx-auto"
          alt="Daily Chart BTC"
        ></img>
        <figcaption
          style={{
            textAlign: "center",
            fontSize: 12,
          }}
        >
          Daily Chart BTC
        </figcaption>
      </figure>
      <p className={styles.p}>
        The Institutional Liquidity Zones are visually displayed on your chart
        as rectangular boxes. These boxes are drawn around the calculated zones,
        indicating potential areas of interest. The display of these zones can
        be configured based on your chosen settings.
      </p>

      <h2 id="importance-and-use" className={`scroll-mt-2 ${styles["h2"]}`}>
        Importance and Use
        <Link
          to="#importance-and-use"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <h3 id="key-price-levels" className={`${styles["h3"]}`}>
        Key Price Levels
        <Link
          to="#key-price-levels"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[118]}
          width={1064}
          height={404}
          className="mx-auto"
          alt="Key Price Levels"
        ></img>
      </p>
      <p className={styles.p}>
        Institutional Liquidity Zones often correspond to key price levels on
        the chart. These levels may include previous support and resistance
        zones, pivot points, and other critical levels. Monitoring these zones
        can help you anticipate potential price movements.
      </p>
      <div
        className={`
        info info-tip selection:bg-[#c4f6ff] selection:text-[#2c2c2c49]
        ${theme === "light" ? styles.infoWarning : styles.infoWarningDark} 
        `}
      >
        <div className="info-heading">
          <h5 className={styles.h5}>
            <span
              className={`
                ${theme === "light" ? styles.infoAlert : styles.infoAlertDark}
              `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 12 16"
              >
                <path
                  fillRule="evenodd"
                  d="M7 2.3c3.14 0 5.7 2.56 5.7 5.7s-2.56 5.7-5.7 5.7A5.71 5.71 0 0 1 1.3 8c0-3.14 2.56-5.7 5.7-5.7zM7 1C3.14 1 0 4.14 0 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm1 3H6v5h2V4zm0 6H6v2h2v-2z"
                ></path>
              </svg>
            </span>
            Info
          </h5>
        </div>
        <div className="warning-content">
          <p>
            Incorporate Institutional Liquidity Zones into your broader
            technical and fundamental analysis. These zones are most effective
            when used in conjunction with other tools and indicators.
          </p>
        </div>
      </div>
      <h3 id="order-execution-strategy" className={`${styles["h3"]}`}>
        Order Execution Strategy
        <Link
          to="#order-execution-strategy"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[119]}
          width={1213}
          height={540}
          className="mx-auto"
          alt="Institutional Liquiduty Zones Order Execution Strategy"
        ></img>
      </p>
      <p className={styles.p}>
        Institutional traders seek to execute large orders with minimal slippage
        and impact costs. The ILZ feature provides insights into where
        institutions may intend to execute trades. This information can be
        valuable for your trading decisions, especially when aligning your
        strategies with institutional activity.
      </p>

      <div
        className={`warning warning-caution selection:bg-[#ffeec4] selection:text-[#4b4b4b] ${styles["alertWarning"]}`}
      >
        <div className="warning-heading">
          <h5 className={styles.h5}>
            <span className={styles.alertWarningIcon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M8.893 1.5c-.183-.31-.52-.5-.887-.5s-.703.19-.886.5L.138 13.499a.98.98 0 0 0 0 1.001c.193.31.53.501.886.501h13.964c.367 0 .704-.19.877-.5a1.03 1.03 0 0 0 .01-1.002L8.893 1.5zm.133 11.497H6.987v-2.003h2.039v2.003zm0-3.004H6.987V5.987h2.039v4.006z"
                ></path>
              </svg>
            </span>
            caution
          </h5>
        </div>
        <div className="warning-content">
          <p>
            <em>
              Disclaimer: The information provided here is for educational
              purposes only and does not constitute financial advice. Trading
              involves risk, and it's essential to conduct thorough research and
              seek advice from financial professionals before making any trading
              decisions.
            </em>
          </p>
        </div>
      </div>
    </>
  );
}
