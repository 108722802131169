import React, { useState, useRef, useEffect } from "react";
import { Auth, API, graphqlOperation, Hub } from "aws-amplify";
import { Link } from "react-router-dom";
import { createSubscriptionPlan } from "../utils/mutations";
import { ShowPasswordIcon } from "./utils/ShowPasswordIcon";
import { HidePasswordIcon } from "./utils/HidePasswordIcon";

const Signup = () => {
  const [step, setStep] = useState("signup"); // signup, verify

  const [verificationCode, setVerificationCode] = useState("");
  const [handleVerificationCode, setHandleVerificationCode] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [hasStartedTyping, setHasStartedTyping] = useState(false);
  const [showTooltip, setShowTooltip] = useState(true);
  const [isActive, setIsActive] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const [isEmailActive, setIsEmailActive] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isEmailFilled, setIsEmailFilled] = useState(false);
  const [email, setEmail] = useState("");

  const [captchaId, setCaptchaId] = useState(null);
  const [captchaImage, setCaptchaImage] = useState(null);
  const [typingStartTime, setTypingStartTime] = useState(null);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [isCaptchaActive, setIsCaptchaActive] = useState(false);
  const [isCaptchaFilled, setIsCaptchaFilled] = useState(false);
  const [captcha, setCaptcha] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const urlParams = new URLSearchParams(window.location.search);
  const planName = urlParams.get("planName");
  const billingCycle = urlParams.get("billingCycle");
  localStorage.setItem("planName", urlParams.get("planName"));
  localStorage.setItem("billingCycle", urlParams.get("billingCycle"));

  const containsAtLeast = useRef(null);

  const togglePassword = () => {
    setShowPassword(!showPassword);
    setShowTooltip(!showTooltip);
  };
  const handleFocus = () => setIsActive(true);
  const handleBlur = () => setIsActive(false);
  const handleChange = (e) => {
    const inputPassword = e.target.value;
    let conditionsMet = 0;

    // Lower case letters
    if (/[a-z]/.test(inputPassword)) conditionsMet++;

    // Upper case letters
    if (/[A-Z]/.test(inputPassword)) conditionsMet++;

    // Numbers
    if (/\d/.test(inputPassword)) conditionsMet++;

    // Special characters
    if (/[!@#$%^&*]/.test(inputPassword)) conditionsMet++;
    // At least 8 characters
    toggleCheckClass(
      document.querySelector(
        "[data-error-code='password-policy-length-at-least']",
      ),
      inputPassword.length >= 8,
    );

    // Update individual conditions
    toggleCheckClass(
      document.querySelector("[data-error-code='password-policy-lower-case']"),
      /[a-z]/.test(inputPassword),
    );

    toggleCheckClass(
      document.querySelector("[data-error-code='password-policy-upper-case']"),
      /[A-Z]/.test(inputPassword),
    );

    toggleCheckClass(
      document.querySelector("[data-error-code='password-policy-numbers']"),
      /\d/.test(inputPassword),
    );

    toggleCheckClass(
      document.querySelector(
        "[data-error-code='password-policy-special-characters']",
      ),
      /[!@#$%^&*]/.test(inputPassword),
    );
    toggleCheckClass(containsAtLeast, conditionsMet >= 3);

    setHasStartedTyping(inputPassword !== "");
    setPassword(e.target.value);
    setIsFilled(e.target.value !== "");
    setErrorMessage("");
  };
  const toggleCheckClass = (element, condition) => {
    // If it's a React ref, get the current DOM node
    if (element && element.current) {
      element = element.current;
    }
    // Toggle the class based on the condition
    if (element) {
      if (condition) {
        element.classList.add("valid-check");
      } else {
        element.classList.remove("valid-check");
      }
    }
  };

  const handleEmailFocus = () => setIsEmailActive(true);
  const handleEmailBlur = () => setIsEmailActive(false);
  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setIsEmailFilled(e.target.value !== "");
    setIsEmailValid(validateEmail(e.target.value));

    // Optionally clear other errors
    setErrorMessage([]);
  };

  const handleCaptchaFocus = () => setIsCaptchaActive(true);
  const handleCaptchaBlur = () => setIsCaptchaActive(false);
  const handleCaptchaChange = (e) => {
    setCaptcha(e.target.value);
    setIsCaptchaFilled(e.target.value !== "");
    setErrorMessage("");
  };

  let loadingCounter = 0;

  const setLoading = (isLoading) => {
    if (isLoading) {
      loadingCounter++;
    } else {
      loadingCounter = Math.max(0, loadingCounter - 1);
    }
    setIsLoading(loadingCounter > 0);
  };

  const handleFieldFocus = () => {
    if (!typingStartTime) {
      setTypingStartTime(Date.now());
    }
  };

  const handleSignUpClick = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (!isEmailValid && isEmailFilled) {
      setShowError(true);
      setErrorMessage("Invalid email format");
      setLoading(false);
      return;
    }
    // If CAPTCHA is visible, validate it
    if (showCaptcha) {
      try {
        const response = await fetch(
          process.env.REACT_APP_VALIDATE_CAPTCHA_URL,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              captchaId: captchaId,
              userInput: captcha,
            }),
          },
        );
        const data = await response.json();
        if (data.statusCode === 200) {
          // CAPTCHA validation was successful, proceed with sign-up
          await handleSignup();
        } else {
          setShowError(true);
          setErrorMessage("Incorrect CAPTCHA. Please try again.");
        }
      } catch (error) {
        setShowError(true);
        setErrorMessage("Failed to validate CAPTCHA");
      } finally {
        setLoading(false); // Ensure to set it back to false
      }
    } else {
      try {
        const typingEndTime = Date.now();
        const timeTaken = typingEndTime - typingStartTime;
        if (timeTaken < 5000) {
          await fetchCaptcha(); // Assuming fetchCaptcha is also async
          setShowCaptcha(true);
        } else {
          await handleSignup();
        }
      } finally {
        setLoading(false); // Ensure to set it back to false
      }
    }
  };

  const fetchCaptcha = async () => {
    setLoading(true);
    try {
      const response = await fetch(process.env.REACT_APP_GENERATE_CAPTCHA_URL);
      const data = await response.json();
      setCaptchaImage(`data:image/png;base64,${data.image}`);
      setCaptchaId(data.captchaId);
    } catch (error) {
      setShowError(true);
      setErrorMessage("Failed to fetch CAPTCHA");
    } finally {
      setLoading(false);
    }
  };

  const handleSignup = async () => {
    setLoading(true);
    try {
      await Auth.signUp({
        username: email,
        password,
        attributes: { email },
      });
      setStep("verify");
    } catch (error) {
      setShowError(true);
      setErrorMessage(`Error signing up: ${error.message}`);
      if (error.code === "UsernameExistsException") {
        setShowError(true);
        setErrorMessage(
          `An account with this email already exists: ${error.message}`,
        );
      } else {
        setShowError(true);
        setErrorMessage(`${error.message}`);
      }
    } finally {
      setLoading(false);
    }
  };

  const createStripeCustomer = async (email) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_CREATE_STRIPE_CUSTOMER,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        },
      );
      const textResponse = await response.text();
      console.log("Raw response:", textResponse);

      // Now attempt to parse the text as JSON
      const data = JSON.parse(textResponse);
      return data.stripeCustomerId;
    } catch (error) {
      setShowError(true);
      setErrorMessage(`Error creating Stripe customer: ${error.message}`);
      console.error("Error creating Stripe customer:", error);
      return null;
    }
  };

  const handleVerification = async () => {
    setLoading(true);
    try {
      console.log("Confirming sign up...");
      await Auth.confirmSignUp(email, verificationCode);
      console.log("Sign up confirmed.");

      console.log("Signing in user...");
      await Auth.signIn(email, password);
      console.log("User signed in successfully.");

      // Store subscription plan details after successful verification

      const currentUser = await Auth.currentAuthenticatedUser();
      const jwtToken = currentUser.signInUserSession.idToken.jwtToken;
      document.cookie = `token=${jwtToken}; domain=.madalgo.io; path=/; secure`;
      const accessToken = currentUser.signInUserSession.accessToken.jwtToken;
      document.cookie = `accessToken=${accessToken}; domain=.madalgo.io; path=/; secure`;

      // Create or get Stripe customer ID
      console.log("Creating/getting Stripe customer ID...");
      const stripeCustomerId = await createStripeCustomer(email);
      if (!stripeCustomerId) {
        console.error(
          "Failed to fetch Stripe Customer ID. Stopping further processing.",
        );
        return; // Early exit from the function if stripeCustomerId is null
      }

      const userID = currentUser.username;
      const planDetails = {
        userID,
        planName: planName || "DefaultPlan",
        billingCycle: billingCycle || "DefaultCycle",
        stripeCustomerId: stripeCustomerId,
      };
      console.log("Creating subscription plan...");
      const newSubscriptionPlan = await API.graphql(
        graphqlOperation(createSubscriptionPlan, { input: planDetails }),
      );
      console.log("New subscription plan:", newSubscriptionPlan);

      // Navigate to checkout
      console.log("Redirecting to checkout page...");
      const planHref =
        planName.toLowerCase() !== "lifetime"
          ? `https://app.madalgo.io/checkout?planName=${planName.toLowerCase()}&billingCycle=${billingCycle}`
          : `https://app.madalgo.io/checkout?planName=lifetime`;

      window.location.href = planHref;
    } catch (error) {
      console.error("Error during verification:", error);
      if (error.code === "UserNotConfirmedException") {
        setErrorMessage(
          "Your account hasn't been verified. Please check your email for the verification code.",
        );
      } else if (error.code === "NotAuthorizedException") {
        setErrorMessage(
          "Failed to sign in. Please ensure your credentials are correct.",
        );
      } else if (error.code === "TooManyRequestsException") {
        setErrorMessage("Too many requests. Please wait and try again.");
      } else {
        setErrorMessage(`Error during verification: ${error.message}`);
      }
      setShowError(true);
    } finally {
      setLoading(false);
    }
  };

  // const handleGoogleSignup = async () => {
  //   try {
  //     await Auth.federatedSignIn({ provider: "Google" });
  //   } catch (error) {
  //     setShowError(true);
  //     setErrorMessage(`Error signing in with Google: ${error.message}`);
  //   }
  // };

  // useEffect(() => {
  //   const unsubscribe = Hub.listen("auth", async (data) => {
  //     console.log("Hub event data:", data);
  //     const { payload } = data;
  //     if (payload.event === "cognitoHostedUI") {
  //       const currentUser = await Auth.currentAuthenticatedUser();
  //       const userID = currentUser.username;

  //       // Create or get Stripe customer ID
  //       console.log("Creating/getting Stripe customer ID for Google user...");
  //       const stripeCustomerId = await createStripeCustomer(
  //         currentUser.attributes.email,
  //       );
  //       if (!stripeCustomerId) {
  //         console.error(
  //           "Failed to fetch Stripe Customer ID for Google user. Stopping further processing.",
  //         );
  //         return; // Early exit if stripeCustomerId is null
  //       }

  //       const jwtToken = currentUser.signInUserSession.idToken.jwtToken;
  //       document.cookie = `token=${jwtToken}; domain=.madalgo.io; path=/; secure`;
  //       const accessToken = currentUser.signInUserSession.accessToken.jwtToken;
  //       document.cookie = `accessToken=${accessToken}; domain=.madalgo.io; path=/; secure`;

  //       const planDetails = {
  //         userID,
  //         planName: finalPlanName,
  //         billingCycle: finalBillingCycle,
  //         stripeCustomerId: stripeCustomerId,
  //       };

  //       const newSubscriptionPlan = await API.graphql(
  //         graphqlOperation(createSubscriptionPlan, { input: planDetails }),
  //       );

  //       console.log("New subscription plan:", newSubscriptionPlan);
  //       // Get plan details from local storage
  //       const storedPlanName = localStorage.getItem("planName");
  //       const storedBillingCycle = localStorage.getItem("billingCycle");
  //       console.log("Retrieved from local storage:", {
  //         storedPlanName,
  //         storedBillingCycle,
  //       });

  //       // Fallback to default values if the stored ones are null
  //       const finalPlanName = storedPlanName || "DefaultPlan";
  //       const finalBillingCycle = storedBillingCycle || "DefaultCycle";

  //       // Navigate to checkout
  //       const planHref =
  //         finalPlanName.toLowerCase() !== "lifetime"
  //           ? `https://app.madalgo.io/checkout?planName=${finalPlanName.toLowerCase()}&billingCycle=${finalBillingCycle}`
  //           : `https://app.madalgo.io/checkout?planName=lifetime`;

  //       window.location.href = planHref;

  //       // Clear the local storage items
  //       localStorage.removeItem("planName");
  //       localStorage.removeItem("billingCycle");
  //     }
  //   });

  //   // Cleanup
  //   return () => unsubscribe();
  // }, []);

  const signInLink = `/u/signin?planName=${planName}&billingCycle=${billingCycle}`;

  const handleVerificationCodeFocus = () => setHandleVerificationCode(true);
  const handleVerificationCodeBlur = () => setHandleVerificationCode(false);
  const handleVerificationCodeChange = (e) => {
    setVerificationCode(e.target.value);
    setHandleVerificationCode(e.target.value !== "");
    setErrorMessage("");
  };

  return (
    <section className="hdf04n04 _prompt-box-outer hdf04n04">
      <div className="hd73bh7d hdn8236v">
        <div className="hd8hn83b">
          <header className="hd83b78n he9n38nn">
            <div
              title="MADALGO"
              id="c-p-logo"
              style={{
                width: "auto",
                height: "60",
                position: "static",
                margin: "auto",
                padding: "0",
                backgroundColor: "transparent",
                backgroundPosition: "center",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              }}
            ></div>
            <img
              className="he8937bd8 he8nds8n"
              id="p-l-c"
              src="https://madalgo.io/logo192.png"
              alt="MADALGO"
            ></img>
            <h1 className="h73bh90sd h38ubjd9">
              {step === "signup"
                ? "Create your MADALGO account"
                : "Verify your MADALGO account"}
            </h1>
            <div className="h8dnb7bd h7bs6vls">
              <p className="h98sdbfdd h3md9ndd">
                {step === "signup"
                  ? "If you've previously had a subscription with us, make sure to use the same email."
                  : "Please enter the verification code sent to your email."}
              </p>
            </div>
          </header>
          {step === "signup" ? (
            <div className="hs873bdf8 hd83b78n">
              <form className="c12db2c34 cf114ab0b">
                <input type="hidden" name="" value="" />
                <div className="c62f2b894 c3bcf3ed8">
                  <input type="hidden" name="strengthPolicy" value="good" />
                  <input
                    type="hidden"
                    name="complexityOptions.minLength"
                    value=""
                  />
                  <div className="c93ndsnfj cf832ns09">
                    <div className="input-wrapper">
                      <div
                        className="ca19662c8 c0749183e text c41b84477 cb6f7ea00"
                        data-action-text=""
                        data-alternate-action-text=""
                      >
                        <label
                          className="c9d37bb1f no-js ce8354906 c7aebad14"
                          htmlFor="email"
                        >
                          Email
                        </label>
                        <input
                          id="email"
                          className={`cbbf11f5c c1ad8da8e input ${
                            isEmailActive || isEmailFilled ? "hbdf8hsdn" : ""
                          }`}
                          inputMode="email"
                          name="email"
                          type="text"
                          value={email}
                          required={true}
                          autoComplete="username"
                          autoCapitalize="none"
                          onChange={handleEmailChange}
                          onFocus={() => {
                            handleFieldFocus();
                            handleEmailFocus();
                          }}
                          onBlur={handleEmailBlur}
                        />
                        <div
                          className={`c9d37bb1f js-required ce8354906 c7aebad14 ${
                            isEmailActive || isEmailFilled ? "hbdf8hsdn" : ""
                          }`}
                          data-dynamic-label-for="email"
                          aria-hidden="true"
                        >
                          Email
                        </div>
                      </div>
                    </div>
                    <div className="input-wrapper">
                      <div
                        className={`ca19662c8 c0749183e password c41b84477 cb6f7ea00 ${
                          isActive || isFilled ? "hbdf8hsdn" : ""
                        }`}
                        data-action-text=""
                        data-alternate-action-text=""
                      >
                        <label className="c9d37bb1f no-js ce8354906 c189ccd39">
                          Password
                        </label>
                        <input
                          id="password"
                          className="cbbf11f5c c24e80294 input"
                          name="password"
                          type={showPassword ? "text" : "password"}
                          required={true}
                          autoComplete="new-password"
                          autoCapitalize="none"
                          spellCheck="false"
                          value={password}
                          onFocus={() => {
                            handleFieldFocus();
                            handleFocus();
                          }}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        <div
                          className={`c9d37bb1f js-required ce8354906 c189ccd39 ${
                            isActive || isFilled ? "hbdf8hsdn" : ""
                          }`}
                          data-dynamic-label-for="password"
                          aria-hidden="true"
                        >
                          Password
                        </div>
                        <button
                          className="df893n9fn ulp-button-icon ddf87b3n9 _button-icon"
                          type="button"
                          onClick={togglePassword}
                        >
                          <span
                            className={`password-icon-tooltip show-password-tooltip ${
                              showTooltip ? "" : "hide"
                            }`}
                            aria-hidden="true"
                          >
                            Show password
                          </span>
                          <span
                            className={`password-icon-tooltip hide-password-tooltip ${
                              showTooltip ? "hide" : ""
                            }`}
                            aria-hidden="true"
                          >
                            Hide password
                          </span>
                          <span
                            className={`screen-reader-only password-toggle-label ${
                              showTooltip ? "" : "hide"
                            }`}
                            data-label="show-password"
                          >
                            Show password
                          </span>
                          <span
                            className={`screen-reader-only password-toggle-label ${
                              showTooltip ? "hide" : "unset"
                            }`}
                            data-label="hide-password"
                          >
                            Hide password
                          </span>
                          <span
                            className="h8G2R6oW password js-required"
                            aria-hidden="true"
                          >
                            {showTooltip ? (
                              <ShowPasswordIcon />
                            ) : (
                              <HidePasswordIcon />
                            )}
                          </span>
                        </button>
                      </div>
                    </div>
                    {showCaptcha && (
                      <div className="nd93nsdn hf83bsd9">
                        <div className="ndhe834h ndf943nsd">
                          <img alt="captcha" src={captchaImage} />
                        </div>
                        <div className="input-wrapper">
                          <div
                            className={`ca19662c8 c0749183e password c41b84477 cb6f7ea00 ${
                              isCaptchaActive || isCaptchaFilled
                                ? "hbdf8hsdn"
                                : ""
                            }`}
                            data-action-text=""
                            data-alternate-action-text=""
                          >
                            <label className="c9d37bb1f no-js ce8354906 c189ccd39">
                              Enter the code shown above
                            </label>
                            <input
                              id="captcha"
                              className="cbbf11f5c c24e80294 input"
                              name="captcha"
                              type="captcha"
                              required={true}
                              autoComplete="new-password"
                              autoCapitalize="none"
                              spellCheck="false"
                              value={captcha}
                              onFocus={handleCaptchaFocus}
                              onBlur={handleCaptchaBlur}
                              onChange={handleCaptchaChange}
                            />
                            <div
                              className={`c9d37bb1f js-required ce8354906 c189ccd39 ${
                                isCaptchaActive || isCaptchaFilled
                                  ? "hbdf8hsdn"
                                  : ""
                              }`}
                              data-dynamic-label-for="captcha"
                            >
                              Enter the code shown above
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    className={`md0m39nfl no-arrow ndf98nm3 dkjd93nmn iksd93n _hide ${
                      hasStartedTyping ? "" : "hide"
                    }`}
                    aria-live="assertive"
                    aria-atomic="true"
                  >
                    <div className="mhdf8n3j">
                      <ul className="mjr9sdn9">
                        <li className="hd8h39mk">
                          <div className="nd93hmas">
                            <span className="93nd8n3k">
                              Your password must contain:
                            </span>
                            <ul className="mdoi93n0">
                              <li
                                className="j93nsd8n"
                                data-error-code="password-policy-length-at-least"
                              >
                                <span className="caf0d2c4a">
                                  At least 8 characters
                                </span>
                              </li>
                              <li
                                className="j93nsd8n"
                                data-error-code="password-policy-contains-at-least"
                                ref={containsAtLeast}
                              >
                                <span className="caf0d2c4a">
                                  At least 3 of the following:
                                </span>
                                <div>
                                  <ul>
                                    <li
                                      className="j93nsd8n"
                                      data-error-code="password-policy-lower-case"
                                    >
                                      <span className="caf0d2c4a">
                                        Lower case letters (a-z)
                                      </span>
                                    </li>
                                    <li
                                      className="j93nsd8n"
                                      data-error-code="password-policy-upper-case"
                                    >
                                      <span className="caf0d2c4a">
                                        Upper case letters (A-Z)
                                      </span>
                                    </li>
                                    <li
                                      className="j93nsd8n"
                                      data-error-code="password-policy-numbers"
                                    >
                                      <span className="caf0d2c4a">
                                        Numbers (0-9)
                                      </span>
                                    </li>
                                    <li
                                      className="j93nsd8n"
                                      data-error-code="password-policy-special-characters"
                                    >
                                      <span className="caf0d2c4a">
                                        Special characters (e.g. !@#$%^&*)
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="c6d5cc3be">
                  <button
                    className="df893n9fn dh83b09c3 c5bf19ed3 c66fbaaf0 c732daa90"
                    disabled={!isEmailValid || isLoading}
                    onClick={handleSignUpClick}
                  >
                    {isLoading ? <div className="spinner"></div> : "Continue"}
                  </button>
                </div>
                {showError && (
                  <div className="hd73666 error d9893h7">{errorMessage}</div>
                )}
              </form>
              <div className="ul-alternate-action  _alternate-action">
                <p className="hdsm94nd">
                  Already have an account?
                  <Link
                    className="h2nd9ns8 h39dns89"
                    to={signInLink}
                    aria-label=""
                  >
                    Log in
                  </Link>
                </p>
              </div>
              {/* <div className="h23vsd7d h238ndm4">
                <span>Or</span>
              </div>
              <div className="dh93n0f3 d94ndf7bn">
                <form
                  method="post"
                  data-provider="google"
                  className="d78nbenb3 d834nf9 d2mdf9jkm"
                  data-form-secondary="true"
                >
                  <input type="hidden" name="" value="" />

                  <input
                    type="hidden"
                    name="connection"
                    value="google-oauth2"
                  />

                  <button
                    type="button"
                    className="d93nmfp0 d4f81gf5 d3df562f"
                    data-provider="google"
                    data-action-button-secondary="true"
                    onClick={handleGoogleSignup}
                  >
                    <span
                      className="d98dfnd8 d67dbf7d"
                      data-provider="google"
                    ></span>

                    <span className="d5df81dsf">Continue with Google</span>
                  </button>
                </form>
              </div> */}
            </div>
          ) : (
            <div className="hs873bdf8">
              <div className="input-wrapper">
                <div
                  className={`ca19662c8 c0749183e password c41b84477 cb6f7ea00 ${
                    handleVerificationCode ? "hbdf8hsdn" : ""
                  }`}
                  data-action-text=""
                  data-alternate-action-text=""
                >
                  <label className="c9d37bb1f no-js ce8354906 c189ccd39">
                    Verification Code
                  </label>
                  <input
                    type="text"
                    id="verification-code-input"
                    className="cbbf11f5c c24e80294 input"
                    required={true}
                    value={verificationCode}
                    onChange={handleVerificationCodeChange}
                    onFocus={handleVerificationCodeFocus}
                    onBlur={handleVerificationCodeBlur}
                  />
                  <div
                    className={`c9d37bb1f js-required ce8354906 c189ccd39 ${
                      handleVerificationCode ? "hbdf8hsdn" : ""
                    }`}
                    data-dynamic-label-for="password"
                    aria-hidden="true"
                  >
                    Verification Code
                  </div>
                </div>
              </div>
              <button
                className="df893n9fn dh83b09c3 c5bf19ed3 c66fbaaf0 c732daa90"
                onClick={handleVerification}
              >
                {isLoading ? <div className="spinner"></div> : "Verify"}
              </button>
              {showError && (
                <div className="hd73666 error d9893h7">{errorMessage}</div>
              )}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Signup;
