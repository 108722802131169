import React, { useEffect } from "react";
import Flickity from "flickity";
import noUiSlider from "nouislider";
import styles from "./Main.module.css";
import { gsap } from "gsap";
import { Flip } from "gsap/Flip";
import m from "./imgs/m.webp";
import TimeLineIcons from "./TimeLineIcons";
import $ from "jquery";
gsap.registerPlugin(Flip);

let windowViewPort;

if (window.innerWidth > 1024) {
  windowViewPort = "desktop";
} else if (window.innerWidth <= 1024 && window.innerWidth > 767) {
  windowViewPort = "tablet";
} else {
  windowViewPort = "mobile";
}

export function TimelineCarousel() {
  const timelineEvents = [
    {
      year: "B.C.",
      description:
        "Babylonians observed patterns in the prices of goods and made trading decisions",
    },
    {
      year: "1700's",
      description:
        "Candlestick charts were invented by Japanese ride trader - Munehisa Homma",
    },
    {
      year: "1792",
      description:
        "Buttonwood Agreement was signed and the NYSE was officially formed",
    },
    {
      year: "1932",
      description: "Dow Theory by Charles H. Dow was expanded upon",
    },
    {
      year: "1971",
      description: "NASDAQ opens - the worlds first electronic stock exchange",
    },
    {
      year: "1991",
      description:
        "Rise of the internet and more access to trading tools and technical analysis",
    },
    {
      year: "2000's",
      description:
        "Explosion of online brokers offering retail Forex trading services",
    },
    { year: "2008", description: "BTC white paper was published" },
    { year: "2011", description: "TradingView was founded" },
    { year: "2023", description: "MADALGO was born" },
  ];
  let eventPositionList = [0, 66, 114, 136, 184, 220, 238, 264, 274, 300, 318];
  useEffect(() => {
    let slidersAutoPlayState = "playing";

    let chosenEvent;
    let chosenRangeSlide;
    var flkty = new Flickity(".tl-event-container", {
      cellAlign: "center",
      freeScroll: false,
      contain: true,
      // percentPosition: true,
      wrapAround: true,
      // autoPlay: 3000,
      pauseAutoPlayOnHover: false,
      pageDots: false,
      prevNextButtons: false,
      draggable: false,
    });

    var flktyIconSlide;

    if (windowViewPort === "mobile") {
      flktyIconSlide = new Flickity(".mobile-icon-container", {
        cellAlign: "center",
        contain: true,
        freeScroll: true,
        pageDots: false,
        // percentPosition: true,
        wrapAround: true,
        // autoplay: 1000,
        prevNextButtons: false,
        pauseAutoPlayOnHover: false,
        draggable: false,
      });
    }

    var rangeSlider = document.getElementById("range-slider");

    if (rangeSlider && !rangeSlider.noUiSlider) {
      noUiSlider.create(rangeSlider, {
        range: {
          min: 0,
          max: 320,
        },
        step: 1,
        start: 0,
        connect: "lower",
        behaviour: "tap-drag",

        format: {
          to: function (value) {
            return value;
          },
          from: function (value) {
            return value.replace("", "");
          },
        },
      });
    }
    setTimeout(() => {
      $(".noUi-touch-area").append($(".range-slider-arrows"));
    }, 300);

    const origins = document.querySelectorAll(".noUi-origin");
    if (origins && origins.length > 1) {
      origins[1].remove();
    }

    const lowerTouchArea = document.querySelector(
      ".noUi-handle-lower .noUi-touch-area",
    );
    if (lowerTouchArea) {
      lowerTouchArea.innerHTML = `
      <div className="slider">
        <div className="slider-arrow-svg w-embed">
          <svg width="24" height="24" viewBox="0 0 24 24">
              <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"/>
          </svg>
        </div>
        <div className="slider-arrow-svg w-embed">
          <svg width="24" height="24" viewBox="0 0 24 24">
              <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"/>
          </svg>
        </div>
      </div>`;
    }

    let previousSlide = 0;

    let visibleIconsList = [7, 8, 0, 1, 2, 3];

    flkty.on("change", function (index) {
      let slideIndex = index;
      let previousIndex;

      if (slideIndex === 0) {
        previousIndex = 9;
      } else {
        previousIndex = slideIndex - 1;
      }

      let rangeSliderYear = $("#event-year");
      rangeSliderYear.css("color", "rgba(255, 255, 255, 0.5)");
      rangeSliderYear.eq(slideIndex).css("color", "white");

      previousSlide = index;
      if (slidersAutoPlayState === "playing") {
        rangeSlider.noUiSlider.set(eventPositionList[index]);
      }

      switch (slideIndex) {
        case 0:
          visibleIconsList = [7, 8, 0, 1, 2, 3];
          break;
        case 1:
          visibleIconsList = [8, 0, 1, 2, 3, 4];
          break;
        case 2:
          visibleIconsList = [0, 1, 2, 3, 4, 5];
          break;
        case 3:
          visibleIconsList = [1, 2, 3, 4, 5, 6];
          break;
        case 4:
          visibleIconsList = [2, 3, 4, 5, 6, 7];
          break;
        case 5:
          visibleIconsList = [3, 4, 5, 6, 7, 8];
          break;
        case 6:
          visibleIconsList = [4, 5, 6, 7, 8, 9];
          break;
        case 7:
          visibleIconsList = [5, 6, 7, 8, 9, 10];
          break;
        case 8:
          visibleIconsList = [6, 7, 8, 9, 10, 11];
          break;
        case 9:
          visibleIconsList = [7, 8, 9, 10, 11, 12];
          break;
        case 10:
          visibleIconsList = [7, 8, 9, 10, 11, 12];
          break;

        default:
      }

      if (windowViewPort !== "mobile") {
        $(".icon").each(function () {
          let iconIndex = parseInt($(this).attr("order"), 10);

          if (!visibleIconsList.includes(iconIndex)) {
            gsap.to(`.icon._${iconIndex}`, {
              opacity: 0,
              scale: 0,
              duration: previousIndex === 9 ? 0 : 1,
            });
            setTimeout(() => {
              if (
                $(`.icon._${iconIndex}`).length &&
                $(".invisible-icon-container").length
              ) {
                Flip.fit(`.icon._${iconIndex}`, `.invisible-icon-container`, {
                  scale: true,
                  duration: previousIndex === 9 ? 0 : 1,
                });
              }
            }, 350);
          } else {
            gsap.to(`.icon._${iconIndex}`, {
              opacity: 0.3,
              duration: 0,
            });
            Flip.fit(
              `.icon._${iconIndex}`,
              `.icon-box._${visibleIconsList.indexOf(iconIndex)}`,
              {
                scale: true,
                stagger: 0.3,
                duration: previousIndex === 9 ? 0.00000000001 : 0.55,
                ease: "sine.inOut",
                opacity: iconIndex === slideIndex && slideIndex < 9 ? 1 : 0.3,
              },
            );
          }
        });
        if (slideIndex === 9 || slideIndex === 10) {
          gsap.to(".madalgo-icon", {
            opacity: 1,
            scale: 1,
            duration: 0.5,
            ease: "sine.inOut",
          });
        } else {
          gsap.to(".madalgo-icon", {
            opacity: 0.3,
            scale: 0.75,
            duration: 0.5,
            ease: "sine.inOut",
          });
        }
      } else {
        // mobile view
        $(".icon-slide-mobile").each(function () {
          let currentSlideIcon = $(this).find(".icon-mobile");
          let iconIndex = parseInt($(this).attr("order"), 10);

          // If transitioning to madalgo-icon
          if (slideIndex === 9 || slideIndex === 10) {
            if (iconIndex === 0) {
              // Animate index 0 as active
              gsap
                .timeline()
                .to($(this), {
                  opacity: 1,
                  duration: 0.5,
                  ease: "power1.inOut",
                })
                .to(
                  currentSlideIcon,
                  {
                    duration: 0.5,
                    y: "3rem",
                    scale: "1.2",
                    opacity: 1,
                    ease: "power1.inOut",
                  },
                  "<",
                );
            } else if (iconIndex === 9) {
              // Reset icon at index 9 to its original state
              gsap.to(currentSlideIcon, {
                duration: 0.5,
                y: "-0.5rem",
                scale: 1,
                opacity: 0.3,
                ease: "power1.inOut",
              });
            } else {
              gsap.to(currentSlideIcon, {
                duration: 0.5,
                y: "-0.5rem",
                opacity: 0.3,
                scale: 1,
                ease: "power1.inOut",
              });
            }
            gsap.to(".madalgo-icon", {
              opacity: 0.75,
              scale: 1,
              ease: "sine.inOut",
            });
          } else {
            if (iconIndex === slideIndex) {
              gsap
                .timeline()
                .to($(this), {
                  opacity: 1,
                  duration: 0.5,
                  ease: "power1.inOut",
                })
                .to(
                  currentSlideIcon,
                  {
                    duration: 0.5,
                    y: "3rem",
                    scale: "1.2",
                    opacity: 1,
                    ease: "power1.inOut",
                  },
                  "<",
                );
            } else if (
              iconIndex === slideIndex - 1 ||
              iconIndex === slideIndex + 1 ||
              slideIndex === 8 ||
              slideIndex === 9 ||
              slideIndex === 0
            ) {
              gsap
                .timeline()
                .to($(this), {
                  opacity: 0.3,
                  duration: 0.5,
                  ease: "power1.inOut",
                })
                .to(
                  currentSlideIcon,
                  {
                    duration: 0.5,
                    y: "-0.5rem",
                    scale: "1",
                    opacity: 0.3,
                    ease: "power1.inOut",
                  },
                  "<",
                );
            } else {
              gsap.to(currentSlideIcon, {
                duration: 0.5,
                y: "-3rem",
                ease: "power1.inOut",
              });
            }
            gsap.to(".madalgo-icon", {
              opacity: 0.3,
              scale: 0.75,
              duration: 0.5,
              ease: "sine.inOut",
            });
          }
        });
      }
      if (windowViewPort === "mobile") {
        flktyIconSlide.select(index);
      }
    });
    flkty.select(0);
    flkty.resize();
    window.dispatchEvent(new Event("resize"));

    $(".timeline-event").on("click", function () {
      let slideIndex = $(this).index();
      flkty.select(slideIndex);
    });

    function updateRangeSlider(
      values,
      handle,
      unencoded,
      tap,
      positions,
      noUiSlider,
    ) {
      let currentRangeValue = values[0];

      switch (true) {
        case currentRangeValue >= 0 && currentRangeValue < 62:
          chosenRangeSlide = timelineEvents[0];
          break;
        case currentRangeValue >= 62 && currentRangeValue < 110:
          chosenRangeSlide = timelineEvents[1];
          break;
        case currentRangeValue >= 110 && currentRangeValue < 132:
          chosenRangeSlide = timelineEvents[2];
          break;
        case currentRangeValue >= 132 && currentRangeValue < 180:
          chosenRangeSlide = timelineEvents[3];
          break;
        case currentRangeValue >= 180 && currentRangeValue < 216:
          chosenRangeSlide = timelineEvents[4];
          break;
        case currentRangeValue >= 216 && currentRangeValue < 234:
          chosenRangeSlide = timelineEvents[5];
          break;
        case currentRangeValue >= 234 && currentRangeValue < 260:
          chosenRangeSlide = timelineEvents[6];
          break;
        case currentRangeValue >= 260 && currentRangeValue < 270:
          chosenRangeSlide = timelineEvents[7];
          break;
        case currentRangeValue >= 270 && currentRangeValue < 296:
          chosenRangeSlide = timelineEvents[8];
          break;
        case currentRangeValue >= 296 && currentRangeValue <= 314:
          chosenRangeSlide = timelineEvents[9];
          break;
        default:
      }

      if (chosenRangeSlide !== undefined && chosenRangeSlide !== chosenEvent) {
        chosenEvent = chosenRangeSlide;
      }
      let chosenSlideIndex = timelineEvents.indexOf(chosenEvent);
      flkty.select(chosenSlideIndex);
    }

    $(".noUi-handle").on("touchstart mousedown", function () {
      if (slidersAutoPlayState === "playing") {
        flkty.stopPlayer();
        slidersAutoPlayState = "stopped";
      }
    });
    rangeSlider.noUiSlider.on("update", updateRangeSlider);

    const viewport = document.querySelector(".flickity-viewport");
    if (viewport) {
      viewport.style.touchAction = "pan-y";
    }

    const slider = document.querySelector(".flickity-slider");
    if (slider) {
      slider.style.left = "0px";
    }

    if (windowViewPort !== "mobile") {
      gsap
        .timeline({
          onComplete: () => {
            flkty.playPlayer();
            gsap.to(".icon._0", {
              opacity: 1,
              duration: 0.55,
            });
          },
        })
        .set(".icon-box", {
          opacity: 1,
        })
        .to(".icon-box .icon", {
          opacity: 1,
          duration: 1.5,
          ease: "power2.inOut",
          // ease: "sine.inOut",
          stagger: 0.15,
        })
        .to(
          ".madalgo-icon",
          {
            opacity: 1,
            duration: 1,
            ease: "power2.inOut",
          },
          ">-0.3",
        )
        .fromTo(
          ".block-content .heading",
          {
            y: "0.5rem",
            opacity: 0,
          },
          {
            y: 0,
            opacity: 1,
            duration: 1,
            delay: 0.3,
          },
        )
        .to(
          ".icon-box .icon,.madalgo-icon",
          {
            opacity: 0.3,
          },
          "<",
        )
        .to(
          ".timeline-indicator",
          {
            opacity: 1,
          },
          ">0.1",
        );
    }

    if (windowViewPort === "mobile") {
      gsap
        .timeline({
          onComplete: () => {
            flkty.playPlayer();
            // gsap.to(".icon._0", {
            //   opacity: 1,
            //   duration: 0.55
            // });
          },
        })
        .to(
          ".madalgo-icon",
          {
            opacity: 1,
            duration: 1,
            ease: "power2.inOut",
          },
          ">-0.3",
        )
        .fromTo(
          ".block-content .heading",
          {
            y: "0.5rem",
            opacity: 0,
          },
          {
            y: 0,
            opacity: 1,
            duration: 1,
            delay: 0.3,
          },
        )
        .to(
          ".mobile-icon-container",
          {
            opacity: 1,
            duration: 0.5,
          },
          "<",
        )
        .to(
          ".icon-box .icon,.madalgo-icon",
          {
            opacity: 0.3,
          },
          "<",
        )
        .to(
          ".timeline-indicator",
          {
            opacity: 1,
          },
          ">0.1",
        );
    }
    return () => {
      // Cleanup listeners and animations when component unmounts
      $(".timeline-event").off("click");
      $(".noUi-handle").off("touchstart mousedown");
      if (rangeSlider && rangeSlider.noUiSlider) {
        rangeSlider.noUiSlider.off("update", updateRangeSlider);
        rangeSlider.noUiSlider.destroy();
      }
      if (flkty) {
        flkty.off("change");
        flkty.destroy();
      }
      if (flktyIconSlide) {
        flktyIconSlide.destroy();
      }
      gsap.killTweensOf(".icon, .icon-box, .tl-event, .noUi-origin");
    };
  }, []);

  return (
    <>
      <div
        id="block-content"
        className="block-content relative z-10 mx-auto max-w-[75%] text-center md:max-w-[90%] lg:max-w-[56.6rem]"
      >
        <h1
          id="heading"
          className="heading m-[.67em_0] my-0 text-2xl font-bold leading-[95%] text-gray-200 md:text-[6.25vw] 2xl:text-[100px]"
        >
          Creating History with Innovation
        </h1>
      </div>
      <div
        id="ti"
        className="timeline-indicator relative z-[8] mt-auto w-full px-7 opacity-0 md:px-[3.75rem] lg:px-[6.75rem]"
      >
        <p
          id="tp"
          className="mb-6 text-center text-xs font-medium uppercase text-white"
        >
          Timeline of Pivotal Moments
        </p>
        <div
          className="tl-event-container relative flex w-full flex-row items-start justify-start"
          tabIndex="0"
          data-flickity
        >
          {timelineEvents.map((event, index) => (
            <div
              className="timeline-event mx-[3.875rem] cursor-pointer text-center [color:rgba(255,_255,_255,_0.3)] lg:mx-[6.25rem]"
              style={{
                position: "absolute",
                left: "0px",
              }}
              key={index}
            >
              <h4
                id="event-year"
                className="mb-3 mt-0 whitespace-nowrap text-7xl font-bold leading-[110%] [transform-origin:50%] [transition:color_.55s_cubic-bezier(.25,_.46,_.45,_.94),_transform_.7s_cubic-bezier(.25,_.46,_.45,_.94)] md:text-8xl "
              >
                {event.year}
              </h4>
              <p
                id="event-description"
                className=" mx-auto mb-0 mt-0 w-48 text-sm font-normal leading-snug tracking-[.1px] text-white [transform-origin:50%] xsm:w-80 md:text-base lg:text-lg"
              >
                {event.description}
              </p>
            </div>
          ))}
        </div>
        <div
          id="range-slider"
          className={`slider relative mb-0 mt-8 h-[5px] min-h-[1.526rem] w-full md:mb-6 md:mt-14 ${styles.slider}`}
        ></div>
      </div>
      <div
        id="block-background"
        className="absolute bottom-[0%] left-[0%] right-[0%] top-[0%] z-[2] flex w-full items-start justify-center overflow-hidden text-[1vw]"
      >
        <div
          id="block-background-container"
          className=" relative flex h-96 w-full items-center justify-center md:h-[30rem] lg:h-[75vh] lg:w-[90%]"
        >
          <img
            src={m}
            id="m"
            className="madalgo-icon absolute top-[-5.5vw] h-96 w-96 object-contain opacity-0 [transform:scale(.75)] md:top-[-0.5vw]"
          ></img>
          <IconBox />
          <MobileSlider />
        </div>
      </div>
    </>
  );
}

function getClassForIndex(index, totalItems) {
  switch (index) {
    case 0:
      return "[transform:translate(0,_3rem)]";
    case 1:
      return "[transform:translate(0,_-.5rem)]";
    case 2:
      return "[transform:translate(0,_-3rem)]";
    case 7:
      return "[transform:translate(0,_-3rem)]";
    case totalItems - 1:
      return "[transform:translate(0,_-.5rem)]";
    default:
      return "";
  }
}

export function MobileSlider() {
  const totalItems = TimeLineIcons.length;
  return (
    <div
      className="mobile-icon-container relative mx-[-2.5vw] mt-auto flex h-[7.5rem] w-full opacity-0 md:hidden"
      tabIndex="0"
    >
      {TimeLineIcons.map((imgSrc, index) => (
        <div
          key={index}
          order={index}
          style={{
            position: "absolute",
            left: "0px",
          }}
          className={`icon-slide-mobile mx-[2.5vw] flex h-full w-[30vw] flex-none items-center justify-center 
          ${index === TimeLineIcons.length - 1 ? "last" : ""} 
          ${index === 0 ? "opacity-100" : "opacity-30"}`}
        >
          <img
            src={imgSrc}
            alt={`Image ${index}`}
            className={`icon-mobile inline-block h-[85%] w-[85%] max-w-full select-none border-none object-contain ${getClassForIndex(
              index,
              totalItems,
            )}`}
          />
        </div>
      ))}
    </div>
  );
}

export function IconBox() {
  return (
    <>
      <div
        id=""
        className={`icon-box _0 ${
          windowViewPort === "mobile" ? "hidden" : ""
        } md:absolute md:left-0 md:top-0 md:h-36 md:w-36 lg:h-48 lg:w-48`}
        data-flip-id="auto-1"
      >
        <img
          className="icon _7 inline-block h-full w-full max-w-full border-none object-contain align-middle opacity-0"
          src={TimeLineIcons[7]}
          alt="btc"
          order="7"
          data-flip-id="auto-14"
          loading="lazy"
        ></img>
      </div>
      <div
        id=""
        className={`icon-box _1 ${
          windowViewPort === "mobile" ? "hidden" : ""
        } md:absolute md:left-12 md:top-44 md:h-36 md:w-36 lg:left-24 lg:top-[17rem] lg:h-48 lg:w-48`}
        data-flip-id="auto-3"
      >
        <img
          className="icon _8 inline-block h-full w-full max-w-full border-none object-contain align-middle opacity-0"
          src={TimeLineIcons[8]}
          alt="Trading View"
          order="8"
          data-flip-id="auto-2"
          loading="lazy"
        ></img>
      </div>
      <div
        id=""
        className={`icon-box _2 ${
          windowViewPort === "mobile" ? "hidden" : ""
        } md:absolute md:left-52 md:right-auto md:top-[19rem] md:h-36 md:w-36 lg:left-[18rem] lg:top-[28rem] lg:h-48 lg:w-48 1xl:left-[24rem] 2xl:left-[37rem]`}
        data-flip-id="auto-5"
      >
        <img
          className="icon _0 inline-block h-full w-full max-w-full border-none object-contain align-middle opacity-0"
          src={TimeLineIcons[0]}
          alt="Babylonian Pot"
          order="0"
          data-flip-id="auto-4"
          loading="lazy"
        ></img>
      </div>
      <div
        id=""
        className={`icon-box _3 ${
          windowViewPort === "mobile" ? "hidden" : ""
        } md:absolute md:left-auto md:right-52 md:top-[19rem] md:h-36 md:w-36 lg:right-[18rem] lg:top-[28rem] lg:h-48 lg:w-48 1xl:right-[24rem] 2xl:right-[37rem]`}
        data-flip-id="auto-7"
      >
        <img
          className="icon _1 inline-block h-full w-full max-w-full border-none object-contain align-middle opacity-0"
          src={TimeLineIcons[1]}
          alt="Candles"
          order="1"
          data-flip-id="auto-6"
          loading="lazy"
        ></img>
      </div>
      <div
        id=""
        className={`icon-box _4 ${
          windowViewPort === "mobile" ? "hidden" : ""
        } md:absolute md:left-auto md:right-[3rem] md:top-44 md:z-[2] md:h-36 md:w-36 lg:top-[17rem] lg:h-48 lg:w-48`}
        data-flip-id="auto-9"
      >
        <img
          className="icon _2 inline-block h-full w-full max-w-full border-none object-contain align-middle opacity-0"
          src={TimeLineIcons[2]}
          alt="Buttonwood Tree"
          order="2"
          data-flip-id="auto-8"
          loading="lazy"
        ></img>
      </div>
      <div
        id=""
        className={`icon-box _5 ${
          windowViewPort === "mobile"
            ? "hidden"
            : "absolute bottom-auto left-auto right-[0] top-[0] h-36 w-36 lg:h-48 lg:w-48"
        } `}
        data-flip-id="auto-11"
      >
        <img
          className="icon _3 inline-block h-full w-full max-w-full object-contain align-middle opacity-0"
          src={TimeLineIcons[3]}
          alt="Dow Theory"
          order="3"
          data-flip-id="auto-10"
          loading="lazy"
        ></img>
      </div>
      <div
        id=""
        className={`invisible-icon-container ${
          windowViewPort === "mobile" ? "hidden" : ""
        } absolute bottom-auto left-[45%] right-[0%] top-[-27.4em] h-40 w-40`}
        data-flip-id="auto-13"
      >
        <img
          className="icon _4 inline-block h-full w-full max-w-full select-none border-none object-contain align-middle opacity-0"
          src={TimeLineIcons[4]}
          alt="NASDAQ"
          order="4"
          data-flip-id="auto-12"
          loading="lazy"
        ></img>
        <img
          className="icon _5 inline-block h-full w-full max-w-full select-none border-none object-contain align-middle opacity-0"
          src={TimeLineIcons[5]}
          alt="Online Trading"
          order="5"
          data-flip-id="auto-15"
          loading="lazy"
        ></img>
        <img
          className="icon _6 inline-block h-full w-full max-w-full select-none border-none object-contain align-middle opacity-0"
          src={TimeLineIcons[6]}
          alt="Forex"
          order="6"
          data-flip-id="auto-16"
          loading="lazy"
        ></img>
        <img
          className="icon _9 inline-block h-full w-full max-w-full select-none border-none object-contain align-middle opacity-0"
          src={TimeLineIcons[0]}
          alt="Babylonian Pot"
          order="9"
          data-flip-id="auto-17"
          loading="lazy"
        ></img>
        <img
          className="icon _10 inline-block h-full w-full max-w-full select-none border-none object-contain align-middle opacity-0"
          src={TimeLineIcons[1]}
          alt="Candles"
          order="10"
          data-flip-id="auto-18"
          loading="lazy"
        ></img>
        <img
          className="icon _11 inline-block h-full w-full max-w-full select-none border-none object-contain align-middle opacity-0"
          src={TimeLineIcons[2]}
          alt="Buttonwood Tree"
          order="11"
          data-flip-id="auto-19"
          loading="lazy"
        ></img>
        <img
          className="icon _12 inline-block h-full w-full max-w-full select-none border-none object-contain align-middle opacity-0"
          src={TimeLineIcons[3]}
          alt="Dow Theory"
          order="12"
          data-flip-id="auto-20"
          loading="lazy"
        ></img>
        <img
          className="icon _14 inline-block h-full w-full max-w-full select-none border-none object-contain align-middle opacity-0"
          src={TimeLineIcons[4]}
          alt="NASDAQ"
          order="14"
          data-flip-id="auto-21"
          loading="lazy"
        ></img>
        <img
          className="icon _13 inline-block h-full w-full max-w-full select-none border-none object-contain align-middle opacity-0"
          src={TimeLineIcons[4]}
          alt="NASDAQ"
          order="13"
          data-flip-id="auto-22"
          loading="lazy"
        ></img>
      </div>
    </>
  );
}
