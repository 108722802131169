// Mfa.js
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import {
  ShieldCheckIcon,
  MailIcon,
  ArrowRightIcon,
} from "@heroicons/react/solid";
import CodeInput from "./CodeInput"; // This component will be created below

const Mfa = ({ setIsVerified }) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [codeSent, setCodeSent] = useState(false);
  const navigate = useNavigate();

  // Fetch the current user's email to prefill the input if needed
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => setEmail(user.attributes.email))
      .catch((err) => console.error(err));
  }, []);

  const handleSendCode = async () => {
    try {
      await Auth.verifyCurrentUserAttribute("email");
      setCodeSent(true);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleVerification = async (code) => {
    try {
      await Auth.verifyCurrentUserAttributeSubmit("email", code);
      setIsVerified(true);
      navigate("/change-email");
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="flex max-w-md flex-col items-center justify-center space-y-4 p-4">
      {!codeSent ? (
        <>
          <ShieldCheckIcon className="h-60 w-60 text-green-500" />
          <h1 className="text-xl font-bold">
            First, let's confirm your identity.
          </h1>
          <p className="max-w-xs pb-8 text-center">
            Before you make any changes, we'll just need to make sure it's you.
          </p>
          <button
            className="mt-4 flex items-center rounded bg-blue-500 px-4 py-2 text-white shadow hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300"
            onClick={handleSendCode}
          >
            <MailIcon className="h-5 w-5" />
            <div className="flex flex-col space-x-4">
              <span className="mx-2 mb-0.5">Email a Code</span>
              <span className="font-medium">{email}</span>
            </div>
            <ArrowRightIcon className="h-5 w-5" />
          </button>
        </>
      ) : (
        <>
          <h2 className="pt-10 font-bold text-red-600">SECURITY CHECK</h2>
          <h1 className="text-2xl font-bold">Check your email for a code</h1>
          <p className="max-w-xs pb-2 text-center">
            Please enter the code we sent to {email} to help us protect your
            account.
          </p>
          <CodeInput onCodeComplete={handleVerification} />
          <button
            className="text-blue-500 hover:underline"
            onClick={handleSendCode}
          >
            Resend Code
          </button>
        </>
      )}
      {error && <p className="text-red-500">{error}</p>}
    </div>
  );
};

export default Mfa;
