import { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import styles from "../Docs.module.css";
import { useTOC } from "../TOCContext";
import DocImages from "../DocImages";
import ThemeContext from "../ThemeContext";

const entries = [
  {
    displayText: "Features",
    urlFragment: "features",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "VWSI Calculation",
    urlFragment: "vwsi-calculation",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "Interpretation",
    urlFragment: "interpretation",
    headingType: "h2",
    subEntries: [],
  },
];
export { entries };

export default function DocsMADALGOPulseVolumeWeightedStrengthIndex() {
  const { theme } = useContext(ThemeContext);
  const { addToTOC, removeFromTOC } = useTOC();

  useEffect(() => {
    entries.forEach(addToTOC);
    return () => {
      entries.forEach((entry) => removeFromTOC(entry));
    };
  }, [addToTOC, removeFromTOC]);

  return (
    <>
      <h1 className={styles.h1}>Volume-Weighted Strength Index</h1>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[84]}
          width={1362}
          height={942}
          className="mx-auto"
          alt="Volume-Weighted Strength Index"
        ></img>
      </p>

      <h2 id="features" className={`scroll-mt-2 ${styles["h2"]}`}>
        Features
        <Link
          to="#features"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        The Volume-Weighted Strength Index (VWSI) is a dynamic tool designed to
        provide traders with insights into the strength of price trends,
        incorporating both volume and price data. Let's explore the features and
        functionalities of this indicator:
      </p>
      <ol className={styles.ol}>
        <li>
          Dynamic Rescaling - The VWSI dynamically scales strength values
          between the defined oversold and overbought levels. This rescaling
          enhances the visual interpretation of strength trends.
        </li>
        <li>
          Customizable Colors - Personalize the colors of the VWSI values
          displayed above and below candle bars. Choose colors that best
          complement your trading environment.
        </li>
      </ol>

      <h2 id="vwsi-calculation" className={`scroll-mt-2 ${styles["h2"]}`}>
        VWSI Calculation
        <Link
          to="#vwsi-calculation"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        The Volume-Weighted Strength Index combines price and volume data to
        provide a holistic perspective on market strength. It calculates the
        strength index using an exponential moving average of gains and losses.
        The resulting values are further processed to obtain the VWSI values.
      </p>
      <div
        className={`
        info info-tip selection:bg-[#c4f6ff] selection:text-[#2c2c2c49]
        ${theme === "light" ? styles.infoWarning : styles.infoWarningDark} 
        `}
      >
        <div className="info-heading">
          <h5 className={styles.h5}>
            <span
              className={`
                ${theme === "light" ? styles.infoAlert : styles.infoAlertDark}
              `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 12 16"
              >
                <path
                  fillRule="evenodd"
                  d="M7 2.3c3.14 0 5.7 2.56 5.7 5.7s-2.56 5.7-5.7 5.7A5.71 5.71 0 0 1 1.3 8c0-3.14 2.56-5.7 5.7-5.7zM7 1C3.14 1 0 4.14 0 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm1 3H6v5h2V4zm0 6H6v2h2v-2z"
                ></path>
              </svg>
            </span>
            Info
          </h5>
        </div>
        <div className="warning-content">
          <p>
            The VWSI incorporates both price and volume data, making it a
            comprehensive tool for assessing trend strength. Keep in mind that
            no single indicator guarantees success, and it's essential to use
            the VWSI as part of a broader trading strategy.
          </p>
        </div>
      </div>

      <h2 id="vwsi-calculation" className={`scroll-mt-2 ${styles["h2"]}`}>
        Interpretation
        <Link
          to="#vwsi-calculation"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[85]}
          width={1226}
          height={716}
          className="mx-auto"
          alt="VWSI Interpretation"
        ></img>
      </p>
      <p className={styles.p}>
        The VWSI values are displayed as numbers above or below the candle bars,
        indicating the strength of the current price trend. Positive values
        above the bars indicate bullish strength, while negative values below
        the bars indicate bearish strength.
      </p>
      <div
        className={`warning warning-caution selection:bg-[#ffeec4] selection:text-[#4b4b4b] ${styles["alertWarning"]}`}
      >
        <div className="warning-heading">
          <h5 className={styles.h5}>
            <span className={styles.alertWarningIcon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M8.893 1.5c-.183-.31-.52-.5-.887-.5s-.703.19-.886.5L.138 13.499a.98.98 0 0 0 0 1.001c.193.31.53.501.886.501h13.964c.367 0 .704-.19.877-.5a1.03 1.03 0 0 0 .01-1.002L8.893 1.5zm.133 11.497H6.987v-2.003h2.039v2.003zm0-3.004H6.987V5.987h2.039v4.006z"
                ></path>
              </svg>
            </span>
            caution
          </h5>
        </div>
        <div className="warning-content">
          <p>
            While the VWSI offers valuable insights, it should be used in
            conjunction with other technical and fundamental analysis tools for
            well-rounded trading decisions.
          </p>
        </div>
      </div>
    </>
  );
}
