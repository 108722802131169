import { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import ShowPasswordIcon from "./ShowPasswordIcon";
import HidePasswordIcon from "./HidePasswordIcon";

const ChangeEmail = ({ isVerified }) => {
  const [newEmail, setNewEmail] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");

  useEffect(() => {
    if (!isVerified) {
      navigate("/dashboard/mfa");
    }
  }, [isVerified, navigate]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, {
        email: newEmail,
      });
      setSuccess(
        "Email change initiated. Please check your new email for a verification code.",
      );
    } catch (error) {
      setError(error.message);
    }
  };

  const handleVerifyEmail = async (event) => {
    event.preventDefault();
    try {
      await Auth.verifyCurrentUserAttributeSubmit("email", verificationCode);
      setSuccess("Your email has been successfully verified.");
    } catch (error) {
      setError("Failed to verify email. " + error.message);
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const renderChangeEmailForm = () => (
    <form
      onSubmit={handleSubmit}
      className="w-full max-w-md space-y-6 rounded-lg bg-[#171717] p-8 shadow-md"
    >
      <div>
        <label
          htmlFor="newEmail"
          className="block text-sm font-medium text-zinc-100"
        >
          New Email
        </label>
        <input
          type="email"
          id="newEmail"
          value={newEmail}
          onChange={(e) => setNewEmail(e.target.value)}
          required
          className={`mt-1 w-full rounded-md border-[1px] ${
            newEmail ? "border-purple-500" : "border-zinc-300"
          } bg-transparent p-2 text-purple-500 caret-purple-500 shadow-sm transition-colors duration-200 ease-in-out hover:border-purple-500 focus:border-purple-500 focus:outline-none`}
        />
      </div>
      <div className="relative">
        <label
          htmlFor="currentPassword"
          className="block text-sm font-medium text-zinc-100"
        >
          Current Password
        </label>
        <input
          type={passwordVisible ? "text" : "password"}
          id="currentPassword"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          required
          className={`mt-1 w-full rounded-md border-[1px] ${
            currentPassword ? "border-purple-500" : "border-zinc-300"
          } bg-transparent p-2 text-purple-500 caret-purple-500 shadow-sm transition-colors duration-200 ease-in-out hover:border-purple-500 focus:border-purple-500 focus:outline-none`}
        />
        <button
          type="button"
          className="absolute inset-y-0 right-0 top-6 z-10 flex items-center pr-3 text-sm leading-5"
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
          onClick={togglePasswordVisibility}
        >
          {passwordVisible ? (
            <ShowPasswordIcon fill="#FFF" />
          ) : (
            <HidePasswordIcon fill="#FFF" />
          )}
        </button>
        {showTooltip && (
          <div
            className="absolute -right-10 z-10 mb-1 w-auto -translate-y-full whitespace-nowrap rounded bg-black p-2 text-xs text-white shadow-md"
            style={{ bottom: "40%", transform: "translateX(-50%)" }}
          >
            {passwordVisible ? "Hide password" : "Show password"}
          </div>
        )}
      </div>
      <button
        type="submit"
        className="w-full rounded-md bg-blue-500 px-4 py-2 font-semibold text-white shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-50"
      >
        Change Email
      </button>
    </form>
  );

  const renderVerifyEmailForm = () => (
    <form
      onSubmit={handleVerifyEmail}
      className="w-full max-w-md space-y-6 rounded-lg bg-[#171717] p-8 shadow-md"
    >
      <div>
        <label
          htmlFor="verificationCode"
          className="block text-sm font-medium text-zinc-100"
        >
          Verification Code
        </label>
        <input
          type="text"
          id="verificationCode"
          value={verificationCode}
          onChange={(e) => setVerificationCode(e.target.value)}
          required
          className={`mt-1 w-full rounded-md border-[1px] ${
            verificationCode ? "border-purple-500" : "border-zinc-300"
          } bg-transparent p-2 text-purple-500 caret-purple-500 shadow-sm transition-colors duration-200 ease-in-out hover:border-purple-500 focus:border-purple-500 focus:outline-none`}
        />
      </div>
      <button
        type="submit"
        className="w-full rounded-md bg-green-500 px-4 py-2 font-semibold text-white shadow-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-300 focus:ring-opacity-50"
      >
        Verify Email
      </button>
    </form>
  );

  return (
    <div className="flex flex-col items-center justify-center p-4">
      <div className="flex flex-col space-y-4 pb-4 text-center">
        <h2 className="text-lg font-bold">
          {success ? "Verify your Email below" : "Change your Email below"}
        </h2>
        <p className=" max-w-xs font-normal">
          {success
            ? "Please enter the verification code sent to your new email."
            : "Please enter your new email with password for confirmation."}
        </p>
      </div>
      {success ? renderVerifyEmailForm() : renderChangeEmailForm()}
      {error && <p className="mt-4 text-sm text-red-600">{error}</p>}
      {success && <p className="mt-4 text-sm text-green-600">{success}</p>}
    </div>
  );
};

export default ChangeEmail;
