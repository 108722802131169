import { useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "../Docs.module.css";
import { useTOC } from "../TOCContext";
import DocImages from "../DocImages";

const entries = [
  {
    displayText: "Key Volatility Indicators",
    urlFragment: "key-volatility-indicators",
    headingType: "h2",
    subEntries: [],
  },
];
export { entries };

export default function DocsTechnicalAnalysisTechnicalIndicatorsVolatility() {
  const { addToTOC, removeFromTOC } = useTOC();

  useEffect(() => {
    entries.forEach(addToTOC);
    return () => {
      entries.forEach((entry) => removeFromTOC(entry));
    };
  }, [addToTOC, removeFromTOC]);

  return (
    <>
      <h1 className={styles.h1}>Volatility Indicators</h1>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[61]}
          width={1911}
          height={836}
          className="mx-auto"
          alt="Volatility Indicators"
        ></img>
      </p>

      <p className={styles.p}>
        Volatility indicators gauge the intensity of price fluctuations,
        offering insights into the magnitude of price variations rather than
        their direction. Thus, they play a pivotal role in risk assessment,
        allowing traders to tailor their positions to their risk tolerance.
        Typically, volatility indicators fall under the category of lagging
        indicators.
      </p>
      <p className={styles.p}>
        Though not frequently found pre-applied on trading charts, many renowned
        technical indicators like the Bollinger Bands or Supertrend incorporate
        elements of volatility measurement.
      </p>
      <h2
        id="key-volatility-indicators"
        className={`scroll-mt-2 ${styles["h2"]}`}
      >
        Key Volatility Indicators
        <Link
          to="#key-volatility-indicators"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        Among the most recognized volatility indicators are the rolling standard
        deviation (often referred to as STDEV) and the average true range (ATR),
        a creation of Wilder.
      </p>
      <figure className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[62]}
          width={1661}
          height={796}
          className="mx-auto"
          alt="STDev and ATR"
        ></img>
        <figcaption
          style={{
            textAlign: "center",
            fontSize: 12,
          }}
        >
          STDev (brown) and ATR (green) both using a period of 14
        </figcaption>
      </figure>
      <p className={styles.p}>
        While some traders prefer using the standard deviation of price shifts
        to determine volatility, others lean towards the standard deviation of
        closing prices.
      </p>
    </>
  );
}
