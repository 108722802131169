import React, { useEffect } from "react";
import BlogThemeContext from "./BlogThemeContext";

const BlogThemeProvider = ({ children }) => {
  useEffect(() => {
    document.body.classList.add("blog-bg");

    return () => {
      document.body.classList.remove("blog-bg");
    };
  }, []);

  return (
    <BlogThemeContext.Provider value={null}>
      {children}
    </BlogThemeContext.Provider>
  );
};

export default BlogThemeProvider;
