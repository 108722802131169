import { useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "../Docs.module.css";
import { useTOC } from "../TOCContext";
import DocImages from "../DocImages";

const entries = [
  {
    displayText: "Initial Capital",
    urlFragment: "initial-capital",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "Risk/Returns",
    urlFragment: "risk-returns",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "Investment Horizon",
    urlFragment: "investment-horizon",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "Availability",
    urlFragment: "availability",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "References",
    urlFragment: "references",
    headingType: "h2",
    subEntries: [],
  },
];
export { entries };

export default function DocsTradingBestPracticesTraderProfile() {
  const { addToTOC, removeFromTOC } = useTOC();

  useEffect(() => {
    entries.forEach(addToTOC);
    return () => {
      entries.forEach((entry) => removeFromTOC(entry));
    };
  }, [addToTOC, removeFromTOC]);

  return (
    <>
      <h1 className={styles.h1}>Your Trader Profile</h1>

      <p className={styles.p}>
        Constructing a portfolio stands as the initial endeavor for most
        traders, and this procedure transcends the mere selection of tradable
        assets. The foundation of a sound portfolio hinges on the identification
        of your trader profile, a task facilitated by addressing pivotal
        inquiries:
      </p>
      <ol className={styles.ol}>
        <li>What constitutes your initial capital?</li>
        <li>What level of return are you targeting?</li>
        <li>How averse are you to risk?</li>
        <li>What is your investment horizon?</li>
        <li>What amount of time can you dedicate?</li>
      </ol>
      <p className={styles.p}>
        These queries interrelate, rendering harmonious responses a challenge.
        The subsequent sections expound upon the essence of each query, aiding
        in the identification of your trader profile.
      </p>
      <h2 id="initial-capital" className={`scroll-mt-2 ${styles["h2"]}`}>
        Initial Capital
        <Link
          to="#initial-capital"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        Determining your investable capital is of paramount significance. While
        varied questions could unveil this value, a simplified approach will
        suffice. Low capital wields diverse implications, directly correlating
        to buying power. Diminished buying power curtails access to specific
        assets and impairs portfolio diversification, amplifying risk. Leverage
        augments buying power but escalates risk.
      </p>
      <p className={styles.p}>
        Limited capital might curtail your portfolio's longevity, conflicting
        with your investment horizon goal. Certain markets favor traders with
        modest capital, exemplified by the forex and cryptocurrency markets,
        which offer elevated leverage compared to stocks.
      </p>
      <h2 id="risk-returns" className={`scroll-mt-2 ${styles["h2"]}`}>
        Risk/Returns
        <Link
          to="#risk-returns"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        Risk and returns share an interconnected dynamic: higher returns entail
        higher risk. Gauging your risk aversion is pivotal for constructing an
        effective portfolio, aligning this choice with other facets of your
        trader profile. Financial instruments exhibit varying risk/return
        ratios, warranting thoughtful selection based on your profile. A
        balanced mix of instruments can mitigate risk, such as a portfolio
        comprised of 60% derivatives and 40% bonds.
      </p>
      <figure className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[67]}
          width={593}
          height={343}
          className="mx-auto"
          alt="Risk and Returns"
        ></img>
        <figcaption
          style={{
            textAlign: "center",
            fontSize: 12,
          }}
        >
          Comparison of the risk profile and return rate of various financial
          instruments [1]
        </figcaption>
      </figure>

      <h2 id="investment-horizon" className={`scroll-mt-2 ${styles["h2"]}`}>
        Investment Horizon
        <Link
          to="#investment-horizon"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        Your investment horizon exerts significant influence over your trading
        journey. Long-term traders opt for holding positions for years,
        employing the buy-and-hold strategy. Others engage in "swing-trading,"
        where positions span days to months. "Day-traders" execute positions
        within a single trading day, with scalpers, a subset of day-traders,
        holding positions for mere minutes.
      </p>
      <p className={styles.p}>
        Though many beginners gravitate towards day-trading and scalping, it's
        crucial to acknowledge that shorter investment horizons entail
        challenges. Short-term trading demands precision in timing and yields
        smaller profits, encouraging higher leverage and amplified risk.
        Multiple positions translate to increased frictional costs, which impact
        profit margins more significantly for smaller gains.
      </p>
      <p className={styles.p}>Scalping is discouraged for beginners.</p>

      <h2 id="availability" className={`scroll-mt-2 ${styles["h2"]}`}>
        Availability
        <Link
          to="#availability"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        Trading necessitates dedicated time and effort, even in automated
        scenarios. However, varying levels of available time exist among
        traders. Essential tasks encompass:
      </p>
      <ol className={styles.olBullet}>
        <li>Monitoring existing positions</li>
        <li>Executing Orders</li>
        <li>Conducting Research</li>
      </ol>
      <p className={styles.p}>
        Traders with ample time can craft sophisticated portfolios and undertake
        shorter-term trades. In contrast, those with limited time gravitate
        towards longer-term trading like swing trading.
      </p>
      <p className={styles.p}>
        These sections aim to provide traders with valuable insights into
        distinct trading facets, assuming a foundational grasp of core concepts
        like order types and trade executions.
      </p>

      <h2 id="references" className={`scroll-mt-2 ${styles["h2"]}`}>
        References
        <Link
          to="#references"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        [1] PAŠkEviČiuS, Arvydas, and Ovidijus SAČilkA. "Structured securities
        and their development in Lithuania." Ekonomika 89.4 (2010): 101-114.
      </p>
    </>
  );
}
