import { createContext, useContext, useState, useCallback } from "react";

const TOCContext = createContext();

export function TOCProvider({ children }) {
  const [tocEntries, setTocEntries] = useState([]);

  const addToTOC = useCallback((entry) => {
    setTocEntries((prevEntries) => [...prevEntries, entry]);
  }, []);

  const removeFromTOC = useCallback((entryToRemove) => {
    setTocEntries((prevEntries) =>
      prevEntries.filter(
        (entry) => entry.displayText !== entryToRemove.displayText,
      ),
    );
  }, []);

  return (
    <TOCContext.Provider value={{ tocEntries, addToTOC, removeFromTOC }}>
      {children}
    </TOCContext.Provider>
  );
}

export const useTOC = () => {
  const context = useContext(TOCContext);
  if (!context) {
    throw new Error("useTOC must be used within a TOCProvider");
  }
  return context;
};
