import React, { useEffect, useRef } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

const ThreeScene = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    // Scene
    const scene = new THREE.Scene();
    scene.background = new THREE.Color("#0a090d");

    // Custom shaders for the points
    const vertexShader = `
      attribute float opacityShift;
      varying float vOpacityShift;
      uniform float pointSize;

      void main() {
        vOpacityShift = opacityShift;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        gl_PointSize = pointSize * 300.0 / length(gl_Position.xyz);
      }
    `;

    const fragmentShader = `
      varying float vOpacityShift;
      uniform float time;

      void main() {
          // Adjust for a 2-second cycle. With vOpacityShift, it will give a value that cycles from 0 to 2.
          float cycleTime = mod(time + vOpacityShift * 2.0, 2.0);
          float opacity;

          if (cycleTime < 1.0) {
              // transition from 0.2 to 1.0 in the first 1 second
              opacity = mix(0.01, 0.8, cycleTime);
          } else {
              // transition from 1.0 to 0.2 in the next 1 second
              opacity = mix(0.8, 0.01, cycleTime - 1.0);
          }

          // Use circle shape for particles
          vec2 coords = 2.0 * gl_PointCoord - 1.0;
          float len = length(coords);
          if (len > 1.0) {
              discard;
          }

          gl_FragColor = vec4(vec3(1.0, 1.0, 1.0) * opacity, opacity); 
      }
    `;

    // ShaderMaterial
    const particlesMaterial = new THREE.ShaderMaterial({
      vertexShader: vertexShader,
      fragmentShader: fragmentShader,
      transparent: true,
      depthWrite: false,
      uniforms: {
        time: { value: 0 },
        pointSize: { value: window.innerWidth < 767 ? 1.2 : 0.06 },
      },
    });

    // Geometry
    const particlesGeometry = new THREE.BufferGeometry();
    const count = 500;
    const positions = new Float32Array(count * 3);
    const opacityShifts = new Float32Array(count);
    const baseDistance = 4;

    for (let i = 0; i < count; i++) {
      const alpha = Math.random() * 2 * Math.PI;
      const beta = Math.random() * Math.PI;

      // Introduce randomness in distance from center
      const variation = 1;
      const randomDistance = baseDistance + (Math.random() * 3 - 1) * variation;

      positions[i * 3] = randomDistance * Math.sin(beta) * Math.cos(alpha);
      positions[i * 3 + 1] = randomDistance * Math.sin(beta) * Math.sin(alpha);
      positions[i * 3 + 2] = randomDistance * Math.cos(beta);

      opacityShifts[i] = Math.random();
    }

    particlesGeometry.setAttribute(
      "position",
      new THREE.BufferAttribute(positions, 3),
    );
    particlesGeometry.setAttribute(
      "opacityShift",
      new THREE.BufferAttribute(opacityShifts, 1),
    );

    // Points
    const particles = new THREE.Points(particlesGeometry, particlesMaterial);
    scene.add(particles);

    // Camera
    const sizes = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
    const camera = new THREE.PerspectiveCamera(
      75,
      sizes.width / sizes.height,
      1,
      1000,
    );
    scene.add(camera);
    camera.position.z = 8;

    // Controls
    const controls = new OrbitControls(camera, canvasRef.current);
    controls.enableDamping = true;
    controls.dampingFactor = 0.05;
    controls.screenSpacePanning = false;
    controls.minDistance = 3;
    controls.maxDistance = 50;
    controls.enableRotate = true;
    controls.minPolarAngle = Math.PI / 2;
    controls.maxPolarAngle = Math.PI / 2;
    controls.minAzimuthAngle = -Infinity; // No limit to left rotation
    controls.maxAzimuthAngle = Infinity; // No limit to right rotation
    controls.enableZoom = false;
    controls.mouseButtons.RIGHT = null;

    // Renderer
    const renderer = new THREE.WebGLRenderer({
      canvas: canvasRef.current,
      antialias: true,
    });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(sizes.width, sizes.height);

    // Animation
    const clock = new THREE.Clock();
    const animate = () => {
      const elapsedTime = clock.getElapsedTime();
      particlesMaterial.uniforms.time.value = elapsedTime;
      particles.rotation.y = elapsedTime * 0.06;
      renderer.render(scene, camera);
      controls.update();
      requestAnimationFrame(animate);
    };
    animate();

    // Handle window resize
    const handleResize = () => {
      sizes.width = window.innerWidth;
      sizes.height = window.innerHeight;
      camera.aspect = sizes.width / sizes.height;
      camera.updateProjectionMatrix();
      renderer.setSize(sizes.width, sizes.height);
      particlesMaterial.uniforms.pointSize.value =
        sizes.width < 767 ? 0.1 : 0.06;
    };
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <canvas
      ref={canvasRef}
      className="webgl"
      style={{ pointerEvents: "auto" }}
    ></canvas>
  );
};

export default ThreeScene;
