import { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import styles from "../Docs.module.css";
import { useTOC } from "../TOCContext";
import DocImages from "../DocImages";
import ThemeContext from "../ThemeContext";

const entries = [
  {
    displayText: "Overview",
    urlFragment: "overview",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "Settings",
    urlFragment: "settings",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "Signals",
    urlFragment: "signals",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "How to Use",
    urlFragment: "how-to-use",
    headingType: "h2",
    subEntries: [],
  },
];
export { entries };

export default function DocsMADALGOPulseHyperTrend() {
  const { theme } = useContext(ThemeContext);
  const { addToTOC, removeFromTOC } = useTOC();

  useEffect(() => {
    entries.forEach(addToTOC);
    return () => {
      entries.forEach((entry) => removeFromTOC(entry));
    };
  }, [addToTOC, removeFromTOC]);

  return (
    <>
      <h1 className={styles.h1}>Hyper Trend</h1>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[96]}
          width={505}
          height={722}
          className="mx-auto"
          alt="Hyper Trend"
        ></img>
      </p>
      <p className={styles.p}>
        The Hyper Trend indicator is a powerful tool within MADALGO Pulse that
        helps traders identify potential trends and reversals in the market. It
        provides visual cues and signals to assist you in making informed
        trading decisions. This guide will walk you through how to effectively
        use the Hyper Trend indicator in your trading strategy.
      </p>

      <h2 id="overview" className={`scroll-mt-2 ${styles["h2"]}`}>
        Overview
        <Link
          to="#overview"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        The Hyper Trend indicator is designed to capture trend movements in the
        market. It generates trend lines that dynamically adjust based on market
        conditions, helping traders identify potential entry and exit points.
        The indicator uses a combination of price action, moving averages, and
        volatility to determine trend direction.
      </p>

      <h2 id="settings" className={`scroll-mt-2 ${styles["h2"]}`}>
        Settings
        <Link
          to="#settings"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        Before using the Hyper Trend indicator, it's essential to understand its
        settings and parameters. Here are the key settings you can customize:
      </p>
      <ol className={styles.olBullet}>
        <li>Hyper Trend: Enable or disable the Hyper Trend indicator.</li>
        <li>
          Amplitude (A): Adjust the sensitivity of the indicator. Higher values
          make the indicator more responsive to price movements.
        </li>
        <li>
          Channel Deviation (CD): Set the deviation factor for the trend
          channel.
        </li>
        <li>
          Show Arrows (SA): Enable or disable the display of arrows for buy and
          sell signals.
        </li>
        <li>
          Hyper Trend Color: Set the color of the trend line when in a bullish
          trend.
        </li>
        <li>
          Hyper Trend Color 1: Set the color of the trend line when in a bearish
          trend.
        </li>
      </ol>

      <h2 id="signals" className={`scroll-mt-2 ${styles["h2"]}`}>
        Signals
        <Link
          to="#signals"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        The Hyper Trend indicator generates both trend lines and buy/sell
        signals based on trend changes and price movements. Here's how to
        interpret the signals:
      </p>
      <ol className={styles.olBullet}>
        <li>Trend Line</li>
      </ol>
      <figure className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[97]}
          width={1441}
          height={1014}
          className="mx-auto"
          alt="Hyper Trend - Trend Line"
        ></img>
        <figcaption
          style={{
            textAlign: "center",
            fontSize: 12,
          }}
        >
          Trend Line
        </figcaption>
      </figure>
      <p className={styles.p}>
        The Hyper Trend indicator draws trend lines that dynamically adjust
        based on market conditions. The color of the line changes to indicate
        bullish or bearish trends.
      </p>

      <ol className={styles.olBullet}>
        <li>Buy Signal</li>
      </ol>
      <figure className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[98]}
          width={640}
          height={749}
          className="mx-auto"
          alt="Hyper Trend - Buy Signal"
        ></img>
        <figcaption
          style={{
            textAlign: "center",
            fontSize: 12,
          }}
        >
          Buy Signal
        </figcaption>
      </figure>
      <p className={styles.p}>
        A buy signal occurs when the trend line crosses above the price action,
        suggesting a potential bullish trend. The indicator displays this signal
        in the form of an upward arrow when it occurs.
      </p>
      <ol className={styles.olBullet}>
        <li>Sell Signal</li>
      </ol>
      <figure className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[99]}
          width={476}
          height={653}
          className="mx-auto"
          alt="Hyper Trend - Sell Signal"
        ></img>
        <figcaption
          style={{
            textAlign: "center",
            fontSize: 12,
          }}
        >
          Sell Signal
        </figcaption>
      </figure>
      <p className={styles.p}>
        A sell signal occurs when the trend line crosses below the price action,
        suggesting a potential bearish trend. The indicator displays this signal
        in the form of an downward arrow when it occurs.
      </p>

      <div
        className={`
        info info-tip selection:bg-[#c4f6ff] selection:text-[#2c2c2c49]
        ${theme === "light" ? styles.infoTipInfo : styles.infoTipInfoDark} 
        `}
      >
        <div className="info-heading">
          <h5 className={styles.h5}>
            <span
              className={`
                ${theme === "light" ? styles.infoTip : styles.infoTipDark}
              `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 12 16"
              >
                <path
                  fillRule="evenodd"
                  d="M6.5 0C3.48 0 1 2.19 1 5c0 .92.55 2.25 1 3 1.34 2.25 1.78 2.78 2 4v1h5v-1c.22-1.22.66-1.75 2-4 .45-.75 1-2.08 1-3 0-2.81-2.48-5-5.5-5zm3.64 7.48c-.25.44-.47.8-.67 1.11-.86 1.41-1.25 2.06-1.45 3.23-.02.05-.02.11-.02.17H5c0-.06 0-.13-.02-.17-.2-1.17-.59-1.83-1.45-3.23-.2-.31-.42-.67-.67-1.11C2.44 6.78 2 5.65 2 5c0-2.2 2.02-4 4.5-4 1.22 0 2.36.42 3.22 1.19C10.55 2.94 11 3.94 11 5c0 .66-.44 1.78-.86 2.48zM4 14h5c-.23 1.14-1.3 2-2.5 2s-2.27-.86-2.5-2z"
                ></path>
              </svg>
            </span>
            Tip
          </h5>
        </div>
        <div className="warning-content">
          <p>
            The Hyper Trend indicator is particularly useful for scalping on
            lower time frames, where quick trend changes are more common.
          </p>
        </div>
      </div>

      <h2 id="how-to-use" className={`scroll-mt-2 ${styles["h2"]}`}>
        How to Use
        <Link
          to="#how-to-use"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[100]}
          width={557}
          height={378}
          className="mx-auto"
          alt="Hyper Trend - How to Use"
        ></img>
      </p>
      <ol className={styles.ol}>
        <li>
          Enable Hyper Trend: Make sure the Hyper Trend indicator is enabled by
          checking the "Hyper Trend" checkbox in the indicator settings.
        </li>
        <li>
          Adjust Parameters: Customize the indicator settings according to your
          preferences. You can adjust the amplitude, channel deviation, and
          arrow display.
        </li>
        <li>
          Interpret Trend Lines: Observe the color and position of the trend
          line. A green trend line indicates a bullish trend, while a red trend
          line indicates a bearish trend.
        </li>
        <li>
          Identify Buy and Sell Signals: Pay attention to the arrows that appear
          on the chart. A green arrow signifies a potential buy signal, while a
          red arrow indicates a potential sell signal.
        </li>
        <li>
          Confirm with Other Indicators: Consider using the Hyper Trend
          indicator in conjunction with some of our other technical indicators
          to validate your trading decisions.
        </li>
        <li>
          Set Stop Loss and Take Profit: Once you identify a potential entry
          point, set your stop-loss and take-profit levels to manage risk and
          maximize profit potential.
        </li>
      </ol>

      <p className={styles.p}>
        By understanding the settings, signals, and usage of the Hyper Trend
        indicator, you can incorporate it into your trading strategy to enhance
        your trading accuracy and effectiveness.
      </p>

      <div
        className={`warning warning-caution selection:bg-[#ffeec4] selection:text-[#4b4b4b] ${styles["alertWarning"]}`}
      >
        <div className="warning-heading">
          <h5 className={styles.h5}>
            <span className={styles.alertWarningIcon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M8.893 1.5c-.183-.31-.52-.5-.887-.5s-.703.19-.886.5L.138 13.499a.98.98 0 0 0 0 1.001c.193.31.53.501.886.501h13.964c.367 0 .704-.19.877-.5a1.03 1.03 0 0 0 .01-1.002L8.893 1.5zm.133 11.497H6.987v-2.003h2.039v2.003zm0-3.004H6.987V5.987h2.039v4.006z"
                ></path>
              </svg>
            </span>
            caution
          </h5>
        </div>
        <div className="warning-content">
          <p>
            While the Hyper Trend indicator can provide valuable insights, no
            indicator is infallible. Always use additional analysis and risk
            management strategies to enhance your trading decisions.
          </p>
        </div>
      </div>
    </>
  );
}
