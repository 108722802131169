import React from "react";
import BlurPages from "./BlurPages";
import styles from "./Main.module.css";
import { motion } from "framer-motion";

function Disclaimer() {
  return (
    <>
      <section
        id="heading-disclaimer-section"
        className="relative z-0 block w-full"
      >
        <div
          id="inner-disclaimer-container"
          className="w-full px-7 pb-9 pt-[10rem] md:px-14 md:pb-[6.25rem] lg:pt-[15.125rem] xl:px-28"
        >
          <div id="header-container" className="relative z-[1] text-center">
            <motion.h1
              id="disclaimer-heading"
              className=" text-5xl md:text-[5rem] lg:text-[7.5rem]"
              initial={{ opacity: 0 }}
              transition={{ duration: 2 }}
              animate={{ opacity: 1 }}
            >
              Disclaimer
            </motion.h1>
          </div>
          <div
            id="blur"
            className=" absolute bottom-[0%] left-[0%] right-[0%] top-[0%] z-0 block"
          >
            <div
              id="gradient-blur-group-1"
              className="lg:w[85vw] absolute bottom-0 left-auto right-[-6.6rem] top-auto h-[100vw] w-[95vw] opacity-60 filter-none md:-bottom-80 md:w-screen lg:right-auto lg:h-[85vw] xl:-bottom-[30rem] xl:left-[5.7rem] xl:max-h-[100rem] xl:max-w-[100rem]"
            >
              <BlurPages />
            </div>
          </div>
        </div>
      </section>

      <motion.section
        id="disclaimer-text-section"
        className="relative z-0 block w-full "
        initial={{ opacity: 0 }}
        transition={{ duration: 2, delay: 0.1 }}
        animate={{ opacity: 1 }}
      >
        <div
          id="disclaimer-container-outer"
          className=" w-full px-7 pb-7 pt-16 md:px-14 lg:pb-24 lg:pt-14 xl:px-28"
        >
          <div
            id="disclaimer-body"
            className=" mx-auto max-w-[50rem] rounded-3xl bg-[rgb(20,18,23,0.8)] p-5 [box-shadow:1px_1px_2.125rem_1.25rem_rgba(0,_0,_0,_.05)] md:p-7 lg:rounded-[2.5rem] lg:p-12"
            style={{
              opacity: 1,
              transform: "translate(0px)",
            }}
          >
            <div
              id="disclaimer-body-content"
              className={` h-full w-full ` + styles["w-rich-text"]}
            >
              <h3
                id="header"
                className=" my-0 pb-1 text-lg font-bold leading-[116.667%] md:text-2xl lg:text-4xl"
              >
                This website is purely for educational purposes and should not
                be seen as a source of financial advice.
              </h3>
              <div className={`` + styles["w-embed"]}>
                <hr className="box-content h-0 pb-1" />
              </div>
              <h4
                id="risk-advisory"
                className=" md:text-1xl my-0 text-base font-bold leading-[116.667%] lg:text-2xl"
              >
                Risk Advisory
              </h4>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                Trading carries significant risk, and many day traders end up
                losing money. All information presented here is hypothetical and
                has been chosen retrospectively to showcase our product. It
                shouldn't be viewed as financial guidance. Any investment
                decisions involving securities, commodities, and other assets
                carry risk and should ideally be made with the guidance of
                financial experts. Past performance is not indicative of future
                returns.
                <br />
                <br />
                The losses in trading can be substantial, so you need to
                evaluate whether trading aligns with your financial status.
              </p>
              <h4
                id="risk-advisory"
                className=" md:text-1xl my-0 text-base font-bold leading-[116.667%] lg:text-2xl"
              >
                Hypothetical and Simulated Performance Advisory
              </h4>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                Hypothetical or simulated results come with certain limitations.
                They don't represent actual trading and could potentially
                compensate for specific market factors, like liquidity, which
                have not been accounted for, given that the trades haven't been
                made. These programs, generally, are created with the benefit of
                hindsight. There's no guarantee that any account will achieve
                similar profits or losses.
              </p>
              <h4
                id="risk-advisory"
                className=" md:text-1xl my-0 text-base font-bold leading-[116.667%] lg:text-2xl"
              >
                Testimonials Advisory
              </h4>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                The testimonials on this website may not reflect the experiences
                of other users, and they're not a guarantee for future success.
                The trading results shown in testimonials are not verified, and
                it's unlikely they're representative due to the variability of
                factors such as skill, risk management, and experience. Trading
                is highly risky, and losses are common.
                <br />
                As a technical analysis tools provider, we don't have access to
                our customers' trading accounts or brokerage statements.
                Consequently, we have no reason to believe our customers'
                performance is better or worse than other traders based on our
                content or tools.
              </p>
              <h4
                id="risk-advisory"
                className=" md:text-1xl my-0 text-base font-bold leading-[116.667%] lg:text-2xl"
              >
                MADALGO's Role
              </h4>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                MADALGO is not an investment adviser registered with the U.S.
                Securities and Exchange Commission. Instead, it operates under
                the "publisher's exclusion" clause from the Investment Advisers
                Act of 1940's definition of an "investment adviser," as per
                Section 202(a)(11) (D) and similar state legislation. Hence,
                MADALGO does not give tailored investment advice.
              </p>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                Any content, material, information, or other offerings
                displayed, disseminated, or otherwise provided on this website
                and related sites might be perceived as "investment advice."
                However, such information is impersonal and not designed for any
                particular individual's investment requirements. The information
                on this website and any related sites is primarily for
                educational and informational purposes.
              </p>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                This information should not be interpreted as trading or
                investment advice, nor should it be seen as a recommendation to
                buy, sell, or hold any positions in any indices or financial
                markets. The content and information provided by MADALGO,
                including the Site and MADALGO's products and services, are
                incidental to MADALGO's business activities, which focus on
                providing educational tools for technical analysis.
              </p>
              <h4
                id="risk-advisory"
                className=" md:text-1xl my-0 text-base font-bold leading-[116.667%] lg:text-2xl"
              >
                Purpose of the Information and Tools
              </h4>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                MADALGO and its provided tools DO NOT offer personalized
                investment advice. The tools, scanners, data, content, and
                information given are impersonal and not tailored to any
                individual's investment needs, serving solely for educational
                and informational purposes.
              </p>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                MADALGO's tools and scanners are designed to assist you in
                conducting your own research and evaluation of securities, based
                on your unique criteria and inputs, which you alone control and
                input. The use of these tools and scanners DOES NOT guarantee
                any predictable, general, specific, or other outcomes and SHOULD
                NOT be the sole determinant for any investment decision or
                transaction. IF YOU CHOOSE TO RELY ON OUR TOOLS, SCANNERS, OR
                ANY SUCH DATA, CONTENT, OR INFORMATION, you do so at your own
                risk, fully comprehending and agreeing that you alone are
                responsible for your investment research and decisions.
                Furthermore, you hold sole responsibility for all decisions and
                transactions related to your security purchases and sales, the
                suitability, profitability, or appropriateness of any security,
                investment, financial product, investment strategy, or any other
                matter, along with all other aspects of your investments and
                investment strategies.
              </p>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                MADALGO will not provide you with any legal, tax, estate
                planning, or accounting advice, or any advice concerning the
                suitability, profitability, or appropriateness of any security,
                investment, financial product, investment strategy, or any other
                matter. The data, content, and information within the tools and
                scanners should not be taken as trading or investment advice,
                and it is not intended as a solicitation or recommendation to
                buy, sell, or hold any positions in any securities or to
                undertake any other transaction.
              </p>
              <h4
                id="risk-advisory"
                className=" md:text-1xl my-0 text-base font-bold leading-[116.667%] lg:text-2xl"
              >
                Web App Disclaimer
              </h4>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                By accessing or using information from this Site, you agree that
                it is intended for educational purposes, and MADALGO.com will
                not be held responsible for any losses or damages arising from
                the content. The Site cannot guarantee the accuracy of the
                information it provides. We advise you to seek advice from an
                independent financial advisor before buying or selling
                securities. MADALGO and all associated parties are not
                registered financial advisors.
                <br />
                The Site, and all affiliated individuals and entities, do not
                accept responsibility for your trading outcomes or investments.
                Our partners and affiliates have no connection to the
                proprietary information provided by this Site.
              </p>
              <h4
                id="risk-advisory"
                className=" md:text-1xl my-0 text-base font-bold leading-[116.667%] lg:text-2xl"
              >
                TradingView Disclaimer
              </h4>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                The charts used on this site come from TradingView, which our
                tools are built on. TradingView® is a registered trademark of
                TradingView, Inc.{" "}
                <a href="https://www.tradingview.com">www.TradingView.com</a>.
                There is no connection, ownership, endorsement, or
                recommendation between TradingView and the providers or
                developers of the products or services described herein.
              </p>
            </div>
          </div>
        </div>
      </motion.section>
    </>
  );
}

export default Disclaimer;
