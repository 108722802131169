import { Link } from "react-router-dom";

export default function DocsFooter() {
  return (
    <footer className=" bg-[#1d1b1f] p-[2rem_0] [color:#ebedfo]">
      <div
        id="footer-container"
        className=" max-w-1140px m-[0_auto] w-full p-[0_1rem]"
      >
        <div id="footer-row" className="mb-4 flex flex-row flex-wrap">
          <div
            id="footer-col"
            className="mb-[calc(1rem_*_3)] ml-0 w-full max-w-full flex-[1_0] [flex-basis:100%] lg:[flex-basis:0%]"
          >
            <div
              id="docs-footer-title"
              className="mb-4 font-bold leading-tight text-white"
            >
              Docs
            </div>
            <ul
              id="link-item-list"
              className=" m-[0_0_1rem] mb-0 flex  flex-col pl-0"
            >
              <li className="mt-0">
                <Link
                  to="faq"
                  className="block leading-loose transition-colors duration-200 [text-decoration:none] [transition-timing-function:cubic-bezier(.08,.52,.52,1)] hover:text-pink-500 hover:underline"
                >
                  FAQ
                </Link>
                <Link
                  to="get-started/what-is-madalgo"
                  className="block leading-loose transition-colors duration-200 [text-decoration:none] [transition-timing-function:cubic-bezier(.08,.52,.52,1)] hover:text-pink-500 hover:underline"
                >
                  Intro
                </Link>
                <Link
                  to="madalgo-premium-solutions/madalgo-pulse"
                  className="block leading-loose transition-colors duration-200 [text-decoration:none] [transition-timing-function:cubic-bezier(.08,.52,.52,1)] hover:text-pink-500 hover:underline"
                >
                  Guides
                </Link>
                <Link
                  to="technical-analysis"
                  className="block leading-loose transition-colors duration-200 [text-decoration:none] [transition-timing-function:cubic-bezier(.08,.52,.52,1)] hover:text-pink-500 hover:underline"
                >
                  Technical Analysis
                </Link>
              </li>
            </ul>
          </div>
          <div
            id="footer-col"
            className="mb-[calc(1rem_*_3)] ml-0 w-full max-w-full flex-[1_0] [flex-basis:100%] lg:[flex-basis:0%]"
          >
            <div
              id="docs-footer-title"
              className="mb-4 font-bold leading-tight text-white"
            >
              Community
            </div>
            <ul id="link-item-list" className=" m-[0_0_1rem] mb-0  pl-0">
              <li className="mt-0">
                <Link
                  to="https://discord.gg/urfhuyhTXd"
                  className="block leading-loose transition-colors duration-200 [text-decoration:none] [transition-timing-function:cubic-bezier(.08,.52,.52,1)] hover:text-pink-500 hover:underline"
                >
                  Discord
                </Link>
                <Link
                  to="https://x.com/_madalgo"
                  className="block leading-loose transition-colors duration-200 [text-decoration:none] [transition-timing-function:cubic-bezier(.08,.52,.52,1)] hover:text-pink-500 hover:underline"
                >
                  X
                </Link>
                <Link
                  to="https://youtube.com/_madalgo"
                  className="block leading-loose transition-colors duration-200 [text-decoration:none] [transition-timing-function:cubic-bezier(.08,.52,.52,1)] hover:text-pink-500 hover:underline"
                >
                  YouTube
                </Link>
              </li>
            </ul>
          </div>
          <div
            id="footer-col"
            className="mb-[calc(1rem_*_3)] ml-0 w-full max-w-full flex-[1_0] [flex-basis:100%] lg:[flex-basis:0%]"
          >
            <div
              id="docs-footer-title"
              className="mb-4 font-bold leading-tight text-white"
            >
              More
            </div>
            <ul id="link-item-list" className=" m-[0_0_1rem] mb-0  pl-0">
              <li className="mt-0">
                <Link
                  to="/community"
                  className="block leading-loose transition-colors duration-200 [text-decoration:none] [transition-timing-function:cubic-bezier(.08,.52,.52,1)] hover:text-pink-500 hover:underline"
                >
                  Programs
                </Link>
                <Link
                  to="/blog"
                  className="block leading-loose transition-colors duration-200 [text-decoration:none] [transition-timing-function:cubic-bezier(.08,.52,.52,1)] hover:text-pink-500 hover:underline"
                >
                  Blog
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div id="footer-bottom" className="text-center">
          <div id="footer-copyright">
            Copyright @ 2023 MADALGO. Licensed under{" "}
            <Link
              className="text-pink-500 hover:underline"
              to="http://creativecommons.org/licenses/by-nc-nd/4.0/"
            >
              CC BY-NC-ND 4.0
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}
