import { useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "../Docs.module.css";
import { useTOC } from "../TOCContext";
import DocImages from "../DocImages";

const entries = [
  {
    displayText: "Tradestation",
    urlFragment: "tradestation",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "Oanda",
    urlFragment: "oanda",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "Forex.com",
    urlFragment: "forex-com",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "Tradovate",
    urlFragment: "tradovate",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "Alpaca",
    urlFragment: "alpaca",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "Binance",
    urlFragment: "binance",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "Coinbase",
    urlFragment: "coinbase",
    headingType: "h2",
    subEntries: [],
  },
];
export { entries };

export default function DocsRecommendedBrokers() {
  const { addToTOC, removeFromTOC } = useTOC();

  useEffect(() => {
    entries.forEach(addToTOC);
    return () => {
      entries.forEach((entry) => removeFromTOC(entry));
    };
  }, [addToTOC, removeFromTOC]);

  return (
    <>
      <h1 className={styles.h1}>Recommended Brokers</h1>

      <p className={styles.p}>
        There are numerous brokers, certain specialising on stock trading,
        others on forex or crypto, while there exist brokers proposing a wide
        variety of markets to their user. Below is a list of brokers we
        recommend to our users.
      </p>

      <h2 id="tradestation" className={`scroll-mt-2 ${styles["h2"]}`}>
        <Link
          className="text-cyan-500 hover:underline"
          to="https://www.tradestation.com/"
        >
          Tradestation
        </Link>
        <Link
          to="#tradestation"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[73]}
          width={128}
          height={171}
          className="mx-auto"
          alt="Tradestation Logo"
        ></img>
      </p>
      <p className={styles.p}>
        TradeStation is a comprehensive online brokerage platform offering
        equities, options, and futures trading with advanced analysis tools.
      </p>
      <p className={styles.p}>
        <strong>Main Tradeable Assets</strong>
      </p>
      <ol className={styles.olBulletBrokers}>
        <li>Equities</li>
        <li>Options</li>
        <li>Futures</li>
      </ol>

      <h2 id="oanda" className={`scroll-mt-2 ${styles["h2"]}`}>
        <Link
          className="text-cyan-500 hover:underline"
          to="https://www.oanda.com/"
        >
          Oanda
        </Link>
        <Link
          to="#oanda"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[74]}
          width={126}
          height={125}
          className="mx-auto"
          alt="Oanda Logo"
        ></img>
      </p>
      <p className={styles.p}>
        Oanda is a global forex and CFD broker known for its user-friendly
        interface and extensive forex currency pairs.
      </p>
      <p className={styles.p}>
        <strong>Main Tradeable Assets</strong>
      </p>
      <ol className={styles.olBulletBrokers}>
        <li>Forex</li>
        <li>CFDs on Indices</li>
        <li>Commodities</li>
      </ol>

      <h2 id="forex-com" className={`scroll-mt-2 ${styles["h2"]}`}>
        <Link
          className="text-cyan-500 hover:underline"
          to="https://www.forex.com/"
        >
          Forex.com
        </Link>
        <Link
          to="#forex-com"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[75]}
          width={149}
          height={137}
          className="mx-auto"
          alt="Forex.com Logo"
        ></img>
      </p>
      <p className={styles.p}>
        Forex.com is a popular forex and CFD broker providing a wide range of
        currency pairs and trading options.
      </p>
      <p className={styles.p}>
        <strong>Main Tradeable Assets</strong>
      </p>
      <ol className={styles.olBulletBrokers}>
        <li>Forex</li>
        <li>CFDs on Indices</li>
        <li>Commodities</li>
      </ol>

      <h2 id="tradovate" className={`scroll-mt-2 ${styles["h2"]}`}>
        <Link
          className="text-cyan-500 hover:underline"
          to="https://www.tradovate.com/"
        >
          Tradovate
        </Link>
        <Link
          to="#tradovate"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[76]}
          width={139}
          height={129}
          className="mx-auto"
          alt="Tradovate Logo"
        ></img>
      </p>
      <p className={styles.p}>
        Tradovate is a futures-focused brokerage platform that offers
        commission-free trading and advanced charting tools.
      </p>
      <p className={styles.p}>
        <strong>Main Tradeable Assets</strong>
      </p>
      <ol className={styles.olBulletBrokers}>
        <li>Futures</li>
      </ol>

      <h2 id="alpaca" className={`scroll-mt-2 ${styles["h2"]}`}>
        <Link
          className="text-cyan-500 hover:underline"
          to="https://alpaca.markets/"
        >
          Alpaca
        </Link>
        <Link
          to="#alpaca"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[77]}
          width={172}
          height={171}
          className="mx-auto"
          alt="Alpaca Logo"
        ></img>
      </p>
      <p className={styles.p}>
        Alpaca is a commission-free stock trading platform with a strong focus
        on algorithmic trading and APIs.
      </p>
      <p className={styles.p}>
        <strong>Main Tradeable Assets</strong>
      </p>
      <ol className={styles.olBulletBrokers}>
        <li>Equities</li>
      </ol>

      <h2 id="binance" className={`scroll-mt-2 ${styles["h2"]}`}>
        <Link
          className="text-cyan-500 hover:underline"
          to="https://www.binance.com/"
        >
          Binance
        </Link>
        <Link
          to="#binance"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[78]}
          width={142}
          height={142}
          className="mx-auto"
          alt="Binance Logo"
        ></img>
      </p>
      <p className={styles.p}>
        Binance is a prominent cryptocurrency exchange offering a wide range of
        cryptocurrencies and trading pairs.
      </p>
      <p className={styles.p}>
        <strong>Main Tradeable Assets</strong>
      </p>
      <ol className={styles.olBulletBrokers}>
        <li>Cryptocurrencies</li>
      </ol>

      <h2 id="coinbase" className={`scroll-mt-2 ${styles["h2"]}`}>
        <Link
          className="text-cyan-500 hover:underline"
          to="https://www.coinbase.com/"
        >
          Coinbase
        </Link>
        <Link
          to="#coinbase"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[79]}
          width={142}
          height={142}
          className="mx-auto"
          alt="Coinbase Logo"
        ></img>
      </p>
      <p className={styles.p}>
        Coinbase is a popular cryptocurrency exchange known for its
        user-friendly interface and wide variety of supported cryptocurrencies.
      </p>
      <p className={styles.p}>
        <strong>Main Tradeable Assets</strong>
      </p>
      <ol className={styles.olBulletBrokers}>
        <li>Cryptocurrencies</li>
      </ol>
    </>
  );
}
