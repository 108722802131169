import React, { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import mapc from "./imgs/mph.webp";
import { useInView } from "react-intersection-observer";
import styles from "./Main.module.css";

const Section = ({ showImage, images, hero, cta, ctaLink, children }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const controls = useAnimation();
  const controls1 = useAnimation();
  const controls2 = useAnimation();
  const controls3 = useAnimation();
  const controls4 = useAnimation();
  const controls5 = useAnimation();
  const controls6 = useAnimation();
  const controls7 = useAnimation();

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const [ref1, inView1] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const [ref2, inView2] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const [ref3, inView3] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const [ref4, inView4] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const [ref5, inView5] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const [ref6, inView6] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const [ref7, inView7] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const ArrowIcon = () => (
    <svg
      className="group-hover:scale-110 group-hover:transform group-hover:duration-300 group-hover:ease-in-out"
      width="100%"
      height="100%"
      viewBox="0 0 20 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 5H19M19 5L14.6667 1M19 5L14.6667 9"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </svg>
  );

  const fadeInFromBottom = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  const fadeInFromRight = {
    hidden: { opacity: 0, x: window.innerWidth < 768 ? 0 : 50 },
    visible: { opacity: 1, x: 0 },
  };

  const [animationVariant, setAnimationVariant] = useState(
    window.innerWidth < 768 ? fadeInFromBottom : fadeInFromRight,
  );

  useEffect(() => {
    const handleResize = () => {
      setAnimationVariant(
        window.innerWidth < 768 ? fadeInFromBottom : fadeInFromRight,
      );
      setWindowWidth(window.innerWidth);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (inView) controls.start("visible");
    if (inView1) controls1.start("visible");
    if (inView2) controls2.start("visible");
    if (inView3) controls3.start("visible");
    if (inView4) controls4.start("visible");
    if (inView5) controls5.start("visible");
    if (inView6) controls6.start("visible");
    if (inView7) controls7.start("visible");
  }, [
    controls,
    controls1,
    controls2,
    controls3,
    controls4,
    controls5,
    controls6,
    controls7,
    inView,
    inView1,
    inView2,
    inView3,
    inView4,
    inView5,
    inView6,
    inView7,
  ]);

  return (
    <section
      ref={ref}
      id="main-section-container"
      className={`relative flex min-h-screen flex-col items-center justify-center ${styles.section}`}
    >
      {hero && (
        <>
          <div
            id="hero-main-container"
            className=" relative z-[1] mx-auto flex max-w-full flex-[0_0_auto] items-center p-[0_6em] px-3.5 [-webkit-box-flex:0] [min-height:calc(100vh_-_9.375em)] md:w-[738px] lg:mx-0 lg:w-[994px] xl:w-[1250px] 1xl:w-[1426px]"
          >
            <div id="hero-container" className={styles.heroContainer}>
              <motion.div
                className="opacity-100 [transform:translate3d(0,0,0)] [transition:all_.5s_.75s_ease-in-out]"
                initial={{ opacity: 0 }}
                transition={{ duration: 1.67 }}
                animate={{ opacity: 1 }}
              >
                MADALGO
              </motion.div>
              <motion.h1
                className="m-[.5em_auto] text-xl font-bold leading-[1.1] [transform:translate3d(0,0,0)] [transition:all_.5s_.75s_ease-in-out] [word-break:break-word] xl:text-3xl"
                initial={{ opacity: 0 }}
                transition={{ duration: 1.87, delay: 0.08 }}
                animate={{ opacity: 1 }}
              >
                <br />A new generation of AI-driven trading indicators
              </motion.h1>
              <motion.div
                id="7-day-risk-free"
                className=" mt-6 flex items-center space-x-4 text-sm md:mt-8 lg:mt-12"
                initial={{ opacity: 0 }}
                transition={{ duration: 2, delay: 0.13 }}
                animate={{ opacity: 1 }}
              >
                {cta && (
                  <a
                    id="14-days-risk-free"
                    href={ctaLink}
                    className={`group relative mb-2 flex items-center text-white [transition:all_.1s_ease-in-out] after:absolute after:right-0 after:top-[110%] after:h-0.5 after:w-[80%] after:bg-[#fff] after:opacity-0 after:[content:''] after:[transform:translateX(-50%)] after:[transition:all_.25s_ease-in-out] hover:after:opacity-100 hover:after:[transform:translateX(0)] ${
                      windowWidth <= 767 ? "after:hidden" : ""
                    }`}
                  >
                    <span className="mb-1">{cta}</span>
                    <div className="ml-2 flex h-[19.2px] w-4 flex-none transform items-center justify-center text-white transition-transform duration-200">
                      <ArrowIcon />
                    </div>
                  </a>
                )}
              </motion.div>
            </div>
          </div>
        </>
      )}
      {showImage && (
        <div
          id="mobile-container"
          className={`flex w-full flex-col items-center justify-between gap-4 ${
            showImage ? "md:space-x-0" : ""
          }`}
        >
          <div
            id="mobile-gradient"
            className="relative inline-block before:absolute before:bottom-0 before:left-28 before:right-28 before:top-0 before:z-[-1] before:animate-gradient-x before:rounded-2xl before:bg-gradient-radial-at-t before:blur-[40px] after:absolute after:bottom-0 after:left-28 after:right-28 after:top-0 after:z-[-1] after:animate-gradient-x after:rounded-2xl after:bg-gradient-radial-at-t after:blur-[40px] before:sm:left-48 before:sm:right-48 after:sm:left-48 after:sm:right-48"
          >
            <img
              className="ml-[-1rem] h-auto w-full max-w-[min(100vw,800px)] sm:ml-[-1.5rem]"
              src={mapc}
              alt="Map"
            />
          </div>
          <motion.div
            animate={controls}
            initial="hidden"
            variants={fadeInFromBottom}
            transition={{ duration: 2, ease: [0.43, 0.13, 0.23, 0.96] }}
            className="flex flex-col gap-4 py-24 text-center"
          >
            <h2 className=" font-normal leading-8 lg:text-xl xl:text-2xl">
              By offering a
              <br />
            </h2>
            <p className="bg-gradient-to-r from-blue-500 to-purple-500 bg-clip-text text-center text-xl font-bold uppercase text-transparent drop-shadow-md text-balance lg:text-2xl xl:text-4xl 2xl:text-[48px]">
              state-of-the-art application
              <br />
            </p>
            <p className="bg-clip-text py-2 text-center text-sm font-semibold text-transparent text-white drop-shadow-md text-balance lg:text-lg xl:text-2xl">
              that will help you find your edge in trading.
            </p>
          </motion.div>
          <div id="cards" className="relative z-10 block w-full">
            <div
              id="cards-container"
              className="h-auto w-full pb-40 tiny:px-2 txsm:px-7 md:px-7 lg:h-auto lg:px-[0] lg:pb-[5.75rem]"
            >
              <div
                id="cards-inner-container"
                className="relative top-[0] z-[1] h-auto w-full overflow-visible pb-0 pt-0 lg:pb-12 lg:pt-28"
              >
                <div
                  id="card-1"
                  className="relative bottom-[0%] left-[0%] right-[0%] top-[0%] z-[1] mb-[3.75rem] flex h-auto w-full translate-y-2 transform items-center justify-center overflow-hidden p-[0_0] opacity-100 lg:h-full lg:overflow-visible lg:px-14 xl:px-[6.75rem]"
                >
                  <div
                    id="card-1-inner"
                    className="relative z-[2] flex h-auto max-h-[50rem] min-h-[auto] w-full flex-col items-center justify-between overflow-hidden rounded-[1.375rem] px-6 pt-[2.375rem] [transition:height_1s] md:flex-row"
                  >
                    <div
                      id="card-1-content"
                      className="relative z-10 m-[0_0_1rem] flex w-full max-w-[none] flex-col items-start justify-between py-0 md:my-14 md:ml-[3.125rem] md:max-w-[20.615rem] md:py-[0] lg:ml-20 lg:max-w-lg lg:py-[8.75vw]"
                    >
                      <p
                        id="card-1-heading-small"
                        className="mb-[0.3125rem] mt-0 text-xs font-medium leading-[153.846%] tracking-[.1px] md:mb-[0.6875rem] md:text-[0.8125rem] lg:mb-5"
                      >
                        EXPLOIT AI-DRIVEN TOOLS
                      </p>
                      <h3
                        id="card-1-heading-large"
                        className="mb-[0.625rem] mt-0 text-2xl font-bold leading-[100%] md:mb-5 md:text-[2.75rem] lg:mb-10 lg:text-[3.375rem]"
                      >
                        Uncover institutional volume
                        <span className=" bg-clip-text [-webkit-text-fill-color:transparent] [background-image:linear-gradient(to_right,_#ba7bff,_#747aff)]">
                          {" "}
                          levels using AI analysis.
                        </span>
                      </h3>
                      <p
                        id="card-1-p"
                        className="mb-5 mt-0 max-w-none text-base font-normal leading-[130%_!important] tracking-[.1px] md:text-lg lg:text-xl"
                      >
                        Benefit from our advanced AI-generated signals and
                        overlays, institutional volume predictions, and more
                        than <strong>20+ additional premium features.</strong>{" "}
                      </p>
                      <a
                        href="#premium-packages"
                        id="card-1-button"
                        className="group relative z-[1] flex max-w-full cursor-pointer items-center whitespace-nowrap rounded-[4.5rem] border-2 border-white bg-black bg-opacity-0 p-[.375rem_1.25rem] text-base leading-[144.44%] text-white [text-decoration:none] [transition:background-color_.2s_cubic-bezier(.25,_.46,_.45,_.94)] lg:text-lg"
                      >
                        <p className="mb-0 mt-0 text-sm font-normal leading-[1.35] tracking-[.1px] lg:text-base">
                          Unlock Premium AI Access
                        </p>
                        <div className="ml-[.625rem] flex h-[.9375rem] w-5 flex-none origin-[0%] items-center justify-center text-white [transition:transform_.2s]">
                          <svg
                            className="group-hover:scale-110 group-hover:transform group-hover:duration-300 group-hover:ease-in-out"
                            width="100%"
                            height="100%"
                            viewBox="0 0 20 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 5H19M19 5L14.6667 1M19 5L14.6667 9"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                        </div>
                      </a>
                    </div>
                    <motion.img
                      ref={ref1}
                      className="relative bottom-auto left-auto right-[4.2vw] top-[0%] z-[2] -mb-20 inline-block h-[50.6996vw] w-[80vw] max-w-full transform-none select-none rounded-none object-contain opacity-100 md:absolute md:right-[4%] md:h-[85%] md:w-[45%] lg:right-[0%] lg:top-[5%] lg:h-[75%] lg:w-[42vw] lg:max-w-lg"
                      src={images[2]}
                      alt=""
                      animate={controls1}
                      initial="hidden"
                      variants={fadeInFromBottom}
                      transition={{
                        duration: 2,
                        ease: [0.43, 0.13, 0.23, 0.96],
                      }}
                    />
                    <motion.img
                      ref={ref2}
                      className="relative -right-[1.1rem] bottom-[0.710rem] left-auto top-auto z-[3] inline-block h-[50.6996vw] w-[80vw] max-w-full transform-none select-none rounded-none object-contain opacity-100 [object-position:_100%_70%] md:-bottom-[0.42rem] md:-right-[3.8rem] md:h-[85%] md:w-[42%] lg:top-24 lg:h-[75%] lg:w-[42vw] lg:max-w-lg"
                      src={images[3]}
                      alt=""
                      animate={controls2}
                      initial="hidden"
                      variants={fadeInFromBottom}
                      transition={{
                        delay: 0.5,
                        duration: 2,
                        ease: [0.43, 0.13, 0.23, 0.96],
                      }}
                    />
                    <motion.img
                      ref={ref3}
                      className="absolute bottom-auto left-[0%] right-[0%] top-[0%] z-[1] inline-block h-[200%] w-[45rem] max-w-[70rem] transform-none select-none object-cover opacity-100 [object-position:_50%_50%] md:w-screen lg:right-[-21%] lg:h-[180%] lg:w-full xl:left-[25%] xl:right-[-9%] xl:h-[125%] xl:max-w-full"
                      src={images[5]}
                      alt=""
                      animate={controls3}
                      initial="hidden"
                      variants={fadeInFromBottom}
                      transition={{
                        delay: 1.0,
                        duration: 2,
                        ease: [0.43, 0.13, 0.23, 0.96],
                      }}
                    />
                    <div className="absolute bottom-[0%] left-[0%] right-[0%] top-[0%] z-0 bg-slate-800 opacity-10"></div>
                  </div>
                  <img
                    className="[display:none] lg:pointer-events-none lg:absolute lg:-right-20 lg:-top-40 lg:bottom-auto lg:left-auto lg:z-0 lg:inline-block lg:h-[18vw] lg:max-h-[20rem] lg:w-[18vw] lg:max-w-[20rem] lg:object-contain"
                    src={images[9]}
                    sizes="(max-width: 479px) 0px, (max-width: 991px) 100vw, 18vw"
                    alt="BTC Coin"
                  />
                </div>
                <div
                  id="card-2"
                  className="relative bottom-[0%] left-[0%] right-[0%] top-[0%] z-[1] mb-[3.75rem] flex w-full translate-y-2 transform items-center justify-center overflow-hidden p-[0_0] opacity-100 lg:h-full lg:overflow-visible lg:px-14 xl:px-[6.75rem]"
                >
                  <div
                    id="card-2-inner"
                    className="relative z-[2] flex h-auto max-h-[50rem] min-h-[auto] w-full flex-col items-center justify-between overflow-hidden rounded-[1.375rem] px-6 pt-[2.375rem] [transition:height_1s] md:flex-row"
                  >
                    <div
                      id="card-2-content"
                      className="relative z-10 m-[0_0_1rem] flex w-full max-w-[none] flex-col items-start justify-between py-0 md:ml-[3.125rem] md:max-w-lg md:py-14 lg:my-14 lg:ml-20 lg:py-[8.75vw]"
                    >
                      <p
                        id="card-2-heading-small"
                        className="mb-[0.3125rem] mt-0 text-xs font-medium leading-[153.846%] tracking-[.1px] md:mb-[0.6875rem] md:text-[0.8125rem] lg:mb-5"
                      >
                        AI-POWERED SIMPLICITY
                      </p>
                      <h3
                        id="card-2-heading-large"
                        className="mb-[0.625rem] mt-0 text-2xl font-bold leading-[100%] md:mb-5 md:text-[2.75rem] lg:mb-10 lg:text-[3.375rem]"
                      >
                        Transform the complexities of trading into clear
                        insights with our
                        <span className=" bg-clip-text [-webkit-text-fill-color:transparent] [background-image:linear-gradient(to_right,_#ba7bff,_#747aff)]">
                          {" "}
                          AI-powered interface.
                        </span>
                      </h3>
                      <p
                        id="card-2-p"
                        className="mb-5 mt-0 max-w-none text-base font-normal leading-[130%_!important] tracking-[.1px] md:max-w-[20.3rem] md:text-lg lg:max-w-sm lg:text-xl xl:max-w-none"
                      >
                        Create a free TradingView profile and join us to gain
                        access to AI- enhanced charts applicable to any market.
                        Experience{" "}
                        <strong>seamless operation on any device </strong> with
                        real-time data, all made accessible through AI
                        technology.
                      </p>
                      <a
                        href="#premium-packages"
                        id="card-2-button"
                        className="group relative z-[1] flex max-w-full cursor-pointer items-center whitespace-nowrap rounded-[4.5rem] border-2 border-white bg-black bg-opacity-0 p-[.375rem_1.25rem] text-base leading-[144.44%] text-white [text-decoration:none] [transition:background-color_.2s_cubic-bezier(.25,_.46,_.45,_.94)] lg:text-lg"
                      >
                        <p className="mb-0 mt-0 text-sm font-normal leading-[1.35] tracking-[.1px] lg:text-base">
                          Grab Advanced AI Charts
                        </p>
                        <div className="ml-[.625rem] flex h-[.9375rem] w-5 flex-none origin-[0%] items-center justify-center text-white [transition:transform_.2s]">
                          <svg
                            className="group-hover:scale-110 group-hover:transform group-hover:duration-300 group-hover:ease-in-out"
                            width="100%"
                            height="100%"
                            viewBox="0 0 20 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 5H19M19 5L14.6667 1M19 5L14.6667 9"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                        </div>
                      </a>
                    </div>
                    <motion.img
                      ref={ref4}
                      className=" relative -bottom-[2rem] left-auto right-auto top-auto z-[3] inline-block h-80 w-[90%] max-w-full select-none object-contain opacity-100 [object-position:100%_100%] md:absolute md:right-3 md:h-3/4 md:w-1/2 lg:-right-40 lg:h-[90%] lg:w-[63%]"
                      sizes="(max-width: 479px) 100vw, (max-width: 767px) 78vw, (max-width: 991px) 44vw, (max-width: 1279px) 57vw, (max-width: 1439px) 54vw, (max-width: 1919px) 56vw, 62vw"
                      src={images[1]}
                      alt="Mac Trading Monitor"
                      animate={controls4}
                      initial="hidden"
                      variants={animationVariant}
                      transition={{
                        duration: 2,
                        ease: [0.43, 0.13, 0.23, 0.96],
                      }}
                    />
                    <motion.img
                      ref={ref5}
                      className="absolute -bottom-[42%] left-[0%] right-auto top-auto z-[1] inline-block h-[45.4rem] w-[56.25rem] max-w-full transform-none select-none object-cover opacity-100 md:-right-80 md:-top-20 md:left-auto lg:top-0 lg:h-[60rem] lg:w-[80rem]"
                      sizes="(max-width: 479px) 100vw, (max-width: 767px) 93vw, (max-width: 991px) 88vw, (max-width: 1279px) 27vw, (max-width: 1439px) 25vw, (max-width: 1919px) 26vw, 384px"
                      src={images[6]}
                      alt="Blur 1"
                      animate={controls5}
                      initial="hidden"
                      variants={animationVariant}
                      transition={{
                        delay: 0.5,
                        duration: 2,
                        ease: [0.43, 0.13, 0.23, 0.96],
                      }}
                    />
                    <div className="absolute bottom-[0%] left-[0%] right-[0%] top-[0%] z-0 bg-slate-800 opacity-10"></div>
                  </div>
                  <img
                    className="[display:none] lg:pointer-events-none lg:absolute lg:-left-10 lg:-right-10 lg:-top-28 lg:bottom-auto lg:z-0 lg:inline-block lg:h-[18vw] lg:max-h-96 lg:w-[18vw] lg:max-w-sm lg:object-contain"
                    src={images[10]}
                    sizes="(max-width: 479px) 0px, (max-width: 991px) 100vw, 18vw"
                    alt="Stock Bull"
                  />
                </div>
                <div
                  id="card-3"
                  className="relative bottom-[0%] left-[0%] right-[0%] top-[0%] z-[1] flex h-auto w-full translate-y-2 transform items-center justify-center overflow-hidden p-[0_0] opacity-100 lg:h-full lg:overflow-visible lg:px-14 xl:px-[6.75rem]"
                >
                  <div
                    id="card-3-inner"
                    className="relative z-[2] flex h-auto max-h-[50rem] min-h-[auto] w-full flex-col items-center justify-between overflow-hidden rounded-[1.375rem] px-6 pt-[2.375rem] [transition:height_1s] md:flex-row"
                  >
                    <div
                      id="card-3-content"
                      className="relative z-10 m-[0_0_1rem] flex w-full max-w-[none] flex-col items-start justify-between py-0 md:mb-[5.5rem] md:ml-[3.125rem] md:mt-14 md:max-w-[29.625rem] lg:ml-20 lg:max-w-lg lg:py-[8.75vw]"
                    >
                      <p
                        id="card-3-heading-small"
                        className="mb-[0.3125rem] mt-0 text-xs font-medium leading-[153.846%] tracking-[.1px] md:mb-[0.6875rem] md:text-[0.8125rem] lg:mb-5"
                      >
                        BEYOND TRADITIONAL CHARTS
                      </p>
                      <h3
                        id="card-3-heading-large"
                        className="mb-[0.625rem] mt-0 text-2xl font-bold leading-[100%] md:mb-5 md:text-[2.75rem] lg:mb-10 lg:text-[3.375rem]"
                      >
                        AI-enabled alerts and optimizations for an
                        <span className=" bg-clip-text [-webkit-text-fill-color:transparent] [background-image:linear-gradient(to_right,_#ba7bff,_#747aff)]">
                          {" "}
                          unprecedented trading experience.
                        </span>
                      </h3>
                      <p
                        id="card-3-p"
                        className="mb-5 mt-0 max-w-none text-base font-normal leading-[130%_!important] tracking-[.1px] md:mb-5 md:max-w-[23.3rem] md:text-lg lg:text-xl"
                      >
                        Receive thousands of AI-generated alerts and continual
                        product updates to further refine your analysis. All
                        these benefits are housed within our{" "}
                        <strong>active member community</strong>, forming an
                        ecosystem of intelligent trading.
                      </p>
                      <a
                        href="#premium-packages"
                        id="card-3-button"
                        className="group relative z-[1] flex max-w-full cursor-pointer items-center whitespace-nowrap rounded-[4.5rem] border-2 border-white bg-black bg-opacity-0 p-[.375rem_1.25rem] text-base leading-[144.44%] text-white [text-decoration:none] [transition:background-color_.2s_cubic-bezier(.25,_.46,_.45,_.94)] lg:text-lg"
                      >
                        <p className="mb-0 mt-0 text-sm font-normal leading-[1.35] tracking-[.1px] lg:text-base">
                          Join Our Community
                        </p>
                        <div className="ml-[.625rem] flex h-[.9375rem] w-5 flex-none origin-[0%] items-center justify-center text-white [transition:transform_.2s]">
                          <svg
                            className="group-hover:scale-110 group-hover:transform group-hover:duration-300 group-hover:ease-in-out"
                            width="100%"
                            height="100%"
                            viewBox="0 0 20 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 5H19M19 5L14.6667 1M19 5L14.6667 9"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                        </div>
                      </a>
                    </div>
                    <motion.img
                      ref={ref6}
                      className="relative -bottom-[4.3rem] left-auto right-auto top-auto z-[3] inline-block h-[32rem] w-full max-w-[80%] object-contain opacity-100 [object_position:_60%_50%] md:absolute md:bottom-[-8.3rem] md:right-[2%] md:h-full md:w-[33%] md:max-w-none"
                      sizes="(max-width: 479px) 100vw, (max-width: 767px) 93vw, (max-width: 991px) 40vw, (max-width: 1279px) 50vw, (max-width: 1439px) 47vw, (max-width: 1919px) 49vw, 54vw"
                      src={images[4]}
                      alt="Discord Alerts"
                      animate={controls6}
                      initial="hidden"
                      variants={animationVariant}
                      transition={{
                        duration: 2,
                        ease: [0.43, 0.13, 0.23, 0.96],
                      }}
                    />
                    <motion.img
                      ref={ref7}
                      className="absolute bottom-[0%] left-[0%] right-[0%] top-[10%] z-[1] inline-block h-[200%] w-[65rem] max-w-full transform-none select-none object-cover opacity-100 filter-none md:-right-80 md:left-auto md:top-[-13.6rem] md:h-[150%] lg:w-full lg:blur-xl xl:right-[-21.8rem] xl:max-w-[85rem]"
                      sizes="(max-width: 479px) 100vw, (max-width: 767px) 93vw, (max-width: 991px) 88vw, (max-width: 1279px) 27vw, (max-width: 1439px) 25vw, (max-width: 1919px) 26vw, 384px"
                      src={images[7]}
                      alt="Blur 3"
                      animate={controls7}
                      initial="hidden"
                      variants={animationVariant}
                      transition={{
                        delay: 0.5,
                        duration: 2,
                        ease: [0.43, 0.13, 0.23, 0.96],
                      }}
                    />
                    <div className="absolute bottom-[0%] left-[0%] right-[0%] top-[0%] z-0 bg-slate-800 opacity-10"></div>
                  </div>
                  <img
                    className="[display:none] lg:pointer-events-none lg:absolute lg:-left-10 lg:-right-10 lg:bottom-auto lg:top-[39rem] lg:z-0 lg:inline-block lg:h-[18vw] lg:max-h-80 lg:w-[18vw] lg:max-w-[20rem] lg:object-contain"
                    src={images[8]}
                    sizes="(max-width: 479px) 0px, (max-width: 991px) 100vw, 18vw"
                    alt="Alert Bell"
                  />
                  <img
                    className="[display:none] lg:pointer-events-none lg:absolute lg:-right-20 lg:-top-40 lg:bottom-auto lg:left-auto lg:z-0 lg:inline-block lg:h-[18vw] lg:max-h-[20rem] lg:w-[18vw] lg:max-w-[20rem] lg:object-contain"
                    src={images[12]}
                    sizes="(max-width: 479px) 0px, (max-width: 991px) 100vw, 18vw"
                    alt="Gold Eth Coin"
                  />
                </div>
                <div
                  id="3d-icon-container"
                  className="pointer-events-none absolute bottom-[0%] left-[0%] right-[0%] top-[0%] block h-full w-full lg:fixed lg:[display:none]"
                >
                  <img
                    className="absolute -right-[4.6rem] -top-[4.5rem] bottom-auto left-auto z-0 inline-block h-[9.1875rem] w-[9.1875rem] max-w-full select-none object-contain [transform:translate(0px)]"
                    src={images[9]}
                    sizes="(max-width: 479px) 100vw, (max-width: 767px) 195px, (max-width: 991px) 236px, 100vw"
                    alt="BTC Coin"
                  />
                  <img
                    className="absolute -left-[5.5rem] -right-[4.8rem] bottom-auto top-[36.4rem] z-0 inline-block h-[12.5rem] w-[12.5rem] max-w-full select-none object-contain opacity-60 [transform:translate(0px)] xsm:top-[42.4rem] sm:top-[50rem] md:top-[32.4rem]"
                    src={images[10]}
                    sizes="(max-width: 479px) 100vw, (max-width: 767px) 195px, (max-width: 991px) 236px, 100vw"
                    alt="Stock Bull"
                  />
                  <img
                    className="absolute -right-[1.8rem] bottom-auto top-[88.8rem] z-0 inline-block h-[7.5rem] w-[7.5rem] max-w-full select-none object-contain opacity-60 [transform:translate(0px)] md:top-[78.8rem]"
                    src={images[8]}
                    sizes="(max-width: 479px) 100vw, (max-width: 767px) 195px, (max-width: 991px) 236px, 100vw"
                    alt="Alert Bell"
                  />
                  <img
                    className="absolute -right-[4.8rem] bottom-auto left-[-5rem] top-[137rem] z-0 inline-block h-[9.5rem] w-[9.5rem] max-w-full select-none object-contain opacity-60 [transform:translate(0px)] md:top-[115rem]"
                    src={images[12]}
                    sizes="(max-width: 479px) 100vw, (max-width: 767px) 195px, (max-width: 991px) 236px, 100vw"
                    alt="Gold Eth Coin"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {children}
    </section>
  );
};

export default Section;
