import { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import styles from "../Docs.module.css";
import { useTOC } from "../TOCContext";
import ThemeContext from "../ThemeContext";

const entries = [
  {
    displayText: "ACCESS",
    urlFragment: "access",
    headingType: "h2",
    subEntries: [
      {
        displayText: "How can I gain access?",
        urlFragment: "how-can-i-gain-access",
        headingType: "h3",
      },
      {
        displayText: "What should I do if I'm unable to access the platform?",
        urlFragment: "what-should-i-do-if-i'm-unable-to-access-the-platform",
        headingType: "h3",
      },
    ],
  },
  {
    displayText: "PRODUCT QUESTIONS",
    urlFragment: "product-questions",
    headingType: "h2",
    subEntries: [
      {
        displayText:
          "Which markets and timeframes are compatible with MADALGO Premium indicators?",
        urlFragment:
          "which-markets-and-timeframes-are-compatible-with-madalgo-premium-indicators",
        headingType: "h3",
      },
      {
        displayText: "What is the accuracy of the signals?",
        urlFragment: "what-is-the-accuracy-of-the-signals",
        headingType: "h3",
      },
      {
        displayText: "Do signals repaint?",
        urlFragment: "do-signals-repaint",
        headingType: "h3",
      },
      {
        displayText: "When is a signal considered confirmed?",
        urlFragment: "when-is-a-signal-considered-confirmed",
        headingType: "h3",
      },
      {
        displayText: "Do I need to upgrade TradingView to use MADALGO?",
        urlFragment: "do-i-need-to-upgrade-tradingview-to-use-madalgo",
        headingType: "h3",
      },
      {
        displayText: "Is MADALGO beginner-friendly?",
        urlFragment: "is-madalgo-beginner-friendly",
        headingType: "h3",
      },
      {
        displayText: "Can I automate trades with MADALGO?",
        urlFragment: "can-i-automate-trades-with-madalgo",
        headingType: "h3",
      },
      {
        displayText: "Can I use MADALGO on other platforms?",
        urlFragment: "can-i-use-madalgo-on-other-platforms",
        headingType: "h3",
      },
      {
        displayText: "Can I access real-time data for everything?",
        urlFragment: "can-i-access-real-time-data-for-everything",
        headingType: "h3",
      },
      {
        displayText: 'Why am I encountering a "Study_not_auth" error?',
        urlFragment: "why-am-i-encountering-a-study-not-auth-error",
        headingType: "h3",
      },
      {
        displayText: 'What does the "Internal Server Study" error mean?',
        urlFragment: "what-does-the-internal-server-study-error-mean",
        headingType: "h3",
      },
      {
        displayText: 'Why am I seeing a "Memory limits exceeded" error?',
        urlFragment: "why-am-i-seeing-a-memory-limits-exceeded-error",
        headingType: "h3",
      },
    ],
  },
  {
    displayText: "ORDERS",
    urlFragment: "orders",
    headingType: "h2",
    subEntries: [
      {
        displayText: "How can I cancel my subscription?",
        urlFragment: "how-can-i-cancel-my-subscription",
        headingType: "h3",
      },
      {
        displayText: "How do I reactivate a cancelled subscription?",
        urlFragment: "how-do-i-reactivate-a-cancelled-subscription",
        headingType: "h3",
      },
      {
        displayText: "What's the process for upgrading my subscription?",
        urlFragment: "whats-the-process-for-upgrading-my-subscription",
        headingType: "h3",
      },
      {
        displayText: "Is there a trial period?",
        urlFragment: "is-there-a-trial-period",
        headingType: "h3",
      },
      {
        displayText: "Will my subscription price change?",
        urlFragment: "will-my-subscription-price-change",
        headingType: "h3",
      },
      {
        displayText: "How long does it take to gain access after subscribing?",
        urlFragment: "how-long-does-it-take-to-gain-access-after-subscribing",
        headingType: "h3",
      },
      {
        displayText: "Can I switch access to a different TradingView account?",
        urlFragment: "can-i-switch-access-to-a-different-tradingview-account",
        headingType: "h3",
      },
      {
        displayText: "What's the typical customer support response time?",
        urlFragment: "whats-the-typical-customer-support-response-time",
        headingType: "h3",
      },
    ],
  },
];
export { entries };

export default function DocsFAQ() {
  const { addToTOC, removeFromTOC } = useTOC();
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    entries.forEach(addToTOC);
    return () => {
      entries.forEach((entry) => removeFromTOC(entry));
    };
  }, [addToTOC, removeFromTOC]);

  return (
    <>
      <h1 className={styles.h1}>❓ FAQ</h1>

      <h2 id="access" className={`scroll-mt-2 ${styles["h2"]}`}>
        ACCESS
        <Link
          to="#access"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <h3 id="how-can-i-gain-access" className={`scroll-mt-2 ${styles["h3"]}`}>
        How can I gain access?
        <Link
          to="#how-can-i-gain-access"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <ol className={styles.ol}>
        <li>Sign up for any plan available on our website.</li>
        <li>
          Upon subscription, you'll receive a prompt to link your accounts,
          granting you instant access.
        </li>
        <li>
          If you encounter any difficulties, don't hesitate to reach out to our
          support team at{" "}
          <Link className={styles.link} to="mailto:support@madalgo.io">
            support@madalgo.io
          </Link>
          . Share your TradingView and Discord usernames, and we'll promptly
          provide you with full access.
        </li>
      </ol>
      <p className={styles.p}>
        For additional FAQs about orders, click{" "}
        <Link className={styles.link} to="#orders">
          here
        </Link>
        .
      </p>
      <h2 id="product-questions" className={`scroll-mt-2 ${styles["h2"]}`}>
        PRODUCT QUESTIONS
        <Link
          to="#product-questions"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <h3
        id="which-markets-and-timeframes-are-compatible-with-madalgo-premium-indicators"
        className={`scroll-mt-2 ${styles["h3"]}`}
      >
        Which markets and timeframes are compatible with MADALGO Premium
        indicators?
        <Link
          to="#which-markets-and-timeframes-are-compatible-with-madalgo-premium-indicators"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        MADALGO Premium indicators are effective across international markets,
        including stocks, indices, forex, futures, and commodities. They
        function across various timeframes, such as 1m, 5m, 30m, 1H, 4H, 1D, 1W,
        etc. These indicators cater to scalping, swing trading, and options
        trading.
      </p>
      <p className={styles.p}>
        There is no <strong>"best timeframe"</strong> as it really all depends
        on the current market conditions, your trading style, and other factors.
      </p>
      <h3
        id="what-is-the-accuracy-of-the-signals"
        className={`scroll-mt-2 ${styles["h3"]}`}
      >
        What is the accuracy of the signals?
        <Link
          to="#what-is-the-accuracy-of-the-signals"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        The accuracy of our signals isn't specified due to their unique design
        and intended usage. We offer two signal modes, Confirmation (most
        popular) and Contrarian, to suit different trading styles. These signals
        are meant to complement your technical analysis, rather than being
        followed blindly. Remember, past performance doesn't necessarily predict
        future results.
      </p>
      <h3 id="do-signals-repaint" className={`scroll-mt-2 ${styles["h3"]}`}>
        Do signals repaint?
        <Link
          to="#do-signals-repaint"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        Signals within MADALGO Premium indicators are non-repainting. They
        appear in real-time and remain confirmed and permanent on the chart
        after the relevant candle closes.
      </p>
      <h3
        id="when-is-a-signal-considered-confirmed"
        className={`scroll-mt-2 ${styles["h3"]}`}
      >
        When is a signal considered confirmed?
        <Link
          to="#when-is-a-signal-considered-confirmed"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        A signal within MADALGO Premium indicators is confirmed only when the
        associated candle closes. While a signal might appear before the candle
        closes, confirmation occurs once the candle has closed, and the
        subsequent one has begun. Our scanner bots wait for this confirmation
        before notifying you of any signals.
      </p>
      <h3
        id="do-i-need-to-upgrade-tradingview-to-use-madalgo"
        className={`scroll-mt-2 ${styles["h3"]}`}
      >
        Do I need to upgrade TradingView to use MADALGO?
        <Link
          to="#do-i-need-to-upgrade-tradingview-to-use-madalgo"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        No upgrade to TradingView is necessary. MADALGO Premium indicators
        function with full capabilities on the basic (free) plan of TradingView.
        You can easily sign up for a free account on their platform.
      </p>
      <h3
        id="is-madalgo-beginner-friendly"
        className={`scroll-mt-2 ${styles["h3"]}`}
      >
        Is MADALGO beginner-friendly?
        <Link
          to="#is-madalgo-beginner-friendly"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        Absolutely! MADALGO Premium indicators are designed for easy
        understanding, and we offer comprehensive tutorials alongside a
        supportive user community. However, keep in mind that trading carries
        risks. If you're new to trading, consider paper-trading initially and
        focusing on risk management until you're familiar with the concepts.
      </p>
      <h3
        id="can-i-automate-trades-with-madalgo"
        className={`scroll-mt-2 ${styles["h3"]}`}
      >
        Can I automate trades with MADALGO?
        <Link
          to="#can-i-automate-trades-with-madalgo"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        MADALGO Premium indicators are comprehensive tools for discretionary
        analysis. While you could theoretically use third-party platforms to
        automate strategies using MADALGO indicators through webhooks, we don't
        typically recommend it. Our indicators are best used as supportive tools
        for decision-making.
      </p>
      <h3
        id="can-i-use-madalgo-on-other-platforms"
        className={`scroll-mt-2 ${styles["h3"]}`}
      >
        Can I use MADALGO on other platforms?
        <Link
          to="#can-i-use-madalgo-on-other-platforms"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        Currently, MADALGO Premium indicators are exclusively compatible with
        TradingView, a web-based charting platform. However, you can trade in
        real-time on any platform using the charts provided by TradingView.
      </p>
      <h3
        id="can-i-access-real-time-data-for-everything"
        className={`scroll-mt-2 ${styles["h3"]}`}
      >
        Can I access real-time data for everything?
        <Link
          to="#can-i-access-real-time-data-for-everything"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        TradingView supports real-time data for the majority of tickers
        available on the platform. Only a small selection, mainly futures
        contracts, might require a modest monthly fee for accessing real-time
        data.
      </p>
      <Link
        to="https://www.tradingview.com/gopro/#markets"
        className={`
          ${theme === "light" ? styles.changelogLink : styles.changelogLinkDark}
        `}
      >
        <span
          style={{
            userSelect: "none",
            marginRight: 10,
            marginBottom: 3,
          }}
        >
          »
        </span>
        TradingView Premium Account
      </Link>
      <h3
        id="why-am-i-encountering-a-study-not-auth-error"
        className={`scroll-mt-2 ${styles["h3"]}`}
      >
        Why am I encountering a "Study_not_auth" error?
        <Link
          to="#why-am-i-encountering-a-study-not-auth-error"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        This error message indicates that a user doesn't have access to the
        indicator. If you've already registered your TradingView username in our
        Connect Accounts portal and still see this error, refresh your browser
        or app. Ensure you're logged in to the TradingView profile you provided.
        If the issue persists, reach out to{" "}
        <Link className={styles.link} to="mailto:support@madalgo.io">
          support@madalgo.io
        </Link>{" "}
        with your TradingView username, and we'll promptly grant you access. If
        you haven't signed up yet, this error might occur when attempting to add
        our premium indicators to your chart. You can gain instant access{" "}
        <Link className={styles.link} to="/#premium-packages">
          here.
        </Link>
      </p>
      <h3
        id="what-does-the-internal-server-study-error-mean"
        className={`scroll-mt-2 ${styles["h3"]}`}
      >
        What does the "Internal Server Study" error mean?
        <Link
          to="#what-does-the-internal-server-study-error-mean"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        This error arises when TradingView struggles with script loads due to
        high traffic. It's common when numerous features are enabled. Resolving
        it may involve adjusting settings within the indicators or toggling
        their visibility. Our continuous efforts aim to enhance toolkit
        performance and minimize these occurrences.
      </p>
      <h3
        id="why-am-i-seeing-a-memory-limits-exceeded-error"
        className={`scroll-mt-2 ${styles["h3"]}`}
      >
        Why am I seeing a "Memory limits exceeded" error?
        <Link
          to="#why-am-i-seeing-a-memory-limits-exceeded-error"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        This error results from a TradingView study surpassing the memory limit
        set by the platform. It's likely to occur with timeframes below 1
        minute. To avoid such errors with our premium toolkits, we recommend
        sticking to minute and higher timeframes.
      </p>

      <h2 id="orders" className={`scroll-mt-2 ${styles["h2"]}`}>
        ORDERS
        <Link
          to="#orders"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <h3
        id="how-can-i-cancel-my-subscription"
        className={`scroll-mt-2 ${styles["h3"]}`}
      >
        How can I cancel my subscription?
        <Link
          to="#how-can-i-cancel-my-subscription"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        You can cancel your subscription easily by clicking 'Manage Account' on{" "}
        <Link className={styles.link} to="/">
          our website
        </Link>{" "}
        or by contacting{" "}
        <Link className={styles.link} to="mailto:support@madalgo.io">
          support@madalgo.io
        </Link>
        . Our team will promptly assist you on the same day. Upon cancellation,
        you'll retain access for the remainder of your purchased plan without
        further charges.
      </p>

      <h3
        id="how-do-i-reactivate-a-cancelled-subscription"
        className={`scroll-mt-2 ${styles["h3"]}`}
      >
        How do I reactivate a cancelled subscription?
        <Link
          to="#how-do-i-reactivate-a-cancelled-subscription"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        To reactivate a previously cancelled subscription, simply purchase a
        plan again from our{" "}
        <Link className={styles.link} to="/#premium-packages">
          pricing page
        </Link>
        . During the process, make sure to use the email associated with your
        previous subscription when logging in.
      </p>

      <h3
        id="whats-the-process-for-upgrading-my-subscription"
        className={`scroll-mt-2 ${styles["h3"]}`}
      >
        What's the process for upgrading my subscription?
        <Link
          to="#whats-the-process-for-upgrading-my-subscription"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        To upgrade your subscription, log in on{" "}
        <Link className={styles.link} to="/">
          our website
        </Link>
        . Click 'Update Plan' after logging in, and then select the upgraded
        plan that suits you. You'll be able to purchase the higher plan with a
        prorated charge, taking into account your previous billing cycle. Once
        purchased, you'll be seamlessly upgraded.
      </p>

      <h3
        id="is-there-a-trial-period"
        className={`scroll-mt-2 ${styles["h3"]}`}
      >
        Is there a trial period?
        <Link
          to="#is-there-a-trial-period"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        Absolutely! We offer a 14-day money-back guarantee, allowing you to
        explore our premium indicators risk-free. Additionally, we're working on
        introducing free indicators, enabling you to try{" "}
        <Link
          className={styles.link}
          to="https://www.tradingview.com/u/MADALGO/#published-script"
        >
          our products
        </Link>{" "}
        without any risk in the near future.
      </p>

      <h3
        id="will-my-subscription-price-change"
        className={`scroll-mt-2 ${styles["h3"]}`}
      >
        Will my subscription price change?
        <Link
          to="#will-my-subscription-price-change"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        No need to worry. The price you initially signed up for will remain
        constant as long as you stay subscribed. We're committed to maintaining
        the subscription price you originally selected. Please note that coupons
        used on madalgo.io apply to the first subscription interval only, and
        subsequent billing intervals will be at the full subscription price you
        chose.
      </p>

      <h3
        id="how-long-does-it-take-to-gain-access-after-subscribing"
        className={`scroll-mt-2 ${styles["h3"]}`}
      >
        How long does it take to gain access after subscribing?
        <Link
          to="#how-long-does-it-take-to-gain-access-after-subscribing"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        Upon signing up, check your confirmation email for a link to connect
        accounts and gain instant access. If you enter your TradingView username
        correctly, it will appear in your TradingView account's "Invite-Only
        Scripts" section within 1-3 minutes after a browser/app refresh. If you
        encounter difficulties, reply to your confirmation email with your
        TradingView username, and our support team will ensure rapid access.
      </p>
      <h3
        id="can-i-switch-access-to-a-different-tradingview-account"
        className={`scroll-mt-2 ${styles["h3"]}`}
      >
        Can I switch access to a different TradingView account?
        <Link
          to="#can-i-switch-access-to-a-different-tradingview-account"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        Absolutely. If you want to switch access to a new TradingView account,
        send both the old and new usernames to{" "}
        <Link className={styles.link} to="mailto:support@madalgo.io">
          support@madalgo.io
        </Link>
        . Our support team will promptly adjust this for you.
      </p>
      <h3
        id="whats-the-typical-customer-support-response-time"
        className={`scroll-mt-2 ${styles["h3"]}`}
      >
        What's the typical customer support response time?
        <Link
          to="#whats-the-typical-customer-support-response-time"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        Our support team is exceptionally responsive, typically addressing
        queries within minutes (with a maximum of 10-15 minutes) on weekdays
        (Monday - Friday). For inquiries made on weekends outside of 9 AM to 8
        PM EST, please allow up to 8 hours for a response. You can reach out to
        us via email at{" "}
        <Link className={styles.link} to="mailto:support@madalgo.io">
          support@madalgo.io
        </Link>{" "}
        or through{" "}
        <Link className={styles.link} to="/">
          our website
        </Link>
        .
      </p>
    </>
  );
}
