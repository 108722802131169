import images from "../images";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "../Docs.module.css";
import { useTOC } from "../TOCContext";

const entries = [
  {
    displayText: "Why Choose MADALGO?",
    urlFragment: "why-choose-madalgo",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "Understanding the Real Power",
    urlFragment: "understanding-the-real-power",
    headingType: "h2",
    subEntries: [
      {
        displayText: "Indicators and Traders: The Synergistic Partnership",
        urlFragment: "indicators-and-traders-the-synergistic-partnership",
        headingType: "h3",
      },
      {
        displayText: "Unleash Your Trading Potential",
        urlFragment: "unleash-your-trading-potential",
        headingType: "h3",
      },
    ],
  },
];
export { entries };

export default function DocsGetStarted() {
  const { addToTOC, removeFromTOC } = useTOC();

  useEffect(() => {
    entries.forEach(addToTOC);
    return () => {
      entries.forEach((entry) => removeFromTOC(entry));
    };
  }, [addToTOC, removeFromTOC]);

  return (
    <>
      <h1 className={styles.h1}>What Is MADALGO?</h1>
      <p className={styles.p}>
        MADALGO is at the forefront of crafting <strong>Feedback-driven</strong>{" "}
        & <strong>AI-driven</strong> indicators, propelling traders into a new
        era of market visualizations. On TradingView, the MADALGO Premium suite
        showcases three cutting-edge indicators, each serving as a comprehensive
        toolkit filled with diverse, specialized features to elevate traders'
        technical analysis prowess.
      </p>
      <p className={styles.p}>
        <img src={images[10]} alt="Bull"></img>
      </p>
      <p className={styles.p}>
        Experience a holistic user journey on our Discord platform, a space
        where alerts from every market converge and where traders collaborate,
        fostering a growing and vibrant trading community.
      </p>
      <p className={styles.p}>
        Our innovative tools are designed to offer users distinct vantage points
        and arm them with an extensive trading toolset.
      </p>
      <p className={styles.p}>
        From insightful signals, dynamic candle hues, real-time alerts, robust
        support & resistance markers, insight into institutional moves, or an
        amalgamation of these elements; traders often cherry-pick and integrate
        select features from our toolkits into their trading strategy.
      </p>
      <p className={styles.p}>
        All of this comes bundled in a single membership, available for{" "}
        <Link className={`${styles["docsInnerLink"]}`} to="/#premium-packages">
          subscription
        </Link>{" "}
        on our website.
      </p>
      <h2 id="why-choose-madalgo" className={`scroll-mt-2 ${styles["h2"]}`}>
        Why Choose MADALGO?
        <Link
          to="#why-choose-madalgo"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        MADALGO is the intersection of innovation and efficiency in trading,
        providing unparalleled insights with next-gen market visualizations.
        Equip yourself with our{" "}
        <Link
          className={`${styles["docsInnerLink"]}`}
          to="https://www.tradingview.com/u/madalgo/#published-scripts"
        >
          cutting-edge indicators
        </Link>
        , and stay ahead in the trading game.
      </p>
      <p className={styles.p}>
        Joining MADALGO is joining a thriving Discord community. Get real-time
        market alerts, engage in enriching discussions, and collaborate with
        traders across all expertise levels. With MADALGO, you're not just
        trading; you're evolving.
      </p>
      <h2
        id="understanding-the-real-power"
        className={`scroll-mt-2 ${styles["h2"]}`}
      >
        Understanding the Real Power
        <Link
          to="#understanding-the-real-power"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        Upon encountering MADALGO, traders often grapple with a couple of
        pressing questions:
      </p>
      <blockquote>
        <p className={styles.p}>
          <em>
            What edge do these premium indicators offer compared to the free
            ones?
          </em>
        </p>
      </blockquote>
      <p className={styles.p}>And the paramount query:</p>
      <blockquote>
        <p className={styles.p}>
          <em>Can I bank on consistent profits using them?</em>
        </p>
      </blockquote>
      <p className={styles.p}>
        It would be both overconfident and misleading for us to claim our
        indicators are magic wands bestowing immense returns swiftly. Reality,
        as always, is nuanced.
      </p>
      <p className={styles.p}>
        No tool, no matter how sophisticated, promises a boost in trading
        outcomes, nor can any approach assure unwavering success percentages
        over durations; there's no golden goose in trading. However, the
        uniqueness of our indicators lies in their interactivity and seamless
        synergy when used in tandem, and therein could lie your edge.
      </p>
      <h3
        id="indicators-and-traders-the-synergistic-partnership"
        className={`scroll-mt-2 ${styles["h3"]}`}
      >
        Indicators and Traders: The Synergistic Partnership
        <Link
          to="#indicators-and-traders-the-synergistic-partnership"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        While many may hope that trading indicators can autonomously navigate
        the markets — a "set and forget" strategy — they'd contend, "If it can't
        yield profits on autopilot, it's not worth it."
      </p>
      <p className={styles.p}>
        True value in a trading tool isn't about autonomous profit-making; the
        unpredictability of market shifts challenges any indicator to
        consistently excel alone. However, for the seasoned trader, indicators
        aren't golden tickets but rather vital aids for informed decisions.
      </p>
      <p className={styles.p}>
        The magic, we believe, resides in the harmonious blend of trader acumen
        with indicator prowess. While an indicator is adept at sifting through
        vast data and distilling it into actionable insights — something
        challenging for humans alone — the trader possesses astute judgment,
        awareness of external nuances, and money management skills.
      </p>
      <blockquote>
        <p className={styles.p}>
          <em>
            During my evaluation for a role at an elite investment firm, we
            collaborated with a trading algorithm. I initially presumed it was
            fully self-reliant. But in times of significant market volatility,
            we manually intervened, curbing potential risks. It was a lesson in
            the symbiotic relationship between human judgment and automated
            tools, reshaping my view on indicators in trading.
          </em>
        </p>
        <p>
          — <em>visions</em>
        </p>
      </blockquote>
      <p className={styles.p}>
        By pairing insightful, accessible information from an indicator with the
        expertise of a seasoned trader, one achieves a formidable duo — and
        that's precisely the dynamic MADALGO aspires to facilitate.
      </p>
      <h3 id="unleash-your-trading-potential" className={`${styles["h3"]}`}>
        Unleash Your Trading Potential
        <Link
          to="#unleash-your-trading-potential"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        Freedom is the foundation of progress. While some traders blaze their
        own trails against the current, others flow seamlessly with the tide.
        Whatever your trading spirit, we cater to it, providing tools versatile
        enough for any trader to harness their maximum potential.
      </p>
      <p className={styles.p}>
        What sets MADALGO Premium indicators apart? While many offer a flat,
        surface-level view of the market, we immerse you in a multi-dimensional
        experience, empowering you to engage with our tools uniquely — either as
        solitary guides or harmonizing alongside other indicators.
      </p>
      <div
        className={`warning warning-caution selection:bg-[#ffeec4] selection:text-[#4b4b4b] ${styles["alertWarning"]}`}
      >
        <div className="warning-heading">
          <h5 className={styles.h5}>
            <span className={styles.alertWarningIcon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M8.893 1.5c-.183-.31-.52-.5-.887-.5s-.703.19-.886.5L.138 13.499a.98.98 0 0 0 0 1.001c.193.31.53.501.886.501h13.964c.367 0 .704-.19.877-.5a1.03 1.03 0 0 0 .01-1.002L8.893 1.5zm.133 11.497H6.987v-2.003h2.039v2.003zm0-3.004H6.987V5.987h2.039v4.006z"
                ></path>
              </svg>
            </span>
            caution
          </h5>
        </div>
        <div className="warning-content">
          <p>
            <em>
              CFTC Rule 4.41 - Hypothetical or Simulated performance results
              have certain limitations, unlike an actual performance record,
              simulated results do not represent actual trading. Also, since the
              trades have not been executed, the results may have under-or-over
              compensated for the impact, if any, of certain market factors,
              such as lack of liquidity. Simulated trading programs in general
              are also subject to the fact that they are designed with the
              benefit of hindsight. No representation is being made that any
              account will or is likely to achieve profit or losses similar to
              those shown.
            </em>
          </p>
        </div>
      </div>
    </>
  );
}
