/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "us-east-1",
  aws_appsync_graphqlEndpoint:
    "https://mjw4naoiwvbkzjzejxxssybi74.appsync-api.us-east-1.amazonaws.com/graphql",
  aws_appsync_region: "us-east-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_appsync_apiKey: "da2-euvylwl6uzfhtciny2xmffsat4",
  aws_cloud_logic_custom: [
    {
      name: "madalgocontactapi",
      endpoint:
        "https://hjf8ikybpg.execute-api.us-east-1.amazonaws.com/staging",
      region: "us-east-1",
    },
  ],
  aws_cognito_identity_pool_id:
    "us-east-1:8805d83d-c9ca-43ce-9e20-6225c394fc59",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_bsW3FMcbS",
  aws_user_pools_web_client_id: "491kqjn4e1kr66ad3sqonvcaei",
  oauth: {
    domain: "srdvdokdc3ho-staging.auth.us-east-1.amazoncognito.com",
    scope: [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin",
    ],
    redirectSignIn: "https://account.madalgo.io/u/signup/",
    redirectSignOut: "https://www.madalgo.io",
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_POOLS",
  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_social_providers: ["GOOGLE"],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: ["SMS"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
  aws_dynamodb_all_tables_region: "us-east-1",
  aws_dynamodb_table_schemas: [
    {
      tableName: "madalgocontactformtable-staging",
      region: "us-east-1",
    },
  ],
};

export default awsmobile;
