import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import styles from "./Main.module.css";
import React from "react";
import ThreeScene from "./ThreeScene";
import { TimelineCarousel } from "./TimelineCarousel";
import cube from "./videos/cube.mp4";
import ai1 from "./videos/ai1.webm";
import meta from "./videos/meta.mp4";
import VideoComponent from "./VideoComponent";

import "./aboutus.css";

export default function AboutUs({ setIsAboutUsPage }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isLoaded, setIsLoaded] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setIsAboutUsPage(true);
    return () => setIsAboutUsPage(false);
  }, [setIsAboutUsPage]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoaded(true);
    });

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    document.body.classList.add("bg-white");

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      document.body.classList.remove("bg-white");
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const h1Variants = {
    hidden: {
      rotateX: "-40deg",
      translateY: "130%",
      opacity: 0,
      transformPerspective: 600,
    },
    visible: {
      rotateX: "0deg",
      translateY: "0%",
      opacity: 1,
      transformPerspective: 600,
      transition: {
        duration: 0.8,
        ease: "easeOut",
        staggerChildren: isMobile ? 0.5 : 0,
      },
    },
  };

  const wordVariants = {
    hidden: {
      opacity: 0,
      rotateX: "-40deg",
      translateY: "130%",
    },
    visible: {
      opacity: 1,
      rotateX: "0deg",
      translateY: "0%",
    },
  };

  return (
    <>
      {" "}
      <div
        id="about-us-loader"
        className={`${styles.aboutUsLoader} ${
          isLoaded ? "" : styles.hideLoader
        }`}
      ></div>
      <div
        id="about-wrapper"
        className=" relative  w-full overflow-hidden pb-24 text-black"
      >
        <section
          id="about-heading"
          className=" relative z-[1] block h-[55vh] min-h-[300px] txsm:min-h-[356px]"
        >
          <div
            id="head-content"
            className="flex h-full flex-col justify-center [-moz-box-direction:normal] [-moz-box-orient:vertical] [-moz-box-pack:center]"
          >
            <div
              id="head-content-wrapper"
              className="relative flex flex-row items-center justify-between p-[0_20px] txsm:p-[0_30px] md:p-[0_100px] lg:p-[0_120px] xl:p-[0_150px] 1xl:p-[0_165px] 2xl:m-[0_auto] 2xl:w-full 2xl:max-w-[2000px] 2xl:p-[0_270px]"
            >
              <motion.div
                id="title"
                className="text-5xl leading-[100%_!important] tracking-[-.05em] md:text-[6.25vw] 2xl:text-[120px]"
                variants={h1Variants}
                initial="hidden"
                animate="visible"
              >
                <motion.h1
                  className=" m-0 block p-0 leading-[inherit] tracking-[inherit] will-change-transform [font-size:inherit] [font-weight:inherit] [perspective:600px] [transform-origin:center_top] [transform-style:preserve-3d]"
                  variants={wordVariants}
                  initial="hidden"
                  animate="visible"
                  transition={{
                    duration: 0.8,
                    ease: "easeOut",
                  }}
                >
                  AI Meets
                </motion.h1>
                <motion.h1
                  className=" 1xl m-0 block p-0 leading-[inherit] tracking-[inherit] will-change-transform [font-size:inherit] [font-weight:inherit] [perspective:600px] [transform-origin:center_top] [transform-style:preserve-3d]"
                  variants={wordVariants}
                  initial="hidden"
                  animate="visible"
                  transition={{
                    duration: 0.8,
                    ease: "easeOut",
                    delay: 0.1675,
                  }}
                >
                  <span className={`${styles.blink}`}>_</span>
                  Trading
                </motion.h1>
              </motion.div>
              <motion.div
                id="ai-animate"
                className="absolute -right-28 -z-[1] md:-right-14 lg:-right-4 xl:right-24"
                initial={{ opacity: 0, x: 50 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8 }}
              >
                <VideoComponent
                  id="ai"
                  className="relative h-[330px] w-[330px] max-w-3xl lg:h-full lg:w-full 1xl:max-w-5xl 2xl:max-w-6xl"
                  poster=""
                >
                  <source data-src={ai1} />
                </VideoComponent>
              </motion.div>
            </div>
          </div>
        </section>
        <section
          id="3dparticleshere"
          className="relative z-0 block w-full overflow-hidden bg-[#0a090d]"
        >
          <div
            id="3d-section-container"
            className="3d-section-container flex min-h-[50rem] w-full flex-col items-center pb-10 pt-28 lg:min-h-[72rem] lg:pt-[11.25rem]"
          >
            {location.pathname === "/about-us" && <TimelineCarousel />}

            <div className={`${styles["w-embed"]} ${styles.canvasContainer}`}>
              <ThreeScene />
            </div>
          </div>
        </section>
        <section id="about-us-section" className="relative z-0 block w-full">
          <motion.div
            id="about-us-upper-content"
            className="flex w-full flex-col items-center pt-14"
          >
            <motion.p
              id="about-us-small-heading"
              className="px-1 py-1 pt-14 text-sm font-medium"
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
            >
              Our North Star
            </motion.p>
            <motion.h2
              id="about-us-heading"
              className=" w-60 p-1 pb-4 text-center text-xl font-bold lg:w-96 lg:text-2xl"
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
            >
              Empowering Every Trader with AI Mastery
            </motion.h2>
            <div id="about-wrapper" className="relative p-2">
              <motion.div
                id="cube-morph"
                className="relative flex justify-center pb-8"
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.4 }}
              >
                <VideoComponent
                  id="cube"
                  className="h-[330px] w-[330px] md:w-1/2 lg:h-full lg:w-1/2"
                >
                  <source data-src={cube} data-wf-ignore="true" />
                </VideoComponent>
              </motion.div>
              <motion.div
                id="about-us-p-content"
                className="relative flex flex-col items-center justify-center lg:flex-row lg:items-start"
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.4 }}
              >
                <div id="about-us-upper-p-wrapper" className="flex flex-col">
                  <p
                    id="about-us-upper-p-1"
                    className="text-1xl px-1 py-1 font-semibold leading-tight tracking-tighter md:w-[25rem]"
                  >
                    MADALGO is more than just an algorithm or a tool; it's a
                    culmination of relentless curiosity and the urge to simplify
                    complexities.
                  </p>
                  <br />
                  <p
                    id="about-us-upper-p-2"
                    className="text-1xl px-1 py-1 font-semibold leading-tight tracking-tighter md:w-[25rem]"
                  >
                    Spawned from the vibrant fusion of advanced technology and
                    trading artistry, MADALGO stands as a beacon for traders,
                    nudging them closer to their aspirations.
                  </p>
                </div>

                <div id="about-us-lower-p-wrapper" className="flex flex-col">
                  <p
                    id="about-us-lower-p-1"
                    className="text-1xl px-1 py-1 font-semibold leading-tight tracking-tighter md:w-[25rem]"
                  >
                    For those seeking not just an edge, but a revolution in
                    trading, MADALGO promises to be a constant companion,
                    learning, evolving, and innovating.
                  </p>
                  <br />
                  <p
                    id="about-us-lower-p-2"
                    className="text-1xl px-1 py-1 font-semibold leading-tight tracking-tighter md:w-[25rem]"
                  >
                    Our journey thrives on the voice of our community. Every
                    feedback, every insight, every success story drives us to
                    refine and redefine trading's future horizons.
                  </p>
                </div>
              </motion.div>
            </div>
          </motion.div>

          <div
            id="about-us-lower-content"
            className="flex w-full flex-col items-center pt-14"
          >
            <motion.p
              id="about-us-small-heading"
              className="px-1 py-1 pt-14 text-sm font-medium"
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
            >
              Our Genesis
            </motion.p>
            <motion.h2
              id="about-us-heading"
              className=" w-60 p-1 pb-4 text-center text-xl font-bold lg:w-96 lg:text-2xl"
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
            >
              A Journey of Pixels, Codes, and Dreams
            </motion.h2>
            <div id="about-lower-wrapper" className="relative p-2">
              <motion.div
                id="cube-morph"
                className="relative flex justify-center pb-8"
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.4 }}
              >
                <VideoComponent
                  id="meta"
                  className="h-[330px] w-[330px] md:w-3/4 lg:h-full lg:w-1/2"
                >
                  <source data-src={meta} data-wf-ignore="true" />
                </VideoComponent>
              </motion.div>
              <motion.div
                id="about-lower-wrapper-content"
                className="relative flex flex-col items-center justify-center lg:flex-row lg:items-start"
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.6 }}
              >
                <div id="about-us-lower-p-wrapper" className="flex flex-col">
                  <p
                    id="about-us-lower-p"
                    className="text-1xl px-1 py-1 font-semibold leading-tight tracking-tighter md:w-[25rem]"
                  >
                    It began with capturing fleeting moments on a simple camera
                    phone. Fast forward through a point-and-shoot, a
                    professional DSLR, and an Instagram following that burgeoned
                    to a staggering 130K+. The journey didn’t stop there.
                  </p>
                  <br />

                  <p
                    id="about-us-lower-p-middle"
                    className="text-1xl px-1 py-1 font-semibold leading-tight tracking-tighter md:w-[25rem]"
                  >
                    An act of faith from an investor equipped Cody with a
                    top-tier arsenal of professional camera and video equipment,
                    setting the stage for what was yet to come. Parallel to
                    this, a freshly built computer destined for 4K video editing
                    kindled a new flame.
                  </p>
                </div>
                <br />
                <div id="about-us-lower-p-wrapper" className="flex flex-col">
                  <p
                    id="about-us-lower-p-middle-second"
                    className="text-1xl px-1 py-1 font-semibold leading-tight tracking-tighter md:w-[25rem]"
                  >
                    An offhand comment about GPU led to the discovery of ETH
                    mining in February 2021, forging a path into the intricate
                    labyrinths of finance, stocks, crypto, and economics. Two
                    years of immersive learning, diving deep into the worlds of
                    trading, technical analysis, and everything finance-related
                    followed.
                  </p>
                  <br />

                  <p
                    id="about-us-lower-p-lower"
                    className="text-1xl px-1 py-1 font-semibold leading-tight tracking-tighter md:w-[25rem]"
                  >
                    Come February 2023, armed with a vision and limited coding
                    knowledge, the odyssey began to build an indicator bundle.
                    From rudimentary web design skills and WordPress themes to
                    mastering a plethora of coding languages, the transformation
                    into a self-made full-stack developer was nothing short of
                    phenomenal. The result? Three cutting-edge indicators
                    boasting 22+ AI-powered features, a testament to what
                    passion, AI, and relentless drive can achieve.
                  </p>
                </div>
              </motion.div>
            </div>
          </div>
        </section>

        <section
          id="check-out-more-digi-globe"
          className="relative z-0 block w-full"
        ></section>
      </div>
    </>
  );
}
