import { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import styles from "../Docs.module.css";
import { useTOC } from "../TOCContext";
import ThemeContext from "../ThemeContext";

const entries = [];

export default function DocsChangelog() {
  const { addToTOC, removeFromTOC } = useTOC();
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    entries.forEach(addToTOC);
    return () => {
      entries.forEach((entry) => removeFromTOC(entry));
    };
  }, [addToTOC, removeFromTOC]);

  return (
    <>
      <h1 className={styles.h1}>Changelogs</h1>
      <p className={styles.p}>
        The subpages below detail the version and update history of every
        MADALGO indicator. You can identify the version of the indicator you're
        currently using by checking the end of its title on your chart.
      </p>
      <Link
        to="madalgo-premium-pulse"
        className={`
          ${theme === "light" ? styles.changelogLink : styles.changelogLinkDark}
        `}
      >
        <span
          style={{
            userSelect: "none",
            marginRight: 10,
            marginBottom: 3,
          }}
        >
          »
        </span>
        MADALGO Premium Pulse
      </Link>
      <Link
        to="madalgo-premium-navigator"
        className={`
          ${theme === "light" ? styles.changelogLink : styles.changelogLinkDark}
        `}
      >
        <span
          style={{
            userSelect: "none",
            marginRight: 10,
            marginBottom: 3,
          }}
        >
          »
        </span>
        MADALGO Premium Navigator
      </Link>
      <Link
        to="madalgo-premium-dynamics"
        className={`
          ${theme === "light" ? styles.changelogLink : styles.changelogLinkDark}
        `}
      >
        <span
          style={{
            userSelect: "none",
            marginRight: 10,
            marginBottom: 3,
          }}
        >
          »
        </span>
        MADALGO Premium Dyanmics
      </Link>
    </>
  );
}
