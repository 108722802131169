import images from "./blogfeatureimages";

const BlogPosts = [
  {
    slug: "advanced-chart-analysis",
    title: "Advanced Chart Analysis",
    image: images[1],
    category: "Education",
    date: "August 27th, 2023",
  },
  // {
  //   slug: "advanced-chart-analysis",
  //   title: "Advanced Chart Analysis",
  //   image: images[0],
  //   category: "Education",
  //   date: "September 14th, 2023",
  // },
  // {
  //   slug: "trends-trends-trends",
  //   title: "Trends, Trends, and more Trends",
  //   image: images[0],
  //   category: "Education",
  //   date: "October 10th, 2023",
  // },
  // {
  //   slug: "seven-swing-trade-methods",
  //   title: "7 Swing Trade Methods",
  //   image: images[0],
  //   category: "Education",
  //   date: "November 10th, 2023",
  // },
  // {
  //   slug: "ai-and-trading",
  //   title: "AI and Trading",
  //   image: images[0],
  //   category: "Education",
  //   date: "December 10th, 2023",
  // },
  // {
  //   slug: "new-feature-coming-soon",
  //   title: "New Feature Coming Soon",
  //   image: images[0],
  //   category: "New Feature",
  //   date: "Jan 10th, 2024",
  // },
  // {
  //   slug: "technical-analysis-is-your-friend",
  //   title: "Technical Analysis is Your Friend",
  //   image: images[0],
  //   category: "Education",
  //   date: "Feb 10th, 2024",
  // },
];

export default BlogPosts;
