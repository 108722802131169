import React from "react";
import BlurPages from "./BlurPages";
import styles from "./Main.module.css";
import { motion } from "framer-motion";

function TermsOfService() {
  return (
    <>
      <section
        id="heading-terms-of-service-section"
        className="relative z-0 block w-full"
      >
        <div
          id="inner-terms-of-service-container"
          className="w-full px-7 pb-9 pt-[10rem] md:px-14 md:pb-[6.25rem] lg:pt-[15.125rem] xl:px-28"
        >
          <div id="header-container" className="relative z-[1] text-center">
            <motion.h1
              id="terms-of-service-heading"
              className=" text-5xl md:text-[5rem] lg:text-[7.5rem]"
              initial={{ opacity: 0 }}
              transition={{ duration: 2 }}
              animate={{ opacity: 1 }}
            >
              Terms Of Service
            </motion.h1>
          </div>
          <div
            id="blur"
            className=" absolute bottom-[0%] left-[0%] right-[0%] top-[0%] z-0 block"
          >
            <div
              id="gradient-blur-group-1"
              className="lg:w[85vw] absolute bottom-0 left-auto right-[-6.6rem] top-auto h-[100vw] w-[95vw] opacity-60 filter-none md:-bottom-80 md:w-screen lg:right-auto lg:h-[85vw] xl:-bottom-[30rem] xl:left-[5.7rem] xl:max-h-[100rem] xl:max-w-[100rem]"
            >
              <BlurPages />
            </div>
          </div>
        </div>
      </section>

      <motion.section
        id="terms-of-service-text-section"
        className="relative z-0 block w-full "
        initial={{ opacity: 0 }}
        transition={{ duration: 2, delay: 0.1 }}
        animate={{ opacity: 1 }}
      >
        <div
          id="terms-of-service-container-outer"
          className=" w-full px-7 pb-7 pt-16 md:px-14 lg:pb-24 lg:pt-14 xl:px-28"
        >
          <div
            id="terms-of-service-body"
            className=" mx-auto max-w-[50rem] rounded-3xl bg-[rgb(20,18,23,0.8)] p-5 [box-shadow:1px_1px_2.125rem_1.25rem_rgba(0,_0,_0,_.05)] md:p-7 lg:rounded-[2.5rem] lg:p-12"
            style={{
              opacity: 1,
              transform: "translate(0px)",
            }}
          >
            <div
              id="terms-of-service-body-content"
              className={` h-full w-full ` + styles["w-rich-text"]}
            >
              <h3
                id="header"
                className=" my-0 pb-1 text-lg font-bold leading-[116.667%] md:text-2xl lg:text-4xl"
              >
                OVERVIEW
              </h3>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                This website is managed by MADALGO. The pronouns "we", "us", and
                "our" within the context of this site all refer to MADALGO. We
                offer this website to you, the user, encompassing all the
                information, resources, and services on the site, subject to
                your agreement with all the terms, policies, conditions, and
                notices stated herein.
              </p>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                When you visit our site and/or make a purchase from us, you are
                partaking in our "Service" and agreeing to comply with the
                subsequent terms and conditions ("Terms of Service", "Terms").
                These include additional terms, conditions, and policies
                referenced herein and/or available via hyperlink. The Terms of
                Service apply universally to all site users, including but not
                limited to browsers, vendors, clients, merchants, and content
                contributors.
              </p>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                Before using our website, we urge you to carefully read these
                Terms of Service. Your consent to these Terms of Service is
                implied when you access or use any part of the site. If you do
                not agree with all the terms and conditions of this agreement,
                then you are not authorized to access the website or use any of
                the services. Should these Terms of Service be considered an
                offer, acceptance is explicitly restricted to these Terms of
                Service.
              </p>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                All new features or tools added to the current store will also
                be governed by the Terms of Service. The most recent version of
                the Terms of Service can be found on this page at any time. We
                retain the right to revise, modify, or replace any part of these
                Terms of Service by posting updates and/or changes to our
                website. It's your responsibility to check this page regularly
                for updates. Your continued usage or access to the website,
                following any posted changes, implies your acceptance of those
                changes.
              </p>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              ></p>

              <h3
                id="risk-advisory"
                className="md:text-1xl my-0  text-lg font-bold leading-[116.667%] md:text-2xl lg:text-4xl"
              >
                SECTION 1 - TERMS FOR ONLINE STORE
              </h3>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                By consenting to these Terms of Service, you declare that you
                have reached the age of majority in your state or province, or
                that you are of legal age and have granted us permission to
                allow any of your minor dependents to use this site.
              </p>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                You are prohibited from using our products for any illegal or
                unauthorized activities, or violating any laws in your
                jurisdiction (including but not limited to copyright laws)
                through your use of the Service.
              </p>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                You are not allowed to transmit any worms, viruses, or any
                destructive code.
              </p>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                Any breach or violation of the Terms will lead to an immediate
                termination of your Services.
              </p>

              <h3
                id="risk-advisory"
                className="md:text-1xl my-0  text-lg font-bold leading-[116.667%] md:text-2xl lg:text-4xl"
              >
                SECTION 2 - GENERAL CONDITIONS
              </h3>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                We maintain the right to deny service to anyone, for any reason,
                at any time.
              </p>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                You understand that your content (excluding credit card
                information), may be transmitted unencrypted and may involve (a)
                transfers over multiple networks; and (b) modifications to
                comply with and accommodate the technical demands of connecting
                networks or devices. Credit card information is always encrypted
                when transferred over networks.
              </p>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                You agree not to copy, reproduce, sell, resell, or exploit any
                aspect of the Service, the use of the Service, or access to the
                Service, or any content on the website through which the service
                is provided, without our express written permission.
              </p>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                The titles used in this agreement are provided for convenience
                only and will not limit or otherwise impact these Terms.
              </p>
              <h3
                id="risk-advisory"
                className="md:text-1xl my-0  text-lg font-bold leading-[116.667%] md:text-2xl lg:text-4xl"
              >
                Section 3 - CAUTIONS & ACKNOWLEDGEMENTS
              </h3>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                By utilizing this site or any products, services, and
                information from this site, you confirm that you have thoroughly
                read and fully understood the subsequent cautions &
                acknowledgements in this section.
              </p>
              <h4
                id="risk-content"
                className="text-base font-bold leading-[116.667%] md:text-xl lg:text-2xl"
              >
                Risk Notice
              </h4>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                Trading involves substantial risks, and the majority of day
                traders suffer financial losses. All content should be regarded
                as hypothetical, selected retrospectively to demonstrate our
                product, and should not be viewed as financial advice. Decisions
                regarding buying, selling, holding, or trading in securities,
                commodities, and other investments carry risks and are best
                guided by qualified financial professionals. Past successes do
                not assure future ones.
                <br />
                <br />
                Trading can potentially result in substantial losses. Therefore,
                you should reflect on whether trading is suitable for you
                considering your financial situation.
              </p>
              <h4
                id="risk-content"
                className="text-base font-bold leading-[116.667%] md:text-xl lg:text-2xl"
              >
                Notice on Hypothetical and Simulated Performance
              </h4>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                Hypothetical or Simulated performance results have specific
                limitations. Unlike real performance records, simulated results
                do not mirror actual trading. Additionally, since the trades
                haven't been executed, the results may be under-or-over
                compensated for certain market factors, such as liquidity
                deficiency. Simulated trading programs are generally designed
                with the benefit of hindsight. There is no guarantee that any
                account will or is likely to achieve profits or losses similar
                to those indicated.
              </p>
              <h4
                id="risk-content"
                className="text-base font-bold leading-[116.667%] md:text-xl lg:text-2xl"
              >
                Notice on Testimonials
              </h4>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                Testimonials on this website may not reflect the experiences of
                other clients or customers and don't assure future performance
                or success. Any trading results shown in testimonials are not
                verified and we have no reason to believe that the individual
                experiences represented are typical given that results can
                significantly vary due to factors like skill, risk management,
                experience, and the inherently high-risk nature of trading,
                where losses are common.
              </p>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                As a provider of technical analysis tools for charting
                platforms, we do not have access to our customers' personal
                trading accounts or brokerage statements. Consequently, we
                cannot verify if our customers perform better or worse than
                traders in general based on any content or tool we provide.
              </p>
              <h4
                id="risk-advisory"
                className=" text-base font-bold leading-[116.667%] md:text-xl lg:text-2xl"
              >
                MADALGO Is Not a Financial Adviser
              </h4>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                MADALGO is not registered as a financial adviser with the U.S.
                Securities and Exchange Commission. Instead, MADALGO relies on
                the “publisher’s exclusion” from the “investment adviser”
                definition provided under Section 202(a)(11)(D) of the
                Investment Advisers Act of 1940 and corresponding state
                regulations. Consequently, MADALGO does not offer or provide
                personalized investment advice.
              </p>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                While some content, material, information, and other offerings
                published, broadcast, or otherwise stated on this and/or
                associated websites may be perceived as "investment advice",
                such information is generic and not tailored to the investment
                requirements of any specific individual. The information
                provided is intended for informational and educational purposes
                only.
              </p>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                The information should not be viewed as investment or trading
                advice and is not intended to be an enticement or recommendation
                to buy, sell, or hold any positions in any indices or financial
                markets mentioned. The content and information provided by
                MADALGO, the Site, and MADALGO's products and services, are
                simply incidental to MADALGO's business and activities in
                providing educational tools for technical analysis.
              </p>
              <h4
                id="risk-advisory"
                className=" text-base font-bold leading-[116.667%] md:text-xl lg:text-2xl"
              >
                Information for Education Purposes Only
              </h4>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                MADALGO and the tools provided DO NOT offer or provide
                personalized investment advice. The tools, scanners, data,
                content, and information provided are generic and not tailored
                to the investment needs of any individual and are meant strictly
                for educational and informational purposes.
              </p>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                The tools & scanners MADALGO provides are designed to aid in
                researching and evaluating securities based on your individual
                criteria and inputs, which are solely controlled and entered by
                you. Usage of the tools & scanners does not guarantee any
                predictable, general, specific, or other outcomes and should not
                be the only basis for any investment decision or transaction.{" "}
                <strong>
                  YOU SHOULD NOT RELY EXCLUSIVELY ON THE TOOLS, SCANNERS, OR ANY
                  DATA, CONTENT AND INFORMATION WITHIN AS THE SOLE BASIS FOR AN
                  INVESTMENT DECISION OR TRANSACTION.
                </strong>{" "}
                If you decide to rely on our tools, scanners, or any such data,
                content, or information, you do so at your own risk and fully
                acknowledge, understand, and agree that you are solely
                responsible for your investment research and decisions. You also
                bear sole responsibility for all decisions and transactions
                related to your securities purchases and sales, the suitability,
                profitability, or appropriateness for you of any security,
                investment, financial product, investment strategy, or other
                matter, and all other matters related to your investments and
                investment strategies.
              </p>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                MADALGO will not provide you with any legal, tax, estate
                planning, or accounting advice, or any advice regarding the
                suitability, profitability, or appropriateness for you of any
                security, investment, financial product, investment strategy, or
                other matter. The data, content, and information within the
                tools & scanners should not be construed as investment or
                trading advice and is not intended to be a solicitation or
                recommendation to buy, sell, or hold any positions in any
                securities or to engage in any other transaction.
              </p>
              <h4
                id="risk-advisory"
                className=" text-base font-bold leading-[116.667%] md:text-xl lg:text-2xl"
              >
                Site Notice
              </h4>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                By accessing any material or using the information within this
                Site, you agree that it is educational material, and you will
                not hold any person or entity accountable for loss or damages
                resulting from the content or general information provided by{" "}
                <a href="https://madalgo.io">madalgo.io</a>
              </p>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                This Site cannot be held responsible for any inaccuracies or
                omissions in the information contained on this Site. We
                recommend that visitors and users seek advice from an
                independent financial advisor before buying or selling
                securities. MADALGO and all affiliated parties are not
                registered as financial advisors.
              </p>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                This Site and all individuals and entities associated with this
                Site bear no responsibility for your trading results or
                investments. Also, our partners or affiliated companies have no
                connection with the proprietary information provided by this
                Site.
              </p>
              <h4
                id="risk-advisory"
                className=" text-base font-bold leading-[116.667%] md:text-xl lg:text-2xl"
              >
                TradingView Disclosure
              </h4>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                Charts used on this site are provided by TradingView, which our
                tools are built on.
              </p>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                TradingView® is a registered trademark of TradingView, Inc.{" "}
                <a href="https://www.tradingview.com">www.TradingView.com</a>.
                TradingView has no affiliation with the owner, developer, or
                provider of the products or services described herein, or any
                interest, ownership, or otherwise, in any such product or
                service, or endorses or recommends any such product or service.
              </p>
              <h3
                id="risk-advisory"
                className="md:text-1xl my-0  text-lg font-bold leading-[116.667%] md:text-2xl lg:text-4xl"
              >
                SECTION 4 - REFUND POLICY
              </h3>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                If for any reason you are unsatisfied with our services or find
                them difficult to understand, we offer a refund within the first
                48 hours of your subscription to any MADALGO plan. To request a
                refund, please contact our support team at{" "}
                <a href="mailto:support@madalgo.io">support@madalgo.io</a>.
                Typically, it takes around 3-5 business days for the refund to
                be reflected in your bank account.
              </p>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                For annual subscription auto-renewals, we provide refunds only
                within the first 72 hours of billing. After the initial 30-day
                period, we are under no obligation to process refunds unless
                under specific circumstances, such as forgetting about your
                subscription auto-renewal. We do send reminders for the first 90
                days before each billing cycle, but managing your subscription
                prior to renewal is entirely up to you by logging in or emailing{" "}
                <a href="mailto:support@madalgo.io">support@madalgo.io</a>.
              </p>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                Please note that refunds for payments made in cryptocurrency are
                not accepted due to potential transaction fees and other related
                complexities.
              </p>
              <h3
                id="risk-advisory"
                className="md:text-1xl my-0  text-lg font-bold leading-[116.667%] md:text-2xl lg:text-4xl"
              >
                SECTION 5 - INFORMATION ACCURACY AND TIMELINESS
              </h3>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                The information provided on this site may not always be precise,
                complete or up-to-date. The materials here are for general
                information and should not solely be relied upon for
                decision-making without consulting more accurate, comprehensive,
                and timely information sources. Any dependency on the site's
                material is at your own risk.
              </p>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                Historical data on this site is for reference purposes and may
                not be current. We may update the site's content at any given
                time without obligation. It is your responsibility to monitor
                changes on our site.
              </p>
              <h3
                id="risk-advisory"
                className="md:text-1xl my-0  text-lg font-bold leading-[116.667%] md:text-2xl lg:text-4xl"
              >
                SECTION 6 - PRODUCT/SERVICE
              </h3>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                Our products or services may exclusively be available online
                through our website. We cannot guarantee that the quality of the
                products, services, or any other material purchased or obtained
                by you will match your expectations or that any service errors
                will be rectified. Any attempts to replicate our product are not
                permitted. We reserve the right to revoke your access and ban
                you from our server if suspected of any form of imitation.
              </p>
              <h3
                id="risk-advisory"
                className="md:text-1xl my-0  text-lg font-bold leading-[116.667%] md:text-2xl lg:text-4xl"
              >
                SECTION 7 - UNACCEPTABLE USAGE
              </h3>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                The Terms of Service prohibit certain uses of our site and its
                content. You are forbidden from using the site or its content
                for any illegal activities; to violate international, federal,
                provincial, state regulations, rules, laws, or local ordinances;
                to infringe our intellectual property rights or those of others;
                to submit false or misleading information; or to interfere with
                the site's security features or those of related websites. Any
                violation of these prohibitions can lead to your access to our
                service being terminated.
              </p>
              <h3
                id="risk-advisory"
                className="md:text-1xl my-0  text-lg font-bold leading-[116.667%] md:text-2xl lg:text-4xl"
              >
                SECTION 8 - WARRANTY DISCLAIMER AND LIABILITY LIMITATION
              </h3>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                We cannot assure or pledge that our service will be continuous,
                timely, safe or errorless. The accuracy or reliability of the
                outcomes derived from the service use is also not guaranteed.
              </p>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                You acknowledge and agree that your use or inability to use the
                service is solely your responsibility. The service and all
                products and services delivered to you are provided 'as is' and
                'as available', devoid of any warranties or conditions, explicit
                or implied. This includes implied warranties or conditions of
                salability, fitness for a particular purpose, durability, title,
                and non-infringement.
              </p>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                MADALGO, its directors, officers, employees, affiliates, agents,
                contractors, interns, suppliers, service providers or licensors
                shall not be held accountable for any injury, loss, or any
                direct, indirect, incidental, punitive, special, or
                consequential damages. This includes lost profits, lost revenue,
                lost savings, loss of data, replacement costs, or any alike
                damages, irrespective of whether it's based in contract, tort
                (including negligence), strict liability or otherwise. These
                damages could arise from the use of the service or any products
                purchased using the service, or for any other claim related in
                any way to your use of the service or any product. This includes
                any errors or omissions in any content, or any loss or damage
                incurred as a result of the use of the service or any content
                posted, transmitted, or otherwise made available via the
                service, even if we have been informed of the possibility of
                such damages. In jurisdictions where the exclusion or the
                limitation of liability for consequential or incidental damages
                is not permitted, our liability will be restricted to the
                fullest extent permitted by law.
              </p>
              <h3
                id="risk-advisory"
                className="md:text-1xl my-0  text-lg font-bold leading-[116.667%] md:text-2xl lg:text-4xl"
              >
                SECTION 9 - INDEMNITY
              </h3>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                You agree to indemnify and hold MADALGO, its parent company,
                subsidiaries, affiliates, partners, officers, directors, agents,
                contractors, licensors, service providers, subcontractors,
                suppliers, interns and employees harmless against any claims or
                demands. This includes reasonable attorneys' fees arising from
                your violation of these Terms of Service, any applicable law, or
                the rights of any third party.
              </p>
              <h3
                id="risk-advisory"
                className="md:text-1xl my-0  text-lg font-bold leading-[116.667%] md:text-2xl lg:text-4xl"
              >
                SECTION 10 - TERMS OF SERVICE MODIFICATIONS
              </h3>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                We reserve the right to revise, change or replace any part of
                these Terms of Service at our discretion. Updates or changes
                will be posted on our website, and it is your responsibility to
                periodically check for these changes. Your continued use of our
                website or the Service after any changes to these Terms of
                Service implies your acceptance of these changes.
              </p>
              <h3
                id="risk-advisory"
                className="md:text-1xl my-0  text-lg font-bold leading-[116.667%] md:text-2xl lg:text-4xl"
              >
                SECTION 11 - QUERY RESOLUTION
              </h3>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                Should you have any questions regarding the Terms of Service,
                please feel free to reach out to us at{" "}
                <a href="mailto:info@madalgo.io">info@madalgo.io</a>.
              </p>
            </div>
          </div>
        </div>
      </motion.section>
    </>
  );
}

export default TermsOfService;
