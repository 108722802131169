import { Routes, Route, Link, Navigate, useLocation } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import DocsHeader from "./DocsHeader";
import ThemeContext from "./ThemeContext";
import DocsSideNav from "./DocsSideNav";
import { DarkSvgIcon } from "./BrandSVG";
import DocsGetStarted from "./doc-pages/DocsGetStarted";
import styles from "./Docs.module.css";
import { useTOC } from "./TOCContext";
import DocsFooter from "./DocsFooter";
import DocsTradingView from "./doc-pages/DocsTradingView";
import DocsSetupMADALGO from "./doc-pages/DocsSetupMADALGO";
import DocsJoinDiscord from "./doc-pages/DocsJoinDiscord";
import DocsUpdatingMadalgo from "./doc-pages/DocsUpdatingMadalgo";
import DocsSettingAlerts from "./doc-pages/DocsSettingAlerts";
import DocsChangelog from "./doc-pages/DocsChangelog";
import PulseChangelog from "./doc-pages/PulseChangelog";
import DynamicsChangelog from "./doc-pages/DynamicsChangelog";
import NavigatorChangelog from "./doc-pages/NavigatorChangelog";
import DocsTechnicalAnalysis from "./doc-pages/DocsTechnicalAnalysis";
import DocsTechnicalAnalysisCharts from "./doc-pages/DocsTechnicalAnalysisCharts";
import DocsTechnicalAnalysisVolume from "./doc-pages/DocsTechnicalAnalysisVolume";
import DocsTechnicalAnalysisTrends from "./doc-pages/DocsTechnicalAnalysisTrends";
import DocsTechnicalAnalysisSupportResistance from "./doc-pages/DocsTechnicalAnalysisSupportResistance";
import DocsTechnicalAnalysisTechnicalIndicators from "./doc-pages/DocsTechnicalAnalysisTechnicalIndicators";
import DocsTechnicalAnalysisTechnicalIndicatorsMomentum from "./doc-pages/DocsTechnicalAnalysisTechnicalIndicatorsMomentum";
import DocsTechnicalAnalysisTechnicalIndicatorsTrend from "./doc-pages/DocsTechnicalAnalysisTechnicalIndicatorsTrend";
import DocsTechnicalAnalysisTechnicalIndicatorsVolatility from "./doc-pages/DocsTechnicalAnalysisTechnicalIndicatorsVolatility";
import DocsTechnicalAnalysisTechnicalIndicatorsVolume from "./doc-pages/DocsTechnicalAnalysisTechnicalIndicatorsVolume";
import DocsTradingBestPractices from "./doc-pages/DocsTradingBestPractices";
import DocsTradingBestPracticesTraderProfile from "./doc-pages/DocsTradingBestPracticesTraderProfile";
import DocsTradingBestPracticesDiversification from "./doc-pages/DocsTradingBestPracticesDiversification";
import DocsDosDonts from "./doc-pages/DocsDosDonts";
import DocsTradingBestPracticesMoneyManagement from "./doc-pages/DocsTradingBestPracticesMoneyManagement";
import DocsTradingBestPracticesEmotionalDiscipline from "./doc-pages/DocsTradingBestPracticesEmotionalDiscipline";
import DocsRecommendedBrokers from "./doc-pages/DocsRecommendedBrokers";
import DocsMADALGOPulse from "./doc-pages/DocsMADALGOPulse";
import DocsMADALGOPulseProfitBands from "./doc-pages/DocsMADALGOPulseProfitBands";
import DocsMADALGOPulseVolumeWeightedStrengthIndex from "./doc-pages/DocsMADALGOPulseVolumeWeightedStrengthIndex";
import DocsMADALGOPulseHyperTrend from "./doc-pages/DocsMADALGOPulseHyperTrend";
import DocsMADALGOPulseTrendAlertCandles from "./doc-pages/DocsMADALGOPulseTrendAlertCandles";
import DocsMADALGOPulseVolumeProfilePro from "./doc-pages/DocsMADALGOPulseVolumeProfilePro";
import DocsMADALGONavigator from "./doc-pages/DocsMADALGONavigator";
import DocsMADALGONavigatorInstitutionalLiquidutyZones from "./doc-pages/DocsMADALGONavigatorInstitutionalLiquidutyZones";
import DocsFAQ from "./doc-pages/DocsFAQ";
import DocsMADALGONavigatorDivergenceDetector from "./doc-pages/DocsMADALGONavigatorDivergenceDetector";
import DocsMADALGONavigatorTargetScope from "./doc-pages/DocsMADALGONavigatorTargetScope";
import DocsMADALGONavigatorTrendShiftClouds from "./doc-pages/DocsMADALGONavigatorTrendShiftClouds";
import DocsMADALGONavigatorTrueRangeFVG from "./doc-pages/DocsMADALGONavigatorTrueRangeFVG";
import DocsMADALGODynamics from "./doc-pages/DocsMADALGODynamics";
import DocsMADALGODynamicsInstitutionalMoneyFlow from "./doc-pages/DocsMADALGODynamicsInstitutionalMoneyFlow";
import DocsMADALGODynamicsMomentumRangeSelector from "./doc-pages/DocsMADALGODynamicsMomentumRangeSelector";
import DocsMADALGODynamicsPersistentTrendIndex from "./doc-pages/DocsMADALGODynamicsPersistentTrendIndex";
import DocsMADALGODynamicsStochastiX from "./doc-pages/DocsMADALGODynamicsStochastiX";
import DocsMADALGODynamicsWeightedPulse from "./doc-pages/DocsMADALGODynamicsWeightedPulse";
import Pagination from "./Pagination";

const docRoutes = [
  { path: "get-started/what-is-madalgo", title: "What is Madalgo?" },
  { path: "get-started/what-is-tradingview", title: "What is TradingView?" },
  { path: "get-started/setup-madalgo", title: "Setup MADALGO on TradingView" },
  {
    path: "get-started/join-madalgo-discord",
    title: "Join MADALGO In Discord",
  },
  { path: "madalgo-premium-solutions/madalgo-pulse", title: "MADALGO Pulse" },
  {
    path: "madalgo-premium-solutions/madalgo-pulse/profit-bands",
    title: "Profit Bands",
  },
  {
    path: "madalgo-premium-solutions/madalgo-pulse/volume-weighted-strength-index",
    title: "Volume-Weighted Strength Index",
  },
  {
    path: "madalgo-premium-solutions/madalgo-pulse/hyper-trend",
    title: "Hyper Trend",
  },
  {
    path: "madalgo-premium-solutions/madalgo-pulse/trend-alert-candles",
    title: "Trend Alert Candles",
  },
  {
    path: "madalgo-premium-solutions/madalgo-pulse/volume-profile-pro",
    title: "Volume Profile Pro",
  },
  {
    path: "madalgo-premium-solutions/madalgo-navigator",
    title: "MADALGO Navigator",
  },
  {
    path: "madalgo-premium-solutions/madalgo-navigator/institutional-liquidity-zones",
    title: "Institutional Liquiduty Zones",
  },
  {
    path: "madalgo-premium-solutions/madalgo-navigator/divergence-detector",
    title: "Divergence Detector",
  },
  {
    path: "madalgo-premium-solutions/madalgo-navigator/target-scope",
    title: "TargetScope",
  },
  {
    path: "madalgo-premium-solutions/madalgo-navigator/trendshift-clouds",
    title: "TrendShift Clouds",
  },
  {
    path: "madalgo-premium-solutions/madalgo-navigator/true-range-fvg",
    title: "True Range Fair Value Gaps (TR FVG)",
  },
  {
    path: "madalgo-premium-solutions/madalgo-dynamics",
    title: "MADALGO Dynamics",
  },
  {
    path: "madalgo-premium-solutions/madalgo-dynamics/institutional-money-flow",
    title: "Institutional Money Flow (IMF)",
  },
  {
    path: "madalgo-premium-solutions/madalgo-dynamics/momentum-range-selector",
    title: "Momentum Range Selector (MRS)",
  },
  {
    path: "madalgo-premium-solutions/madalgo-dynamics/persistent-trend-index",
    title: "Persistent Trend Index (PTI)",
  },
  {
    path: "madalgo-premium-solutions/madalgo-dynamics/stochastix",
    title: "StochastiX",
  },
  {
    path: "madalgo-premium-solutions/madalgo-dynamics/weighted-pulse",
    title: "Weighted Pulse",
  },
  {
    path: "madalgo-premium-solutions/updating-madalgo",
    title: "How To Update",
  },
  {
    path: "madalgo-premium-solutions/setting-alerts",
    title: "How To Set Alerts",
  },
  {
    path: "madalgo-premium-solutions/changelogs",
    title: "Changelogs",
  },
  {
    path: "madalgo-premium-solutions/changelogs/madalgo-premium-pulse",
    title: "Changelogs - MADALGO Pulse",
  },
  {
    path: "madalgo-premium-solutions/changelogs/madalgo-premium-navigator",
    title: "Changelogs - MADALGO Navigator",
  },
  {
    path: "madalgo-premium-solutions/changelogs/madalgo-premium-dynamics",
    title: "Changelogs - MADALGO Dynamics",
  },
  {
    path: "technical-analysis",
    title: "Technical Analysis Introduction",
  },
  {
    path: "technical-analysis/charts",
    title: "Technical Analysis - Charts",
  },
  {
    path: "technical-analysis/volume",
    title: "Technical Analysis - Volume",
  },
  {
    path: "technical-analysis/support-resistance",
    title: "Technical Analysis - Support & Resistance",
  },
  {
    path: "technical-analysis/trends",
    title: "Technical Analysis - Trends",
  },
  {
    path: "technical-analysis/technical-indicators",
    title: "Technical Analysis - Technical Indicators",
  },
  {
    path: "technical-analysis/technical-indicators/momentum-indicators",
    title: "Momentum Indicators",
  },
  {
    path: "technical-analysis/technical-indicators/trend-indicators",
    title: "Trend Indicators",
  },
  {
    path: "technical-analysis/technical-indicators/volatility-indicators",
    title: "Volatility Indicators",
  },
  {
    path: "technical-analysis/technical-indicators/volume-indicators",
    title: "Volume Indicators",
  },
  {
    path: "best-practices",
    title: "Best Practices - Introduction",
  },
  {
    path: "best-practices/trader-profile",
    title: "Your Trader Profile",
  },
  {
    path: "best-practices/diversification",
    title: "Diversification",
  },
  {
    path: "best-practices/money-management",
    title: "Money Management",
  },
  {
    path: "best-practices/emotional-discipline",
    title: "Emotional Discipline",
  },
  {
    path: "best-practices/dos-and-donts",
    title: "Do's & Don'ts",
  },
  {
    path: "best-practices/recommended-brokers",
    title: "Recommended Brokers",
  },
];

export default function Docs() {
  const { theme } = useContext(ThemeContext);
  const [showButton, setShowButton] = useState(false);
  const [lastScrollPos, setLastScrollPos] = useState(0);
  const buttonClass = showButton ? styles["back-to-top-show"] : "";
  const { tocEntries } = useTOC();
  const [activeLink, setActiveLink] = useState(null);
  const [activeSubLink, setActiveSubLink] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const index = docRoutes.findIndex(
      (route) => location.pathname === `/docs/${route.path}`,
    );
    console.log("Current Index:", index); // Debugging line
    setCurrentIndex(index);
  }, [location]);

  useEffect(() => {
    document.body.style.backgroundColor =
      theme === "light" ? "white" : "#0a090d";
    return () => {
      document.body.style.backgroundColor = "";
    };
  }, [theme]);

  useEffect(() => {
    document.body.className = theme; // 'light' or 'dark'
    return () => {
      document.body.className =
        "antialiased dark:text-slate-400 bg-[#0a090d] text-white m-0 text-base font-normal leading-[120%] z-0";
    };
  }, [theme]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;

      if (currentScrollPos < lastScrollPos && currentScrollPos > 10) {
        setShowButton(true);
      } else if (currentScrollPos > lastScrollPos) {
        setShowButton(false);
      }

      setLastScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollPos]);

  useEffect(() => {
    const onScroll = () => {
      let activeMainEntry = null;
      let activeSubEntry = null;

      // Check main entries
      for (let i = 0; i < tocEntries.length; i++) {
        const entry = tocEntries[i];
        const el = document.getElementById(entry.urlFragment);

        if (
          el &&
          el.getBoundingClientRect().top <= 200 &&
          el.getBoundingClientRect().top > 0
        ) {
          activeMainEntry = entry.urlFragment;
        }

        // Check sub-entries
        if (entry.subEntries) {
          for (let j = 0; j < entry.subEntries.length; j++) {
            const subEntry = entry.subEntries[j];
            const subEl = document.getElementById(subEntry.urlFragment);

            if (
              subEl &&
              subEl.getBoundingClientRect().top <= 400 &&
              subEl.getBoundingClientRect().top > 0
            ) {
              activeSubEntry = subEntry.urlFragment;

              break;
            }
          }
        }

        if (activeSubEntry) break;
      }

      setActiveLink(activeSubEntry ? null : activeMainEntry);
      setActiveSubLink(activeSubEntry);
    };

    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [tocEntries]);

  return (
    <>
      <DocsHeader />
      <div id="docs-main-wrapper" className="flex flex-[1_0_auto] text-black">
        <div id="page-content" className="flex w-full">
          <button
            className={`${styles["back-to-top"]} ${buttonClass}`}
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          ></button>
          <aside
            id="sidebar-container"
            className="mt-[calc(3.75rem*-1)] hidden w-[300px] border-gray-300 transition-[width] duration-200 ease-in will-change-[width] [border-right:1px_solid] [clip-path:inset(0)] lg:block"
          >
            <div
              id="sidebar-wrapper"
              className={`sticky top-0 flex h-full max-h-screen w-[300px] flex-col pt-0 transition-opacity duration-[50ms]
            ${theme === "light" ? "bg-slate-100" : "bg-[#111114]"}
            `}
            >
              <Link
                to="/docs"
                id="sidebar-logo"
                className="m-[0_.6rem] mb-2 flex max-h-[3.75rem] min-h-[3.75rem] items-center [color:inherit] [text-decoration:none]"
              >
                <DarkSvgIcon theme={theme} />
                <div className="ml-2 mt-[.2rem] text-white">
                  <svg
                    className="svg-header-logo [transition:all_.15s_ease-in-out]"
                    version="1.1"
                    id="madalgo"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="82"
                    height="14.1667"
                    fill={`${theme === "light" ? "#000" : "#fff"}`}
                    viewBox="0 0 163 24"
                    xmlSpace="preserve"
                  >
                    <g>
                      <g>
                        <path d="M39.2,12.1H28.9V3.4h-3.4v16.9h3.4v-4.8h10.3V24h3.4V3.4h-3.4V12.1z" />
                        <path d="M39.2,3.4V0H28.9v3.4H39.2z" />
                      </g>
                      <g>
                        <path d="M123.8,19.9h4V4.1h-4V19.9z" />
                        <path d="M138.9,19.9h4V4.1h-4V19.9z" />
                        <path d="M139,4.1V0h-11.3v4.1H139z" />
                        <path d="M139,24v-4.1h-11.3V24H139z" />
                      </g>
                      <path d="M0.1,24h3.4V3.4H0.1V24z" />
                      <path d="M9,10.8h3.4V2.7H9V10.8z" />
                      <path d="M18,20.5h3.4V3.4H18V20.5z" />
                      <path d="M19.1,3.5V0H2.3v3.4L19.1,3.5L19.1,3.5z" />
                      <path d="M92.5,20.5V3.2h-3.4V24h12.1v-3.5H92.5z" />
                      <path d="M79,0h-3.8l-8.7,24h3.7l2-5.6h9.9l2,5.6h3.7L79.1,0.2L79,0z M73.3,15.3l3.9-11l3.8,11H73.3z" />
                      <path
                        d="M54.8,0h-5.2v6.9H53V3.4h1.7c4.7,0,8.6,3.8,8.6,8.6c0,4.7-3.8,8.6-8.6,8.6h-5.2V6.9h-3.3V24h8.6c6.6,0,12-5.4,12-12
	S61.4,0,54.8,0z"
                      />
                      <g>
                        <path d="M112.3,1.4c4.3,0,6.9,2,8.8,4.5l-2.8,1.6c-1.3-1.8-3.4-3.1-6-3.1" />
                        <path
                          d="M121.6,18.6c0,2-5.4,5.4-9.3,5.4c-6.6,0-11.9-4.8-11.9-12s5.3-12,11.9-12v3.1c-4.8,0-8.4,3.7-8.4,8.9s3.6,9,8.4,9
		c2.6,0,4.7-1.2,5.8-2.3"
                        />
                        <path d="M117.1,18.7v-2.8h-4.2v-3h7.1c0.3,0,0.5,0.2,0.5,0.5v5.3" />
                      </g>
                    </g>
                  </svg>
                </div>
              </Link>
              <nav
                id="docs-side-nav-main"
                className={`flex-grow overflow-x-hidden p-2 font-semibold [-webkit-scrollbar-width:13px] [scrollbar-width:thin]
              ${theme === "light" ? "bg-slate-100" : "bg-[#111114]"}
              `}
              >
                <DocsSideNav />
              </nav>
            </div>
          </aside>
          <main id="doc-main-container" className="flex w-full">
            <div
              id="main-container"
              className=" m-[0_auto] w-full max-w-6xl p-[0_1rem] pb-8 pt-4"
            >
              <div
                id="row-container"
                className="m-[0_calc(1rem_*-1)] flex flex-row flex-wrap"
              >
                <div
                  id="col-container"
                  className={`ml-0 w-full flex-[1_0] p-[0_1rem] lg:max-w-[75%]
                  ${theme === "light" ? "text-slate-800" : "text-zinc-300"}
                  `}
                >
                  <div id="doc-item-container" className="">
                    <article>
                      <div
                        id="markdown-content"
                        className={`lg:m-10 ${styles.markdown}`}
                      >
                        <Routes>
                          <Route
                            path="/"
                            element={
                              <Navigate
                                to="get-started/what-is-madalgo"
                                replace
                              />
                            }
                          />
                          <Route
                            path="tutorials"
                            element={
                              <Navigate
                                to="/docs/madalgo-premium-solutions/madalgo-pulse"
                                replace
                              />
                            }
                          />
                          <Route path="faq" element={<DocsFAQ />} />
                          <Route
                            path="get-started/what-is-madalgo"
                            element={<DocsGetStarted />}
                          />
                          <Route
                            path="get-started/what-is-tradingview"
                            element={<DocsTradingView />}
                          />
                          <Route
                            path="get-started/setup-madalgo"
                            element={<DocsSetupMADALGO />}
                          />
                          <Route
                            path="get-started/join-madalgo-discord"
                            element={<DocsJoinDiscord />}
                          />
                          <Route
                            path="madalgo-premium-solutions/madalgo-pulse"
                            element={<DocsMADALGOPulse />}
                          />
                          <Route
                            path="madalgo-premium-solutions/madalgo-pulse/profit-bands"
                            element={<DocsMADALGOPulseProfitBands />}
                          />
                          <Route
                            path="madalgo-premium-solutions/madalgo-pulse/volume-weighted-strength-index"
                            element={
                              <DocsMADALGOPulseVolumeWeightedStrengthIndex />
                            }
                          />
                          <Route
                            path="madalgo-premium-solutions/madalgo-pulse/hyper-trend"
                            element={<DocsMADALGOPulseHyperTrend />}
                          />
                          <Route
                            path="madalgo-premium-solutions/madalgo-pulse/trend-alert-candles"
                            element={<DocsMADALGOPulseTrendAlertCandles />}
                          />
                          <Route
                            path="madalgo-premium-solutions/madalgo-pulse/volume-profile-pro"
                            element={<DocsMADALGOPulseVolumeProfilePro />}
                          />
                          <Route
                            path="madalgo-premium-solutions/madalgo-navigator"
                            element={<DocsMADALGONavigator />}
                          />
                          <Route
                            path="madalgo-premium-solutions/madalgo-navigator/institutional-liquidity-zones"
                            element={
                              <DocsMADALGONavigatorInstitutionalLiquidutyZones />
                            }
                          />
                          <Route
                            path="madalgo-premium-solutions/madalgo-navigator/divergence-detector"
                            element={<DocsMADALGONavigatorDivergenceDetector />}
                          />
                          <Route
                            path="madalgo-premium-solutions/madalgo-navigator/target-scope"
                            element={<DocsMADALGONavigatorTargetScope />}
                          />
                          <Route
                            path="madalgo-premium-solutions/madalgo-navigator/trendshift-clouds"
                            element={<DocsMADALGONavigatorTrendShiftClouds />}
                          />
                          <Route
                            path="madalgo-premium-solutions/madalgo-navigator/true-range-fvg"
                            element={<DocsMADALGONavigatorTrueRangeFVG />}
                          />
                          <Route
                            path="madalgo-premium-solutions/madalgo-dynamics"
                            element={<DocsMADALGODynamics />}
                          />
                          <Route
                            path="madalgo-premium-solutions/madalgo-dynamics/institutional-money-flow"
                            element={
                              <DocsMADALGODynamicsInstitutionalMoneyFlow />
                            }
                          />
                          <Route
                            path="madalgo-premium-solutions/madalgo-dynamics/momentum-range-selector"
                            element={
                              <DocsMADALGODynamicsMomentumRangeSelector />
                            }
                          />
                          <Route
                            path="madalgo-premium-solutions/madalgo-dynamics/persistent-trend-index"
                            element={
                              <DocsMADALGODynamicsPersistentTrendIndex />
                            }
                          />
                          <Route
                            path="madalgo-premium-solutions/madalgo-dynamics/stochastix"
                            element={<DocsMADALGODynamicsStochastiX />}
                          />
                          <Route
                            path="madalgo-premium-solutions/madalgo-dynamics/weighted-pulse"
                            element={<DocsMADALGODynamicsWeightedPulse />}
                          />

                          <Route
                            path="madalgo-premium-solutions/updating-madalgo"
                            element={<DocsUpdatingMadalgo />}
                          />
                          <Route
                            path="madalgo-premium-solutions/setting-alerts"
                            element={<DocsSettingAlerts />}
                          />
                          <Route
                            path="changelog"
                            element={
                              <Navigate
                                to="/docs/madalgo-premium-solutions/changelogs"
                                replace
                              />
                            }
                          />
                          <Route
                            path="madalgo-premium-solutions/changelogs"
                            element={<DocsChangelog />}
                          />
                          <Route
                            path="madalgo-premium-solutions/changelogs/madalgo-premium-pulse"
                            element={<PulseChangelog />}
                          />
                          <Route
                            path="madalgo-premium-solutions/changelogs/madalgo-premium-navigator"
                            element={<NavigatorChangelog />}
                          />
                          <Route
                            path="madalgo-premium-solutions/changelogs/madalgo-premium-dynamics"
                            element={<DynamicsChangelog />}
                          />
                          <Route
                            path="technical-analysis"
                            element={<DocsTechnicalAnalysis />}
                          />
                          <Route
                            path="technical-analysis/charts"
                            element={<DocsTechnicalAnalysisCharts />}
                          />
                          <Route
                            path="technical-analysis/volume"
                            element={<DocsTechnicalAnalysisVolume />}
                          />
                          <Route
                            path="technical-analysis/trends"
                            element={<DocsTechnicalAnalysisTrends />}
                          />
                          <Route
                            path="technical-analysis/support-resistance"
                            element={<DocsTechnicalAnalysisSupportResistance />}
                          />
                          <Route
                            path="technical-analysis/technical-indicators"
                            element={
                              <DocsTechnicalAnalysisTechnicalIndicators />
                            }
                          />
                          <Route
                            path="technical-analysis/technical-indicators/momentum-indicators"
                            element={
                              <DocsTechnicalAnalysisTechnicalIndicatorsMomentum />
                            }
                          />
                          <Route
                            path="technical-analysis/technical-indicators/trend-indicators"
                            element={
                              <DocsTechnicalAnalysisTechnicalIndicatorsTrend />
                            }
                          />
                          <Route
                            path="technical-analysis/technical-indicators/volatility-indicators"
                            element={
                              <DocsTechnicalAnalysisTechnicalIndicatorsVolatility />
                            }
                          />
                          <Route
                            path="technical-analysis/technical-indicators/volume-indicators"
                            element={
                              <DocsTechnicalAnalysisTechnicalIndicatorsVolume />
                            }
                          />
                          <Route
                            path="best-practices"
                            element={<DocsTradingBestPractices />}
                          />
                          <Route
                            path="best-practices/trader-profile"
                            element={<DocsTradingBestPracticesTraderProfile />}
                          />
                          <Route
                            path="best-practices/diversification"
                            element={
                              <DocsTradingBestPracticesDiversification />
                            }
                          />
                          <Route
                            path="best-practices/money-management"
                            element={
                              <DocsTradingBestPracticesMoneyManagement />
                            }
                          />
                          <Route
                            path="best-practices/emotional-discipline"
                            element={
                              <DocsTradingBestPracticesEmotionalDiscipline />
                            }
                          />

                          <Route
                            path="best-practices/dos-and-donts"
                            element={<DocsDosDonts />}
                          />
                          <Route
                            path="best-practices/recommended-brokers"
                            element={<DocsRecommendedBrokers />}
                          />
                        </Routes>
                        <Pagination
                          docRoutes={docRoutes}
                          currentIndex={currentIndex}
                        />
                      </div>
                    </article>
                  </div>
                </div>
                <div
                  id="col-container"
                  className="ml-0 hidden w-full max-w-[25%] flex-[0_0_25%] p-[0_1rem] lg:flex"
                >
                  <div
                    id="table-of-contents"
                    className="sticky top-[4.75rem] max-h-[calc(100vh_-_3.75rem_-_2rem)] overflow-y-auto [scrollbar-width:thin]"
                  >
                    <ul
                      id="table-of-contents-list"
                      className=" m-[0_0_1rem] mb-0 list-none border-l border-solid border-slate-300 p-[.5rem_0] pl-2 text-xs"
                    >
                      {tocEntries.map((entry, index) => (
                        <li className="m-[.5rem_.5rem]" key={index}>
                          <a
                            className={`transition-colors duration-200 [text-decoration:none] [transition-timing-function:cubic-bezier(.08,.52,.52,1)] ${
                              theme === "light"
                                ? "hover:text-blue-800"
                                : "hover:text-purple-400"
                            } ${
                              entry.urlFragment === activeLink
                                ? `${
                                    theme === "light"
                                      ? "text-blue-800"
                                      : "text-purple-400"
                                  }`
                                : `${
                                    theme === "light"
                                      ? "text-gray-500"
                                      : "text-gray-200"
                                  }`
                            }`}
                            href={`#${entry.urlFragment}`}
                          >
                            {entry.displayText}
                          </a>
                          {entry.subEntries && entry.subEntries.length > 0 && (
                            <ul className="list-none pl-2">
                              {entry.subEntries.map((subEntry, subIndex) => (
                                <li className=" m-[.5rem_.5rem]" key={subIndex}>
                                  <a
                                    className={`500 transition-colors duration-200 [text-decoration:none] [transition-timing-function:cubic-bezier(.08,.52,.52,1)] ${
                                      theme === "light"
                                        ? "hover:text-blue-800"
                                        : "hover:text-purple-400"
                                    } ${
                                      subEntry.urlFragment === activeSubLink
                                        ? `${
                                            theme === "light"
                                              ? "text-blue-800"
                                              : "text-purple-400"
                                          }`
                                        : `${
                                            theme === "light"
                                              ? "text-gray-500"
                                              : "text-gray-200"
                                          }`
                                    }`}
                                    href={`#${subEntry.urlFragment}`}
                                  >
                                    {subEntry.displayText}
                                  </a>
                                </li>
                              ))}
                            </ul>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <DocsFooter />
    </>
  );
}
