export const createSubscriptionPlan = /* GraphQL */ `
  mutation CreateSubscriptionPlan($input: CreateSubscriptionPlanInput!) {
    createSubscriptionPlan(input: $input) {
      id
      userID
      planName
      billingCycle
      stripeCustomerId
    }
  }
`;

export const updateSubscriptionPlan = /* GraphQL */ `
  mutation UpdateSubscriptionPlan($input: UpdateSubscriptionPlanInput!) {
    updateSubscriptionPlan(input: $input) {
      id
      userID
      planName
      billingCycle
      stripeCustomerId
    }
  }
`;
