import { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import styles from "../Docs.module.css";
import { useTOC } from "../TOCContext";
import DocImages from "../DocImages";
import ThemeContext from "../ThemeContext";
import DocsTabContainer from "./DocsTabContainer";

const entries = [
  {
    displayText: "Line Chart",
    urlFragment: "line-chart",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "Candlestick Chart",
    urlFragment: "candlestick-chart",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "Heikin-Ashi Candlesticks",
    urlFragment: "heikin-ashi-candlesticks",
    headingType: "h2",
    subEntries: [],
  },
];
export { entries };

export default function DocsTechnicalAnalysisCharts() {
  const { addToTOC, removeFromTOC } = useTOC();
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    entries.forEach(addToTOC);
    return () => {
      entries.forEach((entry) => removeFromTOC(entry));
    };
  }, [addToTOC, removeFromTOC]);

  return (
    <>
      <h1 className={styles.h1}>Charts</h1>
      <p className={styles.p}>
        For a technical analyst, viewing historical market data is paramount,
        and this is achieved through trading charts. Such charts present various
        data, predominantly market prices, volume, and technical indicators.
      </p>
      <p className={styles.p}>
        Here are some of the most frequently used trading charts
      </p>
      <h2 id="line-chart" className={`scroll-mt-2 ${styles["h2"]}`}>
        Line Chart
        <Link
          to="#line-chart"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        This is the most basic chart form, showing closing prices over time as a
        singular, unbroken line.
      </p>
      <figure className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[33]}
          width={1441}
          height={1048}
          className="mx-auto"
          alt="Line Chart"
        />
        <figcaption
          style={{
            textAlign: "center",
            fontSize: 14,
          }}
        >
          Typical line chart
        </figcaption>
      </figure>
      <h2 id="candlestick-chart" className={`scroll-mt-2 ${styles["h2"]}`}>
        Candlestick Chart
        <Link
          to="#candlestick-chart"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        Candlestick charts are predominantly favored by traders. In contrast to
        line charts which typically show a sequence of closing prices,
        candlestick charts depict candles that provide information on the
        opening, high, low, and closing prices.
      </p>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={theme === "light" ? DocImages[34] : DocImages[39]}
          width={444}
          height={408}
          className="mx-auto"
          alt="Candlesticks"
        ></img>
      </p>
      <p className={styles.p}>
        The segment between the opening and closing price is termed the body of
        the candle, whereas the slender lines linking the high/low prices are
        known as wicks or shadows.
      </p>
      <p className={styles.p}>
        A bullish candle emerges when the closing price exceeds the opening
        price, whereas a bearish candle appears when the closing price is
        beneath the opening price.
      </p>
      <figure className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[35]}
          width={1505}
          height={1048}
          className="mx-auto"
          alt="Candlestick Chart"
        />
        <figcaption
          style={{
            textAlign: "center",
            fontSize: 14,
          }}
        >
          Candlestick chart
        </figcaption>
      </figure>
      <h2
        id="heikin-ashi-candlesticks"
        className={`scroll-mt-2 ${styles["h2"]}`}
      >
        Heikin-Ashi Candlesticks
        <Link
          to="#heikin-ashi-candlesticks"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        These candlesticks offer a refined version of the traditional
        candlesticks, filtering out abrupt price changes and hence, simplifying
        analysis.
      </p>
      <div
        className={`
        info info-caution selection:bg-[#c4f6ff] selection:text-[#2c2c2c49]
        ${theme === "light" ? styles.danger : styles.dangerDark} 
        `}
      >
        <div className="info-heading">
          <h5 className={styles.h5}>
            <span
              className={`
                ${
                  theme === "light"
                    ? styles.dangerAlert
                    : styles.dangerAlertDark
                }
              `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 14 16"
              >
                <path
                  fillRule="evenodd"
                  d="M5.05.31c.81 2.17.41 3.38-.52 4.31C3.55 5.67 1.98 6.45.9 7.98c-1.45 2.05-1.7 6.53 3.53 7.7-2.2-1.16-2.67-4.52-.3-6.61-.61 2.03.53 3.33 1.94 2.86 1.39-.47 2.3.53 2.27 1.67-.02.78-.31 1.44-1.13 1.81 3.42-.59 4.78-3.42 4.78-5.56 0-2.84-2.53-3.22-1.25-5.61-1.52.13-2.03 1.13-1.89 2.75.09 1.08-1.02 1.8-1.86 1.33-.67-.41-.66-1.19-.06-1.78C8.18 5.31 8.68 2.45 5.05.32L5.03.3l.02.01z"
                ></path>
              </svg>
            </span>
            Warning
          </h5>
        </div>
        <div className="warning-content">
          <p>
            It's vital to understand that heikin-ashi candles don't represent
            actual market price values. It's not advisable to backtest
            strategies using heikin-ashi since the outcomes could be skewed.
            Further details can be found{" "}
            <a href="https://www.tradingview.com/support/solutions/43000481029/">
              here.
            </a>
          </p>
        </div>
      </div>

      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[36]}
          width={1505}
          height={1048}
          className="mx-auto"
          alt="Heikin Ashi Candles"
        ></img>
      </p>
      <p className={styles.p}>
        The values presented by heikin-ashi candles are computed as follows:
      </p>
      <DocsTabContainer />

      <p className={styles.p}>
        TradingView enables users to observe the genuine opening and closing
        prices on a heikin-ashi chart by choosing the Show real prices on price
        scale option (instead of the heikin-ashi price) within the chart
        preferences.
      </p>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[37]}
          width={987}
          height={180}
          className="mx-auto"
          alt="Show Real Prices Heikin Ashi"
        ></img>
      </p>
      <p className={styles.p}>
        Due to the smoother characteristic of heikin-ashi charts, indicators
        applied to them often yield fewer false signals.
      </p>
    </>
  );
}
