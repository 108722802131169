import React, { memo } from "react";

function SymbolInfoWidget({ containerSI }) {
  return (
    <div
      className="tradingview-widget-container h-full w-full"
      ref={containerSI}
    >
      <div
        className="tradingview-widget-container__widget"
        style={{ height: "100%", width: "100%" }}
      ></div>
    </div>
  );
}

export default memo(SymbolInfoWidget);
