import React from "react";
import BlurPages from "./BlurPages";
import styles from "./Main.module.css";
import { motion } from "framer-motion";

function PrivacyPolicy() {
  return (
    <>
      <section
        id="heading-disclaimer-section"
        className="relative z-0 block w-full"
      >
        <div
          id="inner-disclaimer-container"
          className="w-full px-7 pb-9 pt-[10rem] md:px-14 md:pb-[6.25rem] lg:pt-[15.125rem] xl:px-28"
        >
          <div id="header-container" className="relative z-[1] text-center">
            <motion.h1
              id="disclaimer-heading"
              className=" text-5xl md:text-[5rem] lg:text-[7.5rem]"
              initial={{ opacity: 0 }}
              transition={{ duration: 2 }}
              animate={{ opacity: 1 }}
            >
              Privacy Policy
            </motion.h1>
          </div>
          <div
            id="blur"
            className=" absolute bottom-[0%] left-[0%] right-[0%] top-[0%] z-0 block"
          >
            <div
              id="gradient-blur-group-1"
              className="lg:w[85vw] absolute bottom-0 left-auto right-[-6.6rem] top-auto h-[100vw] w-[95vw] opacity-60 filter-none md:-bottom-80 md:w-screen lg:right-auto lg:h-[85vw] xl:-bottom-[30rem] xl:left-[5.7rem] xl:max-h-[100rem] xl:max-w-[100rem]"
            >
              <BlurPages />
            </div>
          </div>
        </div>
      </section>

      <motion.section
        id="disclaimer-text-section"
        className="relative z-0 block w-full "
        initial={{ opacity: 0 }}
        transition={{ duration: 2, delay: 0.1 }}
        animate={{ opacity: 1 }}
      >
        <div
          id="disclaimer-container-outer"
          className=" w-full px-7 pb-7 pt-16 md:px-14 lg:pb-24 lg:pt-14 xl:px-28"
        >
          <div
            id="disclaimer-body"
            className=" mx-auto max-w-[50rem] rounded-3xl bg-[rgb(20,18,23,0.8)] p-5 [box-shadow:1px_1px_2.125rem_1.25rem_rgba(0,_0,_0,_.05)] md:p-7 lg:rounded-[2.5rem] lg:p-12"
            style={{
              opacity: 1,
              transform: "translate(0px)",
            }}
          >
            <div
              id="disclaimer-body-content"
              className={` h-full w-full ` + styles["w-rich-text"]}
            >
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                This Privacy Policy outlines the manner in which we gather,
                utilize, and share your personal information when you access or
                make a purchase from madalgo.io (the “Website”).
              </p>
              <h3
                id="header"
                className=" my-0 pb-1 text-lg font-bold leading-[116.667%] md:text-2xl lg:text-4xl"
              >
                Personal Data we gather
              </h3>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                When you access the Website, we automatically gather specific
                data about your device, including details about your web
                browser, IP address, time zone, and some of the cookies
                installed on your device. Additionally, as you navigate through
                the Website, we collect information about the individual web
                pages or products that you view, the websites or search terms
                that led you to our Website, and information about your
                interaction with the Website. This information gathered
                automatically is referred to as “Device Information”.
              </p>
              <h3
                id="risk-heading-content"
                className=" my-0 pb-1 text-lg font-bold leading-[116.667%] md:text-2xl lg:text-4xl"
              >
                We collect Device Information using the following methods:
              </h3>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                - “Cookies” are data files stored on your device or computer,
                often containing an anonymous unique identifier. To learn more
                about cookies and how to disable them, visit
                http://www.allaboutcookies.org.
              </p>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                - “Log files” keep track of actions occurring on the Website,
                and collect data such as your IP address, browser type, Internet
                service provider, referring/exit pages, and date/time stamps.
              </p>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                - “Web beacons”, “tags”, and “pixels” are electronic files used
                to record data about your browsing activity on the Website.
              </p>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                When you make a purchase or attempt a purchase through the
                Website, we collect certain information from you, including your
                name, payment information (including credit card numbers), email
                address, and phone number. This information is referred to as
                “Order Information”.
              </p>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                In this Privacy Policy, “Personal Information” encompasses both
                Device Information and Order Information.
              </p>
              <h3
                id="risk-heading-content"
                className=" my-0 pb-1 text-lg font-bold leading-[116.667%] md:text-2xl lg:text-4xl"
              >
                Google OAuth for User Authentication:
              </h3>
              <p
                id="google-oauth-p-content"
                className="m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                We utilize Google OAuth to allow users to create an account or
                checkout on our platform. By choosing to sign in with Google,
                you grant us permission to access and store your Google profile
                information. This data includes your name, email address,
                profile picture, and any other information you have made public
                on your Google profile. We use this information to streamline
                the registration process, personalize your experience, and
                ensure the security of your account. We do not share, sell, or
                distribute your Google user data with third parties for their
                promotional purposes. Your data's use, access, and storage are
                solely for the purpose of enhancing your user experience and
                ensuring the seamless operation of our platform. You have the
                option to revoke our access to your Google data by modifying the
                settings in your Google account.
              </p>
              <h3
                id="risk-heading-content"
                className=" my-0 pb-1 text-lg font-bold leading-[116.667%] md:text-2xl lg:text-4xl"
              >
                Subscription Plan Creation:
              </h3>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                Upon signing in, we also create a new subscription plan based on
                your selected plan and billing cycle. Your preferences for the
                plan name and billing cycle are temporarily stored in local
                storage to facilitate the creation of the subscription plan and
                are removed post-processing.
              </p>
              <h3
                id="risk-heading-content"
                className=" my-0 pb-1 text-lg font-bold leading-[116.667%] md:text-2xl lg:text-4xl"
              >
                Cookie Management:
              </h3>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                Post authentication, we set cookies on your device to manage
                your session. These cookies store your session token and access
                token, which are essential for maintaining your session and
                ensuring the security of your account.
              </p>
              <h3
                id="risk-heading-content"
                className=" my-0 pb-1 text-lg font-bold leading-[116.667%] md:text-2xl lg:text-4xl"
              >
                Data Storage:
              </h3>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                All collected data, including your Google profile information,
                Stripe customer ID, and subscription plan details, are securely
                stored and managed within AWS (Amazon Web Services). AWS
                provides robust, secure, and scalable infrastructure to ensure
                the safety and privacy of your data.
              </p>
              <h3
                id="risk-heading-content"
                className=" my-0 pb-1 text-lg font-bold leading-[116.667%] md:text-2xl lg:text-4xl"
              >
                How do we utilize your Personal Information?
              </h3>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                We generally use the Order Information collected to fulfill any
                orders placed through the Website.
                <br />
                <br />
                In addition, we use this Order Information to:
              </p>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                - Communicate with you
              </p>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                - Screen our orders for potential risks or fraud
              </p>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                - In accordance with your shared preferences, provide you with
                information or advertisements related to our products or
                services.
              </p>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                - Display information using third-party apps on our website.
              </p>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                - Set up your accounts with our products.
              </p>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                We use the Device Information collected to help us screen for
                potential risks and fraud (particularly, your IP address), and
                generally to enhance and optimize our Website (for instance, by
                generating analytics about how our customers browse and interact
                with the Website, and assessing the effectiveness of our
                marketing and advertising campaigns).
              </p>
              <h3
                id="risk-heading-content"
                className=" my-0 pb-1 text-lg font-bold leading-[116.667%] md:text-2xl lg:text-4xl"
              >
                Sharing your personal Information
              </h3>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                We share your Personal Information with third parties to assist
                us in utilizing your Personal Information, as outlined above.
                This includes sharing information with payment processing
                platforms and cloud service providers for secure data storage
                and management.
              </p>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                We also use Google Analytics to understand how our customers
                utilize the Website. You can read more about how Google uses
                your Personal Information here:
                https://www.google.com/intl/en/policies/privacy/. You can
                opt-out of Google Analytics here:
                https://tools.google.com/dlpage/gaoptout.
              </p>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                Lastly, we may share your Personal Information to comply with
                applicable laws and regulations, to respond to a subpoena,
                search warrant or other lawful request for information we
                receive, or to safeguard our rights.
              </p>
              <h3
                id="risk-heading-content"
                className=" my-0 pb-1 text-lg font-bold leading-[116.667%] md:text-2xl lg:text-4xl"
              >
                Do not track
              </h3>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                Please be aware that we do not modify our Website’s data
                collection and usage practices when we detect a Do Not Track
                signal from your browser.
              </p>
              <h3
                id="risk-heading-content"
                className=" my-0 pb-1 text-lg font-bold leading-[116.667%] md:text-2xl lg:text-4xl"
              >
                Your rights
              </h3>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                If you are a resident of Europe, you possess the right to access
                the personal information we maintain about you and to request
                that your personal information be corrected, updated, or
                deleted. If you wish to exercise this right, please reach out to
                us using the contact information below.
              </p>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                Furthermore, if you are a European resident, we inform you that
                we process your information to fulfill any contracts we might
                have with you (for example, if you place an order through the
                Website), or otherwise to pursue our legitimate business
                interests listed above.
              </p>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                Please note that your information will be transferred outside of
                Europe, including to Canada and the United States.
              </p>
              <h3
                id="risk-heading-content"
                className=" my-0 pb-1 text-lg font-bold leading-[116.667%] md:text-2xl lg:text-4xl"
              >
                Residents of California
              </h3>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                California Civil Code Section 1798.83, commonly referred to as
                the “Shine The Light” law, allows our users who are California
                residents to request and receive from us, once a year and free
                of charge, information about the types of personal information
                (if any) we have disclosed to third parties for direct marketing
                purposes, along with the names and addresses of all third
                parties with whom we shared personal information in the previous
                calendar year. If you are a resident of California and wish to
                make such a request, kindly submit your request in writing to us
                using the contact details provided below.
              </p>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                If you are a minor, live in California, and have a registered
                account with the Site, you are entitled to request the removal
                of unwanted data that you have posted publicly on the Website.
                To make a request for data removal, please contact us using the
                contact information provided below, and include the email
                address associated with your account and a statement that you
                are a resident of California. We will ensure that the data is
                not publicly displayed on the Website, but note that the data
                may not be fully or comprehensively removed from all our systems
                (e.g., backups, etc.).
              </p>
              <h3
                id="risk-heading-content"
                className=" my-0 pb-1 text-lg font-bold leading-[116.667%] md:text-2xl lg:text-4xl"
              >
                Data Retention
              </h3>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                When you place an order through the Site, we will keep your
                Order Information in our records unless and until you request us
                to delete this information.
              </p>
              <h3
                id="risk-heading-content"
                className=" my-0 pb-1 text-lg font-bold leading-[116.667%] md:text-2xl lg:text-4xl"
              >
                Changes
              </h3>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                We may periodically update this privacy policy to reflect, for
                example, modifications to our practices or for other
                operational, legal, or regulatory reasons. The updated version
                will be marked by an updated “Revised” date and the revised
                version will be effective as soon as it is accessible. If we
                make significant changes to this privacy notice, we may inform
                you either by prominently posting a notice of such changes or by
                directly sending you a notification. We advise you to regularly
                review this privacy notice to stay informed about how we are
                safeguarding your information.
              </p>
              <h3
                id="risk-heading-content"
                className=" my-0 pb-1 text-lg font-bold leading-[116.667%] md:text-2xl lg:text-4xl"
              >
                Contact Us
              </h3>
              <p
                id="risk-p-content"
                className=" m-[1rem_0] text-sm font-normal leading-[1.35] tracking-[0.006rem] md:text-base lg:text-lg"
              >
                For more information regarding our privacy practices, if you
                have queries, or if you wish to file a complaint, please contact
                us via email at{" "}
                <a href="mailto:info@madalgo.io">info@madalgo.io</a>
              </p>
            </div>
          </div>
        </div>
      </motion.section>
    </>
  );
}

export default PrivacyPolicy;
