import React from "react";
import { Link } from "react-router-dom";

const LeftArrow = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height=".8em"
    fill="currentcolor"
    viewBox="0 0 448 512"
  >
    <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
  </svg>
);

const SeeYouLater = () => {
  return (
    <div className="flex h-screen flex-col items-center justify-center bg-[#1e1e1e]">
      <div className="pb-4 text-center text-white">
        <h1 className="mb-4 text-4xl font-bold">See You Later!</h1>
        <p className="mb-4">Your subscription has been cancelled.</p>
        <p>You are now signed out. We hope to see you back soon.</p>
      </div>
      <Link
        to="/"
        className="flex flex-row items-center space-x-2 text-indigo-500 hover:underline"
      >
        <span>{LeftArrow}</span>
        <p>Return Home</p>
      </Link>
    </div>
  );
};

export default SeeYouLater;
