import { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import styles from "../Docs.module.css";
import { useTOC } from "../TOCContext";
import DocImages from "../DocImages";
import ThemeContext from "../ThemeContext";

const entries = [
  {
    displayText: "Apply A Update",
    urlFragment: "apply-a-update",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "Receiving Update Notifications",
    urlFragment: "receiving-update-notifications",
    headingType: "h2",
    subEntries: [],
  },
];
export { entries };

export default function DocsUpdatingMadalgo() {
  const { addToTOC, removeFromTOC } = useTOC();
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    entries.forEach(addToTOC);
    return () => {
      entries.forEach((entry) => removeFromTOC(entry));
    };
  }, [addToTOC, removeFromTOC]);

  return (
    <>
      <h1 className={styles.h1}>How To Update</h1>
      <h2 id="apply-a-update" className={`scroll-mt-2 ${styles["h2"]}`}>
        Apply A Update
        <Link
          to="#apply-a-update"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>To apply an update, you need to:</p>
      <ol className={styles.ol}>
        <li>Refresh your browser or mobile app</li>
        <li>Remove the indicator from your chart</li>
        <li>Re-add it from your invite-only indicators</li>
      </ol>
      <p className={styles.p}>The latest version should appear.</p>
      <div
        className={`
        info info-tip selection:bg-[#c4f6ff] selection:text-[#2c2c2c49]
        ${theme === "light" ? styles.infoTipInfo : styles.infoTipInfoDark} 
        `}
      >
        <div className="info-heading">
          <h5 className={styles.h5}>
            <span
              className={`
                ${theme === "light" ? styles.infoTip : styles.infoTipDark}
              `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 12 16"
              >
                <path
                  fillRule="evenodd"
                  d="M6.5 0C3.48 0 1 2.19 1 5c0 .92.55 2.25 1 3 1.34 2.25 1.78 2.78 2 4v1h5v-1c.22-1.22.66-1.75 2-4 .45-.75 1-2.08 1-3 0-2.81-2.48-5-5.5-5zm3.64 7.48c-.25.44-.47.8-.67 1.11-.86 1.41-1.25 2.06-1.45 3.23-.02.05-.02.11-.02.17H5c0-.06 0-.13-.02-.17-.2-1.17-.59-1.83-1.45-3.23-.2-.31-.42-.67-.67-1.11C2.44 6.78 2 5.65 2 5c0-2.2 2.02-4 4.5-4 1.22 0 2.36.42 3.22 1.19C10.55 2.94 11 3.94 11 5c0 .66-.44 1.78-.86 2.48zM4 14h5c-.23 1.14-1.3 2-2.5 2s-2.27-.86-2.5-2z"
                ></path>
              </svg>
            </span>
            Tip
          </h5>
        </div>
        <div className="warning-content">
          <p>
            Make sure to save your chart layout on Tradingview after
            successfully installing an update so you can ensure it will stay
          </p>
        </div>
      </div>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[9]}
          width={1219}
          height={324}
          alt="Save Chart"
        ></img>
      </p>
      <h2
        id="receiving-update-notifications"
        className={`scroll-mt-2 ${styles["h2"]}`}
      >
        Receiving Update Notifications
        <Link
          to="#receiving-update-notifications"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        We consistently broadcast our updates via our Discord server and social
        media channels. Additionally, an update announcement will be delivered
        to your email. You might also receive an update notification or email
        directly from TradingView. To ensure you're set up for these, navigate
        to:
      </p>
      <p className={styles.p}>
        <code className={styles.code}>
          settings -{">"} notifications -{">"} SCRIPTS YOU'VE ADDED TO FAVORITES
          OR LIKED
        </code>
      </p>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[14]}
          width={1219}
          height={324}
          alt="Scripts You've Favorited"
        ></img>
      </p>
      <div
        className={`
        info info-tip selection:bg-[#c4f6ff] selection:text-[#2c2c2c49]
        ${theme === "light" ? styles.infoTipInfo : styles.infoTipInfoDark} 
        `}
      >
        <div className="info-heading">
          <h5 className={styles.h5}>
            <span
              className={`
                ${theme === "light" ? styles.infoTip : styles.infoTipDark}
              `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 12 16"
              >
                <path
                  fillRule="evenodd"
                  d="M6.5 0C3.48 0 1 2.19 1 5c0 .92.55 2.25 1 3 1.34 2.25 1.78 2.78 2 4v1h5v-1c.22-1.22.66-1.75 2-4 .45-.75 1-2.08 1-3 0-2.81-2.48-5-5.5-5zm3.64 7.48c-.25.44-.47.8-.67 1.11-.86 1.41-1.25 2.06-1.45 3.23-.02.05-.02.11-.02.17H5c0-.06 0-.13-.02-.17-.2-1.17-.59-1.83-1.45-3.23-.2-.31-.42-.67-.67-1.11C2.44 6.78 2 5.65 2 5c0-2.2 2.02-4 4.5-4 1.22 0 2.36.42 3.22 1.19C10.55 2.94 11 3.94 11 5c0 .66-.44 1.78-.86 2.48zM4 14h5c-.23 1.14-1.3 2-2.5 2s-2.27-.86-2.5-2z"
                ></path>
              </svg>
            </span>
            Tip
          </h5>
        </div>
        <div className="warning-content">
          <p>
            For timely update alerts, be sure to follow the MADALGO account and
            give a thumbs-up to the{" "}
            <Link
              className={`${styles["docsInnerLink"]}`}
              to="https://www.tradingview.com/script/q50o6e3c-MADALGO-s-Pulse/"
            >
              MADALGO premium
            </Link>{" "}
            script. This guarantees you're in the loop whenever we roll out new
            features!
          </p>
        </div>
      </div>
    </>
  );
}
