import { motion } from "framer-motion";
import { CheckIcon } from "@heroicons/react/outline";
import RadioSwitch from "./RadioSwitch";
import styles from "./Main.module.css";

const PricingPlan = ({
  planName,
  price,
  isPopular,
  features,
  oneTimePrice,
  billingCycle,
  beforeSalePrice,
  discount,
  index,
}) => {
  const planHref =
    planName.toLowerCase() !== "lifetime"
      ? `https://app.madalgo.io/checkout?planName=${planName.toLowerCase()}&billingCycle=${billingCycle}`
      : `https://app.madalgo.io/checkout?planName=lifetime`;

  // Navigate to signup
  const subdomainPrefix = "https://account.madalgo.io";
  const signupURL = `${subdomainPrefix}/u/signup?planName=${encodeURIComponent(
    planName,
  )}${
    planName.toLowerCase() === "lifetime"
      ? ""
      : `&billingCycle=${encodeURIComponent(billingCycle)}`
  }`;

  const handlePlanClick = async (event) => {
    event.preventDefault();

    const authCheckURL = process.env.REACT_APP_STRIPE_VERIFY_AUTH;

    try {
      const response = await fetch(authCheckURL, {
        credentials: "include", // to include cookies in the request
      });
      const data = await response.json();

      // If authenticated, go to checkout, otherwise to signup
      if (data.authenticated) {
        window.location.href = planHref;
      } else {
        console.log("Signup URL:", signupURL);
        window.location.href = signupURL;
      }
    } catch (error) {
      console.error("Error checking authentication:", error);
      // Depending on the error, you might want to handle it differently.
      // For now, just redirecting to the signup page.
      window.location.href = signupURL;
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 1, delay: index * 0.1 }}
      viewport={{ once: true }}
      id={`price-plan-container-${index}`}
      className={` relative flex w-full flex-col content-start items-center overflow-hidden rounded-3xl border-0 p-6 shadow-md [grid-area:_span_1_/_span_1_/_span_1_/_span_1] md:p-6 lg:p-10 xl:min-h-[606.3px] xl:min-w-[337.1px] md:[&:nth-child(3)]:max-w-[50%] md:[&:nth-child(3)]:[grid-column:_span_2_/_span_2] xl:[&:nth-child(3)]:max-w-full xl:[&:nth-child(3)]:[grid-column:_span_1_/_span_1] ${
        isPopular
          ? "h-100% z-10 bg-[rgb(20,18,23,0.8)] shadow-md shadow-cyan-500"
          : "h-100% z-0 bg-[rgb(20,18,23,0.8)] shadow-md shadow-cyan-500"
      } `}
    >
      <div
        id={`price-plan-${index}`}
        className="relative z-[2] mb-8 flex w-full flex-col content-center items-center justify-between rounded-[1.25rem] bg-[rgb(44,40,49)] py-5 uppercase"
      >
        <h4
          className={`text-left text-xs font-medium ${
            isPopular ? "text-[rgb(6,182,212)]" : "text-[rgb(6,182,212)]"
          }`}
        >
          {planName}
        </h4>
      </div>
      <div
        id={`price-container-${index}`}
        className={`relative flex min-h-[116.3px] flex-col items-center ${
          isPopular ? "text-[rgb(42,131,146)]" : "text-[rgb(42,131,146)]"
        }`}
      >
        {isPopular && (
          <p className=" absolute -right-[4.3rem] -top-[25px] rounded-[2.5rem] bg-cyan-500 px-1.5 py-1 text-xs font-semibold leading-5 text-white">
            Most Popular
          </p>
        )}
        {beforeSalePrice && (
          <h4
            id={`before-sale-price-${index}`}
            className="mb-2.5 h-8 text-xl font-bold line-through opacity-20"
          >
            {beforeSalePrice}
          </h4>
        )}
        <h3
          id={`price-heading-${index}`}
          className={`mb-1 text-4xl font-bold ${
            isPopular
              ? "text-[rgba(245,245,245,0.86)]"
              : "text-[rgba(245,245,245,0.86)]"
          }`}
        >
          <span>{price ? price.amount : oneTimePrice}</span>
          {price && (
            <span
              className={`text-[0.875rem] ${
                isPopular ? "text-slate-600" : "text-[rgb(209,213,219)]"
              }`}
            ></span>
          )}
        </h3>

        {planName === "Basic" && billingCycle === "monthly" ? (
          <p id={`full-price-info-${index}`} className="font-bold">
            Full Price
          </p>
        ) : (
          <p
            id={`discount-info-${index}`}
            className="font-bold leading-[130%] text-green-600"
          >
            Save {discount}%
          </p>
        )}
      </div>
      <ul
        id={`feature-list-${index}`}
        className="mb-9 mt-4 space-y-2 xl:min-h-[188px]"
      >
        {features.map((feature, index) => (
          <li key={index} className="flex items-center space-x-2">
            <CheckIcon
              className={`h-5 w-5 ${
                isPopular ? "text-[rgb(6,182,212)]" : "text-[rgb(107,114,128)]"
              }`}
            />
            <span
              className={`${
                isPopular
                  ? "text-[rgb(238,239,241)]"
                  : "text-[rgb(238,239,241)]"
              }`}
            >
              {feature}
            </span>
          </li>
        ))}
      </ul>
      <a
        id={`plan-checkout-link-${index}`}
        href={planHref}
        onClick={(e) => {
          handlePlanClick(e);
        }}
        className={`group relative z-[1] mb-[.9375rem] flex max-w-full cursor-pointer items-center  whitespace-nowrap rounded-[4.5rem] border-2 border-solid border-white p-[.375rem_1.25rem] text-base leading-[144.44%] text-white  [text-decoration:none] ${
          isPopular ? "" : ""
        } transition-colors duration-300 ease-in-out`}
      >
        <p
          id={`subscribe-${index}`}
          className="mb-0 mt-0 text-sm font-normal leading-[1.35] tracking-[.006rem] md:text-base lg:text-lg"
        >
          Subscribe
        </p>
        <div
          id={`arrow-${index}`}
          className={
            ` ml-[.625rem] flex h-[.9375rem] w-[1.25rem] flex-none origin-[0%] content-center items-center [transition:_transform_.2s] group-hover:scale-110 ` +
            styles["w-embed"]
          }
        >
          <svg
            width="100%"
            height="100%"
            viewBox="0 0 20 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 5H19M19 5L14.6667 1M19 5L14.6667 9"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </svg>
        </div>
      </a>
      <p
        id={`30-day-text-${index}`}
        className=" mb-0 mt-0 text-xs font-normal leading-[133.3%] tracking-[.006rem]"
      >
        30 Day Money Back Guarantee
      </p>
    </motion.div>
  );
};

const benefits = [
  {
    text: ["Premium", " indicators on TradingView!"],
    highlighted: [true, false],
  },
  { text: ["Community access w/ scanners & bots!"], highlighted: [false] },
  {
    text: ["Instant ", "Alerts", " & Reliable ", "Signals!"],
    highlighted: [false, true, false, true],
  },
  {
    text: ["Strategies & Setups shared ", "Weekly!"],
    highlighted: [false, true],
  },
  {
    text: ["24/7", " Support & consistent product updates!"],
    highlighted: [true, false],
  },
];

const PricingSection = ({ billingCycle, setBillingCycle }) => {
  const pricingOptions = [
    { name: "Monthly", value: "monthly" },
    { name: "Annual", value: "annual" },
  ];

  const planDetails = [
    {
      planName: "Premium",
      price: {
        monthly: {
          amount: "$29.99",
          beforeSalePrice: "$39.99",
          discount: "25",
        },
        annual: {
          amount: "$299.99",
          beforeSalePrice: "$479.88",
          discount: "37.49",
        },
      },
      features: [
        "Access to MADALGO",
        "Real-Time Updates",
        "Access to Premium Discord",
        "Buy/Sell Signals for All Assets",
        "New Coin Listing Alerts",
        "Insider Trading Alerts",
        "Exclusive Guides & Content",
        "Access to Workshops",
        "Prioritized Customer Support",
      ],
    },
    {
      planName: "Lifetime",
      price: {
        oneTime: {
          amount: "$499.99",
          beforeSalePrice: "$999.99",
          discount: "40",
        },
      },
      features: [
        "Everything from Premium",
        "Lifetime Updates",
        "Access to Future Indicators",
        "First Access to New Features",
        "Beta Tester Access",
        "VIP Discord Access",
      ],
    },
  ];

  return (
    <div
      id="premium-packages"
      className="relative flex w-full flex-col items-center pb-[3.75rem] pt-[4.875rem] text-center tiny:px-2 txsm:px-6 xsm:px-12 md:px-[3.75rem] lg:px-[5.5rem] xl:px-[6.75rem]"
    >
      <div
        id="plan-packages-header"
        className="flex flex-col items-center justify-between text-center lg:gap-10 xl:flex-row xl:items-start xl:gap-80 xl:pb-40 xl:text-left"
      >
        <motion.h2
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          id="plan-packages-heading"
          className="mb-[0] mt-[0] w-full flex-none text-[2rem] font-bold leading-[97.5%_!important] sm:text-4xl md:text-5xl lg:w-[50rem] xl:w-[30rem]"
        >
          Select your desired plan from our
          <span
            className="animate-gradient-animation bg-clip-text text-transparent"
            style={{
              backgroundImage:
                "linear-gradient(to right, #7C3AED 0%, #EC4899 50%, #F43F5E 100%), linear-gradient(to right, #7C3AED 0%, #EC4899 50%, #F43F5E 100%), linear-gradient(to right, #7C3AED 0%, #EC4899 50%, #F43F5E 100%)",
              backgroundSize: "300% 100%",
            }}
          >
            <br />
            comprehensive membership options
          </span>
        </motion.h2>

        <div
          id="plan-benefits-list"
          className=" ml-[0] mt-[2.375rem] flex flex-col items-start text-base font-normal [grid-row-gap:0.9375rem] lg:ml-[1.125rem] lg:mt-0 lg:text-lg"
        >
          {benefits.map((benefit, index) => (
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: index * 0.2 }}
              viewport={{ once: true }}
              id="plant-benefit-list-item"
              key={index}
              className="flex items-center tiny:whitespace-normal tiny:text-left tiny:[grid-column-gap:.5rem] xsm:[grid-column-gap:1rem]"
            >
              <svg
                className="mr-2 align-middle text-green-500"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M13.78 4.22a.75.75 0 010 1.06l-7.25 7.25a.75.75 0 01-1.06 0L2.22 9.28a.75.75 0 011.06-1.06L6 10.94l6.72-6.72a.75.75 0 011.06 0z" />
              </svg>
              <p className="align-middle">
                {benefit.text.map((item, index) =>
                  benefit.highlighted[index] ? (
                    <strong key={index}>{item}</strong>
                  ) : (
                    item
                  ),
                )}
              </p>
            </motion.div>
          ))}
        </div>
      </div>
      <motion.div
        id="toggle"
        className="mb-4 mt-10 lg:pb-10 xl:pb-0"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
        viewport={{ once: true }}
      >
        <RadioSwitch
          options={pricingOptions}
          value={billingCycle}
          onChange={setBillingCycle}
        ></RadioSwitch>
      </motion.div>
      <div
        id="plan-container"
        className="my-4 grid grid-cols-1 grid-rows-2 place-items-center content-center justify-center justify-items-center gap-y-2.5 md:grid-cols-[1fr_1fr] md:grid-rows-[auto_auto] md:[grid-column-gap:1.5em] md:[grid-row-gap:2.5rem] lg:grid-rows-1 lg:gap-x-14 lg:gap-y-14 xl:mt-[5.75] xl:grid-cols-[max-content_max-content_max-content] xl:grid-rows-[auto] xl:gap-x-6 xl:gap-y-4"
      >
        {planDetails.map((plan, index) => (
          <PricingPlan
            key={index}
            index={index}
            planName={plan.planName}
            price={
              plan.price
                ? plan.planName === "Lifetime"
                  ? plan.price.oneTime
                  : plan.price[billingCycle]
                : null
            }
            isPopular={index === 0}
            features={plan.features}
            oneTimePrice={plan.oneTimePrice}
            billingCycle={billingCycle}
            beforeSalePrice={
              plan.price
                ? plan.planName === "Lifetime"
                  ? plan.price.oneTime.beforeSalePrice
                  : plan.price[billingCycle].beforeSalePrice
                : null
            }
            discount={
              plan.price
                ? plan.planName === "Lifetime"
                  ? plan.price.oneTime.discount
                  : plan.price[billingCycle].discount
                : null
            }
          />
        ))}
      </div>

      <motion.div
        id="options-payment"
        className=" mt-[4.375rem] flex flex-col items-center text-center lg:mt-28 lg:flex-row lg:items-start lg:gap-40 lg:text-start xl:mt-44 xl:gap-80 2xl:gap-[32rem]"
      >
        <motion.h3
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          id="options-payment-heading"
          className=" mb-5 mr-0 mt-0 text-2xl font-bold leading-[100%] lg:max-w-[330px] lg:text-[2.75rem] xl:flex-grow"
        >
          Checkout with confidence.
        </motion.h3>
        <motion.div
          initial={{ opacity: 0, x: -50 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1, delay: 0.2 }}
          id="options-payment-guarantee-container"
          className=" w-full flex-none md:w-[35rem] lg:max-w-sm"
        >
          <p
            id="options-payment-guarantee"
            className="mb-10 mt-0 text-lg font-normal leading-[116.667%] tracking-[0.1px] lg:text-right lg:text-xl xl:text-2xl"
          >
            You can cancel at any time with a single click from your account.
            Following sign-up, you'll receive an immediate access link. All
            trading is at your personal risk.
          </p>
          <div
            id="secure-checkout"
            className=" flex flex-row items-center justify-center opacity-40 [grid-column-gap:_0.6rem] lg:justify-end lg:[grid-column-gap:_2.125rem]"
          >
            <svg
              id="secure-ssl"
              xmlns="http://www.w3.org/2000/svg"
              xmlSpace="preserve"
              style={{ enableBackground: "new 0 0 24 24" }}
              viewBox="0 0 24 24"
              width="100%"
              height="100%"
              className="flex w-16 flex-none items-center justify-center before:table after:table before:content-none after:clear-both after:content-none lg:w-[5.6785rem]"
            >
              <path
                d="m7.7 12.3.4-.4c.2.2.4.3.7.3.3 0 .4-.1.4-.4 0-.2-.1-.3-.3-.4l-.3-.1c-.5-.1-.8-.4-.8-.9 0-.6.4-.9 1-.9.4 0 .7.1 1 .4l-.4.4c-.1-.2-.3-.3-.6-.3s-.4.1-.4.3c0 .2.1.3.3.3l.2.4c.6.1.8.4.8.9 0 .6-.4 1-1.1 1-.3-.1-.7-.3-.9-.6zM10.2 12.7v-3H12v.5h-1.2v.8h1v.5h-1v.7H12v.5h-1.8zM12.8 11.3c0-1.1.5-1.6 1.2-1.6.5 0 .8.2 1 .7l-.5.3c-.1-.3-.2-.4-.5-.4s-.5.2-.5.6v.8c0 .4.2.6.5.6s.4-.2.5-.5l.5.3c-.2.4-.5.7-1 .7-.8 0-1.2-.4-1.2-1.5zM16.2 9.8v1.9c0 .4.1.6.5.6s.5-.2.5-.6V9.8h.6v1.9c0 .8-.3 1.2-1.1 1.2s-1.1-.3-1.1-1.2V9.8h.6zM19.2 12.7h-.6v-3h1.3c.5 0 .8.4.8.9 0 .4-.2.8-.5.9l.5 1.2H20l-.5-1.1h-.4v1.1zm.6-1.6c.2 0 .3-.1.3-.3v-.3c0-.2-.1-.3-.3-.3h-.6v.8h.6zM21.4 12.7v-3h1.8v.5H22v.8h1v.5h-1v.7h1.2v.5h-1.8zM7.7 14.7l.2-.2c.1.1.2.2.3.2.1 0 .2-.1.2-.2s0-.2-.2-.2h-.1c-.3-.1-.4-.2-.4-.4 0-.3.2-.5.5-.5.2 0 .4.1.5.2l-.2.2c-.1-.1-.2-.1-.3-.1-.1 0-.2 0-.2.2 0 .1 0 .1.2.2h.1c.3.1.4.2.4.4 0 .3-.2.5-.5.5-.2-.1-.3-.2-.5-.3zM8.8 14.7l.2-.2c.1.1.2.2.3.2.1 0 .2-.1.2-.2s0-.2-.2-.2h-.1c-.2-.1-.3-.3-.3-.5 0-.3.2-.5.5-.5.2 0 .4.1.5.2l-.2.2c-.1-.1-.2-.1-.3-.1-.1 0-.2 0-.2.2 0 .1 0 .1.2.2h.1c.3.1.4.2.4.4 0 .3-.2.5-.5.5s-.5-.1-.6-.2zM10 14.9v-1.5h.3v1.2h.5v.3H10zM11.5 14.9v-1.5h.9v.3h-.6v.3h.5v.3h-.5v.4h.6v.3h-.9zM13 14.1v-.3 1.1h-.3v-1.5h.3l.4.8.1.2v-1.1h.3v1.5h-.3l-.5-.7zM14.1 14.1c0-.6.2-.8.6-.8.3 0 .4.1.5.4l-.3.1c0-.1-.1-.2-.2-.2-.2 0-.3.1-.3.3v.4c0 .2.1.3.3.3.2 0 .2-.1.3-.2l.3.1c-.1.2-.2.4-.5.4-.5 0-.7-.2-.7-.8zM15.7 14.9h-.3v-1.5h.7c.3 0 .4.2.4.5 0 .2-.1.4-.2.4l.3.6h-.3l-.2-.6h-.2v.6zm.3-.9c.1 0 .1 0 .1-.1v-.1c0-.1-.1-.1-.1-.1h-.3v.3h.3z"
                fill="#fff"
              />
              <path
                d="M16.9 14.9v-.6l-.5-.9h.3l.3.6.3-.6h.3l-.5.9v.6h-.2zM17.7 14.9v-1.5h.7c.3 0 .4.2.4.5s-.1.5-.4.5H18v.6h-.3zm.3-.9h.3c.1 0 .1 0 .1-.1v-.1c0-.1-.1-.1-.1-.1H18v.3zM19.5 13.6v1.2h-.3v-1.2h-.4v-.3h1v.3h-.3zM20 14.9v-.2h.2v-1H20v-.2h.7v.2h-.2v1h.2v.2H20z"
                fill="#fff"
              />
              <path
                d="M20.6 14.1c0-.6.2-.8.6-.8.4 0 .6.2.6.8s-.2.8-.6.8c-.3 0-.6-.2-.6-.8zm.9.2v-.4c0-.2-.1-.3-.3-.3-.2 0-.3.1-.3.3v.4c0 .2.1.3.3.3.2 0 .3-.1.3-.3zM22.6 14.1l-.1-.2V15h-.3v-1.5h.3l.4.8.1.2v-1.1h.3v1.5H23l-.4-.8zM6.9 11.5h-.2v-1C6.6 9 5.5 7.8 4 7.8c-1.4 0-2.6 1.2-2.6 2.6v1h-.2c-.2 0-.5.2-.5.5v3.9c0 .2.2.5.5.5h5.7c.2 0 .5-.2.5-.5v-3.9c-.1-.2-.3-.4-.5-.4zM3.8 15l-.4-.4-.6-.6.4-.4.5.5 1.4-1.4.4.4L3.8 15zm1.9-3.5H2.4v-1.2c0-.9.7-1.7 1.7-1.7.9 0 1.7.7 1.7 1.7l-.1 1.2z"
                fill="#fff"
              />
            </svg>
            <svg
              id="stripe-icon"
              xmlns="http://www.w3.org/2000/svg"
              xmlSpace="preserve"
              style={{ enableBackground: "new 0 0 24 24" }}
              viewBox="0 0 24 24"
              width="100%"
              height="100%"
              className="flex w-12 flex-none items-center justify-center before:table after:table before:content-none after:clear-both after:content-none lg:w-14"
            >
              <path
                d="M23.6 12.2c0-1.7-.8-3-2.3-3-1.5 0-2.5 1.3-2.5 2.9 0 1.9 1.1 2.9 2.7 2.9.8 0 1.3-.2 1.8-.4v-1.3c-.4.2-.9.4-1.6.4-.6 0-1.2-.2-1.3-1h3.2v-.5zm-3.2-.7c0-.7.4-1 .9-1 .4 0 .8.3.8 1h-1.7zm-4.1-2.3c-.6 0-1 .3-1.3.5v-.4h-1.4v7.5l1.6-.3v-1.8c.2.2.6.4 1.1.4 1.2 0 2.2-.9 2.2-3 0-1.9-1-2.9-2.2-2.9zm-.4 4.5c-.4 0-.6-.1-.8-.3V11c.2-.2.4-.3.8-.3.6 0 1 .7 1 1.5s-.4 1.5-1 1.5zm-4.6-4.9 1.7-.3V7.2l-1.6.3v1.3zm0 .5H13V15h-1.6V9.3zm-1.7.5-.1-.5H8.1V15h1.6v-3.8c.4-.5 1-.4 1.2-.3V9.3c-.2-.1-.9-.2-1.3.5zM6.4 7.9l-1.6.4v5.2c0 1 .7 1.7 1.7 1.7.5 0 .9-.1 1.1-.2v-1.3c-.2.1-1.2.4-1.2-.6v-2.3h1.2V9.3H6.4V7.9zM2 11c0-.3.2-.3.5-.3.5 0 1.1.1 1.6.4V9.5c-.5-.2-1-.3-1.5-.3C1.3 9.2.4 9.9.4 11c0 1.8 2.4 1.5 2.4 2.3 0 .3-.3.4-.6.4-.5 0-1.2-.2-1.8-.5v1.5c.6.3 1.2.4 1.8.4 1.3 0 2.3-.7 2.3-1.8 0-2-2.5-1.6-2.5-2.3z"
                style={{
                  fillRule: "evenodd",
                  clipRule: "evenodd",
                  fill: "#fff",
                }}
              />
            </svg>
            <svg
              id="master-card-icon"
              width="100%"
              height="100%"
              xmlns="http://www.w3.org/2000/svg"
              xmlSpace="preserve"
              style={{ enableBackground: "new 0 0 24 24" }}
              viewBox="0 0 24 24"
              className="flex w-9 flex-none items-center justify-center before:table after:table before:content-none after:clear-both after:content-none lg:w-[2.875rem]"
            >
              <path
                fill="#fff"
                d="M4.2 18.7v-.4l-.1.3-.4.8-.4-.8-.1-.3v1.5h-.4v-2.2h.5l.3.7.2.5.2-.5.3-.7h.5v2.2h-.6v-1.1zM6.3 19.8l-.1-.5h-.7l-.1.5h-.5l.6-2.2h.6l.6 2.2h-.4zm-.4-1.4-.1-.4-.1.4-.1.5h.5l-.2-.5zM6.9 19.5l.3-.3c.1.2.3.2.5.2s.3-.1.3-.3c0-.2-.1-.2-.2-.3h-.2c-.4 0-.6-.2-.6-.5 0-.4.3-.7.7-.7.3 0 .5.1.7.3l-.3.3c-.1-.1-.2-.2-.4-.2s-.3.1-.3.2.1.2.2.2h.2c.4.1.6.3.6.6 0 .5-.3.7-.8.7-.3.2-.5.1-.7-.2zM9.6 18v1.8h-.5V18h-.5v-.4h1.5v.4h-.5zM10.4 19.8v-2.2h1.4v.4h-.9v.5h.8v.4h-.8v.5h.9v.4h-1.4zM12.5 19.8h-.4v-2.2h.9c.4 0 .6.3.6.7 0 .3-.1.6-.4.6l.4.9h-.5l-.3-.8h-.3v.8zm.5-1.2c.1 0 .2-.1.2-.2v-.2c0-.2-.1-.2-.2-.2h-.4v.6h.4zM13.9 18.8c0-.8.4-1.1.9-1.1.4 0 .6.2.7.5l-.4.2c0-.2-.1-.3-.4-.3-.2 0-.4.2-.4.5v.4c0 .3.2.5.4.5s.3-.2.4-.3l.4.2c-.2.3-.4.5-.8.5s-.8-.3-.8-1.1zM17 19.8l-.1-.5h-.7l-.1.5h-.4l.6-2.2h.6l.6 2.2H17zm-.4-1.4-.1-.4-.1.4-.1.5h.5l-.2-.5zM18.2 19.8h-.4v-2.2h.9c.4 0 .6.3.6.7 0 .3-.1.6-.4.6l.4.9h-.5l-.3-.8h-.3v.8zm.4-1.2c.1 0 .2-.1.2-.2v-.2c0-.2-.1-.2-.2-.2h-.4v.6h.4zM19.6 17.6h.8c.5 0 .9.3.9 1.1s-.3 1.1-.9 1.1h-.8v-2.2zm.8 1.8c.2 0 .4-.1.4-.4v-.5c0-.3-.1-.4-.4-.4h-.3v1.4h.3zM11.4 15.3s.1.1.2.1c-1 .7-2.2 1-3.4 1-3.4 0-6.2-2.8-6.2-6.2C2 6.8 4.8 4 8.2 4c1.2 0 2.4.4 3.4 1-.1 0-.1.1-.1.1-1.5 1.3-2.3 3.1-2.3 5-.1 2.1.8 4 2.2 5.2zm4.4-11.2c-1.2 0-2.4.4-3.4 1l.1.1c1.5 1.3 2.3 3.1 2.3 5s-.8 3.8-2.3 5c0 0-.1.1-.2.1 1 .7 2.2 1 3.4 1 3.4 0 6.2-2.7 6.3-6.1S19.3 4 15.9 3.9c0 .2-.1.2-.1.2zM12 5.5c-1.5 1.2-2.4 3-2.4 4.8 0 1.9.9 3.7 2.4 4.9 1.5-1.2 2.4-3 2.4-4.9-.1-1.9-.9-3.7-2.4-4.8z"
              />
            </svg>
            <svg
              id="visa-icon"
              width="100%"
              height="100%"
              xmlns="http://www.w3.org/2000/svg"
              xmlSpace="preserve"
              style={{ enableBackground: "new 0 0 24 24" }}
              viewBox="0 0 24 24"
              className="flex w-10 flex-none items-center justify-center before:table after:table before:content-none after:clear-both after:content-none lg:w-12"
            >
              <path
                fill="#fff"
                d="M15.9 9.3c-.5-.2-1-.3-1.5-.3-1.6 0-2.7.8-2.7 2 0 .9.8 1.3 1.4 1.6.6.3.8.5.8.7 0 .4-.5.6-1 .6-.6 0-1-.1-1.5-.3l-.2-.1-.2 1.3c.4.2 1.1.3 1.8.3 1.7 0 2.8-.8 2.8-2 0-.7-.4-1.2-1.4-1.6-.6-.3-.9-.5-.9-.7s.3-.5.9-.5c.4 0 .8.1 1.2.2h.1l.4-1.2zm3.9-.2h-1.2c-.4 0-.7.1-.9.5l-2.4 5.5H17l.3-.9h2.1c0 .2.2.9.2.9h1.5l-1.3-6zm-9.8 0h1.6l-1 5.9H9l1-5.9zm-3.8 3.3.2.8L8 9.1h1.7l-2.5 6H5.4c-.5-1.7-.9-3.3-1.4-5V10c-.1-.1-.1-.2-.2-.2-.2-.1-.5-.2-.8-.3-.4-.1-.7-.2-1-.3l-.2-.1H5c.4 0 .6.1.7.5l.5 2.8zm11.6.6.6-1.7.2-.6.1.5.4 1.8h-1.3z"
              />
            </svg>
            <svg
              id="am-ex-icon"
              xmlns="http://www.w3.org/2000/svg"
              xmlSpace="preserve"
              x="0"
              y="0"
              style={{
                enableBackground: "new 0 0 24 24",
                fill: "rgba(255,255,255,0)",
              }}
              version="1.1"
              viewBox="0 0 24 24"
              width="100%"
              height="100%"
              className="flex w-7 flex-none items-center justify-center before:table after:table before:content-none after:clear-both after:content-none lg:w-[2.75rem]"
            >
              <path d="M2.9 1.5v20.9H24V1.5H13.4z" />
              <path
                d="m20.2 6.6.7-1.8H24V1.5H2.9v20.9H24v-3.3h-2.9L20 17.9l-1.1 1.2h-8.2v-6.7H7.9l3.4-7.7h3.3l.8 1.7V4.8h4.1l.7 1.8z"
                fill="#fff"
              />
              <path fill="#fff" d="m22.4 15.3 1.6 1.6v-3.2l-1.6 1.6z" />
              <path
                fill="#fff"
                d="M21.7 18.1h1.9L21 15.3l2.6-2.8h-1.8L20 14.3l-1.7-1.8h-1.9l2.6 2.8-2.6 2.8h1.8l1.7-1.8 1.8 1.8z"
              />
              <path
                fill="#fff"
                d="M11.8 12.5v5.6h4.7v-1.2h-3.2v-1h3.2v-1.2h-3.2v-1h3.2v-1.2h-4.7zM14.9 11.5h1.6L14 5.9h-1.9l-2.5 5.6h1.6l.4-1.1h2.7l.6 1.1zm-2.1-3.7.3-.7.3.7.6 1.4h-1.7l.5-1.4zM17.9 7.9v-.7l.3.7 1.4 3.6H21l1.4-3.6.3-.7v4.4H24V5.9h-2.4l-1.1 2.8-.3.8-.2-.8-1.1-2.8h-2.4v5.6h1.4V7.9z"
              />
            </svg>
            <svg
              id="paypal-icon"
              xmlns="http://www.w3.org/2000/svg"
              xmlSpace="preserve"
              style={{ enableBackground: "new 0 0 24 24" }}
              viewBox="0 0 24 24"
              width="100%"
              height="100%"
              className=" flex w-[3.5rem] flex-none items-center justify-center before:table after:table before:content-none after:clear-both after:content-none lg:w-24"
            >
              <path
                fill="#fff"
                d="M7 11.1v.9c-.3 1.3-1.1 2-2.5 2h-.3c-.1 0-.1 0-.2.1-.1 0-.1.1-.1.2v.1l-.2 1.5v.1c0 .1-.1.1-.1.2-.1 0-.1.1-.2.1H2.2c-.1 0-.1 0-.1-.1 0 0-.1-.1 0-.2 0-.2.1-.4.1-.8.1-.3.1-.6.1-.8 0-.2.1-.4.1-.8.1-.3.1-.6.1-.8 0-.1.1-.2.2-.2h.6c.4 0 .7 0 1.1-.1.5-.1 1-.3 1.3-.6.3-.3.5-.7.7-1.1.1-.2.1-.4.2-.6.2.3.3.6.4.9zm-.8-1.3c0 .3-.1.7-.2 1.1-.2.7-.7 1.2-1.4 1.4-.3.1-.7.2-1.1.2h-.8c-.3 0-.5.1-.5.4 0 0-.1.8-.4 2.4H.4c-.1 0-.1 0-.2-.1 0 0-.1-.1-.1-.2l1-6.6c0-.1.1-.2.1-.2.1-.1.1-.1.2-.1h2.7c.1 0 .2 0 .4.1.2 0 .4.1.5.1.5.2.8.4 1 .7.1.2.2.5.2.8zM7.3 13.9V9.5h1.9c.8 0 1.2.6 1.2 1.4 0 .8-.4 1.4-1.2 1.4h-1v1.6h-.9zm.9-2.4h.9c.2 0 .4-.1.4-.4v-.4c0-.3-.2-.4-.4-.4h-.9v1.2zM12.8 13.9c-.4 0-.5-.2-.6-.6h-.1c-.1.5-.4.7-.8.7-.6 0-.9-.4-.9-1 0-.7.5-1 1.3-1h.5v-.3c0-.3-.1-.5-.5-.5-.3 0-.5.2-.6.4l-.5-.5c.3-.4.6-.6 1.2-.6.9 0 1.3.4 1.3 1.2v1.5h.3v.7h-.6zm-.7-1v-.4h-.4c-.3 0-.5.1-.5.4v.1c0 .3.1.4.4.4.3 0 .5-.2.5-.5z"
              />
              <path
                fill="#fff"
                d="M15.2 10.6h.9l-1.3 4c-.2.5-.4.6-1 .6h-.6v-.7h.8l.1-.4-1.1-3.5h.9l.5 1.9.1.7h.1l.1-.7.5-1.9zM16.3 13.9V9.5h1.9c.8 0 1.2.6 1.2 1.4 0 .8-.4 1.4-1.2 1.4h-1v1.6h-.9zm.9-2.4h.9c.2 0 .4-.1.4-.4v-.4c0-.3-.2-.4-.4-.4h-.9v1.2zM21.8 13.9c-.4 0-.5-.2-.6-.6h-.1c-.1.4-.4.7-.8.7-.6 0-.9-.4-.9-1 0-.7.5-1 1.3-1h.5v-.3c0-.3-.1-.5-.5-.5-.3 0-.5.2-.6.4l-.5-.5c.3-.4.6-.6 1.2-.6.9 0 1.3.4 1.3 1.2v1.5h.3v.7h-.6zm-.6-1v-.4h-.4c-.3 0-.5.1-.5.4v.1c0 .3.1.4.4.4.2 0 .5-.2.5-.5zM23.4 13.9c-.6 0-.9-.3-.9-.8V9.2h.9v4h.4v.7h-.4z"
              />
            </svg>
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default PricingSection;
