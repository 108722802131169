import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import IntroducingAIWithMADALGO from "./blog-posts/IntroducingAIWithMADALGO";
import BlogMain from "./BlogMain";
import { Helmet } from "react-helmet";
import AdvancedChartAnalysis from "./blog-posts/AdvancedChartAnalysis";

const Blog = ({ setIsBlogPage }) => {
  useEffect(() => {
    setIsBlogPage(true);
    return () => setIsBlogPage(false);
  }, [setIsBlogPage]);

  useEffect(() => {
    document.body.classList.add("bg-white");

    return () => {
      document.body.classList.remove("bg-white");
    };
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<BlogMain />} />
        <Route
          path="introducing-ai-with-madalgo"
          element={<IntroducingAIWithMADALGO setIsBlogPage={setIsBlogPage} />}
        />
        <Route
          path="advanced-chart-analysis"
          element={<AdvancedChartAnalysis setIsBlogPage={setIsBlogPage} />}
        />
      </Routes>
    </>
  );
};

export default Blog;
