import React from "react";
import images from "./images";
import styles from "./Main.module.css";

export function Community() {
  return (
    <section id="community-cta-container" className="relative z-0 block w-full">
      <div
        id="inner-cta"
        className=" w-full px-7 pb-7 pt-14 md:px-14 md:pb-14 md:pt-24 lg:pt-32 xl:px-28"
      >
        <div
          id="cta-container"
          className="relative w-full overflow-hidden rounded-[1.25rem] bg-cover py-[4.5rem] pl-0 [background-position:50%] [box-shadow:_21px_21px_32px_rgba(0,_0,_0,_.05)] lg:rounded-[2.5rem] lg:py-36"
          style={{
            backgroundImage: `url(${images[20]})`,
            transform: "translate(0px}",
            opacity: 1,
          }}
        >
          <div
            id="cta-content"
            className="relative z-[15] flex h-full w-full flex-col items-center text-center"
          >
            <h2
              id="cta-heading"
              className="my-0 max-w-xs text-5xl font-bold leading-[95%] md:max-w-md md:text-[5rem] lg:max-w-lg lg:text-[6.6rem] xl:max-w-none"
            >
              Join our Community
            </h2>
            <div
              id="discord-partner-holder"
              className=" mb-7 mt-4 flex flex-row items-center [grid-column-gap:_.3125rem] lg:mb-[2.875rem] lg:mt-4"
            >
              <img alt=""></img>
              <p
                id="p-body"
                className="mb-0 mt-0 text-sm font-normal leading-[126%] tracking-[0.006em] lg:text-base"
              ></p>
            </div>
            <a
              href="https://discord.gg/urfhuyhTXd"
              id="cta-button"
              target="_blank"
              rel="noopener noreferrer"
              className="group relative z-[1] flex cursor-pointer items-center whitespace-nowrap rounded-[4.5rem] p-[_.375rem_1.25rem] text-base leading-[144.44%] [border:2px_solid_#fff] [text-decoration:none] "
            >
              <p
                id="button-text"
                className="mb-0 mt-0 text-sm font-normal leading-snug md:text-base lg:text-lg"
              >
                discord.gg/madalgo
              </p>
              <div
                id="svg-container"
                className={
                  `ml-[.625rem] flex h-[.9375rem] w-5 flex-none origin-[0%] content-center text-white [transition:_transform_.2s] group-hover:scale-110 ` +
                  styles["w-embed"]
                }
              >
                <svg
                  width="100%"
                  height="100%"
                  viewBox="0 0 20 10"
                  fill="none"
                  xmlns="https://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 5H19M19 5L14.6667 1M19 5L14.6667 9"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </div>
            </a>
          </div>
          <img
            id="3d-chat"
            src={images[21]}
            className="absolute bottom-auto right-auto z-10 inline-block h-44 w-44 max-w-full select-none border-none object-contain opacity-60 tiny:left-[-11%] tiny:top-[-1%] xsm:left-[-6%] xsm:top-[6%] md:bottom-[24%] md:left-[-8%] md:right-[-11%] md:top-[24%] md:h-[15.6875rem] md:w-[15.6875rem] lg:bottom-auto lg:left-[-8%] lg:right-auto lg:top-[15%] lg:h-[27.1168rem] lg:w-[27.1168rem]"
            loading="lazy"
            sizes="(max-width: 767px) 176px, (max-width: 991px) 251px, 433.859375px"
            data-lag="0.15"
            alt=""
          ></img>
          <img
            id="3d-chat-1"
            src={images[22]}
            className=" absolute bottom-auto left-auto z-10 inline-block h-44 w-44 max-w-full select-none border-none object-contain opacity-60 tiny:right-[-6%] tiny:top-[-14%] xsm:right-[-8%] xsm:top-[-18%] md:right-[-8%] md:top-[-31%] md:h-[15.75rem] md:w-[15.75rem] lg:right-[0%] lg:top-[-36%] lg:h-[27rem] lg:w-[27rem] "
            loading="lazy"
            sizes="(max-width: 767px) 176px, (max-width: 991px) 252px, 435.578125px"
            data-lag="0.15"
            alt=""
          ></img>
          <img
            id="3d-discord"
            src={images[23]}
            className="absolute bottom-[-6%] left-auto right-[4%] top-auto z-10 inline-block h-40 w-40 max-w-full select-none border-none object-contain opacity-60 md:bottom-[-10%] md:h-52 md:w-52 lg:bottom-[-15%] lg:right-[10%] lg:h-[22.420rem] lg:w-[22.420rem]"
            loading="lazy"
            sizes="(max-width: 767px) 160px, (max-width: 991px) 208px, 359.578125px"
            data-lag="0.15"
            alt=""
          ></img>
        </div>
        <div
          id="3d-icon-container"
          className="pointer-events-none absolute bottom-[0%] left-[0%] right-[0%] top-[0%] block h-full w-full"
        >
          <img
            className="bottom-20% absolute -top-[80%] left-auto right-0 -z-[1] inline-block w-full max-w-full select-none object-contain [transform:translate(0px)] md:fixed md:[display:none]"
            src={images[26]}
            sizes="(max-width: 479px) 100vw, (max-width: 767px) 195px, (max-width: 991px) 236px, 100vw"
            alt="Spectal"
          />
          <img
            className="absolute -left-[2rem] -right-[4.8rem] bottom-auto top-[25.4rem] z-0 inline-block h-[8.5rem] w-[8.5rem] max-w-full select-none object-contain opacity-40 [transform:translate(0px)] md:top-[32rem] md:h-48 md:w-48 lg:fixed lg:[display:none]"
            src={images[19]}
            sizes="(max-width: 479px) 100vw, (max-width: 767px) 195px, (max-width: 991px) 236px, 100vw"
            alt="Stock Arrow"
          />
        </div>
      </div>
    </section>
  );
}
