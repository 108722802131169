import styles from "../Docs.module.css";
import DocImages from "../DocImages";

export default function DocsTechnicalAnalysisVolume() {
  return (
    <>
      <h1 className={styles.h1}>Volume</h1>
      <p className={styles.p}>
        Trading volume indicates the total number of shares exchanged within a
        specific timeframe, effectively reflecting the intensity of trading
        activity and liquidity. For many traders, volume ranks as one of the
        most crucial data points.
      </p>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[38]}
          width={1505}
          height={1048}
          alt="Volume Bars"
        ></img>
      </p>
      <p className={styles.p}>
        On TradingView, you can incorporate volume into a chart via the
        indicator panel.
      </p>
    </>
  );
}
