import { useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "../Docs.module.css";
import { useTOC } from "../TOCContext";

const entries = [
  {
    displayText: "1.0",
    urlFragment: "v-10",
    headingType: "h2",
    subEntries: [],
  },
];
export { entries };

export default function NavigatorChangelog() {
  const { addToTOC, removeFromTOC } = useTOC();

  useEffect(() => {
    entries.forEach(addToTOC);
    return () => {
      entries.forEach((entry) => removeFromTOC(entry));
    };
  }, [addToTOC, removeFromTOC]);

  return (
    <>
      <h1 className={styles.h1}>MADALGO Navigator</h1>
      <h2 id="v-10" className={`scroll-mt-2 ${styles["h2"]}`}>
        1.0
        <Link
          to="#v-10"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <hr className={styles.hr} />
      <p className={styles.p}>
        Launch version of the MADALGO Premium Navigator.
      </p>
    </>
  );
}
