import { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import styles from "../Docs.module.css";
import { useTOC } from "../TOCContext";
import DocImages from "../DocImages";
import ThemeContext from "../ThemeContext";

const entries = [];

export default function DocsSetupMADALGO() {
  const { addToTOC, removeFromTOC } = useTOC();
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    entries.forEach(addToTOC);
    return () => {
      entries.forEach((entry) => removeFromTOC(entry));
    };
  }, [addToTOC, removeFromTOC]);

  return (
    <>
      <h1 className={styles.h1}>Setup MADALGO on TradingView</h1>
      <p className={styles.p}>
        Upon registering on
        <Link
          to="/#premium-packages"
          className={`select-none pl-1 transition-opacity duration-200 [text-decoration:none] hover:underline
              ${theme === "light" ? "text-blue-500" : "text-purple-500"}
              `}
        >
          our website
        </Link>{" "}
        and linking your active TradingView account via the "Connect Accounts"
        section in your email, you'll gain immediate access to our suite of
        indicators on the platform.
      </p>
      <p className={styles.p}>
        To begin utilizing these indicators, initiate a chart on TradingView.
      </p>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[3]}
          width={1200}
          height={640}
          alt="Products Tab"
        ></img>
      </p>
      <p className={styles.p}>
        After launching the chart, select the Indicators option (or simply type
        / ) to access the indicator menu. Next, opt for "invite-only scripts".
        You'll observe the names of the indicators; select one to incorporate it
        onto your chart.
      </p>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[10]}
          width={1229}
          height={775}
          alt="Invite Only Indicators"
        ></img>
      </p>
      <div
        className={`
        info info-caution selection:bg-[#c4f6ff] selection:text-[#2c2c2c49]
        ${theme === "light" ? styles.infoWarning : styles.infoWarningDark} 
        `}
      >
        <div className="info-heading">
          <h5 className={styles.h5}>
            <span
              className={`
                ${theme === "light" ? styles.infoAlert : styles.infoAlertDark}
              `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 14 16"
              >
                <path
                  fillRule="evenodd"
                  d="M7 2.3c3.14 0 5.7 2.56 5.7 5.7s-2.56 5.7-5.7 5.7A5.71 5.71 0 0 1 1.3 8c0-3.14 2.56-5.7 5.7-5.7zM7 1C3.14 1 0 4.14 0 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm1 3H6v5h2V4zm0 6H6v2h2v-2z"
                ></path>
              </svg>
            </span>
            Info
          </h5>
        </div>
        <div className="warning-content">
          <p>
            If the "invite-only scripts" category or the MADALGO Premium
            indicators aren't visible, consider giving your browser/app a
            refresh. Should the issue persist, drop a message to
            <a
              href="mailto:support@madalgo.io"
              className={`select-none pl-1 transition-opacity duration-200  [text-decoration:none] hover:underline
              ${theme === "light" ? "text-blue-500" : "text-purple-500"}
              `}
            >
              support@madalgo.io
            </a>{" "}
            with your TradingView username, and our team will ensure you receive
            access manually within a span of 6 hours.
          </p>
        </div>
      </div>
      <p className={styles.p}>
        For swift retrieval of the indicators, consider marking them as
        favorites. Simply hit the star adjacent to the indicator names. This
        way, you can instantly access these favorited indicators.
      </p>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[11]}
          width={1203}
          height={265}
          alt="Quick Select Favorites"
        ></img>
      </p>
    </>
  );
}
