import { useEffect } from "react";
import Splide from "@splidejs/splide";
import images from "./blogfeatureimages";
import Slide from "./Slide";
import "@splidejs/splide/dist/css/splide.min.css";
import styles from "./Main.module.css";
import "./SplideArrows.css";

function Carousel() {
  const slidesData = [
    {
      index: 1,
      image: images[0],
      date: "July 23, 2023",
      category: "Education",
      description: "Introducing AI with MADALGO",
      to: "blog/introducing-ai-with-madalgo",
    },
    {
      index: 2,
      image: images[1],
      date: "December 27, 2023",
      category: "News",
      description: "Advanced Chart Analysis",
      to: "blog/advanced-chart-analysis",
    },
    // {
    //   index: 3,
    //   image: images[2],
    //   date: "July 18, 2023",
    //   category: "Coming Soon",
    //   description: "Coming Sooner than you think...",
    // },
    // {
    //   index: 4,
    //   image: images[2],
    //   date: "July 18, 2023",
    //   category: "Coming Soon",
    //   description: "Coming Sooner than you think...",
    // },
    // {
    //   index: 5,
    //   image: images[2],
    //   date: "July 18, 2023",
    //   category: "Coming Soon",
    //   description: "Coming Sooner than you think...",
    // },
    // {
    //   index: 6,
    //   image: images[2],
    //   date: "July 18, 2023",
    //   category: "Coming Soon",
    //   description: "Coming Sooner than you think...",
    // },
  ];

  useEffect(() => {
    new Splide(".splide", {
      type: "slide",
      pagination: false,
      perPage: "3",
      perMove: 1,
      drag: "free",
      snap: "true",
      gap: "0.75rem",
      padding: {
        right: "0px",
        left: "0px",
      },
      breakpoints: {
        478: {
          perPage: 1,
        },
        1700: {
          perPage: 2,
        },
      },
    }).mount();
  }, []);

  return (
    <div className="px-5 md:px-10">
      <div className="mx-auto w-full max-w-6xl">
        <div className=" py-12 md:py-16 lg:py-24 xl:py-40 1xl:py-56">
          <div className="relative w-full max-w-5xl">
            <h2 className=" text-4xl">In The News</h2>
          </div>
          <div className=" p-8 pl-0 pr-0 pt-0 lg:p-12 xl:p-16"></div>
          <div
            id="card-slider"
            className="splide w-[88vw] lg:w-[60vw]"
            aria-roledescription="carousel"
          >
            <div className="splide__arrows hide-mobile-portrait">
              <div className={`` + styles["w-embed"]}>
                <button
                  className="splide__arrow splide__arrow--prev"
                  aria-label="Previous Slide"
                  aria-controls="card-slider-track"
                  disabled=""
                >
                  <div className=" h-4 w-4 flex-none">
                    <svg
                      aria-hidden="true"
                      fill="currentColor"
                      role="img"
                      viewBox="0 0 20 21"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <title>Arrow Left</title>
                      <polygon points="16.172 9 10.101 2.929 11.515 1.515 20 10 19.293 10.707 11.515 18.485 10.101 17.071 16.172 11 0 11 0 9"></polygon>
                    </svg>
                  </div>
                </button>
              </div>
              <div className={`` + styles["w-embed"]}>
                <button className="splide__arrow splide__arrow--next">
                  <div className=" h-4 w-4 flex-none">
                    <svg
                      aria-hidden="true"
                      fill="currentColor"
                      role="img"
                      viewBox="0 0 20 21"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <title>Arrow Right</title>
                      <polygon points="16.172 9 10.101 2.929 11.515 1.515 20 10 19.293 10.707 11.515 18.485 10.101 17.071 16.172 11 0 11 0 9"></polygon>
                    </svg>
                  </div>
                </button>
              </div>
            </div>
            <div
              id="card-slider-track"
              className="splide__track flex w-full overflow-visible"
            >
              <div id="card-slider-list" className="splide__list w-full">
                {slidesData.map((slide) => (
                  <Slide
                    key={slide.index}
                    slideIndex={slide.index}
                    image={slide.image}
                    date={slide.date}
                    category={slide.category}
                    description={slide.description}
                    to={slide.to}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Carousel;
