const menuItems = [
  {
    label: "Get Started",
    icon: "🌐",
    subItems: [
      { text: "What Is MADALGO?", link: "/docs/get-started/what-is-madalgo" },
      {
        text: "What Is TradingView?",
        link: "/docs/get-started/what-is-tradingview",
      },
      {
        text: "Setup MADALGO On TradingView",
        link: "/docs/get-started/setup-madalgo",
      },
      {
        text: "Join MADALGO In Discord",
        link: "/docs/get-started/join-madalgo-discord",
      },
    ],
  },
  {
    label: "MADALGO Premium Solutions",
    icon: "📊",
    subItems: [
      {
        text: "MADALGO Pulse",
        link: "/docs/madalgo-premium-solutions/madalgo-pulse",
        dropdown: [
          {
            text: "Profit Bands W/ Signals",
            link: "/docs/madalgo-premium-solutions/madalgo-pulse/profit-bands",
          },
          {
            text: "Volume-Weighted Strength Index",
            link: "/docs/madalgo-premium-solutions/madalgo-pulse/volume-weighted-strength-index",
          },
          {
            text: "Hyper Trend",
            link: "/docs/madalgo-premium-solutions/madalgo-pulse/hyper-trend",
          },
          {
            text: "Trend Alert Candles",
            link: "/docs/madalgo-premium-solutions/madalgo-pulse/trend-alert-candles",
          },
          {
            text: "Volume Profile Pro",
            link: "/docs/madalgo-premium-solutions/madalgo-pulse/volume-profile-pro",
          },
        ],
      },
      {
        text: "MADALGO Navigator",
        link: "/docs/madalgo-premium-solutions/madalgo-navigator",
        dropdown: [
          {
            text: "Institutional Liquidity Zones",
            link: "/docs/madalgo-premium-solutions/madalgo-navigator/institutional-liquidity-zones",
          },
          {
            text: "Divergence Detector",
            link: "/docs/madalgo-premium-solutions/madalgo-navigator/divergence-detector",
          },
          {
            text: "TargetScope",
            link: "/docs/madalgo-premium-solutions/madalgo-navigator/target-scope",
          },
          {
            text: "TrendShift Clouds",
            link: "/docs/madalgo-premium-solutions/madalgo-navigator/trendshift-clouds",
          },
          {
            text: "True Range FVG",
            link: "/docs/madalgo-premium-solutions/madalgo-navigator/true-range-fvg",
          },
        ],
      },
      {
        text: "MADALGO Dynamics",
        link: "/docs/madalgo-premium-solutions/madalgo-dynamics",
        dropdown: [
          {
            text: "Institutional Money Flow",
            link: "/docs/madalgo-premium-solutions/madalgo-dynamics/institutional-money-flow",
          },
          {
            text: "Momentum Range Selector",
            link: "/docs/madalgo-premium-solutions/madalgo-dynamics/momentum-range-selector",
          },
          {
            text: "Persistent Trend Index",
            link: "/docs/madalgo-premium-solutions/madalgo-dynamics/persistent-trend-index",
          },
          {
            text: "StochastiX",
            link: "/docs/madalgo-premium-solutions/madalgo-dynamics/stochastix",
          },
          {
            text: "Weighted Pulse",
            link: "/docs/madalgo-premium-solutions/madalgo-dynamics/weighted-pulse",
          },
        ],
      },
      {
        text: "Updating MADALGO",
        link: "/docs/madalgo-premium-solutions/updating-madalgo",
      },
      {
        text: "Setting Alerts",
        link: "/docs/madalgo-premium-solutions/setting-alerts",
      },
      {
        text: "Changelogs",
        link: "/docs/madalgo-premium-solutions/changelogs",
        dropdown: [
          {
            text: "MADALGO Pulse",
            link: "/docs/madalgo-premium-solutions/changelogs/madalgo-premium-pulse",
          },
          {
            text: "MADALGO Navigator",
            link: "/docs/madalgo-premium-solutions/changelogs/madalgo-premium-navigator",
          },
          {
            text: "MADALGO Dynamics",
            link: "/docs/madalgo-premium-solutions/changelogs/madalgo-premium-dynamics",
          },
        ],
      },
    ],
  },
  {
    label: "Technical Analysis",
    icon: "📈",
    link: "/docs/technical-analysis",
    subItems: [
      { text: "Charts", link: "/docs/technical-analysis/charts" },
      { text: "Volume", link: "/docs/technical-analysis/volume" },
      {
        text: "Support & Resistance",
        link: "/docs/technical-analysis/support-resistance",
      },
      { text: "Trends", link: "/docs/technical-analysis/trends" },
      {
        text: "Technical Indicators",
        link: "/docs/technical-analysis/technical-indicators",
        dropdown: [
          {
            text: "Momentum Indicators",
            link: "/docs/technical-analysis/technical-indicators/momentum-indicators",
          },
          {
            text: "Trend Indicators",
            link: "/docs/technical-analysis/technical-indicators/trend-indicators",
          },
          {
            text: "Volatility Indicators",
            link: "/docs/technical-analysis/technical-indicators/volatility-indicators",
          },
          {
            text: "Volume Indicators",
            link: "/docs/technical-analysis/technical-indicators/volume-indicators",
          },
        ],
      },
    ],
  },
  {
    label: "Trading — Best Practices",
    icon: "📚",
    link: "/docs/best-practices",
    subItems: [
      { text: "Trader Profile", link: "/docs/best-practices/trader-profile" },
      {
        text: "Diversification",
        link: "/docs/best-practices/diversification",
      },
      {
        text: "Money Management",
        link: "/docs/best-practices/money-management",
      },
      {
        text: "Emotional Discipline",
        link: "/docs/best-practices/emotional-discipline",
      },
      {
        text: "Do's & Don'ts",
        link: "/docs/best-practices/dos-and-donts",
      },
      {
        text: "Recommended Brokers",
        link: "/docs/best-practices/recommended-brokers",
      },
    ],
  },
  {
    label: "Useful Links",
    icon: "🔗",
    subItems: [
      { text: "Discord", link: "https://discord.gg/urfhuyhTXd" },
      { text: "YouTube", link: "https://youtube.com/_madalgo" },
      { text: "X", link: "https://x.com/_madalgo" },
      { text: "FB", link: "https://fb.com/madalgo" },
      {
        text: "Free Indicators",
        link: "https://www.tradingview.com/u/madalgo/#published-scripts",
      },
      {
        text: "Help Center - Trading View",
        link: "https://www.tradingview.com/support/",
      },
    ],
  },
];

export default menuItems;
