import React, { memo } from "react";

function TechnicalAnalysisWidget({ containerTA }) {
  return (
    <div
      className="tradingview-widget-container"
      ref={containerTA}
      style={{ height: "100%", width: "100%" }}
    >
      <div
        className="tradingview-widget-container__widget"
        style={{ height: "calc(100% - 32px)", width: "100%" }}
      ></div>
    </div>
  );
}

export default memo(TechnicalAnalysisWidget);
