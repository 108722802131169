import React, { useState } from "react";
import { motion } from "framer-motion";
import Blur from "./Blur";
import styles from "./Main.module.css";
import { Amplify } from "aws-amplify";
import { API } from "aws-amplify";

import awsExports from "../aws-exports";
Amplify.configure(awsExports);

const SpinnerMobile = () => {
  return (
    <div className={`${styles.spinnerContainer}`}>
      <div className={`${styles.spinnerMobile}`}></div>
    </div>
  );
};

export default function Careers() {
  const [email, setEmail] = useState("");
  const [honeypot, setHoneypot] = useState("");
  const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [submissionStatus, setSubmissionStatus] = useState("idle");
  const [emailError, setEmailError] = useState("");

  const resetFormLargeCTA = () => {
    setHoneypot("");
    setSubmissionStatus("idle");
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const ArrowIcon = () => (
    <svg
      className="pt-1"
      width="100%"
      height="100%"
      viewBox="0 0 20 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 5H19M19 5L14.6667 1M19 5L14.6667 9"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </svg>
  );

  const list = {
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.2,
        ease: [0.04, 0.62, 0.23, 0.98],
      },
    },
    hidden: {
      opacity: 0,
      transition: {
        when: "afterChildren",
        ease: [0.04, 0.62, 0.23, 0.98],
      },
    },
  };
  const item = {
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 2,
        ease: [0.04, 0.62, 0.23, 0.98],
      },
    },
    hidden: {
      opacity: 0,
      y: 50,
      transition: {
        duration: 2,
        ease: [0.04, 0.62, 0.23, 0.98],
      },
    },
  };

  async function handleSubmit(e) {
    e.preventDefault();
    let hasError = false;
    let timer;

    if (!email) {
      setEmailError("Email is required.");
      hasError = true;
    } else if (!isValidEmail.test(email)) {
      setEmailError("Invalid email address.");
      hasError = true;
    }

    if (honeypot) {
      return;
    }

    if (hasError) {
      return;
    }

    setSubmissionStatus("loading");
    setEmailError("");

    const data = {
      body: {
        email: email,
      },
    };

    try {
      const apiData = await API.post("madalgocontactapi", "/contact", data);
      console.log({ apiData });
      setSubmissionStatus("success");
      setEmail("");
      timer = setTimeout(() => {
        resetFormLargeCTA();
      }, 3000);
    } catch (error) {
      console.error(error);
      setSubmissionStatus("error");
    }
    return () => clearTimeout(timer);
  }

  return (
    <div
      id="careers"
      className="box-content flex min-h-screen flex-col items-center justify-center p-8 pt-40"
    >
      <div
        id="blur"
        className=" absolute bottom-[0%] left-[0%] right-[0%] top-[0%] z-[-1] block"
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 2, delay: 0.4 }}
          id="gradient-blur-group-1"
          className="lg:w[85vw] absolute bottom-[55.8rem] left-auto right-[-6.6rem] top-auto h-[100vw] w-[95vw] opacity-60 filter-none lg:-right-80 lg:bottom-[40rem] lg:h-[85vw] xl:bottom-[45rem] xl:left-[5.7rem] xl:max-h-[100rem] xl:max-w-[100rem]"
        >
          <Blur />
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 2, delay: 0.8 }}
          id="gradient-blur-group-2"
          className="lg:w[85vw] absolute bottom-[10.8rem] left-auto right-[10.6rem] top-auto h-[100vw] w-[95vw] opacity-60 filter-none sm:bottom-14  md:-bottom-80 md:w-screen lg:-left-80 lg:h-[85vw] xl:left-[-5.7rem] xl:top-60 xl:max-h-[100rem] xl:max-w-[100rem]"
        >
          <Blur />
        </motion.div>
      </div>
      <motion.div
        id="wrapper"
        className="box-content flex max-h-[640px] w-full max-w-6xl flex-col items-center justify-around rounded-[2rem] border-[1px_solid_rgba(255,_255,_255,_0.125)] bg-white bg-opacity-10 p-6 shadow-2xl md:box-border md:p-16 lg:flex-row lg:p-[8rem_4rem] xl:p-[8rem_6rem]"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 2 }}
      >
        <motion.div
          id="inner"
          className="mb-10 max-w-xl lg:mb-0 lg:mr-8"
          initial="hidden"
          animate="visible"
          variants={list}
        >
          <motion.h1
            className="mb-6 text-xl font-bold leading-8 tracking-tight"
            variants={item}
          >
            Hey, how would you like to join MADALGO?
          </motion.h1>
          <motion.p
            className="mb-2 text-sm leading-6 md:mb-4 md:text-base md:leading-7"
            variants={item}
          >
            At MADALGO, we're always on the lookout for bright minds and
            passionate individuals. While we're in the early stages of our
            journey and aren't hiring just yet, we're excited about the future
            and the potential to expand our team.
          </motion.p>
          <motion.p
            className="mb-2 text-sm leading-6 md:mb-4 md:text-base md:leading-7"
            variants={item}
          >
            We believe in innovation, dedication, and the power of collective
            effort. If you share our vision and values, we'd love for you to
            keep us in mind. Opportunities to join us and make an impact are on
            the horizon.
          </motion.p>
          <motion.p
            className="text-sm leading-6 md:mb-12 md:text-base md:leading-7"
            variants={item}
          >
            Keep an eye on this space or subscribe to our newsletter to stay
            updated on career opportunities and other news from MADALGO.
          </motion.p>
        </motion.div>
        <motion.div
          className="flex w-full max-w-xl flex-col items-start lg:w-auto"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 2, delay: 0.8 }}
        >
          <label htmlFor="email" className="mb-2 hidden text-sm">
            Stay updated:
          </label>
          <div className="flex w-full flex-col lg:w-64">
            <input
              type="text"
              name="honeypot"
              value={honeypot}
              onChange={(e) => setHoneypot(e.target.value)}
              style={{ display: "none" }}
            />
            <input
              type="email"
              placeholder="Your email"
              value={email}
              onChange={handleEmailChange}
              className="mb-2 flex-grow rounded border border-purple-300 bg-transparent px-3 py-2 text-purple-400 caret-purple-400 placeholder:text-purple-400 hover:border-purple-500 focus:border-purple-500 focus:outline-none "
            />
            <button
              onClick={handleSubmit}
              className="group flex w-full items-center justify-center rounded bg-purple-600 px-4 py-2 text-white shadow-2xl shadow-purple-950 transition-[all_.3s_easeInOut]  hover:bg-purple-700 focus:outline-none lg:w-auto"
              disabled={submissionStatus === "loading"}
            >
              {submissionStatus === "loading" ? (
                <SpinnerMobile />
              ) : submissionStatus === "success" ? (
                "Email Sent Successfully"
              ) : submissionStatus === "error" ? (
                "Error Sending Email"
              ) : (
                <>
                  <span className="transition-[all_.3s_easeInOut] group-hover:scale-110">
                    Subscribe
                  </span>
                  <span className="ml-2 h-[19.2px] w-4 transition-[all_.3s_easeInOut] group-hover:translate-x-2 group-hover:scale-110">
                    <ArrowIcon />
                  </span>
                </>
              )}
            </button>
            {submissionStatus !== "loading" && (
              <p className="error-message m-0 mt-4 text-sm text-red-500">
                {emailError}
              </p>
            )}
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
}
