import { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import styles from "../Docs.module.css";
import { useTOC } from "../TOCContext";
import DocImages from "../DocImages";
import ThemeContext from "../ThemeContext";

const entries = [
  {
    displayText: "Features",
    urlFragment: "features",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "Main Settings",
    urlFragment: "main-settings",
    headingType: "h2",
    subEntries: [
      {
        displayText: "Source",
        urlFragment: "source",
        headingType: "h3",
      },
      {
        displayText: "Div Mode",
        urlFragment: "div-mode",
        headingType: "h3",
      },
      {
        displayText: "Gradient",
        urlFragment: "gradient",
        headingType: "h3",
      },
    ],
  },
  {
    displayText: "Adjusting Settings",
    urlFragment: "adjusting-settings",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "Style Settings",
    urlFragment: "style-settings",
    headingType: "h2",
    subEntries: [],
  },
];
export { entries };

export default function DocsMADALGODynamics() {
  const { addToTOC, removeFromTOC } = useTOC();
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    entries.forEach(addToTOC);
    return () => {
      entries.forEach((entry) => removeFromTOC(entry));
    };
  }, [addToTOC, removeFromTOC]);

  return (
    <>
      <h1 className={styles.h1}>MADALGO Dynamics</h1>
      <p className={styles.p}>
        MADALGO Dynamics is the third key component of the toolkit offered by
        MADALGO, offering a wide spectrum of price action related tools, zone &
        divergence finders, and much more . It equips traders with precisive
        tools to make enhanced trading decisions based on the current market
        structure. Provided below are the pages describing each of the features
        in the main indicator in detail:
      </p>

      <h2 id="features" className={`scroll-mt-2 ${styles["h2"]}`}>
        Features
        <Link
          to="#features"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <Link
        to="institutional-money-flow"
        className={`
          ${theme === "light" ? styles.changelogLink : styles.changelogLinkDark}
        `}
      >
        <span
          style={{
            userSelect: "none",
            marginRight: 10,
            marginBottom: 3,
          }}
        >
          »
        </span>
        Institutional Money Flow
      </Link>
      <Link
        to="momentum-range-selector"
        className={`
          ${theme === "light" ? styles.changelogLink : styles.changelogLinkDark}
        `}
      >
        <span
          style={{
            userSelect: "none",
            marginRight: 10,
            marginBottom: 3,
          }}
        >
          »
        </span>
        Momentum Range Selector
      </Link>
      <Link
        to="persistent-trend-index"
        className={`
          ${theme === "light" ? styles.changelogLink : styles.changelogLinkDark}
        `}
      >
        <span
          style={{
            userSelect: "none",
            marginRight: 10,
            marginBottom: 3,
          }}
        >
          »
        </span>
        Persistent Trend Index
      </Link>
      <Link
        to="stochastix"
        className={`
          ${theme === "light" ? styles.changelogLink : styles.changelogLinkDark}
        `}
      >
        <span
          style={{
            userSelect: "none",
            marginRight: 10,
            marginBottom: 3,
          }}
        >
          »
        </span>
        Stochastix
      </Link>
      <Link
        to="weighted-pulse"
        className={`
          ${theme === "light" ? styles.changelogLink : styles.changelogLinkDark}
        `}
      >
        <span
          style={{
            userSelect: "none",
            marginRight: 10,
            marginBottom: 3,
          }}
        >
          »
        </span>
        Weighted Pulse
      </Link>

      <h2 id="main-settings" className={`scroll-mt-2 ${styles["h2"]}`}>
        Main Settings
        <Link
          to="#main-settings"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        The MADALGO Dynamics like the Navigator and Pulse presents an array of
        settings influencing its behavior and visual aspects. These settings are
        specific to their corresponding features and are elaborated on their
        respective pages. The indicator comprises multiple sections for
        settings. The foundational settings are detailed in the subsequent
        sections:
      </p>

      <h3 id="source" className={`scroll-mt-2 ${styles["h3"]}`}>
        Source
        <Link
          to="#source"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        The "Source" setting in MADALGO Dynamics is a critical choice that
        determines the specific price data used for calculations in various
        features of the indicator. It offers options such as "open," "high,"
        "low," "close," and "OHLC4" (the average of open, high, low, and close
        prices). Selecting the appropriate source is essential, as it directly
        impacts the accuracy and relevance of the insights provided by the
        indicator. Different features may require different sources to generate
        meaningful signals and trend analysis.
      </p>

      <h3 id="div-mode" className={`scroll-mt-2 ${styles["h3"]}`}>
        Div Mode
        <Link
          to="#div-mode"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        The "Div Mode" setting within MADALGO Dynamics holds a pivotal role in
        the Divergence Finder feature. This feature acts as a powerful tool to
        identify and visualize active divergences across the MADALGO Dynamics
        Oscillators. Divergences, often regarded as key reversal signals, occur
        when the price action and the oscillator's movement exhibit a
        discrepancy. By enabling the "Div Mode," you unlock the capability to
        automatically detect these divergences, enhancing your ability to
        identify potential trend shifts and trading opportunities with greater
        precision. This setting streamlines the analysis process, helping you
        make informed decisions based on these notable market divergences.
      </p>

      <h3 id="gradient" className={`scroll-mt-2 ${styles["h3"]}`}>
        Gradient
        <Link
          to="#gradient"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        The "Gradient" setting serves as a fundamental visual element in MADALGO
        Dynamics. This feature introduces a dynamic color gradient that adapts
        to the changing market conditions. As market trends shift and momentum
        evolves, the gradient effectively reflects these variations through its
        color spectrum. This visual cue aids traders in intuitively
        comprehending the transitioning market dynamics. When activated, the
        gradient provides a comprehensive visual representation, offering
        valuable insights into the strength and direction of trends. By
        harmonizing with the indicator's other components, the gradient
        amplifies the overall readability and interpretability of the MADALGO
        Dynamics, contributing to more effective trading decisions.
      </p>

      <h2 id="adjusting-settings" className={`scroll-mt-2 ${styles["h2"]}`}>
        Adjusting Settings
        <Link
          to="#adjusting-settings"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[156]}
          width={605}
          height={159}
          className="mx-auto"
          alt="Dynamics Adjusting Settings"
        ></img>
      </p>
      <p className={styles.p}>
        To fine-tune settings, hover your cursor over the indicator title,
        prompting a list of icons. Click on the gear ⚙️ icon to open the
        settings panel.
      </p>
      <p className={styles.p}>
        Alternatively, double-click any element displayed by the indicator, be
        it signals, overlaid indicators, or the dashboard, to access the
        settings panel.
      </p>

      <h2 id="style-settings" className={`scroll-mt-2 ${styles["h2"]}`}>
        Style Settings
        <Link
          to="#style-settings"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        Every feature is customizable in terms of color, accommodating
        individual user preferences for a seamless UI/UX experience.
      </p>
    </>
  );
}
