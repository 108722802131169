import { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { Link, useNavigate } from "react-router-dom";

const LeftArrow = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height=".8em"
    fill="currentcolor"
    viewBox="0 0 448 512"
  >
    <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
  </svg>
);

const CancelMembership = ({ subscription }) => {
  const navigate = useNavigate();
  const [endOfBillingCycleDate, setEndOfBillingCycleDate] = useState("");
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [isCancelling, setIsCancelling] = useState(false);

  useEffect(() => {
    // Redirect to login if not authenticated
    checkAuthState();
    async function checkAuthState() {
      try {
        await Auth.currentAuthenticatedUser();
      } catch {
        navigate("/login");
      }
    }
  }, [navigate]);

  async function cancelSubscription() {
    setIsCancelling(true);
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      const userEmail = currentUser.attributes.email;
      const jwtToken = currentUser.signInUserSession.idToken.jwtToken;

      const response = await fetch(process.env.REACT_APP_CANCEL_SUBSCRIPTION, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify({ email: userEmail }),
      });

      const responseData = await response.json();
      if (!response.ok) {
        throw new Error(responseData.error || "Network response was not ok");
      }

      // Handle successful cancellation
      console.log("Subscription cancelled successfully:", responseData);
      setMessage("Subscription cancelled successfully.");
      setMessageType("success");
      setTimeout(() => logoutAndRedirect(), 5000); // Redirect after 5 seconds
    } catch (error) {
      console.error("Error cancelling subscription:", error.message);
      setMessage("Failed to cancel subscription: " + error.message);
      setMessageType("error");
    } finally {
      setIsCancelling(false); // Enable the button again if there was an error
    }
  }

  async function logoutAndRedirect() {
    try {
      await Auth.signOut();
      navigate("/seeyoulater");
    } catch (error) {
      console.error("Error signing out: ", error.message);
      setMessage("Error signing out: " + error.message);
      setMessageType("error");
    }
  }

  useEffect(() => {
    if (subscription) {
      const nextBillingDate = new Date(
        subscription.nextBillingDate,
      ).toLocaleDateString();
      setEndOfBillingCycleDate(nextBillingDate);
    }
  }, [subscription]);

  return (
    <div className="flex flex-col bg-[#1e1e1e] p-4">
      <div className="pb-8">
        <Link
          to="/dashboard"
          className="flex flex-row items-center space-x-4 text-indigo-500 hover:underline"
        >
          <span>{LeftArrow}</span>
          <p>Return to Dashboard</p>
        </Link>
      </div>
      <div className="w-full items-center justify-center text-white">
        <h1 className="mb-4 text-3xl font-bold">Cancel your plan?</h1>
        <p className="mb-8">
          We understand. If you cancel, it'll take effect on{" "}
          <strong>{endOfBillingCycleDate}.</strong> You'll still be able to use
          MADALGO until then.
        </p>
        <div className="max-w-md bg-[#171717] p-6">
          <button
            onClick={cancelSubscription}
            className={`w-full rounded-[.125rem] bg-blue-500 px-4 py-2 text-sm text-white transition duration-150 ${
              isCancelling ? "bg-gray-400" : "hover:bg-blue-600"
            }`}
            disabled={isCancelling}
          >
            {isCancelling ? "Cancelling..." : "Finish Cancellation"}
          </button>
          <p className="mt-4 text-center text-sm">
            Come back within 10 months and your preferences and settings will be
            waiting.
          </p>
        </div>
        {message && (
          <div
            className={`mt-4 text-center text-sm ${
              messageType === "success" ? "text-green-500" : "text-red-500"
            }`}
          >
            {message}
          </div>
        )}
      </div>
    </div>
  );
};

export default CancelMembership;
