import bgImage from "./imgs/Digital.webp";
import image1 from "./imgs/mad1.webp";
import image2 from "./imgs/mad2.webp";
import image3 from "./imgs/mad3.webp";
import image4 from "./imgs/alertsfix.webp";
import image5 from "./imgs/mesh.webp";
import image6 from "./imgs/mesh1.webp";
import image7 from "./imgs/mesh2.webp";
import bell from "./imgs/bell.webp";
import btcCoin from "./imgs/btc_coin.webp";
import bull from "./imgs/bull.webp";
import goldCheck from "./imgs/gold_check.webp";
import goldEthCoin from "./imgs/gold_eth_coin.webp";
import goldTrimRocket from "./imgs/gold_trim_rocket.webp";
import gradientCheck from "./imgs/gradient_check.webp";
import gradientEthCheck from "./imgs/gradient_eth_coin.webp";
import mesh3 from "./imgs/mesh3.webp";
import mesh4 from "./imgs/mesh4.webp";
import mesh5 from "./imgs/mesh5.webp";
import upArrowStock from "./imgs/up_arrow_stock.webp";
import CtaDBG from "./imgs/ctadbg.webp";
import threedchat from "./imgs/threedchat.webp";
import threedchat1 from "./imgs/threedchat1.webp";
import discord from "./imgs/discord.webp";
import blurlinecoin from "./imgs/blurlinecoin.webp";
import teslablurred from "./imgs/teslablurred.webp";
import specta from "./imgs/specta.webp";
import creators from "./imgs/creators.jpg";
import affiliates from "./imgs/affiliates.jpg";
import advocates from "./imgs/advocates.jpg";
import earth1 from "./imgs/earth1.png";
import ai1 from "./imgs/ai1.png";
import stock from "./imgs/stock.png";
import bg from "./imgs/sbg.png";
import MG from "./imgs/MG.png";
import MGFullWhite from "./imgs/M-MADALGO-WHITE.png";
import MGFullBlack from "./imgs/M-MADALGO-BLACK.png";
import MGFullG from "./imgs/M-MADALGO-G.png";
import MGWhite from "./imgs/M-WHITE.png";
import MGBlack from "./imgs/M-BLACK.png";
import MGG from "./imgs/M-G.png";

const images = [
  bgImage,
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  bell,
  btcCoin,
  bull,
  goldCheck,
  goldEthCoin,
  goldTrimRocket,
  gradientCheck,
  gradientEthCheck,
  mesh3,
  mesh4,
  mesh5,
  upArrowStock,
  CtaDBG,
  threedchat,
  threedchat1,
  discord,
  blurlinecoin,
  teslablurred,
  specta,
  creators,
  affiliates,
  advocates,
  earth1,
  ai1,
  stock,
  bg,
  MG,
  MGFullWhite,
  MGFullBlack,
  MGFullG,
  MGWhite,
  MGBlack,
  MGG,
];

export default images;
