import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Amplify, Auth } from "aws-amplify";
import awsconfig from "./aws-exports";

Amplify.configure({
  ...awsconfig,
  Auth: {
    ...awsconfig.Auth,
    cookieStorage: {
      domain: ".madalgo.io",
      path: "/",
      expires: 365,
      secure: true,
    },
  },
  API: {
    graphql_endpoint: awsconfig.aws_appsync_graphqlEndpoint,
    graphql_headers: async () => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        const token = currentUser.signInUserSession.idToken.jwtToken;
        return { Authorization: token };
      } catch (e) {
        console.error(e);
        return {};
      }
    },
  },
  Storage: {
    AWSS3: {
      bucket: "profile-photos-madalgo",
      region: "us-east-1",
      level: "private",
    },
  },
});

require("events").EventEmitter.defaultMaxListeners = 20;

const root = ReactDOM.createRoot(document.getElementById("smooth-wrapper"));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
