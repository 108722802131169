import { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import styles from "../Docs.module.css";
import { useTOC } from "../TOCContext";
import DocImages from "../DocImages";
import ThemeContext from "../ThemeContext";

const entries = [
  {
    displayText: "How To Create A Account",
    urlFragment: "how-to-create-a-account",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "Exploring TradingView Charts",
    urlFragment: "exploring-tradingview-charts",
    headingType: "h2",
    subEntries: [
      {
        displayText: "Launching a Chart on TradingView",
        urlFragment: "launching-a-chart-on-tradingview",
        headingType: "h3",
      },
      {
        displayText: "Modifying The Chart Symbol",
        urlFragment: "modifying-the-chart-symbol",
        headingType: "h3",
      },
      {
        displayText: "Changing The Timeframe",
        urlFragment: "changing-the-timeframe",
        headingType: "h3",
      },
      {
        displayText: "Adding Indicators To The Chart",
        urlFragment: "adding-indicators-to-the-chart",
        headingType: "h3",
      },
      {
        displayText: "Preserving Your Chart Configuration",
        urlFragment: "preserving-your-chart-configuration",
        headingType: "h3",
      },
    ],
  },
];
export { entries };

export default function DocsTradingView() {
  const { addToTOC, removeFromTOC } = useTOC();
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    entries.forEach(addToTOC);
    return () => {
      entries.forEach((entry) => removeFromTOC(entry));
    };
  }, [addToTOC, removeFromTOC]);

  return (
    <>
      <h1 className={styles.h1}>What Is TradingView?</h1>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[0]}
          width={1200}
          height={630}
          alt="TradingView Logo"
        ></img>
      </p>

      <p className={styles.p}>
        <a
          href="https://www.tradingview.com/"
          target="_blank"
          rel="noopener noreferrer"
          className={`select-none transition-opacity duration-200 [text-decoration:none] hover:underline
              ${theme === "light" ? "text-blue-500" : "text-purple-500"}
              `}
        >
          TradingView{" "}
        </a>
        serves as a versatile charting platform and an interactive community
        where enthusiasts can examine financial charts and employ a myriad of
        tools to uncover potential in various markets like Stocks, Forex,
        Cryptocurrencies, Futures, and Commodities. Additionally, users of
        TradingView can link most brokerage accounts to execute trades directly
        from the platform, capitalizing on opportunities as they surface in
        real-time.
      </p>
      <div
        className={`
        info info-caution selection:bg-[#c4f6ff] selection:text-[#2c2c2c49]
        ${theme === "light" ? styles.infoWarning : styles.infoWarningDark} 
        `}
      >
        <div className="info-heading">
          <h5 className={styles.h5}>
            <span
              className={`
                ${theme === "light" ? styles.infoAlert : styles.infoAlertDark}
              `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 14 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M7 2.3c3.14 0 5.7 2.56 5.7 5.7s-2.56 5.7-5.7 5.7A5.71 5.71 0 0 1 1.3 8c0-3.14 2.56-5.7 5.7-5.7zM7 1C3.14 1 0 4.14 0 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm1 3H6v5h2V4zm0 6H6v2h2v-2z"
                ></path>
              </svg>
            </span>
            Info
          </h5>
        </div>
        <div className="warning-content">
          <p>
            This exceptional platform is where we showcase our MADALGO Premium
            indicator collection. Register for a complimentary account on
            TradingView, and upon
            <Link
              to="/#premium-packages"
              target="_blank"
              rel="noopener noreferrer"
              className={`select-none pl-2 transition-opacity duration-200  [text-decoration:none] hover:underline
              ${theme === "light" ? "text-blue-500" : "text-purple-500"}
              `}
            >
              subscription
            </Link>{" "}
            on our site, you'll gain immediate access to our tools right from
            your indicator's directory.
          </p>
        </div>
      </div>
      <h2
        id="how-to-create-a-account"
        className={`scroll-mt-2 ${styles["h2"]}`}
      >
        How To Create A Account
        <Link
          to="#how-to-create-a-account"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        To initiate a TradingView account, navigate to TradingView's{" "}
        <Link
          className={`${styles["docsInnerLink"]}`}
          to="https://www.tradingview.com/u/madalgo/#published-scripts"
          target="_blank"
          rel="noopener noreferrer"
        >
          main page
        </Link>
        . Tap on "Log in" situated at the upper right corner, followed by
        "Register" at the window's base. Registration can be done using your
        social media credentials or an email account.
      </p>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[1]}
          width={1200}
          height={630}
          alt="TradingView Sign In"
        ></img>
      </p>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[2]}
          width={1200}
          height={630}
          alt="TradingView Sign In Main"
        ></img>
      </p>
      <h2
        id="exploring-tradingview-charts"
        className={`scroll-mt-2 ${styles["h2"]}`}
      >
        Exploring TradingView Charts
        <Link
          to="#exploring-tradingview-charts"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <h3
        id="launching-a-chart-on-tradingview"
        className={`scroll-mt-2 ${styles["h3"]}`}
      >
        Launching a Chart on TradingView
        <Link
          to="#launching-a-chart-on-tradingview"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[3]}
          width={1200}
          height={630}
          alt="TradingView Products Tab"
        ></img>
      </p>
      <p className={styles.p}>
        To display a chart on TradingView, select 'Products' positioned at the
        website's upper left corner after logging in. Then select Supercharts
        from the dropdown menu
      </p>
      <h3
        id="modifying-the-chart-symbol"
        className={`scroll-mt-2 ${styles["h3"]}`}
      >
        Modifying The Chart Symbol
        <Link
          to="#modifying-the-chart-symbol"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[4]}
          width={1200}
          height={630}
          alt="Modify Chart Symbol"
        ></img>
      </p>
      <p className={styles.p}>
        To adjust the chart's symbol, tap the upper left area showcasing the
        active chart identifier.
      </p>
      <div
        className={`
        info info-caution selection:bg-[#c4f6ff] selection:text-[#2c2c2c49]
        ${theme === "light" ? styles.infoWarning : styles.infoWarningDark} 
        `}
      >
        <div className="info-heading">
          <h5 className={styles.h5}>
            <span
              className={`
                ${theme === "light" ? styles.infoAlert : styles.infoAlertDark}
              `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 14 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M7 2.3c3.14 0 5.7 2.56 5.7 5.7s-2.56 5.7-5.7 5.7A5.71 5.71 0 0 1 1.3 8c0-3.14 2.56-5.7 5.7-5.7zM7 1C3.14 1 0 4.14 0 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm1 3H6v5h2V4zm0 6H6v2h2v-2z"
                ></path>
              </svg>
            </span>
            Info
          </h5>
        </div>
        <div className="warning-content">
          <p>
            Alternatively, just key in the desired symbol using your keyboard.
          </p>
        </div>
      </div>
      <p className={styles.p}>
        By selecting it, you'll be presented with a search option for distinct
        symbols. Filters by market or exchange are also available for a refined
        search.
      </p>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[5]}
          width={1200}
          height={630}
          alt="Symbol Search"
        ></img>
      </p>
      <h3 id="changing-the-timeframe" className={`scroll-mt-2 ${styles["h3"]}`}>
        Changing The Timeframe
        <Link
          to="#changing-the-timeframe"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[6]}
          width={1200}
          height={324}
          alt="Changing The Timeframe"
        ></img>
      </p>
      <p className={styles.p}>
        The time interval governs how often a fresh candle appears on the
        display. To modify your chart's time interval, simply tap the section
        next to the ticker label and choose your preferred duration.
      </p>
      <div
        className={`
        info info-caution selection:bg-[#c4f6ff] selection:text-[#2c2c2c49]
        ${theme === "light" ? styles.infoWarning : styles.infoWarningDark} 
        `}
      >
        <div className="info-heading">
          <h5 className={styles.h5}>
            <span
              className={`
                ${theme === "light" ? styles.infoAlert : styles.infoAlertDark}
              `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 14 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M7 2.3c3.14 0 5.7 2.56 5.7 5.7s-2.56 5.7-5.7 5.7A5.71 5.71 0 0 1 1.3 8c0-3.14 2.56-5.7 5.7-5.7zM7 1C3.14 1 0 4.14 0 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm1 3H6v5h2V4zm0 6H6v2h2v-2z"
                ></path>
              </svg>
            </span>
            Info
          </h5>
        </div>
        <div className="warning-content">
          <p>
            Directly keying in the desired time interval on your keyboard is
            another quick alternative.
          </p>
        </div>
      </div>
      <h3
        id="adding-indicators-to-the-chart"
        className={`scroll-mt-2 ${styles["h3"]}`}
      >
        Adding Indicators To The Chart
        <Link
          to="#adding-indicators-to-the-chart"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[7]}
          width={1200}
          height={324}
          alt="Adding Indicators"
        ></img>
      </p>
      <p className={styles.p}>
        Hit the "Indicators" icon atop the chart to reveal a dropdown menu. Look
        for your desired indicator and tap on it to incorporate it into the
        chart.
      </p>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[8]}
          width={1200}
          height={630}
          alt="Idicators Input Field"
        ></img>
      </p>
      <h3
        id="preserving-your-chart-configuration"
        className={`scroll-mt-2 ${styles["h3"]}`}
      >
        Preserving Your Chart Configuration
        <Link
          to="#preserving-your-chart-configuration"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[9]}
          width={1200}
          height={324}
          alt="Save Your Chart"
        ></img>
      </p>
      <p className={styles.p}>
        TradingView enables you to curate, eliminate, and store chart
        configurations. After any alteration to a layout, saving becomes
        imperative. Simply click on the cloud icon situated at the chart's upper
        right corner.
      </p>
      <div
        className={`
        info info-caution selection:bg-[#c4f6ff] selection:text-[#2c2c2c49]
        ${theme === "light" ? styles.infoWarning : styles.infoWarningDark} 
        `}
      >
        <div className="info-heading">
          <h5 className={styles.h5}>
            <span
              className={`
                ${theme === "light" ? styles.infoAlert : styles.infoAlertDark}
              `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 14 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M7 2.3c3.14 0 5.7 2.56 5.7 5.7s-2.56 5.7-5.7 5.7A5.71 5.71 0 0 1 1.3 8c0-3.14 2.56-5.7 5.7-5.7zM7 1C3.14 1 0 4.14 0 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm1 3H6v5h2V4zm0 6H6v2h2v-2z"
                ></path>
              </svg>
            </span>
            Info
          </h5>
        </div>
        <div className="warning-content">
          <p>
            A swift method to store your chart setup is by pressing ctrl + s (or
            cmd + s for macOS users) on your keyboard.
          </p>
        </div>
      </div>
    </>
  );
}
