import { Routes, Route } from "react-router-dom";
import Disclaimer from "./Disclaimer";
import TermsOfService from "./TermsOfService";
import PrivacyPolicy from "./PrivacyPolicy";
import LegalMain from "./LegalMain";

function Legal() {
  return (
    <Routes>
      <Route path="/" element={<LegalMain />} />
      <Route path="disclaimer" element={<Disclaimer />} />
      <Route path="terms-of-service" element={<TermsOfService />} />
      <Route path="privacy-policy" element={<PrivacyPolicy />} />
    </Routes>
  );
}

export default Legal;
