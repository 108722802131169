import { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import styles from "../Docs.module.css";
import { useTOC } from "../TOCContext";
import DocImages from "../DocImages";
import ThemeContext from "../ThemeContext";

const entries = [
  {
    displayText: "Activation and Settings",
    urlFragment: "activation-and-settings",
    headingType: "h2",
    subEntries: [
      {
        displayText: "Activation",
        urlFragment: "activation",
        headingType: "ol",
      },
      {
        displayText: "Reverse Mode",
        urlFragment: "reverse-mode",
        headingType: "ol",
      },
      {
        displayText: "Fixed Range",
        urlFragment: "fixed-range",
        headingType: "ol",
      },
      {
        displayText: "Fixed Range",
        urlFragment: "fixed-range",
        headingType: "ol",
      },
      {
        displayText: "Lookback",
        urlFragment: "lookback",
        headingType: "ol",
      },
      {
        displayText: "Styling",
        urlFragment: "styling",
        headingType: "ol",
      },
    ],
  },
  {
    displayText: "Target Calculation and Display",
    urlFragment: "target-calculation-and-display",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "Importance and Use",
    urlFragment: "importance-and-use",
    headingType: "h2",
    subEntries: [
      {
        displayText: "Precise Trade Execution",
        urlFragment: "precise-trade-execution",
        headingType: "h3",
      },
      {
        displayText: "Risk Management",
        urlFragment: "risk-management",
        headingType: "h3",
      },
    ],
  },
];
export { entries };

export default function DocsMADALGONavigatorTargetScope() {
  const { theme } = useContext(ThemeContext);
  const { addToTOC, removeFromTOC } = useTOC();

  useEffect(() => {
    entries.forEach(addToTOC);
    return () => {
      entries.forEach((entry) => removeFromTOC(entry));
    };
  }, [addToTOC, removeFromTOC]);

  return (
    <>
      <h1 className={styles.h1}>TargetScope</h1>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[134]}
          width={647}
          height={399}
          className="mx-auto"
          alt="TargetScope"
        ></img>
      </p>
      <p className={styles.p}>
        The TargetScope is a versatile tool within MADALGO Navigator that allows
        traders to identify potential entry, stop-loss, and target levels based
        on aggregated visible price ranges. With flexible customization options,
        TargetScope can enhance your trading strategy by assisting you in
        setting optimal trade levels.
      </p>

      <h2
        id="activation-and-settings"
        className={`scroll-mt-2 ${styles["h2"]}`}
      >
        Activation and Settings
        <Link
          to="#activation-and-settings"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        Before using the TargetScope indicator, it's essential to familiarize
        yourself with its customizable settings. Here are the key settings you
        can adjust:
      </p>
      <ol id="activation" className={styles.olBullet}>
        <li>Activation</li>
      </ol>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[135]}
          width={647}
          height={399}
          className="mx-auto"
          alt="TargetScope Settings"
        ></img>
      </p>
      <p className={styles.p}>
        Enable the TargetScope feature by toggling the "AVRTF" switch to the ON
        position in the main settings of MADALGO Navigator. This activates the
        TargetScope function and allows you to specify various parameters for
        calculating target levels.
      </p>
      <ol id="reverse-mode" className={styles.olBullet}>
        <li>Reverse Mode</li>
      </ol>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[136]}
          width={322}
          height={79}
          className="mx-auto"
          alt="TargetScope Reverse Mode"
        ></img>
      </p>
      <p className={styles.p}>
        Toggle the "Reverse" switch to the ON position to enable the reverse
        mode. In this mode, TargetScope can determine target levels for both
        bullish and bearish scenarios, enhancing its versatility.
      </p>

      <ol id="fixed-range" className={styles.olBullet}>
        <li>Fixed Range</li>
      </ol>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[137]}
          width={119}
          height={125}
          className="mx-auto"
          alt="TargetScope Fixed Range"
        ></img>
      </p>
      <p className={styles.p}>
        Enable the "Fixed Range?" switch if you want to use a fixed range for
        calculating target levels. When enabled, the TargetScope will consider a
        fixed range for its calculations, specified by the "Lookback" input.
      </p>
      <ol id="lookback" className={styles.olBullet}>
        <li>Lookback</li>
      </ol>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[138]}
          width={464}
          height={119}
          className="mx-auto"
          alt="TargetScope Lookback"
        ></img>
      </p>
      <p className={styles.p}>
        When "Fixed Range?" is enabled, specify the number of bars to look back
        for calculating the fixed range. This allows you to customize the period
        over which TargetScope derives its calculations.
      </p>
      <ol id="styling" className={styles.olBullet}>
        <li>Styling</li>
      </ol>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[139]}
          width={259}
          height={56}
          className="mx-auto"
          alt="TargetScope Styling"
        ></img>
      </p>
      <p className={styles.p}>Customize the appearance of target levels:</p>
      <ol className={styles.ol}>
        <li>
          Entry, Stop Loss, and Target Colors: Assign colors to the entry,
          stop-loss, and target levels. These colors help differentiate between
          different levels on the chart.
        </li>
        <li>
          Soft Stop Loss Color: Define a color for the soft stop-loss level,
          which provides a visual representation of a more lenient stop-loss.
        </li>
      </ol>
      <div
        className={`
        info info-tip selection:bg-[#c4f6ff] selection:text-[#2c2c2c49]
        ${theme === "light" ? styles.infoTipInfo : styles.infoTipInfoDark} 
        `}
      >
        <div className="info-heading">
          <h5 className={styles.h5}>
            <span
              className={`
                ${theme === "light" ? styles.infoTip : styles.infoTipDark}
              `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 12 16"
              >
                <path
                  fillRule="evenodd"
                  d="M6.5 0C3.48 0 1 2.19 1 5c0 .92.55 2.25 1 3 1.34 2.25 1.78 2.78 2 4v1h5v-1c.22-1.22.66-1.75 2-4 .45-.75 1-2.08 1-3 0-2.81-2.48-5-5.5-5zm3.64 7.48c-.25.44-.47.8-.67 1.11-.86 1.41-1.25 2.06-1.45 3.23-.02.05-.02.11-.02.17H5c0-.06 0-.13-.02-.17-.2-1.17-.59-1.83-1.45-3.23-.2-.31-.42-.67-.67-1.11C2.44 6.78 2 5.65 2 5c0-2.2 2.02-4 4.5-4 1.22 0 2.36.42 3.22 1.19C10.55 2.94 11 3.94 11 5c0 .66-.44 1.78-.86 2.48zM4 14h5c-.23 1.14-1.3 2-2.5 2s-2.27-.86-2.5-2z"
                ></path>
              </svg>
            </span>
            Tip
          </h5>
        </div>
        <div className="warning-content">
          <p>
            Consider combining TargetScope with our other technical analysis
            tools and indicators to confirm trade setups and enhance your
            decision-making process.
          </p>
        </div>
      </div>

      <h2
        id="target-calculation-and-display"
        className={`scroll-mt-2 ${styles["h2"]}`}
      >
        Target Calculation and Display
        <Link
          to="#target-calculation-and-display"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <figure className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[140]}
          width={962}
          height={635}
          className="mx-auto"
          alt="TargetScope Display"
        ></img>
        <figcaption
          style={{
            textAlign: "center",
            fontSize: 12,
          }}
        >
          SPY 5 Min
        </figcaption>
      </figure>
      <p className={styles.p}>
        TargetScope calculates and displays entry, stop-loss, and target levels
        based on the aggregated visible price range. The levels are represented
        as horizontal lines on the chart, helping you visualize and plan your
        trades effectively.
      </p>

      <h2 id="importance-and-use" className={`scroll-mt-2 ${styles["h2"]}`}>
        Importance and Use
        <Link
          to="#importance-and-use"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <h3 id="precise-trade-execution" className={`${styles["h3"]}`}>
        Precise Trade Execution
        <Link
          to="#precise-trade-execution"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[141]}
          width={952}
          height={432}
          className="mx-auto"
          alt="TargetScope Precise Trade Execution"
        ></img>
      </p>
      <p className={styles.p}>
        TargetScope assists in determining optimal entry, stop-loss, and target
        levels. These levels are calculated based on visible price ranges,
        enhancing the precision of trade execution.
      </p>
      <div
        className={`
        info info-tip selection:bg-[#c4f6ff] selection:text-[#2c2c2c49]
        ${theme === "light" ? styles.infoWarning : styles.infoWarningDark} 
        `}
      >
        <div className="info-heading">
          <h5 className={styles.h5}>
            <span
              className={`
                ${theme === "light" ? styles.infoAlert : styles.infoAlertDark}
              `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 12 16"
              >
                <path
                  fillRule="evenodd"
                  d="M7 2.3c3.14 0 5.7 2.56 5.7 5.7s-2.56 5.7-5.7 5.7A5.71 5.71 0 0 1 1.3 8c0-3.14 2.56-5.7 5.7-5.7zM7 1C3.14 1 0 4.14 0 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm1 3H6v5h2V4zm0 6H6v2h2v-2z"
                ></path>
              </svg>
            </span>
            Info
          </h5>
        </div>
        <div className="warning-content">
          <p>
            Adjust the lookback period and settings based on the current market
            conditions. Markets with higher volatility may require larger
            lookback intervals.
          </p>
        </div>
      </div>
      <h3 id="risk-management" className={`${styles["h3"]}`}>
        Risk Management
        <Link
          to="#risk-management"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[142]}
          width={983}
          height={505}
          className="mx-auto"
          alt="TargetScope Risk Management"
        ></img>
      </p>
      <p className={styles.p}>
        By providing clear stop-loss and target levels, TargetScope aids in
        implementing effective risk management strategies. Traders can manage
        their risk exposure and make informed trading decisions.
      </p>

      <div
        className={`warning warning-caution selection:bg-[#ffeec4] selection:text-[#4b4b4b] ${styles["alertWarning"]}`}
      >
        <div className="warning-heading">
          <h5 className={styles.h5}>
            <span className={styles.alertWarningIcon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M8.893 1.5c-.183-.31-.52-.5-.887-.5s-.703.19-.886.5L.138 13.499a.98.98 0 0 0 0 1.001c.193.31.53.501.886.501h13.964c.367 0 .704-.19.877-.5a1.03 1.03 0 0 0 .01-1.002L8.893 1.5zm.133 11.497H6.987v-2.003h2.039v2.003zm0-3.004H6.987V5.987h2.039v4.006z"
                ></path>
              </svg>
            </span>
            caution
          </h5>
        </div>
        <div className="warning-content">
          <p className={styles.p}>
            While TargetScope is a valuable tool, it's important to complement
            its signals with comprehensive analysis and other indicators before
            entering or exiting trades.
          </p>
          <p className={styles.p}>
            <em>
              Disclaimer: The information provided here is for educational
              purposes only and does not constitute financial advice. Trading
              involves risk, and it's essential to conduct thorough research and
              seek advice from financial professionals before making any trading
              decisions.
            </em>
          </p>
        </div>
      </div>
    </>
  );
}
