import { useState } from "react";
import { Auth } from "aws-amplify";
import { Link, useNavigate } from "react-router-dom";

const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isCurrentPasswordFocused, setIsCurrentPasswordFocused] =
    useState(false);
  const [isNewPasswordFocused, setIsNewPasswordFocused] = useState(false);
  const [isConfirmNewPasswordFocused, setIsConfirmNewPasswordFocused] =
    useState(false);

  const [passwordError, setPasswordError] = useState("");
  const [passwordConfirmationError, setPasswordConfirmationError] =
    useState("");

  const shouldLabelFloat = (value, isFocused) => value.length > 0 || isFocused;
  const navigate = useNavigate();

  // New function to validate the password
  const validatePassword = (password) => {
    if (password.length < 6 || password.length > 60) {
      setPasswordError("Password should be between 6 and 60 characters.");
      return false;
    }
    setPasswordError("");
    return true;
  };

  // New function to validate the password confirmation
  const validatePasswordConfirmation = (password, confirmPassword) => {
    if (password !== confirmPassword) {
      setPasswordConfirmationError("Passwords do not match.");
      return false;
    }
    setPasswordConfirmationError("");
    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Perform validation before attempting to change the password
    const isPasswordValid = validatePassword(newPassword);
    const isConfirmationValid = validatePasswordConfirmation(
      newPassword,
      confirmNewPassword,
    );
    if (!isPasswordValid || !isConfirmationValid) {
      return;
    }

    // Attempt to change the password
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, currentPassword, newPassword);
      setSuccess("Password changed successfully.");
      setError("");
      setTimeout(() => {
        navigate("/dashboard");
      }, 3000);
    } catch (error) {
      setError(error.message);
      setSuccess("");
    }
  };
  return (
    <div className="flex flex-col items-center justify-center p-4">
      <div className="flex flex-col space-y-4 pb-4 text-center">
        <h2 className="text-xl font-bold">Change your Password</h2>
        <p className="max-w-xs text-sm font-normal">
          Protect your account with a unique password at least 6 characters
          long.
        </p>
      </div>
      <form
        onSubmit={handleSubmit}
        className="w-full max-w-md space-y-4 rounded-lg bg-[#171717] p-8 shadow-md"
      >
        {/* Current Password Input */}
        <div className="relative mb-1">
          <label
            htmlFor="currentPassword"
            className={`absolute left-3 top-4 block transform text-sm font-medium text-zinc-100 duration-200 ease-in-out ${
              shouldLabelFloat(currentPassword, isCurrentPasswordFocused)
                ? "-translate-x-4 -translate-y-3 scale-75"
                : ""
            }`}
          >
            Current Password
          </label>
          <input
            type="password"
            id="currentPassword"
            value={currentPassword}
            onFocus={() => setIsCurrentPasswordFocused(true)}
            onBlur={() => setIsCurrentPasswordFocused(false)}
            onChange={(e) => setCurrentPassword(e.target.value)}
            required
            className={`mt-1 h-12 w-full rounded-md border-[1px] p-[10px_10px_0] ${
              currentPassword ? "border-purple-500" : "border-zinc-300"
            } bg-transparent p-2 text-purple-500 caret-purple-500 shadow-sm transition-colors duration-200 ease-in-out hover:border-purple-500 focus:border-purple-500 focus:outline-none`}
          />
        </div>
        <Link
          to="/dashboard/loginhelp"
          className="text-sm text-blue-300 hover:underline"
        >
          <p>Forgot Password?</p>
        </Link>

        {/* New Password Input */}
        <div className="relative">
          <label
            htmlFor="newPassword"
            className={`absolute left-3 top-4 block transform text-sm font-medium text-zinc-100 duration-200 ease-in-out ${
              shouldLabelFloat(newPassword, isNewPasswordFocused)
                ? "-translate-x-7 -translate-y-3 scale-75"
                : ""
            }`}
          >
            New Password (6-60 characters)
          </label>
          <input
            type="password"
            id="newPassword"
            value={newPassword}
            onFocus={() => setIsNewPasswordFocused(true)}
            onBlur={() => setIsNewPasswordFocused(false)}
            onChange={(e) => setNewPassword(e.target.value)}
            required
            className={`mt-1 h-12 w-full rounded-md border-[1px] p-[10px_10px_0] ${
              newPassword ? "border-purple-500" : "border-zinc-300"
            } bg-transparent p-2 text-purple-500 caret-purple-500 shadow-sm transition-colors duration-200 ease-in-out hover:border-purple-500 focus:border-purple-500 focus:outline-none`}
          />
          {passwordError && (
            <p className="mt-2 text-xs text-red-600" aria-live="polite">
              {passwordError}
            </p>
          )}
        </div>
        {/* Confirm New Password Input */}
        <div className="relative">
          <label
            htmlFor="confirmNewPassword"
            className={`absolute left-3 top-4 block transform text-sm font-medium text-zinc-100 duration-200 ease-in-out ${
              shouldLabelFloat(confirmNewPassword, isConfirmNewPasswordFocused)
                ? "-translate-x-5 -translate-y-3 scale-75"
                : ""
            }`}
          >
            Confirm New Password
          </label>
          <input
            type="password"
            id="confirmNewPassword"
            value={confirmNewPassword}
            onFocus={() => setIsConfirmNewPasswordFocused(true)}
            onBlur={() => setIsConfirmNewPasswordFocused(false)}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
            required
            className={`mt-1 h-12 w-full rounded-md border-[1px] p-[10px_10px_0] ${
              confirmNewPassword ? "border-purple-500" : "border-zinc-300"
            } bg-transparent p-2 text-purple-500 caret-purple-500 shadow-sm transition-colors duration-200 ease-in-out hover:border-purple-500 focus:border-purple-500 focus:outline-none`}
          />
          {passwordConfirmationError && (
            <p className="mt-2 text-xs text-red-600" aria-live="polite">
              {passwordConfirmationError}
            </p>
          )}
        </div>
        {/* Submit Button */}
        <button
          type="submit"
          className="w-full rounded bg-blue-500 px-4 py-2 font-semibold text-white shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-50"
        >
          Save
        </button>
        {/* Cancel Button */}
        <button
          type="reset"
          className="w-full rounded bg-gray-300 px-4 py-2 font-semibold text-black shadow-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-opacity-50"
        >
          Cancel
        </button>
      </form>
      {error && (
        <p className="mt-4 text-sm text-red-600" aria-live="polite">
          {error}
        </p>
      )}
      {success && (
        <p className="mt-4 text-sm text-green-600" aria-live="polite">
          {success}
        </p>
      )}
    </div>
  );
};

export default ChangePassword;
