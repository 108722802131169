import React, { memo } from "react";

function TradingViewWidget({ container }) {
  return (
    <div
      className="tradingview-widget-container h-[1080px_!important] w-full"
      ref={container}
    >
      <div
        className="tradingview-widget-container__widget"
        style={{ height: "100%", width: "100%" }}
      ></div>
    </div>
  );
}

export default memo(TradingViewWidget);
