import { useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "../Docs.module.css";
import { useTOC } from "../TOCContext";
import DocImages from "../DocImages";
import DocsMovingAveragesTabContainer from "./DocsMovingAveragesTabContainer";

const entries = [
  {
    displayText: "Moving Averages",
    urlFragment: "moving-averages",
    headingType: "h2",
    subEntries: [
      {
        displayText: "Notable Moving Averages",
        urlFragment: "notable-moving-averages",
        headingType: "h3",
      },
      {
        displayText: "Applications",
        urlFragment: "applications",
        headingType: "h3",
      },
    ],
  },
  {
    displayText: "Bands/Channel Indicators",
    urlFragment: "bands-channel-indicators",
    headingType: "h2",
    subEntries: [
      {
        displayText: "Prominent Band Indicators",
        urlFragment: "prominent-band-indicators",
        headingType: "h3",
      },
      {
        displayText: "Usage",
        urlFragment: "usage",
        headingType: "h3",
      },
    ],
  },
  {
    displayText: "Trailing Stops",
    urlFragment: "trailing-stops",
    headingType: "h2",
    subEntries: [
      {
        displayText: "Renowned Trailing Stops",
        urlFragment: "renowned-trailing-stops",
        headingType: "h3",
      },
      {
        displayText: "Applications / Usage",
        urlFragment: "applications-usage",
        headingType: "h3",
      },
    ],
  },
];
export { entries };

export default function DocsTechnicalAnalysisTechnicalIndicatorsTrend() {
  const { addToTOC, removeFromTOC } = useTOC();

  useEffect(() => {
    entries.forEach(addToTOC);
    return () => {
      entries.forEach((entry) => removeFromTOC(entry));
    };
  }, [addToTOC, removeFromTOC]);

  return (
    <>
      <h1 className={styles.h1}>Trend Indicators</h1>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[51]}
          width={1568}
          height={603}
          className="mx-auto"
          alt="Trend Indicators"
        ></img>
      </p>

      <p className={styles.p}>
        Trend indicators provide insights into the prevailing trend within price
        movements. Some specifically estimate the primary trend component, while
        others focus on indicating its trajectory. Most of these indicators are
        categorized as lagging and are usually overlayed on the main price
        chart.
      </p>
      <h2 id="moving-averages" className={`scroll-mt-2 ${styles["h2"]}`}>
        Moving Averages
        <Link
          to="#moving-averages"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        Acting as lagging trend tools, moving averages smooth out the closing
        price by filtering out certain fluctuations, offering an estimation of
        the predominant trend in the price. They're staples in technical
        analysis, making their presence felt in nearly every analytical
        software.
      </p>

      <p className={styles.p}>
        Each moving average comes with a setting that dictates its smoothness
        level. This setting is typically referred to as its "length", "period",
        or sometimes "window".
      </p>

      <h3 id="notable-moving-averages" className={`${styles["h3"]}`}>
        Notable Moving Averages
        <Link
          to="#notable-moving-averages"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <figure className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[52]}
          width={1475}
          height={547}
          className="mx-auto"
          alt="Moving Averages"
        ></img>
        <figcaption
          style={{
            textAlign: "center",
            fontSize: 12,
          }}
        >
          SMA in orange, EMA in green, WMA in blue, all using period 9
        </figcaption>
      </figure>

      <p className={styles.p}>
        The <strong>simple moving average</strong>, often known as "SMA", stands
        out for its straightforward computation.
      </p>

      <p className={styles.p}>
        The exponential moving average (EMA) is another key player, offering
        quicker responsiveness than the SMA.
      </p>
      <p className={styles.p}>
        Another notable mention is the weighted moving average (WMA), which
        emphasizes recent prices more heavily, resulting in even less lag than
        the EMA.
      </p>
      <p className={styles.p}>
        Calculation for each of these moving averages are given below:
      </p>

      <DocsMovingAveragesTabContainer />

      <h3 id="applications" className={`${styles["h3"]}`}>
        Applications
        <Link
          to="#applications"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <figure className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[53]}
          width={1482}
          height={761}
          className="mx-auto"
          alt="EMA 50 with EMA 200"
        ></img>
        <figcaption
          style={{
            textAlign: "center",
            fontSize: 12,
          }}
        >
          period 50 EMA in blue and period 200 EMA in red
        </figcaption>
      </figure>
      <p className={styles.p}>
        Traders often discern the prevailing price trend direction by examining
        intersections between the price and moving average or between short-term
        and long-term moving averages.
      </p>
      <p className={styles.p}>
        Additionally, moving averages serve as dynamic support and resistance
        levels – initiating purchases when an ascending price touches the moving
        average and selling when a descending price does the same.
      </p>
      <figure className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[54]}
          width={1581}
          height={611}
          className="mx-auto"
          alt="Price Bounce on 200 EMA"
        ></img>
        <figcaption
          style={{
            textAlign: "center",
            fontSize: 12,
          }}
        >
          Price bounce over the 200 period EMA
        </figcaption>
      </figure>

      <h2
        id="bands-channel-indicators"
        className={`scroll-mt-2 ${styles["h2"]}`}
      >
        Bands/Channel Indicators
        <Link
          to="#bands-channel-indicators"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <figure className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[55]}
          width={1521}
          height={835}
          className="mx-auto"
          alt="MADALGO Clouds Band Indicator"
        ></img>
        <figcaption
          style={{
            textAlign: "center",
            fontSize: 12,
          }}
        >
          The MADALGO Clouds is a band indicator
        </figcaption>
      </figure>
      <p className={styles.p}>
        Such trend indicators introduce an upper and a lower boundary on the
        price graph, usually deriving from moving averages combined with
        volatility metrics.
      </p>

      <h3 id="prominent-band-indicators" className={`${styles["h3"]}`}>
        Prominent Band Indicators
        <Link
          to="#prominent-band-indicators"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        The Bollinger bands, introduced by John Bollinger, are perhaps the most
        recognizable. They're computed by adding or subtracting a rolling
        standard deviation from an SMA.
      </p>
      <figure className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[56]}
          width={1377}
          height={540}
          className="mx-auto"
          alt="Bollinger Bands Indicator"
        ></img>
        <figcaption
          style={{
            textAlign: "center",
            fontSize: 12,
          }}
        >
          Bollinger Bands indicator
        </figcaption>
      </figure>
      <p className={styles.p}>
        The Donchian channel, crafted by Richard Donchian, is another prevalent
        choice. It sets its top boundary using a rolling high price peak and its
        bottom boundary using a rolling low price trough.
      </p>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[57]}
          width={1367}
          height={740}
          className="mx-auto"
          alt="Donchian Channel"
        ></img>
      </p>

      <h3 id="usage" className={`${styles["h3"]}`}>
        Usage
        <Link
          to="#usage"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        The boundaries rendered by band indicators often act as support and
        resistance zones, especially in sideways markets.
      </p>
      <p className={styles.p}>
        Additionally, they can help discern trend direction via breakout
        techniques: an uptrend is suggested when prices breach the upper
        boundary, and a downtrend upon crossing below the lower boundary.
      </p>
      <figure className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[58]}
          width={819}
          height={779}
          className="mx-auto"
          alt="Price Breaking Lower Bollinger Band"
        ></img>
        <figcaption
          style={{
            textAlign: "center",
            fontSize: 12,
          }}
        >
          Price breaking the lower extremity of the Bollinger bands. the break
          is followed by a downtrend.
        </figcaption>
      </figure>

      <h2 id="trailing-stops" className={`scroll-mt-2 ${styles["h2"]}`}>
        Trailing Stops
        <Link
          to="#trailing-stops"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        These are pivotal in identifying ongoing price trends and can double up
        as dynamic stop loss points or as rolling support/resistance zones.
      </p>

      <h3 id="renowned-trailing-stops" className={`${styles["h3"]}`}>
        Renowned Trailing Stops
        <Link
          to="#renowned-trailing-stops"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        Among the various trailing stops available, the Supertrend, a creation
        of Olivier Seban, is perhaps the most notable.
      </p>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[51]}
          width={1568}
          height={603}
          className="mx-auto"
          alt="Supertrend"
        ></img>
      </p>

      <p className={styles.p}>
        The parabolic stop and reverse (often denoted as PSAR or simply SAR),
        introduced by Wells Wilder, is another key trailing stop.
      </p>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[59]}
          width={1697}
          height={834}
          className="mx-auto"
          alt="PSAR - Parabolic stop and reverse"
        ></img>
      </p>

      <h3 id="applications-usage" className={`${styles["h3"]}`}>
        Applications / Usage
        <Link
          to="#applications-usage"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        Interpreting trailing stops is usually straightforward: an uptrend is
        signaled when prices are above the stop, and a downtrend when below.
        Like moving averages, they can also function as evolving support and
        resistance markers.
      </p>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[60]}
          width={1512}
          height={765}
          className="mx-auto"
          alt="Trailing Stops"
        ></img>
      </p>
    </>
  );
}
