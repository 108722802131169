import image0 from "./imgs/pot.webp";
import image1 from "./imgs/candles.webp";
import image2 from "./imgs/buttonwood.webp";
import image3 from "./imgs/dow.webp";
import image4 from "./imgs/nasdaq.webp";
import image5 from "./imgs/ct.webp";
import image6 from "./imgs/forex.webp";
import image7 from "./imgs/btc.webp";
import image8 from "./imgs/tv.webp";

const TimeLineIcons = [
  image0,
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
];

export default TimeLineIcons;
