import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

function randomDecimal(min, max, decimalPlaces = 15) {
  const randInt = Math.random() * (max - min) + min;
  let randDec = Array(decimalPlaces)
    .fill(0)
    .map(() => Math.floor(Math.random() * 10))
    .join("");
  let result = parseFloat(`${randInt}.${randDec}`);
  return result;
}

function random(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const generateSparkle = (imgSrc) => {
  const size = randomDecimal(2.5, 7.5);
  const leftCalc = randomDecimal(random(-20, 20), 15);
  const startOffset = Math.random() * 2 * Math.PI; // New start offset
  const startRadius = random(0, 10);
  const endRadius = random(60, 75);
  const opacity = [0, 0.6, 0];
  const delay = random(1, 3);

  const sparkle = {
    id: String(random(10000, 99999)),
    createdAt: Date.now(),
    size,
    radius: { start: startRadius, end: endRadius },
    startOffset,
    opacity,
    delay,
    style: {
      width: `${size}px`,
      height: `${size}px`,
      backgroundImage: `url(${imgSrc})`,
      backgroundSize: "cover",
      position: "absolute",
      top: "calc(50% - 5px)",
      left: `calc(50% + ${Math.abs(leftCalc)}px)`,
      willChange: "transform",
      borderRadius: 100,
    },
  };

  return sparkle;
};

const Sparkles = ({ imgSrc, id, className, style, ...delegated }) => {
  const [sparkles, setSparkles] = useState([]);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (isDesktop) {
      setSparkles(() =>
        Array.from({ length: 12 }).map(() => generateSparkle(imgSrc)),
      );
    } else {
      setSparkles([]);
    }
  }, [isDesktop, imgSrc]);

  return (
    <Wrapper id={id} className={className} style={style} {...delegated}>
      <div
        id="particles-container"
        className="absolute inset-[-55px_-70px]"
        style={{
          opacity: 1,
        }}
      >
        <div
          id="particles-main"
          className=""
          style={{
            width: "100%",
            height: "100%",
            position: "relative",
          }}
        >
          {sparkles.map((sparkle, index) => {
            const duration = random(7, 10);
            const initialDelay = Math.random() * duration;

            return (
              <SparkleWrapper
                key={sparkle.id}
                className={`sparkle-${index}`}
                animate={{
                  x: Array(100)
                    .fill(0)
                    .map((_, i) => {
                      const radius =
                        sparkle.radius.start +
                        ((sparkle.radius.end - sparkle.radius.start) * i) / 100;
                      return (
                        radius *
                        Math.cos(sparkle.startOffset + (2 * Math.PI * i) / 100)
                      );
                    }),
                  y: Array(100)
                    .fill(0)
                    .map((_, i) => {
                      const radius =
                        sparkle.radius.start +
                        ((sparkle.radius.end - sparkle.radius.start) * i) / 100;
                      return (
                        radius *
                        Math.sin(sparkle.startOffset + (2 * Math.PI * i) / 100)
                      );
                    }),
                  opacity: sparkle.opacity,
                }}
                transition={{
                  x: {
                    repeat: Infinity,
                    duration: duration,
                    ease: "easeInOut",
                    delay: initialDelay,
                  },
                  y: {
                    repeat: Infinity,
                    duration: duration,
                    ease: "easeInOut",
                    delay: initialDelay,
                  },
                  opacity: {
                    repeat: Infinity,
                    duration: duration,
                    yoyo: Infinity,
                    repeatDelay: sparkle.delay,
                    ease: "easeInOut",
                    delay: initialDelay,
                  },
                }}
                style={sparkle.style}
              />
            );
          })}
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const SparkleWrapper = styled(motion.div)``;

export default Sparkles;
