import { useState, useContext } from "react";
import { InlineMath } from "react-katex";
import "katex/dist/katex.min.css";
import styles from "../Docs.module.css";
import ThemeContext from "../ThemeContext";

export default function DocsMovingAveragesTabContainer() {
  const { theme } = useContext(ThemeContext);
  const [selectedTab, setSelectedTab] = useState(0); // default to the first tab

  const equations = {
    SMA: "\\mathrm{SMA}_{t} = \\displaystyle\\frac{1}{p} \\substack{p-1 \\\\ \\displaystyle\\sum \\\\ i=0} C_{t-i}",
    EMA: "\\mathrm{EMA}_{t} = \\mathrm{EMA}_{t-1} + \\alpha (C_{t} - \\mathrm{EMA}_{t-1})",
    WMA: "\\mathrm{WMA}_{t} = \\frac{\\displaystyle\\substack{p-1 \\\\ \\displaystyle\\sum \\\\ i=0} {(p-i)C_{t-i}}}{\\displaystyle\\frac{p(p+1)}{2}}",
  };

  const statements = {
    SMA: (
      <p>
        where <InlineMath math="p" /> denote the period of the SMA and{" "}
        <InlineMath math="C_t" /> the closing price.
      </p>
    ),
    EMA: (
      <p>
        with <InlineMath math="\alpha = \displaystyle\frac{2}{{p + 1}}" />
        , where <InlineMath math="p" /> denote the period of the EMA and{" "}
        <InlineMath math="C_t" /> the closing price. The EMA is generally
        initialised using a SMA of the same period.
      </p>
    ),

    WMA: (
      <p>
        where <InlineMath math="p" /> denote the period of the WMA and{" "}
        <InlineMath math="C_t" /> the closing price.
      </p>
    ),
  };
  return (
    <div id="tabs">
      <ul
        id="tabs-list"
        className="m-[0_0_1rem] mb-0 flex overflow-x-auto pl-0 font-bold"
        role="tablist"
        aria-orientation="horizontal"
      >
        {["SMA", "EMA", "WMA"].map((label, index) => (
          <li
            key={label}
            role="tab"
            tabIndex="0"
            aria-selected={selectedTab === index}
            className={`mt-0 inline-flex cursor-pointer rounded-md p-[1rem_1rem] transition-colors duration-200 [border-bottom:3px_solid_transparent] [transition-timing-function:cubic-bezier(0.08,_.52,_0.52,_1)] [word-wrap:break-word] hover:bg-[hsla(0,0%,100%,.05)] ${
              theme === "light"
                ? selectedTab === index
                  ? styles.activeTab
                  : ""
                : selectedTab === index
                ? styles.activeTabDark
                : ""
            }`}
            onClick={() => setSelectedTab(index)}
          >
            {label}
          </li>
        ))}
      </ul>
      <div id="math-container" className="mx-auto my-4">
        {["SMA", "EMA", "WMA"].map((content, index) => (
          <div key={content} role="tabpanel" hidden={selectedTab !== index}>
            <div id="math-display" className="mb-4 flex justify-center">
              <InlineMath math={equations[content]} />
            </div>
            <p className="p-[0_0_1rem] pb-4">{statements[content]}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
