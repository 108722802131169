export const SvgIcon = ({
  isAboutUsPage,
  menuOpen,
  isSideNavOpen,
  theme,
  isBlogPage,
}) => {
  let fill;

  if (isSideNavOpen) {
    fill = theme === "light" ? "#000" : "#fff";
  } else if (isAboutUsPage && menuOpen) {
    fill = "#fff";
  } else if (isAboutUsPage) {
    fill = "#000";
  } else if (isBlogPage) {
    fill = "#000";
  } else {
    fill = "#fff";
  }

  return (
    <svg
      className="transition-all duration-200 [transition-timing-function:cubic-bezier(.08,.52,.52,1)]"
      width="50"
      height="50"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill={fill}
    >
      <path
        d="m5.44,4.16l7.19,4.89L23.2.27v2.37l-10.43,8.64-7.33-4.81s0-2.38,0-2.31Z"
        stroke="none"
      />
      <path
        d="m5.41,11.2l2.66,1.68v5.58h-2.7s.04-7.25.04-7.26Z"
        stroke="none"
      />
      <path
        d="m17.3,18.24h2.7V6.36l-7.22,6.05L.12,4.17v2.59l12.58,8.09,4.59-3.72s-.04,7.11,0,7.11Z"
        stroke="none"
      />
    </svg>
  );
};

export const DarkSvgIcon = ({ theme }) => {
  return (
    <svg
      className="transition-all duration-200 [transition-timing-function:cubic-bezier(.08,.52,.52,1)]"
      width="50"
      height="50"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill={`${theme === "light" ? "#000" : "#fff"}`}
    >
      <path
        d="m5.44,4.16l7.19,4.89L23.2.27v2.37l-10.43,8.64-7.33-4.81s0-2.38,0-2.31Z"
        stroke="none"
      />
      <path
        d="m5.41,11.2l2.66,1.68v5.58h-2.7s.04-7.25.04-7.26Z"
        stroke="none"
      />
      <path
        d="m17.3,18.24h2.7V6.36l-7.22,6.05L.12,4.17v2.59l12.58,8.09,4.59-3.72s-.04,7.11,0,7.11Z"
        stroke="none"
      />
    </svg>
  );
};

export const BlogBrandSVG = ({ menuOpen }) => {
  return (
    <svg
      className="transition-all duration-200 [transition-timing-function:cubic-bezier(.08,.52,.52,1)]"
      width="50"
      height="50"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill={menuOpen ? "#fff" : "#000"}
    >
      <path
        d="m5.44,4.16l7.19,4.89L23.2.27v2.37l-10.43,8.64-7.33-4.81s0-2.38,0-2.31Z"
        stroke="none"
      />
      <path
        d="m5.41,11.2l2.66,1.68v5.58h-2.7s.04-7.25.04-7.26Z"
        stroke="none"
      />
      <path
        d="m17.3,18.24h2.7V6.36l-7.22,6.05L.12,4.17v2.59l12.58,8.09,4.59-3.72s-.04,7.11,0,7.11Z"
        stroke="none"
      />
    </svg>
  );
};
