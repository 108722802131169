import { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import styles from "../Docs.module.css";
import { useTOC } from "../TOCContext";
import DocImages from "../DocImages";
import ThemeContext from "../ThemeContext";

const entries = [
  {
    displayText: "Setting Up an Alert",
    urlFragment: "setting-up-an-alert",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "Defining Trigger Conditions",
    urlFragment: "defining-trigger-conditions",
    headingType: "h2",
    subEntries: [
      {
        displayText: "Intersecting Conditions",
        urlFragment: "intersecting-conditions",
        headingType: "h3",
      },
      {
        displayText: "Channel Dynamics",
        urlFragment: "channel-dynamics",
        headingType: "h3",
      },
      {
        displayText: "Value Comparisons",
        urlFragment: "value-comparisons",
        headingType: "h3",
      },
      {
        displayText: "Placeholder Utility",
        urlFragment: "placeholder-utility",
        headingType: "h3",
      },
    ],
  },
  {
    displayText: "Alert Warning",
    urlFragment: "alert-warning",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "Managing Your Alerts",
    urlFragment: "managing-your-alerts",
    headingType: "h2",
    subEntries: [
      {
        displayText: "Alert Updates",
        urlFragment: "alert-updates",
        headingType: "h3",
      },
    ],
  },
];
export { entries };

export default function DocsSettingAlerts() {
  const { addToTOC, removeFromTOC } = useTOC();
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    entries.forEach(addToTOC);
    return () => {
      entries.forEach((entry) => removeFromTOC(entry));
    };
  }, [addToTOC, removeFromTOC]);

  return (
    <>
      <h1 className={styles.h1}>How To Set Alerts</h1>
      <p className={styles.p}>
        Alerts can be set up based on user-defined conditions, such as
        intersections between two indicators, price deviations from a channel,
        and more. Once these conditions are met, users can receive notifications
        through mobile alerts, emails, SMS, or other channels via webhooks.
      </p>
      <h2 id="setting-up-an-alert" className={`scroll-mt-2 ${styles["h2"]}`}>
        Setting Up an Alert
        <Link
          to="#setting-up-an-alert"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>To set up an alert:</p>
      <ol className={styles.olBullet}>
        <li>Tap the alert icon at the chart's top.</li>
      </ol>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[15]}
          width={587}
          height={104}
          className="mx-auto"
          alt="Alert Icon"
        ></img>
      </p>
      <ol className={styles.olBullet}>
        <li>Use the alert manager.</li>
      </ol>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[16]}
          width={386}
          height={563}
          className="mx-auto"
          alt="Alert Manager"
        ></img>
      </p>
      <ol className={styles.olBullet}>
        <li>Use shortcuts: ALT + A (Windows) or ⌥ + A (Mac).</li>
      </ol>
      <h2
        id="defining-trigger-conditions"
        className={`scroll-mt-2 ${styles["h2"]}`}
      >
        Defining Trigger Conditions
        <Link
          to="#defining-trigger-conditions"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        The conditions menu lets users determine when an alert should activate.
        This involves picking indicators and establishing rules for the alert's
        initiation. One dropdown menu refines the alert's exact triggering
        conditions, while another manages the elements or plots defining those
        conditions.
      </p>
      <div
        className={`
        info info-tip selection:bg-[#c4f6ff] selection:text-[#2c2c2c49]
        ${theme === "light" ? styles.infoWarning : styles.infoWarningDark} 
        `}
      >
        <div className="info-heading">
          <h5 className={styles.h5}>
            <span
              className={`
                ${theme === "light" ? styles.infoAlert : styles.infoAlertDark}
              `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 12 16"
              >
                <path
                  fillRule="evenodd"
                  d="M7 2.3c3.14 0 5.7 2.56 5.7 5.7s-2.56 5.7-5.7 5.7A5.71 5.71 0 0 1 1.3 8c0-3.14 2.56-5.7 5.7-5.7zM7 1C3.14 1 0 4.14 0 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm1 3H6v5h2V4zm0 6H6v2h2v-2z"
                ></path>
              </svg>
            </span>
            Info
          </h5>
        </div>
        <div className="warning-content">
          <p>
            Indicators produce plots, visual elements essential for defining
            alert conditions. These plots visualize value sequences, conditions,
            and so on. Some indicators might produce multiple plots.
          </p>
        </div>
      </div>
      <p className={styles.p}>Custom Alert Conditions Explained:</p>
      <h3
        id="intersecting-conditions"
        className={`scroll-mt-2 ${styles["h3"]}`}
      >
        Intersecting Conditions
        <Link
          to="#intersecting-conditions"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>Alerts users when two chosen elements overlap.</p>
      <p className={styles.p}>
        Three crossing conditions are available,{" "}
        <strong>Crossing, Crossing Up, Crossing Down.</strong>
      </p>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[17]}
          width={249}
          height={155}
          className="mx-auto"
          alt="Crossing Conditions"
        ></img>
      </p>
      <p className={styles.p}>
        <strong>Crossing</strong> notifies about any overlaps between two
        specified plots or values.
      </p>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[18]}
          width={652}
          height={356}
          className="mx-auto"
          alt="Crossing"
        ></img>
      </p>
      <p className={styles.p}>
        <strong>Crossing Up</strong> activates when the primary chosen element
        overlaps the secondary one.
      </p>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[19]}
          width={422}
          height={255}
          className="mx-auto"
          alt="Crossing Up"
        ></img>
      </p>
      <p className={styles.p}>
        <strong>Crossing Down</strong> signals when the first selected element
        is surpassed by the second.
      </p>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[20]}
          width={422}
          height={255}
          className="mx-auto"
          alt="Crossing Down"
        ></img>
      </p>
      <h3 id="channel-dynamics" className={`scroll-mt-2 ${styles["h3"]}`}>
        Channel Dynamics
        <Link
          to="#channel-dynamics"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        <strong>Entering Channel</strong> activates when the first element
        surpasses an upper limit or falls below a lower threshold.
      </p>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[21]}
          width={422}
          height={217}
          className="mx-auto"
          alt="Entering Channel"
        ></img>
      </p>
      <p className={styles.p}>
        <strong>Exiting Channel</strong> signals when the primary element
        crosses an upper limit or drops beneath a lower one.
      </p>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[22]}
          width={422}
          height={217}
          className="mx-auto"
          alt="Exiting Channel"
        ></img>
      </p>
      <p className={styles.p}>
        <strong>Outside/Inside Channel</strong> notifies if an element is either
        beyond or within set boundaries.
      </p>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[23]}
          width={422}
          height={217}
          className="mx-auto"
          alt="Outside Channel"
        ></img>
      </p>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[24]}
          width={422}
          height={217}
          className="mx-auto"
          alt="Inside Channel"
        ></img>
      </p>
      <h3 id="value-comparisons" className={`scroll-mt-2 ${styles["h3"]}`}>
        Value Comparisons
        <Link
          to="#value-comparisons"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[25]}
          width={422}
          height={217}
          className="mx-auto"
          alt="Greater Than"
        ></img>
      </p>
      <p className={styles.p}>
        <strong>Greater/Less Than</strong> activates when an element surpasses
        or falls short of another element or specific value.
      </p>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[26]}
          width={422}
          height={217}
          className="mx-auto"
          alt="Less Than"
        ></img>
      </p>
      <h2 id="placeholder-utility" className={`scroll-mt-2 ${styles["h2"]}`}>
        Placeholder Utility
        <Link
          to="#placeholder-utility"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[27]}
          width={642}
          height={186}
          className="mx-auto"
          alt="Placeholder"
        ></img>
      </p>
      <p className={styles.p}>
        Placeholders return a variable's value in an alert message. This can
        represent a symbol's closing price (
        <code>&#123;&#123;close&#125;&#125;</code>), an alert's timeframe (
        <code>&#123;&#123;interval&#125;&#125;</code>), or an indicator's value
        using <code>&#123;&#123;plot("Name")&#125;&#125;</code>, where{" "}
        <code>"Name"</code> denotes the desired plot value. However, only the
        first 22 indicator plots can be utilized as placeholders.
      </p>
      <div
        className={`
        info info-tip selection:bg-[#c4f6ff] selection:text-[#2c2c2c49]
        ${theme === "light" ? styles.infoWarning : styles.infoWarningDark} 
        `}
      >
        <div className="info-heading">
          <h5 className={styles.h5}>
            <span
              className={`
                ${theme === "light" ? styles.infoAlert : styles.infoAlertDark}
              `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 12 16"
              >
                <path
                  fillRule="evenodd"
                  d="M7 2.3c3.14 0 5.7 2.56 5.7 5.7s-2.56 5.7-5.7 5.7A5.71 5.71 0 0 1 1.3 8c0-3.14 2.56-5.7 5.7-5.7zM7 1C3.14 1 0 4.14 0 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm1 3H6v5h2V4zm0 6H6v2h2v-2z"
                ></path>
              </svg>
            </span>
            Info
          </h5>
        </div>
        <div className="warning-content">
          <p>
            Note: Ensure plot("Name") is included in the chosen alert
            condition's indicator.
          </p>
        </div>
      </div>
      <h2 id="alert-warning" className={`scroll-mt-2 ${styles["h2"]}`}>
        Alert Warning
        <Link
          to="#alert-warning"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[28]}
          width={472}
          height={213}
          className="mx-auto"
          alt="Cauting Warning"
        ></img>
      </p>
      <p className={styles.p}>
        Setting up an alert with certain indicators, like the MADALGO Signals &
        Overlays, might prompt a warning. This is due to internal Pinescript
        functions that can potentially repaint under specific configurations.
        However, MADALGO alerts remain consistent; thus, you can proceed without
        hesitation.
      </p>
      <p className={styles.p}>
        However, MADALGO alerts remain consistent; thus, you can proceed{" "}
        <strong>without hesitation.</strong>
      </p>
      <h2 id="managing-your-alerts" className={`scroll-mt-2 ${styles["h2"]}`}>
        Managing Your Alerts
        <Link
          to="#managing-your-alerts"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        Navigate to the alerts manager to oversee your active alerts. This panel
        lets you set up, dismiss, halt, restart, duplicate, locate, organize,
        and modify alerts. Access it by clicking the bell icon on the chart's
        top-left.
      </p>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[29]}
          width={358}
          height={701}
          className="mx-auto"
          alt="Manage Alerts"
        ></img>
      </p>
      <p className={styles.p}>
        To clear all alerts, click the three-dot icon near the "add alert"
        symbol and choose "remove all".
      </p>
      <p className={styles.p}>
        More on alert management can be found{" "}
        <Link
          to="https://www.tradingview.com/support/solutions/43000595311-manage-alerts/"
          className={`select-none transition-opacity duration-200  [text-decoration:none] hover:underline
        ${theme === "light" ? "text-blue-500" : "text-purple-500"}
        `}
        >
          here.
        </Link>
      </p>
      <h3 id="alert-updates" className={`scroll-mt-2 ${styles["h3"]}`}>
        Alert Updates
        <Link
          to="#alert-updates"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        Remember, if an indicator's configuration{" "}
        <strong>changes post-alert setup</strong>, the alert still operates
        based on <strong>previous settings</strong>. To modify an alert, head to
        the alerts manager, select edit.
      </p>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[30]}
          width={429}
          height={221}
          className="mx-auto"
          alt="Edit Alerts"
        ></img>
      </p>
      <p className={styles.p}>
        Then pick the secondary indicator matching the original alert's name and
        save your changes.
      </p>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[31]}
          width={653}
          height={1039}
          className="mx-auto"
          alt="Updating Alerts"
        ></img>
      </p>
    </>
  );
}
