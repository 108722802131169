import { Link } from "react-router-dom";
import BlurPages from "./BlurPages";
import { motion } from "framer-motion";

function LegalMain() {
  const ArrowIcon = () => (
    <svg
      className="[transition:all_.1s_ease-in-out] group-hover:scale-125 group-hover:transform group-hover:duration-300 group-hover:ease-in-out"
      width="100%"
      height="100%"
      viewBox="0 0 20 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 5H19M19 5L14.6667 1M19 5L14.6667 9"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </svg>
  );

  return (
    <>
      {" "}
      <section
        id="heading-disclaimer-section"
        className="relative z-0 block w-full"
      >
        <div
          id="inner-disclaimer-container"
          className="w-full px-7 pb-9 pt-[10rem] md:px-14 md:pb-[6.25rem] lg:pt-[15.125rem] xl:px-28"
        >
          <div id="header-container" className="relative z-[1] text-center">
            <motion.h1
              id="disclaimer-heading"
              className=" text-5xl md:text-[5rem] lg:text-[7.5rem]"
              initial={{ opacity: 0 }}
              transition={{ duration: 2 }}
              animate={{ opacity: 1 }}
            >
              Legal
            </motion.h1>
          </div>
          <div
            id="blur"
            className=" absolute bottom-[0%] left-[0%] right-[0%] top-[0%] z-0 block"
          >
            <div
              id="gradient-blur-group-1"
              className="lg:w[85vw] absolute bottom-0 left-auto right-[-6.6rem] top-auto h-[100vw] w-[95vw] opacity-60 filter-none md:-bottom-80 md:w-screen lg:right-auto lg:h-[85vw] xl:-bottom-[30rem] xl:left-[5.7rem] xl:max-h-[100rem] xl:max-w-[100rem]"
            >
              <BlurPages />
            </div>
          </div>
        </div>
      </section>
      <motion.section
        id="legal-links-section"
        className="relative z-0 block w-full "
        initial={{ opacity: 0 }}
        transition={{ duration: 2, delay: 0.1 }}
        animate={{ opacity: 1 }}
      >
        <div
          id="legal-links-container"
          className="w-full px-7 pb-24 pt-16 md:px-14 lg:pb-40 lg:pt-14 xl:px-28"
        >
          <div
            id="legal-links-body"
            className=" mx-auto flex w-fit max-w-[50rem] flex-col rounded-3xl bg-[rgb(20,18,23,0.8)] p-5 [box-shadow:1px_1px_2.125rem_1.25rem_rgba(0,_0,_0,_.05)] md:p-7 lg:rounded-[2.5rem] lg:p-12"
          >
            <ul className="space-y-4">
              <li>
                <Link
                  to="/legal/disclaimer"
                  className="group flex items-center justify-between text-2xl text-white md:text-3xl lg:text-4xl"
                >
                  <span
                    id="span-outer-container"
                    className="relative inline-block overflow-hidden rounded-[1000px] p-[0_1.5rem] pl-0 text-[105%] font-normal uppercase leading-[.92] tracking-[inherit] [background:0_0] [border:0] [color:#fff] [text-align:inherit] [text-decoration:none] [text-indent:inherit] [transition:transform_0ms_ease_0ms] md:m-[0_0_0_.2em] md:p-[0_4.8rem] md:pl-0"
                    style={{
                      pointerEvents: "auto",
                    }}
                  >
                    <span
                      id="disclaimer-body-span"
                      className="z-2 relative block overflow-hidden px-1 pb-1"
                      style={{
                        translate: "none",
                        rotate: "none",
                        scale: "none",
                        willChange: "auto",
                        transform: "translate(0px, 0%)",
                      }}
                    >
                      <span
                        id="disclaimer span"
                        className="block [transition:transform_559.816ms_cubic-bezier(0.16,_1,_0.3,_1)_0ms] after:absolute after:left-0 after:top-[110%] after:block after:text-white after:[content:_attr(data-text)] group-hover:[transform:_translateY(-110%)]"
                        data-text="Disclaimer"
                      >
                        Disclaimer
                      </span>
                    </span>
                  </span>
                  <span id="arrow-span" className="w-8 md:w-10 lg:w-12">
                    <ArrowIcon />
                  </span>
                </Link>
              </li>
              <li>
                <Link
                  to="/legal/terms-of-service"
                  className="group flex items-center justify-between text-2xl text-white md:text-3xl lg:text-4xl"
                >
                  <span
                    id="span-outer-container"
                    className="relative inline-block overflow-hidden rounded-[1000px] p-[0_1.5rem] pl-0 text-[105%] font-normal uppercase leading-[.92] tracking-[inherit] [background:0_0] [border:0] [color:#fff] [text-align:inherit] [text-decoration:none] [text-indent:inherit] [transition:transform_0ms_ease_0ms] md:m-[0_0_0_.2em] md:p-[0_4.8rem] md:pl-0"
                    style={{
                      pointerEvents: "auto",
                    }}
                  >
                    <span
                      id="disclaimer-body-span"
                      className="z-2 relative block overflow-hidden px-1 pb-1"
                      style={{
                        translate: "none",
                        rotate: "none",
                        scale: "none",
                        willChange: "auto",
                        transform: "translate(0px, 0%)",
                      }}
                    >
                      <span
                        id="disclaimer span"
                        className="block [transition:transform_559.816ms_cubic-bezier(0.16,_1,_0.3,_1)_0ms] after:absolute after:left-0 after:top-[110%] after:block after:text-white after:[content:_attr(data-text)] group-hover:[transform:_translateY(-110%)]"
                        data-text="Terms"
                      >
                        Terms
                      </span>
                    </span>
                  </span>
                  <span id="arrow-span" className="w-8 md:w-10 lg:w-12">
                    <ArrowIcon />
                  </span>
                </Link>
              </li>
              <li>
                <Link
                  to="/legal/privacy-policy"
                  className="group flex items-center justify-between text-2xl text-white md:text-3xl lg:text-4xl"
                >
                  <span
                    id="span-outer-container"
                    className="relative inline-block overflow-hidden rounded-[1000px] p-[0_1.5rem] pl-0 text-[105%] font-normal uppercase leading-[.92] tracking-[inherit] [background:0_0] [border:0] [color:#fff] [text-align:inherit] [text-decoration:none] [text-indent:inherit] [transition:transform_0ms_ease_0ms] md:m-[0_0_0_.2em] md:p-[0_4.8rem] md:pl-0"
                    style={{
                      pointerEvents: "auto",
                    }}
                  >
                    <span
                      id="disclaimer-body-span"
                      className="z-2 relative block overflow-hidden px-1 pb-1"
                      style={{
                        translate: "none",
                        rotate: "none",
                        scale: "none",
                        willChange: "auto",
                        transform: "translate(0px, 0%)",
                      }}
                    >
                      <span
                        id="disclaimer span"
                        className="block [transition:transform_559.816ms_cubic-bezier(0.16,_1,_0.3,_1)_0ms] after:absolute after:left-0 after:top-[110%] after:block after:text-white after:[content:_attr(data-text)] group-hover:[transform:_translateY(-110%)]"
                        data-text="Privacy"
                      >
                        Privacy
                      </span>
                    </span>
                  </span>
                  <span id="arrow-span" className="w-8 md:w-10 lg:w-12">
                    <ArrowIcon />
                  </span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </motion.section>
    </>
  );
}

export default LegalMain;
