import { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import styles from "../Docs.module.css";
import { useTOC } from "../TOCContext";
import DocImages from "../DocImages";
import ThemeContext from "../ThemeContext";

const entries = [
  {
    displayText: "Activation and Customization",
    urlFragment: "activation-and-customization",
    headingType: "h2",
    subEntries: [
      {
        displayText: "Activation",
        urlFragment: "activation",
        headingType: "ol",
      },
      {
        displayText: "Customization",
        urlFragment: "customization",
        headingType: "ol",
      },
    ],
  },
  {
    displayText: "Calculation",
    urlFragment: "calculation",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "Interpretation",
    urlFragment: "interpretation",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "Integration with Divergence Finder and Gradient Filter",
    urlFragment: "integration-with-divergence-finder-and-gradient-filter",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "Practical Application",
    urlFragment: "practical-application",
    headingType: "h2",
    subEntries: [
      {
        displayText: "Identifying Trend Shifts",
        urlFragment: "identifying-trend-shifts",
        headingType: "h3",
      },
    ],
  },
];
export { entries };

export default function DocsMADALGODynamicsWeightedPulse() {
  const { theme } = useContext(ThemeContext);
  const { addToTOC, removeFromTOC } = useTOC();

  useEffect(() => {
    entries.forEach(addToTOC);
    return () => {
      entries.forEach((entry) => removeFromTOC(entry));
    };
  }, [addToTOC, removeFromTOC]);

  return (
    <>
      <h1 className={styles.h1}>Weighted Pulse</h1>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[182]}
          width={764}
          height={609}
          className="mx-auto"
          alt="Weighted Pulse"
        ></img>
      </p>
      <p className={styles.p}>
        The "Weighted Pulse" feature in MADALGO Dynamics is a sophisticated tool
        designed to assist traders in identifying trend shifts and momentum
        changes. By converting and smoothing market data, Weighted Pulse offers
        insights into market sentiment and potential reversals.
      </p>

      <h2
        id="activation-and-customization"
        className={`scroll-mt-2 ${styles["h2"]}`}
      >
        Activation and Customization
        <Link
          to="#activation-and-customization"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <ol id="activation" className={styles.olBullet}>
        <li>Activation</li>
      </ol>
      <p className={styles.p}>To activate the Weighted Pulse feature:</p>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[183]}
          width={511}
          height={96}
          className="mx-auto"
          alt="Weighted Pulse Settings"
        ></img>
      </p>
      <ol className={styles.ol}>
        <li>Access the "Main Settings" section of MADALGO Dynamics.</li>
        <li>Locate the "Weighted Pulse" switch and enable it.</li>
      </ol>

      <ol id="customization" className={styles.olBullet}>
        <li>Customization</li>
      </ol>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[184]}
          width={298}
          height={192}
          className="mx-auto"
          alt="Weighted Pulse Customization"
        ></img>
      </p>
      <p className={styles.p}>
        Customize the Weighted Pulse feature with the following settings:
      </p>
      <ol className={styles.olBullet}>
        <li>
          <strong>Up Color:</strong> Choose the color representing upward
          movements.
        </li>
        <li>
          <strong>Down Color:</strong> Select the color representing downward
          movements.
        </li>
        <li>
          <strong>Gradient:</strong> Toggle the gradient display for enhanced
          visualization.
        </li>
        <li>
          <strong>Div Mode:</strong> Toggle to enable or disable the Divergence
          Finder feature.
        </li>
      </ol>

      <div
        className={`
        info info-tip selection:bg-[#c4f6ff] selection:text-[#2c2c2c49]
        ${theme === "light" ? styles.infoTipInfo : styles.infoTipInfoDark} 
        `}
      >
        <div className="info-heading">
          <h5 className={styles.h5}>
            <span
              className={`
                ${theme === "light" ? styles.infoTip : styles.infoTipDark}
              `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 12 16"
              >
                <path
                  fillRule="evenodd"
                  d="M6.5 0C3.48 0 1 2.19 1 5c0 .92.55 2.25 1 3 1.34 2.25 1.78 2.78 2 4v1h5v-1c.22-1.22.66-1.75 2-4 .45-.75 1-2.08 1-3 0-2.81-2.48-5-5.5-5zm3.64 7.48c-.25.44-.47.8-.67 1.11-.86 1.41-1.25 2.06-1.45 3.23-.02.05-.02.11-.02.17H5c0-.06 0-.13-.02-.17-.2-1.17-.59-1.83-1.45-3.23-.2-.31-.42-.67-.67-1.11C2.44 6.78 2 5.65 2 5c0-2.2 2.02-4 4.5-4 1.22 0 2.36.42 3.22 1.19C10.55 2.94 11 3.94 11 5c0 .66-.44 1.78-.86 2.48zM4 14h5c-.23 1.14-1.3 2-2.5 2s-2.27-.86-2.5-2z"
                ></path>
              </svg>
            </span>
            Tip
          </h5>
        </div>
        <div className="warning-content">
          <p className={styles.p}>
            Activate the Divergence Finder feature to identify potential
            divergences between the UVWAP and price, aiding in predicting trend
            changes.
          </p>
          <p className={styles.p}>
            Enable the gradient display to enhance the visual clarity of
            Weighted Pulse movements.
          </p>
        </div>
      </div>

      <h2 id="calculation" className={`scroll-mt-2 ${styles["h2"]}`}>
        Calculation
        <Link
          to="#calculation"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <figure className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[185]}
          width={510}
          height={663}
          className="mx-auto"
          alt="Weighted Pulse Calculation"
        ></img>
        <figcaption
          style={{
            textAlign: "center",
            fontSize: 12,
          }}
        >
          Oscillating between OB and OS
        </figcaption>
      </figure>
      <p className={styles.p}>
        The Weighted Pulse feature calculates the scaled market pulse by taking
        the current sentiment and dividing it against current trend strength,
        which ultimately serves as a pulse of market sentiment. By analyzing the
        relationship with its smoothed and crossed-over versions, traders can
        gain insights into potential trend changes and reversals.
      </p>

      <h2 id="interpretation" className={`scroll-mt-2 ${styles["h2"]}`}>
        Interpretation
        <Link
          to="#interpretation"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <ol className={styles.ol}>
        <li>
          <strong>Cross Over:</strong> When the Weighted Pulse crosses over the
          20, it may indicate a potential upward momentum and trend reversal.
        </li>
        <li>
          <strong>Cross Under:</strong> Conversely, when the Weighted Pulse
          crosses under the 80, it may signal a potential downward momentum and
          trend reversal.
        </li>
      </ol>

      <h2
        id="integration-with-divergence-finder-and-gradient-filter"
        className={`scroll-mt-2 ${styles["h2"]}`}
      >
        Integration with Divergence Finder and Gradient Filter
        <Link
          to="#integration-with-divergence-finder-and-gradient-filter"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        Weighted Pulse offers enhanced functionality with the "Div Mode"
        setting:
      </p>
      <ol className={styles.olBullet}>
        <li>
          The Gradient Intensity Filter reduces noise and enhances signal
          accuracy.
        </li>
        <li>
          The Divergence Finder identifies potential divergences between price
          and the oscillator, helping you spot trend reversals.
        </li>
      </ol>
      <div
        className={`
        info info-tip selection:bg-[#c4f6ff] selection:text-[#2c2c2c49]
        ${theme === "light" ? styles.infoWarning : styles.infoWarningDark} 
        `}
      >
        <div className="info-heading">
          <h5 className={styles.h5}>
            <span
              className={`
                ${theme === "light" ? styles.infoAlert : styles.infoAlertDark}
              `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 12 16"
              >
                <path
                  fillRule="evenodd"
                  d="M7 2.3c3.14 0 5.7 2.56 5.7 5.7s-2.56 5.7-5.7 5.7A5.71 5.71 0 0 1 1.3 8c0-3.14 2.56-5.7 5.7-5.7zM7 1C3.14 1 0 4.14 0 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm1 3H6v5h2V4zm0 6H6v2h2v-2z"
                ></path>
              </svg>
            </span>
            Info
          </h5>
        </div>
        <div className="warning-content">
          <p>
            Consider combining Weighted Pulse with our other technical
            indicators, such as trendlines or moving averages, to validate
            potential reversal signals.
          </p>
        </div>
      </div>

      <h2 id="practical-application" className={`scroll-mt-2 ${styles["h2"]}`}>
        Practical Application
        <Link
          to="#practical-application"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <h3 id="identifying-trend-shifts" className={`${styles["h3"]}`}>
        Identifying Trend Shifts
        <Link
          to="#identifying-trend-shifts"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>

      <ol className={styles.olBullet}>
        <p className={styles.p}>
          <img
            loading="lazy"
            src={DocImages[186]}
            width={751}
            height={607}
            className="mx-auto"
            alt="Weighted Pulse Identifying Trend Shifts"
          ></img>
        </p>
        <li>
          Utilize the Weighted Pulse to identify trend shifts and reversals in
          the market.
        </li>
        <p className={styles.p}>
          <img
            loading="lazy"
            src={DocImages[187]}
            width={336}
            height={486}
            className="mx-auto"
            alt="Weighted Pulse with Volume Profile Pro from MADALGO Pulse"
          ></img>
        </p>
        <li>
          Combine Weighted Pulse insights with other technical indicators for a
          comprehensive trading strategy.
        </li>
      </ol>

      <div
        className={`warning warning-caution selection:bg-[#ffeec4] selection:text-[#4b4b4b] ${styles["alertWarning"]}`}
      >
        <div className="warning-heading">
          <h5 className={styles.h5}>
            <span className={styles.alertWarningIcon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M8.893 1.5c-.183-.31-.52-.5-.887-.5s-.703.19-.886.5L.138 13.499a.98.98 0 0 0 0 1.001c.193.31.53.501.886.501h13.964c.367 0 .704-.19.877-.5a1.03 1.03 0 0 0 .01-1.002L8.893 1.5zm.133 11.497H6.987v-2.003h2.039v2.003zm0-3.004H6.987V5.987h2.039v4.006z"
                ></path>
              </svg>
            </span>
            caution
          </h5>
        </div>
        <div className="warning-content">
          <p className={styles.p}>
            While Weighted Pulse provides valuable insights, confirm its signals
            with additional analysis tools for more accurate trading decisions.
          </p>
          <p className={styles.p}>
            Consider market conditions and other indicators to better interpret
            Weighted Pulse readings.
          </p>
          <p className={styles.p}>
            <em>
              Disclaimer: Please note that the information provided is intended
              for educational purposes only and should not be construed as
              financial advice. Trading involves risks, and it's essential to
              conduct thorough research and seek guidance from financial
              professionals before making trading decisions.
            </em>
          </p>
        </div>
      </div>
    </>
  );
}
