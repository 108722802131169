import { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import styles from "../Docs.module.css";
import { useTOC } from "../TOCContext";
import DocImages from "../DocImages";
import ThemeContext from "../ThemeContext";

const entries = [
  {
    displayText: "Features",
    urlFragment: "features",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "Main Settings",
    urlFragment: "main-settings",
    headingType: "h2",
    subEntries: [
      {
        displayText: "Signals",
        urlFragment: "signals",
        headingType: "h3",
      },
      {
        displayText: "Volume Profile Pro",
        urlFragment: "volume-profile-pro",
        headingType: "h3",
      },
    ],
  },
  {
    displayText: "Adjusting Settings",
    urlFragment: "adjusting-settings",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "Style Settings",
    urlFragment: "style-settings",
    headingType: "h2",
    subEntries: [],
  },
];
export { entries };

export default function DocsMADALGOPulse() {
  const { addToTOC, removeFromTOC } = useTOC();
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    entries.forEach(addToTOC);
    return () => {
      entries.forEach((entry) => removeFromTOC(entry));
    };
  }, [addToTOC, removeFromTOC]);

  return (
    <>
      <h1 className={styles.h1}>MADALGO Pulse</h1>
      <p className={styles.p}>
        MADALGO Pulse is a key component of the toolkit offered by MADALGO,
        encompassing a spectrum of signal modes, indicator overlays, and
        advanced functionalities. It equips traders with enhanced
        decision-making tools for their trading endeavors. Provided below are
        the pages describing each of the features in the main indicator in
        detail:
      </p>

      <h2 id="features" className={`scroll-mt-2 ${styles["h2"]}`}>
        Features
        <Link
          to="#features"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <Link
        to="profit-bands"
        className={`
          ${theme === "light" ? styles.changelogLink : styles.changelogLinkDark}
        `}
      >
        <span
          style={{
            userSelect: "none",
            marginRight: 10,
            marginBottom: 3,
          }}
        >
          »
        </span>
        Profit Bands
      </Link>
      <Link
        to="volume-weighted-strength-index"
        className={`
          ${theme === "light" ? styles.changelogLink : styles.changelogLinkDark}
        `}
      >
        <span
          style={{
            userSelect: "none",
            marginRight: 10,
            marginBottom: 3,
          }}
        >
          »
        </span>
        Volume-Weighted Strength Index
      </Link>
      <Link
        to="hyper-trend"
        className={`
          ${theme === "light" ? styles.changelogLink : styles.changelogLinkDark}
        `}
      >
        <span
          style={{
            userSelect: "none",
            marginRight: 10,
            marginBottom: 3,
          }}
        >
          »
        </span>
        Hyper Trend
      </Link>
      <Link
        to="trend-alert-candles"
        className={`
          ${theme === "light" ? styles.changelogLink : styles.changelogLinkDark}
        `}
      >
        <span
          style={{
            userSelect: "none",
            marginRight: 10,
            marginBottom: 3,
          }}
        >
          »
        </span>
        Trend Alert Candles
      </Link>
      <Link
        to="volume-profile-pro"
        className={`
          ${theme === "light" ? styles.changelogLink : styles.changelogLinkDark}
        `}
      >
        <span
          style={{
            userSelect: "none",
            marginRight: 10,
            marginBottom: 3,
          }}
        >
          »
        </span>
        Volume Profile Pro
      </Link>
      <h2 id="main-settings" className={`scroll-mt-2 ${styles["h2"]}`}>
        Main Settings
        <Link
          to="#main-settings"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        The MADALGO Pulse presents an array of settings influencing its behavior
        and visual aspects. These settings are specific to their corresponding
        features and are elaborated on their respective pages. The indicator
        comprises multiple sections for settings. The foundational settings are
        detailed in the subsequent sections:
      </p>

      <h3 id="signals" className={`scroll-mt-2 ${styles["h3"]}`}>
        Signals
        <Link
          to="#signals"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        The Profit Bands feature offers a versatile range of four buy/sell
        algorithms, each generating signals that can be seamlessly swapped. This
        control empowers you to select the most relevant algorithm for your
        needs.
      </p>
      <p className={styles.p}>
        More details can be found on the page dedicated to this feature.
      </p>
      <Link
        to="profit-bands"
        className={`
          ${theme === "light" ? styles.changelogLink : styles.changelogLinkDark}
        `}
      >
        <span
          style={{
            userSelect: "none",
            marginRight: 10,
            marginBottom: 3,
          }}
        >
          »
        </span>
        Profit Bands
      </Link>

      <h3 id="volume-profile-pro" className={`scroll-mt-2 ${styles["h3"]}`}>
        Volume Profile Pro
        <Link
          to="#volume-profile-pro"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        The Volume Profile Pro feature boasts flip-ability to display on either
        side. You can manipulate the width of volume profile bars and define
        offsets (when flipped) from the current bar index.
      </p>
      <p className={styles.p}>
        Detailed information awaits you on the dedicated page.
      </p>
      <Link
        to="volume-profile-pro"
        className={`
          ${theme === "light" ? styles.changelogLink : styles.changelogLinkDark}
        `}
      >
        <span
          style={{
            userSelect: "none",
            marginRight: 10,
            marginBottom: 3,
          }}
        >
          »
        </span>
        Volume Profile Pro
      </Link>

      <h2 id="adjusting-settings" className={`scroll-mt-2 ${styles["h2"]}`}>
        Adjusting Settings
        <Link
          to="#adjusting-settings"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[80]}
          width={652}
          height={644}
          className="mx-auto"
          alt="Pulse Adjusting Settings"
        ></img>
      </p>
      <p className={styles.p}>
        To fine-tune settings, hover your cursor over the indicator title,
        prompting a list of icons. Click on the gear ⚙️ icon to open the
        settings panel.
      </p>
      <p className={styles.p}>
        Alternatively, double-click any element displayed by the indicator, be
        it signals, overlaid indicators, or the dashboard, to access the
        settings panel.
      </p>

      <h2 id="style-settings" className={`scroll-mt-2 ${styles["h2"]}`}>
        Style Settings
        <Link
          to="#style-settings"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        Every feature is customizable in terms of color, accommodating
        individual user preferences for a seamless UI/UX experience.
      </p>
    </>
  );
}
