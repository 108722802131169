import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Navigate,
} from "react-router-dom";
import { useInView } from "react-intersection-observer";
import ScrollToTop from "./components/ScrollToTop";
import Header from "./components/Header";
import Footer from "./components/Footer";
import SideNav from "./components/SideNav";
import Section from "./components/Section";
import PricingSection from "./components/PricingSection";
import TransitionGlitch from "./components/TransitionGlitch";
import Legal from "./components/Legal";
import AboutUs from "./components/AboutUs";
import Careers from "./components/Careers";
import Contact from "./components/Contact";
import Partners from "./components/Partners";
import Docs from "./components/Docs";
// import Banner from "./components/Banner";
import images from "./components/images";
import Blur from "./components/Blur";
import { Community } from "./components/Community";
import Carousel from "./components/Carousel";
import { FAQ } from "./components/FAQnew";
import styles from "./components/Main.module.css";
import ThemeProvider from "./components/ThemeProvider";
import { TOCProvider } from "./components/TOCContext";
import Blog from "./components/Blog";
import CommunityMADALGO from "./components/CommunityMADALGO";
import ManageAccount from "./components/ManageAccount";
import Signup from "./account/Signup";
import BlogThemeProvider from "./components/BlogThemeProvider";
import Signin from "./account/SignIn";
import ForgotPassword from "./account/ForgotPassword";

import Error from "./components/404";
import Login from "./Login";
import { useAuth } from "./userAuth";
import Dashboard from "./Dashboard";
import SeeYouLater from "./dashboard/SeeYouLater";
import BrandGuidelines from "./components/BrandGuidelines";

export function AccountRouter({ is404Page }) {
  return (
    <Routes>
      <Route path="/u/signup" element={<Signup />} />
      <Route path="/u/signin" element={<Signin />} />
      <Route
        path="/u/reset-password/req/user-pass-auth"
        element={<ForgotPassword />}
      />
      <Route path="*" element={<Error is404Ref={is404Page} />} />
    </Routes>
  );
}

export function ProtectedRoute({ children }) {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    // Redirect to the login page
    return <Navigate to="/login" replace />;
  }

  return children;
}

function MainContent({ billingCycle, setBillingCycle, is404Page, ...props }) {
  const location = useLocation();
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    if (window.location.hash) {
      const checkElement = setInterval(() => {
        const element = document.querySelector(window.location.hash);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
          clearInterval(checkElement);
        }
      }, 100);

      // Clear the interval after 3 seconds if the element is not found
      setTimeout(() => {
        clearInterval(checkElement);
      }, 3000);
    }
  }, [location]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoader(false);
    });

    return () => clearTimeout(timer); // cleanup the timeout if the component unmounts
  }, []);
  const [slideInFromLeft, setSlideInFromLeft] = useState({
    hidden: { opacity: 0, x: -500 },
    visible: { opacity: 1, x: 0 },
  });

  const [slideInFromRight, setSlideInFromRight] = useState({
    hidden: { opacity: 0, x: 500 },
    visible: { opacity: 1, x: 0 },
  });

  const [slideUpFromBottom, setSlideUpFromBottom] = useState({
    hidden: { opacity: 0, y: 200 },
    visible: { opacity: 1, y: 0 },
  });

  const [ref1, inView1] = useInView({
    triggerOnce: true,
  });

  const [ref2, inView2] = useInView({
    triggerOnce: true,
  });

  const [ref3, inView3] = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setSlideInFromLeft({
        hidden: { opacity: 0, x: -250 },
        visible: { opacity: 1, x: 0 },
      });

      setSlideInFromRight({
        hidden: { opacity: 0, x: 250 },
        visible: { opacity: 1, x: 0 },
      });
      setSlideUpFromBottom({
        hidden: { opacity: 0, y: 100 },
        visible: { opacity: 1, y: 0 },
      });
    } else {
      setSlideInFromLeft({
        hidden: { opacity: 0, x: -500 },
        visible: { opacity: 1, x: 0 },
      });

      setSlideInFromRight({
        hidden: { opacity: 0, x: 500 },
        visible: { opacity: 1, x: 0 },
      });
      setSlideUpFromBottom({
        hidden: { opacity: 0, y: 200 },
        visible: { opacity: 1, y: 0 },
      });
    }

    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setSlideInFromLeft({
          hidden: { opacity: 0, x: -250 },
          visible: { opacity: 1, x: 0 },
        });
        setSlideInFromRight({
          hidden: { opacity: 0, x: 250 },
          visible: { opacity: 1, x: 0 },
        });
        setSlideUpFromBottom({
          hidden: { opacity: 0, y: 100 },
          visible: { opacity: 1, y: 0 },
        });
      } else {
        setSlideInFromLeft({
          hidden: { opacity: 0, x: -500 },
          visible: { opacity: 1, x: 0 },
        });
        setSlideInFromRight({
          hidden: { opacity: 0, x: 500 },
          visible: { opacity: 1, x: 0 },
        });
        setSlideUpFromBottom({
          hidden: { opacity: 0, y: 200 },
          visible: { opacity: 1, y: 0 },
        });
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const excludedPaths = ["/docs", "/login", "/seeyoulater", "/dashboard"];

  const shouldExcludeComponent = (pathname) =>
    excludedPaths.some((excludedPath) => pathname.startsWith(excludedPath));

  return (
    <>
      {!shouldExcludeComponent(location.pathname) && !is404Page.current && (
        <Header {...props} />
      )}
      {!shouldExcludeComponent(location.pathname) && (
        <SideNav
          open={props.menuOpen}
          handleMenuClick={props.handleMenuClick}
        />
      )}
      <TransitionGlitch isClicked={props.menuOpen} />
      <main
        id={location.pathname.startsWith("/docs") ? "docs-r-us" : "main"}
        className={
          location.pathname.startsWith("/docs")
            ? ""
            : "main relative block overflow-hidden"
        }
      >
        <div
          id={
            location.pathname.startsWith("/docs")
              ? "docs-r-us-wrapper"
              : "global-w"
          }
          className={
            location.pathname.startsWith("/docs")
              ? "flex min-h-full flex-col [word-wrap:break-word]"
              : `mx-auto ${
                  props.isAboutUsPage || props.isBlogPage ? "" : "max-w-8xl"
                }`
          }
        >
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/dashboard/*" element={<Dashboard />} />
            <Route path="/seeyoulater" element={<SeeYouLater />} />
            <Route path="/manage-account" element={<ManageAccount />} />
            <Route path="/community/*" element={<CommunityMADALGO />} />
            <Route path="/legal/*" element={<Legal />} />
            <Route path="/brand" element={<BrandGuidelines />} />
            <Route
              path="/about-us"
              element={<AboutUs setIsAboutUsPage={props.setIsAboutUsPage} />}
            />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/partners" element={<Partners />} />

            <Route
              path="/blog/*"
              element={
                <BlogThemeProvider>
                  <Blog setIsBlogPage={props.setIsBlogPage} />
                </BlogThemeProvider>
              }
            />

            <Route
              path="/docs/*"
              element={
                <ThemeProvider>
                  <TOCProvider>
                    <Docs />
                  </TOCProvider>
                </ThemeProvider>
              }
            />
            <Route
              path="/"
              element={
                <>
                  <div
                    id="main-loader"
                    className={
                      showLoader
                        ? `${styles.mainLoader} ${styles.mainhideLoader}`
                        : styles.mainLoader
                    }
                  ></div>
                  <div
                    className=" pointer-events-none absolute bottom-0 left-0 right-0 top-[-700px] bg-center-top bg-no-repeat opacity-30"
                    style={{ backgroundImage: `url(${images[0]})` }}
                  ></div>
                  <div
                    className="absolute left-2/3 top-[10%] z-[-10] transform animate-move blur-[64px] filter sm:left-2/3 sm:top-[10%]"
                    aria-hidden="true"
                  >
                    <div
                      className="h-[100vh] w-[100vw] animate-gradient bg-gradient-to-r from-[#80caff] to-[#4f46e5,rgba(128,202,255,0)] opacity-20 sm:h-[80vw] sm:w-[80vw] md:h-[70vw] md:w-[70vw] lg:h-[60vw] lg:w-[60vw]"
                      style={{
                        clipPath:
                          "polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)",
                      }}
                    ></div>
                  </div>
                  <div
                    className="absolute left-2/3 top-[10%] z-[-10] transform animate-move blur-[64px] filter sm:left-2/3 sm:top-[10%]"
                    aria-hidden="true"
                  >
                    <div
                      className="h-[100vh] w-[100vw] animate-gradient bg-gradient-to-r from-[#80caff] to-[#4f46e5,rgba(128,202,255,0)] opacity-20 sm:h-[80vw] sm:w-[80vw] md:h-[70vw] md:w-[70vw] lg:h-[60vw] lg:w-[60vw]"
                      style={{
                        clipPath:
                          "polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)",
                      }}
                    ></div>
                  </div>
                  <Section
                    hero={true}
                    cta="Get 14 Days Risk Free!"
                    ctaLink="#premium-packages"
                    secondaryLink="/learn-more"
                  />
                  <div className="flex flex-col gap-4 py-4 sm:pb-24">
                    <motion.p
                      ref={ref1}
                      variants={slideInFromLeft}
                      initial="hidden"
                      animate={inView1 ? "visible" : "hidden"}
                      transition={{
                        duration: 0.8,
                        ease: [0.43, 0.13, 0.23, 0.96],
                      }}
                      className="bg-clip-text text-center text-2xl font-bold text-transparent text-white drop-shadow-md text-balance xl:text-4xl 2xl:text-[48px]"
                    >
                      Why MADALGO?
                    </motion.p>
                    <motion.p
                      ref={ref2}
                      variants={slideInFromRight}
                      initial="hidden"
                      animate={inView2 ? "visible" : "hidden"}
                      transition={{
                        duration: 0.8,
                        ease: [0.43, 0.13, 0.23, 0.96],
                      }}
                      className="bg-gradient-to-r from-blue-500 to-purple-500 bg-clip-text text-center text-xl font-bold text-transparent drop-shadow-md text-balance lg:text-2xl xl:text-4xl xl:leading-[3rem] 2xl:text-[48px] 2xl:leading-[4rem]"
                    >
                      AI is transforming how we trade...
                    </motion.p>
                    <motion.p
                      ref={ref3}
                      variants={slideUpFromBottom}
                      initial="hidden"
                      animate={inView3 ? "visible" : "hidden"}
                      transition={{
                        duration: 1.3,
                        ease: [0.43, 0.13, 0.23, 0.96],
                      }}
                      className="bg-clip-text py-2 text-center text-sm font-semibold text-transparent text-white drop-shadow-md text-balance lg:text-lg lg:leading-[130%_!important] xl:text-2xl"
                    >
                      Pioneering the future of technical indicators,
                      <br />
                      aiming to be the world's{" "}
                      <strong>#1 trusted provider</strong>.
                    </motion.p>
                  </div>
                  <Section showImage={true} images={images} />
                  <Section title="PRICING">
                    <div className=" pt-20"></div>
                    <div
                      id="blur"
                      className=" absolute bottom-[0%] left-[0%] right-[0%] top-[0%] z-0 block"
                    >
                      <div
                        id="gradient-blur-group-1"
                        className="lg:w[85vw] absolute bottom-[163.8rem] left-auto right-[-6.6rem] top-auto h-[100vw] w-[95vw] opacity-60 filter-none sm:bottom-[104.6rem] md:bottom-[86.5rem] md:w-screen lg:bottom-[80rem] lg:right-auto lg:h-[85vw] xl:bottom-[45rem] xl:left-[5.7rem] xl:max-h-[100rem] xl:max-w-[100rem]"
                      >
                        <Blur />
                      </div>
                      <div
                        id="gradient-blur-group-2"
                        className="lg:w[85vw] absolute bottom-[20.8rem] left-auto right-[1.6rem] top-auto h-[100vw] w-[95vw] opacity-60 filter-none sm:bottom-[10rem] md:bottom-[4.5rem] md:w-screen lg:bottom-80 lg:right-auto lg:h-[85vw] xl:left-[-5.7rem] xl:top-60 xl:max-h-[100rem] xl:max-w-[100rem]"
                      >
                        <Blur />
                      </div>
                    </div>
                    <PricingSection
                      billingCycle={billingCycle}
                      setBillingCycle={setBillingCycle}
                    />
                    <div
                      id="-blur-line-container"
                      className="absolute bottom-[0%] left-[0%] right-[0%] top-[0%] z-[-1] block"
                    >
                      <img
                        id="blur-light-line-coin"
                        className="absolute -bottom-20 left-0 right-0 w-full opacity-60 filter-none md:-bottom-[54rem] lg:-bottom-[66rem]"
                        src={images[24]}
                        alt=""
                      ></img>

                      <img
                        id="blurred-tesla-coin"
                        className="absolute -bottom-4 left-0 right-0 w-12 opacity-60 blur-[1px] xsm:w-16 md:-bottom-[44rem] md:w-20 lg:-bottom-[56rem] lg:w-28 xl:-bottom-[50rem] xl:left-24"
                        src={images[25]}
                        alt=""
                      ></img>
                    </div>
                  </Section>
                  <section id="FAQ-section" className="block">
                    <FAQ />
                  </section>
                  <Community />
                  <section id="carousel-section" className="block">
                    <Carousel />
                  </section>
                </>
              }
            />
            <Route path="*" element={<Error is404Ref={is404Page} />} />
          </Routes>
        </div>
      </main>
      {!shouldExcludeComponent(location.pathname) && !is404Page.current && (
        <Footer />
      )}
    </>
  );
}

function App() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [buttonOpacity, setButtonOpacity] = useState(1);
  const [isLogoHidden, setIsLogoHidden] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [isAboutUsPage, setIsAboutUsPage] = useState(false);
  const [isBlogPage, setIsBlogPage] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [billingCycle, setBillingCycle] = useState("monthly");
  const is404Page = useRef(false);
  let Checkout;

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoader(false);
    });

    return () => clearTimeout(timer);
  }, []);

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
    setButtonOpacity(buttonOpacity === 1 ? 0 : 1);
    setIsLogoHidden(!isLogoHidden);
    setIsClicked(!isClicked);
    document.body.style.overflow = menuOpen ? "" : "hidden";
    if (!menuOpen) {
      document.body.classList.add("body-black");
    } else {
      document.body.classList.remove("body-black");
    }
  };
  const [stylesLoaded, setStylesLoaded] = useState(false);
  const subdomain = window.location.hostname.split(".")[0];

  useEffect(() => {
    // Change body classes based on subdomain
    if (subdomain === "account" || subdomain === "app") {
      document.body.className = "";
    }

    // Change first div id based on subdomain
    const firstDiv = document.querySelector("body > div");

    if (subdomain === "account") {
      firstDiv.id = "";
      firstDiv.className = "ac521c120 ab537c140";
    } else if (subdomain === "app") {
      firstDiv.id = "__react";
    } else {
      firstDiv.id = "smooth-wrapper";
      firstDiv.className = "";
    }

    const loadStyles = async () => {
      if (subdomain === "account") {
        await import("./account/css/Signup.css");
        await import("./account/css/index.css");
      } else {
        await import("./index.css");
        await import("./styles.css");
      }
      setStylesLoaded(true);
    };

    loadStyles();
  }, [subdomain]);

  if (!stylesLoaded) {
    return null;
  }

  if (subdomain === "account") {
    return (
      <Router>
        <main className="ad893njd a48hjdnl">
          <AccountRouter is404Page={is404Page} />
        </main>
      </Router>
    );
  }

  if (subdomain === "app") {
    Checkout = require("./checkout/CheckOut").default;

    return (
      <Router>
        <main className="main__checkout__madalgo">
          <Routes>
            <Route path="/checkout" element={<Checkout />} />
            <Route path="*" element={<Error is404Ref={is404Page} />} />
          </Routes>
        </main>
      </Router>
    );
  }

  return (
    <Router>
      <ScrollToTop />
      <div className="App absolute min-h-screen min-w-full text-white">
        <MainContent
          onMenuClick={handleMenuClick}
          isMenuOpen={menuOpen}
          buttonOpacity={buttonOpacity}
          isLogoHidden={isLogoHidden}
          isClicked={isClicked}
          setIsClicked={setIsClicked}
          menuOpen={menuOpen}
          isAboutUsPage={isAboutUsPage}
          isBlogPage={isBlogPage}
          setIsBlogPage={setIsBlogPage}
          showLoader={showLoader}
          setShowLoader={setShowLoader}
          handleMenuClick={handleMenuClick}
          setIsAboutUsPage={setIsAboutUsPage}
          billingCycle={billingCycle}
          setBillingCycle={setBillingCycle}
          is404Page={is404Page}
        />
      </div>
    </Router>
  );
}

export default App;
