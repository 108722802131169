import { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import styles from "../Docs.module.css";
import { useTOC } from "../TOCContext";
import DocImages from "../DocImages";
import ThemeContext from "../ThemeContext";

const entries = [
  {
    displayText: "Activation and Settings",
    urlFragment: "activation-and-settings",
    headingType: "h2",
    subEntries: [
      {
        displayText: "Activation",
        urlFragment: "activation",
        headingType: "ol",
      },
      {
        displayText: "Divergence Method",
        urlFragment: "divergence-method",
        headingType: "ol",
      },
      {
        displayText: "Styling",
        urlFragment: "styling",
        headingType: "ol",
      },
    ],
  },
  {
    displayText: "Detection and Display",
    urlFragment: "detection-and-display",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "Importance and Use",
    urlFragment: "importance-and-use",
    headingType: "h2",
    subEntries: [
      {
        displayText: "Spotting Trend Reversals",
        urlFragment: "spotting-trend-reversals",
        headingType: "h3",
      },
      {
        displayText: "Timing Entry and Exit Points",
        urlFragment: "timing-entry-and-exit-points",
        headingType: "h3",
      },
    ],
  },
];
export { entries };

export default function DocsMADALGONavigatorDivergenceDetector() {
  const { theme } = useContext(ThemeContext);
  const { addToTOC, removeFromTOC } = useTOC();

  useEffect(() => {
    entries.forEach(addToTOC);
    return () => {
      entries.forEach((entry) => removeFromTOC(entry));
    };
  }, [addToTOC, removeFromTOC]);

  return (
    <>
      <h1 className={styles.h1}>Divergence Detector</h1>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[127]}
          width={1125}
          height={473}
          className="mx-auto"
          alt="Divergence Detector"
        ></img>
      </p>
      <p className={styles.p}>
        The "Divergence Detector" feature in MADALGO Navigator is a powerful
        tool designed to identify divergences between price and various custom
        oscillators. By detecting these divergences, traders can gain insights
        into potential trend reversals and price movements. The Divergence
        Detector offers a range of options and customization to cater to
        different trading strategies and preferences.
      </p>

      <h2 id="settings" className={`scroll-mt-2 ${styles["h2"]}`}>
        Settings
        <Link
          to="#settings"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        Before using the Divergence Detector indicator, it's essential to
        familiarize yourself with its customizable settings. Here are the key
        settings you can adjust:
      </p>
      <ol id="activation" className={styles.olBullet}>
        <li>Activation</li>
      </ol>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[128]}
          width={564}
          height={539}
          className="mx-auto"
          alt="Divergence Detector Settings"
        ></img>
      </p>
      <p className={styles.p}>
        Enable the Divergence Hunter feature by toggling the "Div Hunter" switch
        to the ON position in the main settings of MADALGO Navigator. This will
        enable the detection and display of divergences on your chart.
      </p>
      <ol id="divergence-method" className={styles.olBullet}>
        <li>Divergence Method</li>
      </ol>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[129]}
          width={118}
          height={335}
          className="mx-auto"
          alt="Divergence Detector Method"
        ></img>
      </p>
      <p className={styles.p}>
        Select the type of divergence you want the Divergence Hunter to search
        for from the dropdown menu. Choose among various oscillator methods that
        align with the features found in MADALGO Matrix. The selected method
        determines the specific algorithm used to identify divergences.
      </p>

      <ol id="styling" className={styles.olBullet}>
        <li>Styling</li>
      </ol>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[130]}
          width={351}
          height={99}
          className="mx-auto"
          alt="Divergence Detector Styling"
        ></img>
      </p>
      <p className={styles.p}>
        Customize the appearance of the divergence markers:
      </p>
      <ol className={styles.ol}>
        <li>
          Bull and Bear Symbol Colors: Assign colors to the labels that indicate
          bullish and bearish divergences. These colors help differentiate
          between different types of divergences.
        </li>
      </ol>
      <div
        className={`
        info info-tip selection:bg-[#c4f6ff] selection:text-[#2c2c2c49]
        ${theme === "light" ? styles.infoTipInfo : styles.infoTipInfoDark} 
        `}
      >
        <div className="info-heading">
          <h5 className={styles.h5}>
            <span
              className={`
                ${theme === "light" ? styles.infoTip : styles.infoTipDark}
              `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 12 16"
              >
                <path
                  fillRule="evenodd"
                  d="M6.5 0C3.48 0 1 2.19 1 5c0 .92.55 2.25 1 3 1.34 2.25 1.78 2.78 2 4v1h5v-1c.22-1.22.66-1.75 2-4 .45-.75 1-2.08 1-3 0-2.81-2.48-5-5.5-5zm3.64 7.48c-.25.44-.47.8-.67 1.11-.86 1.41-1.25 2.06-1.45 3.23-.02.05-.02.11-.02.17H5c0-.06 0-.13-.02-.17-.2-1.17-.59-1.83-1.45-3.23-.2-.31-.42-.67-.67-1.11C2.44 6.78 2 5.65 2 5c0-2.2 2.02-4 4.5-4 1.22 0 2.36.42 3.22 1.19C10.55 2.94 11 3.94 11 5c0 .66-.44 1.78-.86 2.48zM4 14h5c-.23 1.14-1.3 2-2.5 2s-2.27-.86-2.5-2z"
                ></path>
              </svg>
            </span>
            Tip
          </h5>
        </div>
        <div className="warning-content">
          <p>
            While the Divergence Hunter is a powerful tool, consider confirming
            divergence signals with our other technical indicators or analysis
            techniques to reduce false signals.
          </p>
        </div>
      </div>

      <h2 id="detection-and-display" className={`scroll-mt-2 ${styles["h2"]}`}>
        Detection and Display
        <Link
          to="#detection-and-display"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <figure className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[131]}
          width={294}
          height={486}
          className="mx-auto"
          alt="Divergence Detector Bear Signal"
        ></img>
        <figcaption
          style={{
            textAlign: "center",
            fontSize: 12,
          }}
        >
          4hr BTC
        </figcaption>
      </figure>
      <p className={styles.p}>
        When the Divergence Hunter identifies a divergence based on the selected
        oscillator method, it places labels above or below the corresponding
        candle bars. These labels serve as visual markers indicating the
        presence of a divergence between price and the oscillator.
      </p>

      <h2 id="importance-and-use" className={`scroll-mt-2 ${styles["h2"]}`}>
        Importance and Use
        <Link
          to="#importance-and-use"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <h3 id="spotting-trend-reversals" className={`${styles["h3"]}`}>
        Spotting Trend Reversals
        <Link
          to="#spotting-trend-reversals"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[132]}
          width={755}
          height={493}
          className="mx-auto"
          alt="Divergence Detector Trend Reversals"
        ></img>
      </p>
      <p className={styles.p}>
        Divergences between price and oscillators can signal potential trend
        reversals. Bullish divergences may indicate a potential upward reversal,
        while bearish divergences could suggest a potential downward reversal.
      </p>
      <div
        className={`
        info info-tip selection:bg-[#c4f6ff] selection:text-[#2c2c2c49]
        ${theme === "light" ? styles.infoWarning : styles.infoWarningDark} 
        `}
      >
        <div className="info-heading">
          <h5 className={styles.h5}>
            <span
              className={`
                ${theme === "light" ? styles.infoAlert : styles.infoAlertDark}
              `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 12 16"
              >
                <path
                  fillRule="evenodd"
                  d="M7 2.3c3.14 0 5.7 2.56 5.7 5.7s-2.56 5.7-5.7 5.7A5.71 5.71 0 0 1 1.3 8c0-3.14 2.56-5.7 5.7-5.7zM7 1C3.14 1 0 4.14 0 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm1 3H6v5h2V4zm0 6H6v2h2v-2z"
                ></path>
              </svg>
            </span>
            Info
          </h5>
        </div>
        <div className="warning-content">
          <p>
            Incorporate the Divergence Hunter into your existing trading
            strategies. Combining divergence signals with other indicators can
            enhance your trading decision-making process.
          </p>
        </div>
      </div>
      <h3 id="confirmation-of-trends" className={`${styles["h3"]}`}>
        Timing Entry and Exit Points
        <Link
          to="#confirmation-of-trends"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[133]}
          width={514}
          height={524}
          className="mx-auto"
          alt="Divergence Detector Timing Entrys"
        ></img>
      </p>
      <p className={styles.p}>
        The Divergence Hunter can help traders time their entry and exit points
        more effectively. Divergence signals often occur at points of potential
        trend changes, providing traders with valuable insights into when to
        consider entering or exiting a trade.
      </p>

      <div
        className={`warning warning-caution selection:bg-[#ffeec4] selection:text-[#4b4b4b] ${styles["alertWarning"]}`}
      >
        <div className="warning-heading">
          <h5 className={styles.h5}>
            <span className={styles.alertWarningIcon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M8.893 1.5c-.183-.31-.52-.5-.887-.5s-.703.19-.886.5L.138 13.499a.98.98 0 0 0 0 1.001c.193.31.53.501.886.501h13.964c.367 0 .704-.19.877-.5a1.03 1.03 0 0 0 .01-1.002L8.893 1.5zm.133 11.497H6.987v-2.003h2.039v2.003zm0-3.004H6.987V5.987h2.039v4.006z"
                ></path>
              </svg>
            </span>
            caution
          </h5>
        </div>
        <div className="warning-content">
          <p className={styles.p}>
            While the Divergence Hunter is a valuable tool, it's important not
            to solely rely on it for trading decisions. Divergence signals
            should be part of a comprehensive trading strategy that includes
            risk management and other analysis techniques.
          </p>
          <p className={styles.p}>
            <em>
              Disclaimer: The information provided here is for educational
              purposes only and does not constitute financial advice. Trading
              involves risk, and it's essential to conduct thorough research and
              seek advice from financial professionals before making any trading
              decisions.
            </em>
          </p>
        </div>
      </div>
    </>
  );
}
