import { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import styles from "../Docs.module.css";
import { useTOC } from "../TOCContext";
import ThemeContext from "../ThemeContext";

const entries = [
  {
    displayText: "Psychology and Trading",
    urlFragment: "psychology-and-trading",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "Overcoming Emotional Biases",
    urlFragment: "overcoming-emotional-biases",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "Staying Calm under Pressure",
    urlFragment: "staying-calm-under-pressure",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "Developing a Trading Plan",
    urlFragment: "devoloping-a-trading-place",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "Journaling and Self-Reflection",
    urlFragment: "journaling-and-self-reflection",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "Practicing Patience",
    urlFragment: "practicing-patience",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "Risk Management and Emotional Discipline",
    urlFragment: "risk-management-and-emotional-discipline",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "Continuous Learning",
    urlFragment: "continuous-learning",
    headingType: "h2",
    subEntries: [],
  },
];
export { entries };

export default function DocsTradingBestPracticesEmotionalDiscipline() {
  const { theme } = useContext(ThemeContext);
  const { addToTOC, removeFromTOC } = useTOC();

  useEffect(() => {
    entries.forEach(addToTOC);
    return () => {
      entries.forEach((entry) => removeFromTOC(entry));
    };
  }, [addToTOC, removeFromTOC]);

  return (
    <>
      <h1 className={styles.h1}>Emotional Discipline</h1>

      <p className={styles.p}>
        Emotional discipline plays a vital role in successful trading, often
        distinguishing between seasoned traders and those who struggle. While
        this aspect might not have numerical indicators like capital or
        portfolio allocation, it significantly influences trading outcomes.
        Maintaining emotional composure helps traders adhere to their
        strategies, make rational decisions, and manage risk effectively.
      </p>
      <h2 id="psychology-and-trading" className={`scroll-mt-2 ${styles["h2"]}`}>
        Psychology and Trading
        <Link
          to="#psychology-and-trading"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        Understanding the psychology of trading is crucial. Emotions such as
        fear, greed, and impulsiveness can cloud judgment and lead to poor
        decision-making.
      </p>
      <p className={styles.p}>
        Fear of losses might prevent taking reasonable risks, while greed can
        lead to holding positions for too long or chasing after quick gains.
      </p>
      <p className={styles.p}>
        Emotions also contribute to overtrading, ignoring stop losses, and
        making impulsive trades.
      </p>
      <h2
        id="overcoming-emotional-biases"
        className={`scroll-mt-2 ${styles["h2"]}`}
      >
        Overcoming Emotional Biases
        <Link
          to="#overcoming-emotional-biases"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        Recognizing and overcoming emotional biases is a cornerstone of
        emotional discipline.
      </p>
      <p className={styles.p}>
        Confirmation bias, for instance, leads to selectively seeking
        information that aligns with preconceived notions.
      </p>
      <p className={styles.p}>
        Anchoring bias can cause traders to cling to an initial position despite
        changing market conditions.
      </p>
      <p className={styles.p}>
        Overcoming these biases involves critical self-awareness and a
        commitment to objective analysis.
      </p>

      <h2
        id="staying-calm-under-pressure"
        className={`scroll-mt-2 ${styles["h2"]}`}
      >
        Staying Calm under Pressure
        <Link
          to="#staying-calm-under-pressure"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        Market fluctuations and unexpected events can trigger emotional
        responses. Maintaining a calm demeanor during such times is key.
      </p>
      <p className={styles.p}>
        Setting predefined exit points, both for profits and losses, can help
        mitigate impulsive decisions driven by emotional responses. Practicing
        mindfulness techniques and stress management strategies can aid in
        staying level-headed during high-pressure situations.
      </p>
      <h2
        id="developing-a-trading-plan"
        className={`scroll-mt-2 ${styles["h2"]}`}
      >
        Developing a Trading Plan
        <Link
          to="#developing-a-trading-plan"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        A well-structured trading plan acts as a guide, reducing the impact of
        emotional decision-making. A trading plan should outline entry and exit
        strategies, risk management rules, and position sizing guidelines.
        Following a comprehensive plan minimizes the likelihood of deviating
        from your strategy due to emotional reactions.
      </p>
      <Link
        to="/docs/best-practices/trader-profile"
        className={`
          ${theme === "light" ? styles.changelogLink : styles.changelogLinkDark}
        `}
      >
        <span
          style={{
            userSelect: "none",
            marginRight: 10,
            marginBottom: 3,
          }}
        >
          »
        </span>
        Trading Plan
      </Link>
      <h2
        id="journaling-and-self-reflection"
        className={`scroll-mt-2 ${styles["h2"]}`}
      >
        Journaling and Self-Reflection
        <Link
          to="#journaling-and-self-reflection"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        Regularly journaling your trades and reviewing them provides valuable
        insights into emotional patterns. Documenting your thoughts and emotions
        during trades helps identify recurring triggers. Self-reflection fosters
        accountability and helps you make conscious efforts to manage emotions
        better.
      </p>
      <h2 id="practicing-patience" className={`scroll-mt-2 ${styles["h2"]}`}>
        Practicing Patience
        <Link
          to="#practicing-patience"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        Emotional discipline requires patience. Impatience often leads to hasty
        decisions. Waiting for clear signals and well-defined opportunities
        aligns with a disciplined approach. Patience prevents chasing trades out
        of FOMO (Fear Of Missing Out) and encourages disciplined waiting for
        optimal setups.
      </p>
      <h2
        id="risk-management-and-emotional-discipline"
        className={`scroll-mt-2 ${styles["h2"]}`}
      >
        Risk Management and Emotional Discipline
        <Link
          to="#risk-management-and-emotional-discipline"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        Effective risk management is intertwined with emotional discipline.
        Risking more than you're comfortable with due to emotional impulses can
        lead to significant losses. Establishing risk limits aligned with your
        risk tolerance and sticking to them reinforces emotional discipline.
      </p>
      <h2 id="continuous-learning" className={`scroll-mt-2 ${styles["h2"]}`}>
        Continuous Learning
        <Link
          to="#continuous-learning"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        Lastly, emotional discipline is an ongoing journey. Continuously
        learning about behavioral finance, cognitive biases, and trading
        psychology equips you with tools to manage emotions effectively.
        Engaging with trading communities and seeking guidance from experienced
        traders can provide valuable insights.
      </p>
      <p className={styles.p}>
        Incorporating emotional discipline into your trading approach isn't just
        about numbers; it's about cultivating the mental fortitude necessary for
        consistent and successful trading outcomes.
      </p>
    </>
  );
}
