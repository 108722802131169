import { useState, useEffect } from "react";
import { SvgIcon } from "./BrandSVG";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useContext } from "react";
import ThemeContext from "./ThemeContext";
import styles from "./Main.module.css";
import menuItems from "./DocsMenuItems";
import { allEntries } from "./DocsEntries";

export default function DocsHeader() {
  const [isVisible, setIsVisible] = useState(window.innerWidth <= 1024);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSideNavOpen, setSideNavOpen] = useState(false);
  const navigate = useNavigate();

  const [showNav, setShowNav] = useState(true);
  const [lastScrollPos, setLastScrollPos] = useState(0);
  const navClass = showNav
    ? ""
    : "[transform:_translate3d(0,calc(-100%_-_2px),0)]";

  const { theme, toggleTheme } = useContext(ThemeContext);
  const [openDropdown, setOpenDropdown] = useState(null);
  const location = useLocation();
  const currentPath = location.pathname;
  const isFaqPage = currentPath === "/docs/faq";
  const faqClassName = isFaqPage
    ? "bg-[rgba(164,159,180,.15)] [color:rgb(168_85_247)]"
    : "";

  const [selectedItem, setSelectedItem] = useState({
    main: null,
    sub: null,
    dropdown: null,
  });

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 1024) {
        setSideNavOpen(false);
      }
    }

    window.addEventListener("resize", handleResize);

    // Clean up event listener when the component unmounts
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (isSideNavOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    // Optional: Cleanup function to remove the class when the component unmounts
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isSideNavOpen]);

  const getLinkClass = (isDropdownOpen, subIndex, theme) => {
    const baseClasses =
      "flex justify-between items-center rounded-[.25rem] py-[.375rem] pl-0 pr-2 text-sm font-semibold leading-tight transition-[background,_color] duration-200 [text-decoration:none] [transition-timing-function:cubic-bezier(.08,.52,.52,1)] [word-break:break-word]";

    let themeClasses;
    if (theme === "light") {
      themeClasses =
        isDropdownOpen === subIndex
          ? "text-slate-900 hover:bg-[rgba(0,0,0,.05)]"
          : "text-slate-900 hover:bg-[rgba(0,0,0,.05)]";
    } else {
      themeClasses =
        isDropdownOpen === subIndex
          ? "text-zinc-300 hover:bg-[rgba(255,255,255,0.05)]"
          : "text-zinc-300 hover:bg-[rgba(255,255,255,0.05)]";
    }

    return `${baseClasses} ${themeClasses}`;
  };

  useEffect(() => {
    document.body.className = theme; // 'light' or 'dark'
  }, [theme]);

  const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  const handleSearch = (query) => {
    const results = allEntries.reduce((acc, entry) => {
      if (entry.displayText.toLowerCase().includes(query.toLowerCase())) {
        return [...acc, entry];
      }

      const matchingSubEntries = entry.subEntries.filter((subEntry) =>
        subEntry.displayText.toLowerCase().includes(query.toLowerCase()),
      );

      if (matchingSubEntries.length > 0) {
        return [
          ...acc,
          {
            ...entry,
            subEntries: matchingSubEntries.map((subEntry) => ({
              ...subEntry,
              parentUrl: entry.urlFragment,
            })),
          },
        ];
      }

      return acc;
    }, []);
    setFilteredResults(results);
    setIsModalOpen(true);
    document.body.classList.add("overflow-hidden");
  };

  const debouncedHandleSearch = debounce(handleSearch, 300);
  let currentSearchId = 0;

  const onSearchChange = (e) => {
    const searchId = ++currentSearchId;
    const inputValue = e.target.value;

    setSearchQuery(inputValue);

    clearTimeout(debouncedHandleSearch.currentTimeout);
    debouncedHandleSearch.currentTimeout = setTimeout(() => {
      if (searchId === currentSearchId) {
        debouncedHandleSearch(inputValue);
      }
    }, 300);
  };

  const closeModal = () => {
    document.body.classList.remove("overflow-hidden");
    setFilteredResults([]);
    setIsModalOpen(false);
    setSearchQuery("");
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;

      // Check if the current scroll position is near the top of the page
      const nearTop = currentScrollPos < 50; // Threshold set to 50px

      if (nearTop) {
        setShowNav(true);
      } else if (currentScrollPos < lastScrollPos) {
        setShowNav(true);
      } else if (currentScrollPos > lastScrollPos) {
        setShowNav(false);
      }

      setLastScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollPos]);

  useEffect(() => {
    const handleResize = () => {
      setIsVisible(window.innerWidth <= 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <nav
      id="docs-nav"
      className={`sticky top-0 z-[3] flex h-[3.75rem] bg-[linear-gradient(-45deg,#1b85ae,#ec00f4)] p-[.1rem] shadow-[0_1px_2px_0_rgba(0,0,0,.1)] transition-transform duration-200 ${navClass}`}
    >
      <div id="nav-inner" className="flex w-full flex-wrap justify-between">
        <div id="navitems" className="flex min-w-0 items-center [flex:1]">
          <button
            id="nav"
            aria-label="Navigation bar toggle"
            className=" mr-2 cursor-pointer bg-none p-0 pl-2 text-white [border:none] [display:inherit] [font-family:inherit] lg:hidden"
            type="button"
            tabIndex="0"
            onClick={() => setSideNavOpen(!isSideNavOpen)}
          >
            <svg width="30" height="30" viewBox="0 0 30 30" aria-hidden="true">
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="2"
                d="M4 7h22M4 15h22M4 23h22"
              ></path>
            </svg>
          </button>
          <Link
            to="/docs"
            className="mr-2 flex min-w-0 items-center text-gray-900 transition-colors duration-200 [text-decoration:none] [transition-timing-function:cubic-bezier(0.08,_.52,_0.52,_1)] lg:ml-2 lg:w-[240.4px] xl:w-[270.4px]"
          >
            <SvgIcon />
            <div className="ml-2 mt-[.2rem] text-white">
              <svg
                className="svg-header-logo [transition:all_.15s_ease-in-out]"
                version="1.1"
                id="madalgo"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="82"
                height="14.1667"
                fill="#fff"
                viewBox="0 0 163 24"
                xmlSpace="preserve"
              >
                <g>
                  <g>
                    <path d="M39.2,12.1H28.9V3.4h-3.4v16.9h3.4v-4.8h10.3V24h3.4V3.4h-3.4V12.1z" />
                    <path d="M39.2,3.4V0H28.9v3.4H39.2z" />
                  </g>
                  <g>
                    <path d="M123.8,19.9h4V4.1h-4V19.9z" />
                    <path d="M138.9,19.9h4V4.1h-4V19.9z" />
                    <path d="M139,4.1V0h-11.3v4.1H139z" />
                    <path d="M139,24v-4.1h-11.3V24H139z" />
                  </g>
                  <path d="M0.1,24h3.4V3.4H0.1V24z" />
                  <path d="M9,10.8h3.4V2.7H9V10.8z" />
                  <path d="M18,20.5h3.4V3.4H18V20.5z" />
                  <path d="M19.1,3.5V0H2.3v3.4L19.1,3.5L19.1,3.5z" />
                  <path d="M92.5,20.5V3.2h-3.4V24h12.1v-3.5H92.5z" />
                  <path d="M79,0h-3.8l-8.7,24h3.7l2-5.6h9.9l2,5.6h3.7L79.1,0.2L79,0z M73.3,15.3l3.9-11l3.8,11H73.3z" />
                  <path
                    d="M54.8,0h-5.2v6.9H53V3.4h1.7c4.7,0,8.6,3.8,8.6,8.6c0,4.7-3.8,8.6-8.6,8.6h-5.2V6.9h-3.3V24h8.6c6.6,0,12-5.4,12-12
	S61.4,0,54.8,0z"
                  />
                  <g>
                    <path d="M112.3,1.4c4.3,0,6.9,2,8.8,4.5l-2.8,1.6c-1.3-1.8-3.4-3.1-6-3.1" />
                    <path
                      d="M121.6,18.6c0,2-5.4,5.4-9.3,5.4c-6.6,0-11.9-4.8-11.9-12s5.3-12,11.9-12v3.1c-4.8,0-8.4,3.7-8.4,8.9s3.6,9,8.4,9
		c2.6,0,4.7-1.2,5.8-2.3"
                    />
                    <path d="M117.1,18.7v-2.8h-4.2v-3h7.1c0.3,0,0.5,0.2,0.5,0.5v5.3" />
                  </g>
                </g>
              </svg>
            </div>
          </Link>
          <Link
            id="home"
            to="/"
            className="box-border hidden h-[105%] flex-col content-center justify-center border-b-4 border-t-4 border-solid border-transparent px-3 py-1 font-semibold transition duration-300 [text-decoration:none] hover:[border-bottom:4px_solid_#fff] lg:flex"
          >
            Madalgo Home
          </Link>
          <Link
            id="sign-up"
            to="/#premium-packages"
            className="box-border hidden h-[105%] flex-col content-center justify-center border-b-4 border-t-4 border-solid border-transparent px-3 py-1 font-semibold transition duration-300 [text-decoration:none] hover:[border-bottom:4px_solid_#fff] lg:flex"
          >
            Sign Up
          </Link>
          <Link
            id="tradingview"
            to="https://www.tradingview.com/u/madalgo/#published-scripts"
            className="box-border hidden h-[105%] flex-col content-center justify-center border-b-4 border-t-4 border-solid border-transparent px-3 py-1 font-semibold transition duration-300 [text-decoration:none] hover:[border-bottom:4px_solid_#fff] lg:flex"
          >
            TradingView
          </Link>
          <Link
            id="discord"
            to="https://discord.gg/urfhuyhTXd"
            className="box-border hidden h-[105%] flex-col content-center justify-center border-b-4 border-t-4 border-solid border-transparent px-3 py-1 font-semibold transition duration-300 [text-decoration:none] hover:[border-bottom:4px_solid_#fff] lg:flex"
          >
            Discord
          </Link>
        </div>
        <div
          id="navitems-right"
          className="flex min-w-0 flex-[0_0_auto] items-center justify-end"
        >
          <div
            id="nav-item-dropdown"
            className="group relative hidden px-3 py-1 font-semibold lg:inline-block"
          >
            <Link
              to="#"
              id="nav-link"
              className={`${styles.navLink} flex items-center`}
            >
              <span>
                <svg
                  viewBox="0 0 24 24"
                  width="20"
                  height="20"
                  aria-hidden="true"
                  className="mr-[5px] inline-block align-text-bottom"
                >
                  <path
                    fill="currentColor"
                    d="M12.87 15.07l-2.54-2.51.03-.03c1.74-1.94 2.98-4.17 3.71-6.53H17V4h-7V2H8v2H1v1.99h11.17C11.5 7.92 10.44 9.75 9 11.35 8.07 10.32 7.3 9.19 6.69 8h-2c.73 1.63 1.73 3.17 2.98 4.56l-5.09 5.02L4 19l5-5 3.11 3.11.76-2.04zM18.5 10h-2L12 22h2l1.12-3h4.75L21 22h2l-4.5-12zm-2.62 7l1.62-4.33L19.12 17h-3.24z"
                  ></path>
                </svg>
                <span>English</span>
              </span>
            </Link>
            <ul
              id="dropdown"
              className={`absolute right-[10px] top-[calc(100%_-_.25rem_+_.3rem)] z-[2] m-[0_0_1rem] max-h-[80vh] min-w-[10rem] list-none overflow-y-auto rounded-md p-2 opacity-0 shadow-lg transition-all duration-200 [left:inherit] [transform:translateY(-.625rem)] [transition-timing-function:_cubic-bezier(0.08,_.52,_0.52,_1)] [visibility:hidden] group-hover:visible group-hover:opacity-100 group-hover:[transform:translateY(0)]
              ${
                theme === "light"
                  ? "bg-white"
                  : "bg-[#0a090d] shadow-[rgba(255,255,255,0.05)]"
              }
              `}
            >
              <li>
                <Link
                  to="#"
                  target="_self"
                  rel="noopener noreferrer"
                  className={`block whitespace-nowrap rounded-[.25rem] p-[.25rem_.5rem] text-sm text-purple-500  transition-all duration-200 [text-decoration:none] [transition-timing-function:_cubic-bezier(0.08,_.52,_0.52,_1)]
                  ${
                    theme === "light"
                      ? "bg-[rgba(0,0,0,.05)] text-blue-500"
                      : " bg-[rgba(255,255,255,0.05)] text-purple-500"
                  }
                  `}
                >
                  English
                </Link>
              </li>
            </ul>
          </div>
          <div
            id="toggle-switch-container"
            onClick={toggleTheme}
            className="relative m-auto mr-4 hidden h-8 w-8 lg:flex"
          >
            <div
              id="switch-wrapper"
              className={`relative flex h-full w-full cursor-pointer select-none items-center justify-center rounded-[50%] bg-none 
              ${
                theme === "light"
                  ? "hover:bg-[#00000010]"
                  : "hover:bg-[#ffffff10]"
              }
              `}
            >
              <svg
                viewBox="0 0 24 24"
                width="24"
                height="24"
                className={`lightToggleIcon absolute ${
                  theme === "dark" ? "hidden" : ""
                }`}
              >
                <path
                  fill="#fff"
                  d="M12,9c1.65,0,3,1.35,3,3s-1.35,3-3,3s-3-1.35-3-3S10.35,9,12,9 M12,7c-2.76,0-5,2.24-5,5s2.24,5,5,5s5-2.24,5-5 S14.76,7,12,7L12,7z M2,13l2,0c0.55,0,1-0.45,1-1s-0.45-1-1-1l-2,0c-0.55,0-1,0.45-1,1S1.45,13,2,13z M20,13l2,0c0.55,0,1-0.45,1-1 s-0.45-1-1-1l-2,0c-0.55,0-1,0.45-1,1S19.45,13,20,13z M11,2v2c0,0.55,0.45,1,1,1s1-0.45,1-1V2c0-0.55-0.45-1-1-1S11,1.45,11,2z M11,20v2c0,0.55,0.45,1,1,1s1-0.45,1-1v-2c0-0.55-0.45-1-1-1C11.45,19,11,19.45,11,20z M5.99,4.58c-0.39-0.39-1.03-0.39-1.41,0 c-0.39,0.39-0.39,1.03,0,1.41l1.06,1.06c0.39,0.39,1.03,0.39,1.41,0s0.39-1.03,0-1.41L5.99,4.58z M18.36,16.95 c-0.39-0.39-1.03-0.39-1.41,0c-0.39,0.39-0.39,1.03,0,1.41l1.06,1.06c0.39,0.39,1.03,0.39,1.41,0c0.39-0.39,0.39-1.03,0-1.41 L18.36,16.95z M19.42,5.99c0.39-0.39,0.39-1.03,0-1.41c-0.39-0.39-1.03-0.39-1.41,0l-1.06,1.06c-0.39,0.39-0.39,1.03,0,1.41 s1.03,0.39,1.41,0L19.42,5.99z M7.05,18.36c0.39-0.39,0.39-1.03,0-1.41c-0.39-0.39-1.03-0.39-1.41,0l-1.06,1.06 c-0.39,0.39-0.39,1.03,0,1.41s1.03,0.39,1.41,0L7.05,18.36z"
                ></path>
              </svg>
              <svg
                viewBox="0 0 24 24"
                width="24"
                height="24"
                className={`darkToggleIcon absolute ${
                  theme === "light" ? "hidden" : ""
                }`}
              >
                <path
                  fill="#fff"
                  d="M9.37,5.51C9.19,6.15,9.1,6.82,9.1,7.5c0,4.08,3.32,7.4,7.4,7.4c0.68,0,1.35-0.09,1.99-0.27C17.45,17.19,14.93,19,12,19 c-3.86,0-7-3.14-7-7C5,9.07,6.81,6.55,9.37,5.51z M12,3c-4.97,0-9,4.03-9,9s4.03,9,9,9s9-4.03,9-9c0-0.46-0.04-0.92-0.1-1.36 c-0.98,1.37-2.58,2.26-4.4,2.26c-2.98,0-5.4-2.42-5.4-5.4c0-1.81,0.89-3.42,2.26-4.4C12.92,3.04,12.46,3,12,3L12,3z"
                ></path>
              </svg>
            </div>
          </div>
          <div id="search-bar" className="ml-1 pr-2">
            <div
              id="search-field"
              className="w-44"
              data-tags="default,docs-default-current"
            >
              <div
                id="auto"
                className={`m-0 h-10 rounded-2xl p-0 text-left text-sm font-normal leading-4 text-gray-400 
                ${theme === "light" ? "bg-white" : "bg-[#2a2a2c]"}`}
                role="combobox"
                aria-expanded="false"
                aria-haspopup="listbox"
                aria-labelledby="autocomplete-0-label"
                aria-controls="modal-content"
              >
                <div
                  id="search-button"
                  className="relative m-0 flex w-full items-center rounded-2xl border border-solid border-gray-400 p-[0_calc(44px/8)]"
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                    handleSearch(e.target.value);
                  }}
                >
                  <div
                    id="search-button-wrapper"
                    className="flex h-[2.45rem] w-10 cursor-auto items-center justify-center text-blue-400"
                  >
                    <svg
                      className="SubmitIcon"
                      viewBox="0 0 24 24"
                      width="20"
                      height="20"
                      fill="currentColor"
                    >
                      <path d="M16.041 15.856c-0.034 0.026-0.067 0.055-0.099 0.087s-0.060 0.064-0.087 0.099c-1.258 1.213-2.969 1.958-4.855 1.958-1.933 0-3.682-0.782-4.95-2.050s-2.050-3.017-2.050-4.95 0.782-3.682 2.050-4.95 3.017-2.050 4.95-2.050 3.682 0.782 4.95 2.050 2.050 3.017 2.050 4.95c0 1.886-0.745 3.597-1.959 4.856zM21.707 20.293l-3.675-3.675c1.231-1.54 1.968-3.493 1.968-5.618 0-2.485-1.008-4.736-2.636-6.364s-3.879-2.636-6.364-2.636-4.736 1.008-6.364 2.636-2.636 3.879-2.636 6.364 1.008 4.736 2.636 6.364 3.879 2.636 6.364 2.636c2.125 0 4.078-0.737 5.618-1.968l3.675 3.675c0.391 0.391 1.024 0.391 1.414 0s0.391-1.024 0-1.414z"></path>
                    </svg>
                  </div>
                  <input
                    type="text"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={onSearchChange}
                    className={`w-[inherit] flex-1 text-blue-400 caret-blue-400 outline-none placeholder:text-blue-400
                    ${theme === "light" ? "bg-white" : "bg-[#2a2a2c]"}
                    `}
                  />
                </div>
              </div>
            </div>
          </div>
          {isModalOpen && (
            <div className={`${styles.modal}`}>
              <div id="modal-content" className={`${styles.modalContent}`}>
                <button
                  className={`${styles.closeButton}`}
                  onClick={() => closeModal()}
                >
                  X
                </button>
                {filteredResults.map((result, idx) => (
                  <div key={idx}>
                    <a
                      href={`#${result.urlFragment}`}
                      className="flex items-center justify-between p-2 hover:bg-gray-200"
                      onClick={() => {
                        closeModal();
                        const newUrl = `${result.routePath}#${result.urlFragment}`;
                        navigate(newUrl);
                      }}
                    >
                      {result.displayText}{" "}
                      <span className="result-arrow">→</span>
                    </a>
                    {result.subEntries.map((subEntry, subIdx) => (
                      <a
                        key={`sub-${subIdx}`}
                        href={`#${subEntry.urlFragment}`}
                        className="sub-entry flex items-center justify-between p-2 hover:bg-gray-200"
                        onClick={() => {
                          closeModal();
                          const newUrl = `${result.routePath}#${subEntry.urlFragment}`;
                          navigate(newUrl);
                        }}
                      >
                        {subEntry.displayText}{" "}
                        <span className="result-arrow">→</span>
                      </a>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      {isVisible && (
        <div
          id="side-nav-backfilter"
          className={`fixed bottom-0 left-0 right-0 top-0 lg:opacity-0 lg:[visibility:hidden] ${
            isSideNavOpen
              ? "visible opacity-100"
              : "opacity-0 [visibility:hidden]"
          } bg-[rgba(0,0,0,.6)] transition-[opacity,visibility] duration-100 [transition-timing-function:ease-in-out]`}
        ></div>
      )}
      {isVisible && (
        <div
          id="side-nav-content"
          className={`fixed bottom-0 left-0 top-0 w-[83vw] lg:hidden ${
            isSideNavOpen
              ? "visible opacity-100 [transform:translateZ(0)]"
              : " overflow-x-hidden opacity-0 [visibility:hidden] [transform:translate3d(-100%,0,0)]"
          } ${
            theme === "light" ? "bg-slate-100" : "bg-[#111114]"
          } shadow-lg transition-[opacity,visibility,transform] duration-[250ms] [transition-timing-function:ease-in-out]`}
        >
          <div
            id="side-nav-header"
            className={`flex h-[3.75rem] items-center ${
              theme === "light" ? "bg-white" : "bg-[#2a2a2c]"
            } p-2 shadow`}
          >
            <Link
              id="side-nav-logo"
              className="mr-4 flex min-w-0 items-center text-[#1c1e21] [text-decoration:none]"
            >
              <div
                id="logo-container"
                className="mr-2 flex h-8 items-center [flex:0_0_auto]"
              >
                <SvgIcon isSideNavOpen={isSideNavOpen} theme={theme} />
                <div className="ml-2 text-black">
                  <svg
                    className="svg-header-logo transition-[fill] duration-200 [transition-timing-function:cubic-bezier(.08,.52,.52,1)]"
                    version="1.1"
                    id="madalgo"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="82"
                    height="14.1667"
                    style={{ fill: theme === "light" ? "black" : "white" }}
                    viewBox="0 0 163 24"
                    xmlSpace="preserve"
                  >
                    <g>
                      <g>
                        <path d="M39.2,12.1H28.9V3.4h-3.4v16.9h3.4v-4.8h10.3V24h3.4V3.4h-3.4V12.1z" />
                        <path d="M39.2,3.4V0H28.9v3.4H39.2z" />
                      </g>
                      <g>
                        <path d="M123.8,19.9h4V4.1h-4V19.9z" />
                        <path d="M138.9,19.9h4V4.1h-4V19.9z" />
                        <path d="M139,4.1V0h-11.3v4.1H139z" />
                        <path d="M139,24v-4.1h-11.3V24H139z" />
                      </g>
                      <path d="M0.1,24h3.4V3.4H0.1V24z" />
                      <path d="M9,10.8h3.4V2.7H9V10.8z" />
                      <path d="M18,20.5h3.4V3.4H18V20.5z" />
                      <path d="M19.1,3.5V0H2.3v3.4L19.1,3.5L19.1,3.5z" />
                      <path d="M92.5,20.5V3.2h-3.4V24h12.1v-3.5H92.5z" />
                      <path d="M79,0h-3.8l-8.7,24h3.7l2-5.6h9.9l2,5.6h3.7L79.1,0.2L79,0z M73.3,15.3l3.9-11l3.8,11H73.3z" />
                      <path
                        d="M54.8,0h-5.2v6.9H53V3.4h1.7c4.7,0,8.6,3.8,8.6,8.6c0,4.7-3.8,8.6-8.6,8.6h-5.2V6.9h-3.3V24h8.6c6.6,0,12-5.4,12-12
	S61.4,0,54.8,0z"
                      />
                      <g>
                        <path d="M112.3,1.4c4.3,0,6.9,2,8.8,4.5l-2.8,1.6c-1.3-1.8-3.4-3.1-6-3.1" />
                        <path
                          d="M121.6,18.6c0,2-5.4,5.4-9.3,5.4c-6.6,0-11.9-4.8-11.9-12s5.3-12,11.9-12v3.1c-4.8,0-8.4,3.7-8.4,8.9s3.6,9,8.4,9
		c2.6,0,4.7-1.2,5.8-2.3"
                        />
                        <path d="M117.1,18.7v-2.8h-4.2v-3h7.1c0.3,0,0.5,0.2,0.5,0.5v5.3" />
                      </g>
                    </g>
                  </svg>
                </div>
              </div>
              <b
                id="title"
                className=" overflow-hidden whitespace-nowrap [flex:1_1_auto] [text-overflow:hidden]"
              ></b>
            </Link>
            <div
              id="toggle-switch-container"
              onClick={toggleTheme}
              className="relative m-auto mr-4 h-8 w-8"
            >
              <div
                id="switch-wrapper"
                className="relative flex h-full w-full cursor-pointer select-none items-center justify-center rounded-[50%] bg-none hover:bg-[#00000010]"
              >
                <svg
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className={`lightToggleIcon absolute ${
                    theme === "dark" ? "hidden" : ""
                  }`}
                >
                  <path
                    fill="#000"
                    d="M12,9c1.65,0,3,1.35,3,3s-1.35,3-3,3s-3-1.35-3-3S10.35,9,12,9 M12,7c-2.76,0-5,2.24-5,5s2.24,5,5,5s5-2.24,5-5 S14.76,7,12,7L12,7z M2,13l2,0c0.55,0,1-0.45,1-1s-0.45-1-1-1l-2,0c-0.55,0-1,0.45-1,1S1.45,13,2,13z M20,13l2,0c0.55,0,1-0.45,1-1 s-0.45-1-1-1l-2,0c-0.55,0-1,0.45-1,1S19.45,13,20,13z M11,2v2c0,0.55,0.45,1,1,1s1-0.45,1-1V2c0-0.55-0.45-1-1-1S11,1.45,11,2z M11,20v2c0,0.55,0.45,1,1,1s1-0.45,1-1v-2c0-0.55-0.45-1-1-1C11.45,19,11,19.45,11,20z M5.99,4.58c-0.39-0.39-1.03-0.39-1.41,0 c-0.39,0.39-0.39,1.03,0,1.41l1.06,1.06c0.39,0.39,1.03,0.39,1.41,0s0.39-1.03,0-1.41L5.99,4.58z M18.36,16.95 c-0.39-0.39-1.03-0.39-1.41,0c-0.39,0.39-0.39,1.03,0,1.41l1.06,1.06c0.39,0.39,1.03,0.39,1.41,0c0.39-0.39,0.39-1.03,0-1.41 L18.36,16.95z M19.42,5.99c0.39-0.39,0.39-1.03,0-1.41c-0.39-0.39-1.03-0.39-1.41,0l-1.06,1.06c-0.39,0.39-0.39,1.03,0,1.41 s1.03,0.39,1.41,0L19.42,5.99z M7.05,18.36c0.39-0.39,0.39-1.03,0-1.41c-0.39-0.39-1.03-0.39-1.41,0l-1.06,1.06 c-0.39,0.39-0.39,1.03,0,1.41s1.03,0.39,1.41,0L7.05,18.36z"
                  ></path>
                </svg>
                <svg
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className={`darkToggleIcon absolute ${
                    theme === "light" ? "hidden" : ""
                  }`}
                >
                  <path
                    fill="#fff"
                    d="M9.37,5.51C9.19,6.15,9.1,6.82,9.1,7.5c0,4.08,3.32,7.4,7.4,7.4c0.68,0,1.35-0.09,1.99-0.27C17.45,17.19,14.93,19,12,19 c-3.86,0-7-3.14-7-7C5,9.07,6.81,6.55,9.37,5.51z M12,3c-4.97,0-9,4.03-9,9s4.03,9,9,9s9-4.03,9-9c0-0.46-0.04-0.92-0.1-1.36 c-0.98,1.37-2.58,2.26-4.4,2.26c-2.98,0-5.4-2.42-5.4-5.4c0-1.81,0.89-3.42,2.26-4.4C12.92,3.04,12.46,3,12,3L12,3z"
                  ></path>
                </svg>
              </div>
            </div>
            <button
              id="close-button"
              className="ml-auto flex cursor-pointer border-none bg-none stroke-gray-400 p-0 [color:inherit]"
              onClick={() => setSideNavOpen(!isSideNavOpen)}
            >
              <svg viewBox="0 0 15 15" width="21" height="21">
                <g strokeWidth="1.3">
                  <path d="M.75.75l13.5 13.5M14.25.75L.75 14.25"></path>
                </g>
              </svg>
            </button>
          </div>
          <div
            id="side-nav-items"
            className="flex h-[calc(100%_-_3.75rem)] transition-transform duration-200 ease-in-out"
          >
            <div
              id="list-wrapper"
              className=" w-[83vw] flex-shrink-0 overflow-x-hidden p-2 font-semibold"
            >
              <ul id="menu-list" className=" m-0 list-none pl-0">
                <li id="FAQ-link" className="group ml-[calc(.375rem*2)]">
                  <Link
                    id="faq"
                    to="/docs/faq"
                    className={`flex justify-between rounded-[.25rem] pl-0 pr-0 text-sm font-medium leading-normal transition-[background,_color] duration-200 [transition-timing-function:cubic-bezier(.08,.52,.52,1)] [word-break:break-word] group-hover:text-gray-500 ${
                      theme === "light"
                        ? "text-slate-900 group-hover:bg-[rgba(0,0,0,.05)]"
                        : "text-zinc-300 group-hover:bg-[rgba(255,255,255,0.05)]"
                    } ${faqClassName}`}
                  >
                    <span className="m-[3px_8px] ml-2">❓ FAQ</span>
                  </Link>
                </li>
                {menuItems.map((item, index) => {
                  const isCurrentMainItem = currentPath === item.link;
                  const mainItemClassName = isCurrentMainItem
                    ? "bg-[rgba(164,159,180,.15)] [color:rgb(168_85_247)]"
                    : "";
                  return (
                    <li
                      key={index}
                      id={`item-category-${index}`}
                      className="mt-1"
                    >
                      <div
                        id="item-collapsible"
                        className="relative flex flex-wrap rounded-[.25rem] transition-[background,_color] duration-200 [transition-timing-function:cubic-bezier(.08,.52,.52,1)]"
                      >
                        {index === 0 ||
                        index === 1 ||
                        index === menuItems.length - 1 ? (
                          <div
                            className={`mt-4 flex flex-1 cursor-default justify-between rounded-[.25rem] py-[.375rem] pl-6 pr-4 text-xs font-bold uppercase leading-tight tracking-[1.2px]  transition-[background,_color] duration-200 [text-decoration:none] [transition-timing-function:cubic-bezier(.08,.52,.52,1)] ${
                              theme === "light"
                                ? "text-slate-500"
                                : " text-zinc-100"
                            }`}
                          >
                            <span>
                              {item.icon} {item.label}
                            </span>
                          </div>
                        ) : (
                          <Link
                            to={item.link}
                            id="menu-link"
                            onClick={() => {
                              setSelectedItem({
                                main: index,
                                sub: null,
                                dropdown: null,
                              });
                            }}
                            className={`mt-4 flex flex-1 cursor-pointer justify-between rounded-[.25rem] py-[.375rem] pl-6 pr-4 text-xs font-bold uppercase leading-tight tracking-[1.2px] transition-[background,_color] duration-200 [text-decoration:none] [transition-timing-function:cubic-bezier(.08,.52,.52,1)] hover:text-gray-500 ${
                              theme === "light"
                                ? "text-slate-900 hover:bg-[rgba(0,0,0,.05)]"
                                : " text-zinc-300 hover:bg-[rgba(255,255,255,0.05)]"
                            } ${mainItemClassName}`}
                          >
                            <span>
                              {item.icon} {item.label}
                            </span>
                          </Link>
                        )}
                      </div>
                      <ul
                        id="inner-list"
                        className="m-0 mt-1 list-none pl-4"
                        style={{
                          display: "block",
                          overflow: "visible",
                          height: "auto",
                        }}
                      >
                        {item.subItems.map((subItem, subIndex) => {
                          const isSubItemSelected =
                            (selectedItem.main === index &&
                              selectedItem.sub === subIndex) ||
                            (selectedItem.main === index &&
                              selectedItem.sub === subIndex &&
                              selectedItem.dropdown !== null);

                          const isDropdownItemSelected =
                            selectedItem.main === index &&
                            selectedItem.sub === subIndex &&
                            selectedItem.dropdown !== null;

                          const isCurrentSubItem = currentPath === subItem.link;

                          const subItemClassName = `${getLinkClass(
                            openDropdown,
                            subIndex,
                            theme,
                          )} 
                        ${
                          (!isCurrentMainItem &&
                            !isFaqPage &&
                            isCurrentSubItem) ||
                          (!isCurrentMainItem &&
                            !isFaqPage &&
                            selectedItem.main === index &&
                            selectedItem.sub === subIndex &&
                            selectedItem.dropdown === null &&
                            selectedItem.sub !== null)
                            ? "bg-[rgba(164,159,180,.15)] [color:rgb(168_85_247)]"
                            : ""
                        } 
                        ${
                          !isCurrentMainItem &&
                          !isFaqPage &&
                          isSubItemSelected &&
                          selectedItem.sub !== null
                            ? "[color:rgb(168_85_247)]"
                            : ""
                        }`;

                          return (
                            <li
                              key={subIndex}
                              id={`item-link-${index}`}
                              className="mt-1"
                            >
                              <Link
                                to={subItem.link}
                                onClick={(e) => {
                                  if (e.target.closest("svg")) {
                                    e.preventDefault();
                                  } else {
                                    setSelectedItem({
                                      main: index,
                                      sub: subIndex,
                                      dropdown: null,
                                    });
                                  }
                                }}
                                className={subItemClassName}
                              >
                                <span className="ml-2">{subItem.text}</span>
                                {subItem.dropdown && (
                                  <span
                                    onClick={(e) => {
                                      if (!isDropdownItemSelected) {
                                        e.stopPropagation();
                                      }
                                      e.preventDefault();
                                      if (openDropdown === subIndex) {
                                        setOpenDropdown(null);
                                      } else {
                                        setOpenDropdown(subIndex);
                                      }
                                    }}
                                  >
                                    <svg
                                      className={`transform rounded-lg bg-transparent transition-transform duration-300 ${
                                        openDropdown === subIndex
                                          ? "rotate-180"
                                          : "rotate-90"
                                      } ${
                                        theme === "light"
                                          ? "hover:bg-[rgba(0,0,0,.05)]"
                                          : "hover:bg-[rgba(255,255,255,0.05)]"
                                      }`}
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24px"
                                      height="24px"
                                      viewBox="0 0 24 24"
                                    >
                                      <path
                                        fill={`${
                                          theme === "light"
                                            ? "rgba(0,0,0,0.5)"
                                            : "rgba(255,255,255,0.5)"
                                        }`}
                                        d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z"
                                      ></path>
                                    </svg>
                                  </span>
                                )}
                              </Link>
                              {subItem.dropdown && (
                                <ul
                                  id="dropdown-list"
                                  className=" m-0 mt-1 list-none pl-4"
                                  style={{
                                    display: "block",
                                    overflow: "hidden",
                                    maxHeight:
                                      openDropdown === subIndex ? "300px" : "0",
                                    transition: "max-height 292ms ease-in-out",
                                  }}
                                >
                                  {subItem.dropdown.map(
                                    (dropdownItem, dropdownIndex) => {
                                      const isCurrentDropdownItem =
                                        currentPath === dropdownItem.link;

                                      const dropdownItemClassName = `${getLinkClass(
                                        openDropdown,
                                        subIndex,
                                        theme,
                                      )} 
                                    ${
                                      (!isCurrentMainItem &&
                                        !isFaqPage &&
                                        isCurrentDropdownItem) ||
                                      (!isCurrentMainItem &&
                                        !isFaqPage &&
                                        selectedItem.main === index &&
                                        selectedItem.sub === subIndex &&
                                        selectedItem.dropdown === dropdownIndex)
                                        ? "bg-[rgba(164,159,180,.15)] [color:rgb(168_85_247)]"
                                        : ""
                                    }`;

                                      return (
                                        <li key={dropdownIndex}>
                                          <Link
                                            to={dropdownItem.link}
                                            onClick={() =>
                                              setSelectedItem({
                                                main: index,
                                                sub: subIndex,
                                                dropdown: dropdownIndex,
                                              })
                                            }
                                            className={dropdownItemClassName}
                                          >
                                            <span className="ml-2">
                                              {dropdownItem.text}
                                            </span>
                                          </Link>
                                        </li>
                                      );
                                    },
                                  )}
                                </ul>
                              )}
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      )}
    </nav>
  );
}
