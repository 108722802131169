import { entries as getStartedEntries } from "./doc-pages/DocsGetStarted";
import { entries as updatingMADALGOEntries } from "./doc-pages/DocsUpdatingMadalgo";
import { entries as tradingViewEntries } from "./doc-pages/DocsTradingView";
import { entries as joinDiscordEntries } from "./doc-pages/DocsJoinDiscord";
import { entries as settingAlertsEntries } from "./doc-pages/DocsSettingAlerts";
import { entries as pulseChangelogEntries } from "./doc-pages/PulseChangelog";
import { entries as dynamicsChangelogEntries } from "./doc-pages/DynamicsChangelog";
import { entries as navigatorChangelogEntries } from "./doc-pages/NavigatorChangelog";
import { entries as technicalAnalysisChartsEntries } from "./doc-pages/DocsTechnicalAnalysisCharts";
import { entries as technicalAnalysisTrendsEntries } from "./doc-pages/DocsTechnicalAnalysisTrends";
import { entries as technicalAnalysisSREntries } from "./doc-pages/DocsTechnicalAnalysisSupportResistance";
import { entries as technicalAnalysisTechnicalIndicatorsEntries } from "./doc-pages/DocsTechnicalAnalysisTechnicalIndicators";
import { entries as technicalAnalysisTechnicalIndicatorsMomentumEntries } from "./doc-pages/DocsTechnicalAnalysisTechnicalIndicatorsMomentum";
import { entries as technicalAnalysisTechnicalIndicatorsTrendEntries } from "./doc-pages/DocsTechnicalAnalysisTechnicalIndicatorsTrend";
import { entries as technicalAnalysisTechnicalIndicatorsVolatilityEntries } from "./doc-pages/DocsTechnicalAnalysisTechnicalIndicatorsVolatility";
import { entries as technicalAnalysisTechnicalIndicatorsVolumeEntries } from "./doc-pages/DocsTechnicalAnalysisTechnicalIndicatorsVolume";
import { entries as tradingBestPracticesTraderProfileEntries } from "./doc-pages/DocsTradingBestPracticesTraderProfile";
import { entries as tradingBestPracticesDiversificationEntries } from "./doc-pages/DocsTradingBestPracticesDiversification";
import { entries as dosDontsEntries } from "./doc-pages/DocsDosDonts";
import { entries as tradingBestPracticesMoneyManagementEntries } from "./doc-pages/DocsTradingBestPracticesMoneyManagement";
import { entries as tradingBestPracticesEmotionalDisciplineEntries } from "./doc-pages/DocsTradingBestPracticesEmotionalDiscipline";
import { entries as recommendedBrokersEntries } from "./doc-pages/DocsRecommendedBrokers";
import { entries as madalgoPulseEntries } from "./doc-pages/DocsMADALGOPulse";
import { entries as madalgoPulseProfitBandsEntries } from "./doc-pages/DocsMADALGOPulseProfitBands";
import { entries as madalgoPulseVolumeWeightedStrengthIndexEntries } from "./doc-pages/DocsMADALGOPulseVolumeWeightedStrengthIndex";
import { entries as madalgoPulseHyperTrendEntries } from "./doc-pages/DocsMADALGOPulseHyperTrend";
import { entries as madalgoPulseTrendAlertCandlesEntries } from "./doc-pages/DocsMADALGOPulseTrendAlertCandles";
import { entries as madalgoPulseVolumeProfileProEntries } from "./doc-pages/DocsMADALGOPulseVolumeProfilePro";
import { entries as madalgoNavigatorEntries } from "./doc-pages/DocsMADALGONavigator";
import { entries as madalgoNavigatorILZEntries } from "./doc-pages/DocsMADALGONavigatorInstitutionalLiquidutyZones";
import { entries as faqEntries } from "./doc-pages/DocsFAQ";
import { entries as madalgoNavigatorDivergenceDetectorEntries } from "./doc-pages/DocsMADALGONavigatorDivergenceDetector";
import { entries as madalgoNavigatorTargetScopeEntries } from "./doc-pages/DocsMADALGONavigatorTargetScope";
import { entries as madalgoNavigatorTrendShiftCloudsEntries } from "./doc-pages/DocsMADALGONavigatorTrendShiftClouds";
import { entries as madalgoNavigatorTrueRangeFVGEntries } from "./doc-pages/DocsMADALGONavigatorTrueRangeFVG";
import { entries as madalgoDynamicsEntries } from "./doc-pages/DocsMADALGODynamics";
import { entries as madalgoDynamicsIMFEntries } from "./doc-pages/DocsMADALGODynamicsInstitutionalMoneyFlow";
import { entries as madalgoDynamicsMomentumRangeSelectorEntries } from "./doc-pages/DocsMADALGODynamicsMomentumRangeSelector";
import { entries as madalgoDynamicsPersistentTrendIndex } from "./doc-pages/DocsMADALGODynamicsPersistentTrendIndex";
import { entries as madalgoDynamicsStochastiXEntries } from "./doc-pages/DocsMADALGODynamicsStochastiX";
import { entries as madalgoDynamicsWeightedPulseEntries } from "./doc-pages/DocsMADALGODynamicsWeightedPulse";

const allEntries = [
  ...getStartedEntries.map((entry) => ({
    ...entry,
    routePath: "get-started/what-is-madalgo",
  })),
  ...updatingMADALGOEntries.map((entry) => ({
    ...entry,
    routePath: "madalgo-premium-solutions/updating-madalgo",
  })),
  ...tradingViewEntries.map((entry) => ({
    ...entry,
    routePath: "get-started/what-is-tradingview",
  })),
  ...joinDiscordEntries.map((entry) => ({
    ...entry,
    routePath: "get-started/join-madalgo-discord",
  })),
  ...settingAlertsEntries.map((entry) => ({
    ...entry,
    routePath: "madalgo-premium-solutions/setting-alerts",
  })),
  ...pulseChangelogEntries.map((entry) => ({
    ...entry,
    routePath: "madalgo-premium-solutions/changelogs/madalgo-premium-pulse",
  })),
  ...dynamicsChangelogEntries.map((entry) => ({
    ...entry,
    routePath: "madalgo-premium-solutions/changelogs/madalgo-premium-dynamics",
  })),
  ...navigatorChangelogEntries.map((entry) => ({
    ...entry,
    routePath: "madalgo-premium-solutions/changelogs/madalgo-premium-navigator",
  })),
  ...technicalAnalysisChartsEntries.map((entry) => ({
    ...entry,
    routePath: "technical-analysis/charts",
  })),
  ...technicalAnalysisTrendsEntries.map((entry) => ({
    ...entry,
    routePath: "technical-analysis/trends",
  })),
  ...technicalAnalysisSREntries.map((entry) => ({
    ...entry,
    routePath: "technical-analysis/support-resistance",
  })),
  ...technicalAnalysisTechnicalIndicatorsEntries.map((entry) => ({
    ...entry,
    routePath: "technical-analysis/technical-indicators",
  })),
  ...technicalAnalysisTechnicalIndicatorsMomentumEntries.map((entry) => ({
    ...entry,
    routePath: "technical-analysis/technical-indicators/momentum-indicators",
  })),
  ...technicalAnalysisTechnicalIndicatorsTrendEntries.map((entry) => ({
    ...entry,
    routePath: "technical-analysis/technical-indicators/trend-indicators",
  })),
  ...technicalAnalysisTechnicalIndicatorsVolatilityEntries.map((entry) => ({
    ...entry,
    routePath: "technical-analysis/technical-indicators/volatility-indicators",
  })),
  ...technicalAnalysisTechnicalIndicatorsVolumeEntries.map((entry) => ({
    ...entry,
    routePath: "technical-analysis/technical-indicators/volume-indicators",
  })),
  ...tradingBestPracticesTraderProfileEntries.map((entry) => ({
    ...entry,
    routePath: "best-practices/trader-profile",
  })),
  ...tradingBestPracticesDiversificationEntries.map((entry) => ({
    ...entry,
    routePath: "best-practices/diversification",
  })),
  ...dosDontsEntries.map((entry) => ({
    ...entry,
    routePath: "best-practices/dos-and-donts",
  })),
  ...tradingBestPracticesMoneyManagementEntries.map((entry) => ({
    ...entry,
    routePath: "best-practices/money-management",
  })),
  ...tradingBestPracticesEmotionalDisciplineEntries.map((entry) => ({
    ...entry,
    routePath: "best-practices/emotional-discipline",
  })),
  ...recommendedBrokersEntries.map((entry) => ({
    ...entry,
    routePath: "best-practices/recommended-brokers",
  })),
  ...madalgoPulseEntries.map((entry) => ({
    ...entry,
    routePath: "madalgo-premium-solutions/madalgo-pulse",
  })),
  ...madalgoPulseProfitBandsEntries.map((entry) => ({
    ...entry,
    routePath: "madalgo-premium-solutions/madalgo-pulse/profit-bands",
  })),
  ...madalgoPulseVolumeWeightedStrengthIndexEntries.map((entry) => ({
    ...entry,
    routePath:
      "madalgo-premium-solutions/madalgo-pulse/volume-weighted-strength-index",
  })),
  ...madalgoPulseHyperTrendEntries.map((entry) => ({
    ...entry,
    routePath: "madalgo-premium-solutions/madalgo-pulse/hyper-trend",
  })),
  ...madalgoPulseTrendAlertCandlesEntries.map((entry) => ({
    ...entry,
    routePath: "madalgo-premium-solutions/madalgo-pulse/trend-alert-candles",
  })),
  ...madalgoPulseVolumeProfileProEntries.map((entry) => ({
    ...entry,
    routePath: "madalgo-premium-solutions/madalgo-pulse/volume-profile-pro",
  })),
  ...madalgoNavigatorEntries.map((entry) => ({
    ...entry,
    routePath: "madalgo-premium-solutions/madalgo-navigator",
  })),
  ...madalgoNavigatorILZEntries.map((entry) => ({
    ...entry,
    routePath:
      "madalgo-premium-solutions/madalgo-navigator/institutional-liquidity-zones",
  })),
  ...faqEntries.map((entry) => ({
    ...entry,
    routePath: "faq",
  })),
  ...madalgoNavigatorDivergenceDetectorEntries.map((entry) => ({
    ...entry,
    routePath:
      "madalgo-premium-solutions/madalgo-navigator/divergence-detector",
  })),
  ...madalgoNavigatorTargetScopeEntries.map((entry) => ({
    ...entry,
    routePath: "madalgo-premium-solutions/madalgo-navigator/target-scope",
  })),
  ...madalgoNavigatorTrendShiftCloudsEntries.map((entry) => ({
    ...entry,
    routePath: "madalgo-premium-solutions/madalgo-navigator/trendshift-clouds",
  })),
  ...madalgoNavigatorTrueRangeFVGEntries.map((entry) => ({
    ...entry,
    routePath: "madalgo-premium-solutions/madalgo-navigator/true-range-fvg",
  })),
  ...madalgoDynamicsEntries.map((entry) => ({
    ...entry,
    routePath: "madalgo-premium-solutions/madalgo-dynamics",
  })),
  ...madalgoDynamicsIMFEntries.map((entry) => ({
    ...entry,
    routePath:
      "madalgo-premium-solutions/madalgo-dynamics/institutional-money-flow",
  })),
  ...madalgoDynamicsMomentumRangeSelectorEntries.map((entry) => ({
    ...entry,
    routePath:
      "madalgo-premium-solutions/madalgo-dynamics/momentum-range-selector",
  })),
  ...madalgoDynamicsPersistentTrendIndex.map((entry) => ({
    ...entry,
    routePath:
      "madalgo-premium-solutions/madalgo-dynamics/persistent-trend-index",
  })),
  ...madalgoDynamicsStochastiXEntries.map((entry) => ({
    ...entry,
    routePath: "madalgo-premium-solutions/madalgo-dynamics/stochastix",
  })),
  ...madalgoDynamicsWeightedPulseEntries.map((entry) => ({
    ...entry,
    routePath: "madalgo-premium-solutions/madalgo-dynamics/weighted-pulse",
  })),
];

export { allEntries };
