import { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import styles from "../Docs.module.css";
import { useTOC } from "../TOCContext";
import ThemeContext from "../ThemeContext";

const entries = [
  {
    displayText: "Introduction",
    urlFragment: "introduction",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "Leading vs. Lagging Technical Indicators",
    urlFragment: "leading-vs-lagging-technical-indicators",
    headingType: "h2",
    subEntries: [
      {
        displayText: "Leading Indicators",
        urlFragment: "leading-indicators",
        headingType: "h3",
      },
      {
        displayText: "Lagging Indicators",
        urlFragment: "lagging-indicators",
        headingType: "h3",
      },
    ],
  },
  {
    displayText: "Categories of Technical Indicators",
    urlFragment: "categories-of-technical-indicators",
    headingType: "h2",
    subEntries: [],
  },
];
export { entries };

export default function DocsTechnicalAnalysisTechnicalIndicators() {
  const { addToTOC, removeFromTOC } = useTOC();
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    entries.forEach(addToTOC);
    return () => {
      entries.forEach((entry) => removeFromTOC(entry));
    };
  }, [addToTOC, removeFromTOC]);

  return (
    <>
      <h1 className={styles.h1}>Technical Indicators</h1>
      <h2 id="introduction" className={`scroll-mt-2 ${styles["h2"]}`}>
        Introduction
        <Link
          to="#introduction"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>

      <p className={styles.p}>
        Technical indicators offer a glimpse into the market's possible future
        direction by analyzing mathematical computations based on historical
        price and/or volume data. These tools can be visualized as rolling
        statistical models, churning out insights from a set data window.
      </p>
      <p className={styles.p}>
        Traders employ these indicators as decision-making tools, guiding them
        when to initiate or exit a trade.
      </p>
      <h2
        id="leading-vs-lagging-technical-indicators"
        className={`scroll-mt-2 ${styles["h2"]}`}
      >
        Leading vs. Lagging Technical Indicators
        <Link
          to="#leading-vs-lagging-technical-indicators"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        Technical indicators primarily fall into two categories based on their
        predictive nature: leading and lagging indicators.
      </p>
      <h3 id="leading-indicators" className={`${styles["h3"]}`}>
        Leading Indicators
        <Link
          to="#leading-indicators"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        Leading indicators aim to forecast potential price changes ahead of
        time. Their predictive nature makes them valuable, but traders must
        exercise caution. While they hint at potential future price movements,
        they don't guarantee them, occasionally leading to misguided trades.
      </p>
      <h3 id="lagging-indicators" className={`${styles["h3"]}`}>
        Lagging Indicators
        <Link
          to="#lagging-indicators"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        Contrarily, lagging indicators provide insights based on past market
        data. Their strength lies in confirming price movements or trends.
        Although they offer a more accurate reflection of past market behavior,
        their delayed nature can sometimes cause traders to miss timely
        opportunities.
      </p>
      <h2
        id="categories-of-technical-indicators"
        className={`scroll-mt-2 ${styles["h2"]}`}
      >
        Categories of Technical Indicators
        <Link
          to="#categories-of-technical-indicators"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        Technical indicators can be classified based on the kind of data they
        analyze and the insights they provide. Four primary categories of
        technical indicators exist, each detailed below:
      </p>
      <Link
        to="momentum-indicators"
        className={`
          ${theme === "light" ? styles.changelogLink : styles.changelogLinkDark}
        `}
      >
        <span
          style={{
            userSelect: "none",
            marginRight: 10,
            marginBottom: 3,
          }}
        >
          »
        </span>
        Momentum Indicators
      </Link>
      <Link
        to="trend-indicators"
        className={`
          ${theme === "light" ? styles.changelogLink : styles.changelogLinkDark}
        `}
      >
        <span
          style={{
            userSelect: "none",
            marginRight: 10,
            marginBottom: 3,
          }}
        >
          »
        </span>
        Trend Indicators
      </Link>
      <Link
        to="volatility-indicators"
        className={`
          ${theme === "light" ? styles.changelogLink : styles.changelogLinkDark}
        `}
      >
        <span
          style={{
            userSelect: "none",
            marginRight: 10,
            marginBottom: 3,
          }}
        >
          »
        </span>
        Volatility Indicators
      </Link>
      <Link
        to="volume-indicators"
        className={`
          ${theme === "light" ? styles.changelogLink : styles.changelogLinkDark}
        `}
      >
        <span
          style={{
            userSelect: "none",
            marginRight: 10,
            marginBottom: 3,
          }}
        >
          »
        </span>
        Volume Indicators
      </Link>
    </>
  );
}
