import React, { useEffect, useRef } from "react";

function VideoComponent(props) {
  const videoRef = useRef(null);

  useEffect(() => {
    // Ensure the browser supports IntersectionObserver
    if ("IntersectionObserver" in window) {
      const options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      };

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0) {
            // Lazy loading implementation
            const videoSources = videoRef.current.querySelectorAll("source");
            let newSourceSet = false;
            videoSources.forEach((source) => {
              if (!source.src) {
                source.src = source.getAttribute("data-src");
                newSourceSet = true;
              }
            });

            if (newSourceSet) {
              videoRef.current.load();
              setTimeout(() => {
                if (window.innerWidth >= 768) {
                  videoRef.current.play();
                }
              }, 1000); // delay of 1 second, adjust as necessary
            } else {
              if (window.innerWidth >= 768) {
                videoRef.current.play();
              }
            }
          } else {
            videoRef.current.pause();
          }
        });
      }, options);

      observer.observe(videoRef.current);

      // Cleanup observer on unmount
      return () => observer.disconnect();
    }
  }, []);

  return (
    <video ref={videoRef} loop muted {...props}>
      {props.children}
    </video>
  );
}

export default VideoComponent;
