import { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import styles from "../Docs.module.css";
import { useTOC } from "../TOCContext";
import DocImages from "../DocImages";
import ThemeContext from "../ThemeContext";

const entries = [
  {
    displayText: "Activation and Customization",
    urlFragment: "activation-and-customization",
    headingType: "h2",
    subEntries: [
      {
        displayText: "Activation",
        urlFragment: "activation",
        headingType: "ol",
      },
      {
        displayText: "Customization",
        urlFragment: "customization",
        headingType: "ol",
      },
    ],
  },
  {
    displayText: "Calculation and Interpretation",
    urlFragment: "calculation-and-interpretation",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "Integration with Daily Gaps Detection",
    urlFragment: "integration-with-daily-gaps-detection",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "Practical Application",
    urlFragment: "practical-application",
    headingType: "h2",
    subEntries: [
      {
        displayText: "Identifying Significant Gaps",
        urlFragment: "identifying-significant-gaps",
        headingType: "h3",
      },
      {
        displayText: "Confirmation of Gap Continuation",
        urlFragment: "confirmation-of-gap-continuation",
        headingType: "h3",
      },
    ],
  },
];
export { entries };

export default function DocsMADALGONavigatorTrueRangeFVG() {
  const { theme } = useContext(ThemeContext);
  const { addToTOC, removeFromTOC } = useTOC();

  useEffect(() => {
    entries.forEach(addToTOC);
    return () => {
      entries.forEach((entry) => removeFromTOC(entry));
    };
  }, [addToTOC, removeFromTOC]);

  return (
    <>
      <h1 className={styles.h1}>True Range Fair Value Gaps (TR FVG)</h1>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[150]}
          width={735}
          height={447}
          className="mx-auto"
          alt="True Range FVG"
        ></img>
      </p>
      <p className={styles.p}>
        The "True Range Fair Value Gaps" (TR FVG) feature within MADALGO
        Navigator is a powerful tool designed to help traders identify
        significant fair value gaps that hold potential value in the market. By
        analyzing price movements and volatility, TR FVG assists in recognizing
        fair value gaps and their subsequent price movements.
      </p>

      <h2
        id="activation-and-customization"
        className={`scroll-mt-2 ${styles["h2"]}`}
      >
        Activation and Customization
        <Link
          to="#activation-and-customization"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <ol id="activation" className={styles.olBullet}>
        <li>Activation</li>
      </ol>
      <p className={styles.p}>
        To activate the True Range Fair Value Gaps (TR FVG) feature:
      </p>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[151]}
          width={537}
          height={527}
          className="mx-auto"
          alt="True Range FVG Settings"
        ></img>
      </p>
      <ol className={styles.ol}>
        <li>Access the "Main Settings" of MADALGO Navigator.</li>
        <li>Locate the "TR FVG" switch and toggle it to the ON position.</li>
      </ol>

      <ol id="customization" className={styles.olBullet}>
        <li>Customization</li>
      </ol>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[152]}
          width={261}
          height={208}
          className="mx-auto"
          alt="True Range FVG Customization"
        ></img>
      </p>
      <p className={styles.p}>
        Tailor the TR FVG feature to your preferences with the following
        settings:
      </p>
      <ol className={styles.olBullet}>
        <li>
          Up Candle Box Color: Choose the color for the fair value gap box that
          corresponds to an upward candle movement.
        </li>
        <li>
          Down Candle Box Color: Select the color for the fair value gap box
          that corresponds to a downward candle movement.
        </li>
        <li>
          Midline: Enable or disable the midline within the fair value gap box.
        </li>
        <li>
          TR Style: Choose from different styles (A, B, C, D). Style D uses
          default values.
        </li>
      </ol>

      <div
        className={`
        info info-tip selection:bg-[#c4f6ff] selection:text-[#2c2c2c49]
        ${theme === "light" ? styles.infoTipInfo : styles.infoTipInfoDark} 
        `}
      >
        <div className="info-heading">
          <h5 className={styles.h5}>
            <span
              className={`
                ${theme === "light" ? styles.infoTip : styles.infoTipDark}
              `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 12 16"
              >
                <path
                  fillRule="evenodd"
                  d="M6.5 0C3.48 0 1 2.19 1 5c0 .92.55 2.25 1 3 1.34 2.25 1.78 2.78 2 4v1h5v-1c.22-1.22.66-1.75 2-4 .45-.75 1-2.08 1-3 0-2.81-2.48-5-5.5-5zm3.64 7.48c-.25.44-.47.8-.67 1.11-.86 1.41-1.25 2.06-1.45 3.23-.02.05-.02.11-.02.17H5c0-.06 0-.13-.02-.17-.2-1.17-.59-1.83-1.45-3.23-.2-.31-.42-.67-.67-1.11C2.44 6.78 2 5.65 2 5c0-2.2 2.02-4 4.5-4 1.22 0 2.36.42 3.22 1.19C10.55 2.94 11 3.94 11 5c0 .66-.44 1.78-.86 2.48zM4 14h5c-.23 1.14-1.3 2-2.5 2s-2.27-.86-2.5-2z"
                ></path>
              </svg>
            </span>
            Tip
          </h5>
        </div>
        <div className="warning-content">
          <p>
            Incorporate TR FVG across multiple timeframes to gain a broader
            perspective on fair value gaps and their potential impacts on price
            trends.
          </p>
        </div>
      </div>

      <h2
        id="calculation-and-interpretation"
        className={`scroll-mt-2 ${styles["h2"]}`}
      >
        Calculation and Interpretation
        <Link
          to="#calculation-and-interpretation"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <figure className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[153]}
          width={666}
          height={433}
          className="mx-auto"
          alt="True Range FVG Interpretation"
        ></img>
        <figcaption
          style={{
            textAlign: "center",
            fontSize: 12,
          }}
        >
          Daily BTC
        </figcaption>
      </figure>
      <p className={styles.p}>
        The TR FVG feature calculates and identifies fair value gaps based on a
        set of criteria, including previous candle patterns, volatility
        conditions, and the current candle's relationship with the gaps. When a
        fair value gap meets the specified conditions, a colored box is plotted
        on the chart, providing visual context for the detected gap.
      </p>

      <h2
        id="integration-with-daily-gaps-detection"
        className={`scroll-mt-2 ${styles["h2"]}`}
      >
        Integration with Daily Gaps Detection
        <Link
          to="#integration-with-daily-gaps-detection"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        In a future update, the TR FVG feature will incorporate the ability to
        detect daily gaps in stocks. This enhancement will further expand the
        capability of the TR FVG feature, enabling traders to identify both
        intraday and daily gaps that may impact price movements.
      </p>
      <div
        className={`
        info info-tip selection:bg-[#c4f6ff] selection:text-[#2c2c2c49]
        ${theme === "light" ? styles.infoWarning : styles.infoWarningDark} 
        `}
      >
        <div className="info-heading">
          <h5 className={styles.h5}>
            <span
              className={`
                ${theme === "light" ? styles.infoAlert : styles.infoAlertDark}
              `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 12 16"
              >
                <path
                  fillRule="evenodd"
                  d="M7 2.3c3.14 0 5.7 2.56 5.7 5.7s-2.56 5.7-5.7 5.7A5.71 5.71 0 0 1 1.3 8c0-3.14 2.56-5.7 5.7-5.7zM7 1C3.14 1 0 4.14 0 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm1 3H6v5h2V4zm0 6H6v2h2v-2z"
                ></path>
              </svg>
            </span>
            Info
          </h5>
        </div>
        <div className="warning-content">
          <p>
            Combine the insights from TR FVG with other technical indicators to
            enhance your trading strategy's accuracy and effectiveness.
          </p>
        </div>
      </div>

      <h2 id="practical-application" className={`scroll-mt-2 ${styles["h2"]}`}>
        Practical Application
        <Link
          to="#practical-application"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <h3 id="identifying-significant-gaps" className={`${styles["h3"]}`}>
        Identifying Significant Gaps
        <Link
          to="#identifying-significant-gaps"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[154]}
          width={577}
          height={445}
          className="mx-auto"
          alt="True Range FVG Identifying Gaps"
        ></img>
      </p>
      <p className={styles.p}>
        The TR FVG feature helps traders recognize fair value gaps that possess
        significant value, indicating potential price movement opportunities. It
        provides an intuitive visual representation of these gaps on the chart.
      </p>

      <h3 id="confirmation-of-gap-continuation" className={`${styles["h3"]}`}>
        Confirmation of Gap Continuation
        <Link
          to="#confirmation-of-gap-continuation"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h3>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[155]}
          width={247}
          height={308}
          className="mx-auto"
          alt="True Range FVG Confirmation of Gap Continuation"
        ></img>
      </p>
      <p className={styles.p}>
        As part of your trading strategy, the TR FVG feature can serve as a
        confirmation tool for gap continuation. By assessing the presence of
        fair value gaps alongside other technical indicators, you can make more
        informed trading decisions.
      </p>

      <div
        className={`warning warning-caution selection:bg-[#ffeec4] selection:text-[#4b4b4b] ${styles["alertWarning"]}`}
      >
        <div className="warning-heading">
          <h5 className={styles.h5}>
            <span className={styles.alertWarningIcon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M8.893 1.5c-.183-.31-.52-.5-.887-.5s-.703.19-.886.5L.138 13.499a.98.98 0 0 0 0 1.001c.193.31.53.501.886.501h13.964c.367 0 .704-.19.877-.5a1.03 1.03 0 0 0 .01-1.002L8.893 1.5zm.133 11.497H6.987v-2.003h2.039v2.003zm0-3.004H6.987V5.987h2.039v4.006z"
                ></path>
              </svg>
            </span>
            caution
          </h5>
        </div>
        <div className="warning-content">
          <p>
            While TR FVG offers valuable insights, remember to complement its
            observations with other analysis methods and indicators to ensure
            well-rounded trading decisions.
          </p>
          <p>
            <em>
              Disclaimer: Please note that the information provided is intended
              for educational purposes only and should not be construed as
              financial advice. Trading involves risks, and it's essential to
              conduct thorough research and seek guidance from financial
              professionals before making trading decisions.
            </em>
          </p>
        </div>
      </div>
    </>
  );
}
