const TransitionGlitch = ({ isClicked }) => {
  return (
    <div
      className="fixed bottom-0 left-0 right-0 top-0 z-[45] opacity-[.65]"
      style={{ display: isClicked ? "block" : "none" }}
    >
      <img
        width="800"
        height="600"
        src="https://wdikc.wpenginepowered.com/wp-content/uploads/2018/04/Comp_4.gif"
        alt="transition-glitch"
        style={{ opacity: 0.3 }}
        className="lazyloaded h-full w-full max-w-full"
        data-ll-status="loaded"
      />
    </div>
  );
};

export default TransitionGlitch;
