import { useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import styles from "./Main.module.css";
import { SvgIcon } from "./BrandSVG";

const FaqQuestions = [
  "What is MADALGO?",
  "How does MADALGO work?",
  "Do you offer a free trial?",
  "What are the pricing options?",
  "Can beginners use MADALGO?",
  "Which markets can I use it for?",
  "What support do you offer?",
  "Will this win me every trade?",
];

const FaqAnswers = [
  "MADALGO is an AI-driven market data algorithm indicator suite that provides real-time insights for informed trading decisions. With over 22 features, it aims to provide an edge to traders across stocks, crypto, forex, futures, and commodities.",
  "MADALGO uses advanced AI algorithms to analyze market data and provide trading signals. The tool can be integrated with your TradingView charts and will send alerts based on the criteria you set.",
  "Yes, we offer a free 14-day trial to all new subscribers. You can use this period to explore all the features of MADALGO and see if it fits your trading needs.",
  "We offer A simplified pricing model which consists of one price that comes with all features: Premium - $29.99/month, $299.99/year, and $499.99/lifetime.",
  "Absolutely! MADALGO is designed to be user-friendly and accessible to traders of all experience levels. We even provide a comprehensive docs to help you understand how each feature works.",
  "You can use MADALGO on any market chart available on TradingView. This includes stocks, crypto, forex, futures, and commodities. However, there may be limitations for some less frequently viewed or abnormal charts.",
  "We provide 24/7 customer support inside our Discord community. We're also working on integrating a live chat support desk for our main web app to ensure you get help when you need it.",
  "While MADALGO uses sophisticated AI to provide trading insights, it is important to remember that no system can guarantee 100% successful trades due to the unpredictable nature of markets. We recommend using MADALGO as a tool in your trading strategy rather than relying on it exclusively.",
];

const FaqItemContainer = styled(motion.div)`
  padding: 1.1875rem 1.75rem;
  cursor: pointer;
  border-radius: 1.25rem;
  margin-bottom: 0.75rem;
  transition: background-color 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  box-shadow: 1px 1px 2.125rem 1.25rem rgba(0, 0, 0, 0.05);
  @media (min-width: 1024px) {
    padding: 1.875rem;
  }
`;

const svgVariants = {
  hover: {
    scale: 1.8,
    rotate: 90,
    transition: { duration: 0.3, ease: "easeInOut" },
  },
  initial: {
    scale: 1.3,
    rotate: 0,
    transition: { duration: 0.3, ease: "easeInOut" },
  },
  closed: {
    scale: 1.3,
    rotate: 0,
    transition: { duration: 0.3, ease: "easeInOut" },
  },
};

const pathVariants = {
  open: {
    d: "M1.8,12h2.3M4.1,12h2.3H12h3.2M15.2,12h3.2h3.7",
    stroke: "#CD37AC",
  },
  closed: {
    d: "M2.6,12H12 M21.4,12H12 M12,12V2.6 M12,12v9.4",
    stroke: "#3FA9F5",
  },
};

export function FAQ() {
  const [isOpen, setIsOpen] = useState(
    new Array(FaqQuestions.length).fill(false),
  );

  const toggleItem = (index) => {
    let newIsOpen = [...isOpen];
    newIsOpen[index] = !newIsOpen[index];
    setIsOpen(newIsOpen);
  };
  const [hoveredIndex, setHoveredIndex] = useState(null);

  return (
    <motion.div
      id="FAQ"
      className=" relative z-[1] w-full pb-[7vw] pt-16 tiny:px-3 txsm:px-7 md:pt-10 xl:px-28"
    >
      <div
        id="FAQ-inner"
        className=" flex flex-col items-center [grid-row-gap:3.125rem]"
      >
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          id="FAQ-heading"
          className="ml-0 mr-0 mt-0 w-full max-w-3xl text-center md:mb-[4.5rem] lg:mb-20"
        >
          <div
            id="sub-heading"
            className="mb-5  ml-0 mr-0 mt-0 flex h-full flex-row content-center items-end justify-center [grid-column-gap:.5rem] md:mb-6 "
          >
            <div
              id="svg-container"
              className={
                `relative top-[7.4px] inline-block ` + styles["w-embed"]
              }
            >
              <SvgIcon />
            </div>
            <h2
              id="sub-heading-text"
              className=" mb-0 mt-0 inline whitespace-normal bg-gradient-to-tr from-pink-500 to-violet-500 bg-clip-text pr-2 text-lg font-semibold leading-snug text-transparent md:text-base"
            >
              Frequently asked questions
            </h2>
          </div>
          <div id="FAQ-main-heading" className="mb-5 ml-0 mr-0 mt-0 md:mb-6">
            <h2
              id="main-heading"
              className=" mb-0 mt-0 text-4xl font-medium leading-tight tracking-tighter md:text-[2.75rem] lg:text-[3.4rem]"
            >
              FAQs
            </h2>
          </div>
          <p
            id="sub-heading-text"
            className=" bg-gradient-to-tr from-white to-[rgba(255,255,255,.8)] bg-clip-text text-base text-transparent"
          >
            Past performance does not guarantee future results
          </p>
        </motion.div>
        <motion.div
          id="FAQ-wrapper"
          className="flex w-full flex-col items-center"
        >
          <div
            id="FAQ-container"
            className=" flex max-w-xl flex-col lg:max-w-2xl"
            role="list"
          >
            {FaqQuestions.map((question, index) => (
              <FaqItemContainer
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, delay: index * 0.1 }}
                key={index}
                onClick={() => toggleItem(index)}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
                className={`faq-item-container-${index} cursor-pointer ${
                  isOpen[index] ? "bg-[rgb(30,28,33)]" : "bg-[rgb(20,18,23)]"
                } mb-3 rounded-lg p-4 shadow-md`}
                style={{
                  transform: "translate(0px)",
                  opacity: 1,
                }}
              >
                <div
                  id="faq-item-title"
                  className=" flex items-center justify-between"
                >
                  <p
                    id="faq-title-text"
                    className=" mb-0 mt-0 text-base font-normal leading-[130%_!important] tracking-[.006rem] md:text-lg lg:text-xl"
                  >
                    {question}
                  </p>
                  <div
                    id="faq-icon"
                    className={
                      ` ml-4 flex w-[.875rem] items-center justify-center [transition:transform_.35s_cubic-bezier(.25,_.46,_.45,_.94)]` +
                      styles["w-embed"]
                    }
                  >
                    <motion.svg
                      width="100%"
                      height="100%"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      animate={
                        !isOpen[index] &&
                        (hoveredIndex === index ? "hover" : "closed")
                      }
                      variants={svgVariants}
                    >
                      <motion.path
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        stroke="#3FA9F5"
                        animate={isOpen[index] ? "open" : "closed"}
                        variants={pathVariants}
                        initial="closed"
                      />
                    </motion.svg>
                  </div>
                </div>
                <div
                  id="faq-space"
                  className={`faq-space [transition:_height_.6s] ${
                    isOpen[index] ? "h-[1.25rem]" : "h-0"
                  }`}
                ></div>
                <div
                  id="faq-item-content"
                  className={`faq-item-content overflow-hidden [transition:_opacity_.45s,_max-height_.75s] ${
                    isOpen[index]
                      ? "max-h-44 opacity-100 xsm:max-h-32 sm:max-h-28 md:max-h-20"
                      : "max-h-0 opacity-0"
                  }`}
                >
                  <div className={`` + styles["w-rich-text"]}>
                    <p className="mb-0 mt-0 text-sm font-normal leading-snug tracking-[0.006rem]">
                      {FaqAnswers[index]}
                    </p>
                  </div>
                </div>
              </FaqItemContainer>
            ))}
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
}
