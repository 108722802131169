import { useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "../Docs.module.css";
import { useTOC } from "../TOCContext";
import DocImages from "../DocImages";

const entries = [
  {
    displayText: "Asset Allocation",
    urlFragment: "asset-allocation",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "Security Selection",
    urlFragment: "security-selection",
    headingType: "h2",
    subEntries: [],
  },
  {
    displayText: "Modern Portfolio Theory",
    urlFragment: "modern-portfolio-theory",
    headingType: "h2",
    subEntries: [],
  },
];
export { entries };

export default function DocsTradingBestPracticesDiversification() {
  const { addToTOC, removeFromTOC } = useTOC();

  useEffect(() => {
    entries.forEach(addToTOC);
    return () => {
      entries.forEach((entry) => removeFromTOC(entry));
    };
  }, [addToTOC, removeFromTOC]);

  return (
    <>
      <h1 className={styles.h1}>Diversification</h1>

      <p className={styles.p}>
        Determining which assets and securities to trade holds immense
        significance, and this decision must be intricately tied to your risk
        aversion. Novices often fixate on a single market or security, yet
        diversifying your portfolio serves as an effective strategy to mitigate
        risk and avoid sole reliance on the performance of a solitary security.
      </p>
      <h2 id="asset-allocation" className={`scroll-mt-2 ${styles["h2"]}`}>
        Asset Allocation
        <Link
          to="#asset-allocation"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[68]}
          width={1125}
          height={759}
          className="mx-auto"
          alt="Trading Portfolio Diversification"
        ></img>
      </p>
      <p className={styles.p}>
        Creating a portfolio that spans diverse assets empowers an investor to
        manage risk. Through asset allocation, you can blend different classes
        like stocks, commodities, and bonds.
      </p>
      <p className={styles.p}>
        Incorporating less risky assets into your portfolio might yield lower
        potential returns but substantially curbs risk. For instance, a
        portfolio entirely composed of stocks carries more risk than one
        combining 60% stocks and 40% bonds, though the latter boasts the
        prospect of heightened returns.
      </p>
      <p className={styles.p}>
        The most suitable asset allocation hinges on your trader profile.
        Aggressive traders may lean towards a minimal portion of low-risk assets
        or none at all. On the other hand, conservative traders prioritize
        secure assets. Common asset allocations involve dedicating over 50% of
        assets to speculation, with less than half earmarked for generating
        fixed income.
      </p>
      <h2 id="security-selection" className={`scroll-mt-2 ${styles["h2"]}`}>
        Security Selection
        <Link
          to="#security-selection"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <p className={styles.p}>
        Certain traders might specialize in speculative assets or concentrate
        solely on a specific market. Nonetheless, diversification remains
        attainable. Even within the realm of stock trading, a trader can manage
        a portfolio comprised of a diverse array of stocks.
      </p>
      <p className={styles.p}>
        Selecting appropriate securities is paramount. A steadfast guideline is
        to opt for liquid securities, as they translate to reduced transaction
        costs and swifter order execution.
      </p>
      <p className={styles.p}>
        For effective diversification, choose securities with minimal
        correlation. Correlation, represented on a scale of -1 to 1, signifies
        the relationship between two securities. A correlation of 1 indicates a
        perfect positive correlation, where prices tend to move in sync.
        Conversely, -1 denotes a perfect negative correlation, signifying
        opposing price movements. A correlation of 0 signifies no discernible
        connection between securities.
      </p>

      <h2
        id="modern-portfolio-theory"
        className={`scroll-mt-2 ${styles["h2"]}`}
      >
        Modern Portfolio Theory
        <Link
          to="#modern-portfolio-theory"
          title="Direct link to heading"
          className={`select-none pl-2 opacity-0 transition-opacity duration-200 [color:blue] [text-decoration:none] hover:underline hover:opacity-100 ${styles["hashLink"]}`}
        ></Link>
      </h2>
      <figure className={styles.p}>
        <img
          loading="lazy"
          src={DocImages[69]}
          width={750}
          height={643}
          className="mx-auto"
          alt="Modern Portfolio Theory"
        ></img>
        <figcaption
          style={{
            textAlign: "center",
            fontSize: 12,
          }}
        >
          Efficient frontier in blue
        </figcaption>
      </figure>
      <p className={styles.p}>
        The Modern Portfolio Theory (MPT) outlines how an investor can craft
        optimal portfolios within a specific risk tolerance.
      </p>
      <p className={styles.p}>
        Selecting an optimal portfolio involves plotting the expected return
        against portfolio risk. The portfolio offering the highest return at a
        designated risk level is deemed optimal. This process generates a
        collection of optimal portfolios, collectively forming the efficient
        frontier for each risk level.
      </p>
    </>
  );
}
