import React, { useEffect, useState } from "react";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import Profile from "./dashboard/Profile";
import BlogManager from "./dashboard/BlogManager";
import SubscriptionDetails from "./dashboard/SubscriptionDetails";
import {
  UserCircleIcon,
  PencilAltIcon,
  CreditCardIcon,
} from "@heroicons/react/solid";
import { Auth, Storage } from "aws-amplify";
import ChangeEmail from "./dashboard/ChangeEmail";
import { format } from "date-fns";
import Mfa from "./dashboard/Mfa";
import ChangePassword from "./dashboard/ChangePassword";
import CancelMembership from "./dashboard/CancelMembership";
import NewPost from "./dashboard/NewPost";

const AngleRight = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="1em"
    fill="#999"
    viewBox="0 0 320 512"
  >
    <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
  </svg>
);

const generateMockSubscriptionData = () => {
  const plans = ["Basic", "Premium", "Pro"];
  const randomPlanIndex = Math.floor(Math.random() * plans.length);

  const today = new Date();
  const nextMonth = new Date(
    today.getFullYear(),
    today.getMonth() + 1,
    today.getDate(),
  );

  return {
    planName: plans[randomPlanIndex],
    nextBillingDate: nextMonth.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    }),
  };
};

const Dashboard = () => {
  const navigate = useNavigate();
  const [subscription, setSubscription] = useState(
    generateMockSubscriptionData,
  );
  const [isVerified, setIsVerified] = useState(false);

  const [user, setUser] = useState({
    name: "",
    email: "",
    imageUrl: "",
    occupation: "",
    age: "",
    location: "",
    email_verified: "",
    hasBlogAccess: "",
  });

  useEffect(() => {
    document.body.classList.add("bg-[#1e1e1e]");

    return () => {
      document.body.classList.remove("bg-[#1e1e1e]");
    };
  }, []);

  const onUpdateUser = (updatedUser) => {
    setUser(updatedUser);
  };

  const fetchSubscription = async () => {
    try {
      // Get the current session to retrieve the ID token
      const session = await Auth.currentSession();
      const idToken = session.idToken.jwtToken;

      // Make the GET request to the API Gateway with the Authorization header
      const response = await fetch(process.env.REACT_APP_SUBSCRIPTION_DETAILS, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: idToken,
        },
      });

      if (!response.ok) {
        // If the response is not 2xx, throw an error
        throw new Error(`Error: ${response.status}`);
      }

      // Assuming the response data has the subscription information
      const data = await response.json();
      setSubscription(data);
    } catch (error) {
      console.error("Error fetching subscription data: ", error);
      // Handle error, set subscription state to null or show error message
      setSubscription(null);
    }
  };
  useEffect(() => {
    const fetchUserDataAndSubscription = async () => {
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const { attributes } = cognitoUser;
        const groups =
          cognitoUser.signInUserSession.accessToken.payload["cognito:groups"];
        console.log("groups: ", groups);
        const hasBlogAccess = groups && groups.includes("BlogManagers");
        console.log("hasBlogAccess: ", hasBlogAccess);

        setUser((prevState) => {
          const newState = {
            ...prevState,
            name: attributes["custom:name"] || attributes.email.split("@")[0],
            email: attributes.email,
            occupation: attributes["custom:occupation"] || "",
            age: attributes["custom:age"]
              ? String(attributes["custom:age"])
              : "",
            location: attributes["custom:location"] || "",
            imageUrl: attributes["custom:profile_image"] || "",
            hasBlogAccess,
            email_verified: attributes.email_verified,
          };
          return newState;
        });

        await fetchSubscription();
      } catch (error) {
        console.error("Error fetching user data and subscription", error);
      }
    };

    fetchUserDataAndSubscription();
  }, []);

  const basePath = "/dashboard";

  const date = format(new Date(), "PPP");
  const nextBillingDate = subscription
    ? format(new Date(subscription.nextBillingDate), "PPP")
    : null;

  const planName = subscription
    ? subscription.planName.toUpperCase()
    : "NO PLAN";

  return (
    <div className="flex min-h-screen flex-col bg-[#1e1e1e]">
      <div className="w-full items-end p-2">
        <div className="flex justify-between sm:flex-grow">
          <div className="">
            <div className="mb-2 flex items-center">
              <div className="text-3xl font-bold text-white">
                Hello {user.name}
              </div>
              {subscription && (
                <div className="ml-2 flex items-center rounded-xl bg-[#171717] p-2 pr-2.5">
                  <img
                    className="h-4 w-4"
                    src="https://assets.codepen.io/3685267/res-react-dash-premium-star.svg"
                    alt=""
                  />
                  <div className="ml-2 font-bold text-[#f7b91c]">
                    {planName}
                  </div>
                </div>
              )}
            </div>
            <div className="flex items-center">
              <img
                src="https://assets.codepen.io/3685267/res-react-dash-date-indicator.svg"
                alt=""
                className="h-3 w-3"
              />
              <div className="ml-2 text-[#676767]">{date}</div>
            </div>
          </div>
        </div>
        <div className="sm-w56 relative mt-4 w-full sm:mt-0"></div>
      </div>

      <main className="flex w-full flex-col items-center justify-center pb-20">
        <Routes>
          <Route
            index
            element={
              <DashboardHome
                navigate={navigate}
                user={user}
                nextBillingDate={nextBillingDate}
                subscription={subscription}
              />
            }
          />
          <Route path="mfa" element={<Mfa setIsVerified={setIsVerified} />} />
          <Route
            path="email"
            element={<ChangeEmail isVerified={isVerified} />}
          />
          <Route path="password" element={<ChangePassword />} />
          <Route
            path="profile"
            element={<Profile user={user} onUpdateUser={onUpdateUser} />}
          />
          <Route path="blog-manager" element={<BlogManager />} />
          <Route
            path="subscription-details"
            element={<SubscriptionDetails />}
          />
          <Route
            path="cancelplan"
            element={<CancelMembership subscription={subscription} />}
          />
          <Route
            path="*"
            element={() => {
              navigate(basePath, { replace: true });
              return null;
            }}
          />
          <Route path="newpost" element={<NewPost />} />
        </Routes>
      </main>
      <nav className="fixed inset-x-0 bottom-0 z-10 bg-[#171717] p-4 shadow">
        <div className="flex justify-around">
          <Link
            to={`${basePath}/profile`}
            className="flex flex-col items-center text-zinc-100 hover:text-indigo-500"
          >
            <UserCircleIcon className="h-6 w-6" />
            <span className="mt-1 text-xs">Profile</span>
          </Link>
          {user.hasBlogAccess && (
            <Link
              to={`${basePath}/blog-manager`}
              className="flex flex-col items-center text-zinc-100 hover:text-indigo-500"
            >
              <PencilAltIcon className="h-6 w-6" />
              <span className="mt-1 text-xs">Blog</span>
            </Link>
          )}
          <Link
            to={`${basePath}/subscription-details`}
            className="flex flex-col items-center text-zinc-100 hover:text-indigo-500"
          >
            <CreditCardIcon className="h-6 w-6" />
            <span className="mt-1 text-xs">Subscription</span>
          </Link>
        </div>
      </nav>
    </div>
  );
};

const DashboardHome = ({ navigate, user, nextBillingDate, subscription }) => (
  <>
    {/* Profile Section */}
    <div className="w-full p-2 lg:w-1/3">
      <div className="flex h-fit flex-col justify-between rounded-lg bg-[#171717] p-3">
        <div className="flex items-center space-x-4 p-2">
          {/* User Image */}
          {user.imageUrl ? (
            <img
              src={user.imageUrl}
              alt="Profile"
              className="h-16 w-16 rounded-full"
            />
          ) : (
            <UserCircleIcon className="h-16 w-16 text-zinc-100" />
          )}
          {/* User's Email */}
          <p className="text-sm text-zinc-100">{user.email}</p>
        </div>
        <div className="flex flex-col space-y-2 p-2">
          <Link
            to="email"
            className="flex w-full flex-row items-center justify-between border-t border-solid border-[#ccc] bg-transparent p-[15px_0_5px] text-sm text-white transition duration-150 hover:underline"
          >
            Change Email
            <span className="inline-flex items-center">{AngleRight}</span>
          </Link>
          <Link
            to="password"
            className="flex w-full flex-row items-center justify-between border-t border-solid border-[#ccc]  bg-transparent p-[15px_0_5px] text-sm text-white transition duration-150 hover:underline"
          >
            Change Password
            <span className="inline-flex items-center">{AngleRight}</span>
          </Link>
        </div>
      </div>
    </div>
    {/* Subscription Section */}
    <div className="w-full p-2 lg:w-1/3">
      <div className="flex h-fit flex-col justify-between space-y-4 rounded-lg bg-[#171717] p-3">
        <h2 className="text-lg font-semibold">Subscription</h2>
        {subscription ? (
          <>
            <p className="">
              Your subscribed to the {subscription.planName} plan
            </p>
            <p className="">Your next billing date is {nextBillingDate}</p>
            {/* Display days left if applicable */}
            <Link
              to="https://billing.stripe.com/p/login/4gwdU19JOaqgcA8cMM"
              className="flex w-full flex-row items-center justify-between border-t border-solid border-[#ccc] bg-transparent p-[15px_0_5px] text-sm text-white transition duration-150 hover:underline"
            >
              Manage Payment Info
              <span className="inline-flex items-center">{AngleRight}</span>
            </Link>
            <button
              onClick={() => navigate("/dashboard/cancelplan")}
              className="mt-2 w-full rounded bg-gray-400 px-4 py-2 text-sm text-black transition duration-150 hover:bg-gray-300"
            >
              Cancel Membership
            </button>
          </>
        ) : (
          <p>No current subscription.</p>
        )}
      </div>
    </div>
    {/* Blog Section */}
    {user.hasBlogAccess && (
      <div className="w-full p-2 lg:w-1/3">
        <div className="flex h-fit flex-col justify-between rounded-lg bg-[#171717] p-3">
          <div className="flex items-center justify-between">
            <h2 className="text-lg font-semibold">Blog Manager</h2>
            <PencilAltIcon className="h-10 w-10 text-gray-400" />
          </div>
          <p className="mt-4">You have published {user.postsCount}3 posts.</p>
          {/* Display total views assuming you have a state variable like user.totalViews */}
          <p>Total views on your posts: 420{user.totalViews}</p>
          <div className="mt-4 space-y-2">
            <Link
              to="/dashboard/newpost"
              className="flex w-full flex-row items-center justify-between border-t border-solid border-[#ccc] bg-transparent p-[15px_0_5px] text-sm text-white transition duration-150 hover:underline"
            >
              Create New Post
              <span className="inline-flex items-center">{AngleRight}</span>
            </Link>
            <Link
              to="/dashboard/manage-posts"
              className="flex w-full flex-row items-center justify-between border-t border-solid border-[#ccc] bg-transparent p-[15px_0_5px] text-sm text-white transition duration-150 hover:underline"
            >
              Manage Posts
              <span className="inline-flex items-center">{AngleRight}</span>
            </Link>
            {/* If you have analytics, you might want to include a link to view them */}
            <Link
              to="/dashboard/analytics"
              className="flex w-full flex-row items-center justify-between border-t border-solid border-[#ccc] bg-transparent p-[15px_0_5px] text-sm text-white transition duration-150 hover:underline"
            >
              View Analytics
              <span className="inline-flex items-center">{AngleRight}</span>
            </Link>
          </div>
        </div>
      </div>
    )}
  </>
);

export default Dashboard;
