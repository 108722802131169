import React from "react";
import Marquee from "react-fast-marquee";

export function TextCarousel() {
  const content = [
    "Free Charts",
    "Exhnahced TA",
    "Active Community",
    "Constant Updates",
    "Market Anaylsis",
    "AI-Powered Predicitions",
    "Tailored Insights",
    "Early Alert System",
    "Continual Learning",
    "In-Depth Reports",
    "Risk Management",
    "Ease of Use     ",
  ];

  return (
    <section
      id="framer-section"
      style={{
        display: "flex",
        width: "100%",
        height: "100%",
        maxWidth: "100%",
        maxHeight: "100%",
        placeItems: "center",
        margin: 0,
        padding: "10px",
        listStyleType: "none",
        opacity: 1,
        overflow: "hidden",
        WebkitMaskImage:
          "linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 12.5%, rgb(0, 0, 0) 87.5%, rgba(0, 0, 0, 0) 100%)",
        maskImage:
          "linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 12.5%, rgb(0, 0, 0) 87.5%, rgba(0, 0, 0, 0) 100%)",
      }}
    >
      <Marquee gradient={false} speed={30}>
        <ul
          id="list"
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            maxWidth: "100%",
            maxHeight: "100%",
            placeItems: "center",
            margin: 0,
            padding: 0,
            listStyleType: "none",
            gap: 30,
            position: "relative",
            flexDirection: "row",
            willChange: "transform",
            transform: "translateX(-0px)",
          }}
        >
          {content.map((text, index) => (
            <li id="list-item" key={index}>
              <div
                id="list-item-div"
                className=" relative flex h-min w-min flex-row flex-nowrap content-center items-center justify-start gap-[10px] overflow-visible p-0"
                style={{
                  opacity: 1,
                }}
              >
                <div
                  id="div-inner"
                  className=" relative h-5 w-5 flex-none"
                  style={{
                    imageRendering: "pixelated",
                    flexShrink: 0,
                    opacity: 1,
                  }}
                >
                  <div
                    id="svg-container"
                    className=""
                    style={{
                      width: "100%",
                      height: "100%",
                      aspectRatio: "inherit",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      id="svg-container"
                      className="block"
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <use href="#svg531679564">
                        <svg
                          id="svg531679564"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          viewBox="0 0 20 20"
                        >
                          <path
                            d="M 0 0 L 20 0 L 20 20 L 0 20 Z"
                            fill="transparent"
                          ></path>
                          <path
                            d="M 16.875 5.625 L 8.125 14.375 L 3.75 10"
                            fill="transparent"
                            strokeWidth="3"
                            stroke='var(--token-26e3cb56-8447-4a64-9b7d-37f16a9909d4, rgb(255, 255, 255)) /* {"name":"White"} */'
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                      </use>
                    </svg>
                  </div>
                </div>
                <div
                  id="framer-text"
                  className=" relative h-auto w-auto flex-none overflow-visible whitespace-pre"
                  style={{
                    outline: "none",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    flexShrink: 0,
                    transform: "none",
                    opacity: 1,
                  }}
                >
                  <p
                    id="framer-text"
                    className="m-0 p-0 text-start font-normal [text-decoration:none]"
                    style={{
                      fontSize: 20,
                      letterSpacing: 0.4,
                      lineHeight: 1.3,
                    }}
                  >
                    {text}
                  </p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </Marquee>
    </section>
  );
}
