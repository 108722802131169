import React, { useState, useEffect, useRef } from "react";
import { Auth, API, graphqlOperation, Hub } from "aws-amplify";
import { Link } from "react-router-dom";
import { updateSubscriptionPlan } from "../utils/mutations";
import { ShowPasswordIcon } from "./utils/ShowPasswordIcon";
import { HidePasswordIcon } from "./utils/HidePasswordIcon";

const Signin = () => {
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [showTooltip, setShowTooltip] = useState(true);
  const [isActive, setIsActive] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const [isEmailActive, setIsEmailActive] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isEmailFilled, setIsEmailFilled] = useState(false);
  const [email, setEmail] = useState("");

  const [emailReceivedFocus, setEmailReceivedFocus] = useState(false);
  const [emailChangedOutOfFocus, setEmailChangedOutOfFocus] = useState(false);

  const [failedAttempts, setFailedAttempts] = useState(0); // For tracking failed sign-in attempts
  const mouseMovements = useRef(0); // Using useRef to track mouse movements without causing rerenders

  const [captchaId, setCaptchaId] = useState(null);
  const [captchaImage, setCaptchaImage] = useState(null);
  const [typingStartTime, setTypingStartTime] = useState(null);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [isCaptchaActive, setIsCaptchaActive] = useState(false);
  const [isCaptchaFilled, setIsCaptchaFilled] = useState(false);
  const [captcha, setCaptcha] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const urlParams = new URLSearchParams(window.location.search);
  const planName = urlParams.get("planName");
  const billingCycle = urlParams.get("billingCycle");

  const togglePassword = () => {
    setShowPassword(!showPassword);
    setShowTooltip(!showTooltip);
  };

  const handleFocus = () => setIsActive(true);
  const handleBlur = () => setIsActive(false);
  const handleChange = (e) => {
    setPassword(e.target.value);
    setIsFilled(e.target.value !== "");
    setErrorMessage("");
  };

  const handleEmailFocus = () => {
    setIsEmailActive(true);
    setEmailReceivedFocus(true);
    setEmailChangedOutOfFocus(false);
  };
  const handleEmailBlur = () => {
    setIsEmailActive(false);
    if (emailReceivedFocus && email !== "") {
      setEmailChangedOutOfFocus(true);
    }
  };
  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setIsEmailFilled(e.target.value !== "");
    setIsEmailValid(validateEmail(e.target.value));
    setErrorMessage([]);
  };

  const handleCaptchaFocus = () => setIsCaptchaActive(true);
  const handleCaptchaBlur = () => setIsCaptchaActive(false);
  const handleCaptchaChange = (e) => {
    setCaptcha(e.target.value);
    setIsCaptchaFilled(e.target.value !== "");
    setErrorMessage("");
  };

  let loadingCounter = 0;

  const setLoading = (isLoading) => {
    if (isLoading) {
      loadingCounter++;
    } else {
      loadingCounter = Math.max(0, loadingCounter - 1);
    }
    setIsLoading(loadingCounter > 0);
  };

  // For Mouse Movements
  useEffect(() => {
    const handleMouseMove = () => {
      mouseMovements.current += 1;
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  const handleFieldFocus = () => {
    if (!typingStartTime) {
      setTypingStartTime(Date.now());
    }
  };

  const handleSignInClick = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!isEmailValid && isEmailFilled) {
      setShowError(true);
      setErrorMessage("Invalid email format");
      setLoading(false);
      return;
    }

    if (showCaptcha) {
      try {
        const response = await fetch(
          process.env.REACT_APP_VALIDATE_CAPTCHA_URL,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              captchaId: captchaId,
              userInput: captcha,
            }),
          },
        );
        const data = await response.json();
        if (data.statusCode === 200) {
          await handleSignin();
        } else {
          setShowError(true);
          setErrorMessage("Incorrect CAPTCHA. Please try again.");
        }
      } catch (error) {
        setShowError(true);
        setErrorMessage("Failed to validate CAPTCHA");
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const typingEndTime = Date.now();
        const timeTaken = typingEndTime - typingStartTime;
        if (timeTaken < 5000) {
          await fetchCaptcha();
          setShowCaptcha(true);
        } else {
          await handleSignin();
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const fetchCaptcha = async () => {
    setLoading(true);
    try {
      const response = await fetch(process.env.REACT_APP_GENERATE_CAPTCHA_URL);
      const data = await response.json();
      setCaptchaImage(`data:image/png;base64,${data.image}`);
      setCaptchaId(data.captchaId);
    } catch (error) {
      setShowError(true);
      setErrorMessage("Failed to fetch CAPTCHA");
      console.error("Failed to fetch captcha", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSignin = async () => {
    setLoading(true);
    try {
      const signInResponse = await Auth.signIn({
        username: email,
        password,
        attributes: { email },
      });
      console.log("Sign-in successful:", signInResponse);

      // Store subscription plan details after successful sign-in
      const currentUser = await Auth.currentAuthenticatedUser();
      const userID = currentUser.username;

      // Retrieve existing Stripe Customer ID and subscription plan ID
      const { stripeCustomerId, subscriptionPlanId } =
        await getStripeCustomerId(email);
      if (!stripeCustomerId || !subscriptionPlanId) {
        console.error(
          "Failed to fetch required data. Stopping further processing.",
        );
        return; // Early exit from the function if data is null
      }

      const planDetails = {
        id: subscriptionPlanId, // Include the subscriptionPlanId as 'id'
        userID,
        planName: planName || "DefaultPlan",
        billingCycle: billingCycle || "DefaultCycle",
        stripeCustomerId, // Using the retrieved stripeCustomerId
      };

      // Update the existing subscription plan
      console.log("Updating subscription plan...");
      console.log("Plan details:", planDetails);
      const updatedSubscriptionPlan = await API.graphql(
        graphqlOperation(updateSubscriptionPlan, { input: planDetails }),
      );
      console.log("Updated subscription plan:", updatedSubscriptionPlan);

      // Set JWT Token and Access Token in cookies
      const jwtToken = currentUser.signInUserSession.idToken.jwtToken;
      document.cookie = `token=${jwtToken}; domain=.madalgo.io; path=/; secure`;
      const accessToken = currentUser.signInUserSession.accessToken.jwtToken;
      document.cookie = `accessToken=${accessToken}; domain=.madalgo.io; path=/; secure`;

      // Navigate to checkout
      const planHref =
        planName.toLowerCase() !== "lifetime"
          ? `https://app.madalgo.io/checkout?planName=${planName.toLowerCase()}&billingCycle=${billingCycle}`
          : `https://app.madalgo.io/checkout?planName=lifetime`;

      window.location.href = planHref;
    } catch (error) {
      console.error(error);
      setShowError(true);
      setErrorMessage(`Error signing in: ${error.message}`);
      setFailedAttempts((prevAttempts) => prevAttempts + 1);
    } finally {
      setLoading(false);
    }
  };

  const getStripeCustomerId = async (email) => {
    try {
      // Use the environment variable for the URL
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(
        `${apiUrl}?email=${encodeURIComponent(email)}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );
      const data = await response.json();
      const { stripeCustomerId, subscriptionPlanId } = data;
      return { stripeCustomerId, subscriptionPlanId };
    } catch (error) {
      console.error(
        "There has been a problem with your fetch operation:",
        error,
      );
      throw error;
    }
  };

  // const handleGoogleSignin = async () => {
  //   try {
  //     await Auth.federatedSignIn({ provider: "Google" });
  //   } catch (error) {
  //     setShowError(true);
  //     setErrorMessage(`Error signing in with Google: ${error.message}`);
  //     console.error("Error signing in with Google:", error);
  //   }
  // };
  // useEffect(() => {
  //   const unsubscribe = Hub.listen("auth", async (data) => {
  //     const { payload } = data;
  //     if (payload.event === "signIn" && payload.data.provider === "Google") {
  //       // Store subscription plan details after successful verification
  //       const currentUser = await Auth.currentAuthenticatedUser();
  //       const userID = currentUser.username;
  //       const planDetails = {
  //         userID,
  //         planName: planName || "DefaultPlan",
  //         billingCycle: billingCycle || "DefaultCycle",
  //       };

  //       const newSubscriptionPlan = await API.graphql(
  //         graphqlOperation(createSubscriptionPlan, { input: planDetails }),
  //       );
  //       console.log("New subscription plan:", newSubscriptionPlan);
  //       // Navigate to checkout
  //       const planHref =
  //         planName.toLowerCase() !== "lifetime"
  //           ? `https://app.madalgo.io/checkout?planName=${planName.toLowerCase()}&billingCycle=${billingCycle}`
  //           : `https://app.madalgo.io/checkout?planName=lifetime`;

  //       window.location.href = planHref;
  //     }
  //   });

  //   // Cleanup
  //   return () => unsubscribe();
  // }, []);

  const signUpLink = `/u/signup?planName=${planName}&billingCycle=${billingCycle}`;

  const resetPasswordLink = `/u/reset-password/req/user-pass-auth?planName=${planName}&billingCycle=${billingCycle}`;

  return (
    <section className="hdf04n04 _prompt-box-outer hdf04n04">
      <div className="hd73bh7d hdn8236v">
        <div className="hd8hn83b">
          <header className="hd83b78n he9n38nn">
            <div
              title="MADALGO"
              id="c-p-logo"
              style={{
                width: "auto",
                height: "60",
                position: "static",
                margin: "auto",
                padding: "0",
                backgroundColor: "transparent",
                backgroundPosition: "center",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              }}
            ></div>
            <img
              className="he8937bd8 he8nds8n"
              id="p-l-c"
              src="https://madalgo.io/logo192.png"
              alt="MADALGO"
            ></img>
            <h1 className="h73bh90sd h38ubjd9">
              Login to your MADALGO account
            </h1>
            <></>
          </header>
          <div className="hs873bdf8 hd83b78n">
            <form className="c12db2c34 cf114ab0b">
              <input type="hidden" name="" value="" />
              <div className="c62f2b894 c3bcf3ed8">
                <input type="hidden" name="strengthPolicy" value="good" />
                <input
                  type="hidden"
                  name="complexityOptions.minLength"
                  value=""
                />
                <div className="c93ndsnfj cf832ns09">
                  <div className="input-wrapper">
                    <div
                      className="ca19662c8 c0749183e text c41b84477 cb6f7ea00"
                      data-action-text=""
                      data-alternate-action-text=""
                    >
                      <label
                        className="c9d37bb1f no-js ce8354906 c7aebad14"
                        htmlFor="email"
                      >
                        Email
                      </label>
                      <input
                        id="email"
                        className={`cbbf11f5c c1ad8da8e input ${
                          isEmailActive || isEmailFilled ? "hbdf8hsdn" : ""
                        }`}
                        inputMode="email"
                        name="email"
                        type="text"
                        value={email}
                        required={true}
                        autoComplete="username"
                        autoCapitalize="none"
                        onChange={handleEmailChange}
                        onFocus={() => {
                          handleFieldFocus();
                          handleEmailFocus();
                        }}
                        onBlur={handleEmailBlur}
                      />
                      <div
                        className={`c9d37bb1f js-required ce8354906 c7aebad14 ${
                          isEmailActive || isEmailFilled ? "hbdf8hsdn" : ""
                        }`}
                        data-dynamic-label-for="email"
                        aria-hidden="true"
                      >
                        Email
                      </div>
                    </div>
                  </div>
                  <div className="input-wrapper">
                    <div
                      className={`ca19662c8 c0749183e password c41b84477 cb6f7ea00 ${
                        isActive || isFilled ? "hbdf8hsdn" : ""
                      }`}
                      data-action-text=""
                      data-alternate-action-text=""
                    >
                      <label className="c9d37bb1f no-js ce8354906 c189ccd39">
                        Password
                      </label>
                      <input
                        id="password"
                        className="cbbf11f5c c24e80294 input"
                        name="password"
                        type={showPassword ? "text" : "password"}
                        required={true}
                        autoComplete="new-password"
                        autoCapitalize="none"
                        spellCheck="false"
                        value={password}
                        onFocus={() => {
                          handleFieldFocus();
                          handleFocus();
                        }}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      <div
                        className={`c9d37bb1f js-required ce8354906 c189ccd39 ${
                          isActive || isFilled ? "hbdf8hsdn" : ""
                        }`}
                        data-dynamic-label-for="password"
                        aria-hidden="true"
                      >
                        Password
                      </div>
                      <button
                        className="df893n9fn ulp-button-icon ddf87b3n9 _button-icon"
                        type="button"
                        onClick={togglePassword}
                      >
                        <span
                          className={`password-icon-tooltip show-password-tooltip ${
                            showTooltip ? "" : "hide"
                          }`}
                          aria-hidden="true"
                        >
                          Show password
                        </span>
                        <span
                          className={`password-icon-tooltip hide-password-tooltip ${
                            showTooltip ? "hide" : ""
                          }`}
                          aria-hidden="true"
                        >
                          Hide password
                        </span>
                        <span
                          className={`screen-reader-only password-toggle-label ${
                            showTooltip ? "" : "hide"
                          }`}
                          data-label="show-password"
                        >
                          Show password
                        </span>
                        <span
                          className={`screen-reader-only password-toggle-label ${
                            showTooltip ? "hide" : "unset"
                          }`}
                          data-label="hide-password"
                        >
                          Hide password
                        </span>
                        <span
                          className="h8G2R6oW password js-required"
                          aria-hidden="true"
                        >
                          {showTooltip ? (
                            <ShowPasswordIcon />
                          ) : (
                            <HidePasswordIcon />
                          )}
                        </span>
                      </button>
                    </div>
                  </div>
                  {showCaptcha && (
                    <div className="nd93nsdn hf83bsd9">
                      <div className="ndhe834h ndf943nsd">
                        <img alt="captcha" src={captchaImage} />
                      </div>
                      <div className="input-wrapper">
                        <div
                          className={`ca19662c8 c0749183e password c41b84477 cb6f7ea00 ${
                            isCaptchaActive || isCaptchaFilled
                              ? "hbdf8hsdn"
                              : ""
                          }`}
                          data-action-text=""
                          data-alternate-action-text=""
                        >
                          <label className="c9d37bb1f no-js ce8354906 c189ccd39">
                            Enter the code shown above
                          </label>
                          <input
                            id="captcha"
                            className="cbbf11f5c c24e80294 input"
                            name="captcha"
                            type="captcha"
                            required={true}
                            autoComplete="new-password"
                            autoCapitalize="none"
                            spellCheck="false"
                            value={captcha}
                            onFocus={handleCaptchaFocus}
                            onBlur={handleCaptchaBlur}
                            onChange={handleCaptchaChange}
                          />
                          <div
                            className={`c9d37bb1f js-required ce8354906 c189ccd39 ${
                              isCaptchaActive || isCaptchaFilled
                                ? "hbdf8hsdn"
                                : ""
                            }`}
                            data-dynamic-label-for="captcha"
                          >
                            Enter the code shown above
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <p className="hd98hjern">
                <Link
                  className="h2nd9ns8 h39dns89"
                  to={resetPasswordLink}
                  aria-label=""
                >
                  Forgot Password?
                </Link>
              </p>
              <div className="c6d5cc3be">
                <button
                  className="df893n9fn dh83b09c3 c5bf19ed3 c66fbaaf0 c732daa90"
                  disabled={!isEmailValid || isLoading}
                  onClick={handleSignInClick}
                >
                  {isLoading ? <div className="spinner"></div> : "Continue"}
                </button>
              </div>
              {showError && (
                <div className="hd73666 error d9893h7">{errorMessage}</div>
              )}
            </form>
            <div className="ul-alternate-action  _alternate-action">
              <p className="hdsm94nd">
                Don't have an account?
                <Link
                  className="h2nd9ns8 h39dns89"
                  to={signUpLink}
                  aria-label=""
                >
                  Sign Up
                </Link>
              </p>
            </div>
            {/* <div className="h23vsd7d h238ndm4">
                <span>Or</span>
              </div>
              <div className="d94ndf7bn">
                <form
                  method="post"
                  data-provider="google"
                  className="d78nbenb3 d834nf9 d2mdf9jkm"
                  data-form-secondary="true"
                >
                  <input type="hidden" name="" value="" />

                  <input
                    type="hidden"
                    name="connection"
                    value="google-oauth2"
                  />

                  <button
                    type="submit"
                    className="d93nmfp0 d4f81gf5 d3df562f"
                    data-provider="google"
                    data-action-button-secondary="true"
                    onClick={handleGoogleSignin}
                  >
                    <span
                      className="d98dfnd8 d67dbf7d"
                      data-provider="google"
                    ></span>

                    <span className="d5df81dsf">Continue with Google</span>
                  </button>
                </form>
              </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Signin;
