import React, { useEffect, useRef } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import star from "./imgs/star.png";

const isMobileDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );
};

const ThreeComponent = () => {
  const containerRef = useRef(null);
  const controlsEnabled = !isMobileDevice();

  useEffect(() => {
    const sizes = {
      width: window.innerWidth,
      height: window.innerHeight,
    };

    const textureLoader = new THREE.TextureLoader();
    const starTexture = textureLoader.load(star);

    //scene
    const scene = new THREE.Scene();

    //Camera
    const aspect = sizes.width / sizes.height;
    const fov = 75;
    const near = 0.1;
    const far = 100;
    const camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
    camera.position.z = 4;
    camera.position.y = 0;
    camera.position.x = 0;

    //Renderer
    let renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true,
    });

    renderer.setSize(sizes.width, sizes.height);
    renderer.setClearColor(new THREE.Color("#0a090d"));
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    renderer.render(scene, camera);

    document.body.appendChild(renderer.domElement);

    //Controls
    const controls = new OrbitControls(camera, renderer.domElement);

    controls.enableDamping = true;
    controls.enableZoom = false;

    //Objects

    const geometry = new THREE.TorusGeometry(0.7, 0.2, 16, 100);
    const material = new THREE.PointsMaterial({
      size: 0.001,
    });

    const torus = new THREE.Points(geometry, material);

    scene.add(torus);

    //Particles Background

    const particlesGeometry = new THREE.BufferGeometry();
    const count = 500;

    const positions = new Float32Array(count * 3);

    for (let i = 0; i < count * 3; i++) {
      positions[i] = (Math.random() - 0.5) * (Math.random() * 5);
    }
    particlesGeometry.setAttribute(
      "position",
      new THREE.BufferAttribute(positions, 3),
    );

    const particlesMaterial = new THREE.PointsMaterial({
      size: 0.03,
      map: starTexture,
      transparent: true,
      color: "white",
      blending: THREE.AdditiveBlending,
      sizeAttenuation: true,
    });
    const particlesMesh = new THREE.Points(
      particlesGeometry,
      particlesMaterial,
    );

    scene.add(particlesMesh);

    const pointLight = new THREE.PointLight(0xffffff, 0.1);
    pointLight.position.x = 2;
    pointLight.position.y = 3;
    pointLight.position.z = 4;
    scene.add(pointLight);

    var mouse = { x: 0, y: 0 };
    var mousemove = (event) => {
      mouse.x = event.clientX;
      mouse.y = event.clientY;
    };

    containerRef.current.appendChild(renderer.domElement);

    //Recursion function
    let clock = new THREE.Clock();
    const tick = () => {
      const elapsedTime = clock.getElapsedTime();

      // Continuous rotations - reduced speed for the torus
      torus.rotation.x += 0.001; // Slower continuous rotation
      torus.rotation.y += 0.001;
      torus.rotation.z += 0.001;

      // Introducing oscillating variations - reduced magnitude for slower oscillation
      torus.rotation.x += Math.sin(elapsedTime * 0.5) * 0.05; // Half the previous oscillating effect on rotation
      torus.rotation.y += Math.cos(elapsedTime * 0.3) * 0.05;
      torus.rotation.z += Math.sin(elapsedTime * 0.2) * 0.025;

      particlesMesh.rotation.y = -0.1 * (elapsedTime * 0.4);

      if (mouse.x > 0) {
        // Reduced sensitivity for the mouse movement effect on particles
        particlesMesh.rotation.x = -mouse.y * (elapsedTime * 0.00004);
        particlesMesh.rotation.y = -mouse.x * (elapsedTime * 0.00004);
      }

      // Update controls
      controls.update();

      renderer.render(scene, camera);
      requestAnimationFrame(tick);
    };

    tick();

    const onWindowResize = () => {
      sizes.width = window.innerWidth;
      sizes.height = window.innerHeight;
      camera.aspect = sizes.width / sizes.height;
      camera.updateProjectionMatrix();

      renderer.setSize(sizes.width, sizes.height);
      renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    };

    window.addEventListener("resize", onWindowResize);
    if (controlsEnabled) {
      window.addEventListener("mousemove", mousemove);
    }

    return () => {
      window.removeEventListener("resize", onWindowResize);
      if (controlsEnabled) {
        window.removeEventListener("mousemove", mousemove);
      }

      // Cleanup
      if (containerRef.current) {
        containerRef.current.removeChild(renderer.domElement);
      }
      renderer.dispose();
    };
  }, []);
  return (
    <div
      ref={containerRef}
      id="three-js-container"
      className="absolute w-full md:w-screen"
    />
  );
};

export default ThreeComponent;
